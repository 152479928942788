import GameActionType from './gameActionType';

export const getGameProvidersResource = (params, isLoading, key = 'first') => ({
  type: GameActionType.GET_GAME_PROVIDERS_RESOURCE,
  payload: { params, isLoading, key },
});

export const saveGameProvidersResource = (data, key = 'first') => ({
  type: GameActionType.SAVE_GAME_PROVIDERS_RESOURCE,
  payload: { data, key },
});

export const resetGameProvidersResource = (key = 'first') => ({
  type: GameActionType.RESET_GAME_PROVIDERS_RESOURCE,
  payload: { key },
});

export const getBrandGameProviders = (brandId, params) => ({
  type: GameActionType.GET_BRAND_GAME_PROVIDERS,
  payload: { brandId, params },
});

export const saveBrandGameProviders = (data) => ({
  type: GameActionType.SAVE_BRAND_GAME_PROVIDERS,
  payload: { data },
});

export const resetBrandGameProviders = () => ({
  type: GameActionType.RESET_BRAND_GAME_PROVIDERS,
});

export const exportBrandGameProviders = (brand, params) => ({
  type: GameActionType.EXPORT_BRAND_GAME_PROVIDERS,
  payload: { brand, params },
});

export const getGamesResource = (data, isLoading, key = 'first') => ({
  type: GameActionType.GET_GAMES_RESOURCE,
  payload: { data, isLoading, key },
});

export const saveGamesResource = (data, key = 'first') => ({
  type: GameActionType.SAVE_GAMES_RESOURCE,
  payload: { data, key },
});

export const resetGamesResource = (key = 'first') => ({
  type: GameActionType.RESET_GAMES_RESOURCE,
  payload: { key },
});

export const changeGameLoadingState = (state) => ({
  type: GameActionType.CHANGE_GAME_LOADING_STATE,
  payload: { state },
});

export const getSegmentGamesResource = (brandId, params, isLoading) => ({
  type: GameActionType.GET_SEGMENT_GAMES_RESOURCE,
  payload: { brandId, params, isLoading },
});

export const saveSegmentGamesResource = (data) => ({
  type: GameActionType.SAVE_SEGMENT_GAMES_RESOURCE,
  payload: { data },
});

export const resetSegmentGamesResource = () => ({
  type: GameActionType.RESET_SEGMENT_GAMES_RESOURCE,
});

export const getBrandProviderGames = (brandId, params) => ({
  type: GameActionType.GET_BRAND_PROVIDER_GAMES,
  payload: { brandId, params },
});

export const saveBrandProviderGames = (data) => ({
  type: GameActionType.SAVE_BRAND_PROVIDER_GAMES,
  payload: { data },
});

export const resetBrandProviderGames = () => ({
  type: GameActionType.RESET_BRAND_PROVIDER_GAMES,
});

export const exportBrandProviderGames = (data, params) => ({
  type: GameActionType.EXPORT_BRAND_PROVIDER_GAMES,
  payload: { data, params },
});

export const getGameProviders = (params) => ({
  type: GameActionType.GET_GAME_PROVIDERS,
  payload: { params },
});

export const saveGameProviders = (data) => ({
  type: GameActionType.SAVE_GAME_PROVIDERS,
  payload: { data },
});

export const resetGameProviders = () => ({
  type: GameActionType.RESET_GAME_PROVIDERS,
});

export const getProviderGames = (providerId, params) => ({
  type: GameActionType.GET_PROVIDER_GAMES,
  payload: { providerId, params },
});

export const saveProviderGames = (data) => ({
  type: GameActionType.SAVE_PROVIDER_GAMES,
  payload: { data },
});

export const resetProviderGames = () => ({
  type: GameActionType.RESET_PROVIDER_GAMES,
});

export const getFreeGameProviders = (brandId, params) => ({
  type: GameActionType.GET_FREE_GAME_PROVIDERS,
  payload: { brandId, params },
});

export const saveFreeGameProviders = (data) => ({
  type: GameActionType.SAVE_FREE_GAME_PROVIDERS,
  payload: { data },
});

export const resetFreeGameProviders = () => ({
  type: GameActionType.RESET_FREE_GAME_PROVIDERS,
});

export const getFreeGameProvidersV2 = (brandId, params) => ({
  type: GameActionType.GET_FREE_GAME_PROVIDERS_V2,
  payload: { brandId, params },
});

export const saveFreeGameProvidersV2 = (data) => ({
  type: GameActionType.SAVE_FREE_GAME_PROVIDERS_V2,
  payload: { data },
});

export const resetFreeGameProvidersV2 = () => ({
  type: GameActionType.RESET_FREE_GAME_PROVIDERS_V2,
});

export const getFreeGames = (brandId, params) => ({
  type: GameActionType.GET_FREE_GAMES,
  payload: { brandId, params },
});

export const saveFreeGames = (data) => ({
  type: GameActionType.SAVE_FREE_GAMES,
  payload: { data },
});

export const resetFreeGames = () => ({
  type: GameActionType.RESET_FREE_GAMES,
});

export const getFreeGameAmountRestrictions = (brandId, params) => ({
  type: GameActionType.GET_FREE_GAME_AMOUNT_RESTRICTIONS,
  payload: { brandId, params },
});

export const saveFreeGameAmountRestrictions = (data) => ({
  type: GameActionType.SAVE_FREE_GAME_AMOUNT_RESTRICTIONS,
  payload: { data },
});

export const resetFreeGameAmountRestrictions = () => ({
  type: GameActionType.RESET_FREE_GAME_AMOUNT_RESTRICTIONS,
});

export const changeFreeGameLoadingState = (state) => ({
  type: GameActionType.CHANGE_FREE_GAME_LOADING_STATE,
  payload: { state },
});

export const changeFreeGameAmountLoadingState = (state) => ({
  type: GameActionType.CHANGE_FREE_GAME_AMOUNT_LOADING_STATE,
  payload: { state },
});

export const changeProvidersResourceLoadingState = (state) => ({
  type: GameActionType.CHANGE_PROVIDERS_RESOURCE_LOADING_STATE,
  payload: { state },
});

export const getGameProvider = (brandId, params) => ({
  type: GameActionType.GET_GAME_PROVIDER,
  payload: { brandId, params },
});

export const saveGameProvider = (data) => ({
  type: GameActionType.SAVE_GAME_PROVIDER,
  payload: { data },
});

export const resetGameProvider = () => ({
  type: GameActionType.RESET_GAME_PROVIDER,
});

export const getBrandJackpotFeeds = (params) => ({
  type: GameActionType.GET_BRAND_JACKPOT_FEEDS,
  payload: { params },
});

export const saveBrandJackpotFeeds = (data) => ({
  type: GameActionType.SAVE_BRAND_JACKPOT_FEEDS,
  payload: { data },
});

export const resetBrandJackpotFeeds = () => ({
  type: GameActionType.RESET_BRAND_JACKPOT_FEEDS,
});

export const changeBrandJackpotFeedStatus = (id, data, name) => ({
  type: GameActionType.CHANGE_BRAND_JACKPOT_FEED_STATUS,
  payload: { id, data, name },
});

export const getBrandJackpotFeedGames = (id, params) => ({
  type: GameActionType.GET_BRAND_JACKPOT_FEED_GAMES,
  payload: { id, params },
});

export const saveBrandJackpotFeedGames = (data) => ({
  type: GameActionType.SAVE_BRAND_JACKPOT_FEED_GAMES,
  payload: { data },
});

export const resetBrandJackpotFeedGames = () => ({
  type: GameActionType.RESET_BRAND_JACKPOT_FEED_GAMES,
});

export const exportBrandJackpotFeedGames = (id, params) => ({
  type: GameActionType.EXPORT_BRAND_JACKPOT_FEED_GAMES,
  payload: { id, params },
});
