import PlayerComplaintActionType from 'actions/playerComplaint/playerComplaintActionType';

const initialState = {
  playerComplaints: {
    data: [],
    total: 0,
  },
};

const playerComplaintReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case PlayerComplaintActionType.SAVE_PLAYER_COMPLAINTS:
    return {
      ...state,
      playerComplaints: payload.data,
    };
  case PlayerComplaintActionType.RESET_PLAYER_COMPLAINTS:
    return {
      ...state,
      playerComplaints: {
        data: [],
        total: 0,
      },
    };
  default:
    return state;
  }
};

export default playerComplaintReducer;
