import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import qs from 'qs';
import mime from 'mimetypes';
import { changeGenerateReportEndedStatus, changeLoadingState, setErrorMessage } from 'actions/setting/settingActionCreator';
import {
  saveCasinoBets, savePlayerCasinoBets, saveSportBets, savePlayerSportBets, saveCasinoBetDetails, saveSportBetDetails, saveCasinoBet, saveSportBet,
} from 'actions/bet/betActionCreator';
import NetworkService from 'services/networkService';
import { AppConstants } from 'constants/index';
import BetActionType from 'actions/bet/betActionType';
import { downloadFile } from 'utils/utils';
import { selectSportBetsDetails, selectCasinoBetsDetails } from 'selectors';
import { i18n } from 'services';
import { getError } from 'utils/helpers';

function* getCasinoBets({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    const { ReportingAdmin, BetReport, Casino } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, BetReport, Casino], options);
    yield put(saveCasinoBets(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateCasinoBetsReport({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, BetReport, Casino, Generate,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, BetReport, Casino, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCasinoBetDetails({ payload }) {
  try {
    const { betId } = payload;
    const {
      ReportingAdmin, BetReport, Casino, Details,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data: details } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, Casino, betId, Details]);
    const { bet } = yield select();

    const betsDetails = selectCasinoBetsDetails(bet);
    betsDetails[betId] = details;
    yield put(saveCasinoBetDetails(betsDetails));

    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerCasinoBets({ payload }) {
  try {
    const { playerId } = payload;
    const options = {
      data: payload.data,
    };
    const {
      ReportingAdmin, BetReport, Casino, Player,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, BetReport, Casino, Player, playerId], options);
    yield put(savePlayerCasinoBets(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPlayerCasinoBets({ payload }) {
  try {
    const { playerId } = payload;
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, BetReport, Casino, Export, Player,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, BetReport, Casino, Player, Export, playerId], options);
    downloadFile(data, i18n.t('bet:playerCasinoBets'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSportBets({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, BetReport, Sport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, Sport], options);
    yield put(saveSportBets(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateSportBetsReport({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, BetReport, Sport, Generate,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, Sport, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerSportBets({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, BetReport, Sport, Player,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, Sport, Player, playerId], options);
    yield put(savePlayerSportBets(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPlayerSportBets({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, BetReport, Sport, Export, Player,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, Sport, Player, Export, playerId], options);
    downloadFile(data, i18n.t('bet:playerSportBets'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSportBetDetails({ payload }) {
  try {
    const { betId } = payload;
    const {
      ReportingAdmin, BetReport, Sport, Details,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data: details } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, Sport, betId, Details]);

    const { bet } = yield select();
    const betsDetails = selectSportBetsDetails(bet);
    betsDetails[betId] = details;

    yield put(saveSportBetDetails(betsDetails));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCasinoBet({ payload }) {
  try {
    const { betId } = payload;
    const { ReportingAdmin, BetReport, Casino } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, Casino, betId]);
    yield put(saveCasinoBet(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSportBet({ payload }) {
  try {
    const { betId } = payload;
    const { ReportingAdmin, BetReport, Sport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, Sport, betId]);
    yield put(saveSportBet(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* betSaga() {
  yield all([
    takeLatest(BetActionType.GET_CASINO_BETS, getCasinoBets),
    takeLatest(BetActionType.GENERATE_CASINO_BETS_REPORT, generateCasinoBetsReport),
    takeLatest(BetActionType.GET_CASINO_BET_DETAILS, getCasinoBetDetails),
    takeLatest(BetActionType.GET_PLAYER_CASINO_BETS, getPlayerCasinoBets),
    takeLatest(BetActionType.EXPORT_PLAYER_CASINO_BETS, exportPlayerCasinoBets),
    takeLatest(BetActionType.GET_SPORT_BETS, getSportBets),
    takeLatest(BetActionType.GENERATE_SPORT_BETS_REPORT, generateSportBetsReport),
    takeLatest(BetActionType.GET_PLAYER_SPORT_BETS, getPlayerSportBets),
    takeLatest(BetActionType.EXPORT_PLAYER_SPORT_BETS, exportPlayerSportBets),
    takeLatest(BetActionType.GET_SPORT_BET_DETAILS, getSportBetDetails),
    takeLatest(BetActionType.GET_SPORT_BET, getSportBet),
    takeLatest(BetActionType.GET_CASINO_BET, getCasinoBet),
  ]);
}
