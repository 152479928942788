export default {
  en: {
    maxBonusAmount: 'Max Bonus Amount',
    minDepositAmount: 'Min Deposit Amount',
    maxRedeemAmount: 'Max Redeem Amount',
    minBetAmount: 'Min Bet Amount',
    contributionPercent: 'Contribution, %',
    cashbackPercent: 'Cashback, %',
    currencies: 'Currencies',
    triggerPeriod: 'Trigger Period',
    activationPeriod: 'Activation Period',
    playthrough: 'Playthrough',
    wageringPeriod: 'Wagering Period',
    issueBonus: 'Issue Bonus',
    bonusId: 'Bonus ID',
    promoName: 'Promo Name',
    promoCode: 'Promo Code',
    promoCodeType: 'Promo Code Type',
    lPromoCode: 'promo code',
    maxUseCount: 'Max Use Count',
    dateUsed: 'Date Used',
    promotionEndDate: 'Promotion End Date',
    issuedBy: 'Issued By',
    canceledBy: 'Canceled By',
    mobileVerified: 'Mobile Verified',
    identityVerified: 'Identity Verified',
    emailVerified: 'Email Verified',
    disconnectedBy: 'Disconnected By',
    disconnectTrigger: 'Disconnect Trigger',
    remainingTurnover: 'Remaining Turnover',
    bonusStatus: 'Bonus Status',
    triggeredDate: 'Triggered Date',
    providerMayDisabled: 'Provider may has been disabled.',
    gameMayDisabled: 'Game may has been disabled.',
    maxCashbackAmount: 'Max Cashback Amount',
    cashbackId: 'Cashback ID',
    cashbackName: 'Cashback Name',
    cashbackType: 'Cashback Type',
    calculationTime: 'Calculation Time',
    transferType: 'Transfer Type',
    platforms: 'Platforms',
    platform: 'Platform',
    restrictedPlayers: 'Restricted Players',
    restrictedCountries: 'Restricted Countries',
    totalBet: 'Total Bet',
    totalWin: 'Total Win',
    accumulated: 'Accumulated',
    accumulatedCashBack: 'Accumulated CashBack',
    transferredCashBack: 'Transferred CashBack',
    commission: 'Commission',
    commissions: 'Commissions',
    lCommissions: 'Commissions',
    commissionPercent: 'Commission, %',
    manageLimits: 'Manage Limits',
    referees: 'Referees',
    setCommissions: 'Set Commissions',
    referralBonusId: 'Referral Bonus ID',
    lReferralBonus: 'referral bonus',
    referralBonus: 'Referral Bonus',
    bonusName: 'Bonus Name',
    calculationPeriod: 'Calculation Period',
    calculationPeriodTime: 'Calculation Period Time',
    commissionFrom: 'Commission From',
    referrerId: 'Referrer ID',
    accumulatedCommission: 'Accumulated Commission',
    nextCalculationDate: 'Next Calculation Date',
    transferredCommission: 'Transferred Commission',
    commissioningAmount: 'Commissioning Amount',
    convertedTo: 'Converted To',
    convertedAmount: 'Converted Amount',
    redeemedAmount: 'Redeemed Amount',
    winAmount: 'Win Amount',
    lossAmount: 'Loss Amount',
    cashbackAmount: 'Cashback Amount',
    includeActiveBonuses: 'Include Active Bonuses',
    lBonus: 'bonus',
    bonus: 'Bonus',
    casino: 'Casino',
    invalidPlayerCount: 'Invalid player count in report',
    totalPlayersUpdated: 'Total players that will be updated',
    lBonusTemplate: 'bonus template',
    bonusTemplate: 'Bonus Template',
    invalidKeys: 'Invalid Keys',
    playersMissingCurrencyReason: "The player's account currency is not configured",
    playersRestrictedReason: 'The player has restricted account status',
    missingCurrency: 'Missing Currency',
    missingCurrencyReason: "The player's account currency is not configured",
    issue: 'Issue',
    platformType: 'Platform Type',
    amountConfig: 'Amount Config',
    paymentMethods: 'Payment Methods',
    first: 'First',
    second: 'Second',
    third: 'Third',
    fourth: 'Fourth',
    fifth: 'Fifth',
    fromDepositAmount: '% from Deposit Amount',
    fromDeposit: '% from Deposit',
    betType: 'Bet Type',
    minWageringOdd: 'Min Wagering Odd',
    minWageringOddType: 'Min Wagering Odd Type',
    bonusTemplateName: 'Bonus Template Name',
    bonusTemplateId: 'Bonus Template ID',
    categories: 'Categories',
    bonusOptionsDesc: 'A sign up bonus is a sum of money paid to a new player',
    bonusOptionsDesc1: 'Players will get bonuses for all deposits in the trigger period',
    bonusOptionsDesc2: 'User can choose for how many deposits in the trigger period players to get bonuses',
    bonusOptionsDesc3: 'Players will get bonuses for first deposit of each day in trigger period',
    createNewTemplate: 'Create a New Template',
    selectBonusType: 'SELECT A BONUS TYPE',
    selectBonusTrigger: 'SELECT A BONUS TRIGGER',
    numberOfDeposits: 'Number Of Deposits',
    fixedAmount: 'Fixed Amount',
    sport: 'Sport',
    rules: 'Rules',
    rule: 'Rule',
    noRules: 'No Rules to Show',
    relatedBonusId: 'Related Bonus ID',
    endDateTime: 'End Date/Time',
    noEndDate: 'No End Date',
    selectGames: 'Select Games',
    activities: 'Activities',
    discardAccumulatedCommissions: 'Discard accumulated commissions',
    bonusTypeConfig: 'Bonus Type Config',
    playersInAnotherReferral: 'Players already in another Referral Bonus',
    allPlayersSelected: 'All players are selected',
    datesAndPlayers: 'Dates and Players',
    special: 'Special',
    highRisk: 'High Risk',
    mediumRisk: 'Medium Risk',
    countOfReferees: 'Count Of referees',
    playerFromParticipants: 'player from participants',
    signUp: 'Sign Up',
    bonusNameExternal: 'Bonus Name (External)',
    noEndData: 'No End Date',
    minNumberOfSelections: 'Min Number Of Selections',
    dateTriggered: 'Date Triggered',
    referralBonusName: 'Referral Bonus Name',
    refereesId: 'Referees ID',
    refereeId: 'Referee ID',
    realWallet: 'Real Wallet',
    details: 'Details',
    numberOfDepositsInfo: 'Select the deposits that players must receive bonuses for in the trigger period.',
    numberOfDepositsTabIndex: {
      1: 'st',
      2: 'nd',
      3: 'rd',
      default: 'th',
    },
    cloneSettingsTo: 'Clone Settings To',
    balanceConfig: 'Balance Config',
    usedCount: 'Used Count',
    lRule: 'rule',
    referralParticipants: 'Referral participants',
    theCashback: 'The cashback',
    playersCashBack: 'Players cashback',
    theBonus: 'The bonus',
    depositAmount: 'Deposit Amount',
    wageringTurnover: 'Wagering Turnover',
    playersWontBeAble: "Players won't be able to withdraw or bet on P2P games the issued bonus amount.",
    calculationFrom: 'CALCULATION FROM',
    lCalculationFrom: 'Calculation From',
    createNewCashback: 'Create New Cashback',
    cashbackFromBetsDesc: 'Provides cashback on the positive difference of bet - win in the calculation period.',
    cashbackNetDepositsDesc: 'Provides cashback on the positive difference of deposits - withdrawals in the calculation period.',
    netDepositAmount: 'Net Deposit Amount',
    participateRule: 'Participate Rule',
    totalDeposit: 'Total Deposit',
    totalWithdrawal: 'Total Withdrawal',
    totalBonuses: 'Total Bonuses',
    manageParticipation: 'Manage Participation',
    restrictionFor: 'Restricted For',
    availableFor: 'Available For',
    sportWageringBonuses: 'Sport Wagering Bonuses',
    casinoWageringBonuses: 'Casino Wagering Bonuses',
    otherBonuses: 'Other Bonuses',
    finishedBonusesCount: 'Count Of Finished Bonuses',
    totalRedeemBonuses: 'Total Redeem Bonuses',
    referralCommission: 'Referral Commission',
    maxPossibleTransfer: 'Max Possible Transfer',
    playThroughRequired: ' Playthrough Required',
    matchingRegistrationIP: 'The referrer has referees with matching registration IPs',
    sameRegistrationIP: 'Referrer and referee has the same registration IP',
    amountColumnInfo: 'The range includes the left column value, up to but not including the right column value',
    selectedGames: 'Selected Games',
    minAmount: 'Min. Amount',
    step: 'Step',
    gamesInfo: 'Provider allows to select {{type}} games',
    lSingle: 'single',
    lMultiple: 'multiple',
    allowWithdrawal: 'Allow Withdrawal',
    campaignName: 'Campaign Name',
    noTrigger: 'No Trigger',
    cancelBonusWhenWithdrawal: 'Cancel Bonus (When Withdrawal)',
    number: 'Number',
    participant: 'Participant',
    depositsAmountConfigInfo: 'In this area, we configure Bonus Amount and set limitations per currency',
    bonusBetConfig: 'Bonus Bet Config',
    single: 'Single',
    ranges: 'Ranges',
    nextValue: 'Next value',
    lPrevValue: 'previous value',
    addRanges: 'Add Ranges',
    lifetimePeriod: 'Lifetime Period',
    resetRanges: 'Reset Ranges',
    lCampaign: 'campaign',
    multi: 'Multi',
    option: 'Option',
    triggers: 'Triggers',
    viewRanges: 'View Ranges',
    depositNumber: 'Deposit Number',
    bonusResultAreaDesc: 'You can see the Bonuses after adding and configuring them from the side area.',
    participantsResultAreaDesc: 'You can see the Participants after adding and configuring them from the side area.',
    editTriggerDisabled: 'You cant edit trigger now',
    multiOrSingleBonus: 'Multi and single bonus description',
    editParticipantsDisabled: 'You cant edit participants now',
    configureParticipants: 'Configure participants',
    sidebarBonusesDescription: 'Here we can choose Sport, Spins, Casino, and Special available bonus types and add to the campaign',
    selectTriggerParticipantsBonuses: 'Select The Trigger, Choose Participants, Configure Bonuses',
    toMakeSureCampaignSuccessful: 'To make sure your campaign successful',
    participantType: 'Participant Type',
    triggerType: 'Trigger Type',
    cancelPromotion: 'Cancel Promotion',
    createCampaign: 'Create Campaign',
    multiOrSinglePromocodeType: 'Multi and single promocode type description',
    missingAmount: 'Missing Amount',
    walletType: 'Wallet Type',
    multiSelectionNum: 'Multi Selection Num',
    playThroughRequiredDesc: 'In case this option is turned on the Playthrough field value should be equal to the set number, if it’s turned off it should be equal or greater then',
    playThroughInputDesc: 'Allows defining whether a player must wager the bonus as many times as defined in the playthrough config to be able to finish the bonus (besides 0 in the turnover)',
    cancelBonusWhenWithdrawalDesc: 'Allows to cancel the bonus automatically if the player will do the withdrawal transaction during the bonus activation and expiration periods',
    triggerOption: 'Trigger Option',
    campaign: 'Campaign',
    periodDescription: 'Here we can set in which period to define the deposit’s number',
    singleCountTypeDesc: 'Allows to set only one bonus but with different amounts for every deposit number, usable in cases when we don’t need to configure several bonuses with the same bonus type',
    multiCountTypeDesc:
      'Allows setting different types of bonuses with different amount configurations for every deposit’s number. In this case, we choose the deposit number (possible values 1, …, 100) for every bonus type',
    disconnectTriggerDesc:
      "If this functionality is activated and the player received at least one bonus but doesn't satisfy bonus conditions, then the system will disconnect him from the campaign, and the player will no longer get new bonuses",
    participantsPlayerDesc: 'Allows to include players by uploading CSV or manually',
    participantsPlayerListAmountsDesc: 'Allows to include players with individual bonus amounts by uploading the players’ list',
    participantsPromoCodeDesc: 'Allows to include players by using generated promo code',
    promoCodeTypes: {
      0: 'Allows manually filling promo code',
      1: 'Allows the system to generate unique codes',
      2: 'Allows manually add external promo codes. The system automatically will exclude the duplicate codes and the codes which are not between 3-25 length from the uploaded list, the check will be done between all bonus campaigns',
    },
    countryConditionDesc: 'Checks players’ country',
    verifyConditionDesc: 'Checks players’ verification level',
    weekDaysConditionDesc: 'Checks the players’ on specific days',
    accountStatusConditionDesc: 'Checks players’ account statuses',
    sportStatusConditionDesc: 'Checks players’ statuses on Sportsbook',
    segmentConditionDesc: 'Checks the segments players are parts of',
    tagConditionDesc: 'Checks the tags assigned to players',
    paymentsConditionDesc: 'Checks the players’ transaction payments',
    configModeAlreadyOpened: 'You already opened the configuration mode',
    bonusBetConfigInfo: 'Allows configuring bonus bets. Bet should match saved criteria to be considered as a bonus bet',
    amountConfigSingleDesc: 'Allow setting single amounts config per currency',
    amountConfigRangesDesc: 'Allow setting multi ranges amounts config for every currency',
    amountConfigFixedAmountDesc:
      'Allows providing a bonus amount that is fixed for each currency (By currency). In the case of a Fixed Amount, we can configure the Deposit conditions using Single or Range options',
    amountConfigFromDepositDesc: 'Allows to count bonus amount as a percent from the deposit (General for all currencies)',
    periodType: 'Period Type',
    expirationPeriod: 'Expiration period',
    noDataAlt: 'No Trigger, Participants, Bonuses selected',
    successPlayers: 'Players are able to get bonus',
    errorPlayers: 'Missing Currency',
    amountConfigAreaInfo: 'In this area, we configure Bonus Amount and set limitations per currency',
    availableCount: 'Available Count',
    availablePromoCodes: 'Available Promo Codes',
    downloadAvailableCodes: 'Download Available Codes',
    lengthOfCodes: 'Length Of Codes',
    lNextValue: 'next value',
    freeSpinGamesInfo: 'Select provider and category to get games',
    freeSpinAmountConfigInfo: 'Select provider and line count to get amount restrictions',
    freeSpinRequiredAllGames: 'This provider supports free spin on all games',
    maxWinAmount: 'Max Win Amount',
    line: 'Line',
    casinoBonus: 'Casino Bonus',
    campaignStatus: 'Campaign Status',
    participantsAllPlayersDesc: 'Allows to include all players as a participant when the trigger is executed',
    participantsAllPlayersMainAreaDesc: 'The system will include all players after executing the trigger as a participant.',
    spinsAmount: 'Spins Amount',
    maxTriggerCount: 'Maximum Trigger Count',
    maxTriggerCountInfo: 'This field will allow setting the maximum successful trigger count per player',
    freeSpinCurrencyInfo: 'The system includes currencies supported by brand and chosen provider',
    amountRanges: 'Amount Range (From - To)',
    amountRangesHint: "The amount in the 'From' field of the 'Amount Range' is included in that range, whereas the amount in the 'To' field is not included in the range.",
    numberOfPlayers: 'Number Of Players (From - To)',
    numberOfPlayersHint: "The number in the 'From' field of the 'Number Of Players' is included in that range, whereas the number in the 'To' field is not included in the range.",
    numberOfReferees: 'Number Of Referees (From - To)',
    numberOfRefereesHint: "The number in the 'From' field of the 'Number Of Referees' is included in that range, whereas the number in the 'To' field is not included in the range.",
    campaignStatistics: 'Campaign Statistics',
    maxLimitAmount: 'Max Limit Amount',
    netDepositAmountHint: "The number in the 'From' field of the 'Net Deposit Amount' is included in that range, whereas the number in the 'To' field is not included in the range.",
    gameSegmentInfo: 'Changes in the game segment will not affect on already created entity',
    lCashbackAmounts: 'cashback amounts',
    usedPromoCodes: 'Used Promo Codes',
    createCrossPlatformCampaign: 'Create Cross-Platform Campaign',
    amountConfigAreaCrossCampaignInfo: 'In this area, we set limitations per currency',
    selectAndConfigureBonuses: 'Select and configure bonuses',
    cashbackStatistics: 'CashBack Statistics',
    freeSpinAmountConfigInfoForSingle: 'Select provider, line count and game to get amount restrictions',
    minMaxProviderLimit: 'Min-Max Provider Limit',
    spinTotalAmount: 'Spin Total Amount',
    minMaxProviderLimitInfo: 'The Spin Total Amount generated from spins count multiplied by spins amount should be between the mentioned limits',
    maxWageringOddType: 'Max Wagering Odd Type',
    maxWageringOdd: 'Max Wagering Odd',
    wageringOdd: 'Wagering Odd',
    winAmountTransferTo: 'Win Amount Transfer To',
    allowCashout: 'Allow Cashout',
    allowCashoutInfo: 'Allows players to use cashout/partial cashout functionalities for this bonus',
    bonusAbuse: 'Bonus Abuse',
    bonusAbuseInfo: {
      0: 'After activating this checkbox, the players will not be able to make a bet from the Bonus Balance on the same event more than once',
      1: 'After activating this checkbox, the players will not be able to make a bet from the Bonus Balance on the same event more than once',
      2: 'After activating this checkbox, the players will not be able to make a bet from the Bonus Balance on the same event more than once. In the case of Real Wager Bonus, if the player makes a bet from the Real Balance more than once on the same event that meets the bonus requirements, the second bet should not reduce the wagering turnover',
    },
    oneTimeBet: 'One Time Bet',
    oneTimeBetInfo: 'Requires players to use the bonus fully',
    minBetAmountPerCategory: 'Min Bet Amount Per Category',
    periodDescriptionEvery: "Here we can set in which period to define the Maximum Trigger Count, if it's not configured the system will not limit the trigger work for a player",
    totalBets: 'Total Bets',
    totalWins: 'Total Wins',
    subMethod: 'Sub-Method',
    paymentConditionPropertiesInfo: 'Submethods menu is visible only for the payment methods for which the submethods list is provided by the aggregator.',
    verificationTriggerInfo: 'Condition will work if all selected options are verified, trigger field verification is also included',
    promoCodesUploadFormatMsg: 'You can upload promo codes. Only CSV format is acceptable',
    amountConfigDepositSingleDesc: 'Allow setting single % config per currency',
    amountConfigDepositRangesDesc: 'Allow setting multi ranges % config for every currency',
    canceller: 'Canceller',
    awardedBonusesDetails: 'Awarded Bonuses Details',
    bonusLowestAmount: 'Lowest Bonus Amounts',
    bonusHighestAmount: 'Highest Bonus Amounts',
    totalBonusAmount: 'Bonus Total Amounts',
    checkTheListOfRecipients: 'Please check the final list of the recipients',
    allGames: 'All Games',
    allGamesDesc: "Allows to set bonus raffle on brand's all games, all changes on the brand games will be considered",
    selectGamesDesc: 'Allows to set bonus raffle on specific providers and games',
    contribution: 'Contribution, %',
    addPlayersNote: 'It is possible to add only new participants. Otherwise, they will be automatically excluded from the participants list',
    campaignParticipants: 'Campaign participants',
    autoActivation: 'Autoactivation',
    autoActivationDesc:
      'Allows to allocate the bonus already activated to the player. It is recommended to configure one bonus from each bonus type (Casino, Sport, Free Spin) in case of autoactivation usage. Otherwise, bonuses will be activated randomly',
    promoCodeCaseSensitiveInfo: 'Allows to configure the promo code for players. The codes are not key sensitive for players and will be converted to uppercase format',
    supportPlayerCancellationGamesInfo: "Provider allows to select {{type}} games and doesn't allow to cancel the bonus after activation",
    referralStatistics: 'Referral Statistics',
    campaignWillBeSavedInBgInfo: "As the Campaign data is bigger, it will be saved in the background mode and it is available in the Campaigns' list.",
    contentAndNotification: 'Content And Notification',
    contentAndNotificationConfigsRemovedDesc: 'All Bonus Content and Notification configs were removed, after saving you will lose all previous configs.',
    cloneContentFrom: 'Clone <0>{{bonusName}}</0> bonus content To',
    contentLanguages: 'Content Languages',
    notificationInfo:
      'In this area, we configure a notification for the players about bonus allocation, if no template is selected the players will not be notified with brand communication channels.',
    contentInfo:
      "In this area, we configure images and bonus related content with the brand's languages, which will be visible to players. If the content is not configured the system will use the default content.",
    editContent: 'Edit Content',
    bonusStatistics: 'Bonus Statistics',
    sportBonus: 'Sport Bonus',
    manageContentAndNotification: 'Manage Content and Notification',
    minCashbackAmount: 'Min Cashback Amount',
    triggerDeviceTypeDesc: 'Checks the device type of the trigger',
    signUpDeviceTypeDesc: 'Checks the device type through which the player was registered',
    viewContentAndNotifications: 'View Content and Notifications',
    winType: 'Win Type',
    winOptions: 'Win Amount Type',
    realMoney: 'Real Money',
    availableBonus: 'Available Bonus',
    wageringBonus: 'Wagering Bonus',
    pauseBonus: 'Pause Bonus',
    pauseBonusDesc: 'By enabling the option, player will be able to pause the received bonus and later resume it',
    pausedDate: 'Paused Date',
    resumedDate: 'Resumed Date',
    sidebarParticipantsDescription: 'Here we can choose campaign participants’ selection conditions: Player list, Player list – Amounts, Promo Code, Conditions',
    participantsJoinDesc: "Allows to include players as participants through the 'Join' action on the website",
    contentAndNotificationSidebarNoBonuses: {
      text1: "Campaign content configuration is mandatory if the participation method is 'Join'. Otherwise, it is optional.",
      text2: 'The Bonus content and notification configuration will become active after configuring and saving bonuses.',
    },
    sidebarTriggerDescription: 'Here we can set campaign trigger configurations: No Trigger, Deposit, Sign Up, Verify',
    contentAndNotificationSidebarDesc: 'Here we can choose configured bonuses and add bonus specific content and notification',
    contentAndNotificationResultAreaDesc: 'You can see the Content and Notification for bonuses after adding and configuring them from the side area.',
    campaignContent: 'Campaign Content',
    bonusContentAndNotification: 'Bonus Content and Notification',
    campaignContentTabInfo:
      "In this area, we configure images and campaign related content with the brand's languages, which will be visible to players. It is necessary to configure the content with the brand's main language",
    signInDate: 'Sign In Date',
    remainingBonusAmount: 'Remaining Bonus Amount',
    configAlreadySaved: 'You already have configured bonuses or participants, remove them to be able to edit',
    triggerConditionsResultAreaDesc: 'You can see the Trigger Condition after adding and configuring them from the side area.',
    triggerConditions: 'Trigger Conditions',
    websiteImage: 'Website Image',
    sidebarTriggerConditionDescription: 'Here we can choose campaign trigger specific’ conditions',
    mobileImage: 'Mobile Image',
    specificTimePeriod: 'Specific Time Period',
    weekDays: 'Weekdays',
    monthlyDatesConditionDesc: 'Checks the players’ on specific date',
    dates: 'Dates',
    monthlyDates: 'Monthly Dates',
    sourceId: 'Source ID',
    sourceName: 'Source Name',
    participationGroupsNote: 'You can see the Participation Groups after adding and configuring them from the side area.',
    participationGroupsDesc: 'You can see the Participation Conditions after adding and configuring them from the side area.',
    lParticipationGroups: 'participation groups',
    playerListDesc: 'Allows to include players by uploading CSV or manually',
    playerListAmountDesc: 'Allows to include players by uploading CSV or manually',
    allPlayersDesc: 'Allows to include all players as a participant when the trigger is executed',
    promoCodeDesc: 'Allows to include players by using generated promo code',
    additionalConditions: 'Additional Conditions',
    participationGroups: 'Participation Groups',
    participationGroup: 'Participation Group',
    lParticipationGroup: 'participation group',
    playersAreParticipants: 'Players are included as participants',
    bonusesDesc: 'You can see the Bonuses after adding and configuring them from the side area.',
    gameConfig: 'Game Config',
    recurringConditionDesc: "Checks the players' triggers on specific days or monthly dates",
    conditionTypes: 'Condition Types',
    lTriggerCondition: 'trigger condition',
    bonusAbuseInfoNote: 'After activating this checkbox, the players will not be able to make a bet from the Bonus Balance on the same event more than once',
    allowBetBuilder: 'Allow Bet Builder',
    productSelection: 'Product Selection',
    casinoGameConfig: 'Casino Game Config',
    contents: 'Contents',
    lCampaignContent: 'campaign content',
    lBonusContent: 'bonus content',
    units: 'Units',
    unitsNote: 'sd',
    triggerCondition: 'Trigger Condition',
    wageringLogic: 'Wagering Logic',
    wageringBaseAmount: 'Wagering Base Amount',
    wageringCoefficient: 'Wagering Coefficient',
    wageringCount: 'Wagering Count',
    calculationLogic: 'Calculation Logic',
    wageringParticipation: 'Wagering Participation',
    firstWalletDebited: '1st Wallet Debited',
    lockAmount: 'Lock Amount',
    allowNonMatchingBets: 'Allow Non-matching Bets',
    triggerBasedConfig: 'Trigger Based Config',
    lUnit: 'unit',
    unitsDesc: 'You can see the Units after adding and configuring them from the side area.',
    navigationItemsDisabledInfo: 'The navigation items will become active after saving the Campaign General section.',
    sidebarTriggerConditionInfo: 'You can see the Trigger Conditions after adding and configuring them from the side area',
  },
  es: {
    maxBonusAmount: 'Importe máx. de bono',
    minDepositAmount: 'Importe mín. de depósito',
    maxRedeemAmount: 'Importe máx. de canjeo',
    minBetAmount: 'Importe mín. de apuesta',
    contributionPercent: 'Contribución, %',
    cashbackPercent: 'Devolución de efectivo, %',
    bonusType: 'Tipo de bono',
    currencies: 'Monedas',
    triggerPeriod: 'Periodo de activación',
    activationPeriod: 'Periodo de activación',
    playthrough: 'Requisitos de Apuesta',
    wageringPeriod: 'Periodo de Apuestas',
    issueBonus: 'Emisión de bono',
    bonusId: 'ID de Bono',
    promoName: 'Nombre de promoción',
    promoCode: 'Código de promoción',
    promoCodeType: 'Tipo de código de promoción',
    lPromoCode: 'código de promoción',
    maxUseCount: 'Recuento de usos máx.',
    dateUsed: 'Fecha de uso',
    promotionEndDate: 'Fecha de finalización de la promoción',
    issuedBy: 'Emitido por',
    canceledBy: 'Cancelado por',
    mobileVerified: 'Móvil verificado',
    identityVerified: 'Identidad verificada',
    emailVerified: 'Correo electrónico verificado',
    disconnectedBy: 'Desconectado por',
    disconnectTrigger: 'Desencadenante de la desconexión',
    remainingTurnover: 'Requisitos de apuesta restantes',
    bonusStatus: 'Estado del bono',
    triggeredDate: 'Fecha de activación',
    providerMayDisabled: 'Puede que el proveedor haya sido inhabilitado.',
    gameMayDisabled: 'Puede que el juego haya sido inhabilitado.',
    maxCashbackAmount: 'Importe máx. de devolución de efectivo',
    cashbackId: 'ID de devolución de efectivo',
    cashbackName: 'Nombre de devolución de efectivo',
    cashbackType: 'Tipo de devolución de efectivo',
    calculationTime: 'Tiempo de cálculo',
    transferType: 'Tipo de transferencia',
    platforms: 'Plataformas',
    platform: 'Plataforma',
    restrictedPlayers: 'Jugadores restringidos',
    restrictedCountries: 'Países restringidos',
    totalBet: 'Apuesta total',
    totalWin: 'Ganancias totales',
    accumulated: 'Acumulado',
    accumulatedCashBack: 'Devolución de efectivo acumulada',
    transferredCashBack: 'Devolución de efectivo transferida',
    commission: 'Comisión',
    commissions: 'Comisiones',
    lCommissions: 'Comisiones',
    commissionPercent: 'Comisión, %',
    manageLimits: 'Gestionar límites',
    referees: 'Referencias',
    setCommissions: 'Definir comisiones',
    referralBonusId: 'ID del bono por recomendación',
    lReferralBonus: 'bono por recomendación',
    referralBonus: 'Bono por recomendación',
    bonusName: 'Nombre de bono',
    calculationPeriod: 'Periodo de cálculo',
    calculationPeriodTime: 'Tiempo del periodo de cálculo',
    commissionFrom: 'Comisión de',
    referrerId: 'ID de recomendador',
    accumulatedCommission: 'Comisión acumulada',
    nextCalculationDate: 'Próxima fecha de cálculo',
    transferredCommission: 'Comisión transferida',
    commissioningAmount: 'Importe de la comisión',
    convertedTo: 'Convertido a',
    convertedAmount: 'Importe convertido',
    redeemedAmount: 'Importe canjeado',
    winAmount: 'Importe de las ganancias',
    lossAmount: 'Importe de las pérdidas',
    cashbackAmount: 'Importe de devolución de efectivo',
    includeActiveBonuses: 'Incluir bonos activos',
    lBonus: 'bono',
    bonus: 'Bono',
    casino: 'Casino',
    invalidPlayerCount: 'Recuento de jugadores no válido en el informe',
    totalPlayersUpdated: 'Total de jugadores que se van a actualizar',
    lBonusTemplate: 'plantilla de bono',
    bonusTemplate: 'Plantilla de bono',
    invalidKeys: 'Claves inválidas',
    playersMissingCurrencyReason: 'No se ha configurado la moneda de la cuenta del jugador',
    playersRestrictedReason: 'El jugador tiene un estado de cuenta restringido',
    missingCurrency: 'Falta la moneda',
    missingCurrencyReason: 'No se ha configurado la moneda de la cuenta del jugador',
    issue: 'Publicar',
    platformType: 'Tipo de plataforma',
    amountConfig: 'Configuración del importe',
    paymentMethods: 'Métodos de pago',
    first: 'Primero',
    second: 'Segundo',
    third: 'Tercero',
    fourth: 'Cuarto',
    fifth: 'Quinto',
    fromDepositAmount: '% de importe de depósito',
    fromDeposit: '% de depósito',
    betType: 'Tipo de apuesta',
    minWageringOdd: 'Cuota mín. de apuesta',
    minWageringOddType: 'Tipo de cuota mín. de apuesta',
    bonusTemplateName: 'Nombre de plantilla de bono',
    bonusTemplateId: 'ID de plantilla de bono',
    categories: 'Categorías',
    bonusOptionsDesc: 'El bono de registro es una suma de dinero que se paga a un nuevo jugador',
    bonusOptionsDesc1: 'Los jugadores obtendrán bonos por todos sus depósitos durante el periodo de activación',
    bonusOptionsDesc2: 'El usuario puede elegir el número de depósitos por los que el jugador obtendrá bonos durante el periodo de activación',
    bonusOptionsDesc3: 'Los jugadores obtendrán bonos por el primer depósito que realicen cada día durante el periodo de activación',
    createNewTemplate: 'Crear nueva plantilla',
    selectBonusType: 'SELECCIONAR TIPO DE BONO',
    selectBonusTrigger: 'SELECCIONAR ACTIVADOR DE BONO',
    numberOfDeposits: 'Número de depósitos',
    fixedAmount: 'Importe fijo',
    sport: 'Deporte',
    rules: 'Reglas',
    rule: 'Regla',
    noRules: 'No hay reglas que mostrar',
    relatedBonusId: 'ID de bono relacionado',
    endDateTime: 'Fecha/hora de finalización',
    noEndDate: 'No hay fecha de finalización',
    selectGames: 'Seleccionar juegos',
    activities: 'Actividades',
    discardAccumulatedCommissions: 'Descartar comisiones acumuladas',
    bonusTypeConfig: 'Configuración de tipo de bono',
    playersInAnotherReferral: 'Los jugadores ya tienen otro bono por recomendación',
    allPlayersSelected: 'Se han seleccionado todos los jugadores',
    datesAndPlayers: 'Fechas y jugadores',
    special: 'Especial',
    highRisk: 'Riesgo alto',
    mediumRisk: 'Riesgo medio',
    countOfReferees: 'Recuento de referencias',
    playerFromParticipants: 'jugador de los participantes',
    signUp: 'Registrarse',
    bonusNameExternal: 'Nombre de bono (externo)',
    noEndData: 'No hay fecha de finalización',
    minNumberOfSelections: 'Número mín. de selecciones',
    dateTriggered: 'Fecha de activación',
    referralBonusName: 'Nombre del bono por recomendación',
    refereesId: 'IDs de referencias',
    refereeId: 'ID de referencia',
    realWallet: 'Monedero real',
    details: 'Detalles',
    numberOfDepositsInfo: 'Selecciona los depósitos por los que los jugadores deben recibir bonos durante el periodo de activación.',
    numberOfDepositsTabIndex: {
      1: 'º',
      2: 'º',
      3: 'º',
      default: 'º',
    },
    cloneSettingsTo: 'Clonar configuración a',
    balanceConfig: 'Configurar saldo',
    usedCount: 'Recuento utilizado',
    lRule: 'regla',
    referralParticipants: 'Participantes recomendados',
    theCashback: 'La devolución de efectivo',
    playersCashBack: 'Devolución de efectivo de los jugadores',
    theBonus: 'El bono',
    depositAmount: 'Importe de depósito',
    wageringTurnover: 'Requisitos de apuesta',
    playersWontBeAble: 'Los jugadores no podrán retirar ni apostar el importe del bono emitido en juegos P2P.',
    calculationFrom: 'CÁLCULO DE',
    lCalculationFrom: 'Cálculo de',
    createNewCashback: 'Crear nueva devolución de efectivo',
    cashbackFromBetsDesc: 'Proporciona una devolución de efectivo por la diferencia positiva entre apuestas - ganancias durante el periodo de cálculo.',
    cashbackNetDepositsDesc: 'Proporciona una devolución de efectivo por la diferencia positiva entre depósitos - retiradas durante el periodo de cálculo.',
    netDepositAmount: 'Importe neto de depósito',
    participateRule: 'Regla de participación',
    totalDeposit: 'Depósito total',
    totalWithdrawal: 'Retirada total',
    totalBonuses: 'Bonos totales',
    manageParticipation: 'Gestionar participación',
    restrictionFor: 'Restringido para',
    availableFor: 'Disponible para',
    sportWageringBonuses: 'Bonos de apuestas deportivas',
    casinoWageringBonuses: 'Bonos de apuestas reales',
    otherBonuses: 'Otros bonos',
    finishedBonusesCount: 'Recuento de bonos finalizados',
    totalRedeemBonuses: 'Total de bonos canjeados',
    referralCommission: 'Comisión por recomendación',
    maxPossibleTransfer: 'Transferencia máx. posible',
    playThroughRequired: ' Se requiere requisito de apuesta',
    matchingRegistrationIP: 'El recomendador tiene referencias con IPs de registro coincidentes',
    sameRegistrationIP: 'El recomendador y la referencia tienen la misma IP de registro',
    expirationPeriod: 'Período de caducidad',
    lengthOfCodes: 'Longitud de los códigos',
    amountColumnInfo: 'El rango incluye desde el valor de la columna de la izquierda hasta el valor de la columna de la derecha, pero sin incluirlo',
    selectedGames: 'Juegos seleccionados',
    minAmount: 'Importe Min. ',
    step: 'Paso',
    gamesInfo: 'El proveedor permite seleccionar juegos de {{type}}',
    lSingle: 'individual',
    lMultiple: 'multiple',
    allowWithdrawal: 'Permitir la retirada',
    campaignName: 'Nombre de la campaña',
    noTrigger: 'No Trigger',
    cancelBonusWhenWithdrawal: 'Cancelación de la bonificación al retirar el dinero',
    number: 'Número',
    participant: 'Participante',
    depositsAmountConfigInfo: 'En esta área, configuramos el importe de la bonificación y establecemos las limitaciones por moneda',
    bonusBetConfig: 'Configuración de la apuesta de bonificación',
    single: 'individual',
    ranges: 'Rango',
    nextValue: 'Siguiente Valor',
    lPrevValue: 'valor anterior',
    addRanges: 'Añadir rangos',
    lifetimePeriod: 'Período de vida',
    resetRanges: 'Restablecer rangos',
    lCampaign: 'campaña',
    multi: 'Multi',
    option: 'Opcion',
    triggers: 'Triggers',
    viewRanges: 'Ver rangos',
    depositNumber: 'Número del Deposito',
    bonusResultAreaDesc: 'Puedes ver los Bonos después de añadirlos y configurarlos desde el área lateral.',
    participantsResultAreaDesc: 'Puedes ver los Participantes después de añadirlos y configurarlos desde el área lateral.',
    multiOrSingleBonus: 'Descripción de las bonificaciones múltiples e individuales',
    editParticipantsDisabled: 'Ahora no se puede editar a los participantes',
    configureParticipants: 'Configurar los participantes',
    selectTriggerParticipantsBonuses: 'Seleccione el trigger, elija los participantes, configure las bonificaciones',
    toMakeSureCampaignSuccessful: 'Para garantizar el éxito de su campaña',
    participantType: 'Tipo de participante',
    triggerType: 'Tipo de Trigger',
    cancelPromotion: 'Cancelar la promoción',
    createCampaign: 'Crear campaña',
    multiOrSinglePromocodeType: 'Descripción del tipo de promocode múltiple e individual',
    missingAmount: 'Importe Perdido',
    walletType: 'Tipo de cartera',
    multiSelectionNum: 'Número de selección múltiple',
    playThroughRequiredDesc: 'Si esta opción está activada, el valor del campo Playthrough debe ser igual al número establecido, si está desactivada debe ser igual o mayor que',
    playThroughInputDesc:
      'Permite definir si un jugador debe apostar la bonificación tantas veces como se defina en la configuración del playthrough para poder terminar la bonificación (además de 0 en la facturación)',
    cancelBonusWhenWithdrawalDesc: 'Descripción de la cancelación de la bonificación cuando se retira',
    triggerOption: 'Opcion de Trigger',
    campaign: 'Campaña',
    periodDescription: 'Aquí podemos establecer en qué periodo definir el número del depósito',
    singleCountTypeDesc:
      'Permite configurar un solo bono pero con diferentes importes para cada número de depósito, utilizable en los casos en que no necesitemos configurar varios bonos con el mismo tipo de bono',
    multiCountTypeDesc:
      'Permite establecer diferentes tipos de bonificaciones con diferentes configuraciones de importe para cada número de depósito. En este caso, elegimos el número de depósito (valores posibles 1, ..., 100) para cada tipo de bono',
    disconnectTriggerDesc:
      'Si esta funcionalidad está activada y el jugador ha recibido al menos una bonificación pero no satisface las condiciones de bonificación, el sistema lo desconectará de la campaña y el jugador ya no obtendrá nuevas bonificaciones',
    participantsPlayerDesc: 'Permite incluir a los jugadores cargando un CSV o manualmente',
    participantsPlayerListAmountsDesc: 'Permite incluir a los jugadores con importes de bonificación individuales cargando la lista de jugadores',
    participantsPromoCodeDesc: 'Permite incluir a los jugadores mediante un código de promoción generado',
    countryConditionDesc: 'Verifica el país de los jugadores',
    verifyConditionDesc: 'Comprueba el nivel de verificación de los jugadores',
    weekDaysConditionDesc: 'Controla a los jugadores en días específicos',
    accountStatusConditionDesc: 'Comprueba el estado de las cuentas de los jugadores',
    sportStatusConditionDesc: 'Comprueba el estado de los jugadores en Sportsbook',
    segmentConditionDesc: 'Comprueba los segmentos de los que forman parte los jugadores',
    tagConditionDesc: 'Comprueba las etiquetas que tienen los jugadores',
    paymentsConditionDesc: 'Comprueba los pagos de las transacciones de los jugadores',
    configModeAlreadyOpened: 'Ya has abierto el modo de configuración',
    bonusBetConfigInfo: 'Permite configurar las apuestas de bonificación. La apuesta debe coincidir con los criterios guardados para ser considerada como una apuesta de bonificación',
    amountConfigSingleDesc: 'Permitir la configuración de importes individuales por moneda',
    amountConfigRangesDesc: 'Permitir la configuración de cantidades de varios rangos para cada moneda',
    amountConfigFixedAmountDesc:
      'Permite proporcionar un importe de bonificación que es fijo para cada moneda (Por moneda). En el caso de un Importe Fijo, podemos configurar las condiciones de Depósito utilizando las opciones de Individual o Rango',
    amountConfigFromDepositDesc: 'Permite contabilizar el importe de la bonificación como un porcentaje del depósito (general para todas las monedas)',
    periodType: 'Tipo de período',
    noDataAlt: 'Sin Trigger, Participantes, Bonos seleccionados',
    successPlayers: 'Los jugadores pueden obtener bonificaciones',
    errorPlayers: 'Falta de divisas',
    amountConfigAreaInfo: 'En esta área, configuramos el importe de la bonificación y establecemos las limitaciones por moneda',
    availableCount: 'Recuento disponible',
    availablePromoCodes: 'Códigos promocionales disponibles',
    downloadAvailableCodes: 'Descargar los códigos disponibles',
    lNextValue: 'siguiente valor',
    participantsAllPlayersDesc: 'Permite incluir a todos los jugadores como participantes cuando se ejecute el trigger',
    participantsAllPlayersMainAreaDesc: 'El sistema incluirá a todos los jugadores como participantes tras ejecutar el trigger.',
    freeSpinGamesInfo: 'Seleccione proveedor y categoría para obtener juegos',
    freeSpinAmountConfigInfo: 'Seleccione el proveedor y el número de líneas para obtener las restricciones de importe',
    freeSpinRequiredAllGames: 'Este proveedor admite giros gratis en todos los juegos',
    maxWinAmount: 'Importe Máx Ganado',
    line: 'Línea',
    casinoBonus: 'Bonos de Casinos',
    campaignStatus: 'Estado de la Campaña',
    spinsAmount: 'Cantidad de Spins',
    maxTriggerCount: 'Número Máximo de Disparos',
    maxTriggerCountInfo: 'Este campo permitirá establecer el número máximo de disparos con éxito por jugador',
    freeSpinCurrencyInfo: 'El sistema incluye las divisas admitidas por la marca y el proveedor elegido',
    amountRanges: 'Rango de Importe ( Desde - Hasta)',
    amountRangesHint: 'El importe del campo " Desde " del " Rango de Importes " se incluye en ese rango, mientras que el importe del campo " Hasta " no se incluye en el rango.',
    numberOfPlayers: 'Número de Jugadores ( Desde - Hasta )',
    numberOfPlayersHint: 'El número del campo "Desde" del "Número de Jugadores" se incluye en ese intervalo, mientras que el número del campo "Hasta" no se incluye en el intervalo.',
    numberOfReferees: 'Número de Árbitros ( Desde - Hasta)',
    numberOfRefereesHint: 'El número del campo " Desde " del " Número de Árbitros " se incluye en ese intervalo, mientras que el número del campo " Hasta " no se incluye en el intervalo.',
    campaignStatistics: 'Estadísticas de la Campaña',
    maxLimitAmount: 'Importe Límite Máx.',
    netDepositAmountHint: 'El número del campo " Desde " del " Importe Neto del Depósito " se incluye en ese intervalo, mientras que el número del campo " Hasta " no se incluye en el intervalo.',
    gameSegmentInfo: 'Los cambios en el segmento de juego no afectarán a las entidades ya creadas.',
    lCashbackAmounts: 'importes de cashback',
    usedPromoCodes: 'Códigos Promocionales Usados',
    createCrossPlatformCampaign: 'Crear una Campaña Multiplataforma',
    amountConfigAreaCrossCampaignInfo: 'En este ámbito, establecemos limitaciones por divisa',
    selectAndConfigureBonuses: 'Seleccionar y configurar las bonificaciones',
    cashbackStatistics: 'Estadísticas CashBack',
    freeSpinAmountConfigInfoForSingle: 'Seleccione proveedor, número de líneas y juego para obtener restricciones de importe',
    minMaxProviderLimit: 'Límite de Proveedor Mín-Máx',
    spinTotalAmount: 'Importe Total de Spin',
    minMaxProviderLimitInfo: 'El Importe Total de Spin generado a partir del recuento de spins multiplicado por el importe de spins debe estar entre los límites mencionados.',
    maxWageringOddType: 'Tipo Máx. de la Cuota de Wagering',
    maxWageringOdd: 'Máx. Cuota de Wagering',
    wageringOdd: 'Cuota Wagering',
    winAmountTransferTo: 'Transferir el Importe de la Ganancia',
    allowCashout: 'Permitir Cashout',
    allowCashoutInfo: 'Permite a los jugadores utilizar las funcionalidades de retiro de efectivo/retiro parcial para este bono.',
    editTriggerDisabled: 'No se puede editar el trigger ahora',
    bonusAbuse: 'Bonificación Abuso',
    minBetAmountPerCategory: 'Importe Mínimo de Apuesta por Categoría',
    periodDescriptionEvery: 'Aquí podemos establecer en qué periodo definir el Recuento Máximo de Disparos, si no está configurado el sistema no limitará el trabajo de disparo para un jugador',
    totalBets: 'Apuestas Totales',
    totalWins: 'Victorias Totales',
    subMethod: 'Submétodo',
    paymentConditionPropertiesInfo: 'El menú de submétodos sólo es visible para los métodos de pago para los que el agregador proporciona la lista de submétodos.',
    verificationTriggerInfo: 'La condición funcionará si se verifican todas las opciones seleccionadas, también se incluye la verificación del campo de activación',
    promoCodesUploadFormatMsg: 'Puede cargar códigos promocionales. Sólo se acepta el formato CSV',
    amountConfigDepositSingleDesc: 'Permitir la configuración de un % por moneda',
    amountConfigDepositRangesDesc: 'Permitir la configuración de múltiples rangos % para cada moneda',
    canceller: 'Anulador',
    awardedBonusesDetails: 'Detalles de las bonificaciones concedidas',
    bonusLowestAmount: 'Bonificaciones más bajas',
    bonusHighestAmount: 'Bonificaciones más elevadas',
    totalBonusAmount: 'Importe Total de la Bonificación',
    checkTheListOfRecipients: 'Consulte la lista definitiva de destinatarios',
    allGames: 'Todos los Juegos',
    allGamesDesc: 'Permite establecer el sorteo de bonificaciones en todos los juegos de la marca, se tendrán en cuenta todos los cambios en los juegos de la marca.',
    selectGamesDesc: 'Permite establecer sorteos de bonificaciones en proveedores y juegos específicos',
    contribution: 'Contribución, %.',
    addPlayersNote: 'Sólo es posible añadir nuevos participantes. De lo contrario, quedarán automáticamente excluidos de la lista de participantes',
    campaignParticipants: 'Participantes en la campaña',
    autoActivation: 'Autoactivación',
    autoActivationDesc:
      'Permite asignar el bono ya activado al jugador. Se recomienda configurar un bono de cada tipo de bono (Casino, Deporte, Free Spin) en caso de uso de autoactivación. De lo contrario, los bonos se activarán aleatoriamente',
    promoCodeCaseSensitiveInfo: 'Permite configurar el código promocional para los jugadores. Los códigos no son sensibles a la clave para los jugadores y se convertirán a formato mayúsculas',
    supportPlayerCancellationGamesInfo: 'El proveedor permite seleccionar {{tipo}} de juegos y no permite cancelar el bono después de la activación',
    referralStatistics: 'Estadísticas de remisión',
    campaignWillBeSavedInBgInfo: 'A medida que los datos de la Campaña sean mayores, se guardarán en segundo plano y estarán disponibles en la lista de Campañas.',
    contentAndNotification: 'Contenido y Notificación',
    contentAndNotificationConfigsRemovedDesc: 'Todas las configuraciones de Contenido Extra y Notificación fueron removidas, trás guardar se perderán todas las configuraciones previas.',
    cloneContentFrom: 'Clonar <0>{{bonusName}}</0> contenido de bonificación A',
    contentLanguages: 'Idioma de los contenidos',
    notificationInfo:
      'En esta área, configuramos una notificación para los jugadores sobre la asignación de bonificaciones, si no se selecciona ninguna plantilla los jugadores no serán notificados con canales de comunicación de la marca.',
    contentInfo:
      'En esta área se configuran las imágenes y el contenido relacionado con los bonos con los idiomas de la marca, que serán visibles para los jugadores. Si el contenido no está configurado el sistema utilizará el contenido por defecto.',
    editContent: 'Editar Contenido',
    bonusStatistics: 'Estadísticas adicionales',
    sportBonus: 'Bonificación Deportiva',
    manageContentAndNotification: 'Gestionar Contenidos y Notificaciones',
    minCashbackAmount: 'Importe mín. del Cashback',
    triggerDeviceTypeDesc: 'Comprueba el tipo de dispositivo del disparador',
    signUpDeviceTypeDesc: 'Comprueba el tipo de dispositivo a través del cual se registró el reproductor',
    viewContentAndNotifications: 'Ver Contenidos y Notificaciones',
    winType: 'Tipo de Ganancia',
    winOptions: 'Tipo de Ganancia',
    realMoney: 'Dinero Real',
    availableBonus: 'Bono Disponible',
    wageringBonus: 'Bono Wagering',
    pauseBonus: 'Bonificación del Bono ',
    pauseBonusDesc: 'Al activar esta opción, el jugador podrá pausar la bonificación recibida y reanudarla más tarde.',
    pausedDate: 'Fecha de pausa',
    resumedDate: 'Fecha de reanudación',
    sidebarParticipantsDescription:
      'Aquí podemos elegir las condiciones de selección de los participantes de la campaña: Lista de jugadores, Lista de jugadores - Importes, Código de promoción, Condiciones',
    contentAndNotificationSidebarNoBonuses: 'El área de contenidos y notificaciones se activará tras configurar y guardar las bonificaciones',
    sidebarTriggerDescription: 'Aquí podemos establecer las configuraciones de activación de la campaña: Sin activación, Depósito, Inscripción, Verificación',
    contentAndNotificationSidebarDesc: 'Aquí podemos elegir las bonificaciones configuradas y añadir contenido y notificaciones específicos de las bonificaciones.',
    contentAndNotificationResultAreaDesc: 'Puede ver el Contenido y la Notificación de las bonificaciones después de añadirlas y configurarlas desde el área lateral.',
    configAlreadySaved: 'Si ya tienes configurados los bonos o los participantes, elimínalos para poder editar',
  },
  tr: {
    maxBonusAmount: 'Maksimum Bonus Tutarı',
    minDepositAmount: 'Minimum Para Yatırma Tutarı',
    maxRedeemAmount: 'Maksimum Aktarılacak Tutar',
    minBetAmount: 'Minimum  Bahis Tutarı',
    contributionPercent: 'Katkı %',
    cashbackPercent: 'Cashback  %',
    currencies: 'Para Birimleri',
    triggerPeriod: 'Tetikleme Dönemi',
    activationPeriod: 'Aktivasyon Dönemi',
    playthrough: 'En Başından Oyna',
    wageringPeriod: 'Çevrim Süresi',
    issueBonus: 'Bonus Verme',
    bonusId: 'Bonus ID',
    promoName: 'Promosyon Adı',
    promoCode: 'Promosyon Kodu',
    promoCodeType: 'Promosyon Kodu Türü',
    lPromoCode: 'Promosyon Kodu',
    maxUseCount: 'Maksimum Kullanım Sayısı',
    dateUsed: 'Kullanıldığı Tarih',
    promotionEndDate: 'Promosyon Bitiş Tarihi',
    issuedBy: 'Veren',
    canceledBy: 'İptal Eden',
    mobileVerified: 'Cep Telefonu Onaylandı',
    identityVerified: 'Kimlik Doğrulandı',
    emailVerified: 'E-posta Doğrulandı',
    disconnectedBy: 'Bağlantıyı Kesen',
    disconnectTrigger: 'Tetikleyici Bağlantısını Kes',
    remainingTurnover: 'Kalan çevirim',
    bonusStatus: 'Bonus Durumu',
    triggeredDate: 'Tetiklendiği Tarihi',
    providerMayDisabled: 'Sağlayıcı devre dışı bırakılmış olabilir.',
    gameMayDisabled: 'Oyun devre dışı bırakılmış olabilir.',
    maxCashbackAmount: 'Maksimum Cashback Tutarı',
    cashbackId: 'Cashback ID',
    cashbackName: 'Cashback Adı',
    cashbackType: 'Cashback Türü',
    calculationTime: 'Hesaplama Süresi',
    transferType: 'Transfer Türü',
    platforms: 'Platformlar',
    platform: 'Platform',
    restrictedPlayers: 'Yasaklı Oyuncular',
    restrictedCountries: 'Yasaklı Ülkeler',
    totalBet: 'Toplam Bahis',
    totalWin: 'Toplam Kazanç',
    accumulated: 'Birikmiş',
    accumulatedCashBack: 'Akümülatör Kayıp Bonusu',
    transferredCashBack: 'Aktarılan CashBack',
    commission: 'Komisyon',
    commissions: 'Komisyonlar',
    lCommissions: 'Komisyonlar',
    commissionPercent: 'Komisyon %',
    manageLimits: 'Limitleri Yönet',
    referees: 'Referans Olunanlar',
    setCommissions: 'Komisyonları Ayarla',
    referralBonusId: 'Referans Bonus ID',
    lReferralBonus: 'IReferans bonusu',
    referralBonus: 'Referans Bonusu',
    bonusName: 'Bonus Adı',
    calculationPeriod: 'Hesaplama Dönemi',
    calculationPeriodTime: 'Hesaplama Dönemi Zamanı',
    commissionFrom: 'Komisyon ',
    referrerId: 'Referans ID',
    accumulatedCommission: 'Akümülatör Komisyonu',
    nextCalculationDate: 'Sonraki Hesaplama Tarihi',
    transferredCommission: 'Transfer edilen Komisyon',
    commissioningAmount: 'Alınan Komisyon',
    convertedTo: 'Dönüştürüldü',
    convertedAmount: 'Dönüştürülen Tutar',
    redeemedAmount: 'Aktarılan Tutar',
    winAmount: 'Kazanç Tutarı',
    lossAmount: 'Kayıp Tutarı',
    cashbackAmount: 'Cashback Tutarı',
    includeActiveBonuses: 'Aktif Bonusları Dahil Et',
    lBonus: 'Bonus',
    bonus: 'Bonus',
    casino: 'Casino',
    invalidPlayerCount: 'Geçersiz Oyuncu Sayısı',
    totalPlayersUpdated: 'Güncellenecek toplam oyuncu sayısı',
    lBonusTemplate: 'Bonus şablonu',
    bonusTemplate: 'Bonus Şablonu',
    invalidKeys: 'Geçersiz Anahtarlar',
    playersMissingCurrencyReason: 'Oyuncunun hesap para birimi yapılandırılmamış',
    playersRestrictedReason: 'Oyuncunun hesap durumu yasaklı ',
    missingCurrency: 'Eksik Para Birimi',
    missingCurrencyReason: 'Oyuncunun hesap para birimi yapılandırılmamış',
    issue: 'Oluştur',
    platformType: 'Platform Türü',
    amountConfig: 'Tutar Yapılandırması',
    paymentMethods: 'Ödeme Yöntemleri',
    first: 'Birinci',
    second: 'İkinci',
    third: 'Üçüncü',
    fourth: 'Dördüncü',
    fifth: 'Beşinci',
    fromDepositAmount: 'Yatırılan Tutardan %',
    fromDeposit: 'Yatırılandan %',
    betType: 'Bahis Türü',
    minWageringOdd: 'Min Çevrim Oranı',
    minWageringOddType: 'Min Çevrim Oran Türü',
    bonusTemplateName: 'Bonus Şablon Adı',
    bonusTemplateId: 'Bonus Şablon ID',
    categories: 'Kategoriler',
    bonusOptionsDesc: 'Kaydolma bonusu, yeni bir oyuncuya ödenen paranın toplamıdır.',
    bonusOptionsDesc1: 'Oyuncular tetikleme dönemindeki tüm para yatırma işlemleri için bonus alacaklar',
    bonusOptionsDesc2: 'Kullanıcı, oyuncuların tetikleme döneminde kaç para yatırma işlemi için bonus alacağını seçebilir',
    bonusOptionsDesc3: 'Oyuncular, tetikleme döneminde her gün ilk para yatırma işlemleri için bonus alırlar.',
    createNewTemplate: 'Yeni Şablon Oluştur',
    selectBonusType: 'BONUS TÜRÜ SEÇİN',
    selectBonusTrigger: 'BONUS TETİKLEYİCİSİ SEÇİN',
    numberOfDeposits: 'Para Yatırma Sayısı',
    fixedAmount: 'Sabit Tutar',
    sport: 'Spor',
    rules: 'Kurallar',
    rule: 'Kural',
    noRules: 'Gösterilecek Kural Yok',
    relatedBonusId: 'İlgili Bonus ID',
    endDateTime: 'Bitiş Tarihi/Saati',
    noEndDate: 'Bitiş Tarihi Yok',
    selectGames: 'Oyun Seç',
    activities: 'Faaliyetler',
    discardAccumulatedCommissions: 'Akümülatör komisyonlarını yok say',
    bonusTypeConfig: 'Bonus Türü Yapılandırması',
    playersInAnotherReferral: 'Başka bir Referans Bonusunda yer alan oyuncular',
    allPlayersSelected: 'Tüm oyuncular seçildi',
    datesAndPlayers: 'Tarihler ve Oyuncular',
    special: 'Özel',
    highRisk: 'Yüksek Risk',
    mediumRisk: 'Orta Risk',
    countOfReferees: 'Referans Sayısı',
    playerFromParticipants: 'katılımcılar arasından oyuncu',
    signUp: 'Kayıt ol',
    bonusNameExternal: 'Bonus Adı (Harici)',
    noEndData: 'Bitiş Tarihi Yok',
    minNumberOfSelections: 'Min Seçim Sayısı',
    dateTriggered: 'Tetikleme Tarihi',
    referralBonusName: 'Referans Bonusu Adı',
    refereesId: 'Referans Olunanlar ID',
    refereeId: 'Referans ID',
    realWallet: 'Gerçek Cüzdan',
    details: 'Detaylar',
    numberOfDepositsInfo: 'Tetikleme döneminde oyuncuların bonus alması gereken para yatırma işlemlerini seçin.',
    numberOfDepositsTabIndex: {
      1: 'Para Yatırma Sayısı TabIndex__1',
      2: 'Para Yatırma Sayısı TabIndex__2',
      3: 'Para Yatırma Sayısı TabIndex__3',
      default: 'Para Yatırma Sayısı TabIndex__varsayılan',
    },
    cloneSettingsTo: 'Ayarları Klonla',
    balanceConfig: 'Bakiye Yapılandırması',
    usedCount: 'Kullanılan Sayı',
    lRule: 'kural',
    referralParticipants: 'Referans katılımcıları',
    theCashback: 'Cashback',
    playersCashBack: 'Oyuncuların cashback’leri',
    theBonus: 'Bonus',
    depositAmount: 'Para Yatırma Tutarı',
    wageringTurnover: 'Bahis çevrimi',
    playersWontBeAble: 'Oyuncular, verilen bonus tutarını çekemez veya P2P oyunlarına bahis yapamaz.',
    calculationFrom: 'HESAPLAMA',
    lCalculationFrom: 'Hesaplama',
    createNewCashback: 'Yeni Cashback Oluştur',
    cashbackFromBetsDesc: 'Hesaplama döneminde bahis - kazanç arasındaki pozitif fark üzerinden cashback sağlar.',
    cashbackNetDepositsDesc: 'Hesaplama dönemindeki para yatırma - para çekme arasındaki pozitif fark üzerinden cashback sağlar.',
    netDepositAmount: 'Net Para Yatırma Tutarı',
    participateRule: 'Katılım Kuralı',
    totalDeposit: 'Toplam Para Yatırma',
    totalWithdrawal: 'Toplam Para Çekme',
    totalBonuses: 'Toplam Bonuslar',
    manageParticipation: 'Katılımı Yönet',
    restrictionFor: 'Yasaklanmış',
    availableFor: 'Uygun ',
    sportWageringBonuses: 'Spor Çevrim Bonusları',
    casinoWageringBonuses: 'Casino Çevrim Bonusları',
    otherBonuses: 'Diğer Bonuslar',
    finishedBonusesCount: 'Biten Bonus Sayısı',
    totalRedeemBonuses: 'Toplam Aktarılacak Bonuslar',
    referralCommission: 'Referans Komisyonu',
    maxPossibleTransfer: 'Maksimum Olası Transfer',
    playThroughRequired: ' Baştan Sona Oynama Gerekli',
    matchingRegistrationIP: "Referans olan kişinin eşleşen kayıt IP'lerine sahip referansları var",
    sameRegistrationIP: "Referans olan kişi ve referans aynı kayıt IP'sine sahip",
    expirationPeriod: 'Son kullanma dönemi',
    lengthOfCodes: 'Kod Uzunluğu',
    amountColumnInfo: 'Aralık, sol sütun değerini, sağ sütun değerine kadar içerir ancak bu değeri içermez',
    selectedGames: 'Seçili Oyunlar',
    minAmount: 'Min. Tutar',
    step: 'Adım',
    gamesInfo: 'Sağlayıcı {{type}} oyunları seçmeye izin verir',
    lSingle: 'Tekli',
    lMultiple: 'Kombine',
    allowWithdrawal: 'Para Çekmeye İzin Ver',
    campaignName: 'Kampanya Adı',
    noTrigger: 'Tetikleyici Yok ',
    cancelBonusWhenWithdrawal: 'Para Çekerken Bonusu İptal Et',
    number: 'Sayı ',
    participant: 'Katılımcı',
    depositsAmountConfigInfo: 'Bu alanda, Bonus Tutarını yapılandırır ve para birimine göre limitleri koyarız  ',
    bonusBetConfig: 'Bonus Bahis Yapılandırması',
    single: 'Tekli',
    ranges: 'Aralıklar',
    nextValue: 'Sonraki değer',
    lPrevValue: 'önceki değer',
    addRanges: 'Aralık Ekle',
    lifetimePeriod: 'Ömürboyu Süre  ',
    resetRanges: 'Aralıkları Sıfırla',
    lCampaign: 'kampanya',
    multi: 'Kombine',
    option: 'Seçenek',
    triggers: 'Tetikleyiciler',
    viewRanges: 'Aralıkları Görüntüle',
    depositNumber: 'Para Yatırma Numarası',
    bonusResultAreaDesc: 'Bonusları ekledikten ve yapılandırdıktan sonra yan alandan görebilirsiniz.',
    participantsResultAreaDesc: 'Katılımcıları ekledikten ve yapılandırdıktan sonra yan alandan görebilirsiniz.',
    editTriggerDisabled: 'Artık tetikleyiciyi düzenleyemezsiniz',
    multiOrSingleBonus: 'Kombine ve Tekli Bonus tanımı',
    editParticipantsDisabled: 'Artık katılımcıları düzenleyemezsiniz',
    configureParticipants: 'Katılımcıları yapılandır',
    selectTriggerParticipantsBonuses: 'Tetikleyiciyi Seçin, Katılımcıları Seçin, Bonusları Yapılandırın',
    toMakeSureCampaignSuccessful: 'Kampanyanızın başarılı olması için',
    participantType: 'Katılımcı Türü',
    triggerType: 'Tetikleyici Türü',
    cancelPromotion: 'Promosyonu İptal Et',
    createCampaign: 'Kampanya Oluştur',
    multiOrSinglePromocodeType: 'Kombine ve tekli promosyon kodu türü tanımı',
    missingAmount: 'Eksik Tutar',
    walletType: 'Cüzdan Türü',
    multiSelectionNum: 'Çoklu Seçim Numarası',
    playThroughRequiredDesc: 'Bu seçeneğin açık olması durumunda, Playthrough alan değeri ayarlanan sayıya eşit olmalı, kapalı olması durumunda ise eşit veya daha büyük olmalıdır.',
    playThroughInputDesc: 'Bir oyuncunun bonusu tamamlayabilmesi için bonus yapılandırmasında tanımlanan sayıda bonus bahsi oynaması gerekip gerekmediğini tanımlama sağlar (0 çevirimin dışında )',
    cancelBonusWhenWithdrawalDesc: 'Para Çekiminde Bonusu İptali Tanımı',
    triggerOption: 'Tetikleyici Seçenekleri',
    campaign: 'Kampanya',
    periodDescription: 'Burada para yatırma numarasının hangi dönemde tanımlanacağını ayarlayabiliriz',
    singleCountTypeDesc:
      'Sadece bir bonus ayarlamaya izin verir, ancak her para yatırma numarası için farklı tutarlarda, aynı bonus türüne sahip birkaç bonus yapılandırmak gerekmediği durumlarda kullanılabilir',
    multiCountTypeDesc:
      'Her para yatırma numarası için farklı tutar konfigürasyonlarına sahip farklı bonus türlerinin ayarlanmasına izin verir. Bu durumda, her bonus türü için para yatırma numarasını (olası değerler 1, ..., 100) seçiyoruz',
    disconnectTriggerDesc:
      'Bu fonksiyon etkinleştirilirse ve oyuncu en az bir bonus aldıysa ancak bonus koşullarını karşılamıyorsa, sistem oyuncunun kampanya ile bağlantısını kesecek ve oyuncu artık yeni bonuslar alamayacaktır',
    participantsPlayerDesc: 'CSV yükleyerek veya manuel olarak oyuncuları dahil etmeye izin verir',
    participantsPlayerListAmountsDesc: 'Oyuncu listesini yükleyerek bireysel bonus tutarlarına sahip oyuncuları dahil etmeye izin verir',
    participantsPromoCodeDesc: 'Oluşturulmuş promosyon kodunu kullanarak oyuncuları dahil etmeye izin verir',
    countryConditionDesc: 'Oyuncuların ülkesini kontrol eder',
    verifyConditionDesc: 'Oyuncuların doğrulama seviyesini kontrol eder',
    weekDaysConditionDesc: 'Oyuncuları belirli günlerde kontrol eder',
    accountStatusConditionDesc: 'Oyuncuların hesap durumlarını kontrol eder',
    sportStatusConditionDesc: "Oyuncuların Sportsbook'taki durumlarını kontrol eder",
    segmentConditionDesc: 'Oyuncuların dahil olduğu segmentleri kontrol eder',
    tagConditionDesc: 'Oyuncuların sahip olduğu etiketleri kontrol eder',
    paymentsConditionDesc: 'Oyuncuların ödeme işlemlerini kontrol eder',
    configModeAlreadyOpened: 'Yapılandırma modunu zaten açık',
    bonusBetConfigInfo: 'Bonus bahislerin yapılandırılmasına izin verir. Bahis, bonus bahis olarak kabul edilmek için kayıtlı kriterlerle eşleşmelidir',
    amountConfigSingleDesc: 'Para birimi başına tek tutar yapılandırmasının ayarlanmasına izin ver',
    amountConfigRangesDesc: 'Her para birimi için çok aralıklı tutar yapılandırması ayarlamaya izin ver',
    amountConfigFixedAmountDesc:
      'Her para birimi için sabit bir bonus tutarı sağlamaya izin verir (Para birimine göre). Sabit Tutar durumunda, Tek veya Aralık seçeneklerini kullanarak Para Yatırma koşullarını yapılandırabiliriz',
    amountConfigFromDepositDesc: 'Bonus tutarını para yatırma işleminden yüzde olarak saymaya izin verir (genel tüm para birimleri için )',
    periodType: 'Periyot Türü',
    noDataAlt: 'Tetikleyici yok, Katılımcılar, Bonuslar seçildi',
    successPlayers: 'Oyuncular bonus kazanabilir',
    errorPlayers: 'Eksik Para Birimi',
    amountConfigAreaInfo: 'Bu alanda, Bonus Tutarını yapılandırır ve para birimi başına limit koyarız',
    availableCount: 'Kullanılabilir Sayı',
    availablePromoCodes: 'Kullanılabilir Promosyon Kodları',
    downloadAvailableCodes: 'Kullanılabilir Kodları İndir',
    lNextValue: 'sonraki değer',
    participantsAllPlayersDesc: 'Tetikleyici çalıştırıldığında tüm oyuncuların katılımcı olarak dahil edilmesine izin verir',
    participantsAllPlayersMainAreaDesc: 'Tetikleyici çalıştırıldıktan sonra sistem tüm oyuncuları katılımcı olarak dahil edecektir.',
    campaignStatus: 'Kampanya Durumu',
    freeSpinGamesInfo: 'Oyunlar için sağlayıcı ve kategori seçin',
    freeSpinAmountConfigInfo: 'Tutar sınırlaması için sağlayıcıyı ve satır sayısını seçin',
    freeSpinRequiredAllGames: "Bu sağlayıcı tüm oyunlarda free spin'i destekler",
    maxWinAmount: 'Maksimum Kazanç Tutarı',
    line: 'Satır',
    casinoBonus: 'Casino Bonusu',
    spinsAmount: 'Spin Tutarı',
    maxTriggerCount: 'Maksimum Tetikleme Sayısı',
    maxTriggerCountInfo: 'Bu alan, oyuncu başına maksimum başarılı tetikleme sayısının ayarlanmasına izin verir',
    freeSpinCurrencyInfo: 'Sistem, marka ve seçilen sağlayıcı tarafından desteklenen para birimlerini içerir',
    amountRanges: 'Tutar Aralığı (Tutardan - Tutara)',
    amountRangesHint: "Tutar Aralığı'nın 'Tutardan' alanındaki tutar bu aralığa dahil edilir ancak 'Tutara' alanındaki tutar aralığa dahil edilmez.",
    numberOfPlayers: 'Oyuncu Sayısı (Sayıdan - Sayıya)',
    numberOfPlayersHint: "Oyuncu Sayısı'nın 'Sayıdan' alanındaki sayı bu aralığa dahil edilir ancak 'Sayıya' alanındaki sayı aralığa dahil edilmez.",
    numberOfReferees: 'Referans Sayısı (Sayıdan - Sayıya)',
    numberOfRefereesHint: "Referans Sayısı'nın 'Sayıdan' alanındaki sayı bu aralığa dahil edilir ancak  'Sayıya' alanındaki sayı aralığa dahil edilmez.",
    campaignStatistics: 'Kampanya İstatistikleri',
    maxLimitAmount: 'Maksimum Limit Tutarı',
    netDepositAmountHint: "Net Para Yatırma Tutarı'nın 'Tutardan' alanındaki sayı bu aralığa dahil edilir ancak 'Tutara' alanındaki sayı aralığa dahil edilmez.",
    gameSegmentInfo: 'Oyun segmentindeki değişiklikler önceden oluşturulan öğeyi etkilemez',
    lCashbackAmounts: 'Cashback Tutarı',
    usedPromoCodes: 'Kullanılan Promosyon Kodları',
    createCrossPlatformCampaign: 'Çapraz Platform Kampanyası Oluşturun',
    amountConfigAreaCrossCampaignInfo: 'Bu alanda, para birimi başına limit koyabiliriz',
    selectAndConfigureBonuses: 'Bonusları seçin ve yapılandırın',
    cashbackStatistics: 'CashBack İstatistikleri',
    freeSpinAmountConfigInfoForSingle: 'Tutar sınırlamaları için sağlayıcı, sıra numarası ve oyun seçin',
    minMaxProviderLimit: 'Min-Maks Sağlayıcı Limiti',
    spinTotalAmount: 'Spin Toplam Tutar',
    minMaxProviderLimitInfo: 'Spin sayısı ile spin tutarının çarpımından elde edilen Spin Toplam Tutarı belirtilen limitler arasında olmalıdır',
    maxWageringOddType: 'Maksimum Çevrim Oran Türü',
    maxWageringOdd: 'Maksimum Çevrim Oranı',
    wageringOdd: 'Çevrim Oranı',
    winAmountTransferTo: 'Kazanç Tutarını Transfer Et',
    allowCashout: "Cashout'a İzin Ver",
    allowCashoutInfo: 'Oyuncuların bu bonus için cashout / kısmi cashout işlevlerini kullanmalarına izin verir',
    sidebarBonusesDescription: 'Burada Spor, Spinler, Casino ve Özel bonus türlerini seçebilir ve kampanyaya ekleyebiliriz',
    bonusAbuse: 'Bonus Suistimal',
    oneTimeBet: 'Tek Seferlik Bahis',
    oneTimeBetInfo: 'Oyuncuların bonusu tamamen kullanması gerekir',
    minBetAmountPerCategory: 'Kategori Bazında Minimum Bahis Tutarı',
    periodDescriptionEvery: 'Burada Maksimum Tetikleme Sayısını hangi dönem için tanımlayacağımızı ayarlayabiliriz, eğer yapılandırılmamışsa sistem bir oyuncu için tetiklemeyi sınırlamaz',
    totalBets: 'Toplam Bahisler',
    totalWins: 'Toplam Kazanç',
    subMethod: 'Alt Yöntem',
    paymentConditionPropertiesInfo: 'Alt yöntem menüsü yalnızca toplayıcı tarafından alt yöntemler listesi sağlanan ödeme yöntemleri için görünür.',
    verificationTriggerInfo: 'Seçili tüm seçenekler doğrulanırsa koşul çalışır, tetikleme alanı doğrulaması da buna dahildir',
    promoCodesUploadFormatMsg: 'Promosyon kodlarını yükleyebilirsiniz. Sadece CSV formatında kabul edilir',
    amountConfigDepositSingleDesc: 'Para birimi başına tek bir % yapılandırması ayarlamaya izin verir',
    amountConfigDepositRangesDesc: 'Her para birimi için çok aralıklı % yapılandırması ayarlamaya izin verir',
    canceller: 'İptal Edici',
    awardedBonusesDetails: 'Ödüllendirilmiş Bonus Detayları',
    bonusLowestAmount: 'En Düşük Bonus Tutarı',
    bonusHighestAmount: 'En Yüksek Bonus Tutarı',
    totalBonusAmount: 'Toplam Bonus Tutarı',
    checkTheListOfRecipients: 'Lütfen alıcıların son listesini kontrol edin',
    allGames: 'Tüm Oyunlar',
    allGamesDesc: 'Markanın tüm oyunlarında bonus çekilişi ayarlamaya izin verir, marka oyunlarındaki tüm değişiklikler dikkate alınır',
    selectGamesDesc: 'Belirli sağlayıcılar ve oyunlar için bonus çekilişi ayarlamaya izin verir',
    contribution: 'Katılım, %',
    addPlayersNote: 'Yalnızca yeni katılımcılar eklemek mümkündür. Aksi takdirde, katılımcılar listesinden otomatik olarak çıkarılırlar',
    campaignParticipants: 'Kampanya katılımcıları',
    autoActivation: 'Otomatik Etkinleştirme',
    autoActivationDesc:
      'Önceden etkinleştirilen bonusun oyuncuya verilmesine izin verir. Otomatik etkinleştirme kullanımı durumunda her bonus türünden (Casino, Spor, Free Spin) bir bonus yapılandırılması önerilir. Aksi takdirde, bonuslar rastgele etkinleştirilecektir',
    promoCodeCaseSensitiveInfo: 'Oyuncular için promosyon kodunu yapılandırmaya izin verir. Kodlar oyuncular için büyük/küçük harfe duyarlı değildir ve büyük harf formatına dönüştürülecektir',
    supportPlayerCancellationGamesInfo: 'Sağlayıcı {{type}} türünde oyunların seçilmesine izin verir ve etkinleştirme sonrasında bonusun iptal edilmesine izin vermez',
    referralStatistics: 'Yönlendirme İstatistikleri',
    campaignWillBeSavedInBgInfo: 'Kampanya verileri büyüdükçe, arka plan modunda kaydedilir ve Kampanyalar listesinde bulunur.',
    contentAndNotification: 'İçerik ve Bildirim',
    contentAndNotificationConfigsRemovedDesc: 'Tüm Bonus İçerik ve Bildirim konfigürasyonları kaldırıldı, kaydettikten sonra önceki tüm yapılandırmaları kaybedeceksiniz.',
    cloneContentFrom: 'Bonus içeriğine <0>{{bonusName}}</0> klonla',
    contentLanguages: 'İçerik Dilleri',
    notificationInfo: 'Bu alanda, oyuncular için bonus dağıtımı hakkında bir bildirim yapılandırılır, herhangi bir şablon seçilmezse oyuncular marka iletişim kanalları ile bilgilendirilmez.',
    contentInfo: 'Bu alanda, oyuncular tarafından görülebilecek görselleri ve bonusla ilgili içeriği markanın dilleriyle yapılandırırız. İçerik yapılandırılmazsa, sistem varsayılan içeriği kullanır.',
    editContent: 'İçeriği Düzenle',
    bonusStatistics: 'Bonus İstatistikleri',
    sportBonus: 'Spor Bonusu',
    manageContentAndNotification: 'İçeriği ve Bildirimi Yönet',
    minCashbackAmount: 'Min Cashback Tutarı',
    triggerDeviceTypeDesc: 'Tetikleyicinin cihaz türünü kontrol eder',
    signUpDeviceTypeDesc: 'Oyuncunun kaydedildiği cihaz türünü kontrol eder',
    viewContentAndNotifications: 'İçeriği ve Bildirimleri Görüntüle',
    winType: 'Kazanç Türü',
    winOptions: 'Kazanç Tutarı Türü',
    realMoney: 'Real Money',
    availableBonus: 'Kullanılabilir Bonus',
    wageringBonus: 'Çevrim Bonusu',
    pauseBonus: 'Bonusu Duraklat',
    pauseBonusDesc: 'Seçeneği etkinleştirerek, oyuncu aldığı bonusu duraklatabilir ve daha sonra yeniden başlatabilir',
    pausedDate: 'Duraklatıldığı Tarih',
    resumedDate: 'Yeniden Başlatıldığı Tarih',
    sidebarParticipantsDescription: 'Burada kampanya katılımcılarının seçim koşullarını belirleyebiliriz: Oyuncu listesi, Oyuncu listesi - Tutarlar, Promosyon Kodu, Koşullar',
    contentAndNotificationSidebarNoBonuses: 'Bonusları yapılandırıp kaydettikten sonra İçerik ve Bildirim alanı etkin hale gelecektir',
    sidebarTriggerDescription: 'Burada kampanya tetikleyici konfigürasyonlarını ayarlayabiliriz: Tetikleyici Yok, Para Yatırma, Kaydolma, Doğrulama',
    contentAndNotificationSidebarDesc: 'Burada yapılandırılmış bonusları seçebilir ve bonusa özel içerik ve bildirim ekleyebilirsiniz',
    contentAndNotificationResultAreaDesc: 'Yan alandan ekleyip yapılandırdıktan sonra bonuslar için İçerik ve Bildirimi görebilirsiniz.',
    configAlreadySaved: 'Bonusları veya katılımcıları zaten yapılandırdıysanız, düzenleyebilmek için bunları kaldırın',
  },
  ru: {
    maxBonusAmount: 'Макс. сумма бонуса',
    minDepositAmount: 'Мин. сумма депозита',
    maxRedeemAmount: 'Макс. сумма использования',
    minBetAmount: 'Мин. сумма ставки',
    contributionPercent: 'Взнос (%)',
    cashbackPercent: 'Кэшбэк (%)',
    bonusType: 'Тип бонуса',
    currencies: 'Валюты',
    triggerPeriod: 'Период триггера',
    activationPeriod: 'Период активации',
    playthrough: 'Прохождение',
    wageringPeriod: 'Период вейджеринга',
    issueBonus: 'Выдать бонус',
    bonusId: 'Идентификатор бонуса',
    promoName: 'Название промоакции',
    promoCode: 'Промокод',
    promoCodeType: 'Тип промокода',
    lPromoCode: 'промокод',
    maxUseCount: 'Сколько раз можно использовать (макс.)',
    dateUsed: 'Дата использования',
    promotionEndDate: 'Дата окончания промоакции',
    issuedBy: 'Кем выдан',
    canceledBy: 'Кем отменен',
    mobileVerified: 'Верифицировано для мобильных устройств',
    identityVerified: 'Личность подтверждена',
    emailVerified: 'Адрес эл. почты подтвержден',
    disconnectedBy: 'Кем отключен',
    disconnectTrigger: 'Отключить триггер',
    remainingTurnover: 'Остаток оборота',
    bonusStatus: 'Статус бонуса',
    triggeredDate: 'Дата запуска триггера',
    providerMayDisabled: 'Провайдер может быть отключен.',
    gameMayDisabled: 'Игра может быть отключена.',
    maxCashbackAmount: 'Макс. сумма кэшбэка',
    cashbackId: 'ID кэшбэка',
    cashbackName: 'Название кэшбэка',
    cashbackType: 'Тип кэшбэка',
    calculationTime: 'Время расчета',
    transferType: 'Тип перевода',
    platforms: 'Платформы',
    platform: 'Платформа',
    restrictedPlayers: 'Игроки с ограниченным доступом',
    restrictedCountries: 'Запрещенные страны',
    totalBet: 'Общая ставка',
    totalWin: 'Общий выигрыш',
    accumulated: 'Накоплено',
    accumulatedCashBack: 'Накопленный кэшбэк',
    transferredCashBack: 'Переведенный кэшбэк',
    commission: 'Комиссия',
    commissions: 'Комиссии',
    lCommissions: 'Комиссии',
    commissionPercent: 'Комиссия (%)',
    manageLimits: 'Управление лимитами',
    referees: 'Рефералы',
    setCommissions: 'Установить комиссии',
    referralBonusId: 'ID реферального бонуса',
    lReferralBonus: 'реферальный бонус',
    referralBonus: 'Реферальный бонус',
    bonusName: 'Название бонуса',
    calculationPeriod: 'Период расчета',
    calculationPeriodTime: 'Время периода расчета',
    commissionFrom: 'Комиссия от',
    referrerId: 'ID реферера',
    accumulatedCommission: 'Накопленная комиссия',
    nextCalculationDate: 'Следующая дата расчета',
    transferredCommission: 'Переведенная комиссия',
    commissioningAmount: 'Сумма комиссии',
    convertedTo: 'Конвертировано в',
    convertedAmount: 'Конвертированная сумма',
    redeemedAmount: 'Использованная сумма',
    winAmount: 'Сумма выигрыша',
    lossAmount: 'Сумма проигрыша',
    cashbackAmount: 'Сумма кэшбэка',
    includeActiveBonuses: 'Включить активные бонусы',
    lBonus: 'бонус',
    bonus: 'Бонус',
    casino: 'Казино',
    invalidPlayerCount: 'Неверное количество игроков в отчете',
    totalPlayersUpdated: 'Всего игроков, которые будут обновлены',
    lBonusTemplate: 'шаблон бонуса',
    bonusTemplate: 'Шаблон бонуса',
    invalidKeys: 'Недействительные ключи',
    playersMissingCurrencyReason: 'Валюта счета игрока не задана',
    playersRestrictedReason: 'Игрок ограничил статус учетной записи',
    missingCurrency: 'Валюта не указана',
    missingCurrencyReason: 'Валюта аккаунта игрока не задана',
    issue: 'Выпуск',
    platformType: 'Тип платформы',
    amountConfig: 'Конфигурация учетной записи',
    paymentMethods: 'Методы оплаты',
    first: 'Первый',
    second: 'Второй',
    third: 'Третий',
    fourth: 'Четвертый',
    fifth: 'Пятый',
    fromDepositAmount: '% от суммы депозита',
    fromDeposit: '% от депозита',
    betType: 'Тип ставки',
    minWageringOdd: 'Мин. коэффициент вейджера',
    minWageringOddType: 'Тип мин. коэффициента вейджера',
    bonusTemplateName: 'Название шаблона бонуса',
    bonusTemplateId: 'ID шаблона бонуса',
    categories: 'Категории',
    bonusOptionsDesc: 'Бонус за регистрацию — это сумма, выплачиваемая новому игроку',
    bonusOptionsDesc1: 'Игроки получат бонусы за все депозиты, внесенные в период акции',
    bonusOptionsDesc2: 'Пользователь может выбрать за сколько депозитов, внесенных в период акции, игроки получат бонусы',
    bonusOptionsDesc3: 'Игроки получат бонусы за первый депозит каждого дня в течение акции',
    createNewTemplate: 'Создать новый шаблон',
    selectBonusType: 'ВЫБРАТЬ ТИП БОНУСА',
    selectBonusTrigger: 'ВЫБРАТЬ ТРИГГЕР БОНУСА',
    numberOfDeposits: 'Количество депозитов',
    fixedAmount: 'Фиксированная сумма',
    sport: 'Спорт',
    rules: 'Правила',
    rule: 'Правило',
    noRules: 'Нет правил для показа',
    relatedBonusId: 'ID связанного бонуса',
    endDateTime: 'Дата/время завершения',
    noEndDate: 'Нет даты завершения',
    selectGames: 'Выбрать игры',
    activities: 'Действия',
    discardAccumulatedCommissions: 'Отменить накопленные комиссии',
    bonusTypeConfig: 'Настройка типа бонуса',
    playersInAnotherReferral: 'Игроки уже участвуют в другой Реферальной бонусной программе',
    allPlayersSelected: 'Выбраны все игроки',
    datesAndPlayers: 'Даты и игроки',
    special: 'Эксклюзивный',
    highRisk: 'Высокий риск',
    mediumRisk: 'Средний риск',
    countOfReferees: 'Количество рефералов',
    playerFromParticipants: 'игрок из числа участников',
    signUp: 'Зарегистрироваться',
    bonusNameExternal: 'Название бонуса (Внешнего)',
    noEndData: 'Нет даты завершения',
    minNumberOfSelections: 'Мин. количество выбранных вариантов',
    dateTriggered: 'Дата активации триггера',
    referralBonusName: 'Название реферального бонуса',
    refereesId: 'ID рефералов',
    refereeId: 'ID реферала',
    realWallet: 'Реальный кошелек',
    details: 'Podrobnosti',
    numberOfDepositsInfo: 'Выбрать депозиты, за которые игроки должны получить бонусы в течение периода триггера.',
    numberOfDepositsTabIndex: {
      1: 'ый',
      2: 'ой',
      3: 'ий',
      default: 'ый',
    },
    cloneSettingsTo: 'Перенести настройки в',
    balanceConfig: 'Настройки баланса',
    usedCount: 'Количество использованных',
    lRule: 'правило',
    referralParticipants: 'Участники реферальной программы',
    theCashback: 'Кэшбэк',
    playersCashBack: 'Кэшбэк игроков',
    theBonus: 'Бонус',
    depositAmount: 'Сумма депозита',
    wageringTurnover: 'Оборот вейджера по ставкам',
    playersWontBeAble: 'Игроки не смогут вывести или сделать ставки на P2P-игры на сумму полученного бонуса.',
    calculationFrom: 'РАСЧЕТ ОТ',
    lCalculationFrom: 'Расчет от',
    createNewCashback: 'Создать новый кешбэк',
    cashbackFromBetsDesc: 'Предоставляет кешбэк на положительную разницу между ставкой и выигрышем в расчетном периоде.',
    cashbackNetDepositsDesc: 'Предоставляет кешбэк на положительную разницу между депозитами и выведенными средствами в расчетном периоде.',
    netDepositAmount: 'Чистая сумма депозита',
    participateRule: 'Правило участия',
    totalDeposit: 'Всего депозитов',
    totalWithdrawal: 'Всего выведено средств',
    totalBonuses: 'Всего бонусов',
    manageParticipation: 'Управление участием',
    restrictionFor: 'Ограничено для',
    availableFor: 'Доступно для',
    sportWageringBonuses: 'Спортивные вейджеры',
    casinoWageringBonuses: 'Казино вейджеры',
    otherBonuses: 'Другие бонусы',
    finishedBonusesCount: 'Количество завершенных бонусов',
    totalRedeemBonuses: 'Всего использовано бонусов',
    referralCommission: 'Реферальная комиссия',
    maxPossibleTransfer: 'Макс. возможный перевод',
    playThroughRequired: 'Отыгрыш обязателен',
    matchingRegistrationIP: 'У реферера есть рефералы с одинаковыми регистрационными IP-адресами',
    sameRegistrationIP: 'У реферера и реферала одинаковый регистрационный IP-адрес',
    expirationPeriod: 'Срок истечения',
    lengthOfCodes: 'Длина кодов',
    amountColumnInfo: 'Диапазон включает значение левого столбца, до значения правого столбца, но не включая его',
    selectedGames: 'Выбранные игры',
    minAmount: 'Мин. сумма',
    step: 'Шаг',
    gamesInfo: 'Провайдер позволяет выбрать {{type}} игры',
    lSingle: 'однократный',
    lMultiple: 'множественный',
    allowWithdrawal: 'Разрешить вывод средств',
    campaignName: 'Название кампании',
    noTrigger: 'Без триггера',
    cancelBonusWhenWithdrawal: 'Отменить бонус при выводе средств',
    number: 'Номер',
    participant: 'Участник',
    depositsAmountConfigInfo: 'В этой части мы устанавливаем размер бонуса и ограничения по валютам',
    bonusBetConfig: 'Конфигурации бонусной ставки',
    single: 'Однократный',
    ranges: 'Диапазоны',
    nextValue: 'Следующее значение',
    lPrevValue: 'предыдущее значение',
    addRanges: 'Добавить диапазоны',
    lifetimePeriod: 'Жизненный цикл',
    resetRanges: 'Сбросить диапазоны',
    lCampaign: 'кампания',
    multi: 'Мульти',
    option: 'Опция',
    triggers: 'Триггеры',
    viewRanges: 'Посмотреть диапазоны',
    depositNumber: 'Номер депозита',
    bonusResultAreaDesc: 'Вы можете посмотреть бонусы после их добавления и конфигурации в боковой части.',
    participantsResultAreaDesc: 'Вы можете увидеть Участников после их добавления и настройки в боковой части.',
    editTriggerDisabled: 'Вы не можете отредактировать триггер сейчас',
    multiOrSingleBonus: 'Описание мульти- и однократных бонусов',
    editParticipantsDisabled: 'Вы не можете редактировать участников сейчас',
    configureParticipants: 'Настройка участников',
    selectTriggerParticipantsBonuses: 'Выберите триггер, выберите участников, настройте бонусы',
    toMakeSureCampaignSuccessful: 'Для обеспечения успеха вашей кампании',
    participantType: 'Тип участника',
    triggerType: 'Тип триггера',
    cancelPromotion: 'Отменить акцию',
    createCampaign: 'Создать акцию',
    multiOrSinglePromocodeType: 'Описание типов мульти- и одноразовых промокодов',
    missingAmount: 'Недостающая сумма',
    walletType: 'Тип кошелька',
    multiSelectionNum: 'Номер выбранного мульти-',
    playThroughRequiredDesc: 'Если опция включена, то значение поля «Отыгрыш» должно быть равно заданному числу, если выключена, то должно быть равно или больше',
    playThroughInputDesc: 'Позволяет определить, должен ли игрок отыграть бонус столько раз, сколько определено в конфигурации отыгрыша, чтобы иметь возможность завершить бонус (кроме 0 в обороте)',
    triggerOption: 'Опции триггера',
    campaign: 'Кампания',
    periodDescription: 'Здесь мы можем установить, в каком периоде определять номер депозита',
    singleCountTypeDesc:
      'Позволяет установить только один бонус, но с разными суммами для каждого номера депозита, используется в случаях, когда нам не нужно настраивать несколько бонусов с одинаковыми типами бонусов',
    multiCountTypeDesc:
      'Позволяет устанавливать различные типы бонусов с разными конфигурациями суммы для каждого номера депозита. В данном случае мы выбираем номер депозита (возможные значения 1, ..., 100) для каждого типа бонуса',
    disconnectTriggerDesc:
      'Если эта функция активирована, и игрок получил хотя бы один бонус, но не удовлетворяет условиям бонуса, то система отключит его от кампании, и игрок больше не будет получать новые бонусы',
    participantsPlayerDesc: 'Позволяет включать игроков путем загрузки CSV или вручную',
    participantsPlayerListAmountsDesc: 'Позволяет включить игроков с индивидуальными суммами бонусов, загрузив список игроков',
    participantsPromoCodeDesc: 'Позволяет включать игроков с помощью сгенерированного промокода',
    countryConditionDesc: 'Проверяет страны игроков',
    verifyConditionDesc: 'Проверяет уровень верификации игроков',
    weekDaysConditionDesc: 'Проверяет игроков в определенные дни',
    accountStatusConditionDesc: 'Проверяет состояние счетов игроков',
    sportStatusConditionDesc: 'Проверяет статусы игроков в Спортсбуке',
    segmentConditionDesc: 'Проверяет сегменты, в которые входят игроки',
    tagConditionDesc: 'Проверяет теги игроков',
    paymentsConditionDesc: 'Проверяет транзакционные платежи игроков',
    configModeAlreadyOpened: 'Вы уже открыли режим настройки',
    bonusBetConfigInfo: 'Позволяет настраивать бонусные ставки. Ставка должна соответствовать сохраненным критериям, чтобы считаться бонусной ставкой',
    amountConfigSingleDesc: 'Позволяет устанавливать конфигурацию отдельных сумм для каждой валюты',
    amountConfigRangesDesc: 'Позволяет устанавливать несколько диапазонов сумм для каждой валюты',
    amountConfigFixedAmountDesc:
      'Позволяет предоставить сумму бонуса, фиксированную для каждой валюты (по валюте). В случае фиксированной суммы мы можем настроить условия депозита, используя опции Однократный или Диапазон',
    amountConfigFromDepositDesc: 'Позволяет рассчитывать размер бонуса в процентах от депозита (общий для всех валют)',
    periodType: 'Тип периода',
    noDataAlt: 'Без триггера, Участники, Выбранные бонусы',
    successPlayers: 'Игроки могут получить бонус',
    errorPlayers: 'Отсутствующая валюта',
    amountConfigAreaInfo: 'В этой части мы настраиваем сумму бонуса и устанавливаем ограничения по валютам',
    availableCount: 'Доступное количество',
    availablePromoCodes: 'Доступные промокоды',
    downloadAvailableCodes: 'Скачать доступные коды',
    lNextValue: 'следующее значение',
    participantsAllPlayersDesc: 'Позволяет включить всех игроков в качестве участников при активации триггера',
    participantsAllPlayersMainAreaDesc: 'Система включит всех игроков в число участников после активации триггера.',
    freeSpinGamesInfo: 'Выберите провайдера и категорию, чтобы получить игры',
    freeSpinAmountConfigInfo: 'Выберите провайдера и количество строк, чтобы получить ограничения по сумме',
    freeSpinRequiredAllGames: 'Этот провайдер поддерживает фриспины во всех играх',
    maxWinAmount: 'Макс. сумма выигрыша',
    line: 'Строка',
    casinoBonus: 'Бонус казино',
    campaignStatus: 'Статус кампании',
    spinsAmount: 'Сумма спинов',
    maxTriggerCount: 'Максимальное количество триггеров',
    maxTriggerCountInfo: 'Поле позволяетт установить максимальное количество успешных триггеров на игрока',
    freeSpinCurrencyInfo: 'Система содержит валюты, поддерживаемые брендом и выбранным провайдером',
    amountRanges: 'Диапазон суммы (от-до)',
    amountRangesHint: 'Сумма в поле «От» в «Диапазоне сумм» включена в диапазон, тогда как сумма в поле «До» в диапазон не включена.',
    numberOfPlayers: 'Количество игроков (от-до)',
    numberOfPlayersHint: 'Число в поле «От» в «Количестве игроков» включено в диапазон, тогда как число в поле «До» в диапазон не входит.',
    numberOfReferees: 'Количество рефералов (от-до)',
    numberOfRefereesHint: 'Число в поле «От» в «Количестве рефералов» включено в диапазон, тогда как число в поле «До» в диапазон не входит.',
    campaignStatistics: 'Статистика кампании',
    maxLimitAmount: 'Макс. лимит',
    netDepositAmountHint: 'Число в поле «От» в «Чистая сумма депозита» включено в диапазон, тогда как число в поле «До» в диапазон не включено .',
    gameSegmentInfo: 'Изменения в игровом сегменте не повлияют на созданный ранее субъект',
    lCashbackAmounts: 'суммы кешбэка',
    usedPromoCodes: 'Использованные промокоды',
    createCrossPlatformCampaign: 'Создать кроссплатформенную кампанию',
    amountConfigAreaCrossCampaignInfo: 'В этой области мы устанавливаем ограничения по валюте',
    selectAndConfigureBonuses: 'Выберите и настройте бонусы',
    cashbackStatistics: 'Статистика кешбэка',
    freeSpinAmountConfigInfoForSingle: 'Выберите провайдера, количество строк и игру, чтобы получить ограничения по сумме',
    minMaxProviderLimit: 'Мин.-макс. лимит провайдера',
    spinTotalAmount: 'Общая сумма спинов',
    minMaxProviderLimitInfo: 'Общая сумма спинов, сгенерированная путем умножения количества спинов на сумму спинов, должна быть в рамках указанных пределов',
    maxWageringOddType: 'Тип макс. коэффициента вейджера',
    maxWageringOdd: 'Макс. коэффициент вейджера',
    wageringOdd: 'Коэффициент вейджера',
    winAmountTransferTo: 'Перевести сумму выигрыша',
    allowCashout: 'Разрешить вывод средств',
    allowCashoutInfo: 'Позволяет игрокам использовать функции полного / частичного вывода средств для этого бонуса.',
    sidebarBonusesDescription: 'Здесь мы можем выбрать Спорт, Спины, Казино и специальные доступные типы бонусов и добавить их в кампанию.',
    cancelBonusWhenWithdrawalDesc: 'Позволяет автоматически отменить бонус, если игрок совершит операцию по выводу средств в период активации и истечения срока действия бонуса',
    bonusAbuse: 'Злоупотребление бонусами',
    oneTimeBet: 'Одноразовая ставка',
    oneTimeBetInfo: 'Требует от игроков полного использования бонуса',
    minBetAmountPerCategory: 'Минимальная сумма ставки в каждой категории',
    periodDescriptionEvery:
      'Здесь мы можем установить, в какой период определить максимальное количество триггеров, если оно не настроено, то система не будет ограничивать работу триггеров для игрока',
    totalBets: 'Всего ставок',
    totalWins: 'Всего выигрышей',
    subMethod: 'Подметод',
    paymentConditionPropertiesInfo: 'Меню подметодов отображается только для тех способов оплаты, для которых агрегатор предоставляет список подметодов.',
    verificationTriggerInfo: 'Условие будет работать, если проверены все выбранные параметры, и проверка триггерного поля также включена',
    promoCodesUploadFormatMsg: 'Вы можете загружать промокоды. Допускается только формат CSV',
    amountConfigDepositSingleDesc: 'Позволяет устанавливать единую настройку % для каждой валюты',
    amountConfigDepositRangesDesc: 'Позволяет устанавливать несколько диапазонов % для каждой валюты',
    canceller: 'Отмена',
    awardedBonusesDetails: 'Подробности о начисленных бонусах',
    bonusLowestAmount: 'Наименьшие суммы бонусов',
    bonusHighestAmount: 'Наибольшие суммы бонусов',
    totalBonusAmount: 'Общая сумма бонусов',
    checkTheListOfRecipients: 'Пожалуйста, ознакомьтесь с окончательным списком получателей',
    allGames: 'Все игры',
    allGamesDesc: 'Позволяет установить розыгрыш бонусов на все игры бренда, все изменения в играх бренда будут учтены',
    selectGamesDesc: 'Позволяет установить розыгрыш бонусов на определенных провайдеров и игры',
    contribution: 'Вклад, %',
    addPlayersNote: 'Можно добавлять только новых участников. В обратном случае они будут автоматически исключены из списка участников',
    campaignParticipants: 'Участники кампании',
    autoActivation: 'Автоактивация',
    autoActivationDesc:
      'Позволяет распределить уже активированный бонус между игроками. В случае использования автоактивации рекомендуется настраивать по одному бонусу из каждого типа бонусов (казино, спорт, фриспины). В обратном случае бонусы будут активироваться случайным образом',
    promoCodeCaseSensitiveInfo: 'Позволяет настроить промокод для игроков. При написании кода чувствительность клавиатуры не имеет большого значения, и коды будут преобразованы в заглавные буквы',
    supportPlayerCancellationGamesInfo: 'Провайдер позволяет выбрать {{type}} игры и не позволяет отменить бонус после активации',
    referralStatistics: 'Статистика рефералов',
    campaignWillBeSavedInBgInfo: 'По мере увеличения объема данных кампании они будут сохраняться в фоновом режиме и будут доступны в списке кампаний.',
    contentAndNotification: 'Контент и уведомления',
    contentAndNotificationConfigsRemovedDesc: 'Все конфигурации Бонусного контента и уведомлений были удалены, после сохранения вы потеряете все предыдущие конфигурации.',
    cloneContentFrom: 'Клонировать <0>{{bonusName}}</0> бонусный контент Чтобы',
    contentLanguages: 'Языки контента',
    notificationInfo: 'В этой части мы настраиваем уведомление для игроков о распределении бонусов, если шаблон не выбран, игроки не будут уведомляться с помощью фирменных каналов связи.',
    contentInfo:
      'В этой части мы настраиваем изображения и связанный с бонусами контент на языках бренда, который будет виден игрокам. Если контент не настроен, система будет использовать контент по умолчанию.',
    editContent: 'Редактировать контент',
    bonusStatistics: 'Статистика бонусов',
    sportBonus: 'Спортивный бонус',
    manageContentAndNotification: 'Управление контентом и уведомлениями',
    minCashbackAmount: 'Минимальная сумма кэшбэка',
    triggerDeviceTypeDesc: 'Проверяет тип устройства триггера',
    signUpDeviceTypeDesc: 'Проверяет тип устройства, через которое был зарегистрирован игрок',
    viewContentAndNotifications: 'Просмотр Контента и уведомлений',
    winType: 'Тип выигрыша',
    winOptions: 'Тип суммы выигрыша',
    realMoney: 'Реальные деньги',
    availableBonus: 'Доступный бонус',
    wageringBonus: 'Вейджер',
    pauseBonus: 'Бонус за паузу',
    pauseBonusDesc: 'Включив эту опцию, игрок сможет приостановить получение бонуса, а затем возобновить его',
    pausedDate: 'Дата паузы',
    resumedDate: 'Дата возобновления',
    sidebarParticipantsDescription: 'Здесь мы можем выбрать условия отбора участников кампании: Список игроков, Список игроков - Суммы, Промокод, Условия',
    contentAndNotificationSidebarNoBonuses: 'Часть «Контент и уведомления» станет активной после настройки и сохранения бонусов',
    sidebarTriggerDescription: 'Здесь мы можем задать конфигурацию триггеров кампании: Без триггера, Депозит, Регистрация, Верификация',
    contentAndNotificationSidebarDesc: 'Здесь мы можем выбрать настроенные бонусы, а также добавить контент и уведомления о бонусах',
    contentAndNotificationResultAreaDesc: 'Вы можете увидеть Контент и уведомления для бонусов после их добавления и настройки в боковой области.',
    configAlreadySaved: 'Если у вас уже есть настроенные бонусы или участники, удалите их, чтобы иметь возможность редактировать',
  },
  pt: {
    maxBonusAmount: 'Quantia Máx. do Bónus',
    minBonusAmount: 'Valor Mín. do Depósito',
    maxRedeemAmount: 'Quantia Máx. do Resgate',
    minBetAmount: 'Quantia Mín. da Aposta',
    contributionPercent: 'Contribuição, %',
    cashbackPercent: 'Cashback, %',
    bonusType: 'Tipo de Bónus',
    currencies: 'Moedas',
    triggerPeriod: 'Período de Ativação',
    activationPeriod: 'Período de Ativação',
    playthrough: 'Playthrough',
    wageringPeriod: 'Período de Aposta',
    issueBonus: 'Emitir Bónus',
    bonusId: 'ID do Bónus',
    promoName: 'Nome da Promoção',
    promoCode: 'Código Promocional',
    promoCodeType: 'Tipo de Código Promocional',
    lPromoCode: 'código promocional',
    maxUseCount: 'Contagem Máxima de Uso',
    dateUsed: 'Data de Uso',
    promotionEndDate: 'Data de Término da Promoção',
    issuedBy: 'Emitido por',
    canceledBy: 'Cancelado por',
    mobileVerified: 'Telemóvel Verificado',
    identityVerified: 'Identidade Verificada',
    emailVerified: 'E-mail Verificado',
    disconnectedBy: 'Desconectado por',
    disconnectTrigger: 'Desligar Ativador',
    remainingTurnover: 'Turnover Restante',
    bonusStatus: 'Estado do Bónus',
    triggeredDate: 'Data de Ativação',
    providerMayDisabled: 'O fornecedor pode ter sido desativado.',
    gameMayDisabled: 'O jogo pode ter sido desativado.',
    maxCashbackAmount: 'Quantia Máximo de Cashback',
    cashbackId: 'ID do Cashback',
    cashbackName: 'Nome do Cashback',
    cashbackType: 'Tipo do Cashback',
    calculationTime: 'Tempo de Cálculo',
    transferType: 'Tipo de Transferência',
    platforms: 'Plataformas',
    platform: 'Plataforma',
    restrictedPlayers: 'Jogadores com Restrições',
    restrictedCountries: 'Países com Restrições',
    totalBet: 'Aposta Total',
    totalWin: 'Ganho Total',
    accumulated: 'Acumulado',
    accumulatedCashBack: 'CashBack Acumulado',
    transferredCashBack: 'CashBack Transferido',
    commission: 'Comissão',
    commissions: 'Comissões',
    lCommissions: 'Comissões',
    commissionPercent: 'Comissão, %',
    manageLimits: 'Gerir Limites',
    referees: 'Indicados',
    setCommissions: 'Definir Comissões',
    referralBonusId: 'ID do Bónus de Indicação',
    lReferralBonus: 'bónus de indicação',
    referralBonus: 'Bónus de Indicação',
    bonusName: 'Nome do Bónus',
    calculationPeriod: 'Período de Cálculo',
    calculationPeriodTime: 'Período de Tempo do Cálculo',
    commissionFrom: 'Comissão de',
    referrerId: 'ID de Indicação',
    accumulatedCommission: 'Comissão Acumulada',
    nextCalculationDate: 'Data do Próximo Cálculo',
    transferredCommission: 'Comissão Transferida',
    commissioningAmount: 'Valor de Comissionamento',
    convertedTo: 'Convertido para',
    convertedAmount: 'Valor Convertido',
    redeemedAmount: 'Valor Resgatado',
    winAmount: 'Valor do Ganho',
    lossAmount: 'Valor das Perdas',
    cashbackAmount: 'Valor do Cashback',
    includeActiveBonuses: 'Incluir Bónus Ativos',
    lBonus: 'bónus',
    bonus: 'Bónus',
    casino: 'Casino',
    invalidPlayerCount: 'Contagem de jogadores inválidos no relatório',
    totalPlayersUpdated: 'Total de jogadores que serão atualizados',
    lBonusTemplate: 'modelo de bónus',
    bonusTemplate: 'Modelo de Bónus',
    invalidKeys: 'Keys Inválidas',
    playersMissingCurrencyReason: 'A moeda da conta do jogador não está configurada',
    playersRestrictedReason: 'O jogador tem um estado de conta restrito',
    missingCurrency: 'Moeda em Falta',
    missingCurrencyReason: 'A moeda da conta do jogador não está configurada',
    issue: 'Publicar',
    platformType: 'Tipo de Plataforma',
    amountConfig: 'Configurar Quantia',
    paymentMethods: 'Métodos de Pagamento',
    first: 'Primeiro',
    second: 'Segundo',
    third: 'Terceiro',
    fourth: 'Quarto',
    fifth: 'Quinto',
    fromDepositAmount: '% da Quantia do Depósito',
    fromDeposit: '% do Depósito',
    betType: 'Tipo de Aposta',
    minWageringOdd: 'Odds Mín. de Aposta',
    minWageringOddType: 'Tipo de Odds Mín. de Aposta',
    bonusTemplateName: 'Nome do Modelo de Bónus',
    bonusTemplateId: 'ID do Modelo de Bónus',
    providers: 'Provedores',
    categories: 'Categorias',
    bonusOptionsDesc: 'Um bónus de inscrição é o somatório de dinheiro pago a um novo jogador',
    bonusOptionsDesc1: 'Jogadores receberão bónus por todos os depósitos durante o período de ativação',
    bonusOptionsDesc2: 'O utilizador pode escolher por quantos depósitos durante o período de ativação os jogadores receberão bónus',
    bonusOptionsDesc3: 'Jogadores receberão bónus pelo primeiro depósito de cada dia durante o período de ativação',
    createNewTemplate: 'Criar um Novo Modelo',
    selectBonusType: 'SELECIONE UM TIPO DE BÓNUS',
    selectBonusTrigger: 'SELECIONE UM ATIVADOR DE BÓNUS',
    numberOfDeposits: 'Número de Depósitos',
    fixedAmount: 'Valor Fixo',
    sport: 'Desporto',
    rules: 'Regras',
    rule: 'Regra',
    noRules: 'Sem Regras para Mostrar',
    relatedBonusId: 'ID do Bónus Relacionado',
    endDateTime: 'Data/Hora de Término',
    noEndDate: 'Sem Data de Término',
    selectGames: 'Selecione Jogos',
    activities: 'Atividades',
    discardAccumulatedCommissions: 'Descartar comissões acumuladas',
    bonusTypeConfig: 'Configuração do Tipo de Bónus',
    playersInAnotherReferral: 'Jogadores já possuem outro Bónus de Indicação',
    allPlayersSelected: 'Todos os jogadores são selecionados',
    datesAndPlayers: 'Datas e Jogadores',
    special: 'Especial',
    highRisk: 'Alto Risco',
    mediumRisk: 'Risco Médio',
    countOfReferees: 'Contagem de indicados',
    playerFromParticipants: 'jogador dos participantes',
    signUp: 'Inscreva-se',
    bonusNameExternal: 'Nome do Bónus (Externo)',
    noEndData: 'Sem Data de Término',
    minNumberOfSelections: 'Número Mín. de Seleções',
    dateTriggered: 'Data de Ativação',
    referralBonusName: 'Nome do Bónus de Indicação',
    refereesId: 'ID dos Indicados',
    refereeId: 'ID do Indicado',
    realWallet: 'Carteira Real',
    details: 'Detalhes',
    numberOfDepositsInfo: 'Selecione os depósitos para os quais os jogadores precisam receber bónus durante o período de ativação.',
    numberOfDepositsTabIndex: {
      1: 'º',
      2: 'º',
      3: 'º',
      default: 'º',
    },
    cloneSettingsTo: 'Clonar Configurações para',
    balanceConfig: 'Configuração do Saldo',
    usedCount: 'Contagem Usada',
    lRule: 'regra',
    referralParticipants: 'Participantes na indicação',
    theCashback: 'O cashback',
    playersCashBack: 'Cashback dos jogadores',
    theBonus: 'O bónus',
    depositAmount: 'Valor do Depósito',
    wageringTurnover: 'Turnover das Apostas',
    playersWontBeAble: 'Os jogadores não poderão levantar ou apostar o valor do bónus emitido em jogos P2P.',
    calculationFrom: 'CÁLCULO DE',
    lCalculationFrom: 'Cálculo de',
    createNewCashback: 'Criar Novo Cashback',
    cashbackFromBetsDesc: 'Fornece cashback sobre a diferença positiva de apostas - ganho no período de cálculo.',
    cashbackNetDepositsDesc: 'Fornece cashback sobre a diferença positiva de depósitos - levantamentos no período de cálculo.',
    netDepositAmount: 'Valor Líquido de Depósitos',
    participateRule: 'Regra de Participação',
    totalDeposit: 'Total de Depósito',
    totalWithdrawal: 'Total de Levantamento',
    totalBonuses: 'Total de Bónus',
    manageParticipation: 'Gerir Participação',
    restrictionFor: 'Restrito para',
    availableFor: 'Disponível para',
    sportWageringBonuses: 'Bónus de Apostas Desportivas',
    casinoWageringBonuses: 'Bónus de Apostas de Casino',
    otherBonuses: 'Outros Bónus',
    finishedBonusesCount: 'Contagem de Bónus Finalizados',
    totalRedeemBonuses: 'Total de Bónus de Resgate',
    referralCommission: 'Comissão de Indicação',
    maxPossibleTransfer: 'Transferência Máx. Possível',
    playThroughRequired: 'Playthrough Necessário',
    matchingRegistrationIP: 'O indicador tem indicados com os mesmos IPs de registo',
    sameRegistrationIP: 'Indicador e indicado tem o mesmo IP de registo',
    amountColumnInfo: 'O intervalo inclui o valor da coluna esquerda, até, mas não incluindo, o valor da coluna direita',
    expirationPeriod: 'Período de vencimento',
    lengthOfCodes: 'Comprimento dos Códigos',
    minDepositAmount: 'Quantia Mín. do Depósito',
    selectedGames: 'Jogos Selecionados',
    minAmount: 'Valor Mín.',
    step: 'Passo',
    gamesInfo: 'O fornecedor permite que selecione jogos de {{type}}',
    lSingle: 'simples',
    lMultiple: 'múltiplos',
    allowWithdrawal: 'Permitir Levantamento',
    campaignName: 'Nome da Campanha',
    noTrigger: 'Sem Ativador',
    cancelBonusWhenWithdrawal: 'Cancelar Bónus (Quando feito um Levantamento)',
    number: 'Número',
    participant: 'Participante',
    depositsAmountConfigInfo: 'Nesta área, configuramos o Valor do Bónus e estabelecemos limites por moeda',
    bonusBetConfig: 'Configuração da Aposta de Bónus',
    single: 'Simples',
    ranges: 'Intervalos',
    nextValue: 'Valor seguinte',
    lPrevValue: 'valor anterior',
    addRanges: 'Adicionar Intervalos',
    lifetimePeriod: 'Período Vitalício',
    resetRanges: 'Repor Intervalos',
    lCampaign: 'campanha',
    multi: 'Múltiplos',
    option: 'Opção',
    triggers: 'Ativadores',
    viewRanges: 'Ver Intervalos',
    depositNumber: 'Número do Depósito',
    bonusResultAreaDesc: 'Pode ver os Bónus após adicioná-los e configurá-los na área lateral.',
    participantsResultAreaDesc: 'Pode ver os Participantes após adicioná-los e configurá-los na área lateral.',
    editTriggerDisabled: 'Pode editar o ativador agora',
    multiOrSingleBonus: 'Descrição de bónus múltiplos e simples',
    editParticipantsDisabled: 'Pode editar os participantes agora mesmo',
    configureParticipants: 'Configurar participantes',
    selectTriggerParticipantsBonuses: 'Selecione o Ativador, Escolha os Participantes, Configure os Bónus',
    toMakeSureCampaignSuccessful: 'Para garantir que a sua campanha é bem-sucedida',
    participantType: 'Tipo de Participante',
    triggerType: 'Tipo de Ativador',
    cancelPromotion: 'Cancelar Promoção',
    createCampaign: 'Criar Campanha',
    multiOrSinglePromocodeType: 'Descrição do tipo de código promocional múltiplo e simples',
    missingAmount: 'Valor em Falta',
    walletType: 'Tipo de Carteira',
    multiSelectionNum: 'Núm. de Seleção Múltipla',
    playThroughRequiredDesc: 'Se esta opção estiver activada, o valor do campo “Playthrough” deve ser igual ao número definido; se estiver desactivada, deve ser igual ou superior a',
    playThroughInputDesc:
      'Permite definir se um jogador tem de apostar o bónus tantas vezes quantas as estabelecidas na configuração do playthrough para poder finalizar o bónus (além de 0 no turnover).',
    cancelBonusWhenWithdrawalDesc: 'Permite cancelar o bónus automaticamente caso o jogador faça um levantamento durante o período de ativação e expiração do bónus',
    triggerOption: 'Opção do Ativador',
    campaign: 'Campanha',
    periodDescription: 'Aqui, podemos definir em que período definir o número do depósito',
    singleCountTypeDesc:
      'Permite definir apenas um bónus, mas com valores diferentes para cada número de depósito, utilizável em casos em que não necessitamos de configurar vários bónus com o mesmo tipo de bónus',
    multiCountTypeDesc:
      'Permite definir tipos diferentes de bónus com configurações de valores diferentes para cada número de depósito. Neste caso, escolhemos o número de depósito (valores possíveis 1, ..., 100) para cada tipo de bónus',
    disconnectTriggerDesc:
      'Se esta funcionalidade estiver ativa e o jogador tiver recebido, pelo menos, um bónus, mas não satisfazer as condições associadas ao bónus, então o sistema desconectá-lo-á da campanha, e o jogador já não receberá novos bónus',
    participantsPlayerDesc: 'Permite incluir jogadores manualmente ou ao carregar um ficheiro CSV',
    participantsPlayerListAmountsDesc: 'Permite incluir jogadores com valores individuais de bónus ao carregar a lista de jogadores',
    participantsPromoCodeDesc: 'Permite incluir jogadores ao utilizar um código promocional gerado',
    countryConditionDesc: 'Verifica o país dos jogadores',
    verifyConditionDesc: 'Verifica o nível de verificação dos jogadores',
    weekDaysConditionDesc: 'Verifica os jogadores em dias específicos',
    accountStatusConditionDesc: 'Verifica o estado da conta dos jogadores',
    sportStatusConditionDesc: 'Verifica o estado dos jogadores no Sportsbook',
    segmentConditionDesc: 'Verifica os segmentos que os jogadores integram',
    tagConditionDesc: 'Verifica as tags que os jogadores têm',
    paymentsConditionDesc: 'Verifica os pagamentos das transações dos jogadores',
    configModeAlreadyOpened: 'Já abriu o modo de configuração',
    bonusBetConfigInfo: 'Permite configurar as apostas de bónus. A aposta deverá corresponder aos critérios guardados para ser considerada uma aposta de bónus',
    amountConfigSingleDesc: 'Permitir definir a configuração relativa aos valores únicos por moeda.',
    amountConfigRangesDesc: 'Permitir definir a configuração relativa aos multi-intervalos de valores para cada moeda',
    amountConfigFixedAmountDesc:
      'Permite fornecer um valor de bónus que é fixo para cada moeda (Por moeda). No caso de um Valor Fixo, podemos configurar as condições de Depósito utilizando as opções Único ou Intervalo',
    amountConfigFromDepositDesc: 'Permite contabilizar o valor de bónus como uma percentagem do depósito (geral para todas as moedas)',
    periodType: 'Tipo de período',
    noDataAlt: 'Sem Ativador, Participantes, Bónus selecionados',
    successPlayers: 'Os jogadores podem receber bónus',
    errorPlayers: 'Moeda em Falta',
    amountConfigAreaInfo: 'Nesta área, configuramos o Valor do Bónus e estabelecemos limites por moeda',
    availableCount: 'Contagem Disponível',
    availablePromoCodes: 'Códigos Promocionais Disponíveis',
    downloadAvailableCodes: 'Descarregar os Códigos Disponíveis',
    lNextValue: 'valor seguinte',
    participantsAllPlayersDesc: 'Permite incluir todos os jogadores como participantes quando o ativador é executado',
    participantsAllPlayersMainAreaDesc: 'O sistema incluirá todos os jogadores como participantes após a execução do ativador.',
    freeSpinGamesInfo: 'Selecione provedor e categoria para obter jogos',
    freeSpinAmountConfigInfo: 'Selecione o provedor e o número de linhas para obter restrições de quantidade',
    freeSpinRequiredAllGames: 'Este provedor suporta spins grátis em todos os jogos',
    maxWinAmount: 'Valor Máximo de Ganhos',
    line: 'Linha',
    casinoBonus: 'Bónus de casino',
    campaignStatus: 'Estado da campanha',
    spinsAmount: 'Quantidade de Spins',
    maxTriggerCount: 'Número Máximo de Disparos',
    maxTriggerCountInfo: 'Este campo permitirá definir o número máximo de disparos bem-sucedidos por jogador',
    freeSpinCurrencyInfo: 'O sistema inclui moedas suportadas por marca e provedor escolhido',
    amountRanges: 'Intervalo de valor (de - até)',
    amountRangesHint: "O valor no campo 'De' do 'Intervalo de valor' está incluído nesse intervalo, enquanto o valor no campo 'Até' não está incluído no intervalo.",
    numberOfPlayers: 'Número de jogadores (de - até)',
    numberOfPlayersHint: "O número no campo 'De' do 'Número de jogadores' está incluído nesse intervalo, enquanto o número no campo 'Até' não está incluído no intervalo.",
    numberOfReferees: 'Número de árbitros (de - até)',
    numberOfRefereesHint: "O número no campo 'De' do 'Número de árbitros' está incluído nesse intervalo, enquanto o número no campo 'Até' não está incluído no intervalo.",
    campaignStatistics: 'Estatísticas da campanha',
    maxLimitAmount: 'Limite máximo',
    netDepositAmountHint: "O número no campo 'De' do 'Valor líquido de depósitos' está incluído nesse intervalo, enquanto o número no campo 'Até' não está incluído no intervalo.",
    gameSegmentInfo: 'As alterações no segmento do jogo não afetarão as entidades já criadas',
    lCashbackAmounts: 'Quantias de cashback',
    usedPromoCodes: 'Códigos promocionais usados',
    createCrossPlatformCampaign: 'Criar uma campanha multiplataforma',
    amountConfigAreaCrossCampaignInfo: 'Nesta área, definimos limitações por moeda',
    selectAndConfigureBonuses: 'Selecione e configure bónus',
    cashbackStatistics: 'Estatísticas de cashback',
    freeSpinAmountConfigInfoForSingle: 'Selecione provedor, contagem de linhas e jogo para obter restrições de quantidade',
    minMaxProviderLimit: 'Limite mínimo-máximo do provedor',
    spinTotalAmount: 'Valor total de spins',
    minMaxProviderLimitInfo: 'O valor total de spins gerado a partir da contagem de spins multiplicado pelo valor dos spins deve estar entre os limites mencionados',
    maxWageringOddType: 'Tipo de Odd de Aposta Máxima',
    maxWageringOdd: 'Odds Máxima de Aposta',
    wageringOdd: 'Odds de Aposta',
    winAmountTransferTo: 'Transferência do valor do prêmio para',
    allowCashout: 'Permitir Cashout',
    allowCashoutInfo: 'Permite que os jogadores usem as funcionalidades de levantamento/levantamento parcial para este bónus',
    sidebarBonusesDescription: 'Aqui podemos escolher os tipos de bónus Despotivo, Giros, Casino e Especial disponíveis e adicionar à campanha',
    bonusAbuse: 'Abuso de Bónus',
    oneTimeBet: 'Aposta Única',
    oneTimeBetInfo: 'Exige que os jogadores utilizem o bónus na totalidade',
    minBetAmountPerCategory: 'Valor Mín. de Aposta por categoria',
    periodDescriptionEvery: 'Aqui podemos definir em que período definir a contagem máxima de disparos, se não for configurado o sistema não limitará o trabalho de disparo para um jogador',
    totalBets: 'Total de Apostas',
    totalWins: 'Total de Ganhos',
    subMethod: 'Submétodo',
    paymentConditionPropertiesInfo: 'O menu “Submétodos” só é visível para os métodos de pagamento para os quais a lista de submétodos é fornecida pelo agregador.',
    verificationTriggerInfo: 'A condição funcionará se todas as opções seleccionadas forem verificadas, a verificação do campo de ativação também está incluída',
    promoCodesUploadFormatMsg: 'Você pode enviar códigos promocionais. Apenas o formato CSV é aceito',
    amountConfigDepositSingleDesc: 'Permite configurar % simples por moeda',
    amountConfigDepositRangesDesc: 'Permite configurar interválos múltiplos de % para cada moeda',
    canceller: 'Cancelador',
    awardedBonusesDetails: 'Detalhes dos Bônus Concedidos',
    bonusLowestAmount: 'Valores de Bónus Mais Baixo',
    bonusHighestAmount: 'Valores de Bónus Mais Altos',
    totalBonusAmount: 'Valor Total de Bónus',
    checkTheListOfRecipients: 'Verifique a lista final de recipientes',
    allGames: 'Todos os Jogos',
    allGamesDesc: 'Permite definir sorteio de bónus na marca de todos os jogos, todas as alterações nas marcas de jogos serão consideradas',
    selectGamesDesc: 'Permite definir sorteio de bónus em provedores e jogos específicos',
    contribution: 'Contribuição, %',
    addPlayersNote: 'Só é possível adicionar novos participantes. Caso contrário, serão automaticamente excluídos da lista de participantes',
    campaignParticipants: 'Campanha de participantes',
    autoActivation: 'Autoativação',
    autoActivationDesc:
      'Permite atribuir ao jogador o bónus já ativado. Recomenda-se a configuração de um bónus de cada tipo de bónus (Casino, Desporto, Free Spin) em caso de utilização da auto-ativação. Caso contrário, os bónus serão activados aleatoriamente',
    promoCodeCaseSensitiveInfo:
      'Permite configurar o código promocional para os jogadores. Os códigos não são sensível à caixa das letras para os jogadores e serão convertidos para o formato maiúsculo',
    supportPlayerCancellationGamesInfo: 'Provedor permite selecionar jogos {{type}} e não permite cancelar o bónus após ativação ',
    referralStatistics: 'Estatísticas de Indicação',
    campaignWillBeSavedInBgInfo: 'À medida que os dados da campanha aumentam, são guardados em modo de fundo e ficam disponíveis na lista de campanhas.',
    contentAndNotification: 'Conteúdo e Notificação',
    contentAndNotificationConfigsRemovedDesc: 'Todas as configurações de conteúdo e notificação de bónus foram removidas, após guardar perderá todas as configurações anteriores.',
    cloneContentFrom: 'Clonar o conteúdo do bónus <0>{{bonusName}}</0> para',
    contentLanguages: 'Idiomas do conteúdo',
    notificationInfo:
      'Nesta área, configuramos uma notificação para os jogadores sobre a atribuição de bónus. Se não for selecionado nenhum modelo, os jogadores não serão notificados através dos canais de comunicação da marca.',
    contentInfo:
      'Nesta área, configuramos imagens e conteúdos relacionados com bónus nos idiomas da marca, que serão visíveis para os jogadores. Se o conteúdo não estiver configurado, o sistema utilizará o conteúdo predefinido.',
    editContent: 'Editar conteúdo',
    bonusStatistics: 'Estatísticas de bónus',
    sportBonus: 'Bónus desportivo',
    manageContentAndNotification: 'Gerir conteúdos e notificações',
    minCashbackAmount: 'Valor mínimo de cashback',
    triggerDeviceTypeDesc: 'Verifica o tipo de dispositivo do ativador',
    signUpDeviceTypeDesc: 'Verifica o tipo de dispositivo através do qual o jogador foi registado',
    viewContentAndNotifications: 'Ver Conteúdo e Notificações',
    winType: 'Tipo de vitória',
    winOptions: 'Tipo de Valor do Prémio',
    realMoney: 'Dinheiro real',
    availableBonus: 'Bónus disponível',
    wageringBonus: 'Bónus de Aposta',
    pauseBonus: 'Bónus de Pausa',
    pauseBonusDesc: 'Ao ativar a opção, o jogador poderá fazer uma pausa no bónus recebido e retomá-lo mais tarde',
    pausedDate: 'Data de pausa',
    resumedDate: 'Data de retomada',
    sidebarParticipantsDescription: 'Aqui, podemos escolher as condições de seleção dos participantes da campanha: Lista de jogadores, Lista de jogadores – Valores, Código Promocional, Condições',
    contentAndNotificationSidebarNoBonuses: 'A área de conteúdo e notificação ficará ativa depois de configurar e guardar os bónus',
    sidebarTriggerDescription: 'Aqui, podemos definir as configurações de ativação da campanha: Sem Ativador, Depósito, Registo, Verificar',
    contentAndNotificationSidebarDesc: 'Aqui podemos escolher os bónus configurados e adicionar conteúdo e notificação específicos do bónus',
    contentAndNotificationResultAreaDesc: 'Pode ver o conteúdo e a notificação dos bónus depois de os adicionar e configurar a partir da área lateral.',
    configAlreadySaved: 'Já configurou os bónus ou participantes. Remova-os para poder editar',
  },
  zh: {
    maxBonusAmount: '最高奖金数额',
    minBonusAmount: '最低存款金额',
    maxRedeemAmount: '最大赎回金额',
    minBetAmount: '最低投注金额',
    contributionPercent: '贡献，%',
    cashbackPercent: '现金返还，%',
    bonusType: '奖金类型',
    currencies: '货币',
    triggerPeriod: '触发期间',
    activationPeriod: '激活期限',
    playthrough: '游戏周目',
    wageringPeriod: '投注期间',
    issueBonus: '发放奖金',
    bonusId: '奖金ID',
    promoName: '促销名称',
    promoCode: '促销代码',
    promoCodeType: '促销代码类型',
    lPromoCode: '促销代码',
    maxUseCount: '最高使用次数',
    dateUsed: '使用的日期',
    promotionEndDate: '促销结束日期',
    issuedBy: '发布单位',
    canceledBy: '取消者',
    mobileVerified: '手机被验证了',
    identityVerified: '身份被验证了',
    emailVerified: '电子邮件被验证了',
    disconnectedBy: '谁断开了连接',
    disconnectTrigger: '断开触发器',
    remainingTurnover: '剩余的营业额',
    bonusStatus: '奖金状态',
    triggeredDate: '触发的日期',
    providerMayDisabled: '供应商可能已被禁用。',
    gameMayDisabled: '游戏可能已被禁用。',
    maxCashbackAmount: '最高返现金额',
    cashbackId: '现金返还ID',
    cashbackName: '现金返还名称',
    cashbackType: '现金返还类型',
    calculationTime: '计算时间',
    transferType: '转账类型',
    platforms: '平台',
    platform: '平台',
    restrictedPlayers: '受限制的玩家',
    restrictedCountries: '受限制的国家',
    totalBet: '总赌注',
    totalWin: '总胜利',
    accumulated: '累计的',
    accumulatedCashBack: '累计现金返还',
    transferredCashBack: '已转账的现金返还',
    commission: '佣金',
    commissions: '佣金',
    lCommissions: '佣金',
    commissionPercent: '佣金，%',
    manageLimits: '限制管理',
    referees: '推荐人',
    setCommissions: '设定的佣金',
    referralBonusId: '推荐奖金ID',
    lReferralBonus: '推荐奖金',
    referralBonus: '推荐奖金',
    bonusName: '奖金名称',
    calculationPeriod: '计算周期',
    calculationPeriodTime: '计算周期时间',
    commissionFrom: '佣金来自',
    referrerId: '推荐人ID',
    accumulatedCommission: '累计佣金',
    nextCalculationDate: '下一计算计算日期',
    transferredCommission: '转账的佣金',
    commissioningAmount: '委托金额',
    convertedTo: '转换至',
    convertedAmount: '转换金额',
    redeemedAmount: '赎回金额',
    winAmount: '胜利金额',
    lossAmount: '损失金额',
    cashbackAmount: '返现总额',
    includeActiveBonuses: '包含活跃奖金',
    lBonus: '奖金',
    bonus: '奖金',
    casino: '娱乐场',
    invalidPlayerCount: '报告中的玩家计数无效',
    totalPlayersUpdated: '将被更新的玩家总数',
    lBonusTemplate: '奖金模板',
    bonusTemplate: '奖金模板',
    invalidKeys: '无效密钥',
    playersMissingCurrencyReason: '玩家的账户货币没有配置好',
    playersRestrictedReason: '该玩家已限制账户状态',
    missingCurrency: '找不到货币',
    missingCurrencyReason: '玩家的账户货币没有配置好',
    issue: '发行',
    platformType: '平台类型',
    amountConfig: '金额配置',
    paymentMethods: '付款方式',
    first: '第一',
    second: '第二',
    third: '第三',
    fourth: '第四',
    fifth: '第五',
    fromDepositAmount: '存款金额的%',
    fromDeposit: '存款的%',
    betType: '投注类型',
    minWageringOdd: '最低投注赔率',
    minWageringOddType: '最小投注赔率类型',
    bonusTemplateName: '奖金模板名称',
    bonusTemplateId: '奖金模板ID',
    providers: '供应商',
    categories: '类别',
    bonusOptionsDesc: '注册奖金是支付给新玩家的一笔钱',
    bonusOptionsDesc1: '玩家在触发期内的所有存款都将获得奖金',
    bonusOptionsDesc2: '用户可以选择在触发期的存款金额数，以获得奖金',
    bonusOptionsDesc3: '玩家将获得触发期内每天第一次存款的奖金',
    createNewTemplate: '创建新模板',
    selectBonusType: '选择奖金类型',
    selectBonusTrigger: '选择奖金触发器',
    numberOfDeposits: '存款数量',
    fixedAmount: '固定金额',
    sport: '体育项目',
    rules: '规则',
    rule: '规则',
    noRules: '无可展示规则',
    relatedBonusId: '相关奖金ID',
    endDateTime: '结束日期/时间',
    noEndDate: '无结束日期',
    selectGames: '选择游戏',
    activities: '活动',
    discardAccumulatedCommissions: '放弃累计的佣金',
    bonusTypeConfig: '奖金类型的配置',
    playersInAnotherReferral: '已经在另一个推荐奖金中的玩家',
    allPlayersSelected: '全部玩家被选中',
    datesAndPlayers: '日期和玩家',
    special: '特殊',
    highRisk: '高风险',
    mediumRisk: '中度风险',
    countOfReferees: '推荐人数量',
    playerFromParticipants: '参与者中的球员',
    signUp: '注册',
    bonusNameExternal: '奖金名称（外部）',
    noEndData: '无结束日期',
    minNumberOfSelections: '最低选择数量',
    dateTriggered: '触发日期',
    referralBonusName: '推荐奖金名称',
    refereesId: '推荐人ID',
    refereeId: '推荐人ID',
    realWallet: '真实钱包',
    details: '详情',
    numberOfDepositsInfo: '选择玩家在触发期内必须获得奖金的存款。',
    numberOfDepositsTabIndex: {
      1: '存款数量 TabIndex__1',
      2: '存款数量 TabIndex__2',
      3: '存款数量 TabIndex__3',
      default: '第存款数量 TabIndex__默认',
    },
    cloneSettingsTo: '复制设置至',
    balanceConfig: '余额配置',
    usedCount: '已使用次数',
    lRule: '规则',
    referralParticipants: '推荐的参与者',
    theCashback: '现金返还',
    playersCashBack: '玩家现金返还',
    theBonus: '奖金',
    depositAmount: '存款金额',
    wageringTurnover: '赌注营业额',
    playersWontBeAble: '玩家将不能提取或投注P2P游戏所发放的奖金数额。',
    calculationFrom: '计算来自',
    lCalculationFrom: '计算来自',
    createNewCashback: '创建新的现金返还',
    cashbackFromBetsDesc: '在计算期内对投注—获胜情况下的正数差额提供现金返还。',
    cashbackNetDepositsDesc: '在计算期内对存款—取款的正数差额提供现金返还。',
    netDepositAmount: '净存款金额',
    participateRule: '参加规则',
    totalDeposit: '总存款',
    totalWithdrawal: '总取款',
    totalBonuses: '奖金总额',
    manageParticipation: '管理参与',
    restrictionFor: '受到限制',
    availableFor: '可用于',
    sportWageringBonuses: '体育投注奖金',
    casinoWageringBonuses: '娱乐场投注奖金',
    otherBonuses: '其他奖金',
    finishedBonusesCount: '已完成奖金计数',
    totalRedeemBonuses: '赎回总奖金',
    referralCommission: '推荐人佣金',
    maxPossibleTransfer: '最大潜在转账',
    playThroughRequired: ' 需进行游戏周目',
    matchingRegistrationIP: '推荐人有被推荐人与之匹配的注册IP',
    sameRegistrationIP: '推荐人和被推荐人有相同的注册IP',
    amountColumnInfo: '该范围包括左列值，一直到但不包括右列值',
    expirationPeriod: '投注期',
    lengthOfCodes: '代码长度',
    minDepositAmount: '最低存款金额',
    selectedGames: '选的游戏',
    minAmount: '最低金额',
    step: '步',
    gamesInfo: '供应商允许选择{{type}}游戏',
    lSingle: '单',
    lMultiple: '多重',
    allowWithdrawal: '允许提取',
    campaignName: '广告系列名称',
    noTrigger: '没有触发',
    cancelBonusWhenWithdrawal: '取款时取消奖金',
    number: '数量',
    participant: '参与者',
    depositsAmountConfigInfo: '在这个区域可以配置奖金数额并设置每种货币的限制。',
    bonusBetConfig: '奖金投注配置',
    single: '单',
    ranges: '范围',
    nextValue: '下一个值',
    lPrevValue: '以前的值',
    addRanges: '增加量程',
    lifetimePeriod: '终身使用期',
    resetRanges: '重置范围',
    lCampaign: ' 广告系列',
    multi: ' 多重',
    option: '选项',
    triggers: '触发',
    viewRanges: '查看范围',
    depositNumber: '存款编号',
    participantsAllPlayersDesc: '允许在执行触发器时将所有玩家作为参与者。',
    participantsAllPlayersMainAreaDesc: '执行触发后，系统会将所有玩家都列为参与者。',
    bonusResultAreaDesc: '您可以从侧面区域看到添加和配置后的奖金。',
    participantsResultAreaDesc: '添加和配置参与者后，您可以从侧面区域看到他们。',
    editTriggerDisabled: '您现在无法编辑触发器',
    multiOrSingleBonus: '多项和单项奖金说明',
    editParticipantsDisabled: '您现在无法编辑参与者',
    configureParticipants: '配置参与者',
    selectTriggerParticipantsBonuses: '选择触发器，选择参与者，配置奖金',
    toMakeSureCampaignSuccessful: '确保您的活动成功',
    participantType: '参与者类型',
    triggerType: '触发类型',
    cancelPromotion: '取消促销',
    createCampaign: '创建活动',
    multiOrSinglePromocodeType: '多重和单一促销代码类型描述',
    missingAmount: '缺少金额',
    walletType: '钱包类型',
    multiSelectionNum: '多选数字',
    playThroughRequiredDesc: '如果这个选项被打开，那么游戏周目字段的值应该等于设定的数字，如果它被关闭，它应该等于或大于这个数字。',
    playThroughInputDesc: '允许定义玩家是否必须按照游戏周目配置中定义的次数下注奖金才能完成奖金（除了营业额中的0）',
    cancelBonusWhenWithdrawalDesc: '取款时取消奖金说明',
    triggerOption: '触发器选项',
    campaign: '活动',
    periodDescription: '这里可以设置在哪个时期定义存款的数量',
    singleCountTypeDesc: '允许只设置一个奖金，但每个存款号码有不同的金额，当我们不需要配置相同奖金类型的几个奖金时，可以使用。',
    multiCountTypeDesc: '允许设置不同类型的奖金，对每笔存款的数量进行不同的配置。在这种情况下，我们为每个奖金类型选择存款数量（可能的值为1，...，100）。',
    disconnectTriggerDesc: '如果这个功能被激活，并且玩家获得了至少一个奖金，但不满足奖金条件，那么系统将把他从活动中断开，并且玩家将不再获得新的奖金。',
    participantsPlayerDesc: '允许通过上传CSV或手动方式加入球员',
    participantsPlayerListAmountsDesc: '允许通过上传球员名单来包括具有个人奖金数额的球员。',
    participantsPromoCodeDesc: '允许通过使用生成的促销代码将玩家包括在内',
    countryConditionDesc: '检查玩家的国家/地区',
    verifyConditionDesc: 'Checks players’ verification level',
    weekDaysConditionDesc: '在特定的日子里检查球员的',
    accountStatusConditionDesc: '检查玩家的账户状态',
    sportStatusConditionDesc: '检查玩家在体育博彩上的状态',
    segmentConditionDesc: '检查玩家所处的段位',
    tagConditionDesc: '检查玩家拥有的标签',
    paymentsConditionDesc: '检查球员的交易付款',
    configModeAlreadyOpened: '您已打开配置模式',
    bonusBetConfigInfo: '允许配置奖金投注。赌注应符合保存的标准，才能被视为奖金赌注',
    amountConfigSingleDesc: '允许设置每种货币的单一金额配置',
    amountConfigRangesDesc: '允许为每种货币设置多范围金额配置',
    amountConfigFixedAmountDesc: '允许提供对每种货币固定的奖金数额（按货币）。在固定金额的情况下，我们可以使用单个或范围选项配置存款条件',
    amountConfigFromDepositDesc: '允许以存款的百分比来计算奖金数额（所有货币都适用）。',
    periodType: '期间类型',
    noDataAlt: '没有选择触发器、参与者、奖金',
    successPlayers: '玩家可以获得奖金',
    errorPlayers: '缺少货币',
    amountConfigAreaInfo: '在该区域里可以配置奖金金额并设置每种货币的限制',
    availableCount: '可用数',
    availablePromoCodes: '可用的促销代码',
    downloadAvailableCodes: '下载可用代码',
    lNextValue: '下一个值',
    freeSpinGamesInfo: '选择提供商和类别以获取游戏',
    freeSpinAmountConfigInfo: '选择提供商和行数以获取金额限制',
    freeSpinRequiredAllGames: '该供应商支持所有游戏的免费旋转',
    maxWinAmount: '最大赢利金额',
    line: '线路',
    casinoBonus: '娱乐场奖金',
    campaignStatus: '活动状态',
    spinsAmount: '转盘数量',
    maxTriggerCount: '最大触发器计数',
    maxTriggerCountInfo: '此字段允许设置每个玩家的最大成功触发次数',
    freeSpinCurrencyInfo: '该系统包括由品牌和所选供应商支持的货币',
    amountRanges: '金额范围(从-到)',
    amountRangesHint: '金额范围 "的 "自 "字段中的金额包括在该范围内，而 "至 "字段中的金额则不包括在该范围内。',
    numberOfPlayers: '玩家数量（从-到）',
    numberOfPlayersHint: '球员人数 "的 "从 "字段中的数字包括在该范围内，而 "到 "字段中的数字则不包括在该范围内。',
    numberOfReferees: '推荐人数(从-到)。',
    numberOfRefereesHint: '推荐人数 "的 "从 "字段中的数字包括在该范围内，而 "到 "字段中的数字则不包括在该范围内。',
    campaignStatistics: '活动统计',
    maxLimitAmount: '最高限额金额',
    netDepositAmountHint: '存款净额 "的 "自 "字段中的数字包括在该范围内，而 "至 "字段中的数字则不包括在该范围内。',
    gameSegmentInfo: '游戏分段中的更改不会影响已创建的实体',
    lCashbackAmounts: '现金返还金额',
    usedPromoCodes: '使用的促销代码',
    createCrossPlatformCampaign: '创建跨平台活动',
    amountConfigAreaCrossCampaignInfo: '这个领域里可以为每种货币设定了限制',
    selectAndConfigureBonuses: '选择和配置奖金',
    cashbackStatistics: '现金返还统计',
    freeSpinAmountConfigInfoForSingle: '选择提供商、行数和游戏以获得金额限制',
    minMaxProviderLimit: '最小-最大提供程序限制',
    spinTotalAmount: '旋转总金额',
    minMaxProviderLimitInfo: '由转盘数量乘以转盘金额产生的转盘总金额应在上述限额之间。',
    maxWageringOddType: '最大赌注的奇数类型',
    maxWageringOdd: '最大赌注奇数',
    wageringOdd: '投注赔率',
    winAmountTransferTo: '赢取金额转至',
    allowCashout: '允许提现',
    allowCashoutInfo: '允许玩家对该奖金使用提现/部分提现功能',
    bonusAbuse: '奖金滥用',
    oneTimeBet: '一次投注',
    oneTimeBetInfo: '要求玩家充分使用奖金',
    minBetAmountPerCategory: '每个类别的最小投注额',
    periodDescriptionEvery: '在这里，我们可以设置在哪个时段定义最大触发次数，如果没有设置，系统将不会限制玩家的触发次数。',
    totalBets: '总投注额',
    totalWins: '总获胜',
    subMethod: '子方法',
    paymentConditionPropertiesInfo: '子方法菜单仅适用于聚合器提供子方法列表的支付方法。',
    verificationTriggerInfo: '如果所有选定选项都已验证，则条件将起作用，触发器字段验证也包括在内',
    promoCodesUploadFormatMsg: '您可以上传促销代码。只接受CSV格式',
    amountConfigDepositSingleDesc: '允许为每种货币设置单一的%配置',
    amountConfigDepositRangesDesc: '允许为每种货币设置多范围%配置',
    canceller: '取消方',
    awardedBonusesDetails: '已获奖金详情',
    bonusLowestAmount: '最低奖金额',
    bonusHighestAmount: '最高奖金额',
    totalBonusAmount: '奖金总额',
    checkTheListOfRecipients: '请查看最终获奖者名单',
    allGames: '所有游戏',
    allGamesDesc: '允许在品牌的所有游戏上设置奖金抽奖，品牌游戏上的所有更改都将被考虑在内',
    selectGamesDesc: '允许在特定提供商和游戏上设置奖金抽奖',
    contribution: '贡献，%',
    addPlayersNote: '可以只添加新参与者。否则，他们将自动从参与者列表中排除',
    campaignParticipants: '活动参与者',
    autoActivation: '自动激活',
    autoActivationDesc: '允许将已激活的奖金分配给玩家。如果使用自动激活功能，建议从每种奖金类型（娱乐场、体育、免费旋转）中配置一种奖金。否则，奖金将随机激活。',
    promoCodeCaseSensitiveInfo: '允许为玩家配置促销代码。这些代码对玩家不敏感，会被转换为大写格式',
    supportPlayerCancellationGamesInfo: '提供商允许选择 {{type}} 游戏，但不允许在激活后取消奖励',
    referralStatistics: '推荐统计',
    campaignWillBeSavedInBgInfo: '活动数据越大，将在后台模式下保存，并在活动列表中提供。',
    contentAndNotification: '内容和通知',
    contentAndNotificationConfigsRemovedDesc: '删除了所有奖金内容和通知配置，保存后将丢失之前的所有配置。',
    cloneContentFrom: '克隆 <0>{{bonusName}}</0> 奖金内容到',
    contentLanguages: '内容语言',
    notificationInfo: '在此区域，我们为玩家配置了奖金分配通知，如果没有选择模板，玩家将不会收到品牌传播渠道的通知。',
    contentInfo: '在这一区域，我们用品牌语言配置图片和奖金相关内容，玩家将看到这些内容。如果未配置内容，系统将使用默认内容。',
    editContent: '内容编辑',
    bonusStatistics: '奖金统计',
    sportBonus: '体育奖金',
    manageContentAndNotification: '管理内容和通知',
    minCashbackAmount: '最小返现金额',
    triggerDeviceTypeDesc: '检查触发器的设备类型',
    signUpDeviceTypeDesc: '检查注册播放器的设备类型',
    viewContentAndNotifications: '查看内容和通知',
    winType: '赢的类型',
    winOptions: '赢得金额类型',
    realMoney: '真钱',
    availableBonus: '可用奖金',
    wageringBonus: '投注奖金',
    pauseBonus: '暂停奖金',
    pauseBonusDesc: '启用该选项后，玩家可以暂停已收到的奖金，稍后再继续',
    pausedDate: '暂停日期',
    resumedDate: '恢复继续日期',
    sidebarParticipantsDescription: '这里可以选择活动参与者的选择条件： 玩家名单，玩家名单-金额，促销代码，条件',
    contentAndNotificationSidebarNoBonuses: '配置和保存奖金后，“内容和通知”区域将处于活跃状态',
    sidebarTriggerDescription: '这里可以设置活动触发器配置：无触发器、存款、注册、验证',
    contentAndNotificationSidebarDesc: '在这里，我们可以选择已配置的奖金，并添加奖金的具体内容和通知',
    contentAndNotificationResultAreaDesc: '在侧边区域添加和配置奖金后，您可以看到奖金的内容和通知。',
    configAlreadySaved: '您已经配置了奖金或参与者，请删除它们以便进行编辑',
  },
  ro: {
    maxBonusAmount: 'Suma maximă de bonus',
    maxRedeemAmount: 'Valoarea maximă de rambursare',
    minBetAmount: 'Suma minimă de pariu',
    contributionPercent: 'Contribuție, %',
    cashbackPercent: 'Cashback, %',
    bonusType: 'Tip de bonus',
    currencies: 'Valute',
    triggerPeriod: 'Perioadă de declanșare',
    activationPeriod: 'Perioadă de activare',
    playthrough: 'Rulaj',
    wageringPeriod: 'Perioadă de pariere',
    issueBonus: 'Emite bonusul',
    bonusId: 'ID de bonus',
    promoName: 'Numele promoției',
    promoCode: 'Cod promoțional',
    promoCodeType: 'Tip de cod promoțional',
    lPromoCode: 'cod promoțional',
    maxUseCount: 'Numărul maxim de utilizare',
    dateUsed: 'Data de utilizare',
    promotionEndDate: 'Data de încheiere a promoției',
    issuedBy: 'Acordat de către',
    canceledBy: 'Anulat de către',
    mobileVerified: 'Telefon mobil verificat',
    identityVerified: 'Identitate verificată',
    emailVerified: 'E-mail verifiat',
    disconnectedBy: 'Deconectat de către',
    disconnectTrigger: 'Deconecta declanșatorul',
    remainingTurnover: 'Cifra rămasă de rulat',
    bonusStatus: 'Stare de bonus',
    triggeredDate: 'Data de declanșare',
    providerMayDisabled: 'Furnizorul poate fi dezactivat.',
    gameMayDisabled: 'Jocul poate fi dezactivat.',
    maxCashbackAmount: 'Suma maximă a Cashback-ului',
    cashbackId: 'ID de Cashback',
    cashbackName: 'Numele de Cashback',
    cashbackType: 'Tip de Cashback',
    calculationTime: 'Timp de calcul',
    transferType: 'Tip de transfer',
    platforms: 'Platforme',
    platform: 'Platformă',
    restrictedPlayers: 'Jucători restricționați',
    restrictedCountries: 'Țări restricționate',
    totalBet: 'Pariu total',
    totalWin: 'Câștig total',
    accumulated: 'Acumulat',
    accumulatedCashBack: 'Cashback acumulat',
    transferredCashBack: 'CashBack transferat',
    commission: 'Comision',
    commissions: 'Comisioane',
    lCommissions: 'Comisioane',
    commissionPercent: 'Comision, %',
    manageLimits: 'Administrare de limită',
    referees: 'Recomandații/Referințe',
    setCommissions: 'Setare comisioane',
    referralBonusId: 'ID bonus de recomandare',
    lReferralBonus: 'bonus de recomandare',
    referralBonus: 'ID bonus de recomandare',
    bonusName: 'Numele bonusului',
    calculationPeriod: 'Perioada de calcul',
    calculationPeriodTime: 'Timpul perioadei de calcul',
    commissionFrom: 'Comision de la',
    referrerId: 'ID de referitor',
    accumulatedCommission: 'Comision acumulat',
    nextCalculationDate: 'Următoarea dată de calcul',
    transferredCommission: 'Comision transferat',
    commissioningAmount: 'Suma de comision',
    convertedTo: 'Convertit la',
    convertedAmount: 'Convertit la',
    redeemedAmount: 'Suma rambursată',
    winAmount: 'Suma de câștig',
    lossAmount: 'Suma pierdută',
    cashbackAmount: 'Suma de cashback',
    includeActiveBonuses: 'Include bonusuri active',
    lBonus: 'bonus',
    bonus: 'Bonus',
    casino: 'Casino',
    invalidPlayerCount: 'Numărul de jucători nevalid în raport',
    totalPlayersUpdated: 'Numărul total de jucători de actualizat',
    lBonusTemplate: 'șablon de bonus',
    bonusTemplate: 'Șablon de bonus',
    invalidKeys: 'Chei nevalide',
    playersMissingCurrencyReason: 'Valuta contului de jucător nu este configurată',
    playersRestrictedReason: 'Jucătorul are un cont cu acces restricționat',
    missingCurrency: 'Valută incompletă',
    missingCurrencyReason: 'Moneda contului de jucător nu este configurată',
    issue: 'Problemă',
    platformType: 'Tipul de platformă',
    amountConfig: 'Suma de configurare',
    paymentMethods: 'Metode de plată',
    first: 'Primul',
    second: 'Al doilea',
    third: 'Al treilea',
    fourth: 'Al patrulea',
    fifth: 'Al cincilea',
    fromDepositAmount: '% din suma depunerii',
    fromDeposit: '% din depozit',
    betType: 'Tipul de pariu',
    minWageringOdd: 'Pariere minimă de cotă',
    minWageringOddType: 'Tip de pariere minimă de cotă',
    bonusTemplateName: 'Numele de șablon bonus',
    bonusTemplateId: 'ID de șablon bonus',
    categories: 'Categorii',
    bonusOptionsDesc: 'Un bonus de înscriere este o sumă de bani plătită unui nou jucător',
    bonusOptionsDesc1: 'Jucătorii vor primi bonusuri pentru toate depunerile din perioada de declanșare',
    bonusOptionsDesc2: 'Utilizatorul poate alege numărul de depuneri în perioada de declanșare pentru care jucătorii vor primi bonusuri',
    bonusOptionsDesc3: 'Jucătorii vor primi bonusuri pentru prima depunere a fiecărei zile în perioada de declanșare',
    createNewTemplate: 'Creează un șablon nou',
    selectBonusType: 'SELECTEAZĂ UN TIP DE BONUS',
    selectBonusTrigger: 'SELECTEAZĂ UN DECLANȘATOR DE BONUS',
    numberOfDeposits: 'Numărul depunerilor',
    fixedAmount: 'Cantitate fixă',
    sport: 'Sport',
    rules: 'Regule',
    rule: 'Regulă',
    noRules: 'Nicio regulă de afișat',
    relatedBonusId: 'ID de bonus asociat',
    endDateTime: 'Data/Ora de încheiere',
    noEndDate: 'Fără dată de încheiere',
    selectGames: 'Selectare jocuri',
    activities: 'Activități',
    discardAccumulatedCommissions: 'Eliminează comisioanele acumulate',
    bonusTypeConfig: 'Configurare tip bonus',
    playersInAnotherReferral: 'Jucători deja în alt bonus de recomandare',
    allPlayersSelected: 'Toți jucătorii sunt selectați',
    datesAndPlayers: 'Date și jucători',
    special: 'Special',
    highRisk: 'Risc ridicat',
    mediumRisk: 'Risc mediu',
    countOfReferees: 'Număr de recomandat',
    playerFromParticipants: 'jucător din lista de participanți',
    signUp: 'Înregistrează-te',
    bonusNameExternal: 'Numele bonusului (extern)',
    noEndData: 'Nu sunt date de încheiere',
    minNumberOfSelections: 'Numărul minim de selecții',
    dateTriggered: 'Data de declanșare',
    referralBonusName: 'Numele bonusului de recomandare',
    refereesId: 'ID de recomandat/referință',
    refereeId: 'ID de recomandat',
    realWallet: 'Portofel real',
    details: 'Detalii',
    numberOfDepositsInfo: 'Selectează depunerile pentru care jucătorii trebuie să primească bonusuri în perioada de declanșare.',
    cloneSettingsTo: 'Clonează setările către',
    balanceConfig: 'Setări de sold',
    usedCount: 'Numărul folosit',
    lRule: 'regulă',
    referralParticipants: 'Participanții de referință',
    theCashback: 'Cashback-ul',
    playersCashBack: 'Cashback de jucători',
    theBonus: 'Bonusul',
    depositAmount: 'Sumă de depunere',
    wageringTurnover: 'Cifra de afacere de pariuri',
    playersWontBeAble: 'Jucătorii nu vor putea să retragă sau să parieze pe jocurile P2P valoarea bonusului emis.',
    calculationFrom: 'CALCULUL DIN',
    lCalculationFrom: 'Calculul din',
    createNewCashback: 'Creează un nou Cashback',
    cashbackFromBetsDesc: 'Oferă un Cashback pe diferența pozitivă de pariu - câștig în perioada de calcul.',
    cashbackNetDepositsDesc: 'Oferă Cashback pe diferența pozitivă de depozite - retrageri în perioada de calcul.',
    netDepositAmount: 'Sumă netă de depunere',
    participateRule: 'Regulă de participare',
    totalDeposit: 'Depunere totală',
    totalWithdrawal: 'Retragere totală',
    totalBonuses: 'Bonusuri totale',
    manageParticipation: 'Gestiona participarea',
    restrictionFor: 'Restricţionat pentru',
    availableFor: 'Disponibil pentru',
    sportWageringBonuses: 'Bonusuri la pariuri sportive',
    casinoWageringBonuses: 'Bonusuri la pariuri de casino',
    otherBonuses: 'Alte bonusuri',
    finishedBonusesCount: 'Număr de bonusuri finalizate',
    totalRedeemBonuses: 'Rambursare totală a bonusurilor',
    referralCommission: 'Comision de recomandare',
    maxPossibleTransfer: 'Transfer maxim posibil',
    playThroughRequired: 'Rulaj necesar',
    matchingRegistrationIP: 'Referitorul are persoane recomandate cu IP-uri de înregistrare potrivite',
    sameRegistrationIP: 'Referitorul și recomandatul au același IP de înregistrare',
    amountColumnInfo: 'Intervalul include valoarea coloanei din stânga, până la dar fără să includă valoarea coloanei din dreapta',
    selectedGames: 'Jocuri selectate',
    minAmount: 'Suma minimă',
    step: 'Etapă',
    gamesInfo: 'Furnizorul permite selectarea jocurilor {{type}}',
    lSingle: 'simplu',
    lMultiple: 'multiplu',
    allowWithdrawal: 'Permite retragerea',
    expirationPeriod: 'Perioadă de pariere',
    lengthOfCodes: 'Lungime de coduri',
    participantsAllPlayersDesc: 'Permite includerea tuturor jucătorilor ca participant/participanți, atunci când declanșatorul (trigger-ul) este executat',
    participantsAllPlayersMainAreaDesc: 'Sistemul va include toți jucătorii ca jucător participant după executarea declanșatorului.',
    triggerOption: 'Opțiunea de declanșare',
    participantType: 'Tipul de participant',
    campaignName: 'Denumirea campaniei',
    walletType: 'Tip portofel',
    campaignStatus: 'Starea campaniei',
    availableCount: 'Număr disponibil',
    cancelBonusWhenWithdrawal: 'Anulați bonusul la retragere',
    minDepositAmount: 'Suma min. de depunere',
    number: 'număr',
    participant: 'participant ',
    depositsAmountConfigInfo: 'În această zonă configurăm Suma Bonusului și stabilim limitări pentru fiecare monedă.',
    bonusBetConfig: 'Configurare Bonus de pariu ',
    single: 'simplu ',
    ranges: 'Intervale',
    nextValue: 'Valoarea următoare',
    lPrevValue: 'valoarea anterioară',
    addRanges: 'Adaugă Intervale',
    lifetimePeriod: 'Întregul ciclu de viață',
    resetRanges: 'Resetare a intervalelor',
    lCampaign: 'campanie',
    multi: 'Multi',
    option: 'Opțiune',
    triggers: 'Declanșatori',
    viewRanges: 'Vizualizare intervale',
    depositNumber: 'Numărul depunerii',
    bonusResultAreaDesc: 'Poți vedea Bonusurile după adăugarea și configurarea lor din partea laterală.',
    participantsResultAreaDesc: 'Poți vedea Participanții după adăugarea și configurarea lor din partea laterală.',
    editTriggerDisabled: 'Nu poți edita declanșatorul acum ',
    multiOrSingleBonus: 'Descrierea bonusului multi și simplu',
    editParticipantsDisabled: 'Nu poți edita participanții acum',
    configureParticipants: 'Setările participanților',
    selectTriggerParticipantsBonuses: 'Selectează Declanșatorul (Triggerul), Alege Participanții, Configurează Bonusurile',
    toMakeSureCampaignSuccessful: 'Pentru a asigura succesul campaniei tale',
    triggerType: 'Tipul declanșatorului',
    cancelPromotion: 'Anulează promoția',
    createCampaign: 'Crează o campanie',
    multiOrSinglePromocodeType: 'Descrierea tipului de cod promoțional multi și simplu',
    missingAmount: 'Sumă lipsă',
    multiSelectionNum: 'Număr de selecție multiplă',
    playThroughRequiredDesc:
      'În cazul în care această opțiune este activată, valoarea câmpului Playthrough trebuie să fie egală cu numărul setat, iar în cazul dezactivării, aceasta trebuie să fie egală sau mai mare decât',
    playThroughInputDesc:
      "Permite definirea dacă un jucător trebuie să parieze bonusul de atâtea ori, cât este definit în configurația ''playthrough'' pentru a putea termina bonusul (în afară de 0 în rulaj).",
    cancelBonusWhenWithdrawalDesc: 'Descriere de Anulare Bonus la Retragere',
    campaign: 'Campanie',
    periodDescription: 'Aici putem stabili în ce perioadă să definim numărul depunerii',
    singleCountTypeDesc:
      'Permite configurarea unui singur bonus, însă cu sume diferite pentru fiecare număr de depunere, utilizabilă în cazurile în care nu este nevoie de configurare a mai multor bonusuri cu același tip de bonus.',
    multiCountTypeDesc:
      'Permite setarea diferitelor tipuri de bonusuri cu diferite configurații de sume pentru fiecare număr de depunere. În acest caz, alegem numărul depunerii/depozitului (valori posibile 1, ..., 100) pentru fiecare tip de bonus',
    disconnectTriggerDesc:
      'În cazul în care această funcționalitate este activată și jucătorul a primit cel puțin un bonus, dar nu îndeplinește condițiile de acordare a bonusurilor, atunci sistemul îl va deconecta din campanie, iar jucătorul nu va mai primi bonusuri noi.',
    participantsPlayerDesc: 'Permite includerea jucătorilor prin încărcare CSV sau manuală ',
    participantsPlayerListAmountsDesc: 'Permite includerea jucătorilor împreună cu sume de bonus individuale prin încărcarea listei de jucători',
    participantsPromoCodeDesc: 'Permite includerea jucătorilor prin utilizarea codului promoțional generat',
    participantsConditionsDesc: 'Permite includerea jucătorilor prin declanșarea unor condiții specifice',
    countryConditionDesc: 'Verifică țara jucătorilor',
    verifyConditionDesc: 'Verifică nivelul de verificare al jucătorilor',
    weekDaysConditionDesc: 'Verifică jucătorii în anumite zile',
    accountStatusConditionDesc: 'Verifică statusul conturilor jucătorilor',
    sportStatusConditionDesc: 'Verifică statusurile jucătorilor pe Sportsbook',
    segmentConditionDesc: 'Verifică segmentele din care fac parte jucătorii',
    tagConditionDesc: 'Verifică etichetele pe care le au jucătorii',
    paymentsConditionDesc: 'Verifică plățile tranzacțiilor jucătorilor',
    configModeAlreadyOpened: 'Ai deschis deja modul de configurare',
    bonusBetConfigInfo: 'Permite configurarea pariurilor de bonus. Pariul trebuie să corespundă criteriilor salvate pentru a fi considerat ca fiind un pariu bonus.',
    amountConfigSingleDesc: 'Permite configurarea unor sume unice pentru fiecare monedă',
    amountConfigRangesDesc: 'Permite configurarea sumelor din mai multe intervale pentru fiecare valută',
    amountConfigFixedAmountDesc:
      'Permite furnizarea unei sume de bonus care este fixă pentru fiecare valută (Prin monedă). În cazul unei sume fixe, putem configura condițiile de depunere folosind opțiunile Simplu sau interval',
    amountConfigFromDepositDesc: 'Permite numărarea sumei bonusului ca procent din depunere (general pentru toate valutele)',
    periodType: 'Tip de Perioadă',
    noDataAlt: 'Fără Declanșator, Participanți, Bonusuri selectate',
    successPlayers: 'Jucătorii pot obține un bonus',
    errorPlayers: 'Lipsa de valută ',
    amountConfigAreaInfo: 'În această parte configurăm suma bonusului și stabilim limitări pentru fiecare monedă',
    availablePromoCodes: 'Coduri promoționale disponibile',
    downloadAvailableCodes: 'Descarcă codurile disponibile',
    lNextValue: 'Valoarea următoare',
    freeSpinGamesInfo: 'Selectează furnizorul și categoria pentru a obține jocuri',
    freeSpinAmountConfigInfo: 'Selectează furnizorul și numărul de linii pentru a obține restricțiile de sumă',
    freeSpinRequiredAllGames: 'Acest furnizor acceptă rotiri gratuite la toate jocurile',
    maxWinAmount: 'Valoarea maximă de câștig',
    line: 'Linie',
    casinoBonus: 'Bonus de Casino',
    spinsAmount: 'Suma de rotiri',
    maxTriggerCount: 'Numărul maxim de declanșare',
    maxTriggerCountInfo: 'Acest câmp va permite setarea numărului maxim de declanșări reușite pentru fiecare jucător',
    freeSpinCurrencyInfo: 'Sistemul include monedele acceptate de marca (metodă de plată) și de furnizorul ales',
    amountRanges: 'Intervalul sumei (de la - până la)',
    amountRangesHint: 'Suma din câmpul "De la" al "Intervalului de sume" este inclusă în intervalul respectiv, în timp ce suma din câmpul "La" nu este inclusă în interval.',
    numberOfPlayers: 'Numărul de jucători (de la - la)',
    numberOfPlayersHint: 'Numărul din câmpul "De la" al "Număr de jucători" este inclus în acel interval, în timp ce numărul din câmpul "Până la" nu este inclus în interval.',
    numberOfReferees: 'Numărul de arbitri (de la - până la)',
    numberOfRefereesHint: 'Numărul din câmpul "De la" al "Număr de arbitri" este inclus în intervalul respectiv, în timp ce numărul din câmpul "Până la" nu este inclus în acest interval.',
    campaignStatistics: 'Statisticile campaniei',
    maxLimitAmount: 'Suma maximă de limită',
    netDepositAmountHint: 'Numărul din câmpul "De la" al "Sumei nete a depozitului" este inclus în intervalul respectiv, în timp ce numărul din câmpul "La" nu este inclus în interval.',
    gameSegmentInfo: 'Modificările din segmentul de joc nu vor afecta o entitate deja creată.',
    lCashbackAmounts: 'Sume de Cashback',
    usedPromoCodes: 'Coduri promoționale utilizate',
    createCrossPlatformCampaign: 'Crează campania Cross-Platform',
    amountConfigAreaCrossCampaignInfo: 'În acest domeniu stabilim limitări pentru fiecare valută',
    selectAndConfigureBonuses: 'Selectează și configurează bonusuri',
    cashbackStatistics: 'Statisticile CashBack',
    freeSpinAmountConfigInfoForSingle: 'Selectează furnizorul, numărul de linii și jocul pentru a obține restricțiile de sumă',
    minMaxProviderLimit: 'Limita furnizorului Min-Max',
    spinTotalAmount: 'Suma totală de rotiri',
    minMaxProviderLimitInfo: 'Suma totală a rotiri generată de numărul de rotiri înmulțit cu suma învârtirilor trebuie să fie între limitele menționate',
    maxWageringOddType: 'Miza maximă de Tip Odd',
    maxWageringOdd: 'Miza maximă de Odd',
    wageringOdd: 'Pariuri Odd',
    winAmountTransferTo: 'Suma de câștig transferată către',
    allowCashout: 'Permite Cashout-ul',
    allowCashoutInfo: 'Permite jucătorilor să folosească funcțiile de retragere (Cashout)/încasare parțială pentru acest bonus',
    sidebarParticipantsDescription: 'Aici putem alege condițiile de selecție a participanților la campanie: Lista de jucători, Lista de jucători - Sume, Cod promoțional, Condiții',
    sidebarTriggerDescription: 'Aici putem seta configurațiile de declanșare a campaniei: Fără declanșare, Depunere, Înregistrare, Verificare',
    configAlreadySaved: 'Ai configurat deja bonusuri sau participanți, elimină-i pentru a putea edita',
  },
  bg: {},
  el: {
    maxBonusAmount: 'Μέγ. ποσό μπόνους',
    minDepositAmount: 'Ελάχ. ποσό κατάθεσης',
    maxRedeemAmount: 'Μέγ. ποσό εξαργύρωσης',
    minBetAmount: 'Ελάχ. ποσό στοιχήματος',
    contributionPercent: 'Συνεισφορά, %',
    cashbackPercent: 'Επιστροφή μετρητών, %',
    bonusType: 'Τύπος μπόνους',
    currencies: 'Νομίσματα',
    triggerPeriod: 'Περίοδος ενεργοποίησης',
    activationPeriod: 'Περίοδος ενεργοποίησης',
    playthrough: 'Playthrough',
    wageringPeriod: 'Περίοδος στοιχηματισμού',
    issueBonus: 'Έκδοση μπόνους',
    bonusId: 'Αναγνωριστικό μπόνους',
    promoName: 'Όνομα προωθητικής ενέργειας',
    promoCode: 'Κωδικός προώθησης',
    promoCodeType: 'Τύπος κωδικού προώθησης',
    lPromoCode: 'κωδικός προώθησης',
    maxUseCount: 'Αριθμός μέγ. χρήσης',
    dateUsed: 'Ημερομηνία σε χρήση',
    promotionEndDate: 'Ημερομηνία λήξης προωθητικής ενέργειας',
    issuedBy: 'Εκδόθηκε από',
    canceledBy: 'Ακυρώθηκε από',
    mobileVerified: 'Επαληθευμένο κινητό',
    identityVerified: 'Επαληθευμένη ταυτότητα',
    emailVerified: 'Επαληθευμένο email',
    disconnectedBy: 'Αποσυνδέθηκε από',
    disconnectTrigger: 'Αποσύνδεση ενεργοποίησης',
    remainingTurnover: 'Υπολειπόμενο τζιράρισμα',
    bonusStatus: 'Κατάσταση μπόνους',
    triggeredDate: 'Ημερομηνία ενεργοποίησης',
    providerMayDisabled: 'Ο πάροχος μπορεί να έχει απενεργοποιηθεί.',
    gameMayDisabled: 'Το παιχνίδι μπορεί να έχει απενεργοποιηθεί.',
    maxCashbackAmount: 'Μέγ. ποσό επιστροφής μετρητών',
    cashbackId: 'Αναγνωριστικό επιστροφής μετρητών',
    cashbackName: 'Όνομα επιστροφής μετρητών',
    cashbackType: 'Τύπος επιστροφής μετρητών',
    calculationTime: 'Χρόνος υπολογισμού',
    transferType: 'Τύπος μεταφοράς',
    platforms: 'Πλατφόρμες',
    platform: 'Πλατφόρμα',
    restrictedPlayers: 'Παίκτες υπό περιορισμό',
    restrictedCountries: 'Χώρες υπό περιορισμό',
    totalBet: 'Συνολικό στοίχημα',
    totalWin: 'Συνολικό κέρδος',
    accumulated: 'Συσσωρευμένο',
    accumulatedCashBack: 'Συσσωρευμένη επιστροφή μετρητών',
    transferredCashBack: 'Επιστροφή μετρητών που μεταφέρθηκε',
    commission: 'Προμήθεια',
    commissions: 'Προμήθειες',
    lCommissions: 'Προμήθειες',
    commissionPercent: 'Προμήθεια, %',
    manageLimits: 'Διαχείριση ορίων',
    referees: 'Παραπεμπόμενοι',
    setCommissions: 'Ορισμός προμηθειών',
    referralBonusId: 'Αναγνωριστικό μπόνους παραπομπής',
    lReferralBonus: 'μπόνους παραπομπής',
    referralBonus: 'Μπόνους παραπομπής',
    bonusName: 'Όνομα μπόνους',
    calculationPeriod: 'Περίοδος υπολογισμού',
    calculationPeriodTime: 'Χρόνος περιόδου υπολογισμού',
    commissionFrom: 'Προμήθεια από',
    referrerId: 'Αναγνωριστικό παραπέμποντος',
    accumulatedCommission: 'Συσσωρευμένη προμήθεια',
    nextCalculationDate: 'Ημερομηνία επόμενου υπολογισμού',
    transferredCommission: 'Προμήθεια που μεταφέρθηκε',
    commissioningAmount: 'Ποσό προμήθειας',
    convertedTo: 'Μετατράπηκε σε',
    convertedAmount: 'Μετατραπέν ποσό',
    redeemedAmount: 'Εξαργυρωμένο ποσό',
    winAmount: 'Ποσό κέρδους',
    lossAmount: 'Ποσό απώλειας',
    cashbackAmount: 'Ποσό επιστροφής μετρητών',
    includeActiveBonuses: 'Να συμπεριληφθούν ενεργά μπόνους',
    lBonus: 'μπόνους',
    bonus: 'Μπόνους',
    casino: 'Καζίνο',
    invalidPlayerCount: 'Μη έγκυρος αριθμός παικτών στην αναφορά',
    totalPlayersUpdated: 'Σύνολο παικτών που θα ενημερωθούν',
    lBonusTemplate: 'πρότυπο μπόνους',
    bonusTemplate: 'Πρότυπο μπόνους',
    invalidKeys: 'Μη έγκυρα κλειδιά',
    playersMissingCurrencyReason: 'Το νόμισμα λογαριασμού παίκτη δεν έχει διαμορφωθεί',
    playersRestrictedReason: 'Ο παίκτης βρίσκεται σε κατάσταση περιορισμένου λογαριασμού',
    missingCurrency: 'Δεν έχει οριστεί νόμισμα',
    missingCurrencyReason: 'Το νόμισμα λογαριασμού παίκτη δεν έχει διαμορφωθεί',
    issue: 'Έκδοση',
    platformType: 'Τύπος πλατφόρμας',
    amountConfig: 'Διαμόρφωση ποσού',
    paymentMethods: 'Μέθοδοι πληρωμής',
    first: 'Πρώτο',
    second: 'Δεύτερο',
    third: 'Τρίτο',
    fourth: 'Τέταρτο',
    fifth: 'Πέμπτο',
    fromDepositAmount: '% από το ποσό κατάθεσης',
    fromDeposit: '% από την κατάθεση',
    betType: 'Τύπος στοιχήματος',
    minWageringOdd: 'Ελάχ. απόδοση στοιχηματισμού',
    minWageringOddType: 'Τύπος ελάχ. απόδοσης στοιχηματισμού',
    bonusTemplateName: 'Όνομα προτύπου μπόνους',
    bonusTemplateId: 'Αναγνωριστικό προτύπου μπόνους',
    categories: 'Κατηγορίες',
    bonusOptionsDesc: 'Το μπόνους εγγραφής είναι ένα ποσό που καταβάλλεται σε έναν νέο παίκτη',
    bonusOptionsDesc1: 'Οι παίκτες θα λάβουν μπόνους για όλες τις καταθέσεις στην περίοδο ενεργοποίησης',
    bonusOptionsDesc2: 'Ο χρήστης μπορεί να επιλέξει για πόσες καταθέσεις στην περίοδο ενεργοποίησης θα λάβουν οι παίκτες μπόνους',
    bonusOptionsDesc3: 'Οι παίκτες θα λάβουν μπόνους για την πρώτη κατάθεση κάθε ημέρας στην περίοδο ενεργοποίησης',
    createNewTemplate: 'Δημιουργία νέου προτύπου',
    selectBonusType: 'ΕΠΙΛΟΓΗ ΤΥΠΟΥ ΜΠΟΝΟΥΣ',
    selectBonusTrigger: 'ΕΠΙΛΟΓΗ ΕΝΕΡΓΟΠΟΙΗΣΗΣ ΜΠΟΝΟΥΣ',
    numberOfDeposits: 'Αριθμός καταθέσεων',
    fixedAmount: 'Σταθερό ποσό',
    sport: 'Άθλημα',
    rules: 'Κανόνες',
    rule: 'Κανόνας',
    noRules: 'Δεν υπάρχουν κανόνες για εμφάνιση',
    relatedBonusId: 'Αναγνωριστικό σχετικού μπόνους',
    endDateTime: 'Ημερομηνία/ώρα λήξης',
    noEndDate: 'Δεν υπάρχει ημερομηνία λήξης',
    selectGames: 'Επιλογή παιχνιδιών',
    activities: 'Δραστηριότητες',
    discardAccumulatedCommissions: 'Απόρριψη συσσωρευμένων προμηθειών',
    bonusTypeConfig: 'Διαμόρφωση τύπου μπόνους',
    playersInAnotherReferral: 'Οι παίκτες έχουν ήδη άλλο μπόνους παραπομπής',
    allPlayersSelected: 'Όλοι οι παίκτες έχουν επιλεγεί',
    datesAndPlayers: 'Ημερομηνίες και παίκτες',
    special: 'Ειδικό',
    highRisk: 'Υψηλού κινδύνου',
    mediumRisk: 'Μετρίου κινδύνου',
    countOfReferees: 'Αριθμός παραπεμπόμενων',
    playerFromParticipants: 'παίκτης από συμμετέχοντες',
    signUp: 'Εγγραφή',
    bonusNameExternal: 'Όνομα μπόνους (Εξωτερικό)',
    noEndData: 'Δεν υπάρχει ημερομηνία λήξης',
    minNumberOfSelections: 'Ελάχ. αριθμός επιλογών',
    dateTriggered: 'Ημερομηνία ενεργοποίησης',
    referralBonusName: 'Όνομα μπόνους παραπομπής',
    refereesId: 'Αναγνωριστικό παραπεμπόμενων',
    refereeId: 'Αναγνωριστικό παραπεμπόμενου',
    realWallet: 'Πορτοφόλι πραγματικών χρημάτων',
    details: 'Στοιχεία',
    numberOfDepositsInfo: 'Επιλέξτε τις καταθέσεις για τις οποίες οι παίκτες πρέπει να λάβουν μπόνους κατά την περίοδο ενεργοποίησης.',
    numberOfDepositsTabIndex: {
      1: 'η',
      2: 'η',
      3: 'η',
      default: 'η',
    },
    cloneSettingsTo: 'Κλωνοποίηση ρυθμίσεων σε',
    balanceConfig: 'Διαμόρφωση υπολοίπου',
    usedCount: 'Αριθμός χρησιμοποιημένων',
    lRule: 'κανόνας',
    referralParticipants: 'Συμμετέχοντες παραπομπής',
    theCashback: 'Η επιστροφή μετρητών',
    playersCashBack: 'Επιστροφή μετρητών παικτών',
    theBonus: 'Το μπόνους',
    depositAmount: 'Ποσό κατάθεσης',
    wageringTurnover: 'Τζιράρισμα στοιχηματισμού',
    playersWontBeAble: 'Οι παίκτες δεν θα μπορούν να κάνουν ανάληψη ή να ποντάρουν το εκδοθέν ποσό μπόνους σε παιχνίδια P2P.',
    calculationFrom: 'ΥΠΟΛΟΓΙΣΜΟΣ ΑΠΟ',
    lCalculationFrom: 'Υπολογισμός από',
    createNewCashback: 'Δημιουργία νέας επιστροφής μετρητών',
    cashbackFromBetsDesc: 'Δίνει επιστροφή μετρητών στο θετικό αποτέλεσμα της διαφοράς στοιχήματος - κέρδους στην περίοδο υπολογισμού.',
    cashbackNetDepositsDesc: 'Δίνει επιστροφή μετρητών στο θετικό αποτέλεσμα της διαφοράς καταθέσεων - αναλήψεων στην περίοδο υπολογισμού.',
    netDepositAmount: 'Καθαρό ποσό κατάθεσης',
    participateRule: 'Κανόνας συμμετοχής',
    totalDeposit: 'Συνολική κατάθεση',
    totalWithdrawal: 'Συνολική ανάληψη',
    totalBonuses: 'Συνολικά μπόνους',
    manageParticipation: 'Διαχείριση συμμετοχής',
    restrictionFor: 'Υπό περιορισμό για',
    availableFor: 'Διαθέσιμο για',
    sportWageringBonuses: 'Μπόνους αθλητικού στοιχηματισμού',
    casinoWageringBonuses: 'Μπόνους στοιχηματισμού καζίνο',
    otherBonuses: 'Άλλα μπόνους',
    finishedBonusesCount: 'Aριθμός μπόνους που τελείωσαν',
    totalRedeemBonuses: 'Συνολικά εξαργυρωμένα μπόνους',
    referralCommission: 'Προμήθεια παραπομπής',
    maxPossibleTransfer: 'Μέγ. πιθανή μεταφορά',
    playThroughRequired: 'Απαιτείται playthrough',
    matchingRegistrationIP: 'Ο παραπέμπων έχει παραπεμπόμενους με ίδιες IP εγγραφής',
    sameRegistrationIP: 'Ο παραπέμπων και ο παραπεμπόμενος έχουν την ίδια IP εγγραφής',
    amountColumnInfo: 'Το εύρος κυμαίνεται από την τιμή της αριστερής στήλης, έως την τιμή της δεξιάς στήλης, αλλά δεν περιλαμβάνει την τιμή της δεξιάς στήλης',
    selectedGames: 'Επιλεγμένα παιχνίδια',
    minAmount: 'Ελάχ. Ποσό',
    step: 'Βήμα',
    gamesInfo: 'Ο πάροχος επιτρέπει την επιλογή παιχνιδιών {{type}}',
    lSingle: 'μονό',
    lMultiple: 'πολλαπλό',
    allowWithdrawal: 'Να επιτρέπεται η ανάληψη',
    campaignName: 'Όνομα καμπάνιας',
    noTrigger: 'Χωρίς ενεργοποίηση',
    cancelBonusWhenWithdrawal: 'Ακύρωση μπόνους (Κατά την ανάληψη)',
    number: 'Αριθμός',
    participant: 'Συμμετέχων',
    depositsAmountConfigInfo: 'Σε αυτή την περιοχή, διαμορφώνουμε το ποσό μπόνους και ορίζουμε περιορισμούς ανά νόμισμα',
    bonusBetConfig: 'Διαμόρφωση στοιχήματος μπόνους',
    single: 'Μονό',
    ranges: 'Εύρη',
    nextValue: 'Επόμενη τιμή',
    lPrevValue: 'προηγούμενη τιμή',
    addRanges: 'Προσθήκη εύρους',
    lifetimePeriod: 'Περίοδος διάρκειας ζωής',
    resetRanges: 'Επαναφορά εύρους',
    lCampaign: 'καμπάνια',
    multi: 'Πολλαπλό',
    option: 'Επιλογή',
    triggers: 'Ενεργοποιήσεις',
    viewRanges: 'Προβολή εύρους',
    depositNumber: 'Αριθμός κατάθεσης',
    bonusResultAreaDesc: 'Μπορείτε να δείτε τα Μπόνους αφού τα προσθέσετε και τα διαμορφώσετε από την πλαϊνή περιοχή.',
    participantsResultAreaDesc: 'Μπορείτε να δείτε τους Συμμετέχοντες αφού τους προσθέσετε και τους διαμορφώσετε από την πλαϊνή περιοχή.',
    editTriggerDisabled: 'Δεν μπορείτε να επεξεργαστείτε την ενεργοποίηση τώρα',
    multiOrSingleBonus: 'Περιγραφή μονού και πολλαπλού μπόνους',
    editParticipantsDisabled: 'Δεν μπορείτε να επεξεργαστείτε τους συμμετέχοντες τώρα',
    configureParticipants: 'Διαμόρφωση συμμετεχόντων',
    selectTriggerParticipantsBonuses: 'Επιλέξτε την ενεργοποίηση, Επιλέξτε συμμετέχοντες, Διαμορφώστε τα μπόνους',
    toMakeSureCampaignSuccessful: 'Για να βεβαιωθείτε ότι η καμπάνια σας είναι επιτυχημένη',
    participantType: 'Τύπος συμμετέχοντος',
    triggerType: 'Τύπος ενεργοποίησης',
    cancelPromotion: 'Ακύρωση προωθητικής ενέργειας',
    createCampaign: 'Δημιουργία καμπάνιας',
    multiOrSinglePromocodeType: 'Περιγραφή τύπου μονού και πολλαπλού κωδικού προώθησης',
    missingAmount: 'Δεν έχει οριστεί ποσό',
    walletType: 'Τύπος πορτοφολιού',
    multiSelectionNum: 'Αριθμός επιλογής πολλαπλών',
    playThroughRequiredDesc:
      'Σε περίπτωση που αυτή η επιλογή είναι ενεργοποιημένη, η τιμή του πεδίου Playthrough θα πρέπει να είναι ίση με τον αριθμό που έχει οριστεί. Εάν είναι απενεργοποιημένη, θα πρέπει να είναι ίση ή μεγαλύτερη από',
    playThroughInputDesc:
      'Επιτρέπει να καθοριστεί εάν ένας παίκτης πρέπει να στοιχηματίσει το μπόνους όσες φορές ορίζεται στη διαμόρφωση του playthrough για να μπορέσει να τελειώσει το μπόνους (εκτός από 0 στο τζιράρισμα)',
    cancelBonusWhenWithdrawalDesc: 'Επιτρέπει την αυτόματη ακύρωση του μπόνους εάν ο παίκτης κάνει τη συναλλαγή ανάληψης κατά τη διάρκεια των περιόδων ενεργοποίησης και λήξης του μπόνους',
    triggerOption: 'Επιλογή ενεργοποίησης',
    campaign: 'Καμπάνια',
    periodDescription: 'Εδώ μπορούμε να ορίσουμε σε ποια περίοδο θα καθορίσουμε τον αριθμό κατάθεσης',
    singleCountTypeDesc:
      'Επιτρέπει τον ορισμό μόνο ενός μπόνους αλλά με διαφορετικά ποσά για κάθε αριθμό κατάθεσης, που είναι χρήσιμο σε περιπτώσεις που δεν χρειάζεται να διαμορφώσουμε διάφορα μπόνους με τον ίδιο τύπο μπόνους',
    multiCountTypeDesc:
      'Επιτρέπει τον ορισμό διαφορετικών τύπων μπόνους με διαφορετικές διαμορφώσεις ποσών για κάθε αριθμό κατάθεσης. Σε αυτή την περίπτωση, επιλέγουμε τον αριθμό κατάθεσης (πιθανές τιμές 1, â€¦, 100) για κάθε τύπο μπόνους',
    disconnectTriggerDesc:
      'Εάν αυτή η λειτουργία είναι ενεργοποιημένη και ο παίκτης έχει λάβει τουλάχιστον ένα μπόνους αλλά δεν πληροί τις προϋποθέσεις μπόνους, τότε το σύστημα θα τον αποσυνδέσει από την καμπάνια και ο παίκτης δεν θα λαμβάνει πλέον νέα μπόνους',
    participantsPlayerDesc: 'Επιτρέπει τη συμπερίληψη παικτών μεταφορτώνοντας CSV ή μη αυτόματα',
    participantsPlayerListAmountsDesc: 'Επιτρέπει τη συμπερίληψη παικτών με ατομικά ποσά μπόνους μεταφορτώνοντας τη λίστα παικτών',
    participantsPromoCodeDesc: 'Επιτρέπει τη συμπερίληψη παικτών χρησιμοποιώντας τον κωδικό προώθησης που δημιουργήθηκε',
    participantsConditionsDesc: 'Επιτρέπει τη συμπερίληψη παικτών ενεργοποιώντας συγκεκριμένες προϋποθέσεις',
    countryConditionDesc: 'Ελέγχει τη χώρα των παικτών',
    verifyConditionDesc: 'Ελέγχει το επίπεδο επαλήθευσης των παικτών',
    weekDaysConditionDesc: 'Ελέγχει τους παίκτες σε συγκεκριμένες ημέρες',
    accountStatusConditionDesc: 'Ελέγχει την κατάσταση του λογαριασμού των παικτών',
    sportStatusConditionDesc: 'Ελέγχει την κατάσταση των παικτών στο Sportsbook',
    segmentConditionDesc: 'Ελέγχει τα τμήματα των οποίων αποτελούν μέρη οι παίκτες',
    tagConditionDesc: 'Ελέγχει τις ετικέτες που έχουν ανατεθεί στους παίκτες',
    paymentsConditionDesc: 'Ελέγχει τις πληρωμές συναλλαγών των παικτών',
    configModeAlreadyOpened: 'Έχετε ανοίξει ήδη τη λειτουργία διαμόρφωσης',
    bonusBetConfigInfo: 'Επιτρέπει τη διαμόρφωση των στοιχημάτων μπόνους. Το στοίχημα θα πρέπει να αντιστοιχίζεται στα αποθηκευμένα κριτήρια για να θεωρείται στοίχημα μπόνους',
    amountConfigSingleDesc: 'Να επιτρέπεται η ρύθμιση παραμέτρων μεμονωμένων ποσών ανά νόμισμα',
    amountConfigRangesDesc: 'Να επιτρέπεται η ρύθμιση παραμέτρων ποσών πολλαπλού εύρους για κάθε νόμισμα',
    amountConfigFixedAmountDesc:
      'Επιτρέπει την παροχή ενός ποσού μπόνους που είναι σταθερό για κάθε νόμισμα (Ανά νόμισμα). Στην περίπτωση ενός σταθερού ποσού, μπορούμε να διαμορφώσουμε τις προϋποθέσεις κατάθεσης χρησιμοποιώντας τις επιλογές Μονό ή Εύρος',
    amountConfigFromDepositDesc: 'Επιτρέπει την καταμέτρηση του ποσού του μπόνους ως ποσοστό από την κατάθεση (Γενικά για όλα τα νομίσματα)',
    periodType: 'Τύπος περιόδου',
    expirationPeriod: 'Περίοδος λήξης',
    noDataAlt: 'Χωρίς ενεργοποίηση, Συμμετέχοντες, Μπόνους επιλέχτηκαν',
    successPlayers: 'Οι παίκτες μπορούν να λάβουν μπόνους',
    errorPlayers: 'Δεν έχει οριστεί νόμισμα',
    amountConfigAreaInfo: 'Σε αυτή την περιοχή, διαμορφώνουμε το ποσό μπόνους και ορίζουμε περιορισμούς ανά νόμισμα',
    availableCount: 'Διαθέσιμο πλήθος',
    availablePromoCodes: 'Διαθέσιμοι κωδικοί προώθησης',
    downloadAvailableCodes: 'Λήψη διαθέσιμων κωδικών',
    lengthOfCodes: 'Μήκος κωδικών',
    lNextValue: 'επόμενη τιμή',
    freeSpinGamesInfo: 'Επιλογή παρόχου και κατηγορίας για απόκτηση παιχνιδιών',
    freeSpinAmountConfigInfo: 'Επιλογή παρόχου και πλήθους γραμμών για λήψη περιορισμών ποσού',
    freeSpinRequiredAllGames: 'Αυτός ο πάροχος υποστηρίζει δωρεάν περιστροφή σε όλα τα παιχνίδια',
    maxWinAmount: 'Ποσό μέγ. κέρδους',
    line: 'Γραμμή',
    casinoBonus: 'Μπόνους καζίνο',
    campaignStatus: 'Κατάσταση καμπάνιας',
    participantsAllPlayersDesc: 'Επιτρέπει τη συμπερίληψη όλων των παικτών ως συμμετέχοντες όταν εκτελείται η ενεργοποίηση',
    participantsAllPlayersMainAreaDesc: 'Το σύστημα θα συμπεριλάβει όλους τους παίκτες ως συμμετέχοντες μετά την εκτέλεση της ενεργοποίησης.',
    spinsAmount: 'Ποσό περιστροφών',
    maxTriggerCount: 'Μέγιστο πλήθος ενεργοποιήσεων',
    maxTriggerCountInfo: 'Αυτό το πεδίο θα επιτρέψει τον ορισμό του μέγιστου πλήθους επιτυχημένων ενεργοποιήσεων ανά παίκτη',
    freeSpinCurrencyInfo: 'Το σύστημα περιλαμβάνει νομίσματα που υποστηρίζονται από την επωνυμία και τον επιλεγμένο πάροχο',
    amountRanges: 'Εύρος ποσού (Από - Έως)',
    amountRangesHint: 'Το ποσό στο πεδίο «Από» στο «Εύρος ποσού» περιλαμβάνεται σε αυτό το εύρος, ενώ το ποσό στο πεδίο «Έως» δεν περιλαμβάνεται στο εύρος.',
    numberOfPlayers: 'Αριθμός παικτών (Από - Έως)',
    numberOfPlayersHint: 'Ο αριθμός στο πεδίο «Από» στο «Αριθμός παικτών» περιλαμβάνεται σε αυτό το εύρος, ενώ ο αριθμός στο πεδίο «Έως» δεν περιλαμβάνεται στο εύρος.',
    numberOfReferees: 'Αριθμός παραπεμπόμενων (Από - Έως)',
    numberOfRefereesHint: 'Ο αριθμός στο πεδίο «Από» στο «Αριθμός παραπεμπόμενων» περιλαμβάνεται σε αυτό το εύρος, ενώ ο αριθμός στο πεδίο «Έως» δεν περιλαμβάνεται στο εύρος.',
    campaignStatistics: 'Στατιστικά καμπάνιας',
    maxLimitAmount: 'Μέγ. ποσό ορίου',
    netDepositAmountHint: 'Ο αριθμός στο πεδίο «Από» στο «Καθαρό ποσό κατάθεσης» περιλαμβάνεται σε αυτό το εύρος, ενώ ο αριθμός στο πεδίο «Έως» δεν περιλαμβάνεται στο εύρος.',
    gameSegmentInfo: 'Οι αλλαγές στο τμήμα του παιχνιδιού δεν θα επηρεάσουν την ήδη δημιουργημένη οντότητα',
    lCashbackAmounts: 'ποσά επιστροφής μετρητών',
    usedPromoCodes: 'Χρησιμοποιημένοι κωδικοί προώθησης',
    createCrossPlatformCampaign: 'Δημιουργία καμπάνιας μεταξύ πλατφορμών',
    amountConfigAreaCrossCampaignInfo: 'Σε αυτή την περιοχή, ορίζουμε περιορισμούς ανά νόμισμα',
    selectAndConfigureBonuses: 'Επιλογή και διαμόρφωση μπόνους',
    cashbackStatistics: 'Στατιστικά επιστροφής μετρητών',
    freeSpinAmountConfigInfoForSingle: 'Επιλογή παρόχου, πλήθους γραμμών και παιχνιδιού για λήψη περιορισμών ποσού',
    minMaxProviderLimit: 'Ελάχ.-Μέγ. όριο παρόχου',
    spinTotalAmount: 'Συνολικό ποσό περιστροφών',
    minMaxProviderLimitInfo:
      'Το συνολικό ποσό περιστροφών που δημιουργείται από το πλήθος των περιστροφών πολλαπλασιασμένο επί το ποσό των περιστροφών θα πρέπει να βρίσκεται μεταξύ των αναφερόμενων ορίων',
    maxWageringOddType: 'Τύπος μέγ. απόδοσης στοιχηματισμού',
    maxWageringOdd: 'Μέγ. απόδοση στοιχηματισμού',
    wageringOdd: 'Απόδοση στοιχηματισμού',
    winAmountTransferTo: 'Μεταφορά ποσού κέρδους σε',
    allowCashout: 'Να επιτρέπεται η εξαργύρωση',
    allowCashoutInfo: 'Να επιτρέπεται στους παίκτες να χρησιμοποιούν τις λειτουργίες εξαργύρωσης/μερικής εξαργύρωσης για αυτό το μπόνους',
    bonusAbuse: 'Κατάχρηση μπόνους',
    bonusAbuseInfo: {
      0: 'Μετά την ενεργοποίηση αυτού του πλαισίου ελέγχου, οι παίκτες δεν θα μπορούν να στοιχηματίσουν από το Υπόλοιπο μπόνους στο ίδιο γεγονός περισσότερες από μία φορές',
      1: 'Μετά την ενεργοποίηση αυτού του πλαισίου ελέγχου, οι παίκτες δεν θα μπορούν να στοιχηματίσουν από το Υπόλοιπο μπόνους στο ίδιο γεγονός περισσότερες από μία φορές',
      2:
        'Μετά την ενεργοποίηση αυτού του πλαισίου ελέγχου, οι παίκτες δεν θα μπορούν να στοιχηματίσουν από το Υπόλοιπο μπόνους στο ίδιο γεγονός περισσότερες από μία φορές.'
        + ' Στην περίπτωση του Μπόνους πραγματικού στοιχήματος, εάν ο παίκτης στοιχηματίσει από το Πραγματικό υπόλοιπο περισσότερες από μία φορές στο ίδιο γεγονός που ικανοποιεί τις απαιτήσεις του μπόνους, το δεύτερο ποντάρισμα δεν θα πρέπει να μειώνει το τζιράρισμα στοιχηματισμού',
    },
    oneTimeBet: 'Στοίχημα μίας φοράς',
    oneTimeBetInfo: 'Απαιτεί από τους παίκτες να χρησιμοποιήσουν πλήρως το μπόνους',
    minBetAmountPerCategory: 'Ελάχ. ποσό στοιχήματος ανά κατηγορία',
    periodDescriptionEvery:
      'Εδώ μπορούμε να ορίσουμε σε ποια περίοδο θα καθορίσουμε το Μέγιστο πλήθος ενεργοποιήσεων. Εάν δεν έχει διαμορφωθεί, το σύστημα δεν θα περιορίσει την εργασία ενεργοποίησης για έναν παίκτη',
    totalBets: 'Συνολικά στοιχήματα',
    totalWins: 'Συνολικά κέρδη',
    subMethod: 'Δευτερεύουσα μέθοδος',
    paymentConditionPropertiesInfo: 'Το μενού δευτερευόντων μεθόδων είναι ορατό μόνο για τους τρόπους πληρωμής για τους οποίους παρέχεται η λίστα δευτερευόντων μεθόδων από τον σωρευτή.',
    verificationTriggerInfo: 'Η προϋπόθεση θα λειτουργήσει εάν επαληθευτούν όλες οι επιλεγμένες επιλογές, περιλαμβάνεται επίσης η επαλήθευση του πεδίου ενεργοποίησης',
    promoCodesUploadFormatMsg: 'Μπορείτε να μεταφορτώσετε κωδικούς προώθησης. Μόνο η μορφή CSV είναι αποδεκτή',
    amountConfigDepositSingleDesc: 'Να επιτρέπεται η ρύθμιση παραμέτρων μονών % ανά νόμισμα',
    amountConfigDepositRangesDesc: 'Να επιτρέπεται η ρύθμιση παραμέτρων πολλαπλού εύρους % για κάθε νόμισμα',
    canceller: 'Ακυρωτής',
    awardedBonusesDetails: 'Στοιχεία απονεμηθέντων μπόνους',
    bonusLowestAmount: 'Τα χαμηλότερα ποσά μπόνους',
    bonusHighestAmount: 'Τα υψηλότερα ποσά μπόνους',
    totalBonusAmount: 'Συνολικά ποσά μπόνους',
    checkTheListOfRecipients: 'Ελέγξτε την τελική λίστα των παραληπτών',
    allGames: 'Όλα τα παιχνίδια',
    allGamesDesc: 'Επιτρέπει τον ορισμό λοταρίας μπόνους σε όλα τα παιχνίδια της επωνυμίας, όλες οι αλλαγές στα παιχνίδια της επωνυμίας θα ληφθούν υπόψη',
    selectGamesDesc: 'Επιτρέπει τον ορισμό λοταρίας μπόνους σε συγκεκριμένους παρόχους και παιχνίδια',
    contribution: 'Συνεισφορά, %',
    addPlayersNote: 'Είναι δυνατή η προσθήκη μόνο νέων συμμετεχόντων. Διαφορετικά, θα αποκλειστούν αυτόματα από τη λίστα συμμετεχόντων',
    campaignParticipants: 'Συμμετέχοντες καμπάνιας',
    autoActivation: 'Αυτόματη ενεργοποίηση',
    autoActivationDesc:
      'Επιτρέπει την εκχώρηση του μπόνους που έχει ήδη ενεργοποιηθεί στον παίκτη. Συνιστάται η διαμόρφωση ενός μπόνους από κάθε τύπο μπόνους (Καζίνο, Αθλητικά, Δωρεάν Περιστροφή) σε περίπτωση χρήσης της αυτόματης ενεργοποίησης. Διαφορετικά, τα μπόνους θα ενεργοποιηθούν τυχαία',
    promoCodeCaseSensitiveInfo: 'Επιτρέπει τη διαμόρφωση του κωδικού προώθησης για παίκτες. Οι κωδικοί δεν επηρεάζονται από τα πλήκτρα για τους παίκτες και θα μετατραπούν σε κεφαλαία',
    supportPlayerCancellationGamesInfo: 'Ο πάροχος επιτρέπει την επιλογή παιχνιδιών {{type}} και δεν επιτρέπει την ακύρωση του μπόνους μετά την ενεργοποίηση',
    referralStatistics: 'Στατιστικά παραπομπής',
    campaignWillBeSavedInBgInfo: 'Καθώς τα δεδομένα της καμπάνιας είναι μεγαλύτερα, θα αποθηκευτούν στη λειτουργία παρασκηνίου και είναι διαθέσιμα στη λίστα των Καμπανιών.',
    contentAndNotification: 'Περιεχόμενο και ειδοποίηση',
    contentAndNotificationConfigsRemovedDesc: 'Όλες οι διαμορφώσεις Περιεχομένου και ειδοποιήσεων μπόνους αφαιρέθηκαν. Μετά την αποθήκευση, θα χάσετε όλες τις προηγούμενες διαμορφώσεις.',
    cloneContentFrom: 'Κλωνοποίηση περιεχομένου μπόνους <0>{{bonusName}}</0> σε',
    contentLanguages: 'Γλώσσες περιεχομένου',
    notificationInfo:
      'Σε αυτή την περιοχή, διαμορφώνουμε μια ειδοποίηση για τους παίκτες σχετικά με την κατανομή μπόνους. Εάν δεν επιλεγεί κανένα πρότυπο, οι παίκτες δεν θα ειδοποιηθούν με κανάλια επικοινωνίας της επωνυμίας.',
    contentInfo:
      'Σε αυτή την περιοχή, διαμορφώνουμε εικόνες και περιεχόμενο σχετικό με το μπόνους με τις γλώσσες της επωνυμίας, που θα μπορούν να βλέπουν οι παίκτες. Εάν το περιεχόμενο δεν έχει διαμορφωθεί, το σύστημα θα χρησιμοποιήσει το προεπιλεγμένο περιεχόμενο.',
    editContent: 'Επεξεργασία περιεχομένου',
    sidebarParticipantsDescription: 'Εδώ μπορούμε να επιλέξουμε τις προϋποθέσεις επιλογής των συμμετεχόντων στην καμπάνια: Λίστα παικτών, Ποσά λίστας παικτών, Κωδικός προώθησης, Προϋποθέσεις',
    contentAndNotificationSidebarNoBonuses: 'Η περιοχή Περιεχομένου και ειδοποίησης θα ενεργοποιηθεί μετά τη διαμόρφωση και την αποθήκευση των μπόνους',
    sidebarTriggerDescription: 'Εδώ μπορούμε να ορίσουμε διαμορφώσεις ενεργοποίησης καμπάνιας: Χωρίς ενεργοποίηση, κατάθεση, εγγραφή, επαλήθευση',
    contentAndNotificationSidebarDesc: 'Εδώ μπορούμε να επιλέξουμε διαμορφωμένα μπόνους και να προσθέσουμε συγκεκριμένο περιεχόμενο και ειδοποίηση για το μπόνους',
    contentAndNotificationResultAreaDesc: 'Μπορείτε να δείτε Περιεχόμενο και ειδοποίηση για μπόνους αφού τα προσθέσετε και τα διαμορφώσετε από την πλαϊνή περιοχή.',
    configAlreadySaved: 'Έχετε διαμορφώσει ήδη τα μπόνους ή τους συμμετέχοντες, αφαιρέστε τους για να μπορέσετε να τους επεξεργαστείτε',
  },
};
