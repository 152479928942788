import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { IconSvg } from 'components/common';
import { StyledIconDefault } from 'components/styledComponents/icon/Icon.styled';
import Buttons from 'components/common/button/Buttons';
import { StyledModal } from 'components/styledComponents/modal/Modal.styled';
import { StyledTimeZoneMargin } from 'components/brandSetting/BrandSettings.styled';
import ChangeTimeZoneForm from 'components/brandSetting/ChangeTimeZoneForm';
import { TabInfo } from 'components/common/info';

const ChangeTimeZone = (props) => {
  const {
    visible, brand, editBrandTimeZone, onClose, timeZones,
  } = props;

  const { t } = useTranslation();

  const [form] = Form.useForm();
  const { validateFields } = form;

  const onApply = () => {
    validateFields().then((values) => {
      const { id } = brand;
      if (values.timeZone !== brand.timeZone) {
        editBrandTimeZone(id, values);
      } else {
        onClose();
      }
    });
  };

  return (
    <StyledModal
      maskClosable={false}
      destroyOnClose
      open={visible}
      title={t('edit')}
      closable
      onCancel={onClose}
      width="34.3rem"
      centered
      closeIcon={(
        <StyledIconDefault>
          <IconSvg icon="CloseModalIcon" width="1.14rem" height="1.14rem" />
        </StyledIconDefault>
      )}
      footer={<Buttons onClose={onClose} onApply={onApply} okText="apply" size="large" />}
    >
      <StyledTimeZoneMargin>
        <TabInfo content={t('brand:timezoneInfo')} />
        <ChangeTimeZoneForm brand={brand} form={form} timeZones={timeZones} />
      </StyledTimeZoneMargin>
    </StyledModal>
  );
};

ChangeTimeZone.propTypes = {
  visible: PropTypes.bool,
  brand: PropTypes.object,
  editBrandTimeZone: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  timeZones: PropTypes.array.isRequired,
};

ChangeTimeZone.defaultProps = {
  visible: false,
  brand: {},
};

export default ChangeTimeZone;
