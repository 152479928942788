import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { Descriptions } from 'antd';
import CheckPng from 'assets/img/check.png';
import { media, truncate, getBoxShadow } from 'utils/styledHelpers';
import { StyledIconHover } from 'components/styledComponents/icon/Icon.styled';
import { StyledMoreWrapper } from 'components/styledComponents/button/Button.styled';

const Global = createGlobalStyle`
  html.app-html {
    height: 100%;
    font-size: 14px;
    font-family: ${(props) => props.theme.typography.fontFamilyBase};
    overflow: hidden;

    @media only screen and (max-width: 1366px) {
      font-size: 13px;
    }
    @media only screen and (max-width: 1280px) {
      font-size: 12px;
    }

    button, input, select, textarea {
      font-family: ${(props) => props.theme.typography.fontFamilyBase};
    }

    a {
      color: ${(props) => props.theme.colors.blue};
      text-decoration: none;
    }

    ${media.sm`
      overflow: hidden auto;
    `}
  }
  body.app_body {
    min-height: 100%;
    font-family: ${(props) => props.theme.typography.fontFamilyBase};
    line-height: normal;
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    background-color: ${(props) => props.theme.colors.primaryBg};
    color: ${(props) => props.theme.colors.primaryText};
  }

  #root {
    height: 100dvh;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    ${media.sm`
      overflow: visible;
    `}
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.secondaryBg};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.primaryBorder};
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  }
  
  @supports not selector(::-webkit-scrollbar) {
    * {
      scrollbar-width: thin;
      scrollbar-color: ${(props) => props.theme.colors.primaryBorder} ${(props) => props.theme.colors.secondaryBg};
    }
  }
  
  ::selection {
    background-color: ${(props) => props.theme.colors.blue};
    color: #ffffff;
  }
  
  svg {
    display: block;
  }

  h1, h2, h3, h4, h5, h6, p {
    color: ${(props) => props.theme.colors.primaryText};
    line-height: unset;
  }

  strong {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium} !important;
  }

  .ant-upload-list-item-info > span {
    display: flex;
    align-items: center;
  }

  html.app-html .ant-layout-content.no-padding {
    padding: 0;
  }

  .ant-select-item-option-selected .anticon-check {
    display: none;
  }

  .layout {
    min-height: calc(100vh - 200px) !important;
  }

  .ant-select-dropdown-menu-item-group {
    &-title {
      font-weight: ${(props) => props.theme.typography.fontWeightMedium};
      font-size: ${(props) => props.theme.typography.fontSizeDefault};
      padding-left: 16px;
    }

    .ant-select-dropdown-menu-item-group-list li {
      padding-left: 32px !important;
    }
  }

  .ant-form-vertical .ant-form-item-label > .ant-form-item-required:after,
  .ant-form-horizontal .ant-form-item-label > .ant-form-item-required:after {
    display: inline-block;
    content: '*';
    visibility: visible;
  }

  .ant-checkbox-group {
    display: inline-block;
  }

  .ant-checkbox {
    top: -0.1rem;
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;

    span {
      font-size: 1rem;
    }

    .ant-checkbox-input[disabled] + .ant-checkbox-inner {
      background-color: ${(props) => props.theme.colors.secondaryBg} !important;
    }

    color: ${(props) => props.theme.colors.primaryText};

    .ant-checkbox-disabled + span {
      color: ${(props) => props.theme.colors.filterLabelColor};
    }

    .ant-checkbox-checked:not(.ant-checkbox-indeterminate) {
      .ant-checkbox-inner {
        background-color: ${(props) => props.theme.colors.blue} !important;
        border: 1px solid ${(props) => props.theme.colors.blue};
      }

      .ant-checkbox-input[disabled] + .ant-checkbox-inner {
        background-color: ${(props) => props.theme.colors.blue}60 !important;
      }
    }

    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: ${(props) => props.theme.colors.white};
    }

    .ant-checkbox-inner,
    .ant-checkbox-indeterminate .ant-checkbox-inner {
      background-color: ${(props) => props.theme.colors.secondaryBg};
      border: 1px solid ${(props) => props.theme.colors.primaryBorder};
    }

    .ant-checkbox-input[disabled] + .ant-checkbox-inner {
      background-color: ${(props) => props.theme.colors.white};
      border-color: ${(props) => props.theme.colors.primaryBorder} !important;
    }
  }

  .ant-card .ant-card-head {
    padding: 0 0 0 16px;
  }

  .ant-dropdown-menu-submenu-popup {
    padding: 0;

    ul {
      margin: 0 !important;
    }
  }

  .ant-form-item .ant-form-item-control-input {
    min-height: auto;
  }

  .draggable-columns table th {
    cursor: move !important;
    
    ${media.sm`
      cursor: auto !important;
    `}
  }
  .draggable-rows table tbody td.drag-row-handle {
    cursor: move !important;
  }

  .dragging-col-custom {
    border-bottom: 2px dashed blue !important;
  }

  .draggable-columns table .ant-table-column-sorter {
    cursor: pointer !important;
    z-index: 1;
  }

  .ant-tooltip .ant-tooltip-inner {
    min-height: initial;
    text-align: center;
    box-shadow: none;
  }

  .ant-tooltip {
    width: auto;
  }

  .ant-tooltip-arrow-content, .ant-tooltip-inner {
    color: ${(props) => props.theme.colors.white} !important;

    > div > span {
      color: ${(props) => props.theme.colors.white} !important;
    }
  }

  .ant-popover {
    .ant-popover-inner {
      background-color: ${(props) => props.theme.colors.selectBg};
      border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
      padding: 0;
    }

    .ant-popover-title {
      font-size: 1rem;
      padding: 1rem 1.71rem;
      border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder} !important;
      color: ${(props) => props.theme.colors.primaryText};
    }

    .ant-popover-inner-content {
      border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
      padding: 0.3rem 0;
      word-break: break-word;
      font-size: ${(props) => props.theme.typography.fontSizeDefault};
      color: ${(props) => props.theme.colors.primaryText};
    }

    .ant-popover-arrow:before {
      background-color: ${(props) => props.theme.colors.selectBg};
    }
  }

  .ant-popover-content > .ant-popover-arrow {
    display: none;
  }

  .ant-input-search .ant-input-affix-wrapper {
    height: 100%;
  }

  .ant-input-search > .ant-input-group > .ant-input-group-addon, .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: auto;
    height: 100%;
    padding: 0;

    .ant-btn.ant-btn-icon-only.ant-input-search-button {
      height: 100%;
      padding: 0;
    }
  }

  .description-popover {
    padding-top: 0;

    &-long {
      max-width: 50%;

      .ant-popover-inner-content {
        padding: 1.14rem;
        line-height: 22px;
      }
    }

    .ant-popover-inner-content {
      padding: 1.14rem;
      line-height: 22px;
    }
  }

  .ant-popover-arrow {
    display: none;
  }

  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: ${(props) => props.theme.colors.blue}15;
  }

  .ant-calendar-picker-clear {
    background: transparent;
  }

  .ant-select-selection--multiple .ant-select-selection__rendered,
  .ant-select-selection__rendered {
    margin-left: 16px;
  }

  input.ant-input,
  textarea.ant-input {
    padding: 10px 1.14rem;
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  }

  input.ant-input,
  span.ant-input-password, .ant-select {
    height: 100%;
  }

  .ant-select-item-option-content {
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
  }

  .ant-select-show-search.ant-select-single:not(.ant-select-customize-input).ant-select-disabled .ant-select-selector {
    cursor: not-allowed;
  }

  .ant-table-wrapper .ant-table {
    background: transparent;
  }

  .ant-form-item-has-success, .ant-form-item {
    .ant-form .ant-form-item-control {
      &:hover .ant-input:not([disabled]),
      &:hover .ant-select-selection,
      & .ant-select-selection:hover {
        border-color: ${(props) => props.theme.colors.blue};
        box-shadow: 0 0 0 2px ${(props) => props.theme.colors.blue}30;
      }

      &:focus .ant-input:not([disabled]),
      .ant-input:not([disabled]):focus {
        border-color: ${(props) => props.theme.colors.blue};
        box-shadow: 0 0 0 2px ${(props) => props.theme.colors.blue}30;
      }

      &:focus:not(.ant-select-disabled) .ant-select-selection,
      &:not(.ant-select-disabled).ant-select-selection:focus {
        border-color: ${(props) => props.theme.colors.blue};
        box-shadow: 0 0 0 2px ${(props) => props.theme.colors.blue}30;
      }

      .ant-select-focused .ant-select-selection {
        border-color: ${(props) => props.theme.colors.blue};
        box-shadow: 0 0 0 2px ${(props) => props.theme.colors.blue}30;
      }
    }

    .ant-input:hover {
      border-color: ${(props) => props.theme.colors.blue};
    }

    .ant-input:focus {
      box-shadow: 0 0 0 2px ${(props) => props.theme.colors.blue}30;
    }
  }

  .dragging-row-custom {
    border-top: 1px dashed ${(props) => props.theme.colors.blue} !important;
    border-bottom: 1px dashed ${(props) => props.theme.colors.blue} !important;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
  }

  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background: ${(props) => props.theme.colors.white};
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding-right: 1.29rem;
    font-size: 1rem;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 1.14rem;
  }

  .ant-form-item-explain {
    margin: 0;
    width: 100%;

    > div {
      background: ${(props) => props.theme.colors.red};
      border: 1px solid #c30909;
      border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
      box-sizing: border-box;

      span {
        color: ${(props) => props.theme.colors.primaryText};
      }
    }
  }

  .ant-select-item-group {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    color: ${(props) => props.theme.colors.primaryText};
  }

  .ant-select .ant-select-clear, .ant-picker .ant-picker-clear {
    width: 1.14rem;
    height: 1.14rem;
    margin-top: 0;
    transform: translateY(-50%);
    background: ${(props) => props.theme.colors.secondaryBg};

    > span {
      width: 100%;
      height: 100%;

      > svg {
        width: 100%;
        height: 100%;
        color: ${(props) => props.theme.colors.secondaryColor}90;
      
        &:hover {
          color: ${(props) => props.theme.colors.secondaryColor};
        }
      }
    }
  }

  ::-webkit-scrollbar {
    background: ${(props) => props.theme.colors.primaryBg}30;
  }

  .ant-checkbox-inner {
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusDefault};
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 1.14rem 0 1.1rem;
    background: ${(props) => props.theme.colors.secondaryBg};
    border: 1px solid ${(props) => props.theme.colors.primaryBorder};
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  }

  .ant-select-selection-item {
    color: ${(props) => props.theme.colors.primaryText};
  }

  .ant-tabs-tab-btn {
    ${truncate()}
    color: ${(props) => props.theme.colors.primaryText};
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-carousel .slick-slide {
    text-align: center;
    height: 160px;
    line-height: 160px;
    overflow: hidden;
  }

  .ant-carousel .slick-slide h3 {
    color: #fff;
  }

  .ant-picker-datetime-panel {
    width: 100%;
  }

  .schedule-modal {
    .ant-modal-content {
      height: 488px;

      .ant-modal-body {
        padding: 48px 20px 0;
      }
      .schedule-input {
        visibility: hidden;
      }
    }
  }

  .schedule-date-picker {
    width: 410px;

    .ant-picker-panel-container {
      border: 1px solid ${(props) => props.theme.colors.primaryBorder};
      border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
      box-shadow: none;
      margin-top: -40px;
    }

    .ant-picker-panel {
      width: 100%;
      border: none;

      .ant-picker-datetime-panel {
        font-size: ${(props) => props.theme.typography.fontSizeDefault};
      }

      .ant-picker-time-panel {
        width: 127.52px;

        .ant-picker-time-panel-column {
          overflow-y: auto;
        }

        .ant-picker-time-panel-column::after {
          height: auto;
        }
      }
    }
  }

  .account-modal-change-password {
    .ant-modal-body {
      padding: 3.43rem 3.43rem 0;
      ${media.sm`
        padding: ${(props) => (props.mdPaddings ? '1.4rem' : '1.4rem 1.4rem 0')};      
      `}
    }
  }

  .ant-select-option-indeterminate {
    &.ant-select-item-option:before {
      background: url(
      data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAFRJREFUGFfdkMENQFAUBGc1ogRKUQoV0In8BvxW6OBrxEqE24s42+PsnEbMuUOMiJZopmAGkZYCqkPpgWYVKftVus+/iR/y4E1X8MoTqIkzeedQfwK2eiJeUuEXcQAAAABJRU5ErkJggg==
      ) no-repeat center;
      background-size: 50%;
    }
  }

  .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
    div {
      font-size: ${(props) => props.theme.typography.fontSizeDefault};
    }
    &:not(.ant-dropdown-menu-item-disabled) {
      color: ${(props) => props.theme.colors.primaryText};
    }
  }

  .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }

  .brand-settings.ant-layout-sider {
    .ant-menu {
      &.ant-menu-root .ant-menu-item {
        padding-left: 1.45rem !important;
      }

      svg {
        g rect {
          fill: none;
        }
      }
    }
  }

  aside li {
    user-select: none;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: none;
  }

  .ant-select-selection-search {
    color: ${(props) => props.theme.colors.primaryText};
  }

  .ant-menu.ant-menu-root .ant-menu-submenu.ant-menu-submenu-active .ant-menu-submenu-title span {
    span {
      color: ${(props) => props.theme.colors.hover};
    }
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    padding-left: 0.45rem;
  }

  .ant-select-multiple .ant-select-selection-search {
    margin-left: 1.14rem;
  }

  .ant-select-multiple .ant-select-selection-item {
    padding-left: 1.14rem;
  }

  .rc-virtual-list-scrollbar {
    width: 0.43rem !important;
    height: 0.43rem !important;
  }

  .rc-virtual-list-scrollbar-thumb {
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall} !important;
    background-color: ${(props) => props.theme.colors.primaryBorder} !important;
  }

  .site-tree-search-value {
    color: #f50;
  }

  .ant-tabs-dropdown {
    min-width: 14.29rem;

    .ant-tabs-dropdown-menu {
      background: ${(props) => props.theme.colors.selectBg};
    }

    .ant-tabs-dropdown-menu-item {
      padding: 0.71rem 1.14rem;
      font-size: ${(props) => props.theme.typography.fontSizeDefault};

      &:hover {
        background: ${(props) => props.theme.colors.hoverBg};
      }
    }
  }

  .ant-btn:after {
    display: none;
  }
  
  .ant-btn.ant-btn-icon-only > span {
    transform: scale(1);
  }
  
  .anticon.anticon-search {
    font-size: 1.28rem;
  }

  .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    padding-right: 1.5rem;
  }

  .ant-input, .ant-input-affix-wrapper {
    font-size: 1rem;
    border: 1px solid ${(props) => props.theme.colors.primaryBorder};
    background: transparent;
    padding: 0.28rem 1.14rem;
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    &:hover {
      background: transparent;
    }
  }

  .ant-input-affix-wrapper-disabled.ant-input-affix-wrapper input.ant-input {
    color: ${(props) => props.theme.colors.filterLabelColor};
  }

  .ant-input-wrapper.ant-input-group,
  .ant-input-affix-wrapper.ant-input-search {
    height: 100%;
  }

  .ant-input-group {
    display: flex;
  }

  .ant-input-search > .ant-input-group > .ant-input-affix-wrapper {
    background: ${(props) => props.theme.colors.secondaryBg};
  }

  .ant-select-selector {
    padding-right: 2.85rem;
    font-size: 1rem;
  }

  .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-right-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    border-bottom-right-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    border: 1px solid ${(props) => props.theme.colors.primaryBorder};
  }

  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    border-bottom-left-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  }

  .ant-picker-range-arrow::after {
    border-color: ${(props) => props.theme.colors.tableTrEvenColor} ${(props) => props.theme.colors.tableTrEvenColor} transparent;
  }

  .ant-tabs {
    color: ${(props) => props.theme.colors.primaryText};
    
    .ant-tabs-tab {
      font-size: 1rem;

      &-active {
        border-bottom: 2px solid ${(props) => props.theme.colors.blue};
      }
    }

    .ant-tabs-nav-operations {
      .ant-tabs-nav-more {
        color: ${(props) => props.theme.colors.primaryText};
      }
    }
  }

  .ant-list {
    .ant-list-item {
      color: ${(props) => props.theme.colors.primaryText};
    }
  }

  .ant-tabs-nav-wrap {
    ::-webkit-scrollbar {
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  .ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
    width: 11.86rem;
  }

  .ant-dropdown .ant-dropdown-menu,
  .ant-dropdown-menu-submenu .ant-dropdown-menu,
  .ant-radio-inner {
    background-color: ${(props) => props.theme.colors.selectBg};
  }

  .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    border-top-color: ${(props) => props.theme.colors.secondaryBg};
    border-left-color: ${(props) => props.theme.colors.secondaryBg};
  }

  .ant-picker-range {
    width: 100%;
  }

  .ant-dropdown-menu-submenu-popup li {
    > div {
      display: flex;
      justify-content: space-between;
    }

    &:hover {
      background: ${(props) => props.theme.colors.hoverBg};
    }
  }

  .ant-dropdown-menu-submenu-title {
    height: 2.57rem;

    &:hover {
      background: ${(props) => props.theme.colors.hoverBg};
    }
  }

  .ant-select-item-option-content {
    color: ${(props) => props.theme.colors.primaryText};
  }

  .hidden {
    display: none !important;
  }

  .content-overlay {
    width: 100%;
    background: ${(props) => props.theme.colors.secondaryBg};
    overflow: hidden;
    height: 100%;
    position: absolute;
    z-index: 1000;
    opacity: 0.1;
  }

  .pdf-viewer-canvas {
    height: 28.5rem;
  }

  .read-more-popover .ant-popover-inner-content {
    background: #4d4d4c;
    width: 20rem;
  }

  .read-more-popover .ant-popover-inner-content p {
    color: ${(props) => props.theme.colors.secondaryBg};
  }

  //menu tooltip icon
  .ant-menu-inline-collapsed-tooltip .ant-tooltip-inner div > div {
    display: none;
  }

  .ant-menu-item.ant-menu-item-active:hover > div span {
    color: ${(props) => props.theme.colors.blue};
  }

  .ant-dropdown {
    &.fit-content {
      .ant-dropdown-menu-title-content {
        white-space: nowrap;
      }
    }

    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        font-size: 1rem;
        color: ${(props) => props.theme.colors.primaryText};
      }
    }
  }

  .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
    background-color: ${(props) => props.theme.colors.hoverBg};
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none !important;
  }

  .ant-message {
    z-index: 2000;

    .ant-message-notice-wrapper {
      .ant-message-notice-content {
        position: relative;
        border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
        padding-right: 46px;
        word-break: break-word;
        text-align: center;
        line-height: 22px;
        color: rgba(0,0,0,0.88);
        max-height: 90vh;
        overflow: auto;
      }
    }
  }

  .generate-image {
    width: 242px;
    height: 148px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .ant-select-multiple .ant-select-selection-overflow {
    overflow: hidden;
    flex-wrap: nowrap;
  }

  .ant-empty {
    .ant-empty-description {
      font-size: 1rem;
      color: ${(props) => props.theme.colors.filterLabelColor};
    }
  }

  .ant-modal-header, .ant-modal-content {
    .ant-modal-title {
      color: ${(props) => props.theme.colors.primaryText};
    }

    background: ${(props) => props.theme.colors.secondaryBg};
  }

  .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input-affix-wrapper:hover,
  .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    background: ${(props) => props.theme.colors.secondaryBg} !important;
  }

  .ant-input-affix-wrapper-disabled, .ant-select-disabled {
    background: ${(props) => props.theme.colors.grayButton} !important;
    border-color: ${(props) => props.theme.colors.primaryBorder} !important;
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    pointer-events: none;

    .ant-select-selector {
      background: ${(props) => props.theme.colors.grayButton} !important;
      border-color: ${(props) => props.theme.colors.primaryBorder} !important;
    }
  }

  .ant-picker.ant-picker-range.ant-picker-disabled {
    background: ${(props) => props.theme.colors.grayButton};
    border-color: ${(props) => props.theme.colors.primaryBorder};
  }

  .ant-picker-input > input[disabled] {
    color: ${(props) => props.theme.colors.filterLabelColor};
  }

  .description-popover .ant-popover-inner-content {
    padding: 0.57rem 1.14rem;
    max-width: 32rem;
  }

  .more-popover {
    padding-top: 0;

    .ant-popover-inner-content {
      padding: 16px;
      font-size: ${(props) => props.theme.typography.fontSizeDefault};
      max-height: 12.7rem;
      overflow-y: auto;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: none;
  }

  .ant-radio {
    top: -0.1rem;
  }

  .ant-radio-wrapper {
    align-items: center;
    color: ${(props) => props.theme.colors.primaryText};
    margin-inline-end: 1.14rem;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: transparent;

    span {
      color: ${(props) => props.theme.colors.blue};
    }
  }

  .multi-select {
    .ant-select-item-option {
      padding-left: 2.86rem;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0.86rem;
        width: 16px;
        height: 16px;
        border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusDefault};
        border: 1px solid ${(props) => props.theme.colors.primaryBorder};
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      &:before {
        content: '';
        width: 16px;
        background-color: ${(props) => props.theme.colors.blue};
        border: 1px solid ${(props) => props.theme.colors.blue};
        background-image: url(${CheckPng});
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .ant-select-item-option-disabled {
      .ant-select-item-option-content {
        color: ${(props) => props.theme.colors.secondaryColor};
      }

      &:before {
        border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
      }
    }
  }
  
  .ant-select-arrow svg{
      fill: ${(props) => props.theme.colors.primaryText};
  }

  .no-padding-left:not(.ant-popover-inner-content .no-padding-left) {
    padding-left: 0 !important;

    .ant-table-column-sorters {
      padding-left: 0 !important;
    }
  }

  .ant-form-item {
    .ant-form-item-label {
      padding-bottom: 8px;

      & > label {
        font-size: 1rem;
      }
    }
  }

  .ant-menu-title-content,
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .ant-picker-dropdown .ant-picker-panel-container,
  .ant-picker-ranges {
    background: ${(props) => props.theme.colors.selectBg};
    color: ${(props) => props.theme.colors.primaryText};
  }
  .ant-picker-dropdown:not(.datepicker-show-ok) {
    .ant-picker-footer .ant-picker-ranges {
      display: none;
    }
  }

  .ant-picker-footer-extra {
    padding: 0 12px;

    > div {
      display: inline-block;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .ant-form-item-control-input-content input.ant-input {
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  }

  //dark mode
  .ant-input-affix-wrapper > input.ant-input {
    color: ${(props) => props.theme.colors.primaryText};
  }

  .ant-form-item .ant-form-item-label > label {
    color: ${(props) => props.theme.colors.primaryText};
  }

  .ant-input-affix-wrapper > input.ant-input::placeholder {
    color: ${(props) => props.theme.colors.filterLabelColor};
  }

  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    background: ${(props) => props.theme.colors.secondaryBg};

    &:hover {
      background: ${(props) => props.theme.colors.secondaryBg};
    }
  }

  .ant-select-item-empty {
    padding: 0.57rem 0.71rem;
    color: ${(props) => props.theme.colors.filterLabelColor};
  }

  .ant-input-number,
  .ant-input-number-handler-wrap,
  .ant-picker {
    background: ${(props) => props.theme.colors.secondaryBg};
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    border-color: ${(props) => props.theme.colors.primaryBorder};
    
    &:hover {
      background: ${(props) => props.theme.colors.secondaryBg};
    }
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background: ${(props) => props.theme.colors.primaryBg};
    border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  }

  .ant-tabs-dropdown {
    .ant-tabs-dropdown-menu-item {
      color:${(props) => props.theme.colors.primaryText};
    }
  }

  .ant-tabs-dropdown-menu-item {
    color: ${(props) => props.theme.colors.primaryText};
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    background: ${(props) => props.theme.colors.secondaryBg};
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  }

  .ant-select-dropdown {
    background: ${(props) => props.theme.colors.selectBg};
  }

  .ant-picker-panel-container {
    .ant-picker-panel {
      background: ${(props) => props.theme.colors.selectBg};
    }
  }
  
  ${media.sm`
    .ant-picker-dropdown {
      left: 50% !important;
      transform: translateX(-50%) !important;
      
      .ant-picker-range-wrapper {
        min-width: unset !important;
      }
      
      .ant-picker-panel-container {
        margin-left: 0 !important;
        .ant-picker-panel-layout {
          flex-wrap: wrap;
          flex-direction: column-reverse;        
        }
        .ant-picker-panels {
          flex-wrap: wrap;
        }
        
        .ant-picker-presets {
          width: 100%;
          max-width: 100%;
          ul {
            height: auto;
          }
        }
      }
    }
  `}

  .ant-picker-focused, .ant-input-group-wrapper .ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper-focused {
    border: 1px solid ${(props) => props.theme.colors.blue};
  }

  .ant-form-item-has-error .ant-picker,
  .ant-picker:not(.ant-picker-disabled):not([disabled]).ant-picker-status-error,
  .ant-form-item-has-error .ant-picker.ant-picker-status-error.ant-picker-focused {
    background: ${(props) => props.theme.colors.secondaryBg};
  }

  .ant-input,
  .ant-input-number .ant-input-number-input,
  .ant-picker .ant-picker-input input {
    line-height: 2.28rem;
    color: ${(props) => props.theme.colors.primaryText};

    ::placeholder {
      color: ${(props) => props.theme.colors.filterLabelColor};
    }
  }

  .ant-select-selection-placeholder {
    color: ${(props) => props.theme.colors.filterLabelColor};
  }

  .ant-picker-header button {
    color: ${(props) => props.theme.colors.primaryText};
  }

  .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
    background: ${(props) => props.theme.colors.secondaryBg};
    border: 1px solid ${(props) => props.theme.colors.primaryBorder};
    color: ${(props) => props.theme.colors.primaryText};
  }

  .ant-menu,
  .ant-drawer-content,
  .ant-drawer-header {
    background: ${(props) => props.theme.colors.selectBg};
  }

  .ant-checkbox-disabled + span {
    color: ${(props) => props.theme.colors.primaryText};
  }

  .ant-picker-dropdown {
    .ant-picker-cell.ant-picker-cell-in-view, .ant-picker-header-view,
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
      color: ${(props) => props.theme.colors.primaryText};
    }
    .ant-picker-presets {
      line-height: 1.57rem;
    }
  }

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    background: ${(props) => props.theme.colors.hoverBg};
  }

  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-start::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: ${(props) => props.theme.colors.hoverBg} !important;
  }

  .ant-picker-cell,
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner,
  .ant-picker-time-panel-cell-disabled {
    color: ${(props) => props.theme.colors.primaryText};
    vertical-align: middle;
  }

  .ant-menu-item {
    &-selected {
      ${media.sm`
        pointer-events: none;
      `}
    }
    &:hover {
      span {
        color: ${(props) => props.theme.colors.blue};
      }
    }
  }

  .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input:hover,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover,
  .ant-form-item-has-error .ant-input-number:not([disabled]):hover,
  .ant-form-item-has-error .ant-picker:not([disabled]):hover {
    background: transparent;
  }

  .ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input,
  .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper-focused,
  .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:focus,
  .ant-form-item-has-error :not(.ant-input-disabled).ant-input-focused,
  .ant-form-item-has-error :not(.ant-input-disabled).ant-input:focus {
    box-shadow: none;
  }

  .ant-menu-submenu-popup.ant-menu-submenu > .ant-menu,
  .rc-virtual-list-holder-inner .ant-select-item.ant-select-item-option {
    background: ${(props) => props.theme.colors.selectBg};
  }

  .rc-virtual-list-holder-inner .ant-select-item.ant-select-item-option-active {
    background: ${(props) => props.theme.colors.hoverBg};
  }

  .ant-empty-image {
    svg g {
      path:nth-child(2),
      ellipse {
        fill: ${(props) => props.theme.colors.secondaryBorder};
      }

      g {
        stroke: ${(props) => props.theme.colors.filterLabelColor};
      }
    }
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: ${(props) => props.theme.colors.hoverBg};
  }

  .ant-picker-dropdown .ant-picker-content th {
    color: ${(props) => props.theme.colors.filterLabelColor};
  }

  .ant-select-disabled .ant-select-selection-item {
    color: ${(props) => props.theme.colors.filterLabelColor};
  }

  // for expanded small table second td to have left position
  .ant-small-table-fix-left:not(.ant-table-small.ant-table-empty .ant-small-table-fix-left) {
    left: 38px !important;
    @media (max-width: 1366px) {
      left: 35px !important;
    }
  }

  // Dashboard widgets drawer
  .dashboard-widget-drawer {
    top: 6.85rem !important;
    height: calc(100vh - 75px);

    .ant-drawer-header {
      border-radius: 10px 10px 0 0;
      padding: 1.14rem 1.14rem 1.71rem;
      border-bottom: none;

      &-title {
        width: 100%;
      }
    }

    .ant-drawer-close {
      cursor: default;
      padding: 0;
      width: 100%;
      margin-right: 0;
      
      &:hover {
        background-color: transparent;
      }
    }

    .ant-drawer-body {
      background-color: ${(props) => props.theme.colors.selectBg};
      overflow: hidden;
      padding: 0;
    }

    .ant-drawer-content {
      border-radius: 10px 10px 0 0;

      &-wrapper {
        border-radius: 10px 10px 0 0;
      }
    }
  }

`;

const FormItemMarginWrapper = styled.div`
  margin-bottom: ${(props) => (props.bigMarginBottom ? '2.3rem' : '1.71rem')};
`;
const FormItemMarginTopWrapper = styled.div`
  margin-top: ${(props) => (props.bigMarginTop && '2.85rem') || (props.mediumMarginTop && '2.57rem') || '1.71rem'};
  display: ${(props) => (props.hidden ? 'none' : 'block')};
`;

const StyledBackWrapper = styled.div`
  padding: 1.14rem 1.71rem;
  height: 3.57rem;
  position: relative;
  border-bottom: ${(props) => (props.noBorder || props.noBorderBottom ? 'none' : `1px solid ${props.theme.colors.secondaryBorder}`)};
  border-top-right-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  border-top-left-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  color: ${(props) => props.theme.colors.primaryText};
  background: ${(props) => props.theme.colors.secondaryBg};
  box-shadow: -0px -5px 16px -10px ${(props) => props.theme.colors.secondaryShadow};
  z-index: 11;
  width: 100%;
`;

const StyledBackClick = styled.span`
  display: inline-flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primaryText};
  > div {
    margin-right: 0.86rem;
  }
`;
const StyledTruncate = styled.div`
  ${truncate()};
  max-width: ${(props) => (props.largeWidth ? '19rem' : '14rem')};
  max-height: 4.57rem;
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
  margin: ${(props) => (props.isCentered ? '0 auto' : '')};
  font-weight: ${(props) => (props.isMedium ? props.theme.typography.fontWeightMedium : props.theme.typography.fontWeightRegular)};
  display: block;
  cursor: default;
`;
const DescriptionList = styled(Descriptions)`
  .ant-descriptions-view {
    .ant-descriptions-item-label::after {
      display: none;
    }

    .ant-descriptions-item-content {
      font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    }

    .ant-descriptions-row {
      &:last-child {
        border-bottom: ${(props) => (props.nopadding ? 'none' : '2rem solid transparent')};
      }

      .ant-descriptions-item-container {
        margin: 0.57rem 0 0.57rem 0.14rem;
      }
    }

    .ant-descriptions-item {
      position: relative;
      padding-bottom: 0;

      &:not(:first-of-type) {
        padding-left: 1.71rem;
        padding-right: 1.71rem;

        ${media.sm`
          padding-left: 1rem;
          padding-right: 1rem;
        `}
      }

      &:last-of-type {
        padding-right: 0 !important;
      }

      &:not(:last-of-type):after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 1px;
        background-color: ${(props) => props.theme.colors.secondaryBorder};
      }

      &-label {
        color: ${(props) => props.theme.colors.filterLabelColor};
        font-size: ${(props) => props.theme.typography.fontSizeDefault};

        ${truncate()}
        span {
          ${truncate()}
        }
      }

      &-content {
        font-size: ${(props) => props.theme.typography.fontSizeDefault};
        color: ${(props) => props.theme.colors.primaryText};
      }
    }
  }
`;
const StyledViewList = styled.div`
  .ant-descriptions-item-container .ant-descriptions-item-content {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    padding-right: 0.7rem;
    margin-left: 0.57rem;
    display: flex;
    max-width: 70%;
    flex: 0 0 auto;
    ${truncate()}
    span {
      ${truncate()}
    }
  }
  .ant-descriptions-item-content {
    > span,
    > div {
      font-weight: ${(props) => props.theme.typography.fontWeightMedium};
      div {
        justify-content: flex-start;
      }
    }
  }
  .ant-descriptions-row:first-child .ant-descriptions-item-container {
    display: flex;
    align-items: center;
  }
`;
const StyledSingleDescriptionListItem = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${(props) => (props.noPadding ? '0' : '2rem')};
  margin-bottom: ${(props) => (props.marginBottom ? '0.57rem' : '0')};

  span {
    color: ${(props) => props.theme.colors.filterLabelColor};
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    ${truncate()}
  }

  p,
  ${StyledMoreWrapper} {
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    color: ${(props) => props.theme.colors.primaryText};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    margin-left: 0.57rem;
  }
`;
const StyledBackgroundWrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.secondaryBg};
  height: ${(props) => (props.isFullHeight ? '100%' : 'auto')};
  margin-top: ${(props) => (props.marginTop ? '1.43rem' : '0')};
  border-radius: ${(props) => `${props.theme.customBorderRadius.borderRadiusMiddle} ${props.theme.customBorderRadius.borderRadiusMiddle} 0 0`};
  padding-bottom: 4rem;
  box-shadow: ${(props) => (props.noShadow ? 'none' : getBoxShadow(props.theme.colors.secondaryShadow))};
  display: flex;
  flex-direction: column;
  flex-grow: ${(props) => (props.disableGrow ? 'initial' : '1')};
  form {
    padding: 0 2.85rem;
    height: auto;
    flex-grow: 1;
    background-color: ${(props) => props.theme.colors.secondaryBg};
    border-radius: ${(props) => `${props.theme.customBorderRadius.borderRadiusMiddle} ${props.theme.customBorderRadius.borderRadiusMiddle} 0 0`};
    display: flex;
    flex-direction: column;
    ${media.sm`
       padding: 0 1.14rem;
    `}
  }
  .ant-tabs {
    overflow: ${(props) => (props.isOverflow ? 'initial' : 'hidden')};
  }
`;
const StyledBackgroundContent = styled.div`
  padding: ${(props) => (props.isPlayer ? '0 1rem' : '0 2.85rem')};
  border-top: ${(props) => (props.isPlayer ? 'none' : `1px solid ${props.theme.colors.secondaryBorder}`)};

  ${media.sm`
    padding: ${(props) => (props.isPlayer ? '0' : '0 1.4rem')};
  `}
`;
const StyledNoData = styled.div`
  display: flex;
  height: ${(props) => (props.isHeight ? '100%' : '')};
  flex-grow: ${(props) => (props.isHeight ? '1' : '')};

  flex-direction: column;
  justify-content: center;
  > div {
    justify-content: center;
  }
`;
const StyledNoDataImg = styled.div`
  display: flex;
  svg g g g g g path:first-child {
    fill: #fff;
  }
  svg g g g > path {
    fill: #ccc;
  }
`;

const StyledNoDataText = styled.p`
  font-size: 1.14rem;
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.colors.primaryText}60;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const StyledBannerWrapper = styled.div`
  width: ${(props) => (props.isFullWidth ? '100%' : '72.4rem')};
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  .ant-empty-normal {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const StyledTruncateText = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 0;
`;

const StyledTruncatedWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledBannerWrapperBg = styled(StyledBannerWrapper)`
  background-color: ${(props) => props.theme.colors.secondaryBg};
  border-radius: ${(props) => `${props.theme.customBorderRadius.borderRadiusMiddle} ${props.theme.customBorderRadius.borderRadiusMiddle} 0 0`};
  box-shadow: ${(props) => getBoxShadow(props.theme.colors.secondaryShadow)};
`;

const StyledUpdateDate = styled.div`
  position: absolute;
  top: -2rem;
  right: 0;
  z-index: 11;
  color: ${(props) => props.theme.colors.secondaryColor};
  span {
    margin-left: 8px;
  }
`;

const StyledMarginTopWrapper = styled.div`
  margin-top: 1.14rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  height: ${(props) => (props.isHeight ? 'auto' : '100%')};
  display: ${(props) => (props.isFlex ? 'flex' : 'block')};
  flex-grow: ${(props) => (props.isHeight ? '1' : '')};
`;
const ComponentSeparator = styled.hr`
  margin: ${(props) => (props.mediumSize ? '0 0 2.7rem' : ' 0 0 1.14rem')};
  border: none;
  width: 0;
`;
const TooltipIconWrapper = styled.div`
  cursor: pointer;
`;

const StyledViewPermissionPagesWrapper = styled.div`
  p {
    margin-bottom: 1.71rem;
  }
  > div {
    font-size: 1rem;
    margin-bottom: 2.28rem;
  }
  .ant-descriptions-view {
    padding-left: 0;
  }
`;

const StyledDeletedItem = styled.span`
  color: ${(props) => props.theme.colors.danger};
`;
const StyledDisabledItem = styled.span`
  color: ${(props) => props.theme.colors.filterLabelColor};
`;

const StyledSmallMarginBottom = styled.div`
  margin-bottom: 1.14rem;
`;

const StyledEmptyConfigWrapper = styled.div`
  padding: 1.71rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  background-color: ${(props) => props.theme.colors.primaryBg};
  text-align: center;
  margin: 0.71rem 0;
  border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  h4 {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    margin-bottom: 0.57rem;
  }
`;

const EditCreateButtonGroup = styled.div`
  display: flex;
  padding-top: ${(props) => (props.smallPadding ? '1.14rem' : '4rem')};
  margin-top: auto;
  justify-content: flex-end;
  .ant-btn {
    margin-left: 1.14rem;
  }
  ${media.xs`
    flex-wrap: wrap;
    row-gap: 0.71rem;
  `}
`;

const StyledConditionItem = styled.div`
  height: 100%;
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
  padding: 0 1.14rem 1rem;
  border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    border-color: ${(props) => props.theme.colors.blue}60;
    h6 {
      color: ${(props) => props.theme.colors.blue};
    }
  }

  ${(props) => props.isFilled
    && `
    border-color: ${props.theme.colors.blue}80;
    background: linear-gradient(137.6deg, rgba(6, 177, 190, 0.2) 3.6%, rgba(0, 161, 179, 0.05) 95.65%);
    h6 {
      color: ${props.theme.colors.blue};
    }
  `}

  ${(props) => props.isSelected
    && `
    background: linear-gradient(137.6deg, ${props.theme.colors.blue}40 4%,${props.theme.colors.blue}02 96%);
    border-color: ${props.theme.colors.blue}40;
    h6 {
      color: ${props.theme.colors.blue};
    }
  `}

  .ant-card-meta {
    margin: 0;
    .ant-card-meta-detail {
      width: 100%;
      .ant-card-meta-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.14rem;
        border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
      }
    }
  }
`;
const StyledConditionCardTitle = styled.h6`
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
  padding: 1rem 0;
  transition: 0.3s color;
  ${truncate()}
`;
const StyledConditionReset = styled(StyledIconHover)`
  padding: 0.5rem;
  border-bottom: none;
`;
const StyledConditionDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
`;
const StyledConditionConfigComponent = styled.div`
  max-width: ${(props) => (props.isWide ? '800px' : '354px')};
  margin: 0 auto;
`;

const StyledMessageCloseIcon = styled.span`
  position: absolute;
  padding: 14px 16px;
  right: 0;
  top: 0;
  cursor: pointer;
`;

const animateHeight = (maxHeight) => keyframes`
  to {
    max-height: ${maxHeight || '40rem'};
    opacity: 1;
  }
`;
const StyledAnimateHeight = styled.div`
  max-height: 0;
  opacity: 0;
  animation: ${(props) => animateHeight(props.maxHeight)} 0.6s forwards;
  overflow: ${(props) => (props.hasShadow ? '' : 'hidden')};
`;

const StyledPreviewResultWrapper = styled.div`
  background: ${(props) => props.theme.colors.primaryBg};
  border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  padding: 1.29rem 1.71rem 0.72rem;
  margin-top: ${(props) => (props.noMargin ? '0' : '2.42rem')};
`;
const StyledPreviewResultList = styled.ul`
  display: flex;
  overflow-x: auto;
  padding-bottom: 0.57rem;
`;
const StyledPreviewResultItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 1.71rem;
  color: ${(props) => props.theme.colors.primaryText};
  white-space: nowrap;
  font-size: 1rem;

  &:not(:last-of-type) {
    border-right: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  }

  @media (max-width: 1200px) {
    padding: 0 1.14rem;
  }
  > div {
    display: flex;
    align-items: center;
  }
  strong {
    margin-left: 0.28rem;
    color: ${(props) => props.theme.colors.primaryText};
    white-space: nowrap;
  }
  &:first-child:before {
    position: absolute;
    content: '';
    left: 0;
    width: 1.07rem;
    border-bottom: 2px solid ${(props) => props.theme.colors.secondaryBorder};
  }
`;
const StyledPreviewResultLastItem = styled.li`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  gap: 1.71rem;
  position: sticky;
  right: 0;
  background: ${(props) => props.theme.colors.primaryBg};
  padding-left: 1.71rem;
`;
const StyledResultNumberValue = styled.span`
  padding: 0.28rem 0.57rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  font-size: 1rem;
  color: ${(props) => (props.isMissing ? props.theme.colors.danger : props.theme.colors.blue)};
  background-color: ${(props) => (props.isMissing ? `${props.theme.colors.danger}20` : `${props.theme.colors.blue}20`)};
  &:not(:last-child) {
    margin-right: 0.85rem;
  }
`;

const StyledRelativeWrapper = styled.div`
  position: relative;
`;
const StyledWrapperHeading = styled.h4`
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-size: ${(props) => (props.small ? props.theme.typography.fontSizeDefault : props.theme.typography.fontSizeMiddle)};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
`;

const StyledTitleWithInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: ${(props) => (props.noMargin ? '0' : '1.43rem')};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  text-transform: ${(props) => (props.isUppercase ? 'uppercase' : 'none')};
`;

const StyledGeneralConfigWrapper = styled.div`
  margin-bottom: 1.71rem;
`;

const StyledExpandableWrapper = styled.div`
  transition: 0.3s all;
  max-height: 100rem;
  overflow: hidden;
  padding-top: 1rem;
  &.collapsed {
    max-height: 0;
    opacity: 0;
    padding-top: 0;
  }
`;

const StyledMaintenancePeriod = styled.span`
  display: flex;
  align-items: center;
  gap: 1rem;

  ${(props) => !props.isInTable
    && `
    margin-left: 1.14rem;
    padding-left: 1.57rem;
    border-left: 1px solid ${props.theme.colors.secondaryBorder};
  `}

  & > span {
    display: flex;
    gap: 0.57rem;
  }
`;
const StyledMaintenancePeriodDates = styled.span`
  display: flex;
  align-items: center;
  gap: 0.57rem;
  margin-right: 0.57rem;
`;

const StyledSidebarInner = styled.div`
  @media (max-width: 1200px) {
    overflow-y: auto;
    padding-right: 1rem;
    height: 100%;
  }
`;

const StyledUploadImageResultWrapper = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  svg {
    width: 14px;
    height: 14px;
    fill: ${(props) => props.theme.colors.white};
    top: -0.5rem;
    right: -0.5rem;
    position: absolute;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
  }
  img {
    width: 32px;
    height: 32px;
  }
  &:hover svg {
    opacity: 1;
  }
`;
export default Global;

export {
  ComponentSeparator,
  FormItemMarginWrapper,
  FormItemMarginTopWrapper,
  StyledMarginTopWrapper,
  StyledNoData,
  StyledNoDataImg,
  StyledNoDataText,
  StyledBackWrapper,
  StyledTruncate,
  DescriptionList,
  StyledBackgroundWrapper,
  StyledBackgroundContent,
  StyledBannerWrapperBg,
  StyledBannerWrapper,
  StyledBackClick,
  StyledTruncateText,
  StyledTruncatedWrapper,
  StyledUpdateDate,
  StyledViewList,
  StyledSingleDescriptionListItem,
  TooltipIconWrapper,
  StyledViewPermissionPagesWrapper,
  StyledDeletedItem,
  StyledDisabledItem,
  StyledSmallMarginBottom,
  StyledEmptyConfigWrapper,
  EditCreateButtonGroup,
  StyledConditionItem,
  StyledConditionCardTitle,
  StyledConditionReset,
  StyledConditionDescription,
  StyledConditionConfigComponent,
  StyledMessageCloseIcon,
  StyledAnimateHeight,
  StyledPreviewResultWrapper,
  StyledPreviewResultList,
  StyledPreviewResultItem,
  StyledPreviewResultLastItem,
  StyledResultNumberValue,
  StyledRelativeWrapper,
  StyledWrapperHeading,
  StyledExpandableWrapper,
  StyledTitleWithInfo,
  StyledGeneralConfigWrapper,
  StyledMaintenancePeriod,
  StyledMaintenancePeriodDates,
  StyledSidebarInner,
  StyledUploadImageResultWrapper,
};
