export default {
  en: {
    identityVerified: 'Identity Verified',
    emailVerified: 'Email Verified',
    mobileVerified: 'Mobile Verified',
    addressVerified: 'Address Verified',
    accountLocked: 'Account Locked',
    locked: 'Locked',
    accountInactive: 'Account Inactive',
    blockedForMessages: 'Blocked for Messages',
    closed: 'Closed',
    inactiveAccount: 'Inactive Account',
    accountInTimeout: 'Account in Time-out',
    accountSelfExcluded: 'Account Self-Excluded',
    exclusionState: 'Exclusion State',
    physicalVerification: 'Physical Verification',
    blacklisted: 'Blacklisted',
    regDate: 'Reg. Date',
    regIP: 'Reg. IP',
    emailNotifications: 'Email Notifications',
    SMSNotifications: 'SMS Notifications',
    accountType: 'Account Type',
    accountUsers: 'Account Users',
    lastProvisionDate: 'Last Provision Date',
    lastAdjustmentDate: 'Last Adjustment Date',
    currencyDeletedOrDisabledTooltip: 'Account currency may have been disabled/deleted',
    adjustBalance: 'Adjust Balance',
    finishUse: 'Finish Use',
    closedAccountNote: 'Account will get closed and will no longer be available.',
    freeAccountNote: 'Account will become free for use.',
    testAccountDetails: 'Test Account Details',
    userDetails: 'User Details',
    accountUser: 'Account User',
    userEmail: 'User Email',
    userEmailTooltip: "Account's credentials will be sent to the provided email",
    adjust: 'Adjust',
    moneyTransferred: 'Money Transferred',
    moneyUsed: 'Money Used',
    periodStart: 'Period Start',
    periodEnd: 'Period End',
    provisionDate: 'Provision Date',
    providedBy: 'Provided By',
    attachmentId: 'Attachment ID',
    startProcess: 'Start Process',
    manageFiles: 'Manage Files',
    blockControl: 'Block Control',
    unblockMessages: 'Unblock Messages',
    investigationResults: 'Investigation Results',
    noAvailableAccountInfo: 'Account will no longer be available for use.',
    lComplete: 'complete',
    lIncomplete: 'incomplete',
    accountVerificationProgress: 'Account Verification Progress',
    newslettersAndPromotions: 'Newsletters and Promotions',
    betLimit: {
      modalTitle: 'Set Bet Limit',
      name: 'Bet Limit',
      description: 'Control the money player spends on gambling',
      buttonText: 'Set Limit',
    },
    withdrawalLimit: {
      modalTitle: 'Set Withdrawal Limit',
      name: 'Withdrawal Limit',
      description: 'Control the money player withdraws',
      buttonText: 'Set Limit',
    },
    depositLimit: {
      modalTitle: 'Set Deposit Limit',
      name: 'Deposit Limit',
      description: 'Control the money player deposits for gambling',
      buttonText: 'Set Limit',
    },
    timeOutLimit: {
      modalTitle: 'Set Time-Out',
      name: 'Time-Out',
      description: 'Give a short break to player from all gambling activities',
      buttonText: 'Set Time-Out',
    },
    selfExclusionLimit: {
      modalTitle: 'Set Exclusion',
      name: 'Self-Exclusion',
      description: 'Exclude player from all gambling activities to stay in control',
      buttonText: 'Set Exclusion',
    },
    realityCheckLimit: {
      modalTitle: 'Set Check',
      name: 'Reality Check',
      description: 'Remind the player spent on the platform',
      buttonText: 'Set Check',
    },
    accessLimit: {
      modalTitle: 'Set Access Limit',
      name: 'Access Limit',
      description: 'Limit the player access to the website',
      buttonText: 'Set Limit',
    },
    lossLimit: {
      modalTitle: 'Set Loss Limit',
      name: 'Loss Limit',
      description: 'Controls the money player loses while gambling',
      buttonText: 'Set Limit',
    },
    singleBetLimit: {
      modalTitle: 'Set Single Bet Limit',
      name: 'Single Bet Limit',
      description: 'Controls the maximum single  stake amount player can bet',
      buttonText: 'Set Limit',
    },
    noLimit: 'No Limit',
    current: 'Current',
    remainder: 'Remainder',
    upcomingChanges: 'Upcoming Changes',
    limitType: 'Limit Type',
    dateRequested: 'Date Requested',
    endOfLimitation: 'End of Limitation',
    appliedBy: 'Applied By',
    exportNoteInfo: 'Up to 6 months data will be exported.',
    accountStatusBeforeClosure: 'Account Status Before Closure',
    accountRegistrationDate: 'Account Registration Date',
    accountClosureDate: 'Account Closure Date',
    reasonOfClosure: 'Reason Of Closure',
    matchingCriteria: 'Matching Criteria',
    dateAgreed: 'Date Agreed',
    accountInformation: 'Account Information',
    test: 'Test',
    personalInformation: 'Personal Information',
    walletInformation: 'Wallet Information',
    age: 'Age',
    countryCity: 'Country, City',
    mobile: 'Mobile',
    documents: 'Documents',
    currencyConverter: 'Currency Converter',
    pendingFileGroups: 'Pending File Groups',
    verifiedWith: 'Verified With',
    uploadAsApproved: 'Upload as Approved',
    archive: 'Archive',
    unverifyPlayerIdentity: "Unverify player's identity",
    unverifyPlayerAddress: "Unverify player's address",
    fileNameAndExtension: 'File Name and Extension',
    approvedBy: 'Approved By',
    notBlacklisted: 'Not Blacklisted',
    statusBefore: 'Status Before',
    statusAfter: 'Status After',
    currentStatus: 'Current Status',
    playersWithMissingInfo: 'Players with missing info',
    verifyDocumentInfo: 'Make sure that all the approved documents contain complete information before verification.',
    notes: 'Notes',
    markAttention: 'Mark Attention',
    playerSession: 'Player session',
    playerBalance: 'Player balance',
    playersBalance: "Players' balances",
    testPlayersAccount: "Test player's account",
    accountUsersInformation: "Account user's information",
    process: 'Process',
    attentionLevel: 'Attention Level',
    playerNotes: 'Player Notes',
    cards: 'Cards',
    sessionStart: 'Session Start',
    sessionEnd: 'Session End',
    sessionEndReason: 'Session End Reason',
    walletTransactionId: 'Wallet TXN ID',
    realWalletAfter: 'Real Wallet After',
    history: 'History',
    sumRealWallets: 'Sum Real Wallets',
    actionLogs: 'Action Logs',
    day1HoursLimitAmount: '24 Hours Limit Amount',
    days7LimitAmount: '7 Days Limit Amount',
    month1LimitAmount: '1 Month Limit Amount',
    selectedProviders: 'Selected Providers',
    countryOfCitizenship: 'Country Of Citizenship',
    lCountryOfCitizenship: 'country of citizenship',
    citizenshipVerified: 'Citizenship Verified',
    blockProviders: 'Block Providers',
    blockedProvidersList: 'Blocked Providers list',
    blockedProviders: 'Blocked Providers',
    lastDeposit: 'Last Deposit',
    lastWithdrawal: 'Last Withdrawal',
    last24hDeposits: 'Deposits within the last 24h',
    last24hWithdrawals: 'Withdrawals within the last 24h',
    takenMonthData: 'Only data from the last {{count}} months is taken into account',
    method: 'Method',
    playerActivities: 'Player Activities',
    lastLoginInfo: 'Last Login Info',
    registrationInfo: 'Registration Info',
    responsibleGamingLimits: 'Responsible Gaming Limits',
    p2pGgr: 'P2P GGR',
    similarAccountsInfo: 'Here we can see accounts filtering during the last month with these criteria {{criteria1}} and {{criteria2}}',
    nationality: 'Nationality',
    pep: 'PEP',
    verticalCategoryNoReasonInfo: 'Please activate the reasons for this category or edit the reasons requirement from the category',
    clickToSeePendingFiles: 'Click on the icon to see pending files',
    repetitionPeriod: 'Repetition Period',
    subAffiliateId: ' Sub Affiliate ID',
    repetitionDate: 'Repetition Date',
    approvalDate: 'Approval Date',
    debtWallet: 'Debt Wallet',
    personalNumericCode: 'Personal Numeric Code',
    personalNumericCodeNote: 'If checked, Personal Numeric Code field is added to the Document Verification flow, can be verified with Document and is available in different reports.',
    brandRealityCheckLimitInfo: 'The limit is configured for all brand players, and it is not possible to change the limit for the player individually',
    externalPlayerId: 'External Player ID',
    documentDescriptionInfo: 'Please describe the meaning of the document',
    realWalletBefore: 'Real Wallet Before',
    resetPassword: 'Reset Password',
    PlayerMobileNumberInUse: 'A player with this phone number already exists',
    PlayerEmailInUse: 'A player with this email address already exists',
    cpfNumber: 'CPF Number',
    disableTFA: 'Disable 2FA',
    tFA: '2FA',
    missingFieldsInAttachmentsInfo: 'The required data for approving this document has not been filled',
    year1LimitAmount: '1 Year Limit Amount',
    pushNotification: 'Push Notification',
    indicators: 'Indicators',
    turnover: 'Turnover',
    totalNetBalance: 'Total Net Balance',
    extraWallets: 'Extra Wallets',
    tillDate: 'Till Date',
    tillDateInfo: 'Till Date can be selected at least seven days after the present date',
    currentType: 'Current Type',
    upcomingType: 'Upcoming Type',
    upcomingDate: 'Upcoming Date',
    editAccessLimit: 'Edit Access Limit',
    weekDays: 'Weekdays',
    inactivePeriodTime: 'Inactive Period Time',
    setBy: 'Set by',
    effectDate: 'Effect Date',
    accessLimitTimezoneInfo: 'The Access Limit will work with brand default timezone: {{timezone}}',
    upcoming: 'Upcoming',
    timeTo: 'Time to',
    timeFrom: 'Time from',
    viewAccessLimit: 'View Access Limit',
    cpfNumberVerified: 'CPF Verified',
    deposit: 'Deposit',
    withdrawal: 'Withdrawal',
    smsPreferencesDescription: 'Select the events for which the player will receive SMS notifications.',
    emailPreferencesDescription: 'Select the events for which the player will receive Email notifications.',
    pushPreferencesDescription: 'Select the events for which the player will receive Push Notifications.',
    blockedPayments: 'Blocked Payments',
    blockPayments: 'Block Payments',
    unblockPayments: 'Unblock Payments',
    endSessions: 'End Sessions',
    showLastLoginInfo: 'Show Last Login Info',
    showLastLoginInfoNote: 'By enabling the player will see the last login information on the website.',
  },
  es: {
    identityVerified: 'Identidad verificada',
    emailVerified: 'Correo electrónico verificado',
    mobileVerified: 'Móvil verificado',
    addressVerified: 'Dirección verificada',
    accountLocked: 'Cuenta bloqueada',
    locked: 'Bloqueada',
    accountInactive: 'Cuenta inactiva',
    blockedForMessages: 'Bloquedada para mensajes',
    closed: 'Cerrada',
    inactiveAccount: 'Cuenta inactiva',
    accountInTimeout: 'Cuenta en tiempo muerto',
    accountSelfExcluded: 'Cuenta autoexcluida',
    exclusionState: 'Estado de exclusión',
    physicalVerification: 'Verificación física',
    blacklisted: 'Incluido en la lista de no admitidos',
    regDate: 'Fecha de reg.',
    regIP: 'IP de reg.',
    emailNotifications: 'Notificaciones por correo electrónico',
    SMSNotifications: 'Notificaciones por SMS',
    accountType: 'Tipo de cuenta',
    accountUsers: 'Usuarios de la cuenta',
    lastProvisionDate: 'Fecha de última oferta',
    lastAdjustmentDate: 'Fecha de último ajuste',
    currencyDeletedOrDisabledTooltip: 'La moneda de la cuenta puede haber sido desactivada/borrada',
    adjustBalance: 'Ajustar saldo',
    finishUse: 'Uso de la terminación',
    closedAccountNote: 'La cuenta se cerrará y dejará de estar disponible.',
    freeAccountNote: 'La cuenta pasará a ser de uso gratuito.',
    testAccountDetails: 'Datos de la cuenta de prueba',
    userDetails: 'Datos del usuario',
    accountUser: 'Usuario de la cuenta',
    userEmail: 'Correo electrónico del usuario',
    userEmailTooltip: 'Las credenciales de la cuenta se enviarán al correo electrónico proporcionado',
    adjust: 'Ajustar',
    moneyTransferred: 'Dinero transferido',
    moneyUsed: 'Dinero transferido',
    periodStart: 'Inicio del periodo',
    periodEnd: 'Fin del periodo',
    provisionDate: 'Fecha de la oferta',
    providedBy: 'Proporcionado por',
    attachmentId: 'ID del anexo',
    startProcess: 'Iniciar proceso',
    manageFiles: 'Gestionar archivos',
    blockControl: 'Bloquear control',
    unblockMessages: 'Desbloquear mensajes',
    investigationResults: 'Resultados de la investigación',
    noAvailableAccountInfo: 'La cuenta dejará de estar disponible para su uso',
    lComplete: 'completo',
    lIncomplete: 'incompleto',
    accountVerificationProgress: 'Progreso de la verificación de cuenta',
    newslettersAndPromotions: 'Boletines y promociones',
    betLimit: {
      modalTitle: 'Fijar límite de apuesta',
      name: 'Límite de apuesta',
      description: 'Controlar el dinero que el jugador gasta en el juego',
      buttonText: 'Fijar límite',
    },
    withdrawalLimit: {
      modalTitle: 'Fijar límite de retiro',
      name: 'Límite de retiro',
      description: 'Controlar el dinero que el jugador retira',
      buttonText: 'Fijar límite',
    },
    depositLimit: {
      modalTitle: 'Fijar límite de depósito',
      name: 'Depositar límite',
      description: 'Controlar el dinero que el jugador deposita para jugar',
      buttonText: 'Fijar límite',
    },
    timeOutLimit: {
      modalTitle: 'Fijar tiempo muerto',
      name: 'Tiempo muerto',
      description: 'Dar una pequeña pausa al jugador de todas las actividades de juego',
      buttonText: 'Fijar tiempo muerto',
    },
    selfExclusionLimit: {
      modalTitle: 'Fijar exclusión',
      name: 'Autoexclusión',
      description: 'Excluir al jugador de todas las actividades de juego para que conserve el control',
      buttonText: 'Fijar exclusión',
    },
    noLimit: 'Sin límite',
    current: 'Actual',
    remainder: 'Resto',
    upcomingChanges: 'Próximos cambios',
    limitType: 'Tipo de límite',
    dateRequested: 'Fecha solicitada',
    endOfLimitation: 'Fin de la limitación',
    appliedBy: 'Aplicado por',
    exportNoteInfo: 'Se exportarán los datos de hasta 6 meses.',
    accountStatusBeforeClosure: 'Estado de la cuenta antes del cierre',
    accountRegistrationDate: 'Fecha de registro de la cuenta',
    accountClosureDate: 'Fecha de cierre de la cuenta',
    reasonOfClosure: 'Motivo del cierre',
    matchingCriteria: 'Criterios de concordancia',
    dateAgreed: 'Fecha acordada',
    accountInformation: 'Información de la cuenta',
    test: 'Test',
    personalInformation: 'Información personal',
    walletInformation: 'Información del monedero',
    age: 'Edad',
    countryCity: 'País, ciudad',
    mobile: 'Móvil',
    documents: 'Documentos',
    currencyConverter: 'Convertir moneda',
    pendingFileGroups: 'Grupos de archivos pendientes',
    verifiedWith: 'Verificar con',
    uploadAsApproved: 'Cargar como aprobado',
    archive: 'Archivo',
    unverifyPlayerIdentity: 'Sin verificar la identidad del jugador',
    unverifyPlayerAddress: 'Sin verificar la dirección del jugador',
    fileNameAndExtension: 'Nombre y extensión del archivo',
    approvedBy: 'Aprobado por',
    notBlacklisted: 'No está en la lista de no admitidos',
    statusBefore: 'Estado previo',
    statusAfter: 'Estado posterior',
    currentStatus: 'Estado actual',
    playersWithMissingInfo: 'Jugadores sin info. completa',
    verifyDocumentInfo: 'Asegúrese de que todos los documentos aprobados contienen información completa antes de la verificación.',
    notes: 'Notas',
    markAttention: 'Atención a la marca',
    playerSession: 'Sesión del jugador',
    playerBalance: 'Saldo del jugador',
    playersBalance: 'Saldos de los jugadores',
    testPlayersAccount: 'Cuenta del jugador de prueba',
    accountUsersInformation: 'Información del usuario de la cuenta',
    process: 'Proceso',
    attentionLevel: 'Nivel de antención',
    playerNotes: 'Notas del jugador',
    cards: 'Cartas',
    sessionId: 'ID de sesión',
    sessionStart: 'Inicio de sesión',
    sessionEnd: 'Fin de sesión',
    sessionEndReason: 'Motivo del fin de sesión',
    walletTransactionId: 'ID de transacción del monedero',
    realWalletAfter: 'Monedero real posterior',
    history: 'Historial',
    sumRealWallets: 'Suma de monederos reales',
    actionLogs: 'Registros de acción',
    day1HoursLimitAmount: 'Importe límite de 24 horas',
    days7LimitAmount: 'Importe límite de 7 días',
    month1LimitAmount: 'Importe límite de 1 mes',
    lastDeposit: 'Último Depósito',
    takenMonthData: 'Solamente se tienen en cuenta los datos de los últimos {{count}} meses',
    method: 'Método',
    lastWithdrawal: 'Última Retirada',
    last24hDeposits: 'Depósitos en las últimas 24 horas',
    last24hWithdrawals: 'Retiradas en las últimas 24 horas',
    playerActivities: 'Actividades del jugador',
    lastLoginInfo: 'Información de la última conexión',
    registrationInfo: 'Información de registro',
    blockedProviders: 'Proveedores bloqueados',
    selectedProviders: 'Proveedores seleccionados',
    countryOfCitizenship: 'País de nacionalidad',
    lCountryOfCitizenship: 'País de nacionalidad',
    citizenshipVerified: 'Nacionalidad verificada',
    blockProviders: 'Proveedores de bloques',
    blockedProvidersList: 'Lista de proveedores bloqueados',
    responsibleGamingLimits: 'Límites del juego responsable',
    p2pGgr: 'P2P GGR',
    similarAccountsInfo: 'Aquí podemos ver las cuentas filtradas durante el último mes con estos criterios {{criteria1}} y {{criteria2}}',
    nationality: 'Nacionalidad',
    pep: 'PEP',
    verticalCategoryNoReasonInfo: 'Por favor, active los motivos de esta categoría o edite el requisito de los motivos de la categoría',
    clickToSeePendingFiles: 'Haga clic en el icono para ver los expedientes pendientes',
    repetitionPeriod: 'Periodo de Repetición',
    subAffiliateId: 'ID de Subafiliado',
    repetitionDate: 'Fecha de Repetición',
    approvalDate: 'Fecha de Aprobación',
    debtWallet: 'Cartera de Deudas',
    personalNumericCode: 'Código Numérico Personal',
    personalNumericCodeNote:
      'Si se marca, se añade el campo de Código Numérico Personal al flujo de Verificación de Documentos, puede ser verificado con el documento y está disponible en diferentes informes.',
    brandRealityCheckLimitInfo: 'El límite está configurado para todos los jugadores de la marca y no es posible cambiar el límite individualmente para el jugador.',
    externalPlayerId: 'ID de Jugador Externo',
    documentDescriptionInfo: 'Por favor, describe el significado del documento.',
    realWalletBefore: 'Anterior a la Cartera Real',
    resetPassword: 'Restablecer Contraseña',
    PlayerMobileNumberInUse: 'Ya existe un jugador con este número de teléfono.',
    PlayerEmailInUse: 'Ya existe un jugador con este E-mail',
    cpfNumber: 'Número de CPF',
    disableTFA: 'Desactivar 2FA',
    tFA: '2FA',
    missingFieldsInAttachmentsInfo: 'Los datos necesarios para aprobar este documento no han sido completados.',
    year1LimitAmount: 'Importe de Límite de 1 Año',
    pushNotification: 'Notificación Push',
    indicators: 'Indicadores',
    turnover: 'Turnover',
    totalNetBalance: 'Balance Neto Total',
    extraWallets: 'Cartera Adicional',
    tillDate: 'Hasta la Fecha',
    tillDateInfo: 'Hasta la Fecha se puede seleccionar al menos siete días después de la fecha actual.',
    currentType: 'Tipo Actual',
    upcomingType: 'Tipo Próximo',
    upcomingDate: 'Fecha Próxima',
    editAccessLimit: 'Editar Límite de Acceso',
    weekDays: 'Días de la Semana',
    inactivePeriodTime: 'Tiempo de Período Inactivo',
    setBy: 'Establecido por',
    effectDate: 'Fecha de Efecto',
    accessLimitTimezoneInfo: 'El Límite de Acceso funcionará con la zona horaria predeterminada de la marca: {{timezone}}',
    upcoming: 'Próximo',
    timeTo: 'Hora hasta',
    timeFrom: 'Hora desde',
    viewAccessLimit: 'Ver Límite de Acceso',
    cpfNumberVerified: 'CPF Verificado',
    deposit: 'Depósito',
    withdrawal: 'Retiro',
    smsPreferencesDescription: 'Selecciona los eventos para los cuales el jugador recibirá notificaciones SMS',
    emailPreferencesDescription: 'Selecciona los eventos para los cuales el jugador recibirá notificaciones por correo electrónico.',
    pushPreferencesDescription: 'Selecciona los eventos para los cuales el jugador recibirá Notificaciones Push',
  },
  tr: {
    identityVerified: 'Kimlik Doğrulandı',
    emailVerified: 'E-posta Doğrulandı',
    mobileVerified: 'Cep Telefonu Doğrulandı',
    addressVerified: 'Adres Doğrulandı',
    accountLocked: 'Hesap Kilitli',
    locked: 'Kilitli',
    accountInactive: 'Hesap Aktif Değil',
    blockedForMessages: 'Mesajlar için Engellendi',
    closed: 'Kapalı',
    inactiveAccount: 'Aktif Olmayan Hesap',
    accountInTimeout: 'Hesap Kısıtlandı',
    accountSelfExcluded: 'Hesap Kendini Hariç Tutuyor',
    exclusionState: 'Hariç Tutma Durumu',
    physicalVerification: 'Fiziksel Doğrulama',
    blacklisted: 'Kara listede',
    regDate: 'Kayıt Tarihi',
    regIP: 'Kayıt IP',
    emailNotifications: 'E-posta Bildirimleri',
    SMSNotifications: 'SMS Bildirimleri',
    accountType: 'Hesap Türü',
    accountUsers: 'Hesap Kullanıcıları',
    lastProvisionDate: 'Son Provizyon Tarihi',
    lastAdjustmentDate: 'Son Düzenleme Tarihi',
    currencyDeletedOrDisabledTooltip: 'Hesabın para birimi devre dışı bırakılmış/silinmiş olabilir',
    adjustBalance: 'Bakiyeyi Düzenle',
    finishUse: 'Kullanımı Bitir',
    closedAccountNote: 'Hesap kapatılır ve artık kullanılamaz.',
    freeAccountNote: 'Hesap kullanım için ücretsiz olacaktır.',
    testAccountDetails: 'Test Hesabı Bilgileri',
    userDetails: 'Kullanıcı Bilgileri',
    accountUser: 'Hesap Kullanıcısı',
    userEmail: 'Kullanıcı E-postası',
    userEmailTooltip: 'Hesabın giriş bilgileri sağlanan e-postaya gönderilecek',
    adjust: 'Düzenle',
    moneyTransferred: 'Transfer Edilen Para',
    moneyUsed: 'Kullanılan Para',
    periodStart: 'Dönem Başlangıcı',
    periodEnd: 'Dönem Bitişi',
    provisionDate: 'Provizyon Tarihi',
    providedBy: 'Sağlayan',
    attachmentId: 'Ek Kimlik',
    startProcess: 'İşlemi Başlat',
    manageFiles: 'Dosyaları Yönet',
    blockControl: 'Engel Kontrolü',
    unblockMessages: 'Mesajların Engelini Kaldır',
    investigationResults: 'Soruşturma Sonuçları',
    noAvailableAccountInfo: 'Hesap artık kullanılamaz.',
    lComplete: 'tamamlandı',
    lIncomplete: 'tamamlanmadı',
    accountVerificationProgress: 'Hesap Doğrulaması İlerlemesi',
    newslettersAndPromotions: 'Haber Bültenleri ve Promosyonlar',
    betLimit: {
      modalTitle: 'Bahis Limiti Belirle',
      name: 'Bahis Limiti',
      description: 'Oyuncunun bahis oynamaya harcadığı parayı kontrol edin',
      buttonText: 'Limit Belirle',
    },
    withdrawalLimit: {
      modalTitle: 'Para Çekme Limiti Belirle',
      name: 'Para Çekme Limiti',
      description: 'Oyuncunun çektiği parayı kontrol edin',
      buttonText: 'Limit Belirle',
    },
    depositLimit: {
      modalTitle: 'Para Yatırma Limiti Belirle',
      name: 'Para Yatırma Limiti',
      description: 'Oyuncunun bahis oynamak için yatırdığı parayı kontrol edin',
      buttonText: 'Limit Belirle',
    },
    timeOutLimit: {
      modalTitle: 'Kısıtlama Belirle',
      name: 'Kısıtlama',
      description: 'Oyuncuya bütün bahis oynama aktiviteleri konusunda kısa bir ara verin',
      buttonText: 'Kısıtlama Belirle',
    },
    selfExclusionLimit: {
      modalTitle: 'Hariç Tutmayı Belirle',
      name: 'Kendini Hariç Tutma',
      description: 'Kontrolü kaybetmemek için oyuncuyu bütün bahis oynama aktivitelerinden hariç tutun',
      buttonText: 'Hariç Tutmayı Ayarla',
    },
    noLimit: 'Limit yok',
    current: 'Mevcut',
    remainder: 'Kalan',
    upcomingChanges: 'Gelecek Değişiklikler',
    limitType: 'Limit Türü',
    dateRequested: 'Talep Tarihi',
    endOfLimitation: 'Limit Sonu',
    appliedBy: 'Uygulayan',
    exportNoteInfo: 'En fazla 6 aylık veri dışa aktarılacaktır.',
    accountStatusBeforeClosure: 'Kapatılmadan Önce Hesap Durumu',
    accountRegistrationDate: 'Hesap Kayıt Tarihi',
    accountClosureDate: 'Hesap Kapatma Tarihi',
    reasonOfClosure: 'Kapatma Nedeni',
    matchingCriteria: 'Eşleşen Kriterler',
    dateAgreed: 'Kabul Edilme Tarihi',
    accountInformation: 'Hesap Bilgileri',
    test: 'Test',
    personalInformation: 'Kişisel Bilgiler',
    walletInformation: 'Cüzdan Bilgileri',
    age: 'Yaş',
    countryCity: 'Ülke, Şehir',
    mobile: 'Cep Telefonu',
    documents: 'Belgeler',
    currencyConverter: 'Para Birimi Dönüştürücü',
    pendingFileGroups: 'Bekleyen Dosya Grupları',
    verifiedWith: 'Doğrulama Metodu',
    uploadAsApproved: 'Onaylandı olarak yükle',
    archive: 'Arşivle',
    unverifyPlayerIdentity: 'Oyuncu kimliği doğrulamasını kaldır',
    unverifyPlayerAddress: 'Oyuncu adresi doğrulamasını kaldır',
    fileNameAndExtension: 'Dosya Adı ve Genişletme',
    approvedBy: 'Onaylayan',
    notBlacklisted: 'Kara Listede Değil',
    statusBefore: 'Önceki Durum',
    statusAfter: 'Sonraki Durum',
    currentStatus: 'Mevcut Durum',
    playersWithMissingInfo: 'Bilgileri eksik olan oyuncular',
    verifyDocumentInfo: 'Bütün onaylı belgelerin doğrulamadan önce tam bilgi içerdiğinden emin olun.',
    notes: 'Notlar',
    markAttention: 'Dikkat işareti koy',
    playerSession: 'Oyuncu oturumu',
    playerBalance: 'Oyuncu bakiyesi',
    playersBalance: 'Oyuncuların bakiyeleri',
    testPlayersAccount: 'Test oyuncusu hesabı',
    accountUsersInformation: 'Hesap kullanıcısı bilgileri',
    process: 'İşlem',
    attentionLevel: 'Dikkat Seviyesi',
    playerNotes: 'Oyuncu Notları',
    cards: 'Kartlar',
    sessionId: 'Oturum Kimliği',
    sessionStart: 'Oturum Başlangıcı',
    sessionEnd: 'Oturum Sonu',
    sessionEndReason: 'Oturum Sonu Nedeni',
    walletTransactionId: 'Cüzdan TXN Kimliği',
    realWalletAfter: 'Sonra Gerçek Cüzdan',
    history: 'Geçmiş',
    sumRealWallets: 'Gerçek Cüzdanlar Toplamı',
    actionLogs: 'Eylem Günlükleri',
    day1HoursLimitAmount: '24 Saatlik Limit Miktarı',
    days7LimitAmount: '7 Günlük Limit Miktarı',
    month1LimitAmount: '1 Aylık Limit Miktarı',
    lastDeposit: 'Son Para Yatırımı',
    takenMonthData: 'Sadece son {{count}} aydaki veriler hesaba alınır.',
    method: 'Yöntem',
    lastWithdrawal: 'Son Para Çekimi',
    last24hDeposits: 'Son 24 saatteki para yatırımları',
    last24hWithdrawals: 'Son 24 saatteki para çekimleri',
    playerActivities: 'Oyuncu İşlemleri',
    lastLoginInfo: 'Son Giriş Bilgileri',
    registrationInfo: 'Kayıt Bilgisi',
    blockedProviders: 'Engellenen Sağlayıcılar',
    selectedProviders: 'Seçilmiş Sağlayıcılar',
    countryOfCitizenship: 'Vatandaşı Olunan Ülke',
    lCountryOfCitizenship: 'vatandaşı olunan ülke',
    citizenshipVerified: 'Vatandaşlık Doğrulandı',
    blockProviders: 'Sağlayıcıları Engelle',
    blockedProvidersList: 'Engellenen Sağlayıcılar listesi',
    responsibleGamingLimits: 'Sorumlu Oyun Limitleri',
    p2pGgr: 'P2P GGR',
    similarAccountsInfo: 'Burada {{kriter1}} ve {{kriter2}} kriterleriyle geçen ay filtrelenen hesapları görebiliriz',
    nationality: 'Uyruğu',
    pep: 'PEP',
    verticalCategoryNoReasonInfo: 'Lütfen bu kategori için nedenleri etkinleştirin veya kategoriden neden koşulunu düzenleyin',
    clickToSeePendingFiles: 'Bekleyen dosyaları görmek için simgeye tıklayın',
    repetitionPeriod: 'Tekrarlama Periyodu',
    subAffiliateId: 'Alt Affiliate ID',
    repetitionDate: 'Tekrarlama Tarihi',
    approvalDate: 'Onay Tarihi',
    debtWallet: 'Borç Cüzdanı',
    personalNumericCode: 'Kişisel Sayısal Kod',
    personalNumericCodeNote: 'İşaretlenirse, Kişisel Sayısal Kod alanı Belge Doğrulama akışına eklenir, Belge ile doğrulanabilir ve farklı raporlarda görüntülenir.',
    brandRealityCheckLimitInfo: 'Limit tüm marka oyuncular için yapılandırılmıştır ve her bir oyuncu için limiti tek tek değiştirmek mümkün değildir',
    externalPlayerId: 'Harici Oyuncu ID',
    documentDescriptionInfo: 'Lütfen belgenin anlamını açıklayınız',
    realWalletBefore: 'Gerçek Cüzdan Önce',
    resetPassword: 'Şifreyi Sıfırla',
    PlayerMobileNumberInUse: 'Bu telefon numarasına sahip oyuncu zaten mevcut',
    PlayerEmailInUse: 'Bu e-posta adresine sahip oyuncu zaten mevcut',
    cpfNumber: 'CPF Numarası',
    disableTFA: "2FA'yı devre dışı bırak",
    tFA: '2FA',
    missingFieldsInAttachmentsInfo: 'Bu belgenin onaylanması için gerekli veriler doldurulmamıştır',
    year1LimitAmount: '1 Yıllık Limit Tutarı',
    pushNotification: 'Anlık Bildirim',
    indicators: 'Göstergeler',
    turnover: 'Ciro',
    totalNetBalance: 'Toplam Net Bakiye',
    extraWallets: 'Ekstra Cüzdanlar',
    tillDate: 'Tarihe kadar',
    tillDateInfo: 'Tarih, bugünden en az yedi gün sonra seçilebilir',
    currentType: 'Güncel Tür',
    upcomingType: 'Gelecek Tür',
    upcomingDate: 'Yaklaşan Tarih',
    editAccessLimit: 'Erişim Limitini Düzenle',
    weekDays: 'Hafta içi',
    inactivePeriodTime: 'Etkin Olmayan Dönem Süresi',
    setBy: 'Ayarla',
    effectDate: 'Başlangıç Tarihi',
    accessLimitTimezoneInfo: 'Erişim Limiti, marka varsayılan saat dilimiyle çalışır: {{timezone}}',
    upcoming: 'Yaklaşan',
    timeTo: 'Saate',
    timeFrom: 'Saatten',
    viewAccessLimit: 'Erişim Limitini Görüntüle',
    cpfNumberVerified: 'CPF Doğrulandı',
    deposit: 'Para Yatırma',
    withdrawal: 'Para Çekme',
    smsPreferencesDescription: 'Oyuncunun SMS bildirimi alacağı etkinlikleri seçin.',
    emailPreferencesDescription: 'Oyuncunun E-posta bildirimi alacağı etkinlikleri seçin.',
    pushPreferencesDescription: 'Oyuncunun Anlık Bildirimler alacağı etkinlikleri seçin.',
  },
  ru: {
    identityVerified: 'Личность проверена',
    emailVerified: 'Адрес электронной почты проверен',
    mobileVerified: 'Номер мобильного телефона проверен',
    addressVerified: 'Адрес проверен',
    accountLocked: 'Учетная запись заблокирована',
    locked: 'Заблокировано',
    accountInactive: 'Учетная запись неактивна',
    blockedForMessages: 'Заблокировано для сообщений',
    closed: 'Закрыто',
    inactiveAccount: 'Неактивная учетная запись',
    accountInTimeout: 'Время ожидания учетной записи истекло',
    accountSelfExcluded: 'Самоисключение учетной записи',
    exclusionState: 'Состояние исключения',
    physicalVerification: 'Физическая верификация',
    blacklisted: 'Добавлено в список запрещенных',
    regDate: 'Дата регистрации',
    regIP: 'IP рег.',
    emailNotifications: 'Уведомления по электронной почте',
    SMSNotifications: 'SMS-уведомления',
    accountType: 'Тип учетной записи',
    accountUsers: 'Пользователи учетной записи',
    lastProvisionDate: 'Дата последнего обеспечения',
    lastAdjustmentDate: 'Дата последней корректировки',
    currencyDeletedOrDisabledTooltip: 'Валюта учетной записи, возможно, была деактивирована/удалена',
    adjustBalance: 'Корректировать баланс',
    finishUse: 'Завершить использование',
    closedAccountNote: 'Учетная запись будет закрыта и станет недоступной.',
    freeAccountNote: 'Учетная запись станет бесплатной для использования.',
    testAccountDetails: 'Детали тестовой учетной записи',
    userDetails: 'Детали пользователя',
    accountUser: 'Пользователь учетной записи',
    userEmail: 'Адрес электронной почты пользователя',
    userEmailTooltip: 'Логин и пароль учетной записи будут отправлены на указанный адрес электронной почты',
    adjust: 'Корректировать',
    moneyTransferred: 'Средства переведены',
    moneyUsed: 'Средства использованы',
    periodStart: 'Начало периода',
    periodEnd: 'Завершение периода',
    provisionDate: 'Дата обеспечения',
    providedBy: 'Предоставлено (кем)',
    attachmentId: 'ID приложения',
    startProcess: 'Начать процесс',
    manageFiles: 'Управлять файлами',
    blockControl: 'Блокировать контроль',
    unblockMessages: 'Разблокировать сообщения',
    investigationResults: 'Результаты расследования',
    noAvailableAccountInfo: 'Учетная запись больше не будет доступной для использования.',
    lComplete: 'завершено',
    lIncomplete: 'не завершено',
    accountVerificationProgress: 'Прогресс верификации учетной записи',
    newslettersAndPromotions: 'Рассылки и промоакции',
    betLimit: {
      modalTitle: 'Установить лимит ставок',
      name: 'Лимит ставок',
      description: 'Контролировать средства, расходуемые игроком на азартные игры',
      buttonText: 'Установить лимит',
    },
    withdrawalLimit: {
      modalTitle: 'Установить лимит для вывода средств',
      name: 'Лимит для вывода средств',
      description: 'Контролировать средства, выводимые игроком',
      buttonText: 'Установить лимит',
    },
    depositLimit: {
      modalTitle: 'Установить лимит депозитов',
      name: 'Лимит депозитов',
      description: 'Контролировать средства, зачисляемые игроком на счет для гемблинга',
      buttonText: 'Установить лимит',
    },
    timeOutLimit: {
      modalTitle: 'Установить время ожидания',
      name: 'Время ожидания',
      description: 'Дать игроку короткую паузу от всех игровых действий',
      buttonText: 'Установить время ожидания',
    },
    selfExclusionLimit: {
      modalTitle: 'Установить исключение',
      name: 'Самоисключение',
      description: 'Исключить игрока из всех игровых действий для сохранения контроля',
      buttonText: 'Установить исключение',
    },
    noLimit: 'Без ограничения',
    current: 'Текущий',
    remainder: 'Остаток',
    upcomingChanges: 'Предстоящие изменения',
    limitType: 'Тип ограничения',
    dateRequested: 'Дата запроса',
    endOfLimitation: 'Завершение ограничения',
    appliedBy: 'Применено к',
    exportNoteInfo: 'Будут экспортированы данные за период до 6 месяцев.',
    accountStatusBeforeClosure: 'Статус учетной записи перед закрытием',
    accountRegistrationDate: 'Дата регистрации учетной записи',
    accountClosureDate: 'Дата закрытия учетной записи',
    reasonOfClosure: 'Причина закрытия',
    matchingCriteria: 'Критерии подбора',
    dateAgreed: 'Дата согласования',
    accountInformation: 'Информация об учетной записи',
    test: 'Тест',
    personalInformation: 'Личные данные',
    walletInformation: 'Информация о кошельке',
    age: 'Возраст',
    countryCity: 'Страна, город',
    mobile: 'Мобильный телефон',
    documents: 'Документы',
    currencyConverter: 'Конвертер валют',
    pendingFileGroups: 'Группы файлов, ожидающих обработки',
    verifiedWith: 'Проверено с',
    uploadAsApproved: 'Загрузить как одобренное',
    archive: 'Архив',
    unverifyPlayerIdentity: 'Отменить результаты проверки личности игрока',
    unverifyPlayerAddress: 'Отменить результаты проверки адреса игрока',
    fileNameAndExtension: 'Имя и расширение файла',
    approvedBy: 'Одобрено',
    notBlacklisted: 'Не в списке запрещенных',
    statusBefore: 'Статус до',
    statusAfter: 'Статус после',
    currentStatus: 'Текущий статус',
    playersWithMissingInfo: 'Игроки с недостающей информацией',
    verifyDocumentInfo: 'Перед верификацией убедитесь, что все одобренные документы содержат полную информацию.',
    notes: 'Примечания',
    markAttention: 'Пометка «Внимание»',
    playerSession: 'Сессия игрока',
    playerBalance: 'Баланс игрока',
    playersBalance: 'Балансы игроков',
    testPlayersAccount: 'Тестовая учетная запись игрока',
    accountUsersInformation: 'Информация об учетной записи игрока',
    process: 'Процесс',
    attentionLevel: 'Уровень внимания',
    playerNotes: 'Заметки игрока',
    cards: 'Карты',
    sessionId: 'ID сессии',
    sessionStart: 'Начало сессии',
    sessionEnd: 'Завершение сессии',
    sessionEndReason: 'Причина завершения сессии',
    walletTransactionId: 'ID транзакции кошелька',
    realWalletAfter: 'Реальный кошелек после',
    history: 'История',
    sumRealWallets: 'Сумма реальных кошельков',
    actionLogs: 'Журналы действий',
    day1HoursLimitAmount: '‎24-часовой лимит',
    days7LimitAmount: '‎7-дневный лимит',
    month1LimitAmount: 'Месячный лимит',
    lastDeposit: 'Последний депозит',
    takenMonthData: 'Учитываются только данные за последние {{count}} месяцев',
    method: 'Метод',
    lastWithdrawal: 'Последняя выплата',
    last24hDeposits: 'Депозиты в течение последних 24 часов',
    last24hWithdrawals: 'Выплаты в течение последних 24 часов',
    playerActivities: 'Действия игрока',
    lastLoginInfo: 'Информация о последнем входе',
    registrationInfo: 'Информация о регистрации',
    blockedProviders: 'Заблокированные провайдеры',
    selectedProviders: 'Выбранные провайдеры',
    countryOfCitizenship: 'Гражданство',
    lCountryOfCitizenship: 'гражданство',
    citizenshipVerified: 'гражданство подтверждено',
    blockProviders: 'Заблокировать провайдеров',
    blockedProvidersList: 'Список заблокрованных провайдеров',
    responsibleGamingLimits: 'Лимиты ответственной игры',
    p2pGgr: 'P2P GGR',
    similarAccountsInfo: 'Здесь мы видим учетные записи, фильтрованные за последний месяц по этим критериям {{criteria1}} и {{criteria2}}',
    nationality: 'Национальность',
    pep: 'PEP',
    verticalCategoryNoReasonInfo: 'Пожалуйста, активируйте причины для этой категории или отредактируйте требования к причинам из категории',
    clickToSeePendingFiles: 'Нажмите на значок, чтобы увидеть файлы, ожидающие обработки',
    repetitionPeriod: 'Период повторения',
    subAffiliateId: 'ID субпартнера',
    repetitionDate: 'Дата повторения',
    approvalDate: 'Дата утверждения',
    debtWallet: 'Долговой кошелек',
    personalNumericCode: 'Персональный цифровой код',
    personalNumericCodeNote:
      'Если флажок установлен, поле Персональный цифровой код добавляется в поток Верификации документа и может быть верифицировано с Документом, а также доступно в различных отчетах.',
    brandRealityCheckLimitInfo: 'Лимит настраивается для всех игроков бренда, и изменить лимит для каждого игрока по отдельности невозможно',
    externalPlayerId: 'ID внешнего игрока',
    documentDescriptionInfo: 'Пожалуйста, опишите значение этого документа',
    realWalletBefore: 'Реальный кошелек до',
    resetPassword: 'Сбросить пароль',
    PlayerMobileNumberInUse: 'Игрок с таким номером телефона уже существует',
    PlayerEmailInUse: 'Игрок с таким адресом электронной почты уже существует',
    cpfNumber: 'Номер CPF',
    disableTFA: 'Отключить 2FA',
    tFA: '2FA',
    missingFieldsInAttachmentsInfo: 'Данные, необходимые для утверждения этого документа, не заполнены',
    year1LimitAmount: 'Сумма лимита на 1 год',
    pushNotification: 'Push-уведомление',
    indicators: 'Индикаторы',
    turnover: 'Оборот',
    totalNetBalance: 'Итого чистый баланс',
    extraWallets: 'Дополнительные кошельки',
    tillDate: 'До даты',
    tillDateInfo: 'До даты можно выбрать не менее чем через семь дней после текущей даты',
    currentType: 'Текущий тип',
    upcomingType: 'Предстоящий тип',
    upcomingDate: 'Предстоящая дата',
    editAccessLimit: 'Редактировать ограничение доступа',
    weekDays: 'Будни',
    inactivePeriodTime: 'Время бездействия',
    setBy: 'Установлено',
    effectDate: 'Дата вступления в силу',
    accessLimitTimezoneInfo: 'Ограничение доступа будет работать с часовым поясом бренда по умолчанию: {{timezone}}',
    upcoming: 'Предстоящий',
    timeTo: 'Время до',
    timeFrom: 'Время от',
    viewAccessLimit: 'Просмотр ограничения доступа',
    cpfNumberVerified: 'CPF-проверено',
    deposit: 'Депозит',
    withdrawal: 'Вывод средств',
    smsPreferencesDescription: 'Выбрать события, о которых игрок будет получать SMS-уведомления.',
    emailPreferencesDescription: 'Выбрать события, о которых игрок будет получать уведомления по электронной почте.',
    pushPreferencesDescription: 'Выбрать события, о которых игрок будет получать push-уведомления.',
  },
  pt: {
    identityVerified: 'Identidade Verificada',
    emailVerified: 'E-mail Verificado',
    mobileVerified: 'Telemóvel Verificado',
    addressVerified: 'Endereço Verificado',
    accountLocked: 'Conta Bloqueada',
    locked: 'Bloqueada',
    accountInactive: 'Conta Inativa',
    blockedForMessages: 'Bloqueado para Mensagens',
    closed: 'Fechada',
    inactiveAccount: 'Conta Inativa',
    accountInTimeout: 'Conta em Time-out',
    accountSelfExcluded: 'Conta Auto-excluída',
    exclusionState: 'Estado da Exclusão',
    physicalVerification: 'Verificação Física',
    blacklisted: 'Colocado na Lista de Proibidos',
    regDate: 'Data de Reg.',
    regIP: 'IP de Reg.',
    emailNotifications: 'Notificações por E-mail',
    SMSNotifications: 'Notificações SMS',
    accountType: 'Tipo de Conta',
    accountUsers: 'Utilizadores da Conta',
    lastProvisionDate: 'Data da Última Disposição',
    lastAdjustmentDate: 'Data do Último Ajuste',
    currencyDeletedOrDisabledTooltip: 'A moeda da conta pode ter sido desativada/apagada',
    adjustBalance: 'Ajustar o Saldo',
    finishUse: 'Utilização Final',
    closedAccountNote: 'A conta será fechada e já não estará disponível.',
    freeAccountNote: 'A conta será gratuita para utilização.',
    testAccountDetails: 'Dados da Conta de Teste',
    userDetails: 'Dados do Utilizador',
    accountUser: 'Conta de Utilizador',
    userEmail: 'E-mail do Utilizador',
    userEmailTooltip: 'As credenciais da conta serão enviadas para o e-mail fornecido',
    adjust: 'Ajuste',
    moneyTransferred: 'Dinheiro Transferido',
    moneyUsed: 'Dinheiro Utilizado',
    periodStart: 'Início do Período',
    periodEnd: 'Fim do Período',
    provisionDate: 'Data da Provisão',
    providedBy: 'Fornecido por',
    attachmentId: 'ID do Anexo',
    startProcess: 'Iniciar Processo',
    manageFiles: 'Gerir Arquivos',
    blockControl: 'Controlo de Bloco',
    unblockMessages: 'Desbloquear Mensagens',
    investigationResults: 'Resultados da Investigação',
    noAvailableAccountInfo: 'A conta já não estará disponível para utilização.',
    lComplete: 'completo',
    lIncomplete: 'incompleto',
    accountVerificationProgress: 'Progresso da Verificação de Conta',
    newslettersAndPromotions: 'Newsletters e Promoções',
    betLimit: {
      modalTitle: 'Definir Limite de Aposta',
      name: 'Limite de Aposta',
      description: 'Controlar o dinheiro que o jogador gasta em apostas',
      buttonText: 'Definir Limite',
    },
    withdrawalLimit: {
      modalTitle: 'Definir Limite de Levantamento',
      name: 'Limite de Levantamento',
      description: 'Controlar o dinheiro levantado pelo jogador',
      buttonText: 'Definir Limite',
    },
    depositLimit: {
      modalTitle: 'Definir Limite de Depósito',
      name: 'Limite de Depósito',
      description: 'Controlar o dinheiro depositado pelo jogador para apostas',
      buttonText: 'Definir Limite',
    },
    timeOutLimit: {
      modalTitle: 'Definir Time-out',
      name: 'Time-out',
      description: 'Oferecer ao jogador um intervalo de todas as atividades de jogo',
      buttonText: 'Definir Time-out',
    },
    selfExclusionLimit: {
      modalTitle: 'Definir Exclusão',
      name: 'Autoexclusão',
      description: 'Excluir o jogador de todas as atividades de jogo para este manter o controlo',
      buttonText: 'Definir Exclusão',
    },
    noLimit: 'Sem Limite',
    current: 'Atual',
    remainder: 'Lembrete',
    upcomingChanges: 'Próximas Alterações',
    limitType: 'Tipo de Limite',
    dateRequested: 'Data da Solicitação',
    endOfLimitation: 'Fim do Limite',
    appliedBy: 'Aplicado por',
    exportNoteInfo: 'Serão exportados dados de até 6 meses.',
    accountStatusBeforeClosure: 'Estado da Conta Antes do Encerramento',
    accountRegistrationDate: 'Data de Registo da Conta',
    accountClosureDate: 'Data de Encerramento da Conta',
    reasonOfClosure: 'Motivo do Encerramento',
    matchingCriteria: 'Critérios de Correspondência',
    dateAgreed: 'Data Acordada',
    accountInformation: 'Informações da Conta',
    test: 'Teste',
    personalInformation: 'Informações Pessoais',
    walletInformation: 'Informações da Carteira',
    age: 'Idade',
    countryCity: 'País, Cidade',
    mobile: 'Telemóvel',
    documents: 'Documentos',
    currencyConverter: 'Conversor de Moeda',
    pendingFileGroups: 'Grupos de Arquivos Pendentes',
    verifiedWith: 'Verificado com',
    uploadAsApproved: 'Carregar Conforme Aprovado',
    archive: 'Arquivar',
    unverifyPlayerIdentity: 'Não verificar a identidade do jogador',
    unverifyPlayerAddress: 'Não verificar o endereço do jogador',
    fileNameAndExtension: 'Nome do arquivo e extensão',
    approvedBy: 'Aprovado por',
    notBlacklisted: 'Não está na Lista de Proibidos',
    statusBefore: 'Estado Antes',
    statusAfter: 'Estado Após',
    currentStatus: 'Estado Atual',
    playersWithMissingInfo: 'Jogadores com informações em falta',
    verifyDocumentInfo: 'Certifique-se de que todos os documentos aprovados contenham informações completas antes da verificação.',
    notes: 'Notas',
    markAttention: 'Marcar Atenção',
    playerSession: 'Sessão do jogador',
    playerBalance: 'Saldo do jogador',
    playersBalance: 'Saldos dos Jogadores',
    testPlayersAccount: 'Conta do jogador de teste',
    accountUsersInformation: 'Informações do utilizador da conta',
    process: 'Processo',
    attentionLevel: 'Nível de Atenção',
    playerNotes: 'Notas do Jogador',
    cards: 'Cartas',
    sessionId: 'ID da Sessão',
    sessionStart: 'Início da Sessão',
    sessionEnd: 'Fim da Sessão',
    sessionEndReason: 'Motivo do Fim da Sessão',
    walletTransactionId: 'ID da Carteira TXN',
    realWalletAfter: 'Carteira Real Após',
    history: 'Histórico',
    sumRealWallets: 'Soma das Carteiras Reais',
    actionLogs: 'Registos de Ações',
    day1HoursLimitAmount: 'Valor de Limite de 24 Horas',
    days7LimitAmount: 'Valor de Limite de 7 Dias',
    month1LimitAmount: 'Valor de Limite de 1 Mês',
    lastDeposit: 'Último Depósito',
    takenMonthData: 'Apenas são levados em consideração os dados dos últimos {{count}} meses',
    method: 'Método',
    lastWithdrawal: 'Último Levantamento',
    last24hDeposits: 'Depósitos nas últimas 24h',
    last24hWithdrawals: 'Levantamentos nas últimas 24h',
    playerActivities: 'Atividades do Jogador',
    lastLoginInfo: 'Informações Sobre o Último Login',
    registrationInfo: 'Informações de Registo',
    blockedProviders: 'Fornecedores Bloqueados',
    selectedProviders: 'Fornecedores Selecionados',
    countryOfCitizenship: 'País de Cidadania',
    lCountryOfCitizenship: 'país de cidadania',
    citizenshipVerified: 'Cidadania Verificada',
    blockProviders: 'Bloquear Fornecedores',
    blockedProvidersList: 'Lista de Fornecedores Bloqueados',
    responsibleGamingLimits: 'Limites do Jogo Responsável',
    p2pGgr: 'P2P GGR',
    similarAccountsInfo: 'Aqui, podemos ver a filtragem de contas durante o último mês com estes critérios {{criteria1}} e {{criteria2}}',
    nationality: 'Nacionalidade',
    pep: 'PEP',
    verticalCategoryNoReasonInfo: 'Por favor, ative os motivos para esta categoria ou edite o requisito relativo aos motivos a partir da categoria',
    clickToSeePendingFiles: 'Clique no ícone para ver os arquivos pendentes',
    repetitionPeriod: 'Período de Repetição',
    subAffiliateId: 'ID do Subafiliado',
    repetitionDate: 'Data de Repetição',
    approvalDate: 'Data de aprovação',
    debtWallet: 'Carteira de Dívidas',
    personalNumericCode: 'Código numérico pessoal',
    personalNumericCodeNote:
      'Se estiver selecionado, o campo Código numérico pessoal é adicionado ao fluxo de verificação de documentos, pode ser verificado com o documento e está disponível em diferentes relatórios.',
    brandRealityCheckLimitInfo: 'O limite é configurado para todos os jogadores da marca, e não é possível alterar o limite para o jogador individualmente',
    externalPlayerId: 'ID do jogador externo',
    documentDescriptionInfo: 'Por favor, descreva o significado do documento',
    realWalletBefore: 'Carteira Real Antes',
    resetPassword: 'Redefinir palavra-passe',
    PlayerMobileNumberInUse: 'Já existe um jogador com este número de telefone',
    PlayerEmailInUse: 'Já existe um jogador com este endereço de correio eletrónico',
    cpfNumber: 'Número do CPF',
    disableTFA: 'Desativar 2FA',
    tFA: '2FA',
    missingFieldsInAttachmentsInfo: 'Os dados necessários para a aprovação deste documento não foram preenchidos',
    year1LimitAmount: 'Montante Limite de 1 ano ',
    pushNotification: 'Notificação Push ',
    indicators: 'Indicadores',
    turnover: 'Turnover',
    totalNetBalance: 'Saldo líquido total',
    extraWallets: 'Carteiras extra',
    tillDate: 'Data até',
    tillDateInfo: 'A data até pode ser selecionada pelo menos sete dias após a data atual',
    currentType: 'Tipo atual',
    upcomingType: 'Tipo futuro',
    upcomingDate: 'Próxima data',
    editAccessLimit: 'Editar limite de acesso',
    weekDays: 'Dias da semana',
    inactivePeriodTime: 'Tempo do período inativo',
    setBy: 'Definido por',
    effectDate: 'Data de efeito',
    accessLimitTimezoneInfo: 'O Limite de Acesso funcionará com o fuso horário predefinido da marca: {{timezone}}',
    upcoming: 'Próximo',
    timeTo: 'Tempo até',
    timeFrom: 'Tempo de',
    viewAccessLimit: 'Ver limite de acesso',
    cpfNumberVerified: 'CPF verificado',
    deposit: 'Depósito',
    withdrawal: 'Retirada',
    smsPreferencesDescription: 'Seleccione os eventos para os quais o jogador irá receber notificações por SMS.',
    emailPreferencesDescription: 'Seleccione os eventos para os quais o jogador irá receber notificações por e-mail.',
    pushPreferencesDescription: 'Seleccione os eventos para os quais o jogador irá receber notificações Push.',
  },
  zh: {
    identityVerified: '身份已验证',
    emailVerified: '已验证电子邮箱',
    mobileVerified: '已验证手机',
    addressVerified: '地址已验证',
    accountLocked: '账户已锁定',
    locked: '已锁定',
    accountInactive: '账户未激活',
    blockedForMessages: '屏蔽信息',
    closed: '已关闭',
    inactiveAccount: '未激活账户',
    accountInTimeout: '账户处于暂停期',
    accountSelfExcluded: '账户处于自我禁制期',
    exclusionState: '排除状态',
    physicalVerification: '物理验证',
    blacklisted: '被列入黑名单',
    regDate: '注册日期',
    regIP: '注册IP',
    emailNotifications: '电子邮箱通知',
    SMSNotifications: '短信通知',
    accountType: '账户类型',
    accountUsers: '账户持有者',
    lastProvisionDate: '最近提供日期',
    lastAdjustmentDate: '最近调整日期',
    currencyDeletedOrDisabledTooltip: '账户货币可能已禁用/删除',
    adjustBalance: '调整余额',
    finishUse: '完成使用',
    closedAccountNote: '账户即将关闭，并将不再可用。',
    freeAccountNote: '账户将免费可用。',
    testAccountDetails: '测试账户详情',
    userDetails: '用户详情',
    accountUser: '账户持有者',
    userEmail: '用户电子邮箱',
    userEmailTooltip: '账户的凭证将被发送到所提供的电子邮箱中',
    adjust: '调整',
    moneyTransferred: '已转账',
    moneyUsed: '已使用资金',
    periodStart: '开始周期',
    periodEnd: '结束周期',
    provisionDate: '提供日期',
    providedBy: '提供',
    attachmentId: '附件ID',
    startProcess: '开始进程',
    manageFiles: '管理文件',
    blockControl: '屏蔽控制',
    unblockMessages: '解除屏蔽信息',
    investigationResults: '调查结果',
    noAvailableAccountInfo: '账户即将不再可用。',
    lComplete: '完成',
    lIncomplete: '未完成',
    accountVerificationProgress: '账户验证流程',
    newslettersAndPromotions: '新闻简报和促销',
    betLimit: {
      modalTitle: '设置投注限制',
      name: '投注限制',
      description: '控制玩家在赌博上消费的资金',
      buttonText: '设置限制',
    },
    withdrawalLimit: {
      modalTitle: '设置取款限制',
      name: '取款限制',
      description: '控制玩家提取的资金',
      buttonText: '设置限制',
    },
    depositLimit: {
      modalTitle: '设置存款限制',
      name: '存款限制',
      description: '控制玩家为赌博而存入的资金',
      buttonText: '设置限制',
    },
    timeOutLimit: {
      modalTitle: '设置暂停时间',
      name: '暂停时间',
      description: '让玩家从所有的赌博活动中短暂休息',
      buttonText: '设置暂停时间',
    },
    selfExclusionLimit: {
      modalTitle: '设置排除条件',
      name: '自我禁制',
      description: '将玩家排除在所有赌博活动之外，以保持控制',
      buttonText: '设置排除项',
    },
    noLimit: '无限制',
    current: '当前',
    remainder: '剩余部分',
    upcomingChanges: '即将发生的变化',
    limitType: '限制类型',
    dateRequested: '要求日期',
    endOfLimitation: '限制结束',
    appliedBy: '应用',
    exportNoteInfo: '将导出最多6个月的数据。',
    accountStatusBeforeClosure: '关闭前的账户状态',
    accountRegistrationDate: '账户注册日期',
    accountClosureDate: '账户关闭日期',
    reasonOfClosure: '关闭原因',
    matchingCriteria: '匹配标准',
    dateAgreed: '商定日期',
    accountInformation: '账户信息',
    test: '测试',
    personalInformation: '个人信息',
    walletInformation: '钱包信息',
    age: '年龄',
    countryCity: '国家/地区，城市',
    mobile: '手机',
    documents: '文件',
    currencyConverter: '货币转换器',
    pendingFileGroups: '待处理文件组',
    verifiedWith: '验证经由',
    uploadAsApproved: '经批准后上传',
    archive: '归档',
    unverifyPlayerIdentity: '解除验证玩家身份',
    unverifyPlayerAddress: '解除验证玩家地址',
    fileNameAndExtension: '文件名和扩展',
    approvedBy: '已通过',
    notBlacklisted: '未被列入黑名单',
    statusBefore: '之前状态',
    statusAfter: '之后状态',
    currentStatus: '当前状态',
    playersWithMissingInfo: '缺失信息的玩家',
    verifyDocumentInfo: '确保所有批准的文件在核查前都包含完整的信息。',
    notes: '备注',
    markAttention: '标记关注',
    playerSession: '玩家会话',
    playerBalance: '玩家余额',
    playersBalance: '玩家余额',
    testPlayersAccount: '测试玩家账户',
    accountUsersInformation: '账户持有者信息',
    process: '处理',
    attentionLevel: '注意等级',
    playerNotes: '玩家备注',
    cards: '卡',
    sessionId: '专场ID',
    sessionStart: '专场开始',
    sessionEnd: '专场结束',
    sessionEndReason: '专场结束原因',
    walletTransactionId: '钱包交易ID',
    realWalletAfter: '真实钱包之后',
    history: '历史记录',
    sumRealWallets: '合计真实钱包',
    actionLogs: '操作日志',
    day1HoursLimitAmount: '24小时限额金额',
    days7LimitAmount: '7天限制金额',
    month1LimitAmount: '1个月限制金额',
    lastDeposit: '最后一次存款',
    takenMonthData: '顾及只过去{{count}}个月的数据',
    method: '方式',
    lastWithdrawal: '最后一次取款',
    last24hDeposits: '最后24小时内的存款',
    last24hWithdrawals: '最后24小时内的取款',
    playerActivities: '玩家活动',
    lastLoginInfo: '上次登录信息',
    registrationInfo: '注册信息',
    blockedProviders: '被封禁的提供商',
    countryOfCitizenship: '国籍国',
    lCountryOfCitizenship: '国籍国',
    citizenshipVerified: '公民身份得到确认',
    blockProviders: '阻止提供程序',
    blockedProvidersList: '阻止的提供程序列表',
    similarAccountsInfo: '在这里，我们可以看到上个月使用这些条件｛{criteria1｝｝和｛{criteria2｝进行的帐户筛选',
    nationality: '国籍',
    pep: 'PEP',
    verticalCategoryNoReasonInfo: '请激活此类别的原因或编辑类别中的原因要求',
    selectedProviders: '选定的供应商',
    responsibleGamingLimits: '负责任的游戏限制',
    p2pGgr: 'P2P GGR',
    clickToSeePendingFiles: '请单击图标查看挂起的文件',
    repetitionPeriod: '复读期',
    subAffiliateId: '子伙伴ID',
    repetitionDate: '复读期',
    approvalDate: '批准日期',
    debtWallet: '债务钱包',
    personalNumericCode: '个人数字代码',
    personalNumericCodeNote: '如果选中，个人数字代码字段将添加到文档验证流程中，可与文档一起验证，并在不同的报告中提供。',
    brandRealityCheckLimitInfo: '该限制是为所有品牌玩家配置的，无法单独更改玩家的限制',
    externalPlayerId: '外部玩家ID',
    documentDescriptionInfo: '请说明该文件的含义',
    realWalletBefore: '真实钱包之前',
    resetPassword: '重置密码',
    PlayerMobileNumberInUse: '已存在使用此电话号码的玩家',
    PlayerEmailInUse: '已存在使用此电子邮件地址的玩家',
    cpfNumber: 'CPF号',
    disableTFA: '停用2FA',
    tFA: '2FA',
    missingFieldsInAttachmentsInfo: '批准本文件所需的数据尚未填写',
    year1LimitAmount: '1年限制金额',
    pushNotification: '推送通知',
    indicators: '指标',
    turnover: '周转',
    totalNetBalance: '净余额共计',
    extraWallets: '额外钱包',
    tillDate: '截至日期',
    tillDateInfo: '截止日期可选在当前日期之后至少七天',
    currentType: '当前类型',
    upcomingType: '即将推出的类型',
    upcomingDate: '即将到来的日期',
    editAccessLimit: '编辑访问限制',
    weekDays: '工作日',
    inactivePeriodTime: '非活跃期时间',
    setBy: '设置',
    effectDate: '生效日期',
    accessLimitTimezoneInfo: '访问限制将使用品牌默认时区：{{timezone}}',
    upcoming: '即将到来',
    timeTo: '时间到',
    timeFrom: '时间从',
    viewAccessLimit: '查看访问限制',
    cpfNumberVerified: 'CPF已验证',
    deposit: '存款',
    withdrawal: '取款',
    smsPreferencesDescription: '选择玩家将收到短信通知的事件。',
    emailPreferencesDescription: '选择玩家将收到电子邮件通知的事件。',
    pushPreferencesDescription: '选择玩家将收到推送通知的事件。',
  },
  ro: {
    lastDeposit: 'Ultimul depozit',
    takenMonthData: 'Sunt luate în considerare numai datele din ultimele {{count}} luni',
    method: 'Metodă',
    lastWithdrawal: 'Ultima retragere',
    last24hDeposits: 'Depuneri în ultimele 24 de ore',
    last24hWithdrawals: 'Retrageri în ultimele 24 de ore',
    playerActivities: 'Acțiunile jucătorilor',
    lastLoginInfo: 'Ultimele informații de conectare',
    registrationInfo: 'Informații de înregistrare',
    blockedProviders: 'Furnizori blocați',
    identityVerified: 'Identitate verificată',
    emailVerified: 'E-mail verificat',
    mobileVerified: 'Telefon mobil verificat',
    addressVerified: 'Adresă verificată',
    accountLocked: 'Cont blocat',
    locked: 'Blocat',
    accountInactive: 'Cont inactiv',
    blockedForMessages: 'Blocat pentru mesaje',
    closed: 'Închis',
    inactiveAccount: 'Cont inactiv',
    accountInTimeout: 'Cont expirat',
    accountSelfExcluded: 'Cont auto-exclus',
    exclusionState: 'Stare de excludere',
    physicalVerification: 'Verificare fizică',
    blacklisted: 'Pe lista neagră',
    regDate: 'Data de înreg.',
    regIP: 'IP de înreg.',
    emailNotifications: 'Notificări prin e-mail',
    SMSNotifications: 'Notificări prin SMS',
    accountType: 'Tip de cont',
    accountUsers: 'Utilizatori de cont',
    lastProvisionDate: 'Data ultimei prevederi',
    lastAdjustmentDate: 'Data ultimei ajustări',
    currencyDeletedOrDisabledTooltip: 'Este posibil ca moneda contului să fie dezactivată/ștearsă',
    adjustBalance: 'Completează soldul',
    finishUse: 'Închide utilizarea',
    closedAccountNote: 'Contul va fi închis și nu va mai fi disponibil.',
    freeAccountNote: 'Contul va deveni gratuit pentru utilizare.',
    testAccountDetails: 'Detalii ale contului de testare',
    userDetails: 'Detalii de utilizator',
    accountUser: 'Utilizator de cont',
    userEmail: 'E-mail de utilizator',
    userEmailTooltip: 'Acreditările contului vor fi trimise la adresa de e-mail furnizată',
    adjust: 'Completează',
    moneyTransferred: 'Bani transferați',
    moneyUsed: 'Bani folosiți',
    periodStart: 'Începutul perioadei',
    periodEnd: 'Sfârșitul perioadei',
    provisionDate: 'Data de prevedere',
    providedBy: 'Furnizat de',
    attachmentId: 'ID de atașament',
    startProcess: 'Începe procesul',
    manageFiles: 'Gestiona fișierele',
    blockControl: 'Control de blocare',
    unblockMessages: 'Debloca mesaje',
    investigationResults: 'Rezultate de investigație',
    noAvailableAccountInfo: 'Contul nu va mai fi disponibil pentru utilizare.',
    lComplete: 'completat',
    lIncomplete: 'incomplet',
    accountVerificationProgress: 'Progres de verificare al contului',
    newslettersAndPromotions: 'Buletine informative și promoții',
    noLimit: 'Fără limită',
    current: 'Curent',
    remainder: 'Rest',
    upcomingChanges: 'Schimbări viitoare',
    limitType: 'Tip de limită',
    dateRequested: 'Data solicitată',
    endOfLimitation: 'Sfârșit de limitări',
    appliedBy: 'Aplicat de',
    exportNoteInfo: 'Datele de până la 6 luni vor fi exportate.',
    accountStatusBeforeClosure: 'Starea contului înainte de închidere',
    accountRegistrationDate: 'Data înregistrării contului',
    accountClosureDate: 'Data închiderii contului',
    reasonOfClosure: 'Motivul închiderii',
    matchingCriteria: 'Criterii de potrivire',
    dateAgreed: 'Data convenită',
    accountInformation: 'Informații despre cont',
    test: 'Test',
    personalInformation: 'Date personale',
    walletInformation: 'Informații despre portofel',
    age: 'Vârstă',
    countryCity: 'Țară, oraș',
    mobile: 'Telefon mobil',
    documents: 'Documente',
    currencyConverter: 'Convertor valutar',
    pendingFileGroups: 'Grupuri de fișiere în așteptare',
    verifiedWith: 'Verificat cu',
    validTill: 'Valid până la',
    uploadAsApproved: 'Încărca ca aprobat',
    archive: 'Arhivă',
    unverifyPlayerIdentity: 'Neverifica identitatea jucătorului',
    unverifyPlayerAddress: 'Anulează verificarea adresei jucătorului',
    fileNameAndExtension: 'Numele și extensia fișierului',
    approvedBy: 'Aprobat de',
    notBlacklisted: 'Neinclus pe lista neagră',
    statusBefore: 'Starea înainte',
    statusAfter: 'Starea după',
    currentStatus: 'Starea actuală',
    playersWithMissingInfo: 'Jucători cu informații incomplete',
    verifyDocumentInfo: 'Asigură-te că toate documentele aprobate conțin informații complete înainte de verificare.',
    notes: 'Note',
    markAttention: 'Semn de atenție',
    playerSession: 'Sesiune de jucător',
    playerBalance: 'Soldul jucătorului',
    playersBalance: 'Soldurile jucătorilor',
    testPlayersAccount: 'Cont de testare al jucătorului',
    accountUsersInformation: 'Informații despre contul de utilizator',
    process: 'Proces',
    attentionLevel: 'Nivelul de atenție',
    playerNotes: 'Notele jucătorului',
    cards: 'Carduri',
    sessionId: 'ID-ul sesiunii',
    sessionStart: 'Începutul sesiunii',
    sessionEnd: 'Sfârșitul sesiunii',
    sessionEndReason: 'Motivul încheierii sesiunii',
    walletTransactionId: 'ID-ul tranzacției de portofel',
    realWalletAfter: 'Portofel real după',
    history: 'Istoric',
    sumRealWallets: 'Sumă de portofele reale',
    actionLogs: 'Jurnalele de acțiuni',
    day1HoursLimitAmount: 'Suma limită pentru 24 de ore',
    days7LimitAmount: 'Suma limită pentru 7 zile',
    month1LimitAmount: 'Suma limită pentru 1 lună',
    selectedProviders: 'Furnizori selectați',
    countryOfCitizenship: 'Țara de cetățenie',
    lCountryOfCitizenship: 'țara de origine',
    citizenshipVerified: 'Cetățenie verificată',
    blockProviders: 'Blochează furnizorii',
    blockedProvidersList: 'Lista de furnizori blocați',
    responsibleGamingLimits: 'Limite de joc responsabil',
    p2pGgr: 'P2P VBJ',
    similarAccountsInfo: 'Aici putem vedea conturile, care se filtrează în ultima lună cu aceste criterii {{criteria1}} și {{criteria2}}',
    nationality: 'Naționalitate',
    pep: 'PEP',
    verticalCategoryNoReasonInfo: 'Te rugăm să activezi motivele pentru această categorie sau să modifici cerința de motive din categorie',
    clickToSeePendingFiles: 'Fă clic pe pictogramă pentru a vedea fișierele în așteptare',
    repetitionPeriod: 'Perioadă de repetare',
    subAffiliateId: 'ID subafiliat',
    repetitionDate: 'Data repetării',
    approvalDate: 'Data aprobării',
    debtWallet: 'Portofelul datoriei',
  },
  bg: {},
  el: {
    identityVerified: 'Επαληθευμένη ταυτότητα',
    emailVerified: 'Επαληθευμένο email',
    mobileVerified: 'Επαληθευμένο κινητό',
    addressVerified: 'Επαληθευμένη διεύθυνση',
    accountLocked: 'Ο λογαριασμός είναι κλειδωμένος',
    locked: 'Κλειδωμένος',
    accountInactive: 'Ο λογαριασμός είναι ανενεργός',
    blockedForMessages: 'Αποκλείστηκε για μηνύματα',
    closed: 'Κλειστό',
    inactiveAccount: 'Ανενεργός λογαριασμός',
    accountInTimeout: 'Ο λογαριασμός βρίσκεται σε προσωρινή διακοπή',
    accountSelfExcluded: 'Ο λογαριασμός βρίσκεται υπό αυτοαποκλεισμό',
    exclusionState: 'Κατάσταση αποκλεισμού',
    physicalVerification: 'Φυσική επαλήθευση',
    blacklisted: 'Βρίσκεται στη λίστα αποκλεισμένων',
    regDate: 'Ημερομηνία εγγραφής',
    regIP: 'IP εγγραφής',
    emailNotifications: 'Ειδοποιήσεις email',
    SMSNotifications: 'Ειδοποιήσεις SMS',
    accountType: 'Τύπος λογαριασμού',
    accountUsers: 'Χρήστες λογαριασμού',
    lastProvisionDate: 'Ημερομηνία τελευταίας παροχής',
    lastAdjustmentDate: 'Ημερομηνία τελευταίας προσαρμογής',
    currencyDeletedOrDisabledTooltip: 'Το νόμισμα λογαριασμού ενδέχεται να έχει απενεργοποιηθεί/διαγραφεί',
    adjustBalance: 'Προσαρμογή υπολοίπου',
    finishUse: 'Τέλος χρήσης',
    closedAccountNote: 'Ο λογαριασμός θα κλείσει και δεν θα είναι πλέον διαθέσιμος.',
    freeAccountNote: 'Ο λογαριασμός θα είναι ελεύθερος για χρήση.',
    testAccountDetails: 'Στοιχεία δοκιμαστικού λογαριασμού',
    userDetails: 'Στοιχεία χρήστη',
    accountUser: 'Χρήστης λογαριασμού',
    userEmail: 'Email χρήστη',
    userEmailTooltip: 'Τα στοιχεία πρόσβασης του λογαριασμού θα σταλούν στο email που έχετε δώσει',
    adjust: 'Προσαρμογή',
    moneyTransferred: 'Χρήματα που μεταφέρθηκαν',
    moneyUsed: 'Χρήματα που χρησιμοποιήθηκαν',
    periodStart: 'Έναρξη περιόδου',
    periodEnd: 'Λήξη περιόδου',
    provisionDate: 'Ημερομηνία παροχής',
    providedBy: 'Παροχή από',
    attachmentId: 'Αναγνωριστικό συνημμένου',
    startProcess: 'Έναρξη διαδικασίας',
    manageFiles: 'Διαχείριση αρχείων',
    blockControl: 'Έλεγχος αποκλεισμού',
    unblockMessages: 'Αναίρεση αποκλεισμού μηνυμάτων',
    investigationResults: 'Αποτελέσματα έρευνας',
    noAvailableAccountInfo: 'Ο λογαριασμός δεν θα είναι πλέον διαθέσιμος για χρήση.',
    lComplete: 'ολοκληρωμένο',
    lIncomplete: 'μη ολοκληρωμένο',
    accountVerificationProgress: 'Πρόοδος επαλήθευσης λογαριασμού',
    newslettersAndPromotions: 'Ενημερωτικά δελτία και προωθητικές ενέργειες',
    betLimit: {
      modalTitle: 'Ρύθμιση ορίου στοιχήματος',
      name: 'Όριο στοιχήματος',
      description: 'Έλεγχος των χρημάτων που ξοδεύει ο παίκτης σε τυχερά παιχνίδια',
      buttonText: 'Ρύθμιση ορίου',
    },
    withdrawalLimit: {
      modalTitle: 'Ρύθμιση ορίου ανάληψης',
      name: 'Όριο ανάληψης',
      description: 'Έλεγχος των χρημάτων ανάληψης παίκτη',
      buttonText: 'Ρύθμιση ορίου',
    },
    depositLimit: {
      modalTitle: 'Ρύθμιση ορίου κατάθεσης',
      name: 'Όριο κατάθεσης',
      description: 'Έλεγχος των χρημάτων που καταθέτει ο παίκτης για συμμετοχή σε τυχερά παιχνίδια',
      buttonText: 'Ρύθμιση ορίου',
    },
    timeOutLimit: {
      modalTitle: 'Ρύθμιση προσωρινής διακοπής',
      name: 'Προσωρινή διακοπή',
      description: 'Δίνει στον παίκτη την ευκαιρία να απέχει για ένα σύντομο χρονικό διάστημα από όλες τις δραστηριότητες τυχερών παιχνιδιών',
      buttonText: 'Ρύθμιση προσωρινής διακοπής',
    },
    selfExclusionLimit: {
      modalTitle: 'Set Exclusion',
      name: 'Αυτοαποκλεισμός',
      description: 'Αποκλείει τον παίκτη από όλες τις δραστηριότητες τυχερών παιχνιδιών, ώστε να διατηρεί τον έλεγχο',
      buttonText: 'Ορισμός αποκλεισμού',
    },
    realityCheckLimit: {
      modalTitle: 'Ρύθμιση ελέγχου',
      name: 'Reality Check',
      description: 'Υπενθυμίζει στον παίκτη πόσο χρόνο έχει περάσει στην πλατφόρμα',
      buttonText: 'Ρύθμιση ελέγχου',
    },
    noLimit: 'Χωρίς Όριο',
    current: 'Τρέχον',
    remainder: 'Απομένει',
    upcomingChanges: 'Επερχόμενες αλλαγές',
    limitType: 'Τύπος ορίου',
    dateRequested: 'Ημερομηνία αιτήματος',
    endOfLimitation: 'Τέλος περιορισμού',
    appliedBy: 'Εφαρμόστηκε από',
    exportNoteInfo: 'Θα εξάγονται δεδομένα μέχρι 6 μηνών.',
    accountStatusBeforeClosure: 'Κατάσταση λογαριασμού πριν από το κλείσιμο',
    accountRegistrationDate: 'Ημερομηνία εγγραφής λογαριασμού',
    accountClosureDate: 'Ημερομηνία κλεισίματος λογαριασμού',
    reasonOfClosure: 'Αιτία κλεισίματος',
    matchingCriteria: 'Κριτήρια αντιστοίχισης',
    dateAgreed: 'Ημερομηνία συμφωνίας',
    accountInformation: 'Πληροφορίες λογαριασμού',
    test: 'Δοκιμαστικός',
    personalInformation: 'Προσωπικά στοιχεία',
    walletInformation: 'Στοιχεία πορτοφολιού',
    age: 'Ηλικία',
    countryCity: 'Χώρα, πόλη',
    mobile: 'Κινητό',
    documents: 'Έγγραφα',
    currencyConverter: 'Μετατροπέας νομισμάτων',
    pendingFileGroups: 'Ομάδες αρχείων σε εκκρεμότητα',
    verifiedWith: 'Επαληθεύτηκε με',
    uploadAsApproved: 'Μεταφόρτωση ως εγκεκριμένο',
    archive: 'Αρχειοθέτηση',
    unverifyPlayerIdentity: 'Αναίρεση επαλήθευσης ταυτότητας παίκτη',
    unverifyPlayerAddress: 'Αναίρεση επαλήθευσης διεύθυνσης παίκτη',
    fileNameAndExtension: 'Όνομα και επέκταση αρχείου',
    approvedBy: 'Εγκρίθηκε από',
    notBlacklisted: 'Δεν βρίσκεται στη λίστα αποκλεισμένων',
    statusBefore: 'Κατάσταση πριν',
    statusAfter: 'Κατάσταση μετά',
    currentStatus: 'Τρέχουσα κατάσταση',
    playersWithMissingInfo: 'Παίκτες με ελλιπή στοιχεία',
    verifyDocumentInfo: 'Βεβαιωθείτε ότι τα στοιχεία που περιέχονται σε όλα τα εγκεκριμένα έγγραφα είναι πλήρη πριν από την επαλήθευση.',
    notes: 'Σημειώσεις',
    markAttention: 'Επισήμανση προσοχής',
    playerSession: 'Συνεδρία παίκτη',
    playerBalance: 'Υπόλοιπο παίκτη',
    playersBalance: 'Υπόλοιπα παικτών',
    testPlayersAccount: 'Δοκιμαστικός λογαριασμός παίκτη',
    accountUsersInformation: 'Στοιχεία χρήστη λογαριασμού',
    process: 'Επεξεργασία',
    attentionLevel: 'Επίπεδο προσοχής',
    playerNotes: 'Σημειώσεις παίκτη',
    cards: 'Κάρτες',
    sessionId: 'Αναγνωριστικό συνεδρίας',
    sessionStart: 'Έναρξη συνεδρίας',
    sessionEnd: 'Λήξη συνεδρίας',
    sessionEndReason: 'Αιτία λήξης συνεδρίας',
    walletTransactionId: 'Αναγνωριστικό συναλ. πορτοφολιού',
    realWalletAfter: 'Πορτοφόλι πραγματικών χρημάτων μετά',
    history: 'Ιστορικό',
    sumRealWallets: 'Ποσό πορτοφολιών πραγματικών χρημάτων',
    actionLogs: 'Αρχεία καταγραφής ενεργειών',
    day1HoursLimitAmount: 'Ποσό ορίου 24 ωρών',
    days7LimitAmount: 'Ποσό ορίου 7 ημερών',
    month1LimitAmount: 'Ποσό ορίου 1 μήνα',
    selectedProviders: 'Επιλεγμένοι πάροχοι',
    countryOfCitizenship: 'Χώρα υπηκοότητας',
    lCountryOfCitizenship: 'χώρα υπηκοότητας',
    citizenshipVerified: 'Επαληθευμένη υπηκοότητα',
    blockProviders: 'Αποκλεισμός παρόχων',
    blockedProvidersList: 'Λίστα αποκλεισμένων παρόχων',
    blockedProviders: 'Αποκλεισμένοι πάροχοι',
    lastDeposit: 'Τελευταία κατάθεση',
    lastWithdrawal: 'Τελευταία ανάληψη',
    last24hDeposits: 'Καταθέσεις εντός των τελευταίων 24 ωρών',
    last24hWithdrawals: 'Αναλήψεις εντός των τελευταίων 24 ωρών',
    takenMonthData: 'Λαμβάνονται υπόψη μόνο δεδομένα από τους τελευταίους {{count}} μήνες',
    method: 'Μέθοδος',
    playerActivities: 'Δραστηριότητες παίκτη',
    lastLoginInfo: 'Στοιχεία τελευταίας σύνδεσης',
    registrationInfo: 'Πληροφορίες εγγραφής',
    responsibleGamingLimits: 'Όρια υπεύθυνου παιχνιδιού',
    p2pGgr: 'GGR από P2P',
    similarAccountsInfo: 'Εδώ μπορούμε να βλέπουμε το φιλτράρισμα των λογαριασμών κατά τη διάρκεια του τελευταίου μήνα με αυτά τα κριτήρια {{criteria1}} και {{criteria2}}',
    nationality: 'Εθνικότητα',
    pep: 'PEP',
    verticalCategoryNoReasonInfo: 'Ενεργοποιήστε τις αιτίες για αυτή την κατηγορία ή επεξεργαστείτε το προαπαιτούμενο αιτιών από αυτή την κατηγορία',
    clickToSeePendingFiles: 'Κάντε κλικ στο εικονίδιο για να δείτε τα αρχεία που είναι σε εκκρεμότητα',
    repetitionPeriod: 'Περίοδος επανάληψης',
    subAffiliateId: 'Αναγνωριστικό υποσυνεργάτη',
    repetitionDate: 'Ημερομηνία επανάληψης',
    approvalDate: 'Ημερομηνία έγκρισης',
    debtWallet: 'Πορτοφόλι οφειλών',
    personalNumericCode: 'Προσωπικός αριθμητικός κωδικός',
    personalNumericCodeNote:
      'Αν είναι επιλεγμένο, το πεδίο Προσωπικού αριθμητικού κωδικού προστίθεται στη ροή Επαλήθευσης εγγράφων, μπορεί να επαληθευτεί με Έγγραφο και είναι διαθέσιμο σε διάφορες αναφορές.',
    brandRealityCheckLimitInfo: 'Το όριο διαμορφώνεται για όλους τους παίκτες της επωνυμίας και δεν μπορεί να αλλάξει για τον παίκτη προσωπικά',
    externalPlayerId: 'Εξωτερικό αναγνωριστικό παίκτη',
    documentDescriptionInfo: 'Περιγράψτε το έγγραφο',
    realWalletBefore: 'Πορτοφόλι πραγματικών χρημάτων πριν',
    resetPassword: 'Επαναφορά κωδικού πρόσβασης',
    PlayerMobileNumberInUse: 'Υπάρχει ήδη παίκτης με αυτόν τον αριθμό τηλεφώνου',
    PlayerEmailInUse: 'Υπάρχει ήδη παίκτης με αυτή τη διεύθυνση email',
    cpfNumber: 'Αριθμός CPF',
    disableTFA: 'Απενεργοποίηση 2FA',
    tFA: '2FA',
    missingFieldsInAttachmentsInfo: 'Δεν έχουν υποβληθεί τα απαιτούμενα δεδομένα για την έγκριση αυτού του εγγράφου',
  },
};
