export const selectCustomScripts = (state) => state.customScripts;
export const selectBrandAffiliateServices = (state) => state.affiliateServices;
export const selectBrandAffiliateService = (state) => state.affiliateService;
export const selectBrandAnalyticsServices = (state) => state.analyticServices;
export const selectBrandAnalyticService = (state) => state.analyticService;
export const selectBrandLiveChatSolutionServices = (state) => state.liveChatSolutionServices;
export const selectBrandLiveChatSolutionService = (state) => state.liveChatSolutionService;
export const selectBrandAgSolutionServices = (state) => state.agSolutionServices;
export const selectBrandAgSolutionService = (state) => state.agSolutionService;
export const selectBrandGamificationServices = (state) => state.gamificationServices;
export const selectBrandGamificationService = (state) => state.gamificationService;
export const selectBrandCrmServices = (state) => state.crmServices;
export const selectBrandCrmService = (state) => state.crmService;
export const selectBrandRetailSolutionServices = (state) => state.retailSolutionServices;
export const selectBrandRetailSolutionService = (state) => state.retailSolutionService;
export const selectBrandAgSolutionServicePaymentLimits = (state) => state.agSolutionServicePaymentLimits;
export const selectBrandKycServices = (state) => state.kycServices;
export const selectBrandKycService = (state) => state.kycService;
export const selectBrandAuthenticationServices = (state) => state.authenticationServices;
export const selectBrandAuthenticationService = (state) => state.authenticationService;
export const selectBrandFirebaseServicePreferences = (state) => state.firebasePreferences;
