import { Modal } from 'antd';
import styled from 'styled-components';
import { media, textAlignCenter, truncate } from 'utils/styledHelpers';

const StyledModal = styled(Modal)`
  color: ${(props) => props.theme.colors.primaryText};
  .ant-modal-content {
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
    padding: 0;
  }
  .ant-upload.ant-upload-drag {
    background: none;
    border: none;
  }
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
  .ant-upload.ant-upload-drag .ant-upload &.autoModal {
    max-height: unset;
    .ant-modal-footer {
      justify-content: center;
      padding-top: 0;
    }
    .ant-modal-close {
      top: 16px;
    }
  }
  &.confirm-modal {
    .ant-modal-footer {
      justify-content: center;
    }
    .ant-modal-footer {
      padding: 0 3.43rem 3.43rem;
    }
    .ant-modal-body {
      padding: 2.85rem 3.43rem 2.85rem;
      ${media.sm`
        padding: ${(props) => (props.mdPaddings ? '2.85rem 1.4rem 1.4rem' : '2.85rem 1.4rem 0')};    
      `}
    }
    .ant-modal-close {
      top: 10px;
    }
  }
  .ant-modal-content {
    background-color: ${(props) => props.theme.colors.selectBg};
    box-shadow: 0 3px 6px #00000016;
  }

  .ant-modal-close {
    top: 1.1rem;
    right: 1.14rem;
    width: 22px;
    height: 22px;
    &:hover,
    &:focus {
      background-color: transparent;
    }
    .ant-modal-close-x {
      width: initial;
      height: initial;
    }
  }

  .ant-modal-body {
    padding: ${(props) => (props.mdPaddings ? '3.43rem' : '3.43rem 3.43rem 0')};

    ${media.sm`
      padding: ${(props) => (props.mdPaddings ? '3.45rem 1.4rem' : '3.45rem 1.4rem 0')};      
    `}
  }

  .ant-modal-footer {
    border: initial;
    padding: 2.86rem 3.43rem 3.43rem;
    margin-top: 0;
  }
  .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    margin-left: 1.14rem;
  }
  .ant-modal-header {
    border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
    padding: 0 1.71rem;
    height: 3.43rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    .ant-modal-title {
      font-weight: ${(props) => props.theme.typography.fontWeightMedium};
      color: ${(props) => props.theme.colors.primaryText};
      font-size: ${(props) => props.theme.typography.fontSizeMiddle};
      user-select: none;
    }
  }
  .ant-card-meta-description {
    color: ${(props) => props.theme.colors.primaryText};
  }
  .ant-form-item-control-input-content textarea.ant-input {
    height: 156px;
    padding: 1.14rem;
    resize: none;
  }
  .ant-calendar-range-picker-input {
    width: 100px;
    text-align: left;
  }
  .ant-calendar-range-picker-separator {
    vertical-align: initial;
    padding: 0 20px 0 0;
  }
`;

const StyledModalView = styled(StyledModal)`
  width: 75rem !important;

  @media (max-width: 1366px) {
    width: 76% !important;
  }
  @media (max-width: 1024px) {
    width: 100% !important;
  }

  .ant-modal-content {
    height: 476px;

    ${media.sm`
      height: auto;
    `}
  }

  .ant-modal-body {
    display: flex;
    padding: 3.43rem;
    height: 90%;

    ${media.sm`
      display: block;
      padding: 1.4rem;
      height: auto;
    `}

    > div:first-child {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-basis: 66%;
      margin-right: 6.86rem;
      background-color: ${(props) => props.theme.colors.primaryBg};
      border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
      @media (max-width: 1200px) {
        flex-basis: 55%;
      }
      ${media.sm`
        flex-basis: 100%;
        margin: 0 0 2rem;
        background: transparent;
      `}

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
      }
      &:after {
        position: absolute;
        content: ' ';
        height: 100%;
        width: 1px;
        border-left: 1px solid ${(props) => props.theme.colors.primaryBorder};
        right: -48px;

        ${media.sm`
          content: none;
        `}
      }
    }
    > div:last-child {
      flex-basis: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    video {
      width: 100%;
      height: 100%;
    }
    .pdf-viewer-canvas {
      height: 100%;
      canvas {
        width: 400px !important;
        height: 332px !important;
        object-fit: cover;
      }
    }
  }
`;

const StyledViewModalDetailsRow = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: ${(props) => (props.allowWrap ? 'wrap' : 'nowrap')};
  gap: 0.72rem;
  justify-content: space-between;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.primaryText};

  span:first-child {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
  a {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${(props) => props.theme.colors.blue};
    &:hover {
      color: ${(props) => props.theme.colors.blue};
      text-decoration: underline;
    }
  }
`;

const StyledViewModalDetailsUrl = styled.div`
  display: flex;
  span {
    margin-right: 0.57rem;
    svg {
      cursor: pointer;
      &:hover {
        path {
          fill: ${(props) => props.theme.colors.hover};
        }
      }
    }
  }
`;

const StyledModalBig = styled(StyledModal)`
  min-height: 58%;
  .ant-modal-close {
    display: block;
  }
  .ant-modal-body {
    padding: 0;
    display: flex;
  }
  .ant-table-thead > tr:hover.ant-table-row-selected > td,
  .ant-table-tbody > tr:hover.ant-table-row-selected > td {
    background-color: transparent;
  }
  .ant-checkbox + span {
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    color: ${(props) => props.theme.colors.primaryText};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
`;

const StyledModalBigger = styled(StyledModal)`
  height: 92%;
  &.ant-modal {
    padding-bottom: 0;
  }
  .ant-modal-header,
  .ant-modal-footer {
    flex-shrink: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: ${(props) => (props.mdPaddings ? '2.85rem 3.43rem' : '2.86rem 3.43rem 0')};
    .ant-form {
      height: initial;
    }
  }
  .ant-checkbox + span {
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    color: ${(props) => props.theme.colors.primaryText};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
`;

const StyledConfigureModal = styled(StyledModalBigger)`
  .ant-modal-body {
    display: flex;
    flex-direction: ${(props) => (props.isSmallSize ? 'column' : 'row')};
    align-items: ${(props) => (props.isSmallSize ? 'center' : 'flex-start')};
    padding: ${(props) => (props.isSmallSize ? '1.16rem' : '3.43rem 3.43rem 0')};
    flex-wrap: wrap;
    flex-grow: 0;

    ${media.sm`
      padding: ${(props) => (props.mdPaddings ? '1.4rem' : '1.4rem 1.4rem 0')};      
    `}
  }
  .ant-modal-footer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-grow: 1;
  }
`;
const StyledConfigureModalInfoWrapper = styled.div`
  flex: 0 0 100%;
`;

const StyledModalQuestion = styled.div`
  ${textAlignCenter()};
  color: ${(props) => props.theme.colors.primaryText};
  line-height: 1.71rem;
  font-size: 1rem;
  .ant-modal-content {
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  }
`;

const SaveDiscardButtonsGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.isCenter ? 'center' : 'flex-end')};
  .ant-btn {
    height: 2.57rem;
    font-size: 1rem;
  }
`;

const TableTdDistance = styled.section`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -0.5rem;
  display: flex;
  > span {
    margin-left: 1.25rem;
  }
`;
const CommsComposeModalStyle = styled(Modal)`
  padding-bottom: 0;

  .ant-modal-content {
    height: 100%;
    .ant-modal-body {
      padding: 2.29rem 2.5rem 3.14rem;
      max-height: 86vh;
      overflow: auto;

      ${media.xs`
        padding: 2.29rem 0.71rem 3.14rem;
      `}
    }
  }
`;
const StyledModalForm = styled.div`
  .has-error .ant-form-explain {
    top: 37px;
  }
  .ant-form-item-children {
    display: block;
    height: 36px;
  }
  .ant-form-item {
    padding-bottom: 2rem;
  }
  .ant-form-vertical .ant-form-item-label > .ant-form-item-required:after,
  .ant-form-horizontal .ant-form-item-label > .ant-form-item-required:after {
    color: ${(props) => props.theme.colors.secondaryColor};
  }
  .ant-modal-content {
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  }
`;

const StyledModalLeft = styled.div`
  width: calc(50% - 2.86rem);
  flex: 0 0 calc(50% - 2.86rem);
  margin-right: 2.86rem;

  ${media.xs`
    width: 100%;
    flex: 0 0 100%;
    margin: 0 0 3rem;
  `}
`;

const StyledModalRight = styled.div`
  width: ${(props) => (props.fullWidth ? '100%' : '50%')};
  flex: 0 0 ${(props) => (props.fullWidth ? '100%' : '50%')};

  ${media.xs`
    width: 100%;
    flex: 0 0 100%;
  `}

  > div {
    width: auto;
  }
`;
const StyledPlayerCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledRightModalBorderBottomHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  padding: 0.1rem 1.7rem 0.3rem 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.primaryBorder};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
  color: ${(props) => props.theme.colors.primaryText};
`;
const StyledTotalPlayers = styled.div`
  font-size: ${(props) => props.theme.typography.fontSizeMiddle};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => (!props.isActive ? props.theme.colors.primaryText : props.theme.colors.blue)};
  &:hover {
    color: ${(props) => props.theme.colors.blue};
    text-decoration: ${(props) => (props.isActive ? '' : 'underline')};
  }
  cursor: ${(props) => (props.isActive ? 'initial' : 'pointer')};
`;

const StyledModalInvalidBluePlayers = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
  color: ${(props) => props.theme.colors.primaryText};
  padding-right: 1.71rem;
  > span {
    &:first-child {
      background: ${(props) => (props.isActive ? props.theme.colors.blue : `${props.theme.colors.blue}20`)};
      color: ${(props) => (props.isActive ? props.theme.colors.white : props.theme.colors.primaryText)};
      cursor: ${(props) => (props.isActive ? 'initial' : 'pointer')};
      padding: 0.43rem 1.14rem;
      border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
      transition: 0.2s all;
    }
  }
`;

const StyledAllModalTable = styled.div`
  .ant-table-cell.ant-table-cell-scrollbar {
    display: none;
  }
  .ant-spin-container .ant-pagination {
    padding-bottom: 0;
  }
  .ant-table-pagination.ant-pagination {
    margin-bottom: 0;
  }
  .ant-table .ant-table-thead {
    background: none;
    > tr > th {
      background: none;
    }
  }
  height: calc(100% - 2rem);
  a {
    color: ${(props) => props.theme.colors.blue};
  }
  .ant-checkbox-wrapper {
    display: inline-flex !important;
    margin-top: 5px;
  }
  .ant-table-empty {
    .ant-table-header {
      display: none;
    }
    & .ant-table-tbody > tr:last-child:not(.ant-table-expanded-row):not(.partners-brands-row):not(.permission-row) > td {
      border-bottom: none !important;
    }
    .ant-empty-normal {
      margin-right: 1.79rem;
    }
  }
`;

const StyledModalColumn = styled.div`
  text-align: center;
  display: flex;
  justify-content: flex-start;

  > div {
    > div {
      background: ${(props) => props.theme.colors.primaryBg};
      border: 1px solid ${(props) => props.theme.colors.primaryBorder};
      border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
      width: max-content;
      max-width: 6.78rem;
      padding: 0 0.27rem;
      height: 2rem;
      line-height: 2rem;
      margin-right: 0.57rem;
      ${truncate()}
    }
    > span {
      height: calc(100% - 0.71rem);
    }
  }
`;
const StyledModalTab = styled.div`
  .ant-tabs-tab-active {
    border-bottom: none;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background: ${(props) => props.theme.colors.blue};
    }
  }
  .ant-tabs-tab-btn {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }

  .ant-tabs-tab {
    padding: 0 0.85rem 0.85rem;
  }
`;
const StyledModalSearch = styled.div`
  margin: 1rem 0 0.43rem;
  > div {
    width: 100%;
  }
  .ant-select .ant-select-selector {
    padding-right: 0;
  }
  .ant-input-group-wrapper.ant-input-search {
    height: 2.57rem;
    .ant-input-search-icon::before {
      display: none;
    }
  }
  .ant-input {
    background: transparent;
  }
`;
const RightSearchFieldForModals = styled.div`
  .ant-input-search {
    margin: 1.71rem 0 0;
  }
`;
const StyledAllPlayer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 1rem;
  margin-bottom: 1.14rem;
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    .ant-checkbox {
      order: 2;
    }
  }
`;
const CustomLabel = styled.p`
  color: ${(props) => props.theme.colors.primaryText};
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
  margin-bottom: 0.57rem;
`;

const StyledBulkUploadList = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledAllSelectedPlayers = styled.div`
  padding: 1.14rem 1.71rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  color: ${(props) => props.theme.colors.primaryText};
  display: inline-flex;
  margin: 2.57rem auto 0;
  align-items: center;
  font-size: 1rem;
  > div {
    margin-right: 1.14rem;
  }
`;
const StyledWithCheckboxModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ant-checkbox + span {
    font-size: 1rem;
  }
`;
const StyledAllPlayerTabs = styled.div`
  > div:not(:last-child) {
    margin-bottom: 0.85rem;
  }
  margin-top: 1.71rem;
`;

const StyledViewTableInModal = styled.div`
  padding-bottom: 3.42rem;
`;

export {
  StyledModal,
  StyledAllSelectedPlayers,
  StyledModalSearch,
  StyledAllPlayer,
  StyledModalBig,
  StyledModalBigger,
  StyledModalQuestion,
  StyledModalTab,
  StyledModalView,
  StyledViewModalDetailsRow,
  StyledViewModalDetailsUrl,
  SaveDiscardButtonsGroup,
  TableTdDistance,
  StyledConfigureModal,
  StyledConfigureModalInfoWrapper,
  CommsComposeModalStyle,
  StyledModalForm,
  StyledModalLeft,
  StyledModalRight,
  StyledAllModalTable,
  StyledModalColumn,
  StyledRightModalBorderBottomHeading,
  StyledModalInvalidBluePlayers,
  RightSearchFieldForModals,
  CustomLabel,
  StyledTotalPlayers,
  StyledPlayerCenter,
  StyledWithCheckboxModal,
  StyledBulkUploadList,
  StyledAllPlayerTabs,
  StyledViewTableInModal,
};
