import qs from 'qs';
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Popover, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ChangeMainConfigs, ChangeStatus, ChangeTimeZone } from 'containers/brandSetting';
import enumTypes from 'constants/enumTypes';
import { StyledIconFilter, StyledCollapseIcon, StyledSvgIconHover } from 'components/styledComponents/icon/Icon.styled';
import Config from 'config';

import { IconSvg } from 'components/common';
import {
  StyledBrandTemplateThumbnail,
  StyledIconWithTextWrapper,
  StyledIconWithText,
  StyledParagraph,
  StyledMainSettings,
  StyledTopMainSettings,
  StyledCountryLang,
  StyledStatusChange,
  StyledStatus,
  StyledStatusName,
  StyledBorderTop,
  StyledImgHoverIcons,
  StyledHoverIcons,
  StyledCollapseWrapper,
  StyledCardPartnerWrapper,
  StyledCollapseIconsWrapper,
  StyledCardBrandNameMain,
  StyledBrandCardMainConfig,
} from 'components/brand/Brand.styled';
import { StyledCardItemStatus } from 'components/styledComponents/card/Card.styled';
import { StyledMaintenancePeriod, StyledMaintenancePeriodDates, StyledTruncate } from 'Global.styled';
import { resource, types } from 'constants/accessControl';
import Can from 'components/common/Can';
import { useTimeZone } from 'hooks';
import { AppConstants } from 'constants';

const { isBrRegulationAudit } = Config;
const MainInfo = (props) => {
  const {
    brand: {
      id, name, licenseId, templateId, createdDate, status, market, disabledVersionNames, timeZone: brandTimeZone, domain, partnerId, maintenancePeriodFrom, maintenancePeriodTo,
    },
    theme: { colors },
    getBrand,
    main,
    getMarkets,
    markets,
    getTemplates,
    resetTemplates,
    templates,
    expand,
    createOrEditUserConfig,
    getTimeZones,
    timeZones,
    resetBrand,
    resetBrandSettings,
    locale,
    callEndedStatus,
    changeBrandCallEndedStatus,
  } = props;

  const { brandStatuses = {}, deviceTypes = {} } = locale;

  const { getFormattedDate } = useTimeZone();

  const { t } = useTranslation();

  const params = useParams();

  const [action, setAction] = useState({ type: '', visible: false });

  useEffect(() => {
    getTimeZones(true);
    getTemplates();
  }, [getTemplates, getTimeZones]);

  useEffect(() => {
    if (params.id) {
      getBrand(params.id, true);
    }
  }, [getBrand, params.id]);

  useEffect(
    () => () => {
      resetBrand();
      resetBrandSettings();
      resetTemplates();
    },
    [resetBrand, resetBrandSettings, resetTemplates],
  );

  useEffect(() => {
    getMarkets();
  }, [getMarkets]);

  const onClose = useCallback(() => {
    setAction({
      type: '',
      visible: false,
    });
  }, []);

  useEffect(() => {
    if (callEndedStatus) {
      onClose();
      changeBrandCallEndedStatus(false);
      getBrand(params.id, true);
    }
  }, [callEndedStatus, onClose, changeBrandCallEndedStatus, params.id, getBrand]);

  const template = templates.find((el) => el.id === templateId);

  const getStatus = () => {
    if (status !== enumTypes.brandStatuses.VersionNamesDisabled) {
      return brandStatuses[status];
    }
    return `${disabledVersionNames.map((el) => deviceTypes.get(el)).join(', ')} ${t('disabled')}`;
  };

  const isExpand = !_.isUndefined(expand) ? expand : true;

  const handleToggle = () => {
    createOrEditUserConfig(
      {
        key: 'brandSettingsExpand',
        configValue: !expand,
      },
      false,
    );
  };

  const cardStatuses = ['', 'seen', 'unseen', 'unseen', 'unseen', 'unseen'];

  const getTimeZone = () => {
    if (brandTimeZone && timeZones.length) {
      const data = timeZones.find((el) => el.id === brandTimeZone);
      return data && data.name;
    }
    return null;
  };

  const query = {
    cid: partnerId,
    p: id,
  };
  const uiCusUrl = `${Config.siteBuilderURL}edit/customize/${id}?${qs.stringify(query)}`;

  const timeZone = getTimeZone();

  const actionTypes = {
    statusChange: <ChangeStatus visible={action.visible} onClose={onClose} />,
    timeZoneChange: <ChangeTimeZone visible={action.visible} onClose={onClose} />,
    mainConfigsChange: <ChangeMainConfigs visible={action.visible} onClose={onClose} />,
  };

  const Modal = actionTypes[action.type];

  const renderMainConfigs = (isSmallSize) => name && (
    <StyledBrandCardMainConfig isSmallSize={isSmallSize}>
      <StyledCardBrandNameMain isSmallSize={isSmallSize}>{name}</StyledCardBrandNameMain>
      <StyledIconWithText>
        <IconSvg icon="BrandsVerificationIcon" width={14} height={14} />
        <StyledParagraph>
          {`${t('brand:licenseId')}:`}
          <Popover placement="bottomLeft" content={licenseId} title="" overlayClassName="description-popover">
            <StyledTruncate>
              <strong>{licenseId || '-'}</strong>
            </StyledTruncate>
          </Popover>
        </StyledParagraph>
      </StyledIconWithText>
      <Can type={types.edit} rule={`${resource.brands}.${resource.mainSettings}`}>
        {!isBrRegulationAudit && (
          <StyledSvgIconHover
            onClick={() => {
              setAction({
                type: 'mainConfigsChange',
                visible: true,
              });
            }}
          >
            <Tooltip placement="top" title={t('edit')}>
              <IconSvg icon="EditIcon" width={12} height={12} color={colors.blue} />
            </Tooltip>
          </StyledSvgIconHover>
        )}
      </Can>
    </StyledBrandCardMainConfig>
  );

  const renderStatus = () => (
    <StyledStatus isExpand={isExpand ? 1 : 0}>
      <StyledCardItemStatus status={cardStatuses[status]} />
      <StyledStatusName>
        <span>{id && getStatus()}</span>
        {!_.isEmpty(maintenancePeriodFrom) && (
          <StyledMaintenancePeriod>
            <IconSvg icon="MaintenanceIcon" width="1.14rem" height="1rem" color="" />
            <span>
              {`${t('brand:maintenancePeriod')}: `}
              <strong>
                <StyledMaintenancePeriodDates>
                  {`${getFormattedDate(maintenancePeriodFrom, AppConstants.format.dateTime)} - `}
                  {maintenancePeriodTo ? getFormattedDate(maintenancePeriodTo, AppConstants.format.dateTime) : <IconSvg icon="InfinityIcon" height="1.28rem" />}
                </StyledMaintenancePeriodDates>
              </strong>
            </span>
          </StyledMaintenancePeriod>
        )}
      </StyledStatusName>
      <Can type={types.edit} rule={`${resource.brands}.${resource.mainSettings}`}>
        {!isBrRegulationAudit && (
          <StyledStatusChange
            onClick={() => {
              setAction({
                type: 'statusChange',
                visible: true,
              });
            }}
          >
            <Tooltip placement="top" title={t('edit')}>
              <div>
                <IconSvg icon="EditIcon" width={12} height={12} color={colors.blue} />
              </div>
            </Tooltip>
          </StyledStatusChange>
        )}
      </Can>
    </StyledStatus>
  );

  const renderActions = () => (isExpand ? (
    <StyledImgHoverIcons>
      <StyledHoverIcons>
        <Link to={`//${domain}`} target="_blank">
          <IconSvg icon="PreviewIcon" />
        </Link>
      </StyledHoverIcons>
      <StyledHoverIcons>
        <Link to={uiCusUrl} target="_blank">
          <IconSvg icon="BrandBuilderIcon" />
        </Link>
      </StyledHoverIcons>
    </StyledImgHoverIcons>
  ) : (
    <StyledCollapseIconsWrapper>
      <div>
        <Tooltip placement="top" title={t('view')}>
          <Link to={`//${domain}`} target="_blank">
            <IconSvg icon="PreviewIcon" />
          </Link>
        </Tooltip>
      </div>
      <div>
        <Tooltip placement="top" title="Builder">
          <Link to={uiCusUrl} target="_blank">
            <IconSvg icon="BrandBuilderIcon" />
          </Link>
        </Tooltip>
      </div>
    </StyledCollapseIconsWrapper>
  ));

  return (
    <StyledBorderTop>
      {action.visible && Modal}
      <StyledCollapseIcon onClick={handleToggle}>
        <Tooltip title={t(isExpand ? 'collapse' : 'expand')} placement="top">
          <StyledIconFilter isExpand={isExpand}>
            <IconSvg icon="DropdownIcon" />
          </StyledIconFilter>
        </Tooltip>
      </StyledCollapseIcon>
      {isExpand ? (
        <StyledMainSettings>
          <StyledBrandTemplateThumbnail>
            {template ? <img alt="" src={template ? template.thumbnailUrl : ''} /> : null}
            {renderActions()}
          </StyledBrandTemplateThumbnail>
          <StyledTopMainSettings>
            {renderMainConfigs()}
            {renderStatus()}
            <StyledIconWithTextWrapper>
              <StyledCountryLang>
                <StyledIconWithText>
                  <IconSvg icon="InfoIcon" width={13} height={13} />
                  <StyledParagraph>
                    <Popover placement="bottomLeft" content={t('id')} title="" overlayClassName="description-popover">
                      <StyledTruncate>
                        <span>{`${t('id')}:`}</span>
                      </StyledTruncate>
                    </Popover>
                    <strong>{id}</strong>
                  </StyledParagraph>
                </StyledIconWithText>
                <StyledIconWithText>
                  <IconSvg icon="Calendar2Icon" width={13} height={13} />
                  <StyledParagraph>
                    <Popover placement="bottomLeft" content={t('registrationDate')} title="" overlayClassName="description-popover">
                      <StyledTruncate>
                        <span>{`${t('registrationDate')}:`}</span>
                      </StyledTruncate>
                    </Popover>
                    <strong>{createdDate && getFormattedDate(createdDate)}</strong>
                  </StyledParagraph>
                </StyledIconWithText>
                <StyledIconWithText>
                  <IconSvg icon="LocationIcon" width={13} height={13} />
                  <StyledParagraph>
                    {`${t('country')}:`}
                    <Popover placement="bottomLeft" content={main.country.name} title="" overlayClassName="description-popover">
                      <StyledTruncate>
                        <strong>{main.country.name}</strong>
                      </StyledTruncate>
                    </Popover>
                  </StyledParagraph>
                </StyledIconWithText>
                <StyledIconWithText>
                  <IconSvg icon="TimeZoneIcon" width={13} height={13} />
                  <StyledParagraph>
                    <Popover placement="bottomLeft" content={t('timeZone')} title="" overlayClassName="description-popover">
                      <StyledTruncate>
                        <span>{`${t('timeZone')}:`}</span>
                      </StyledTruncate>
                    </Popover>
                    <Popover placement="bottomLeft" content={timeZone} title="" overlayClassName="description-popover">
                      <StyledTruncate>
                        <strong>{timeZone}</strong>
                      </StyledTruncate>
                    </Popover>
                  </StyledParagraph>
                  <Can type={types.edit} rule={`${resource.brands}.${resource.mainSettings}`}>
                    {!isBrRegulationAudit && (
                      <StyledStatusChange
                        onClick={() => {
                          setAction({
                            type: 'timeZoneChange',
                            visible: true,
                          });
                        }}
                      >
                        <Tooltip placement="top" title={t('edit')}>
                          <div>
                            <IconSvg icon="EditIcon" width={12} height={12} color={colors.blue} />
                          </div>
                        </Tooltip>
                      </StyledStatusChange>
                    )}
                  </Can>
                </StyledIconWithText>
                <StyledIconWithText>
                  <IconSvg icon="LanguageIcon" width={12} height={12} />
                  <StyledParagraph>
                    {t('language')}
                    :
                    <span>
                      <strong>{main.language.name}</strong>
                    </span>
                  </StyledParagraph>
                </StyledIconWithText>
                <StyledIconWithText>
                  <IconSvg icon="CurrencyIcon" width={16} height={16} />
                  <StyledParagraph>
                    {t('currency')}
                    :
                    <span>
                      <strong>{main.currency.id}</strong>
                    </span>
                  </StyledParagraph>
                </StyledIconWithText>
                {!!markets.length && market && markets[0].id !== market.id && (
                  <StyledIconWithText>
                    <IconSvg icon="LicenseIcon" width={13} height={13} />
                    <StyledParagraph>
                      {t('brand:market')}
                      :
                      <span>
                        <strong>{market.name}</strong>
                      </span>
                    </StyledParagraph>
                  </StyledIconWithText>
                )}
              </StyledCountryLang>
            </StyledIconWithTextWrapper>
          </StyledTopMainSettings>
        </StyledMainSettings>
      ) : (
        <StyledCollapseWrapper>
          <StyledCardPartnerWrapper>
            {renderMainConfigs(true)}
            {renderStatus()}
          </StyledCardPartnerWrapper>
          <div>{renderActions()}</div>
        </StyledCollapseWrapper>
      )}
    </StyledBorderTop>
  );
};

MainInfo.propTypes = {
  brand: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  getBrand: PropTypes.func.isRequired,
  resetBrand: PropTypes.func.isRequired,
  resetBrandSettings: PropTypes.func.isRequired,
  main: PropTypes.object.isRequired,
  markets: PropTypes.array,
  getMarkets: PropTypes.func.isRequired,
  templates: PropTypes.array,
  getTemplates: PropTypes.func.isRequired,
  resetTemplates: PropTypes.func.isRequired,
  createOrEditUserConfig: PropTypes.func.isRequired,
  expand: PropTypes.bool,
  getTimeZones: PropTypes.func.isRequired,
  timeZones: PropTypes.array.isRequired,
  locale: PropTypes.object.isRequired,
  callEndedStatus: PropTypes.bool,
  changeBrandCallEndedStatus: PropTypes.func.isRequired,
};

MainInfo.defaultProps = {
  markets: [],
  templates: [],
  expand: undefined,
  callEndedStatus: false,
};

export default withTheme(MainInfo);
