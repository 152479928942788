import ThirdPartyServicesActionType from 'actions/thirdPartyServices/thirdPartyServicesActionType';

const initialState = {
  customScripts: [],
  affiliateServices: [],
  affiliateService: {},
  analyticServices: [],
  analyticService: {},
  liveChatSolutionServices: [],
  liveChatSolutionService: {},
  agSolutionServices: [],
  agSolutionService: {},
  crmServices: [],
  crmService: {},
  gamificationServices: [],
  gamificationService: {},
  retailSolutionServices: [],
  retailSolutionService: {},
  agSolutionServicePaymentLimits: [],
  kycServices: [],
  kycService: {},
  authenticationServices: [],
  authenticationService: {},
  firebasePreferences: {},
};

const thirdPartyServicesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ThirdPartyServicesActionType.SAVE_CUSTOM_SCRIPTS:
    return {
      ...state,
      customScripts: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_CUSTOM_SCRIPTS:
    return {
      ...state,
      customScripts: [],
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_AFFILIATE_SERVICES:
    return {
      ...state,
      affiliateServices: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_AFFILIATE_SERVICES:
    return {
      ...state,
      affiliateServices: [],
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_ANALYTIC_SERVICES:
    return {
      ...state,
      analyticServices: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_ANALYTIC_SERVICES:
    return {
      ...state,
      analyticServices: [],
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_ANALYTIC_SERVICE:
    return {
      ...state,
      analyticService: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_ANALYTIC_SERVICE:
    return {
      ...state,
      analyticService: {},
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_LIVE_CHAT_SOLUTION_SERVICES:
    return {
      ...state,
      liveChatSolutionServices: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_LIVE_CHAT_SOLUTION_SERVICES:
    return {
      ...state,
      liveChatSolutionServices: [],
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_LIVE_CHAT_SOLUTION_SERVICE:
    return {
      ...state,
      liveChatSolutionService: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_LIVE_CHAT_SOLUTION_SERVICE:
    return {
      ...state,
      liveChatSolutionService: {},
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_AG_SOLUTION_SERVICES:
    return {
      ...state,
      agSolutionServices: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_AG_SOLUTION_SERVICES:
    return {
      ...state,
      agSolutionServices: [],
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_AG_SOLUTION_SERVICE:
    return {
      ...state,
      agSolutionService: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_AG_SOLUTION_SERVICE:
    return {
      ...state,
      agSolutionService: {},
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_CRM_SERVICES:
    return {
      ...state,
      crmServices: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_CRM_SERVICES:
    return {
      ...state,
      crmServices: [],
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_CRM_SERVICE:
    return {
      ...state,
      crmService: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_CRM_SERVICE:
    return {
      ...state,
      crmService: {},
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_GAMIFICATION_SERVICES:
    return {
      ...state,
      gamificationServices: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_GAMIFICATION_SERVICES:
    return {
      ...state,
      gamificationServices: [],
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_GAMIFICATION_SERVICE:
    return {
      ...state,
      gamificationService: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_GAMIFICATION_SERVICE:
    return {
      ...state,
      gamificationService: {},
    };

  case ThirdPartyServicesActionType.SAVE_BRAND_RETAIL_SOLUTION_SERVICES:
    return {
      ...state,
      retailSolutionServices: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_RETAIL_SOLUTION_SERVICES:
    return {
      ...state,
      retailSolutionServices: [],
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_RETAIL_SOLUTION_SERVICE:
    return {
      ...state,
      retailSolutionService: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_RETAIL_SOLUTION_SERVICE:
    return {
      ...state,
      retailSolutionService: {},
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_AFFILIATE_SERVICE:
    return {
      ...state,
      affiliateService: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_AFFILIATE_SERVICE:
    return {
      ...state,
      affiliateService: {},
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_KYC_SERVICES:
    return {
      ...state,
      kycServices: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_KYC_SERVICES:
    return {
      ...state,
      kycServices: [],
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_KYC_SERVICE:
    return {
      ...state,
      kycService: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_KYC_SERVICE:
    return {
      ...state,
      kycService: {},
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_DIGITAIN_AG_SERVICE_PAYMENT_LIMITS:
    return {
      ...state,
      agSolutionServicePaymentLimits: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_DIGITAIN_AG_SERVICE_PAYMENT_LIMITS:
    return {
      ...state,
      agSolutionServicePaymentLimits: {},
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_AUTHENTICATION_SERVICES:
    return {
      ...state,
      authenticationServices: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_AUTHENTICATION_SERVICES:
    return {
      ...state,
      authenticationServices: [],
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_AUTHENTICATION_SERVICE:
    return {
      ...state,
      authenticationService: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_AUTHENTICATION_SERVICE:
    return {
      ...state,
      authenticationService: {},
    };
  case ThirdPartyServicesActionType.SAVE_BRAND_FIREBASE_SERVICE_PREFERENCES:
    return {
      ...state,
      firebasePreferences: payload.data,
    };
  case ThirdPartyServicesActionType.RESET_BRAND_FIREBASE_SERVICE_PREFERENCES:
    return {
      ...state,
      firebasePreferences: {},
    };
  default:
    return state;
  }
};

export default thirdPartyServicesReducer;
