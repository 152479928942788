import { createSelector } from 'reselect';

const selectCampaigns = (state) => state.campaigns;
export const selectCampaignsV2Data = (state) => selectCampaigns(state).data;
export const selectCampaignsV2Total = (state) => selectCampaigns(state).total;
export const selectCampaignV2 = (state) => state.campaign;
export const selectCreatedCampaignV2Id = (state) => state.createdCampaignId;
export const selectCampaignV2GeneralConfigEndedStatus = (state) => state.generalConfigEndedStatus;
export const selectCampaignV2Trigger = (state) => selectCampaignV2(state).trigger;

export const selectCampaignV2BrandId = (state) => selectCampaignV2(state).brandId;
export const selectCampaignV2ParticipationGroups = (state) => state.participationGroups;
export const selectCampaignV2ParticipationGroup = (state) => state.participationGroup;
export const selectCampaignV2ParticipationGroupEndedStatus = (state) => state.participationGroupEndedStatus;

export const selectCampaignV2BonusEndedStatus = (state) => state.bonusEndedStatus;
export const selectCampaignV2FreeBetBonus = (state) => state.freeBetBonus;
export const selectCampaignV2FreeSpinBonus = (state) => state.freeSpinBonus;
export const selectCampaignV2CombinedBonus = (state) => state.combinedBonus;
export const selectCampaignV2Bonuses = (state) => state.bonuses;
export const selectCampaignV2SpecialBonus = (state) => state.specialBonus;

export const selectCampaignV2TriggerConditions = (state) => state.triggerConditions;
export const selectCampaignV2TriggerCondition = (state) => state.triggerCondition;
export const selectCampaignV2TriggerConditionEndedStatus = (state) => state.triggerConditionEndedStatus;
export const selectCampaignV2PublishEndedStatus = (state) => state.publishEndedStatus;

export const selectCampaignV2BonusContents = (state) => state.bonusContents;
export const selectCampaignV2BonusContent = (state) => state.bonusContent;
export const selectCampaignV2BonusContentEndedStatus = (state) => state.bonusContentEndedStatus;

export const selectCampaignV2CampaignContents = (state) => state.campaignContents;
export const selectCampaignV2CampaignContent = (state) => state.campaignContent;
export const selectCampaignV2CampaignContentEndedStatus = (state) => state.campaignContentEndedStatus;

export const selectNonContentBonuses = (state) => createSelector(
  () => selectCampaignV2BonusContents(state),
  () => selectCampaignV2BonusContent(state),
  () => selectCampaignV2Bonuses(state),
  (bonusContents, bonusContent, bonuses) => {
    const contentIds = bonusContents.map((content) => content.bonusId);

    return bonuses.filter((bonus) => bonus.id === bonusContent.bonusId || !contentIds.includes(bonus.id)).map((bonus) => ({ id: bonus.id, name: bonus.name }));
  },
)();

export const selectCampaignV2Units = (state) => state.units;
export const selectCampaignV2Unit = (state) => state.unit;
export const selectCampaignV2UnitEndedStatus = (state) => state.unitEndedStatus;
