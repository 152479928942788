import qs from 'qs';
import mime from 'mimetypes';
import { getError, getMessage } from 'utils/helpers';
import { downloadFile } from 'utils/utils';
import {
  takeLatest, all, put, call,
} from 'redux-saga/effects';
import {
  changePlayerNetBalanceLoading,
  changePlayerWalletsInfoUpdatedStatus,
  saveBalanceAdjustments,
  savePlayerNetBalance,
  savePlayerWalletHistory,
  savePlayerWalletsInfo,
  saveBalanceAdjustmentTransactions,
  saveAdjustmentCsvPlayers,
} from 'actions/playerWallet/playerWalletActionCreator';
import { AppConstants } from 'constants/index';
import NetworkService from 'services/networkService';
import { i18n, MessageService } from 'services';
import PlayerWalletActionType from 'actions/playerWallet/playerWalletActionType';
import {
  changeEndedStatus, changeGenerateReportEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import {
  changeEndedStatus as changePlayerEndedStatus, changePlayerDataUpdatedStatus, changePlayerLoadingState, changePlayerReportLoadingState,
} from 'actions/player/playerActionCreator';
import Config from 'config';

const { isBrRegulationAudit, brRegulationSpecialActionsBaseURL } = Config;

function* getPlayerWalletHistory({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Wallet, WalletFlowHistory } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Wallet, WalletFlowHistory], options);
    yield put(savePlayerWalletHistory(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPlayerWalletHistory({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Wallet, WalletFlowHistory, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Wallet, WalletFlowHistory, Export], options);
    downloadFile(data, i18n.t('walletHistory'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerWalletsInfo({ payload }) {
  const { isLoading, id } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }

    const { ReportingAdmin, Player, Wallets } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, id, Wallets]);

    if (isLoading) {
      yield put(changeLoadingState(false));
    }

    yield put(savePlayerWalletsInfo(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getPlayerNetBalance({ payload }) {
  const { id } = payload;
  try {
    yield put(changePlayerNetBalanceLoading(true));

    const { ReportingAdmin, Wallet, TotalNetBalance } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Wallet, id, TotalNetBalance]);

    yield put(changePlayerNetBalanceLoading(false));
    yield put(savePlayerNetBalance(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerNetBalanceLoading(false));
  }
}

function* getPlayerBalanceAdjustments({ payload }) {
  try {
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const { Transaction, Wallet, BalanceAdjustmentReport } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Wallet, BalanceAdjustmentReport], options);
    yield put(saveBalanceAdjustments(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBalanceAdjustmentTransactions({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Wallet, BalanceAdjustmentTransactions } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Wallet, BalanceAdjustmentTransactions], options);
    yield put(saveBalanceAdjustmentTransactions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBalanceAdjustments({ payload }) {
  try {
    const options = {
      params: payload.data,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changePlayerReportLoadingState(true));
    const { ReportingAdmin, Wallet, BalanceAdjustmentsForBrand } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Wallet, BalanceAdjustmentsForBrand], options);
    yield put(saveBalanceAdjustments(data));
    yield put(changePlayerReportLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changePlayerReportLoadingState(false));
  }
}

function* exportBalanceAdjustments({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Wallet, BalanceAdjustmentReport, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Wallet, BalanceAdjustmentReport, Export], options);
    downloadFile(data, i18n.t('balanceAdjustments'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createPlayerBalanceAdjustment({ payload }) {
  try {
    const options = {
      data: payload.data,
      ...{ baseURL: isBrRegulationAudit && brRegulationSpecialActionsBaseURL },
    };
    yield put(changeLoadingState(true));
    const { Transaction, Wallet, BalanceAdjustmentByPlayerId } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Wallet, BalanceAdjustmentByPlayerId], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(changePlayerDataUpdatedStatus(true));
    yield put(changePlayerWalletsInfoUpdatedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('player:playerBalance'), i18n.t('notification:slAdjusted'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createBulkBalanceAdjustment({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { Transaction, Wallet, BulkAdjustBalance } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Wallet, BulkAdjustBalance], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('player:playersBalance'), i18n.t('notification:plAdjusted'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generatePlayersRealWalletsSumReport({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Player, PlayerTotals, Generate,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, PlayerTotals, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getAdjustmentCsvPlayers({ payload }) {
  try {
    yield put(changePlayerLoadingState(true));
    const { Transaction, Wallet, ParseBalanceAdjustmentCsv } = AppConstants.api;
    const options = {
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    const { data } = yield call(NetworkService.makeAPIPostRequest, [Transaction, Wallet, ParseBalanceAdjustmentCsv], options);
    yield put(saveAdjustmentCsvPlayers(data));
    yield put(changePlayerEndedStatus(true));
    yield put(changePlayerLoadingState(false));
    if (!data.length) {
      MessageService.error(i18n.t('notification:emptyFile'));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
      yield put(changePlayerEndedStatus(true));
    }
    yield put(changePlayerLoadingState(false));
  }
}

export default function* playerWalletSaga() {
  yield all([
    takeLatest(PlayerWalletActionType.GET_PLAYER_WALLET_HISTORY, getPlayerWalletHistory),
    takeLatest(PlayerWalletActionType.EXPORT_PLAYER_WALLET_HISTORY, exportPlayerWalletHistory),
    takeLatest(PlayerWalletActionType.GET_PLAYER_WALLETS_INFO, getPlayerWalletsInfo),
    takeLatest(PlayerWalletActionType.GET_PLAYER_NET_BALANCE, getPlayerNetBalance),
    takeLatest(PlayerWalletActionType.GET_PLAYER_BALANCE_ADJUSTMENTS, getPlayerBalanceAdjustments),
    takeLatest(PlayerWalletActionType.GET_BALANCE_ADJUSTMENTS, getBalanceAdjustments),
    takeLatest(PlayerWalletActionType.CREATE_PLAYER_BALANCE_ADJUSTMENT, createPlayerBalanceAdjustment),
    takeLatest(PlayerWalletActionType.GET_ADJUSTMENT_CSV_PLAYERS, getAdjustmentCsvPlayers),
    takeLatest(PlayerWalletActionType.CREATE_BULK_BALANCE_ADJUSTMENT, createBulkBalanceAdjustment),
    takeLatest(PlayerWalletActionType.GET_BALANCE_ADJUSTMENT_TRANSACTIONS, getBalanceAdjustmentTransactions),
    takeLatest(PlayerWalletActionType.GENERATE_PLAYERS_REAL_WALLETS_SUM_REPORT, generatePlayersRealWalletsSumReport),
    takeLatest(PlayerWalletActionType.EXPORT_PLAYER_BALANCE_ADJUSTMENTS, exportBalanceAdjustments),
  ]);
}
