import PushNotificationActionType from './pushNotificationActionType';

export const createPushNotification = (data) => ({
  type: PushNotificationActionType.CREATE_PUSH_NOTIFICATION,
  payload: { data },
});

export const getPushNotificationRecipientsStatistics = (data) => ({
  type: PushNotificationActionType.GET_PUSH_NOTIFICATION_RECIPIENTS_STATISTICS,
  payload: { data },
});
export const savePushNotificationRecipientsStatistics = (data) => ({
  type: PushNotificationActionType.SAVE_PUSH_NOTIFICATION_RECIPIENTS_STATISTICS,
  payload: { data },
});

export const resetPushNotificationRecipientsStatistics = () => ({
  type: PushNotificationActionType.RESET_PUSH_NOTIFICATION_RECIPIENTS_STATISTICS,
});

export const getSentNotifications = (params) => ({
  type: PushNotificationActionType.GET_SENT_PUSH_NOTIFICATIONS,
  payload: { params },
});

export const saveSentNotifications = (data) => ({
  type: PushNotificationActionType.SAVE_SENT_PUSH_NOTIFICATIONS,
  payload: { data },
});

export const resetSentNotifications = () => ({
  type: PushNotificationActionType.RESET_SENT_PUSH_NOTIFICATIONS,
});

export const getPushNotification = (id) => ({
  type: PushNotificationActionType.GET_PUSH_NOTIFICATION,
  payload: { id },
});

export const savePushNotification = (data) => ({
  type: PushNotificationActionType.SAVE_PUSH_NOTIFICATION,
  payload: { data },
});

export const resetPushNotification = () => ({
  type: PushNotificationActionType.RESET_PUSH_NOTIFICATION,
});

export const checkPushNotificationNameExists = (params) => ({
  type: PushNotificationActionType.CHECK_PUSH_NOTIFICATION_NAME_EXISTS,
  payload: { params },
});

export const savePushNotificationTitleExist = (data) => ({
  type: PushNotificationActionType.SAVE_PUSH_NOTIFICATION_NAME_EXISTS,
  payload: { data },
});

export const resetPushNotificationNameExists = () => ({
  type: PushNotificationActionType.RESET_PUSH_NOTIFICATION_NAME_EXISTS,
});

export const deletePushNotification = (id, name) => ({
  type: PushNotificationActionType.DELETE_PUSH_NOTIFICATION,
  payload: { id, name },
});

export const getScheduledNotifications = (params) => ({
  type: PushNotificationActionType.GET_SCHEDULED_PUSH_NOTIFICATIONS,
  payload: { params },
});

export const saveScheduledNotifications = (data) => ({
  type: PushNotificationActionType.SAVE_SCHEDULED_PUSH_NOTIFICATIONS,
  payload: { data },
});

export const resetScheduledNotifications = () => ({
  type: PushNotificationActionType.RESET_SCHEDULED_PUSH_NOTIFICATIONS,
});

export const getPushNotificationRecipientsTotals = (id) => ({
  type: PushNotificationActionType.GET_PUSH_NOTIFICATION_RECIPIENTS_TOTALS,
  payload: { id },
});

export const savePushNotificationRecipientsTotals = (data) => ({
  type: PushNotificationActionType.SAVE_PUSH_NOTIFICATION_RECIPIENTS_TOTALS,
  payload: { data },
});

export const resetPushNotificationRecipientsTotals = () => ({
  type: PushNotificationActionType.RESET_PUSH_NOTIFICATION_RECIPIENTS_TOTALS,
});

export const getPushNotificationRecipients = (id, params) => ({
  type: PushNotificationActionType.GET_PUSH_NOTIFICATION_RECIPIENTS,
  payload: { id, params },
});

export const savePushNotificationRecipients = (data) => ({
  type: PushNotificationActionType.SAVE_PUSH_NOTIFICATION_RECIPIENTS,
  payload: { data },
});

export const generatePushNotificationRecipients = (id, data) => ({
  type: PushNotificationActionType.GENERATE_PUSH_NOTIFICATION_RECIPIENTS,
  payload: { id, data },
});

export const resetPushNotificationRecipients = () => ({
  type: PushNotificationActionType.RESET_PUSH_NOTIFICATION_RECIPIENTS,
});

export const getPlayerPushNotifications = (id, params) => ({
  type: PushNotificationActionType.GET_PLAYER_PUSH_NOTIFICATIONS,
  payload: { id, params },
});

export const savePlayerPushNotifications = (data) => ({
  type: PushNotificationActionType.SAVE_PLAYER_PUSH_NOTIFICATIONS,
  payload: { data },
});

export const resetPlayerPushNotifications = () => ({
  type: PushNotificationActionType.RESET_PLAYER_PUSH_NOTIFICATIONS,
});
