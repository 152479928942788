export const selectLobbies = (state) => state.lobbies;
export const selectLobby = (state) => state.lobby;

export const selectLobbyName = (state) => selectLobby(state)?.name;
export const selectLobbyProviders = (state) => state.lobbyProviders;
export const selectLobbyProvider = (state) => state.lobbyProvider;
export const selectLobbyProvidersOrders = (state) => state.lobbyProvidersOrders;
export const selectLobbyProviderGamesOrders = (state) => state.lobbyProvidersGamesOrders;
export const selectCsvLobbyProvidersOrders = (state) => state.csvLobbyProvidersOrders;
export const selectCsvLobbyProviderGamesOrders = (state) => state.csvLobbyProviderGamesOrders;
const selectLobbyLogs = (state) => state.lobbyLogs;
export const selectLobbyLogsData = (state) => selectLobbyLogs(state)?.data;
export const selectLobbyLogsTotal = (state) => selectLobbyLogs(state)?.total;
export const selectLobbyGameGroups = (state) => state.lobbyGameGroups;
export const selectLobbyGameGroup = (state) => state.lobbyGameGroup;
export const selectLobbyGeneralWidgets = (state) => state.lobbyGeneralWidgets;
export const selectLobbyJackpotWidgets = (state) => state.lobbyJackpotWidgets;
export const selectLobbyGeneralWidget = (state) => state.lobbyGeneralWidget;
export const selectLobbyJackpotWidget = (state) => state.lobbyJackpotWidget;
export const selectLobbyHomeWidgets = (state) => state.lobbyHomeWidgets;
export const selectLobbyHomeWidget = (state) => state.lobbyHomeWidget;
export const selectLobbyHomeWidgetName = (state) => selectLobbyHomeWidget(state)?.name;
