import CampaignV2ActionType from 'actions/campaignV2/campaignV2ActionType';

const initialState = {
  campaigns: {
    data: [],
    total: 0,
  },
  campaign: {},
  createdCampaignId: null,
  generalConfigEndedStatus: false,
  participationGroup: {},
  participationGroups: [],
  participationGroupEndedStatus: false,
  freeBetBonus: {},
  freeSpinBonus: {},
  combinedBonus: {},
  bonusEndedStatus: false,
  bonuses: [],
  triggerConditions: [],
  triggerCondition: {},
  triggerConditionEndedStatus: false,
  specialBonus: {},
  bonusContents: [],
  bonusContent: {},
  bonusContentEndedStatus: false,
  campaignContents: [],
  campaignContent: {},
  campaignContentEndedStatus: false,
  units: [],
  unit: {},
  unitEndedStatus: false,
  publishEndedStatus: false,
};

const campaignV2Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case CampaignV2ActionType.SAVE_CAMPAIGNS_V2:
    return {
      ...state,
      campaigns: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGNS_V2:
    return {
      ...state,
      campaigns: {
        data: [],
        total: 0,
      },
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2:
    return {
      ...state,
      campaign: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2:
    return {
      ...state,
      campaign: {},
    };
  case CampaignV2ActionType.SAVE_CREATED_CAMPAIGN_V2_ID:
    return {
      ...state,
      createdCampaignId: payload.id,
    };
  case CampaignV2ActionType.CHANGE_CAMPAIGN_V2_GENERAL_CONFIG_ENDED_STATUS:
    return {
      ...state,
      generalConfigEndedStatus: payload.status,
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_PARTICIPATION_GROUP:
    return {
      ...state,
      participationGroup: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_PARTICIPATION_GROUP:
    return {
      ...state,
      participationGroup: {},
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_PARTICIPATION_GROUPS:
    return {
      ...state,
      participationGroups: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_PARTICIPATION_GROUPS:
    return {
      ...state,
      participationGroups: [],
    };
  case CampaignV2ActionType.CHANGE_CAMPAIGN_V2_PARTICIPATION_GROUP_ENDED_STATUS:
    return {
      ...state,
      participationGroupEndedStatus: payload.status,
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_FREE_BET_BONUS:
    return {
      ...state,
      freeBetBonus: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_FREE_BET_BONUS:
    return {
      ...state,
      freeBetBonus: {},
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_FREE_SPIN_BONUS:
    return {
      ...state,
      freeSpinBonus: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_FREE_SPIN_BONUS:
    return {
      ...state,
      freeSpinBonus: {},
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_COMBINED_BONUS:
    return {
      ...state,
      combinedBonus: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_COMBINED_BONUS:
    return {
      ...state,
      combinedBonus: {},
    };
  case CampaignV2ActionType.CHANGE_CAMPAIGN_V2_BONUS_ENDED_STATUS:
    return {
      ...state,
      bonusEndedStatus: payload.status,
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_BONUSES:
    return {
      ...state,
      bonuses: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_BONUSES:
    return {
      ...state,
      bonuses: [],
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_TRIGGER_CONDITIONS:
    return {
      ...state,
      triggerConditions: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_TRIGGER_CONDITIONS:
    return {
      ...state,
      triggerConditions: [],
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_TRIGGER_CONDITION:
    return {
      ...state,
      triggerCondition: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_TRIGGER_CONDITION:
    return {
      ...state,
      triggerCondition: {},
    };
  case CampaignV2ActionType.CHANGE_CAMPAIGN_V2_TRIGGER_CONDITION_ENDED_STATUS: {
    return {
      ...state,
      triggerConditionEndedStatus: payload.status,
    };
  }
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_SPECIAL_BONUS:
    return {
      ...state,
      specialBonus: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_SPECIAL_BONUS:
    return {
      ...state,
      specialBonus: {},
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_BONUS_CONTENTS:
    return {
      ...state,
      bonusContents: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_BONUS_CONTENTS:
    return {
      ...state,
      bonusContents: [],
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_BONUS_CONTENT:
    return {
      ...state,
      bonusContent: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_BONUS_CONTENT:
    return {
      ...state,
      bonusContent: {},
    };
  case CampaignV2ActionType.CHANGE_CAMPAIGN_V2_BONUS_CONTENT_ENDED_STATUS: {
    return {
      ...state,
      bonusContentEndedStatus: payload.status,
    };
  }
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_CAMPAIGN_CONTENTS:
    return {
      ...state,
      campaignContents: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_CAMPAIGN_CONTENTS:
    return {
      ...state,
      campaignContents: [],
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_CAMPAIGN_CONTENT:
    return {
      ...state,
      campaignContent: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_CAMPAIGN_CONTENT:
    return {
      ...state,
      campaignContent: {},
    };
  case CampaignV2ActionType.CHANGE_CAMPAIGN_V2_CAMPAIGN_CONTENT_ENDED_STATUS: {
    return {
      ...state,
      campaignContentEndedStatus: payload.status,
    };
  }
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_UNITS:
    return {
      ...state,
      units: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_UNITS:
    return {
      ...state,
      units: [],
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_UNIT:
    return {
      ...state,
      unit: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_UNIT:
    return {
      ...state,
      unit: {},
    };
  case CampaignV2ActionType.CHANGE_CAMPAIGN_V2_UNIT_ENDED_STATUS:
    return {
      ...state,
      unitEndedStatus: payload.status,
    };
  case CampaignV2ActionType.CHANGE_CAMPAIGN_V2_PUBLISH_ENDED_STATUS: {
    return {
      ...state,
      publishEndedStatus: payload.status,
    };
  }
  default:
    return state;
  }
};

export default campaignV2Reducer;
