import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import qs from 'qs';
import mime from 'mimetypes';
import {
  setErrorMessage, changeLoadingState, changeEndedStatus, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import { AppConstants } from 'constants/index';
import {
  saveGameProvidersResource,
  changeGameLoadingState,
  saveSegmentGamesResource,
  saveBrandGameProviders,
  saveGamesResource,
  saveBrandProviderGames,
  saveGameProviders,
  saveProviderGames,
  saveFreeGameProviders,
  saveFreeGames,
  saveFreeGameAmountRestrictions,
  changeFreeGameLoadingState,
  changeFreeGameAmountLoadingState,
  changeProvidersResourceLoadingState,
  saveGameProvider,
  saveBrandJackpotFeeds,
  saveBrandJackpotFeedGames,
  saveFreeGameProvidersV2,
} from 'actions/game/gameActionCreator';
import { i18n, NetworkService } from 'services';
import GameActionType from 'actions/game/gameActionType';
import { getError, getMessage } from 'utils/helpers';
import { downloadFile } from 'utils/utils';
import { changeEndedStatus as changePlayerEndedStatus } from 'actions/player/playerActionCreator';

// get brand providers report
function* getBrandGameProviders({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Provider, Settings } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Provider, Settings, brandId], options);
    yield put(saveBrandGameProviders(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBrandGameProviders({ payload }) {
  try {
    const {
      params,
      brand: { brandId, name },
    } = payload;
    const options = {
      params,
      responseType: 'blob',
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };

    yield put(changeLoadingState(true));
    const {
      Transaction, Provider, Settings, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Provider, Settings, Export, brandId], options);
    downloadFile(data, `${name} ${i18n.t('providerList')}`, mime.detectExtension(headers['content-type']));
    yield put(changePlayerEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

// providers resource call
function* getGameProvidersResource({ payload }) {
  const { isLoading, key } = payload;
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      Transaction, Game, Providers, Resource,
    } = AppConstants.api;
    if (isLoading) {
      yield put(changeProvidersResourceLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Game, Providers, Resource], options);
    yield put(saveGameProvidersResource(data, key));
    if (isLoading) {
      yield put(changeProvidersResourceLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeProvidersResourceLoadingState(false));
    }
  }
}

// games resource call
function* getGamesResource({ payload }) {
  const { isLoading } = payload;
  try {
    const { key } = payload;
    const options = {
      data: payload.data,
    };
    const {
      Transaction, Provider, Games, Resource,
    } = AppConstants.api;
    if (isLoading) {
      yield put(changeGameLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIPostRequest, [Transaction, Provider, Games, Resource], options);
    yield put(saveGamesResource(data, key));
    if (isLoading) {
      yield put(changeGameLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeGameLoadingState(false));
    }
  }
}

// segment games resource call
function* getSegmentGamesResource({ payload }) {
  const { isLoading } = payload;
  try {
    const { brandId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Game, BySegment } = AppConstants.api;
    if (isLoading) {
      yield put(changeGameLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Game, BySegment, brandId], options);
    yield put(saveSegmentGamesResource(data));
    if (isLoading) {
      yield put(changeGameLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeGameLoadingState(false));
    }
  }
}

function* getBrandProviderGames({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Provider, Games } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Provider, Games, brandId], options);
    yield put(saveBrandProviderGames(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBrandProviderGames({ payload }) {
  try {
    const {
      params,
      data: { brandId, brandName, providerName },
    } = payload;
    const options = {
      params,
      responseType: 'blob',
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };

    yield put(changeLoadingState(true));
    const {
      Transaction, Provider, Games, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Provider, Games, Export, brandId], options);
    downloadFile(data, `${brandName} ${providerName} ${i18n.t('gameList')}`, mime.detectExtension(headers['content-type']));
    yield put(changePlayerEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

// all providers report
function* getGameProviders({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Provider } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Provider], options);
    yield put(saveGameProviders(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

// get games by provider id
function* getProviderGames({ payload }) {
  try {
    const { params, providerId } = payload;
    const option = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Provider, Games } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Provider, providerId, Games], option);
    yield put(saveProviderGames(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getFreeGameProviders({ payload }) {
  try {
    const { brandId, params } = payload;
    const option = {
      params,
    };
    const { BonusAdmin, FreeSpin, Providers } = AppConstants.api;
    yield put(changeGameLoadingState(true));
    const { data: response } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, FreeSpin, brandId, Providers], option);
    yield put(saveFreeGameProviders(response.providers));
    yield put(changeGameLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeGameLoadingState(false));
  }
}

function* getFreeGameProvidersV2({ payload }) {
  try {
    const { brandId, params } = payload;
    const option = {
      params,
    };
    const {
      BonusAdmin, FreeSpin, V2, Providers,
    } = AppConstants.api;
    yield put(changeGameLoadingState(true));
    const { data: response } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, FreeSpin, V2, brandId, Providers], option);
    yield put(saveFreeGameProvidersV2(response.providers));
    yield put(changeGameLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeGameLoadingState(false));
  }
}

function* getFreeGames({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params,
    };
    const { BonusAdmin, FreeSpin, Games } = AppConstants.api;
    yield put(changeFreeGameLoadingState(true));
    const { data: response } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, FreeSpin, brandId, Games], options);
    yield put(saveFreeGames(response.games));
    yield put(changeFreeGameLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeFreeGameLoadingState(false));
  }
}

function* getFreeGameAmountRestrictions({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, FreeSpin, Provider, Amount, Restrictions,
    } = AppConstants.api;
    yield put(changeFreeGameAmountLoadingState(true));
    const { data: response } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, FreeSpin, brandId, Provider, Amount, Restrictions], options);
    yield put(saveFreeGameAmountRestrictions(response));
    yield put(changeFreeGameAmountLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeFreeGameAmountLoadingState(false));
  }
}

function* getGameProvider({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Provider } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Provider, brandId], options);
    yield put(saveGameProvider(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandJackpotFeeds({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { Transaction, Game, JackpotFeeds } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Game, JackpotFeeds], options);
    yield put(saveBrandJackpotFeeds(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeBrandJackpotFeedStatus({ payload }) {
  try {
    const { id, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Game, JackpotFeeds, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Game, JackpotFeeds, id, Status], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage('', i18n.t(!data.enabled ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandJackpotFeedGames({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
    };
    const {
      Transaction, Game, JackpotFeeds, Games,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Game, JackpotFeeds, id, Games], options);
    yield put(saveBrandJackpotFeedGames(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBrandJackpotFeedGames({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Game, JackpotFeeds, Games, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Game, JackpotFeeds, id, Games, Export], options);
    downloadFile(data, i18n.t('brand:jackpotGames'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* gameSaga() {
  yield all([
    takeLatest(GameActionType.GET_GAME_PROVIDERS_RESOURCE, getGameProvidersResource),
    takeLatest(GameActionType.GET_SEGMENT_GAMES_RESOURCE, getSegmentGamesResource),
    takeLatest(GameActionType.GET_GAMES_RESOURCE, getGamesResource),
    takeLatest(GameActionType.GET_BRAND_GAME_PROVIDERS, getBrandGameProviders),
    takeLatest(GameActionType.EXPORT_BRAND_GAME_PROVIDERS, exportBrandGameProviders),
    takeLatest(GameActionType.GET_BRAND_PROVIDER_GAMES, getBrandProviderGames),
    takeLatest(GameActionType.EXPORT_BRAND_PROVIDER_GAMES, exportBrandProviderGames),
    takeLatest(GameActionType.GET_GAME_PROVIDERS, getGameProviders),
    takeLatest(GameActionType.GET_PROVIDER_GAMES, getProviderGames),

    takeLatest(GameActionType.GET_FREE_GAME_PROVIDERS, getFreeGameProviders),
    takeLatest(GameActionType.GET_FREE_GAME_PROVIDERS_V2, getFreeGameProvidersV2),
    takeLatest(GameActionType.GET_FREE_GAMES, getFreeGames),
    takeLatest(GameActionType.GET_FREE_GAME_AMOUNT_RESTRICTIONS, getFreeGameAmountRestrictions),
    takeLatest(GameActionType.GET_GAME_PROVIDER, getGameProvider),
    takeLatest(GameActionType.GET_BRAND_JACKPOT_FEEDS, getBrandJackpotFeeds),
    takeLatest(GameActionType.CHANGE_BRAND_JACKPOT_FEED_STATUS, changeBrandJackpotFeedStatus),
    takeLatest(GameActionType.GET_BRAND_JACKPOT_FEED_GAMES, getBrandJackpotFeedGames),
    takeLatest(GameActionType.EXPORT_BRAND_JACKPOT_FEED_GAMES, exportBrandJackpotFeedGames),
  ]);
}
