import { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    if (error.name === 'ChunkLoadError') {
      // window.location.reload(false);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
