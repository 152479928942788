import { Popover } from 'antd';
import { IconSvg } from 'components/common';
import { AdminSelect } from 'components/common/form';
import { useEffect, useMemo, useState } from 'react';
import {
  StyledHeaderSelectClose, StyledHeaderSelectToggle, StyledHeaderSelectWrapper, StyledListItemViewMode,
} from 'components/layout/Layout.styled';
import { withTheme } from 'styled-components';
import { LocalStorageService } from 'services';
import PropTypes from 'prop-types';

const Brands = (props) => {
  const {
    user,
    showBrand,
    brand,
    partnerBrands,
    theme: { colors },
    brandId,
    setCurrentBrand,
    getUserBrandsByPartner,
    getResourceBrand,
  } = props;

  const [open, setOpen] = useState(false);

  const isVisible = useMemo(() => user && showBrand, [showBrand, user]);

  useEffect(() => {
    if (user && partnerBrands.length) {
      const currentData = LocalStorageService.get('current') || {};
      const id = currentData?.brandId || partnerBrands[0].id;
      setCurrentBrand(id);

      LocalStorageService.set('current', {
        ...currentData,
        brandId: id,
      });
    }
  }, [user, setCurrentBrand, partnerBrands]);

  useEffect(() => {
    if (isVisible) {
      getUserBrandsByPartner();
    }
  }, [getUserBrandsByPartner, isVisible]);

  useEffect(() => {
    if (showBrand?.viewMode && brandId && isVisible) {
      getResourceBrand(brandId);
    }
  }, [showBrand, brandId, isVisible, getResourceBrand]);

  if (!isVisible) {
    return;
  }

  if (showBrand.viewMode) {
    const { name } = brand;
    return (
      <Popover placement="bottomLeft" content={name} title="" overlayClassName="description-popover">
        <StyledListItemViewMode isBold>{name}</StyledListItemViewMode>
      </Popover>
    );
  }

  const openDropDown = () => {
    setOpen(true);
  };

  const closeDropDown = () => {
    setOpen(false);
  };

  const brandChange = (id) => {
    const currentData = LocalStorageService.get('current') || {};

    LocalStorageService.set('current', {
      ...currentData,
      brandId: id,
    });

    setCurrentBrand(id);
    closeDropDown();
  };

  return (
    !!partnerBrands.length && (
      <div>
        <StyledHeaderSelectToggle onClick={openDropDown}>
          <IconSvg icon="PartnersIcon" width="1.4rem" height="1.4rem" color={colors.white} />
        </StyledHeaderSelectToggle>
        <StyledHeaderSelectWrapper isOpen={open ? 1 : 0}>
          <AdminSelect onChange={brandChange} value={brandId} allowClear={false} data={partnerBrands} isToString={false} virtual={false} />
          <StyledHeaderSelectClose onClick={closeDropDown}>
            <IconSvg icon="CloseModalIcon" width="1.14rem" height="1.14rem" color={colors.white} />
          </StyledHeaderSelectClose>
        </StyledHeaderSelectWrapper>
      </div>
    )
  );
};

Brands.propTypes = {
  theme: PropTypes.object.isRequired,
  setCurrentBrand: PropTypes.func.isRequired,
  brandId: PropTypes.number,
  showBrand: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  user: PropTypes.object,
  partnerBrands: PropTypes.array,
  brand: PropTypes.object,
  getUserBrandsByPartner: PropTypes.func.isRequired,
  getResourceBrand: PropTypes.func.isRequired,
};

Brands.defaultProps = {
  brandId: null,
  showBrand: false,
  user: null,
  partnerBrands: [],
  brand: {},
};

export default withTheme(Brands);
