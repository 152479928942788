import styled from 'styled-components';
import { StyledModal } from 'components/styledComponents/modal/Modal.styled';
import {
  media, handleColorType, truncate, getBoxShadow,
} from 'utils/styledHelpers';
import { StyledCardImage } from 'components/styledComponents/card/Card.styled';

const StyledBrandMainSettingsPadding = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
`;
const BrandFullHeightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const StyledBorderTop = styled.div`
  width: 100%;
`;

const StyledMainSettings = styled.div`
  display: flex;
  flex: 1;
  max-width: 100%;
  margin-bottom: 2rem;

  ${media.sm`
    flex-direction: column;
    align-items: center;
    max-height: unset;
  `}
`;

const StyledCountryLang = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledTopMainSettings = styled.div`
  position: relative;
  padding: 1.71rem;
  margin-left: 1.14rem;
  background-color: ${(props) => props.theme.colors.secondaryBg};
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  box-shadow: ${(props) => getBoxShadow(props.theme.colors.secondaryShadow)};
  flex: 1;
  @media (max-width: 1366px) {
    max-height: 100%;
    padding: 1.14rem 1.78rem;
  }

  ${media.sm`
    max-width: 100%;
    height: auto;
    margin: 1.14rem 0 0;
    padding: 0.71rem 1.14rem 1.14rem;
  `}
`;

const StyledBrandsForm = styled.div`
  height: 100%;
  form {
    height: 100%;
    > div {
      > div:last-child {
        > .ant-col {
          > div {
            padding-bottom: 2.85rem;
          }
        }
      }
    }
  }
`;

const StyledBrandsName = styled.h3`
  color: ${(props) => props.theme.colors.white};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-size: ${(props) => (props.isSmallText ? '1.29rem' : '1.71rem')};
  z-index: 1;
`;

const StyledBrandTemplateThumbnail = styled.div`
  width: 15.4rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  overflow: hidden;
  position: relative;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: ${(props) => props.theme.colors.black}40;
    z-index: 11;
    opacity: 0;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
    div {
      opacity: 1;
      > div {
        background-size: 100% 100%, auto;
      }
    }
    img {
      transform: scale(1.1);
    }
  }
  img {
    transition: 0.4s ease-in-out;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 1200px) {
    width: 12.4rem;
  }
  ${media.xs`
    width: unset;
  `}
`;

const StyledImgHoverIcons = styled.div`
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  z-index: 22;
`;

const StyledHoverIcons = styled.div`
  background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(transparent, transparent);
  background-size: 100% 0, auto;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.4s ease-out;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  width: 2.57rem;
  height: 2.57rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-child {
    margin-right: 1.14rem;
  }

  svg {
    g path {
      fill: ${(props) => props.theme.colors.blue};
    }
  }
`;

const StyledParagraph = styled.div`
  font-size: 1rem;
  display: flex;
  color: ${(props) => props.theme.colors.primaryText};
  margin-left: 0.7rem;
  strong {
    ${truncate()};
    margin-left: 0.29rem;
    max-width: 15.1rem;
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
  span:first-child {
    ${truncate()};
    max-width: 15.1rem;
  }

  > div {
    @media (max-width: 1366px) {
      max-width: 5rem;
    }

    ${media.sm`
      max-width: unset;
    `}
  }
`;

const StyledIconWithTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledIconWithText = styled.div`
  display: flex;
  align-items: center;
  margin: 0.3rem 0;
  @media (max-width: 1366px) {
    margin: 0.3rem 0;
    width: 30%;
  }
  ${media.lg`
    width: 50%;
  `}
  &:first-child {
    padding-right: 1rem;
  }

  &&:last-child {
    padding-right: 0;
  }
  &:not(:first-child) {
    padding: 0 1.14rem;
    border-left: 1px solid ${(props) => props.theme.colors.primaryBorder};
  }
  @media (max-width: 1366px) {
    &:not(:first-child) {
      padding: 0;
      border-left: none;
    }
  }

  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  ${media.sm`
    width: 100%;
  `}
`;

const StyledLanguage = styled.span`
  text-transform: uppercase;
`;

const StyledBrandItemStatus = styled.span`
  position: absolute;
  right: -2px;
  bottom: -2px;
  width: 1.14rem;
  height: 1.14rem;
  background: ${({ status }) => handleColorType(status)};
  border: 2px solid ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusCircle};
`;
const StyledBrandStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.7rem;

  > div {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    color: ${(props) => props.theme.colors.primaryText};
  }
`;

const StyledCreateBrand = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.42rem;
  justify-content: space-between;
  ${media.sm`
    padding: 1.71rem;
  `}
  .ant-input-affix-wrapper {
    height: 100%;
  }
  .ant-row {
    margin: 0;
  }
  .ant-form-item {
    padding-bottom: 2rem;
  }
  button {
    width: 8.5rem;
  }
  .ant-form-item-control-input-content {
    height: 2.57rem;
  }
  .ant-select-dropdown.select-scrollable {
    padding-right: 0;
  }
`;

const StyledTemplateWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 5.85rem;
  margin: 0 auto;
  &:nth-child(3) {
    margin-right: 0;
  }
  & > div {
    justify-content: center;
  }
  ${media.sm`
    padding: 0;
  `}
`;

const StyledAbsolute = styled.div`
  transition: opacity 0.3s;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${(props) => props.theme.colors.black}30;
  width: 19rem;
  height: 16.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  & button:first-child {
    margin: 0 0 0.57rem;
    background: ${(props) => props.theme.colors.white};
    line-height: 2.2rem;
  }
`;

const StyledTemplate = styled.div`
  position: relative;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  padding: 1.14rem;
  border: ${(props) => (props.isSelected ? `1px solid ${props.theme.colors.blue}` : `1px solid ${props.theme.colors.secondaryBorder}`)};
  box-shadow: ${(props) => props.isSelected && getBoxShadow(props.theme.colors.secondaryShadow)};
  width: 19rem;
  height: 16.6rem;
  cursor: pointer;
  .ant-btn {
    width: 8.57rem;
    height: 2.43rem;
    line-height: 34px;
    padding: 0;
  }
  p {
    margin-top: 0.95rem;
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    color: ${(props) => (props.isSelected ? props.theme.colors.blue : props.theme.colors.primaryText)};
  }
  &:hover {
    box-shadow: none;
    ${StyledAbsolute} {
      opacity: 1;
    }
  }
`;

const StyledImg = styled.img`
  max-width: 100%;
  height: 11.8rem;
  width: 16.7rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusDefault};
`;

const StyledBrandsModalLeft = styled.div`
  width: 14.3rem;
  background-color: ${(props) => props.theme.colors.blue};
  padding: 3.3rem 3rem;
  border-bottom-left-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};

  ${media.sm`
    display: none;
  `}
`;

const StyledBrandsModalRight = styled.div`
  width: calc(100% - 14.3rem);
  padding: 3.43rem 2.29rem 3.43rem 2.86rem;

  ${media.sm`
    width: 100%;
    padding: 1.44rem;
  `}
`;

const StyledFlexModalTitle = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.white};
  padding-bottom: 1.86rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBg}20;
  align-items: baseline;
  p {
    color: ${(props) => props.theme.colors.white};
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    line-height: 22px;
    margin-left: 0.57rem;
  }
`;

const StyledModalDescription = styled.h2`
  color: ${(props) => props.theme.colors.white};
  padding-top: 14px;
  font-size: 30px;
`;

const StyledSearch = styled.div`
  .ant-input-group-wrapper.ant-input-search.ant-input-search-with-button .ant-input-suffix {
    margin-right: 3rem;
  }
  border-radius: ${(props) => (props.leftRadius ? `0 ${props.theme.customBorderRadius.borderRadiusSmall} 0 0` : `${props.theme.customBorderRadius.borderRadiusSmall} 0 0 0`)};

  .ant-input-clear-icon {
    font-size: 1.14rem;
    svg path {
      fill: ${(props) => props.theme.colors.secondaryColor};
    }
  }
  > span {
    padding: 0.5rem 0.7rem;
  }
  input {
    text-indent: 10px;
  }

  .ant-input-group-wrapper {
    height: 2.8rem;
    padding: 0;
    .ant-input-suffix {
      margin-right: 2.6rem;
    }
    .ant-input-affix-wrapper {
      padding: 0 !important;
    }
    .ant-input-group-addon button {
      height: 2.8rem;
    }
    .ant-input-wrapper.ant-input-group {
      position: relative;
      display: flex;
      .ant-input-search.ant-input-search-enter-button {
        padding: 0;
        &.ant-input-affix-wrapper {
          height: 2.8rem !important;
          border: 1px solid #cccccc;
        }
      }
      .ant-input-group-addon {
        display: block;
        background-color: transparent;
        position: absolute;
        top: 0.14rem;
        right: 2rem;
        z-index: 9;
        button {
          background-color: transparent;
          padding-right: 0.8rem;
          border: none;
        }
      }
    }
  }
`;

const StyledDomainModal = styled.div`
  padding: 2.28rem 2.28rem 0.57rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  max-width: 32.8rem;
  &:first-child h3 {
    margin-bottom: 1.14rem;
  }
  strong {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
  h3 {
    margin-bottom: 4px;
    margin-top: 4px;
    font-size: 1rem;
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }

  ol {
    margin-bottom: 0.85rem;
    li {
      display: flex;
      align-items: baseline;
      margin-bottom: 1.14rem;
      line-height: 1.14rem;
      font-size: ${(props) => props.theme.typography.fontSizeSmall};
      color: ${(props) => props.theme.colors.primaryText};
      &:last-child {
        margin-bottom: 0;
      }
      p {
        position: relative;
        padding-left: 1.43rem;
        &:before {
          position: absolute;
          top: 2px;
          left: 0;
          content: '';
          width: 0.57rem;
          height: 0.57rem;
          background: ${(props) => props.theme.colors.blue};
          border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusCircle};
        }
      }
      & > div > span {
        display: block;
        margin-top: 0.5rem;
        font-weight: ${(props) => props.theme.typography.fontWeightMedium};
        &:last-child {
          margin-top: 0;
        }
      }
    }
  }
`;

const StyledExampleText = styled.div`
  display: flex;
  padding-left: 1.43rem;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  margin-top: 0.58rem;

  > div {
    display: flex;
    flex-direction: column;
    margin-left: 0.28rem;
  }
`;

const StyledItemWrapper = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  > div {
    display: flex;
    align-items: center;
  }
`;

const StyledProduct = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 67.33%;
  margin-bottom: 16px;
  span {
    font-size: 1rem;
    color: ${(props) => props.theme.colors.primaryText};
  }
`;

const StyledProductsSwitcherWrapper = styled.div`
  padding: 1.14rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &:first-child {
    border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  }
`;

const StyledProductsSwitcher = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 6rem;
  width: 100%;
  h2 {
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    color: ${(props) => props.theme.colors.primaryText};
  }
`;

const StyledProductDefault = styled(StyledProduct)`
  margin-bottom: 0;
  display: flex;
  align-items: center;
  span {
    line-height: 1.43;
  }

  ${media.xs`
    display: none;
  `}
`;

const StyledLabelInfo = styled.div`
  display: flex;
  position: relative;

  > div {
    margin-left: 0.8rem;
  }

  span:first-child {
    margin-right: 1rem;
  }
  svg:hover {
    cursor: pointer;
  }
`;

const StyledUserWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: 6rem auto 9rem;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  h3 {
    font-size: ${(props) => props.theme.typography.fontSizeMiddle};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    padding-bottom: 6px;
    border-bottom: 1px solid ${(props) => props.theme.colors.primaryBorder};
    width: 25rem;
    max-width: 100%;
    margin: 0 auto 2.85rem;

    ${media.xs`
      width: 100%;
      margin: 0 0 2.85rem;
    `}
  }

  strong {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }

  ${media.xs`
    margin: 3rem auto;
  `}
`;

const StyledModalDomain = styled(StyledModal)`
  .ant-modal-footer {
    position: relative;
  }
`;

const StyledModalDomainBody = styled.div`
  padding-bottom: 0;
  .ant-input-affix-wrapper {
    position: relative;
  }
  .ant-input-clear-icon {
    color: #999999;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0.8rem;
    z-index: 1;
    svg {
      width: 1.14rem;
      height: 1.14rem;
    }
  }
  .ant-form {
    .ant-form-item-control-input {
      input {
        height: 2.6rem;
      }
    }
  }
`;

const StyledModalDomainFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  > div {
    button:last-child {
      width: 7.8rem;
    }
  }
`;

const StyledTemplateWrap = styled.div`
  padding: 0.86rem;
`;
const StyledStatusChange = styled.div`
  display: flex;
  font-size: 0.86rem;
  cursor: pointer;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.blue};
  margin-left: 0.57rem;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  > span {
    display: inline-block;
    margin-right: 0.43rem;
  }
  &:hover {
    svg {
      path {
        fill: ${(props) => props.theme.colors.hover};
      }
    }
  }
`;
const StyledStatus = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => (props.isExpand ? '1.2rem 0 0.8rem' : 0)};
`;
const StyledStatusName = styled.span`
  display: flex;
  align-items: center;
  margin-left: 0.57rem;
  color: ${(props) => props.theme.colors.primaryText};
`;
const StyleBrandItem = styled.div`
  &:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.colors.primaryBorder};
  }
`;
const StyledCollapseWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.colors.secondaryBg};
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
  padding-left: 1.71rem;
  height: 2.85rem;
  margin-bottom: 1.8rem;
  box-shadow: ${(props) => getBoxShadow(props.theme.colors.secondaryShadow)};
  > div {
    align-items: center;
    display: flex;
    margin-right: 2.85rem;
  }
  ${media.sm`
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
    height: 8rem;
    
    > div {
      flex-direction: column;
      align-items: flex-start;
    }
  `}
`;
const StyledCardPartnerWrapper = styled.div`
  align-items: center;
  display: flex;
  > h2 {
    padding-right: 1.71rem;
    margin-right: 1.71rem;
    border-right: 1px solid ${(props) => props.theme.colors.primaryBorder};

    ${media.sm`
      margin: 0;
      padding: 0;
      border: none;
      margin-bottom: 1.14rem;
    `}
  }
  &:hover {
    svg {
      path {
        fill: ${(props) => props.theme.colors.hover};
      }
    }
  }
`;

const StyledCollapseIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  > div {
    padding-right: 1.71rem;
    &:last-child {
      border-right: 1px solid ${(props) => props.theme.colors.primaryBorder};
    }
  }
  ${media.sm`
    flex-direction: column;
    align-items: flex-start;
    gap: 0.57rem;
    position: absolute;
    right: 0.57rem;
    top: 2.85rem;
    
    > div {
      padding 0.2rem;
      width: 1.71rem;
      height: 1.71rem;
      
      &:last-child {
        border: none;
      }
    }
  `}
  svg {
    &:hover {
      path {
        fill: ${(props) => props.theme.colors.hover};
      }
    }
  }
`;

const StyledCardImageThumb = styled(StyledCardImage)`
  position: relative;
  width: 4.57rem;
  height: 4.57rem;
  background-color: ${(props) => props.theme.colors.tertiaryBg};

  img {
    width: 80%;
    height: 80%;
    object-fit: contain;
  }

  ${media.sm`
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1.4rem;
  `}
`;

const StyledCardBrandName = styled.h2`
  > div {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    font-size: ${(props) => props.theme.typography.fontSizeMiddle};
    color: ${(props) => props.theme.colors.blue};
    text-transform: uppercase;
    margin-bottom: 0.8rem;
    margin-left: 2px;
  }
`;

const StyledBrandCardMainConfig = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${(props) => props.isSmallSize && '2rem'};
`;

const StyledCardBrandNameMain = styled(StyledCardBrandName)`
  display: flex;
  align-items: center;
  font-size: ${(props) => (props.isSmallSize ? '1.14rem' : '1.7rem')};
  color: ${(props) => props.theme.colors.blue};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  margin-right: 1.14rem;
  @media (max-width: 1200px) {
    font-size: 1.15rem;
  }
`;

const StyledCardServiceName = styled(StyledCardBrandName)`
  ${({ hasNote }) => hasNote
    && `
    display: flex;
    gap: 0.72rem;
  `}
  > div {
    margin-bottom: 1.14rem;
    color: ${(props) => props.theme.colors.primaryText};
  }
`;

export {
  StyledStatusName,
  StyledCollapseWrapper,
  StyledCollapseIconsWrapper,
  StyledCardPartnerWrapper,
  StyledHoverIcons,
  StyleBrandItem,
  StyledImgHoverIcons,
  StyledItemWrapper,
  StyledTemplateWrap,
  StyledMainSettings,
  StyledBrandMainSettingsPadding,
  StyledTopMainSettings,
  StyledCountryLang,
  StyledBrandsName,
  StyledIconWithTextWrapper,
  StyledIconWithText,
  StyledParagraph,
  StyledBrandTemplateThumbnail,
  StyledLanguage,
  StyledBrandItemStatus,
  StyledBrandsForm,
  StyledCreateBrand,
  StyledTemplateWrapper,
  StyledTemplate,
  StyledImg,
  StyledAbsolute,
  StyledBrandsModalLeft,
  StyledBrandsModalRight,
  StyledFlexModalTitle,
  StyledModalDescription,
  StyledSearch,
  StyledDomainModal,
  StyledProduct,
  StyledProductsSwitcherWrapper,
  StyledProductsSwitcher,
  StyledProductDefault,
  StyledLabelInfo,
  StyledUserWrapper,
  StyledModalDomain,
  StyledModalDomainBody,
  StyledModalDomainFooter,
  BrandFullHeightWrapper,
  StyledBrandStatus,
  StyledStatusChange,
  StyledStatus,
  StyledBorderTop,
  StyledExampleText,
  StyledCardBrandName,
  StyledCardBrandNameMain,
  StyledCardImageThumb,
  StyledCardServiceName,
  StyledBrandCardMainConfig,
};
