export default {
  en: {
    mainCurrency: 'Main Currency',
    mainLanguage: 'Main Language',
    market: 'Market',
    setMainCountry: 'Set Main Country',
    setMainCurrency: 'Set Main Currency',
    setMainLanguage: 'Set Main Language',
    domainName: 'Domain Name',
    redirectedTo: 'Redirected To',
    nameServer1: 'Name Server 1',
    nameServer2: 'Name Server 2',
    onlyVerifiedDomainInfo: 'Only verified domains can be connected',
    disconnectDomain: 'Disconnect Domain',
    connectDomain: 'Connect Domain',
    deleteDomain: 'Delete Domain',
    setMainDomain: 'Set Main Domain',
    disconnected: 'Disconnected',
    deleteScript: 'Delete Script',
    deactivateScript: 'Deactivate Script',
    activateScript: 'Activate Script',
    supportedCurrencies: 'Supported Currencies',
    supportedCountries: 'Supported Countries',
    supportedLanguages: 'Supported Languages',
    providerDisableInfo: 'Provider may have been disabled for the whole system',
    activity: 'Activity',
    verificationAnd: 'Verification (and)',
    verificationOr: 'Verification (or)',
    andDelimiter: 'and',
    currencyFrom: 'Currency From',
    currencyTo: 'Currency To',
    reverseRate: 'Reverse Rate',
    feature: 'Feature',
    otherTransactions: 'Other Transactions',
    limitType: 'Limit Type',
    playersNotifiedDate: 'Date Notified',
    depositProcessingType: 'Deposit Processing Type',
    withdrawalProcessingType: 'Withdrawal Processing Type',
    allowedActivities: 'Allowed Activities',
    depositSupportedCurrencies: 'Deposit Supported Currencies',
    withdrawalSupportedCurrencies: 'Withdrawal Supported Currencies',
    newFee: 'New Fee, %',
    feeBefore: 'Fee Before, %',
    newLimit: 'New Limit',
    statusDisabledInfo: 'Website will get unavailable from all domains.',
    statusActivitiesDisabledInfo: 'No new bets will be accepted.',
    statusVersionNamesDisabledInfo: 'Betting will be disabled for selected versions.',
    timezoneInfo: 'Any ongoing calculations related to time zone will get changed.',
    domainInfo: {
      text1: 'Login to your domain provider',
      text2: 'Your domain provider is the company where you purchased it (e.g., GoDaddy, eNom, 1and1).',
      text3: 'Update your domain nameservers',
      text4: 'Go to your domain DNS/Nameservers settings.',
      text5: 'Replace all your current nameservers with the custom nameservers shown in the table for each domain',
      text6: 'Verify your domain connection',
      text7: 'Check that your domain settings were updated.',
      text8: 'Registrars can take 24 hours to process nameserver updates.',
    },
    domainRedirection: 'Domain Redirection',
    uVerify: 'VERIFY',
    redirect: 'Redirect',
    forwardingURL: 'Forwarding URL',
    destinationURL: 'Destination URL',
    domainRedirectInfo: "The domain that is used as either forwarding or destination URL can't be deleted without deleting the existing rule.",
    registerDomainInfo: 'You need to register all your domains in your google account to enable captcha for all of them.',
    ageRestriction: 'Age Restriction',
    failedLogonAttempts: 'Failed Logon Attempts',
    passwordPattern: 'Password Pattern',
    failedAttemptsPeriod: 'Failed Attempts Period',
    lockPeriod: 'Lock Period',
    passwordReminder: 'Password Reminder',
    inactivityPeriod: 'Inactivity Period',
    securityPeriod: 'Security Check Period',
    referralActivationPeriod: 'Referral Activation Period',
    passwordPatternNote:
      'It is recommended passwords to have minimum 8 characters including at least 1 number and 1 uppercase letter. However, you can weaken it up to having 6-20 Latin letters, numbers or special characters.',
    referralActivationPeriodNote: "Period for which you want to keep the referrer's ID information for a new registration on the website.",
    correspondingTranslations: 'Make sure all translations are correspondingly updated.',
    verificationCodeLength: 'Verification Code Length',
    dailyMobileVerification: 'Daily Mobile Verification Requests',
    emailRequestsInDaily: 'Daily Email Verification Requests',
    expirationMinutes: 'Token Expiration Period',
    expirationMinutesNote: 'Config defines the period after which the token (code, link) in the notification will become invalid.',
    emailRequestsInDailyNote: 'Config defines how many email requests for verification player can get in a day. Min value is {{value}}.',
    dailyMobileVerificationNote: 'Config defines how many SMS requests for verification player can get in a day. Min value is  {{value}}.',
    identityDocument: 'Identity Document',
    addressDocument: 'Address Document',
    additionalDocument: 'Additional Document',
    disableSportBook: 'Disable {{name}}',
    gameDisableTooltip: 'Game may have been disabled for the whole system',
    playerLimitInfo: {
      text1: 'You can set limit severity decrease period for each limit type.',
      text2:
        'Player-imposed limit changes, that make the current limits weaker, will get apply in accordance with this configuration. Exception: in case of access limit type any changes done by player and operator will work in the configured period.',
    },
    definiteSelfExclusionDecreaseLimit: 'Definite Self-Exclusion',
    indefiniteSelfExclusionDecreaseLimit: 'Indefinite Self-Exclusion',
    depositDecreaseLimit: 'Deposit Limit',
    betDecreaseLimit: 'Bet Limit',
    withdrawalDecreaseLimit: 'Withdrawal Limit',
    activateSportsbook: 'Activate Sportsbook',
    customScript: {
      text1: 'You can integrate new applications or add different',
      text2: 'functionalities to your website by adding custom scripts.',
    },
    scriptName: 'Script Name',
    insideHead: 'Inside closing </head> tag',
    insideBody: 'Inside closing </body> tag',
    headTag: 'head tag script',
    bodyTag: 'body tag script',
    service: 'service',
    integrate: 'Integrate',
    showOnLive: 'Show on Live Website',
    showOnLiveTitle: "When you turn off this switcher the 3-rd party service's icon will be hidden from the home page of your website.",
    apiSecret: 'API Secret',
    apiSecretOrPass: 'API Secret or password',
    apiKeyOrUsername: 'API Key or username',
    sendEmailVia: 'Send Email Via',
    smtpUser: 'SMTP User',
    smtpPass: 'SMTP Password',
    useSSL: 'Use SSL',
    smtpHost: 'SMTP Host',
    smtpPort: 'SMTP Port',
    emailsInfo: {
      text1: 'For some SMTP providers it is required the same email to be registered on their end.',
      text2: 'Please, make sure the requirement is met.',
    },
    emailsInfo1: 'All scheduled messages with this email will be moved to draft',
    blockCountries: 'Block Countries',
    blockCountriesMainText: 'Website will get unavalilable from selected countries',
    blockIps: 'Block IPs',
    versionControl: 'Version Control',
    policies: 'Policies',
    policy: 'Policy',
    dateToNotify: 'Date to Notify',
    featureName: 'Feature Name',
    noBrandData: 'No Brands to Show',
    createBrand: 'Create Brand',
    builder: 'Builder',
    platformState: 'Platform State',
    minMaxWithdrawalAmount: 'Min-Max System Withdrawal Amount',
    minWithdrawalAmount: 'Min. Withdrawal Amount',
    maxWithdrawalAmount: 'Max. Withdrawal Amount',
    minMaxDepositAmount: 'Min-Max System Deposit Amount',
    minDepositAmount: 'Min. Deposit Amount',
    maxDepositAmount: 'Max. Deposit Amount',
    operationFeePercent: 'Operation Fee, %',
    withdrawalFeePercent: 'Withdrawal Fee, %',
    withdrawalFee: 'Withdrawal Fee',
    withdrawalFeeForUsedBalancePercent: 'Withdrawal Fee For Used Balance, %',
    withdrawalFeeForUsedBalance: 'Withdrawal Fee For Used Balance',
    allowedConversion: 'Allowed Conversion',
    paymentMethodName: 'Payment Method Name',
    minAmount: 'Min {{title}} Amount',
    maxAmount: 'Max {{title}} Amount',
    minMaxSystemAmount: 'Min-Max System {{title}}  Amount',
    depositFeePercent: 'Deposit Fee, %',
    depositFee: 'Deposit Fee',
    allowConversion: 'Allow Conversion',
    previewLive: 'Preview Live',
    feeFromDeposit: 'Fee From Deposit',
    allowWithdrawalFromUnusedBalance: 'Allow Withdrawal From Unused Balance',
    allowWithdrawalFeeUsedBalance: 'Fee for Withdrawal will be held from the remaining balance.',
    feeCalculationForWithdrawals: 'Fee Calculation for Withdrawals',
    feeFromWithdrawals: 'Fee From Withdrawals',
    feeFromWithdrawalsUsedBalance: 'Fee From Withdrawals (Used Balance)',
    feeFromWithdrawalsBalance: 'Fee From Withdrawals ({{type}} Balance)',
    unusedBalanceInfo: "When activated, 'Unused' balance option will appear in 'Fee From Withdrawals' setting",
    paymentTransactionType: 'Payment Transaction Type',
    paymentMethodType: 'Payment Method Type',
    playerPaymentRelation: 'Player-Payment Relation',
    failedPaymentAttempts: 'Failed Payment Attempts',
    failedPaymentAttemptsPeriod: 'Failed Payment Attempts Period',
    temporaryBlockPeriod: 'Temporary Block Period',
    reverseConversion: '{{title}} Reverse Conversion',
    lCustomScript: 'custom script',
    largeTransactions: 'Large transactions',
    lBrand: 'brand',
    playerSettings: 'Player settings',
    verificationConfigurations: 'Verification configurations',
    theCurrency: 'The currency',
    mainCountry: 'Main Country',
    theLanguage: 'The language',
    theDomain: 'The domain',
    smsSettings: 'SMS settings',
    verificationSettings: 'Verification settings',
    smtpSettings: 'SMTP settings',
    captchaSettings: 'Captcha settings',
    theEmail: 'The email',
    conversions: 'Conversions',
    theConversion: 'The conversion',
    theCountry: 'The country',
    playerVerificationSettings: 'Player verification settings',
    domainRedirectionRule: 'Domain redirection rule',
    wageringActivities: 'Wagering activities',
    theSportsBook: 'The Sportsbook',
    sportsBook: 'Sportsbook',
    theSportsBookView: 'The SportsBook view',
    setMainView: 'Set Main View',
    feeForWithdrawalsWillBeHeld: 'Fee for Withdrawal will be held from the remaining balance.',
    brandName: 'Brand Name',
    defaultCountry: 'Default Country',
    defaultLanguage: 'Default Language',
    defaultCurrency: 'Default Currency',
    rgsActivation: 'By RGS product activation you will get access to a comprehensive portfolio of up to 5000 games of worldwide known providers delivering best gaming experience to your players.',
    sportsBookActivation:
      'By Sportsbook product activation you will get access to a comprehensive multi-lingual, multi-currency, customizable interface with live and pre-match events for 65+ sports, 7500 leagues worldwide and over 3000 betting markets.',
    products: 'Products',
    websiteTemplate: 'Website Template',
    failedPaymentInfo: 'Configurations are not available due to inactivity of "Failed Payments Procedure" standard. Please, activate it from the "Standards" section.',
    depositWithdrawalPaymentMethodInfo:
      "Payment methods using conversion will be affected in case of changes and custom min/max limits may be reset by the system if contradicting to new changes . Make sure to review the limits and the availability of currencies in the payment methods' settings.",
    otherPaymentMethodInfo: 'Internal functionalities using conversion will use these conversion rates. Make sure to have all conversions in place (e.g. Referral Bonus).',
    conversionSettingsInfo: 'The current limit contradicts system/system-converted limit.',
    keywords: 'Keywords',
    eachKeyword: 'Each keyword',
    seoKeywordsInfo: 'Recommended to have 3-5 keywords, separated by a comma.',
    pageDescriptionInfo: 'Recommended length is up to 1000 characters.',
    pageTitleInfo: 'Recommended length is up to 200 characters.',
    card: 'Card',
    siteTwitterUsername: 'Site (Twitter @username)',
    imageAlt: 'Image Alt',
    recommendedSize: 'Recommended size',
    stateOrRegion: 'State/Region',
    socialLinks: 'Social Links',
    socialLinkInfo: "Company's profile pages on different resources, such as Facebook, Twitter, Google+, Instagram, Youtube, LinkedIn, Wikipedia, etc. You can add as many URLs, as you wish.",
    image: 'Image',
    depositPaymentCategory: 'Deposit Payment Category',
    withdrawalPaymentCategory: 'Withdrawal Payment Category',
    createNewBrand: 'Create New Brand',
    lConversion: 'conversion',
    logInAllowed: 'Log In Allowed',
    logInAllowedNote: 'When enabled, players can log in and make withdrawals only',
    schemaMarkupSettings: 'Schema Markup settings',
    seoSettings: 'SEO settings',
    openGraphSettings: 'Open Graph settings',
    twitterSettings: 'Twitter settings',
    accountId: 'Account ID',
    accountIdInfo: 'Any identification name or code associated with Service Account ID that provides a specific level of access',
    depositTaxesInfo: "If for a currency no tax config has been set the player won't be able to make deposits via that currency.",
    withdrawalTaxesInfo: "If for a currency no tax config has been set the player won't be able to make withdrawals via that currency.",
    ranges: 'Ranges',
    depositAmountRanges: 'Deposit Amount Range (From - To)',
    taxPercentage: 'Tax Percentage %',
    lTaxes: 'taxes',
    withdrawalAmountRanges: 'Withdrawal Amount Range (From - To)',
    fixedAmountOrPercent: 'Fixed Amount or Tax Percentage',
    allowWithdrawalViaTheDepositedMethods: 'Allow withdrawal only via the deposited methods',
    allowWithdrawalViaTheDepositedMethodsInfo: 'Activating the setting restricts the player from withdrawing money via the brand methods he never deposited before',
    spotsBookJackpotDescription: 'When enabled Sports Jackpot Widget gets available on the Landing Page',
    spotsBookPrematchTopMatchesDescription: 'When enabled Top Matches Widget gets available in the Iframe of Sportsbook and on the Landing Page',
    spotsBookScrollingTypeDescription: 'The setting is only applicable for Standard View and when enabled Sports page is wholly scrollable with one scroll',
    spotsBookTournamentBalanceDescription: 'When enabled the tournament balance gets available on the Website Homepage',
    sportJackpot: 'Sport Jackpot',
    sectionsScrolling: 'Sections Scrolling',
    sportTournamentBalance: 'Sport Tournament Balance',
    iframe: 'Iframe',
    native: 'Native',
    sportsBookIframeIntegrationDescription: 'The sportsbook will be available in an inline frame',
    sportsBookNativeIntegrationDescription: 'The sportsbook will be available directly in a build in-app',
    sportsBookMobileIntegrationType: 'Integration Type on Mobile',
    setAsDefault: 'Set as default',
    configs: 'Configs',
    availableLayouts: 'Available Layouts',
    setAmountRangesInfo:
      "If for a range both 'Fixed Amount' and 'Tax Percentage' be set, the system should calculate the tax in the following way: withhold a fixed tax amount + the x percentage of the amount which exceeds the amount set in the 'From' field of the range.",
    resetTaxNote: "Note that after resetting the tax configs the players won't be able to make deposits via that currency.",
    depositAmountRangesHint: "The amount in the 'From' field of the 'Deposit Amount Range' is included in that range, whereas the amount in the 'To' field is not included in the range.",
    withdrawalAmountRangesHint: "The amount in the 'From' field of the 'Withdrawal Amount Range' is included in that range, whereas the amount in the 'To' field is not included in the range.",
    fixedAmountHint:
      "The amount in the 'Fixed Amount' field should be lower or equal to the amount entered in the 'From' field of the 'Deposit Amount Range'. If the amount 'From' is 0, the amount in the 'Fixed Amount' field also must be 0.",
    fixedAmount: 'Fixed Amount',
    brandSportsBookActivation:
      'By Sportsbook product activation you will get access to a customizable interface with live and pre-match events for 65+ sports. When activated the product can only be deactivated from Sportsbook admin panel.',
    amountFrom: 'Amount From',
    allowPlayerCompose: 'Allow Players to "Compose" Messages',
    sportsBookInheritWebsiteColor: 'Inherit Website Color',
    sportsBookInheritWebsiteColorDescription: 'The setting applies to all sports pages.',
    theBrand: 'The brand',
    taxSettings: 'Tax settings',
    infoUrlHint: 'This URL will be used on the website for displaying taxation details.',
    everyMatrixDescription: 'Modular and API driven iGaming solutions designed to unleash bold ideas',
    smarticoAffiliateDescription: 'There is no better way to connect with your customers',
    hotjarDescription: 'Understand how users are really experiencing your site without drowning in numbers',
    hotjarId: 'HotJar ID',
    googleAnalyticsId: 'Google Analytics ID',
    googleAnalyticsDescription: 'Analyze usage across your websites',
    googleSiteVerificationCode: 'Google site verification code',
    googleSearchConsoleDescription: "Get help to monitor, maintain, and troubleshoot your website's presence in search results on Google.",
    copyPasteFieldName: 'Copy your {{fieldName}} and paste it to the field below.',
    learnMore: 'Learn more…',
    affiliateInfo: '{{count}} integrations can not be active simultaneously',
    liveChatDescription: 'Online communication app that enables you and your website visitors to chat in real-time',
    liveAgentDescription: 'Provide excellent customer service',
    intercomDescription: 'Start great customer relationship here',
    zendeskDescription: 'Communicate with customers more efficiently',
    intercomId: 'Intercom ID',
    zendeskId: 'Zendesk ID',
    liveChatLicenseNumber: 'LiveChat LICENSE_NUMBER',
    liveAgentYourLa: 'LiveAgent YOUR-LA',
    liveAgentButtonID: 'LiveAgent button ID',
    digitainAgIdInfo: 'This is the ID of the Brand in the "Digitain Agent" admin panel',
    depositCurrencyInfo:
      'In case of players are allowed to make deposits with all the currencies supported with the payment methods, "Allow Conversion" with the supported currency configs should be active for the payment method.',
    pageGroups: 'Page Groups',
    digitainRetailInfo: "This is the ID of the Brand in the 'Digitain Retail' admin panel",
    smarticoDescription: 'The gamification solution that utilizes challenges and gaming techniques to motivate players',
    smarticoLabelKey: 'Label Key',
    smarticoBrandKey: 'Brand Key',
    smarticoBrandKeyInfo: 'A public key for the brand',
    smarticoLabelKeyInfo: 'A public key for the label',
    smarticoNote: 'Smartico CRM and Gamification are interconnected, each action in this section has effect on Gamification as well',
    realityCheckPeriod: 'Reality Check Period',
    prematchTopMatches: 'Prematch Top Matches',
    prematchTotalTopMatches: 'Prematch Top Matches (Total Market Included)',
    spotsBookPrematchTotalTopMatchesDescription: 'When enabled Prematch Top Matches Widget with Total Market Data gets available',
    livePrematchTopMatches: 'Live & Prematch Top Matches',
    spotsBookLivePrematchTopMatchesDescription: 'When enabled Live & Prematch Top Matches Widget gets available',
    liveTopMatches: 'Live Top Matches',
    spotsBookLiveTopMatchesDescription: 'When enabled Live Top Matches Widget gets available',
    verificationPeriodTabInfo: 'In this section, you can set configurations to automatically block unverified player transactions or accounts for customizable periods.',
    periodCalculationSource: 'Period Calculation Source',
    periodCalculationSourceInfo: 'Select the date source on which the calculation of the verification period will start',
    verificationPeriod: 'Verification period',
    verificationPeriodInfo: ' The number of days a player account can be active before verification',
    unverifiedPlayersExclusionsInfo:
      "In cases when a payment method is selected in 'Payments Ignoring Deposit Verification' menu and limits are set in 'Max Deposit Amounts' part, the limits will not affect on that payment method.",
    periodsLimitsInfo: 'In this section you  can set a specific time period that a player can choose for time out and self-exclusion.',
    preferencesDescription: 'Activating/ deactivating SMS, Email and Push Notifications for players.',
    preferencesSMSDescription: 'SMS sending for all the players of the brand and on the website Via SMS part should become invisible',
    preferencesEmailDescription: 'E-mail sending for all the players of the brand and on the website Via E-mail part should become invisible',
    walletsSettings: 'Wallets Settings',
    wallets: 'Wallets',
    features: 'Features',
    dda: 'DDA',
    ddaInfo: 'Activating this option the taxes on the withdrawal will be withheld in the DDA logic',
    whatsAppDescription: 'Let your customers contact you using the most popular messenger in the world.',
    phoneNumber: 'Phone Number',
    whatsAppPhoneNumberInfo: 'Enter the phone number with which your WhatsApp account is registered',
    multiGameLaunch: 'Multi-Game launch',
    multiGameLaunchDescription: 'Depending on the configuration, the multi-game option is available on the website and players are able to play multiple games simultaneously.',
    jackpotWidget: 'Jackpot Widget',
    jackpotWidgetDescription: 'Refers to Casino Jackpot Widget activation for Landing Page. Available for Amusnet Provider Jackpot Widget.',
    gameIframe: 'Game Iframe',
    manuBarFixation: 'Menu Bar Fixation',
    manuBarFixationDescription: 'Only for mobile view, depending on the configuration Main Bar is fixed for the lobbies.',
    paymentVisibilityInfo: 'In this section, you can hide and not allow payment method usage for players who have one of the selected tags',
    centrivoConnectAgDescription: "Transform Your iGaming Business with Centrivo's Connect Agent Solution",
    retailCentrivoDescription: "Transform Your iGaming Business with Centrivo's Retail Solution",
    currencySymbol: 'Currency Symbol',
    preferencesPushNotificationsDescription: 'Push Notifications channel will become available for sending notifications for the Brand',
    currencyFormat: 'Currency Format',
    currencyFormatInfo: 'Which Currency format to show on the website for the players.',
    isoCode: 'ISO Code',
    symbols: 'Symbols',
    digitainCrmDescription: 'CRM solution designed to maintain the engagement of existing players and enhance their overall satisfaction',
    accessDecreaseLimit: 'Access Limit',
    depositOnNegativeBalance: 'Deposit on Negative Balance',
    depositOnNegativeBalanceInfo: "After the deposit approval the adjustment will be done to the player's negative wallet for closing the debt.",
    digitainAffiliateDescription: 'Activate your affiliate program and experience a significant boost in your website traffic.',
    projectId: 'Project ID',
    projectIdInfo: 'This is the ID of the Project in the "Digitain Affiliate" admin panel',
    brandIdInfo: 'This is the ID of the Brand in the "Digitain Affiliate" admin panel',
    maintenanceSetup: 'Maintenance setup',
    blockAction: 'Block Action',
    maintenancePeriod: 'Maintenance Period',
    sportsBookViews: 'Sportsbook Views',
    mandatoryNotification: 'Mandatory Notification',
    mandatoryNotificationInfo: 'A mandatory pop-up for logged in players on the website',
    lossDecreaseLimit: 'Loss Limit',
    inactivityIndicators: 'Inactivity Indicators',
    inactivityIndicatorsInfo:
      "This configuration will consider as an inactive player based on one of several indicators, the player's last login date or last transactions(bet, deposit, withdrawal) date",
    recoverByPlayer: 'Recover By Player',
    recoverByPlayerInfo: 'When enabled, players can recover themself through the verification code',
    singleBetDecreaseLimit: 'Single Bet Limit',
    cashierType: 'Cashier Type',
    lIcon: 'icon',
    gameLaunchType: 'Game Launch Type (Mobile view)',
    gameFullScreenLaunchDescription: 'Game open with iframe in full screen',
    gameHeaderFooterLaunchDescription: 'Games open with iframe and header and footer',
    gameQuickActionBarLaunchDescription: 'Games open with quick actions bar',
    limitEffectConfigs: 'Limit Effect Configs',
    ignoreFirstDeposit: 'Ignore First Deposit',
    ignoreFirstDepositHint: "If this option is selected the player's first lifetime successful deposit will not be calculated in the responsible gaming deposit limit",
    periodLimit: 'Period Limit',
    playerLimitsConfiguration: 'Player Limits configuration',
    alanbaseDescription: 'A platform brought to you by experts in iGaming and affiliate marketing helping other experts to optimize their costs and boost their profits.',
    routForEvents: 'Rout for Events',
    routForGoals: 'Rout for Goals',
    fastDepositPaymentMethod: 'Fast Deposit Payment Method',
    fastDepositPaymentMethodInfo:
      "Fast Deposit is a functionality on the website through which the players are able to make a deposit without leaving the game page by the payment method predefined from 'Centrivo'.",
    sportStatusIconsInfo: "Uploaded icons will be visible in the player's profile on the website. Each uploaded icon will be reflected on the website after no more than one hour.",
    rolloverBetsReturn: 'Rollover Bets Return to Same Wallet',
    rolloverBetsReturnInfo: 'In cases when the bet made from the rollover balance is returned/cancelled the amount will be transferred to rollover balance',
    maxDepositAmounts: 'Max Deposit Amounts',
    paymentsIgnoringDepositVerification: 'Payments Ignoring Deposit Verification',
    selfExclusion: 'Self-Exclusion',
    timeOut: 'Time-Out',
    configureLimits: 'Configure Limits',
    metaMapDesc: 'Everything you need to verify your customers in a single platform',
    clientId: 'Client Id',
    clientIdInfo: 'Used to identify you as a merchant.',
    clientSecret: 'Client Secret',
    clientSecretInfo: 'Use client_secret taken from the Dashbord as your password.',
    pushGameState: 'Push Game State',
    pushGameStateDescription: 'This setup defines the state of the games ("Game State") sent by the aggregator to the Back Office.',
    thunderBiteDescription: 'Gamify the customer experience and turn engagement into loyalty.',
    campaignURL: 'Campaign URL',
    robotsFile: 'Robots File',
    captchaCaseInsensitiveInfo: 'By enabling the option for the captcha, the system does not distinguish between uppercase and lowercase letters, which are considered identical.',
    caseInsensitive: 'Case Insensitive',
    verificationRequestPeriod: 'SMS/Email Verification Request Period',
    verificationRequestPeriodInfo: 'Config defines when the next SMS/Email verification request will be sent to the player',
    sec: 'sec',
    integratedDate: 'Integrated Date',
    integratedBy: 'Integrated By',
    isInvisible: 'Is Invisible',
    captchaInvisibleInfo: 'If checked, the captcha will not be visible to players',
    captchaLogonAttemptsInfo: 'Define the number of failed login attempts, the period for counting these attempts, and the duration for which CAPTCHA will appear.',
    jackpot: 'Jackpot',
    globalState: 'Global State',
    jackpotGames: 'Jackpot Games',
    myAffiliatesDescription: 'The most flexible affiliate tracking software on the market',
    sportDomainsTabInfo: 'Max sport domain count is {{count}}. Only active domains can be set as Main. Only verified domains can be connected.',
    licenseId: 'License ID',
    firebaseAuthenticationDescription: 'Configure social media platforms for user sign-up and sign-in authentication with Firebase.',
    frontend: 'Frontend',
    backend: 'Backend',
    google: 'Google',
    facebook: 'Facebook',
    socialMediaPlatforms: 'Social Media Platforms',
  },
  es: {
    mainCurrency: 'Moneda principal',
    mainLanguage: 'Idioma principal',
    market: 'Mercado',
    setMainCountry: 'Establecer país principal',
    setMainCurrency: 'Establecer moneda principal',
    setMainLanguage: 'Establecer idioma principal',
    domainName: 'Nombre de dominio',
    redirectedTo: 'Redirigido a',
    nameServer1: 'Nombre del servidor 1',
    nameServer2: 'Nombre del servidor 2',
    onlyVerifiedDomainInfo: 'Solo se puede conectar servidores verificados',
    disconnectDomain: 'Desconectar dominio',
    connectDomain: 'Conectar dominio',
    deleteDomain: 'Eliminar dominio',
    deleteScript: 'Eliminar script',
    deactivateScript: 'Desactivar script',
    activateScript: 'Activar script',
    supportedCurrencies: 'Monedas admitidas',
    supportedCountries: 'Países admitidos',
    supportedLanguages: 'Idiomas admitidos',
    providerDisableInfo: 'Puede que el proveedor haya sido desactivado en todo el sistema',
    activity: 'Actividad',
    verificationAnd: 'Verificación (y)',
    verificationOr: 'Verificación (o)',
    andDelimiter: 'y',
    currencyFrom: 'Moneda desde',
    currencyTo: 'Moneda hacia',
    reverseRate: 'Tarifa inversa',
    feature: 'Función',
    otherTransactions: 'Otras transacciones',
    limitType: 'Tipo de límite',
    playersNotifiedDate: 'Fecha de notificación',
    depositProcessingType: 'Tipo de procesamiento de depósitos',
    withdrawalProcessingType: 'Tipo de procesamiento de retiros',
    allowedActivities: 'Actividades permitidas',
    depositSupportedCurrencies: 'Monedas admitidas para el depósito',
    withdrawalSupportedCurrencies: 'Monedas admitidas para la retirada',
    newFee: 'Nueva tarifa, %',
    feeBefore: 'Tarifa antes, %',
    newLimit: 'Nuevo límite',
    statusDisabledInfo: 'El sitio web dejará de estar disponible desde todos los dominios.',
    statusActivitiesDisabledInfo: 'No se aceptarán nuevas apuestas.',
    statusVersionNamesDisabledInfo: 'Las apuestas se desactivarán para las versiones seleccionadas.',
    timezoneInfo: 'Los cálculos en curso relacionados con la zona horaria se modificarán.',
    domainInfo: {
      text1: 'Inicie sesión en su proveedor de dominio',
      text2: 'Su proveedor de dominio es la empresa donde lo compró (por ejemplo, GoDaddy, eNom, 1and1).',
      text3: 'Actualice sus servidores de nombre',
      text4: 'Vaya a la configuración de los DNS/servidores de nombre de su dominio.',
      text5: 'Sustituya todos sus servidores de nombre actuales por los servidores de nombre personalizados que se muestran en la tabla para cada dominio',
      text6: 'Verifique la conexión de su dominio',
      text7: 'Compruebe que la configuración de su dominio se ha actualizado.',
      text8: 'Puede que los registradores tarden alrededor de 24 horas en procesar las actualizaciones de los servidores de nombre.',
    },
    domainRedirection: 'Redirección de dominio',
    uVerify: 'VERIFICAR',
    redirect: 'Redirigir',
    forwardingURL: 'URL de reenvío',
    destinationURL: 'URL de destino',
    domainRedirectInfo: 'El domain que se utiliza como URL de reenvío o de destino no puede ser eliminado sin borrar la regla existente.',
    registerDomainInfo: 'Debe registrar todos sus domains en su cuenta de google para habilitar el captcha en todos ellos.',
    ageRestriction: 'Restricción de edad',
    failedLogonAttempts: 'Intentos de inicio de sesión fallidos',
    passwordPattern: 'Patrón de contraseña',
    failedAttemptsPeriod: 'Periodo de intentos fallidos',
    lockPeriod: 'Periodo de bloqueo',
    passwordReminder: 'Recordatorio de contraseña',
    inactivityPeriod: 'Periodo de inactividad',
    securityPeriod: 'Periodo de comprobación de seguridad',
    referralActivationPeriod: 'Periodo de activación de la recomendación',
    passwordPatternNote:
      'Se recomienda que las contraseñas tengan un mínimo de 8 caracteres, incluyendo al menos 1 número y 1 letra mayúscula. Sin embargo, puede debilitarla hasta tener entre 6 y 20 letras, números o caracteres especiales.',
    referralActivationPeriodNote: 'Periodo durante el cual desea conservar la información de identificación del recomendador para un nuevo registro en el sitio web.',
    correspondingTranslations: 'Asegúrese de que todas las traducciones se han actualizado correspondientemente.',
    verificationCodeLength: 'Longitud del código de verificación',
    dailyMobileVerification: 'Solicitudes diarias de verificación por móvil',
    emailRequestsInDaily: 'Solicitudes diarias de verificación por correo electrónico',
    expirationMinutes: 'Periodo de caducidad del token',
    expirationMinutesNote: 'La configuración define el periodo tras el cual el token (código, enlace) de la notificación dejará de ser válido.',
    emailRequestsInDailyNote: 'Esta configuración define cuántas solicitudes de verificación por correo electrónico puede recibir el jugador en un día. El valor mín. es {{value}}.',
    dailyMobileVerificationNote: 'Esta configuración define cuántas solicitudes de verificación por SMS puede recibir el jugador en un día. El valor mín. es {{value}}.',
    identityDocument: 'Documento de identidad',
    addressDocument: 'Documento de dirección',
    additionalDocument: 'Documento adicional',
    disableSportBook: 'Inhabilitar {{name}}',
    gameDisableTooltip: 'Puede que el juego haya sido desactivado en todo el sistema',
    playerLimitInfo: {
      text1: 'Puede configurar el periodo de disminución de la gravedad de los límites para cada tipo de límite.',
    },
    definiteSelfExclusionDecreaseLimit: 'Autoexclusión definitiva',
    indefiniteSelfExclusionDecreaseLimit: 'Autoexclusión indefinida',
    depositDecreaseLimit: 'Límite de depósito',
    betDecreaseLimit: 'Límite de apuesta',
    withdrawalDecreaseLimit: 'Límite de retirada',
    activateSportsbook: 'Activar Sportsbook',
    customScript: {
      text1: 'Puede integrar nuevas aplicaciones o añadir funcionalidades',
      text2: 'diferentes a su sitio web mediante la adición de scripts personalizados.',
    },
    scriptName: 'Nombre de script',
    insideHead: 'Dentro de la etiqueta de cierre </head>',
    insideBody: 'Dentro de la etiqueta de cierre </body>',
    headTag: 'Script de etiqueta de encabezado',
    bodyTag: 'Script de etiqueta de cuerpo',
    service: 'servicio',
    integrate: 'Integrar',
    showOnLive: 'Mostrar en sitio web activo',
    showOnLiveTitle: 'Si desactiva este interruptor, el icono del servicio de terceros quedará oculto en la página de inicio de su sitio web.',
    apiSecret: 'Secreto de API',
    apiSecretOrPass: 'Secreto de API o contraseña',
    apiKeyOrUsername: 'Clave de API o nombre de usuario',
    sendEmailVia: 'Enviar correo electrónico mediante',
    smtpUser: 'Usuario SMTP',
    smtpPass: 'Contraseña SMTP',
    useSSL: 'Usar SSL',
    smtpHost: 'Host SMTP',
    smtpPort: 'Puerto SMTP',
    emailsInfo: {
      text1: 'Algunos proveedores de SMTP requieren que el mismo correo electrónico esté registrado en su extremo.',
      text2: 'Por favor, asegúrese de que se cumple este requisito.',
    },
    emailsInfo1: 'Todos los mensajes programados con este correo electrónico se moverán a borradores',
    blockCountries: 'Bloquear países',
    blockCountriesMainText: 'El sitio web dejará de estar disponible desde los países seleccionados',
    blockIps: 'Bloquear IPs',
    versionControl: 'Control de versiones',
    policies: 'Políticas',
    policy: 'Política',
    dateToNotify: 'Fecha de notificación',
    featureName: 'Nombre de función',
    noBrandData: 'No hay marcas que mostrar',
    createBrand: 'Crear marca',
    builder: 'Constructor',
    platformState: 'Estado de la plataforma',
    minMaxWithdrawalAmount: 'Importe mín-máx de retirada del sistema',
    minWithdrawalAmount: 'Importe min. de retirada',
    maxWithdrawalAmount: 'Importe máx. de retirada',
    minMaxDepositAmount: 'Importe mín-máx de depósito del sistema',
    minDepositAmount: 'Importe min. de depósito',
    maxDepositAmount: 'Importe máx. de depósito',
    operationFeePercent: 'Tasa de operación, %',
    withdrawalFeePercent: 'Tasa de retirada, %',
    withdrawalFee: 'Tasa de retirada',
    withdrawalFeeForUsedBalancePercent: 'Tasa de retirada para saldo utilizado, %',
    withdrawalFeeForUsedBalance: 'Tasa de retirada para saldo utilizado',
    allowedConversion: 'Conversión permitida',
    paymentMethodName: 'Nombre del método de pago',
    minAmount: 'Importe mín. de {{title}}',
    maxAmount: 'Importe máx. de {{title}}',
    minMaxSystemAmount: 'Importe mín-máx de {{title}}',
    depositFeePercent: 'Tasa de depósito, %',
    depositFee: 'Tasa de depósito',
    allowConversion: 'Permitir conversión',
    previewLive: 'Previsualización en vivo',
    feeFromDeposit: 'Tasa del depósito',
    allowWithdrawalFromUnusedBalance: 'Permitir la retirada del saldo no utilizado',
    allowWithdrawalFeeUsedBalance: 'La tasa de retirada se retendrá del saldo restante.',
    feeCalculationForWithdrawals: 'Cálculo de la tasa de retirada',
    feeFromWithdrawals: 'Tasa de las retiradas',
    feeFromWithdrawalsUsedBalance: 'Tasa de las retiradas (saldo utilizado)',
    feeFromWithdrawalsBalance: 'Tasa de las retiradas (saldo {{type}})',
    unusedBalanceInfo: 'Si se activa, la opción de saldo no utilizado aparecerá en la configuración de la "Tasa de retirada".',
    paymentTransactionType: 'Tipo de transacción de pago',
    paymentMethodType: 'Tipo de método de pago',
    playerPaymentRelation: 'Relación jugador-pago',
    failedPaymentAttempts: 'Intentos de pago fallidos',
    failedPaymentAttemptsPeriod: 'Periodo de intentos de pago fallidos',
    temporaryBlockPeriod: 'Periodo de bloqueo temporal',
    reverseConversion: 'Conversión inversa de {{title}}',
    lCustomScript: 'script personalizado',
    largeTransactions: 'Transacciones grandes',
    lBrand: 'marca',
    playerSettings: 'Opciones del jugador',
    verificationConfigurations: 'Configuraciones de verificación',
    theCurrency: 'La moneda',
    mainCountry: 'País principal',
    theLanguage: 'El idioma',
    theDomain: 'El Domain',
    smsSettings: 'Opciones de SMS',
    verificationSettings: 'Opciones de verificación',
    smtpSettings: 'Opciones de SMTP',
    captchaSettings: 'Opciones de captcha',
    theEmail: 'El correo electrónico',
    conversions: 'Conversiones',
    theConversion: 'La conversión',
    theCountry: 'El país',
    playerVerificationSettings: 'Configuración de verificación de jugadores',
    domainRedirectionRule: 'Regla de redirección de dominio',
    wageringActivities: 'Actividades de apuesta',
    theSportsBook: 'El sportsbook',
    sportsBook: 'Sportsbook',
    theSportsBookView: 'La vista de sportsbook',
    setMainView: 'Establecer vista principal',
    feeForWithdrawalsWillBeHeld: 'La tasa de retirada se retendrá del saldo restante.',
    brandName: 'Nombre de marca',
    defaultCountry: 'País predeterminado',
    defaultLanguage: 'Idioma predeterminado',
    defaultCurrency: 'Moneda predeterminada',
    rgsActivation:
      'Con la activación del producto RGS obtendrá acceso a una amplia cartera de hasta 5000 juegos de proveedores mundialmente conocidos y que ofrecen la mejor experiencia de juego a sus jugadores.',
    sportsBookActivation:
      'Mediante la activación del producto de sportsbook, obtendrá acceso a una completa interfaz multilingüe, multimoneda y personalizable, con eventos en directo y previos al partido para más de 65 deportes, 7500 ligas de todo el mundo y más de 3000 mercados de apuestas.',
    products: 'Productos',
    websiteTemplate: 'Plantilla de sitio web',
    failedPaymentInfo: 'Las configuraciones no están disponibles debido a la inactividad de la norma "Procedimiento de pago fallido". Por favor, actívela desde la sección "Estándares".',
    depositWithdrawalPaymentMethodInfo:
      'Los métodos de pago que utilicen la conversión se verán afectados en caso de cambios y el sistema puede restablecer los límites mínimos/máximos personalizados si se contradicen con los nuevos cambios. '
      + 'Asegúrese de revisar los límites y la disponibilidad de divisas en la configuración de los métodos de pago.',
    otherPaymentMethodInfo:
      'Las funcionalidades internas que usan la conversión utilizarán estos tipos de conversión. Asegúrese de tener todas las conversiones en vigor (por ejemplo, el bono por recomendación).',
    conversionSettingsInfo: 'El límite actual contradice el límite del sistema/conversión.',
    keywords: 'Palabras clave',
    card: 'Tarjeta',
    siteTwitterUsername: 'Sitio (Twitter @usuario)',
    imageAlt: 'Imagen Alt.',
    recommendedSize: 'Tamaño recomendado',
    stateOrRegion: 'Estado/Región',
    socialLinks: 'Enlaces a redes sociales',
    socialLinkInfo: 'Páginas de perfil de la empresa en diferentes recursos, como Facebook, Twitter, Google+, Instagram, Youtube, LinkedIn, Wikipedia, etc. Puede añadir tantas URLs como desee.',
    image: 'Imagen',
    depositPaymentCategory: 'Categoría de pago de depósitos',
    withdrawalPaymentCategory: 'Categoría de pago de retiradas',
    createNewBrand: 'Crear nueva marca',
    accountId: 'ID de Cuenta',
    allowWithdrawalViaTheDepositedMethods: 'Permitir la retirada sólo a través de los métodos depositados',
    allowWithdrawalViaTheDepositedMethodsInfo: 'La activación de la configuración restringe al jugador la posibilidad de retirar dinero a través de los métodos que nunca antes había depositado',
    lConversion: 'conversión',
    logInAllowed: 'Acceso permitido',
    logInAllowedNote: 'Cuando está activada, los jugadores sólo pueden iniciar sesión y realizar reintegros',
    schemaMarkupSettings: 'Configuración de Schema Markup',
    seoSettings: 'Ajustes SEO',
    openGraphSettings: 'Configuración de Open Graph',
    twitterSettings: 'Ajustes de Twitter',
    accountIdInfo: 'Cualquier nombre o código de identificación asociado al ID de cuenta de servicio que proporcione un nivel específico de acceso.',
    ranges: 'Rangos',
    depositAmountRanges: 'Importe del depósito (Desde - Hasta)',
    taxPercentage: 'Impuesto Porcentaje %',
    lTaxes: 'impuestos',
    withdrawalAmountRanges: 'Intervalo de retirada ( Desde - Hasta )',
    fixedAmountOrPercent: 'Importe fijo o porcentaje fiscal',
    spotsBookJackpotDescription: 'Importe fijo o tipo impositivo',
    spotsBookPrematchTopMatchesDescription: 'Cuando se activa, el widget Top Matches está disponible en el Iframe de Sportsbook y en la Landing Page.',
    spotsBookScrollingTypeDescription: 'La configuración sólo es aplicable a la vista estándar y, cuando está activada, la página de deportes es totalmente desplazable con un solo desplazamiento.',
    spotsBookTournamentBalanceDescription: 'Cuando se activa, el saldo del torneo está disponible en la página de inicio del sitio web.',
    sportJackpot: 'Jackpot deportivo',
    sectionsScrolling: 'Desplazamiento de secciones',
    sportTournamentBalance: 'Torneo deportivo Balance',
    iframe: 'Iframe',
    native: 'Nativo',
    sportsBookIframeIntegrationDescription: 'El sportsbook estará disponible en un marco en línea',
    sportsBookNativeIntegrationDescription: 'El sportsbook estará disponible directamente en un build in-app',
    sportsBookMobileIntegrationType: 'Tipo de integración en el móvil',
    setAsDefault: 'Fijar por defecto',
    configs: 'Configs',
    availableLayouts: 'Diseños Disponibles',
    depositTaxesInfo: 'Si para una divisa no se ha establecido ninguna configuración de impuestos, el jugador no podrá realizar depósitos a través de esa divisa.',
    withdrawalTaxesInfo: 'Si para una divisa no se ha establecido ninguna configuración de impuestos, el jugador no podrá realizar reintegros a través de esa divisa.',
    setAmountRangesInfo:
      "Si para un rango se establece tanto 'Importe Fijo' como 'Porcentaje de Impuesto', el sistema deberá calcular el impuesto de la siguiente manera: retener un importe de impuesto fijo + el porcentaje x del importe que exceda del importe establecido en el campo 'Desde' del rango.",
    resetTaxNote: 'Tenga en cuenta que después de restablecer la configuración de los impuestos, los jugadores no podrán hacer depósitos a través de esa moneda.',
    depositAmountRangesHint:
      'El importe del campo " Desde " del " Intervalo de Importes de Depósito " se incluye en dicho intervalo, mientras que el importe del campo " Hasta " no se incluye en el intervalo.',
    withdrawalAmountRangesHint:
      'El importe del campo " Desde " del " Intervalo de Importes de Retirada " se incluye en dicho intervalo, mientras que el importe del campo " Hasta " no se incluye en el intervalo.',
    fixedAmountHint:
      'El importe del campo "Importe Fijo" debe ser inferior o igual al importe introducido en el campo "Desde" del "Rango de Importe del Depósito". Si el importe \'Desde\' es 0, el importe del campo \'Importe Fijo\' también debe ser 0.',
    fixedAmount: 'Importe Fijo',
    brandSportsBookActivation:
      'Mediante la activación del producto Sportsbook obtendrá acceso a una interfaz personalizable con eventos en directo y pre-partido para más de 65 deportes. Una vez activado, el producto solo se puede desactivar desde el panel de administración de Sportsbook.',
    amountFrom: 'Importe Desde',
    allowPlayerCompose: 'Permitir a los Jugadores "Redactar" Mensajes',
    sportsBookInheritWebsiteColor: 'Heredar Color del Sitio Web',
    sportsBookInheritWebsiteColorDescription: 'El ajuste se aplica a todas las páginas de deportes.',
    theBrand: 'Marca',
    taxSettings: 'Configuración Fiscal',
    infoUrlHint: 'Esta URL se utilizará en el sitio web para mostrar los datos fiscales.',
    everyMatrixDescription: 'Soluciones de iGaming modulares y basadas en API diseñadas para liberar ideas audaces',
    smarticoAffiliateDescription: 'No hay mejor manera de conectar con sus clientes',
    hotjarDescription: 'Entender cómo los usuarios están experimentando realmente su sitio web sin ahogarse en los números.',
    hotjarId: 'HotJar ID',
    googleAnalyticsId: 'ID de Google Analytics',
    googleAnalyticsDescription: 'Analice el uso de sus sitios web',
    googleSiteVerificationCode: 'Código de verificación del sitio de Google',
    googleSearchConsoleDescription: 'Obtenga ayuda para supervisar, mantener y solucionar los problemas de presencia de su sitio web en los resultados de búsqueda de Google.',
    copyPasteFieldName: 'Copia tu {{fieldName}} y pégalo en el campo de abajo.',
    learnMore: 'Saber más...',
    affiliateInfo: '{{count}} las integraciones no pueden estar activas simultáneamente',
    liveChatDescription: 'Aplicación de comunicación en línea que le permite chatear en tiempo real con los visitantes de su sitio web.',
    liveAgentDescription: 'Ofrecer un excelente servicio al cliente',
    intercomDescription: 'Inicie aquí una excelente relación con el cliente',
    zendeskDescription: 'Comunicación más eficaz con los clientes',
    intercomId: 'Intercom ID',
    zendeskId: 'Zendesk ID',
    liveChatLicenseNumber: 'LiveChat LICENSE_NUMBER',
    liveAgentYourLa: 'LiveAgent YOUR-LA',
    liveAgentButtonID: 'ID del botón de LiveAgent',
    digitainAgIdInfo: 'Este es el ID de la marca en el panel de administración "Digitain Agent".',
    depositCurrencyInfo:
      'En caso de que a los jugadores se les permita hacer depósitos con todas las divisas soportadas con los métodos de pago, "Permitir Conversión" con las configuraciones de divisas soportadas debe estar activo para el método de pago.',
    pageGroups: 'Grupos de Páginas',
    digitainRetailInfo: 'Este es el ID del método de pago en el panel de administración de "Digitain Retail".',
    smarticoDescription: 'La solución de gamificación que utiliza retos y técnicas de juego para motivar a los jugadores.',
    smarticoLabelKey: 'Clave de Etiqueta',
    smarticoBrandKey: 'Clave de la Marca',
    smarticoBrandKeyInfo: 'Clave pública para la marca',
    smarticoLabelKeyInfo: 'Clave pública para la etiqueta',
    smarticoNote: 'Smartico CRM y Gamification están interconectados, cada acción en esta sección tiene efecto en Gamification también',
    realityCheckPeriod: 'Reality Check Period',
    timeOut: 'Tiempo muerto',
    prematchTotalTopMatches: 'Primeros partidos (mercado total incluido)',
    spotsBookPrematchTotalTopMatchesDescription: 'Si se activa, el widget de los mejores partidos de Prematch con datos totales del mercado estará disponible.',
    livePrematchTopMatches: 'En Vivo y PrePartido Top Partidos',
    spotsBookLiveTopMatchesDescription: 'Cuando está activado, el Widget de Top Partidos en Directo está disponible',
    verificationPeriodTabInfo: 'En esta sección, puede establecer configuraciones para bloquear automáticamente transacciones o cuentas de jugadores no verificadas durante periodos personalizables.',
    periodCalculationSource: 'Período Fuente de cálculo',
    periodCalculationSourceInfo: 'Seleccione la fuente de fecha en la que se iniciará el cálculo del período de verificación',
    verificationPeriod: 'Período de Verificación',
    verificationPeriodInfo: ' El número de días que una cuenta de jugador puede estar activa antes de la verificación.',
    unverifiedPlayersExclusionsInfo:
      'En los casos en que se seleccione un método de pago en el menú "Pagos que Ignoran la Verificación de Depósito" y se establezcan límites en la parte "Importes Máximos de Depósito", los límites no afectarán a ese método de pago.',
    periodsLimitsInfo: 'En esta sección puede establecer un periodo de tiempo específico que un jugador puede elegir para el tiempo muerto y la autoexclusión.',
    preferencesSMSDescription: 'Envío de SMS para todos los jugadores de la marca y sección de SMS invisible en el sitio web',
    preferencesEmailDescription: 'Envío de correo electrónico para todos los jugadores de la marca y el sitio web a través de correo electrónico debe ser invisible',
    walletsSettings: 'Ajustes de la Cartera',
    wallets: 'Carteras',
    features: 'Funciones',
    dda: 'DDA',
    ddaInfo: 'Activando esta opción los impuestos sobre el retiro serán retenidos en la lógica del APD',
    whatsAppDescription: 'Permita que sus clientes se pongan en contacto con usted a través del servicio de mensajería más popular del mundo.',
    phoneNumber: 'Número de Teléfono',
    whatsAppPhoneNumberInfo: 'Introduce el número de teléfono con el que está registrada tu cuenta de WhatsApp',
    multiGameLaunch: 'Lanzamiento Multijuegos',
    multiGameLaunchDescription: 'Dependiendo de la configuración, la opción multijuego está disponible en el sitio web y los jugadores pueden jugar varias partidas simultáneamente.',
    jackpotWidget: 'Jackpot Widget',
    jackpotWidgetDescription: 'Se refiere a la activación del Widget Jackpot de Casino para la Página de Destino. Disponible para el Widget Jackpot del Proveedor Amusnet.',
    gameIframe: 'Juego Iframe',
    manuBarFixation: 'Fijación de la Barra de Menús',
    manuBarFixationDescription: 'Sólo para la vista móvil, dependiendo de la configuración Barra Principal es fijo para los vestíbulos.',
    paymentVisibilityInfo: 'En esta sección, puede ocultar y no permitir el uso de métodos de pago a los jugadores que tengan una de las etiquetas seleccionadas',
    centrivoConnectAgDescription: 'Transforme su negocio de iGaming con la solución Connect Agent de Centrivo',
    retailCentrivoDescription: 'Transforme su negocio de iGaming con la solución para retail de Centrivo',
    currencySymbol: 'Símbolo de Moneda',
    preferencesPushNotificationsDescription: 'El canal de Notificaciones Push estará disponible para enviar notificaciones para la Marca',
    currencyFormat: 'Formato de moneda',
    currencyFormatInfo: 'Qué formato de moneda mostrar en el sitio web para los jugadores.',
    isoCode: 'Código ISO',
    symbols: 'Símbolos',
    digitainCrmDescription: 'Solución CRM diseñada para mantener el compromiso de los jugadores existentes y mejorar su satisfacción general.',
    accessDecreaseLimit: 'Límite de acceso',
    depositOnNegativeBalance: 'Depósito sobre saldo negativo',
    depositOnNegativeBalanceInfo: 'Después de la aprobación del depósito, el ajuste se realizará en el monedero negativo del jugador para cerrar la deuda.',
    digitainAffiliateDescription: 'Active su programa de afiliación y experimente un aumento significativo del tráfico de su sitio web.',
    projectId: 'ID del Proyecto',
    projectIdInfo: 'Este es el ID del proyecto en el panel de administración de "Digitain Affiliate".',
    brandIdInfo: 'Este es el ID de la marca en el panel de administración de "Digitain Affiliate".',
    maintenanceSetup: 'Configuración de mantenimiento',
    blockAction: 'Acción en Bloque',
    maintenancePeriod: 'Período de Mantenimiento',
    sportsBookViews: 'Vistas de Sportsbook',
    mandatoryNotification: 'Notificación obligatoria',
    mandatoryNotificationInfo: 'Una ventana emergente obligatoria para los jugadores registrados en el sitio web',
    lossDecreaseLimit: 'Límite de Pérdidas',
    inactivityIndicators: 'Indicadores de inactividad',
    inactivityIndicatorsInfo:
      'Esta configuración considerará como jugador inactivo en base a uno de varios indicadores, la última fecha de inicio de sesión del jugador o la última fecha de transacciones (apuesta, depósito, retirada).',
    recoverByPlayer: 'Recuperación por jugador',
    recoverByPlayerInfo: 'Cuando está activado, los jugadores pueden recuperarse a través del código de verificación',
    singleBetDecreaseLimit: 'Límite de Apuesta Única',
    cashierType: 'Tipo de Caja',
    gameLaunchType: 'Tipo de Lanzamiento del Juego (vista móvil)',
    gameFullScreenLaunchDescription: 'Juego abierto con iframe en pantalla completa',
    gameHeaderFooterLaunchDescription: 'Juegos abiertos con iframe y encabezado y pie de página',
    gameQuickActionBarLaunchDescription: 'Los juegos se abren con la barra de acciones rápidas',
    limitEffectConfigs: 'Configuración del efecto límite',
    ignoreFirstDeposit: 'Ignorar el primer depósito',
    ignoreFirstDepositHint: 'Si se selecciona esta opción, el primer depósito realizado con éxito por el jugador no se calculará en el límite de depósito de juego responsable.',
    periodLimit: 'Límite de período',
    playerLimitsConfiguration: 'Configuración de los límites de los jugadores',
    alanbaseDescription: 'Una plataforma creada por expertos en iGaming y marketing de afiliación para ayudar a otros expertos a optimizar sus costes y aumentar sus beneficios.',
    routForEvents: 'Rutas para eventos',
    routForGoals: 'Rutas por objetivos',
    fastDepositPaymentMethod: 'Método de pago de depósito rápido',
    fastDepositPaymentMethodInfo:
      "Depósito Rápido es una funcionalidad del sitio web a través de la cual los jugadores pueden realizar un depósito sin salir de la página de juego mediante el método de pago predefinido de 'Centrivo'.",
    sportStatusIconsInfo: 'Los iconos cargados serán visibles en el perfil del jugador en el sitio web. Cada icono cargado se reflejará en el sitio web al cabo de una hora como máximo.',
    rolloverBetsReturn: 'Las apuestas prorrogadas vuelven a la misma cartera',
    rolloverBetsReturnInfo: 'En caso de devolución/cancelación de la apuesta realizada con el saldo de reinversión, el importe se transferirá al saldo de reinversión.',
    maxDepositAmounts: 'Importes máximos de depósito',
    paymentsIgnoringDepositVerification: 'Pagos que ignoran la verificación de depósitos',
    selfExclusion: 'Autoexclusión',
  },
  tr: {
    mainCurrency: 'Ana Para Birimi',
    mainLanguage: 'Ana Dil',
    market: 'Pazar',
    setMainCountry: 'Ana Ülkeyi Ayarla',
    setMainCurrency: 'Ana Para Birimi Ayarla',
    setMainLanguage: 'Ana Dili Ayarla',
    domainName: 'Alan Adı',
    redirectedTo: 'Yönlendirildi:',
    nameServer1: 'Ad Sunucusu 1',
    nameServer2: 'Ad Sunucusu 2',
    onlyVerifiedDomainInfo: 'Yalnızca doğrulanmış domain bağlanabilir',
    disconnectDomain: 'Domain Bağlantısını Kes',
    connectDomain: 'Domain Bağla',
    deleteDomain: 'Domain Sil',
    deleteScript: 'Komut Dosyasını Sil',
    deactivateScript: 'Komut Dosyasını Devre Dışı Bırak',
    activateScript: 'Komut Dosyasını Etkinleştir',
    supportedCurrencies: 'Desteklenen Para Birimleri',
    supportedCountries: 'Desteklenen Ülkeler',
    supportedLanguages: 'Desteklenen Diller',
    providerDisableInfo: 'Sağlayıcı tüm sistem için devre dışı bırakılmış olabilir',
    activity: 'Faaliyet',
    verificationAnd: 'Doğrulama (ve)',
    verificationOr: 'Doğrulama (veya)',
    andDelimiter: 've',
    currencyFrom: 'Para Birimi:',
    currencyTo: 'Para Birimi:',
    reverseRate: 'Ters Oran',
    feature: 'Özellik',
    otherTransactions: 'Diğer İşlemler',
    limitType: 'Limit Türü',
    playersNotifiedDate: 'Bildirim Tarihi',
    depositProcessingType: 'Para Yatırma İşlemi Türü',
    withdrawalProcessingType: 'Para Çekme İşlemi Türü',
    allowedActivities: 'İzin Verilen Faaliyetler',
    depositSupportedCurrencies: 'Para Yatırmak için Desteklenen Para Birimleri',
    withdrawalSupportedCurrencies: 'Para Çekmek için Desteklenen Para Birimleri',
    newFee: 'Yeni Ücret, %',
    feeBefore: 'Öncesinde Ücret, %',
    newLimit: 'Yeni Limit',
    statusDisabledInfo: 'Web sitesi tüm domain alanlarından kullanılamayacak.',
    statusActivitiesDisabledInfo: 'Yeni bahisler kabul edilmeyecektir.',
    statusVersionNamesDisabledInfo: 'Seçilen sürümler için bahisler devre dışı bırakılacaktır.',
    timezoneInfo: 'Saat dilimiyle ilgili devam eden tüm hesaplamalar değişecektir.',
    domainInfo: {
      text1: 'Domain sağlayıcınıza giriş yapın',
      text2: 'Domain sağlayıcınız, onu satın aldığınız şirkettir (ör. GoDaddy, eNom, 1and1).',
      text3: 'Domain sunucularınızı güncelleyin',
      text4: 'Domain DNS/Ad sunucuları ayarlarına gidin.',
      text5: 'Mevcut tüm ad sunucularınızı, her domain için tabloda gösterilen özel ad sunucularıyla değiştirin',
      text6: 'Domain bağlantınızı doğrulayın',
      text7: 'Domain ayarlarınızın güncellenip güncellenmediğini kontrol edin.',
      text8: 'Kayıt şirketlerinin ad sunucusu güncellemelerini işlemesi 24 saat sürebilir.',
    },
    domainRedirection: 'Domain Yönlendirme',
    uVerify: 'DOĞRULA',
    redirect: 'Yönlendirme',
    forwardingURL: 'Yönlendirme URL',
    destinationURL: 'Hedef URL',
    domainRedirectInfo: 'Yönlendirme veya hedef URL olarak kullanılan domain, mevcut kural silinmeden silinemez.',
    registerDomainInfo: "Hepsi için captcha'yı etkinleştirmek için tüm domain adlarınızı google hesabınıza kaydetmeniz gerekir.",
    ageRestriction: 'Yaş Kısıtlaması',
    failedLogonAttempts: 'Başarısız Oturum Açma Denemeleri',
    passwordPattern: 'Şifre Kalıbı',
    failedAttemptsPeriod: 'Başarısız Denemeler Dönemi',
    lockPeriod: 'Kilit Süresi',
    passwordReminder: 'Şifre Hatırlatıcı',
    inactivityPeriod: 'Hareketsizlik Dönemi',
    securityPeriod: 'Güvenlik Kontrolü Dönemi',
    referralActivationPeriod: 'Yönlendirme Aktivasyon Süresi',
    passwordPatternNote:
      'Şifrelerin en az 1 rakam ve 1 büyük harf olmak üzere en az 8 karakterden oluşması önerilir. Ancak 6-20 Latin harf, sayı veya özel karakter olacak kadar güçlendirebilirsiniz.',
    referralActivationPeriodNote: 'Web sitesinde yeni bir kayıt için referans olan kişinin kimlik bilgilerini saklamak istediğiniz süre.',
    correspondingTranslations: 'Tüm çevirilerin uygun şekilde güncellendiğinden emin olun.',
    verificationCodeLength: 'Doğrulama Kodu Uzunluğu',
    dailyMobileVerification: 'Günlük Mobil Doğrulama İstekleri',
    emailRequestsInDaily: 'Günlük E-mail Doğrulama İstekleri',
    expirationMinutes: 'Jeton Son Kullanma Süresi',
    expirationMinutesNote: 'Config, bildirimdeki belirtecin (kod, bağlantı) geçersiz hale geleceği süreyi tanımlar.',
    emailRequestsInDailyNote: 'Config, doğrulama oyuncusunun bir günde kaç e-posta talebi alabileceğini tanımlar. Minimum değer: {{value}}.',
    dailyMobileVerificationNote: 'Yapılandırma, oyuncunun bir günde kaç SMS talebi alabileceğini tanımlar. Minimum değer: {{value}}.',
    identityDocument: 'Kimlik Belgesi',
    addressDocument: 'Adres Belgesi',
    additionalDocument: 'Ek Belge',
    disableSportBook: '‎{{name}} Devre Dışı Bırak',
    gameDisableTooltip: 'Oyun tüm sistem için devre dışı bırakılmış olabilir',
    playerLimitInfo: {
      text1: 'Her bir limit türü için limit seviyesini düşürme süresini ayarlayabilirsiniz.',
    },
    definiteSelfExclusionDecreaseLimit: 'Süreli Kendini Hariç Tutma',
    indefiniteSelfExclusionDecreaseLimit: 'Süresiz Kendini Hariç Tutma',
    depositDecreaseLimit: 'Para Yatırma Limiti',
    betDecreaseLimit: 'Bahis Limiti',
    withdrawalDecreaseLimit: 'Para Çekme Limiti',
    activateSportsbook: 'Spor Bahis Platformunu Etkinleştir',
    customScript: {
      text1: 'Yeni uygulamalar entegre edebilir veya farklı uygulamalar ekleyebilirsiniz.',
      text2: 'özel komut dosyaları ekleyerek web sitenize işlevselleştirir.',
    },
    scriptName: 'Komut Dosyası Adı',
    insideHead: 'Kapanış </head> etiketinin içinde',
    insideBody: 'Kapanış </body> etiketinin içinde',
    headTag: 'baş etiketi komut dosyası',
    bodyTag: 'gövde etiketi komut dosyası',
    service: 'hizmet',
    integrate: 'Birleştir',
    showOnLive: 'Canlı Web Sitesinde Göster',
    showOnLiveTitle: 'Bu değiştiriciyi kapattığınızda, 3. taraf hizmetinin simgesi web sitenizin ana sayfasından gizlenecektir.',
    apiSecret: 'API Gizli Anahtarı',
    apiSecretOrPass: 'API Gizli Anahtarı veya Şifresi',
    apiKeyOrUsername: 'API Anahtarı veya kullanıcı adı',
    sendEmailVia: 'E-mail Gönder',
    smtpUser: 'SMTP Kullanıcısı',
    smtpPass: 'SMTP Şifresi',
    useSSL: 'SSL Kullan',
    smtpHost: 'SMTP Ana Bilgisayarı',
    smtpPort: 'SMTP Bağlantı Noktası',
    emailsInfo: {
      text1: 'Bazı SMTP sağlayıcıları için aynı e-mailin kendi taraflarında kayıtlı olması gerekir.',
      text2: 'Lütfen gereksinimin karşılandığından emin olun.',
    },
    emailsInfo1: 'Bu e-maili içeren tüm planlanmış iletiler taslağa taşınacak',
    blockCountries: 'Ülke Yasakla',
    blockCountriesMainText: 'Web sitesi seçilen ülkelerden kullanılamaz hale gelecek',
    blockIps: "IP'leri engelle",
    versionControl: 'Sürüm Kontrolü',
    policies: 'Politikalar',
    policy: 'Politika',
    dateToNotify: 'Bildirim Tarihi',
    featureName: 'Özellik Adı',
    noBrandData: 'Gösterilecek Marka Yok',
    createBrand: 'Marka Yarat',
    builder: 'Geliştirici',
    platformState: 'Platform Durumu',
    minMaxWithdrawalAmount: 'Min-Max Sistem Para Çekme Tutarı',
    minWithdrawalAmount: 'Min. Para Çekme Tutarı',
    maxWithdrawalAmount: 'Maks. Para Çekme Tutarı',
    minMaxDepositAmount: 'Min-Max Sistem Para Yatırma Tutarı',
    minDepositAmount: 'Min. Para Yatırma Tutarı',
    maxDepositAmount: 'Maks. Para Yatırma Tutarı',
    operationFeePercent: 'Operasyon Ücreti, %',
    withdrawalFeePercent: 'Para Çekme Ücreti, %',
    withdrawalFee: 'Para Çekme Ücreti',
    withdrawalFeeForUsedBalancePercent: 'Kullanılan bakiye için Para Çekme Ücreti, %',
    withdrawalFeeForUsedBalance: 'Kullanılan bakiye için Para Çekme Ücreti',
    allowedConversion: 'İzin Verilen Dönüşüm',
    paymentMethodName: 'Ödeme yöntemi adı',
    minAmount: 'Min {{title}} Tutar',
    maxAmount: 'Maks. {{title}} Tutar',
    minMaxSystemAmount: 'Min-Maks Sistem {{title}} Tutarı',
    depositFeePercent: 'Para Yatırma Ücreti, %',
    depositFee: 'Para Yatırma Ücreti',
    allowConversion: 'Dönüşüme İzin Ver',
    previewLive: 'Önizleme Canlı',
    feeFromDeposit: 'Para Yatırma Ücreti',
    allowWithdrawalFromUnusedBalance: 'Kullanılmayan Bakiyeden Para Çekilmesine İzin Ver',
    allowWithdrawalFeeUsedBalance: 'Para Çekme Ücreti kalan bakiyeden yapılacaktır.',
    feeCalculationForWithdrawals: 'Para Çekme Ücreti Hesaplama',
    feeFromWithdrawals: 'Para Çekme Ücreti',
    feeFromWithdrawalsUsedBalance: 'Para Çekme Ücreti (Kullanılmış Bakiye)',
    feeFromWithdrawalsBalance: 'Para Çekme Ücreti ({{type}} Bakiyesi)',
    unusedBalanceInfo: "Etkinleştirildiğinde, 'Para Çekme Ücreti' ayarında 'Kullanılmayan' bakiye seçeneği görünecektir.",
    paymentTransactionType: 'Ödeme İşlem Türü',
    paymentMethodType: 'Ödeme Yöntemi Türü',
    playerPaymentRelation: 'Oyuncu-Ödeme İlişkisi',
    failedPaymentAttempts: 'Başarısız Ödeme Girişimleri',
    failedPaymentAttemptsPeriod: 'Başarısız Ödeme Girişimleri Dönemi',
    temporaryBlockPeriod: 'Geçici Blok Dönemi',
    reverseConversion: '‎{{title}} Ters Dönüşüm',
    lCustomScript: 'özel komut dosyası',
    largeTransactions: 'Büyük işlemler',
    lBrand: 'marka',
    playerSettings: 'Oyuncu ayarları',
    verificationConfigurations: 'Doğrulama yapılandırmaları',
    theCurrency: 'Para Birimi',
    mainCountry: 'Ana Ülke',
    theLanguage: 'Dil',
    theDomain: 'Domain',
    smsSettings: 'SMS ayarları',
    verificationSettings: 'Doğrulama ayarları',
    smtpSettings: 'SMTP ayarları',
    captchaSettings: 'Captcha ayarları',
    theEmail: 'E-mail',
    conversions: 'Dönüşümler',
    theConversion: 'Dönüşüm',
    theCountry: 'Ülke',
    playerVerificationSettings: 'Oyuncu doğrulama ayarları',
    domainRedirectionRule: 'Domain yeniden yönlendirme kuralı',
    wageringActivities: 'Çevrim Aktiviteleri',
    theSportsBook: 'Spor Bahis Platformu',
    sportsBook: 'Spor Bahis Platformu',
    theSportsBookView: 'Spor Bahis Platformu Görünümü',
    setMainView: 'Ana Görünümü Ayarla',
    feeForWithdrawalsWillBeHeld: 'Para Çekme Ücreti kalan bakiyeden yapılacaktır.',
    brandName: 'Marka Adı',
    defaultCountry: 'Varsayılan Ülke',
    defaultLanguage: 'Varsayılan Dil',
    defaultCurrency: 'Varsayılan Para Birimi',
    rgsActivation:
      "RGS ürün aktivasyonu ile oyuncularınıza en iyi oyun deneyimini sunan dünya çapında bilinen sağlayıcılardan oluşan 5000'e kadar oyundan oluşan kapsamlı bir portföye erişim elde edersiniz.",
    sportsBookActivation:
      "Spor bahis platformu ürün aktivasyonu ile 65+ spor, dünya çapında 7500 lig ve 3000'den fazla bahis pazarı için canlı ve maç öncesi etkinliklerle kapsamlı çok dilli, çok para birimli, özelleştirilebilir bir arayüze erişim elde edersiniz.",
    products: 'Ürünler',
    websiteTemplate: 'Web Sitesi Şablonu',
    failedPaymentInfo: '‎"Başarısız Ödeme Prosedürü" standardının etkin olmaması nedeniyle yapılandırmalar kullanılamıyor. Lütfen "Standartlar" bölümünden etkinleştirin.',
    depositWithdrawalPaymentMethodInfo:
      'Dönüşüm kullanan ödeme yöntemleri, değişiklik durumunda etkilenecektir ve yeni değişikliklerle çakışması durumunda sistem tarafından özel min/maks limitleri sıfırlanabilir. Ödeme yöntemleri ayarlarında para birimlerinin limitlerini ve kullanılabilirliğini gözden geçirdiğinizden emin olun.',
    otherPaymentMethodInfo: 'Dönüştürmeyi kullanan dâhili işlevler bu dönüştürme oranlarını kullanır. Tüm dönüşümlerin yerinde olduğundan emin olun (ör. Referans Bonusu).',
    conversionSettingsInfo: 'Geçerli limit, sistem/sistem tarafından dönüştürülmüş limitle çakışıyor.',
    keywords: 'Anahtar kelimeler',
    card: 'Kart',
    siteTwitterUsername: 'Site (Twitter @kullanıcıadı)',
    imageAlt: 'Resim Alt',
    recommendedSize: 'Önerilen boyut',
    stateOrRegion: 'Şehir/Bölge',
    socialLinks: 'Sosyal Bağlantılar',
    socialLinkInfo: 'Şirketin Facebook, Twitter, Google+, Instagram, Youtube, LinkedIn, Wikipedia gibi farklı kaynaklardaki profil sayfaları. Dilediğiniz kadar URL ekleyebilirsiniz.',
    content: 'İçerik',
    image: 'Resim',
    depositPaymentCategory: 'Para Yatırma Ödeme Kategorisi',
    withdrawalPaymentCategory: 'Para Çekme Ödeme Kategorisi',
    createNewBrand: 'Yeni Marka Yarat',
    accountId: 'Hesap ID',
    allowWithdrawalViaTheDepositedMethods: 'Yalnızca para yatırmak için kullanılan yöntemlerle para çekme işlemlerine izin ver',
    allowWithdrawalViaTheDepositedMethodsInfo: 'Bu ayarı etkinleştirmek, oyuncunun daha önce hiç para yatırmadığı marka yöntemleriyle para çekmesini kısıtlar',
    lConversion: 'dönüşüm',
    logInAllowed: 'Giriş İzni Verildi',
    logInAllowedNote: 'Etkinleştirildiğinde, oyuncular giriş yapabilir ve sadece para çekebilir',
    schemaMarkupSettings: 'Schema Markup ayarları',
    seoSettings: 'SEO ayarları',
    openGraphSettings: 'Açık Grafik ayarları',
    twitterSettings: 'Twitter ayarları',
    accountIdInfo: "Hizmet Hesabı ID'si ile ilişkili olan herhangi bir kimlik adı veya kodu, belirli bir erişim düzeyi sağlar",
    depositTaxesInfo: 'Herhangi bir para birimi için vergi yapılandırması ayarlanmamışsa, oyuncu bu para birimiyle para yatırma işlemi yapamaz.',
    withdrawalTaxesInfo: 'Herhangi bir para birimi için vergi yapılandırması ayarlanmamışsa, oyuncu bu para birimiyle para çekme işlemi yapamaz.',
    ranges: 'Aralıklar',
    depositAmountRanges: 'Para Yatırma Tutar Aralığı (Tutardan - Tutara)',
    taxPercentage: 'Vergi Yüzdesi %',
    lTaxes: 'vergiler',
    withdrawalAmountRanges: 'Para Çekme Tutar Aralığı (Tutardan - Tutara)',
    fixedAmountOrPercent: 'Sabit Tutar veya Vergi Yüzdesi',
    spotsBookJackpotDescription: "Etkinleştirildiğinde Spor Jackpot Widget'ı Açılış Sayfasında görünür",
    spotsBookScrollingTypeDescription: 'Ayar sadece Standart Görünüm için uygulanır ve etkinleştirildiğinde Spor sayfası tek kaydırma ile bütünüyle kaydırılabilir',
    spotsBookTournamentBalanceDescription: 'Etkinleştirildiğinde, turnuva bakiyesi Web Sitesi Ana Sayfasında görünür ',
    sportJackpot: 'Spor Jackpot',
    sectionsScrolling: 'Bölüm Kaydırma',
    sportTournamentBalance: 'Spor Turnuvası Bakiyesi',
    iframe: 'Iframe',
    native: 'Yerel',
    sportsBookIframeIntegrationDescription: 'Spor bahisleri inline frame olarak sunulacak',
    sportsBookNativeIntegrationDescription: 'Spor bahisleri doğrudan uygulama içi bir yapıda sunulacak',
    sportsBookMobileIntegrationType: 'Mobil Entegrasyon Türü',
    setAsDefault: 'Varsayılan olarak ayarla',
    configs: 'Konfigürasyonlar',
    availableLayouts: 'Mevcut Düzenler',
    setAmountRangesInfo:
      "Bir aralık için hem 'Sabit Tutar' hem de 'Vergi Yüzdesi' ayarlanırsa, sistem vergiyi şu şekilde hesaplar: sabit bir vergi tutarı + aralığın 'Tutardan' kısmında belirtilen miktarı geçen tutarın x yüzdesi.",
    resetTaxNote: 'Vergi yapılandırmalarını resetledikten sonra oyuncuların bu para birimi üzerinden para yatırması mümkün olmaz.',
    depositAmountRangesHint: "Para Yatırma Tutarı Aralığı'nın' 'Tutardan' alanındaki tutar bu aralığa dahil edilirken, 'Tutara' alanındaki tutar aralığa dahil edilmez.",
    withdrawalAmountRangesHint: "Para Çekme Tutarı Aralığı'nın' 'Tutardan' alanındaki tutar bu aralığa dahil edilirken, 'Tutara' alanındaki tutar aralığa dahil edilmez.",
    fixedAmountHint:
      "Sabit Tutar' alanındaki tutar, 'Para Yatırma Tutar Aralığı'nın 'Tutardan' alanına girilen tutardan düşük veya eşit olmalıdır. 'Tutardan' alanındaki tutar 0 ise, 'Sabit Tutar' alanındaki tutar da 0 olmalıdır.",
    fixedAmount: 'Sabit Tutar',
    brandSportsBookActivation:
      'Spor Bahisleri etkinleştirildiğinde, 65+ spor için canlı ve maç öncesi etkinlikleri içeren özelleştirilebilir arayüze erişebileceksiniz. Ürün etkinleştirildiğinde yalnızca Spor bahisleri yönetici panelinden devre dışı bırakılabilir.',
    amountFrom: 'Tutardan',
    allowPlayerCompose: 'Oyuncuların Mesaj "Oluşturmasına" İzin Ver',
    sportsBookInheritWebsiteColor: 'Web Sitesi Rengini Devam Ettir',
    sportsBookInheritWebsiteColorDescription: 'Bu ayar tüm spor sayfaları için geçerlidir.',
    theBrand: 'Marka',
    taxSettings: 'Vergi ayarları',
    infoUrlHint: 'Bu URL, vergi detaylarını görüntülemek için web sitesinde kullanılacaktır.',
    everyMatrixDescription: 'Cesur fikirleri hayata geçirmek için tasarlanmış modüler ve API odaklı iGaming çözümleri',
    smarticoAffiliateDescription: 'There is no better way to connect with your customers',
    hotjarDescription: 'Kullanıcıların sayılara takılmak zorunda kalmadan sitenizi gerçekten nasıl kullandığını anlayın',
    hotjarId: 'HotJar ID',
    googleAnalyticsDescription: 'Web sitelerinizde kullanımı analiz edin',
    googleSiteVerificationCode: 'Google site doğrulama kodu',
    googleSearchConsoleDescription: "Google'daki arama sonuçlarında web sitenizin durumunu izlemek, bakımını yapmak ve sorunlarını gidermek için yardım alın.",
    copyPasteFieldName: '{{fieldName}} kopyalayın ve aşağıdaki alana yapıştırın.',
    learnMore: 'Daha fazlası...',
    affiliateInfo: '{{count}} entegrasyonları aynı anda aktif olamaz',
    liveChatDescription: 'Online iletişim uygulaması, sizin ve web sitenizin ziyaretçilerinin gerçek zamanlı olarak sohbet etmesini sağlar',
    liveAgentDescription: 'Mükemmel müşteri hizmeti sağlar',
    intercomDescription: 'Mükemmel müşteri ilişkilerine buradan başlayın',
    zendeskDescription: 'Müşterilerinizle daha verimli iletişim kurun',
    intercomId: 'Intercom ID',
    zendeskId: 'Zendesk ID',
    liveChatLicenseNumber: 'LiveChat LICENSE_NUMBER',
    liveAgentYourLa: 'LiveAgent YOUR-LA',
    liveAgentButtonID: 'LiveAgent button ID',
    digitainAgIdInfo: 'Bu, "Digitain Acentesi" yönetici panelindeki Markanın ID\'sidir',
    depositCurrencyInfo:
      'Oyuncuların ödeme yöntemleriyle desteklenen tüm para birimleriyle para yatırmalarına izin verilmesi için, ödeme yöntemi için desteklenen para birimi yapılandırmaları ile "Dönüştürmeye İzin Ver" etkinleştirilmelidir.',
    pageGroups: 'Sayfa Grupları',
    digitainRetailInfo: "Bu, 'Digitain Perakende' yönetici panelindeki Markanın ID'sidir",
    smarticoDescription: 'Oyuncuları motive etmek için mücadele ve oyun tekniklerini kullanan oyunlaştırma çözümü',
    smarticoLabelKey: 'Etiket Anahtarı',
    smarticoBrandKey: 'Marka Anahtarı',
    smarticoBrandKeyInfo: 'Marka için genel anahtar',
    smarticoLabelKeyInfo: 'Etiket için genel anahtar',
    smarticoNote: 'Smartico CRM ve Oyunlaştırma birbiriyle bağlantılıdır, bu bölümdeki her eylemin Oyunlaştırma üzerinde de etkisi vardır',
    realityCheckPeriod: 'Gerçeklik Kontrolü Dönemi',
    timeOut: 'Zaman Aşımı',
    eachKeyword: 'Her anahtar kelime',
    seoKeywordsInfo: 'Virgülle ayrılmış 3-5 anahtar kelime olması önerilir.',
    pageDescriptionInfo: 'Önerilen uzunluk en fazla 1000 karakterdir.',
    pageTitleInfo: 'Önerilen uzunluk en fazla 200 karakterdir.',
    spotsBookPrematchTopMatchesDescription: "Etkinleştirildiğinde En İyi Maçlar Widget'ı Spor Bahisleri Iframe'inde ve Açılış Sayfasında kullanılabilir hale gelir",
    googleAnalyticsId: 'Google Analitik ID',
    prematchTopMatches: 'Maç Öncesi En İyi Maçlar',
    prematchTotalTopMatches: 'Maç Öncesi En İyi Maçlar (Toplam Pazar Dahil)',
    spotsBookPrematchTotalTopMatchesDescription: "Etkinleştirildiğinde Toplam Pazar Verilerini ile Maç Öncesi En İyi Maçlar Widget'ı kullanılabilir hale gelir",
    livePrematchTopMatches: 'Canlı & Maç Öncesi En İyi Maçlar',
    spotsBookLivePrematchTopMatchesDescription: 'Canlı ve Maç Öncesi En İyi Maçlar Etkinleştirildiğinde Widget kullanılabilir ',
    liveTopMatches: 'En İyi Canlı Maçlar',
    spotsBookLiveTopMatchesDescription: 'En İyi Canlı Maçlar Etkinleştirildiğinde Widget kullanılabilir',
    verificationPeriodTabInfo:
      'Bu bölümde, doğrulanmamış oyuncu işlemlerini otomatik olarak engellemek için yapılandırmaları ayarlayabilir veya hesapları özelleştirilebilir dönemler için ayarlayabilirsiniz.',
    periodCalculationSource: 'Dönem Hesaplama Kaynağı',
    periodCalculationSourceInfo: 'Doğrulama dönemi hesaplamasının başlayacağı tarih kaynağını seç',
    verificationPeriod: 'Doğrulama dönemi',
    verificationPeriodInfo: ' Oyuncu hesabının doğrulanmadan önce etkin olabileceği gün sayısı',
    unverifiedPlayersExclusionsInfo:
      "Para Yatırma Doğrulamasını Dikkate Almayan Ödemeler' menüsünde bir ödeme yöntemi seçildiğinde ve 'Maksimum Para Yatırma Tutarları' bölümünde limitler belirlendiğinde, limitler bu ödeme yönteminden etkilenmez.",
    periodsLimitsInfo: 'Bu bölümde, oyuncunun zaman aşımı ve kendi hariç tutma için seçebileceği belirli bir süre ayarlayabilirsiniz.',
    preferencesDescription: 'Oyuncular için SMS, E-posta ve Anlık Bildirimlerin etkinleştirilmesi/devre dışı bırakılması.',
    preferencesSMSDescription: 'Markanın tüm oyuncuları için SMS gönderimi ve web sitesinde SMS bölümünün görünmez olması',
    preferencesEmailDescription: 'E-mail sending for all the players of the brand and on the website Via E-mail part should become invisible',
    walletsSettings: 'Cüzdan Ayarları',
    wallets: 'Cüzdanlar',
    features: 'Özellikler',
    dda: 'DDA',
    ddaInfo: 'Bu seçenek etkinleştirildiğinde, para çekme işlemine ilişkin vergiler DDA kapsamında kesilir',
    whatsAppDescription: 'Müşterilerinizin dünyanın en popüler mesajlaşma aracını kullanarak sizinle iletişime geçmesini sağlayın.',
    phoneNumber: 'Telefon Numarası',
    whatsAppPhoneNumberInfo: 'WhatsApp hesabınızın kayıtlı olduğu telefon numarasını girin',
    multiGameLaunch: 'Çoklu Oyun başlatma',
    multiGameLaunchDescription: 'Yapılandırmaya bağlı olarak, çoklu oyun seçeneği web sitesinde mevcuttur ve oyuncular aynı anda birden fazla oyun oynayabilir',
    jackpotWidget: "Jackpot Widget'ı",
    jackpotWidgetDescription: "Açılış Sayfası için Casino Jackpot Widget aktivasyonunu ifade eder. Amusnet Sağlayıcı Jackpot Widget'ı için kullanılabilir.",
    gameIframe: "Oyun Iframe'i",
    manuBarFixation: 'Menü Çubuğu Sabitleme',
    manuBarFixationDescription: 'Yalnızca mobil görünüm için, yapılandırmaya bağlı olarak Ana Çubuk lobiler için sabitlenir.',
    paymentVisibilityInfo: 'Bu bölümde, seçilen etiketlerden birine sahip olan oyuncular için ödeme yöntemi kullanımını gizleyebilir veya izin vermeyebilirsiniz',
    centrivoConnectAgDescription: "Centrivo'nun Connect Acente Çözümü ile iGaming İşinizi Geliştirin",
    retailCentrivoDescription: "Centrivo'nun Perakende Çözümü ile iGaming İşinizi Geliştirin",
    currencySymbol: 'Para Birimi Sembolü',
    preferencesPushNotificationsDescription: 'Anında Bildirimler kanalı Marka için bildirim göndermek için kullanılabilir hale gelir',
    currencyFormat: 'Para Birimi Formatı',
    currencyFormatInfo: 'Oyuncular için web sitesinde hangi Para Birimi formatı gösterilir.',
    isoCode: 'ISO Code',
    symbols: 'Semboller',
    digitainCrmDescription: 'CRM çözümü, mevcut oyuncuların bağlılığını sürdürmek ve genel memnuniyetlerini artırmak için tasarlanmıştır',
    accessDecreaseLimit: 'Erişim Limiti',
    depositOnNegativeBalance: 'Negatif Bakiyeye Para Yatırma',
    depositOnNegativeBalanceInfo: 'Para yatırma onayından sonra, borcun kapatılması için oyuncunun negatif cüzdanında ayarlama yapılır.',
    digitainAffiliateDescription: 'Affiliate programınızı etkinleştirin ve web sitenizin trafiğinde ciddi bir artış sağlayın.',
    projectId: 'Proje ID',
    projectIdInfo: 'Bu, "Digitain Affiliate" yönetici panelindeki Proje ID\'sidir',
    brandIdInfo: 'Bu, "Digitain Affiliate" yönetici panelindeki Markanın ID\'sidir',
    maintenanceSetup: 'Bakım kurulumu',
    blockAction: 'Eylemi Engelle',
    maintenancePeriod: 'Bakım Süresi',
    sportsBookViews: 'Spor Bahisleri Görünümleri',
    mandatoryNotification: 'Zorunlu Bildirim',
    mandatoryNotificationInfo: 'Web sitesinde oturum açan oyuncular için zorunlu pop-up',
    lossDecreaseLimit: 'Kayıp Limiti',
    inactivityIndicators: 'Etkin Olmama Göstergeleri',
    inactivityIndicatorsInfo:
      'Bu yapılandırma, oyuncunun son giriş tarihi veya son işlem (bahis, para yatırma, para çekme) tarihi gibi çeşitli göstergelerden birine göre aktif olmayan bir oyuncu olarak değerlendirilir',
    recoverByPlayer: 'Oyuncuya Göre Kurtar',
    recoverByPlayerInfo: 'Etkinleştirildiğinde, oyuncular doğrulama kodu aracılığıyla kendilerini kurtarabilir',
    singleBetDecreaseLimit: 'Tekli Bahis Limiti',
    cashierType: 'Kasiyer Türü',
    lIcon: 'simge',
    gameLaunchType: 'Oyun Başlatma Türü (Mobil görünüm)',
    gameFullScreenLaunchDescription: 'Oyun tam ekranda iframe ile açılır',
    gameHeaderFooterLaunchDescription: 'Oyunlar iframe, üstbilgi ve altbilgi ile açılır',
    gameQuickActionBarLaunchDescription: 'Oyunlar hızlı eylemler çubuğu ile açılır',
    limitEffectConfigs: 'Limit Efekt Yapılandırmaları',
    ignoreFirstDeposit: 'İlk Para Yatırmayı Yoksay',
    ignoreFirstDepositHint: 'Bu seçenek seçilirse, oyuncunun ilk ömür boyu başarılı para yatırma işlemi sorumlu oyun para yatırma limitinde hesaplanmaz',
    periodLimit: 'Dönem Limiti',
    playerLimitsConfiguration: 'Oyuncu Limitleri yapılandırması',
    alanbaseDescription: 'iGaming ve affiliate pazarlama uzmanları tarafından hazırlanmış, maliyetlerinizi optimize etmenize ve kârınızı artırmanıza yardımcı olan bir platform.',
    routForEvents: 'Etkinlikler İçin Rota',
    routForGoals: 'Hedefler için Rota',
    fastDepositPaymentMethod: 'Hızlı Para Yatırma Ödeme Yöntemi',
    fastDepositPaymentMethodInfo: "Hızlı Para Yatırma, web sitesinde oyuncuların oyun sayfasından çıkmadan 'Centrivo'dan önceden tanımlanmış ödeme yöntemiyle para yatırabilecekleri bir işlevdir.",
    sportStatusIconsInfo: 'Yüklenen simgeler oyuncunun web sitesindeki profilinde görülebilir. Yüklenen her simge en fazla bir saat sonra web sitesine yansır.',
    rolloverBetsReturn: 'Rollover Bahisler Aynı Cüzdana Geri Döner',
    rolloverBetsReturnInfo: 'Rollover bakiyesinden yapılan bahsin iade/iptal edilmesi durumunda tutar rollover bakiyesine aktarılır',
    maxDepositAmounts: 'Maksimum Para Yatırma Tutarları',
    paymentsIgnoringDepositVerification: 'Para Yatırma Doğrulamasını Yok Sayan Ödemeler',
    selfExclusion: 'Kendini Hariç Tutma',
  },
  ru: {
    mainCurrency: 'Основная валюта',
    mainLanguage: 'Основной язык',
    market: 'Маркет',
    setMainCountry: 'Задать основную страну',
    setMainCurrency: 'Задать основную валюту',
    setMainLanguage: 'Задать основной язык',
    domainName: 'Название домена',
    redirectedTo: 'Перенаправлен на',
    nameServer1: 'Название 1-го сервера',
    nameServer2: 'Название 2-го сервера',
    onlyVerifiedDomainInfo: 'Могут быть подключены только подтвержденные домены',
    disconnectDomain: 'Отключить домен',
    connectDomain: 'Подключить домен',
    deleteDomain: 'Удалить домен',
    deleteScript: 'Удалить скрипт',
    deactivateScript: 'Деактивировать скрипт',
    activateScript: 'Активировать скрипт',
    supportedCurrencies: 'Поддерживаемые валюты',
    supportedCountries: 'Поддерживаемые страны',
    supportedLanguages: 'Поддерживаемые языки',
    providerDisableInfo: 'Возможно, провайдер отключен для всей системы',
    activity: 'Действие',
    verificationAnd: 'Верификация (и)',
    verificationOr: 'Верификация (или)',
    andDelimiter: 'и',
    currencyFrom: 'Из валюты',
    currencyTo: 'В валюту',
    reverseRate: 'Обратный курс',
    feature: 'Особенность',
    otherTransactions: 'Другие операции',
    limitType: 'Вид лимита',
    playersNotifiedDate: 'Дата уведомления',
    depositProcessingType: 'Вид обработки депозитов',
    withdrawalProcessingType: 'Вид обработки вывода средств',
    allowedActivities: 'Разрешенные действия',
    depositSupportedCurrencies: 'Поддерживаемые валюты для депозитов',
    withdrawalSupportedCurrencies: 'Поддерживаемые валюты для вывода средств',
    newFee: 'Новая комиссия (%)',
    feeBefore: 'Прежняя комиссия (%)',
    newLimit: 'Новый лимит',
    statusDisabledInfo: 'Сайт станет недоступен со всех доменов.',
    statusActivitiesDisabledInfo: 'Новые ставки приниматься не будут.',
    statusVersionNamesDisabledInfo: 'Ставки будут отключены для выбранных версий.',
    timezoneInfo: 'Все текущие расчеты, связанные с часовым поясом, будут изменены.',
    domainInfo: {
      text1: 'Войдите в учетную запись провайдера вашего домена',
      text2: 'Провайдер вашего домена — это компания, у которой вы его приобрели (например, GoDaddy, eNom, 1and1).',
      text3: 'Обновите названия серверов на вашем домене',
      text4: 'Войдите в настройки DNS / названия серверов на вашем домене',
      text5: 'Замените все текущие имена серверов на собственные имена серверов, показанные в таблице для каждого домена.',
      text6: 'Подтвердите подключение к домену',
      text7: 'Убедитесь, что настройки вашего домена обновлены.',
      text8: 'Регистраторам может потребоваться 24 часа для обработки обновлений названий серверов.',
    },
    domainRedirection: 'Перенаправление домена',
    uVerify: 'ВЕРИФИКАЦИЯ',
    redirect: 'Перенаправить',
    forwardingURL: 'Перенаправление URL',
    destinationURL: 'Целевой URL',
    domainRedirectInfo: 'Домен, используемый в качестве перенаправления или целевого URL, не может быть удален без удаления существующего правила.',
    registerDomainInfo: 'Необходимо зарегистрировать все свои домены в учетной записи Google, чтобы включить капчу для всех них.',
    ageRestriction: 'Ограничения по возрасту',
    failedLogonAttempts: 'Неудачные попытки входа в систему',
    passwordPattern: 'Шаблон пароля',
    failedAttemptsPeriod: 'Период неудачных попыток',
    lockPeriod: 'Период блокировки',
    passwordReminder: 'Подсказка пароля',
    inactivityPeriod: 'Период бездействия',
    securityPeriod: 'Период проверки безопасности',
    referralActivationPeriod: 'Период активации реферальной программы',
    passwordPatternNote:
      'Рекомендуется, чтобы пароли содержали не менее 8 символов, в том числе как минимум 1 цифру и 1 заглавную букву. Однако вы можете создать менее сложный пароль, используя от 6 до 20 букв латинского алфавита, цифр или специальных символов.',
    referralActivationPeriodNote: 'Период, в течение которого вы хотите хранить информацию об ID реферера при новой регистрации на сайте.',
    correspondingTranslations: 'Убедитесь, что все переводы соответствующим образом обновлены.',
    verificationCodeLength: 'Длина кода подтверждения',
    dailyMobileVerification: 'Ежедневные запросы на верификацию мобильного устройства',
    emailRequestsInDaily: 'Ежедневные запросы на верификацию электронной почты',
    expirationMinutes: 'Срок действия токена',
    expirationMinutesNote: 'Конфигурация определяет период, по истечении которого токен (код, ссылка) в уведомлении станет недействительным.',
    emailRequestsInDailyNote: 'Конфигурация определяет сколько запросов в день игрок может получить по электронной почте для верификации. Минимальное значение: {{value}}.',
    dailyMobileVerificationNote: 'Конфигурация определяет сколько запросов в день игрок может получить по SMS для верификации. Минимальное значение: {{value}}.',
    identityDocument: 'Документ, удостоверяющий личность',
    addressDocument: 'Документ, подтверждающий место жительства',
    additionalDocument: 'Дополнительный документ',
    disableSportBook: 'Отключить {{name}}',
    gameDisableTooltip: 'Возможно, игра отключена для всей системы',
    playerLimitInfo: {
      text1: 'Для каждого типа лимита вы можете установить период снижения строгости лимита.',
    },
    definiteSelfExclusionDecreaseLimit: 'Самоисключение на определенное время',
    indefiniteSelfExclusionDecreaseLimit: 'Самоисключение на неопределенное время',
    depositDecreaseLimit: 'Лимит на депозиты',
    betDecreaseLimit: 'Лимит на ставки',
    withdrawalDecreaseLimit: 'Лимит на вывод средств',
    activateSportsbook: 'Активировать Спортсбук',
    customScript: {
      text1: 'Вы можете интегрировать новые приложения или добавить другие',
      text2: 'функции на свой сайт, внедрив специальные скрипты.',
    },
    scriptName: 'Название скрипта',
    insideHead: 'Внутри закрывающего тега </head>',
    insideBody: 'Внутри закрывающего тега </body>',
    headTag: 'скрипт для заголовка тега',
    bodyTag: 'скрипт для содержания тега',
    service: 'сервис',
    integrate: 'Интегрировать',
    showOnLive: ' Показать на действующем сайте',
    showOnLiveTitle: 'Когда вы отключите этот переключатель, иконка стороннего сервиса будет скрыта с главной страницы вашего сайта.',
    apiSecret: 'Секретный вопрос для API',
    apiSecretOrPass: 'Секретный вопрос для API или пароль',
    apiKeyOrUsername: 'Ключ API или имя пользователя',
    sendEmailVia: 'Отправить письмо через',
    smtpUser: 'Пользователь SMTP',
    smtpPass: 'Пароль SMTP',
    useSSL: 'Использовать SSL',
    smtpHost: 'Хост SMTP',
    smtpPort: 'Порт SMTP',
    emailsInfo: {
      text1: 'Некоторые SMTP-провайдеры требуют, чтобы у них был зарегистрирован тот же адрес электронной почты.',
      text2: 'Убедитесь, что вы выполнили это требование.',
    },
    emailsInfo1: 'Все запланированные сообщения с этим адресом электронной почты будут перемещены в черновики',
    blockCountries: 'Заблокировать страны',
    blockCountriesMainText: 'Сайт станет недоступен во всех выбранных странах',
    blockIps: 'Заблокировать IP',
    versionControl: 'Контроль версии',
    policies: 'Политики',
    policy: 'Политика',
    dateToNotify: 'Дата уведомления',
    featureName: 'Название функции',
    noBrandData: 'Нет бренда для показа',
    createBrand: 'Создать бренд',
    builder: 'Билдер',
    platformState: 'Состояние платформы',
    minMaxWithdrawalAmount: 'Мин. – макс. сумма для вывода из системы',
    minWithdrawalAmount: 'Мин. сумма вывода средств',
    maxWithdrawalAmount: 'Макс. сумма вывода средств',
    minMaxDepositAmount: 'Мин. – макс. сумма депозита',
    minDepositAmount: 'Мин. сумма депозита',
    maxDepositAmount: 'Макс. сумма депозита',
    operationFeePercent: 'Операционная комиссия (%)',
    withdrawalFeePercent: 'Комиссия за вывод средств (%)',
    withdrawalFee: 'Комиссия за вывод средств',
    withdrawalFeeForUsedBalancePercent: 'Комиссия за вывод использованного остатка (%)',
    withdrawalFeeForUsedBalance: 'Комиссия за вывод использованного остатка',
    allowedConversion: 'Допустимая конвертация',
    paymentMethodName: 'Название способа оплаты',
    minAmount: 'Мин. сумма {{title}}',
    maxAmount: 'Макс. сумма {{title}}',
    minMaxSystemAmount: 'Мин. – макс. сумма {{title}} в системе',
    depositFeePercent: 'Комиссия за внесение средств (%)',
    depositFee: 'Комиссия за внесение средств',
    allowConversion: 'Разрешить конвертацию',
    previewLive: 'Предпросмотр в реальном времени',
    feeFromDeposit: 'Комиссия за депозит',
    allowWithdrawalFromUnusedBalance: 'Разрешить вывод средств из неиспользованного остатка',
    allowWithdrawalFeeUsedBalance: 'Комиссия за вывод будет удержана из остатка средств.',
    feeCalculationForWithdrawals: 'Расчет комиссии для вывода средств',
    feeFromWithdrawals: 'Комиссия за вывод средств',
    feeFromWithdrawalsUsedBalance: 'Комиссия за вывод средств (использованный остаток)',
    feeFromWithdrawalsBalance: 'Комиссия за вывод средств (остаток {{type}})',
    unusedBalanceInfo: 'При активации опция  «Неиспользованный» баланс появится в настройке «Комиссия за вывод средств».',
    paymentTransactionType: 'Вид платежной операции',
    paymentMethodType: 'Вид способа оплаты',
    playerPaymentRelation: 'Взаимосвязь между игроком и платежами',
    failedPaymentAttempts: 'Неудачные попытки оплаты',
    failedPaymentAttemptsPeriod: 'Период неудачных попыток оплаты',
    temporaryBlockPeriod: 'Период временной блокировки',
    reverseConversion: 'Обратная конвертация {{title}}',
    lCustomScript: 'специальный скрипт',
    largeTransactions: 'Крупные операции',
    lBrand: 'бренд',
    playerSettings: 'Настройки игрока',
    verificationConfigurations: 'Структуры проверки',
    theCurrency: 'Валюта',
    mainCountry: 'Основная страна',
    theLanguage: 'Язык',
    theDomain: 'Домен',
    smsSettings: 'Настройки SMS',
    verificationSettings: 'Настройки верификации',
    smtpSettings: 'Настройки SMTP',
    captchaSettings: 'Настройки капчи',
    theEmail: 'Электронная почта',
    conversions: 'Конвертации',
    theConversion: 'Конвертация',
    theCountry: 'Страна',
    playerVerificationSettings: 'Настройки верификации игрока',
    domainRedirectionRule: 'Правило перенаправления домена',
    wageringActivities: 'Действия вейджеринга',
    theSportsBook: 'Спортсбук',
    sportsBook: 'Спортсбук',
    theSportsBookView: 'Вид Спортсбука',
    setMainView: 'Задать основной вид',
    feeForWithdrawalsWillBeHeld: 'Комиссия за вывод будет удержана из остатка средств.',
    brandName: 'Наименование бренда',
    defaultCountry: 'Страна по умолчанию',
    defaultLanguage: 'Язык по умолчанию',
    defaultCurrency: 'Валюта по умолчанию',
    rgsActivation:
      'Активировав продукт RGS, вы получите доступ к обширному портфолио, включающему до 5000 игр от всемирно известных провайдеров, которые обеспечат вашим клиентам потрясающие впечатления от игры.',
    sportsBookActivation:
      'Активировав продукт Спортсбук, вы получите доступ к комплексному многоязычному, мультивалютному, настраиваемому интерфейсу с лайв и прематч событиями для более чем 65 видов спорта, 7500 лиг по всему миру и более 3000 рынков ставок.',
    products: 'Продукты',
    websiteTemplate: 'Шаблон сайта',
    failedPaymentInfo: 'Конфигурации недоступны из-за неактивности стандарта «Процедура обработки неудавшихся платежей». Активируйте его в разделе «Стандарты».',
    depositWithdrawalPaymentMethodInfo:
      'В случае изменений будут затронуты способы оплаты, использующие конвертацию, а также система может сбросить пользовательские минимальные / максимальные лимиты, если они противоречат изменениям. Обязательно проверьте лимиты и доступность валют в настройках способов оплаты.',
    otherPaymentMethodInfo:
      'Внутренние функции, использующие конвертацию, будут использовать эти коэффициенты конвертации. Убедитесь, что у вас указаны все варианты конвертации (например, реферальный бонус).',
    conversionSettingsInfo: 'Текущий лимит противоречит системному / конвертированному системой лимиту.',
    keywords: 'Ключевые слова',
    card: 'Карта',
    siteTwitterUsername: 'Сайт (Twitter @имяпользователя)',
    imageAlt: 'Альт. изображение',
    recommendedSize: 'Рекомендованный размер',
    stateOrRegion: 'Штат / регион',
    socialLinks: 'Социальные сети',
    socialLinkInfo:
      'Страницы компании на различных ресурсах, таких как Facebook, Twitter, Google+, Instagram, Youtube, LinkedIn, Wikipedia и т. д. Вы можете добавить столько URL-адресов, сколько пожелаете.',
    content: 'Контент',
    image: 'Изображение',
    depositPaymentCategory: 'Категория депозита средств',
    withdrawalPaymentCategory: 'Категория вывода средств',
    createNewBrand: 'Создать новый бренд',
    accountId: 'ID учетной записи',
    allowWithdrawalViaTheDepositedMethods: 'Разрешить снятие средств только посредством методов оплаты, которые использовались для депозита средств',
    allowWithdrawalViaTheDepositedMethodsInfo: 'Активация настройки запрещает игроку снимать деньги с помощью методов оплаты, которые он никогда раньше не использовал для депозита',
    lConversion: 'конвертация',
    logInAllowed: 'Вход разрешен',
    logInAllowedNote: 'Когда эта функция включена, игроки могут входить в систему и только снимать средства',
    schemaMarkupSettings: 'Настройки схемы',
    seoSettings: 'Настройки SEO',
    openGraphSettings: 'Настройки Open Graph ',
    twitterSettings: 'Настройки Twitter',
    accountIdInfo: 'Любой ID имени или код, связанные с ID учетной записи сервиса, которые обеспечивает определенный уровень доступа',
    depositTaxesInfo: 'Информация о налогах депозита',
    withdrawalTaxesInfo: 'Информация о налогах снятия средств',
    ranges: 'Диапазоны',
    depositAmountRanges: 'Диапазон суммы депозита (от - до)',
    taxPercentage: 'Процент налога %',
    lTaxes: 'налоги',
    withdrawalAmountRanges: 'Диапазон суммы вывода (от - до)',
    fixedAmountOrPercent: 'Фиксированная сумма или процент налога',
    spotsBookJackpotDescription: 'При включении виджет спортивного джекпота становится доступным на целевой странице',
    spotsBookPrematchTopMatchesDescription: 'При включении виджет Топ матчей становится доступным в Iframe Спортсбука и на целевой странице',
    spotsBookScrollingTypeDescription: 'Настройка применима только для стандартного вида, и когда она включена, спортивная страница полностью прокручивается одной прокруткой',
    spotsBookTournamentBalanceDescription: 'После включения баланс турнира становится доступным на главной странице сайта',
    sportJackpot: 'Спортивный джекпот',
    sectionsScrolling: 'Прокрутка разделов',
    sportTournamentBalance: 'Баланс спортивного турнира',
    iframe: 'Iframe',
    native: 'Нативный',
    sportsBookIframeIntegrationDescription: 'Спортсбук будет доступен в рамках встроенного фрейма',
    sportsBookNativeIntegrationDescription: 'Спортсбук будет доступен непосредственно во встроенном приложении',
    sportsBookMobileIntegrationType: 'Тип интеграции на мобильных устройствах',
    setAsDefault: 'Установить по умолчанию',
    configs: 'Конфигурации',
    availableLayouts: 'Доступные лэйауты',
    setAmountRangesInfo:
      'Если диапазоны установлены как «Фиксированная сумма» и «Процент налога», система расчитает комиссию следующим образом: удержать фиксированную сумму налога + x процентов от суммы, которая превышает сумму, указанную в поле «От» диапазона',
    resetTaxNote: 'Обратите внимание, что после сброса настроек налогов игроки не смогут вносить депозиты в этой валюте.',
    depositAmountRangesHint: 'Сумма в поле «От» в «Диапазоне суммы депозита» включена в диапазон, тогда как сумма в поле «До» в диапазон не входит.',
    withdrawalAmountRangesHint: 'Сумма в поле «От» в «Диапазоне суммы снятия» включена в этот диапазон, тогда как сумма в поле «До» в диапазон не входит.',
    fixedAmountHint:
      'Сумма в поле «Фиксированная сумма» должна быть меньше или равна сумме, введенной в поле «От» в «Диапазоне суммы депозита». Если сумма в поле «От» равна 0, сумма в поле «Фиксированная сумма» также должна быть равна 0.',
    fixedAmount: 'Фиксированная сумма',
    brandSportsBookActivation:
      'Активировав продукт Спортсбук, вы получите доступ к настраиваемому интерфейсу с лайв и прематч событиями для более чем 65 видов спорта. После активации продукт можно деактивировать только из панели администратора Спортсбук.',
    amountFrom: 'Сумма от',
    allowPlayerCompose: 'Разрешить игрокам «составлять» сообщения',
    sportsBookInheritWebsiteColor: 'Использовать цвет веб-сайта',
    sportsBookInheritWebsiteColorDescription: 'Настройка применяется ко всем спортивным страницам.',
    theBrand: 'Бренд',
    taxSettings: 'Налоговые настройки',
    infoUrlHint: 'Этот URL-адрес будет использоваться на веб-сайте для отображения сведений о налогообложении.',
    everyMatrixDescription: 'Модульные решения в iGaming на основе API для реализации смелых идей',
    smarticoAffiliateDescription: 'Нет лучшего способа понять клиентов',
    hotjarDescription: 'Поймите, как пользователи на самом деле воспринимают ваш сайт, не утопая в цифрах.',
    hotjarId: 'ID HotJar',
    googleAnalyticsId: 'ID Google Analytics',
    googleAnalyticsDescription: 'Анализируйте пользовательскую активность на своих веб-сайтах',
    googleSiteVerificationCode: 'Код верификации сайта от Google',
    googleSearchConsoleDescription: 'Получите помощь по отслеживанию, обслуживанию и устранению неполадок, связанных с выдачей вашего веб-сайта в результатах поиска Google.',
    copyPasteFieldName: 'Скопируйте {{fieldName}} и вставьте его в поле ниже.',
    learnMore: 'Узнать больше…',
    affiliateInfo: '{{count}} интеграции не могут быть активны одновременно',
    liveChatDescription: 'Приложение для онлайн-общения, с помощью которого вы сможете общаться с пользователями в чате в режиме реального времени.',
    liveAgentDescription: 'Обеспечьте клиентам безупречное обслуживание',
    intercomDescription: 'Начните выстраивать отличные отношения с клиентами здесь',
    zendeskDescription: 'Общайтесь с клиентами эффективно',
    intercomId: 'ID Intercom',
    zendeskId: 'ID Zendesk',
    liveChatLicenseNumber: 'LiveChat LICENSE_NUMBER',
    liveAgentYourLa: 'LiveAgent YOUR-LA',
    liveAgentButtonID: 'Идентификатор кнопки LiveAgent',
    digitainAgIdInfo: 'ID Бренда в панели администратора «Digitain Agent»',
    depositCurrencyInfo:
      'В случае, если игрокам разрешено вносить депозиты поддерживаемыми способами оплаты во всех валютах , поле «Разрешить конвертацию» с конфигурациями поддерживаемых валют должно быть активно для выбранного способа оплаты.',
    pageGroups: 'Группы страниц',
    digitainRetailInfo: 'ID Бренда в панели администратора «Digitain Retail»',
    smarticoDescription: 'Здесь используется геймификация с различными испытаниями и игровыми приемами для мотивации игроков',
    smarticoLabelKey: 'Ключ лейбла',
    smarticoBrandKey: 'Ключ бренда',
    smarticoBrandKeyInfo: 'Открытый ключ для бренда',
    smarticoLabelKeyInfo: 'Открытый ключ для лейбла',
    smarticoNote: 'Smartico CRM и геймификация взаимосвязаны, каждое действие в этом разделе также влияет на геймификацию.',
    realityCheckPeriod: 'Частота напоминаний о времени, проведённом в игре',
    timeOut: 'Перерыв',
    eachKeyword: 'Каждое ключевое слово',
    seoKeywordsInfo: 'Рекомендуется иметь 3-5 ключевых слов, разделенных запятой.',
    pageDescriptionInfo: 'Рекомендуемая длина — до 1000 символов.',
    pageTitleInfo: 'Рекомендуемая длина — до 200 символов.',
    prematchTopMatches: 'Прематч Топ-матчи',
    prematchTotalTopMatches: 'Прематч Топ-матчи (с учетом всего рынка)',
    spotsBookPrematchTotalTopMatchesDescription: 'При включении виджет Прематч Топ-матчи (с учетом всего рынка) становится доступным',
    livePrematchTopMatches: 'Топ-матчи в прямом эфире и прематче',
    spotsBookLivePrematchTopMatchesDescription: 'При включении виджет Топ-матчи в прямом эфире и прематче становится доступным',
    liveTopMatches: 'Топ-матчи в прямом эфире',
    spotsBookLiveTopMatchesDescription: 'При включении виджет Топ-матчи в прямом эфире становится доступным',
    verificationPeriodTabInfo: 'В этом разделе вы можете настроить конфигурации для автоматической блокировки непроверенных транзакций или учетных записей игроков на настраиваемые периоды времени.',
    periodCalculationSource: 'Источник расчета периода',
    periodCalculationSourceInfo: 'Выбрать источник даты, с которой начнется расчет периода верификации',
    verificationPeriod: 'Период верификации',
    verificationPeriodInfo: 'Количество дней, в течение которых аккаунт игрока может быть активен до верификации',
    unverifiedPlayersExclusionsInfo:
      'Если в меню «Платежи, которые не требуют верификации депозита» выбран способ оплаты, а в разделе «Максимальные суммы депозита» установлены лимиты, лимиты не будут действовать для этого способа оплаты.',
    periodsLimitsInfo: 'В этом разделе вы можете установить определенный период времени, который игрок может выбрать для тайм-аута и самоисключения.',
    preferencesDescription: 'Активация/деактивация SMS, Email и Push-уведомлений для игроков.',
    preferencesSMSDescription: 'SMS-рассылка для всех игроков бренда и на сайте Часть SMS должна стать невидимой',
    preferencesEmailDescription: 'Отправка электронной почты для всех игроков бренда и на сайте Часть электронной почты должна стать невидимой',
    walletsSettings: 'Настройки кошельков',
    wallets: 'Кошельки',
    features: 'Особенности',
    dda: 'DDA',
    ddaInfo: 'При активации этой опции налоги на вывод средств будут удерживаться в логике DDA',
    whatsAppDescription: 'Предоставьте своим клиентам возможность связаться с вами с помощью самого популярного мессенджера в мире.',
    phoneNumber: 'Номер телефона',
    whatsAppPhoneNumberInfo: 'Введите номер телефона, на который зарегистрирован ваш аккаунт WhatsApp',
    multiGameLaunch: 'Запуск нескольких игр',
    multiGameLaunchDescription: 'В зависимости от конфигурации, на сайте доступна опция запуска нескольких игр, и игроки могут играть в несколько игр одновременно.',
    jackpotWidget: 'Виджет джекпота',
    jackpotWidgetDescription: 'Относится к активации виджета Казино джекпот для лендинга. Доступно для виджета Amusnet Provider Jackpot.',
    gameIframe: 'Iframe игры',
    manuBarFixation: 'Исправление панели меню',
    manuBarFixationDescription: 'Только для мобильного просмотра, в зависимости от конфигурации Главная панель фиксирована для лобби.',
    paymentVisibilityInfo: 'В этом разделе вы можете скрыть и не разрешить использование способа оплаты для игроков, имеющих один из выбранных тегов',
    centrivoConnectAgDescription: 'Преобразуйте свой iGaming-бизнес с помощью решения Connect Agent от Centrivo',
    retailCentrivoDescription: 'Преобразуйте свой iGaming-бизнес с помощью Retail Solution от Centrivo',
    currencySymbol: 'Символ валюты',
    preferencesPushNotificationsDescription: 'Канал Push Notifications станет доступен для отправки уведомлений для Бренда',
    currencyFormat: 'Формат валюты',
    currencyFormatInfo: 'Какой формат валюты показывать на сайте для игроков.',
    isoCode: 'Код ISO',
    symbols: 'Символы',
    digitainCrmDescription: 'CRM-решение, предназначенное для поддержания вовлеченности существующих игроков и повышения их общего довольства',
    accessDecreaseLimit: 'Ограничение доступа',
    depositOnNegativeBalance: 'Депозит при отрицательном балансе',
    depositOnNegativeBalanceInfo: 'После одобрения депозита будет произведена корректировка отрицательного кошелька игрока для закрытия долга.',
    digitainAffiliateDescription: 'Активируйте партнерскую программу и ощутите значительный рост посещаемости вашего сайта.',
    projectId: 'ID проекта',
    projectIdInfo: 'Это ID Проекта в панели администратора «Digitain Affiliate»',
    brandIdInfo: 'Это ID Бренда в панели администратора «Digitain Affiliate».',
    maintenanceSetup: 'Настройка технического обслуживания',
    blockAction: 'Блокировка действия',
    sportsBookViews: 'Просмотры Спортсбука',
    mandatoryNotification: 'Обязательное уведомление',
    mandatoryNotificationInfo: 'Обязательное всплывающее окно для игроков, которые зашли на сайт',
    lossDecreaseLimit: 'Лимит потерь',
    inactivityIndicators: 'Индикаторы неактивности',
    inactivityIndicatorsInfo:
      'Данная конфигурация будет считать игрока неактивным на основании одного из нескольких показателей, даты последнего входа в систему или даты последних транзакций (ставка, депозит, вывод средств)',
    recoverByPlayer: 'Восстановление игроком',
    recoverByPlayerInfo: 'Если эта функция включена, игроки могут самостоятельно восстановить свои данные с помощью верификационного кода',
    singleBetDecreaseLimit: 'Лимит одиночной ставки',
    cashierType: 'Тип кассира',
    lIcon: 'иконка',
    gameLaunchType: 'Тип запуска игры (мобильный вид)',
    gameFullScreenLaunchDescription: 'Игра открывается с помощью iframe в полноэкранном режиме',
    gameHeaderFooterLaunchDescription: 'Игры открываются с iframe, заголовком и нижним колонтитулом',
    gameQuickActionBarLaunchDescription: 'Игры открываются с помощью панели быстрых действий',
    limitEffectConfigs: 'Конфигурации предельных эффектов',
    ignoreFirstDeposit: 'Игнорировать первый депозит',
    ignoreFirstDepositHint: 'Если выбрана эта опция, первый в жизни игрока успешный депозит не будет учитываться в лимите ответственного игрового депозита',
    periodLimit: 'Лимит по периоду',
    playerLimitsConfiguration: 'Настройка лимитов игроков',
    alanbaseDescription: 'Платформа, созданная экспертами в области iGaming и партнерского маркетинга, помогающая другим экспертам оптимизировать свои расходы и увеличить прибыль.',
    routForEvents: 'Маршрут для мероприятий',
    routForGoals: 'Маршрут для целей',
    fastDepositPaymentMethod: 'Быстрый способ оплаты депозита',
    fastDepositPaymentMethodInfo:
      'Быстрый депозит — это функция на сайте, с помощью которой игроки могут сделать депозит, не покидая страницу игры, с помощью платежного метода, предопределенного от Centrivo.',
    sportStatusIconsInfo: 'Загруженные иконки будут видны в профиле игрока на сайте. Каждая загруженная иконка будет отображаться на сайте не более чем в течение часа.',
    rolloverBetsReturn: 'Ставки на ролловер возвращаются в тот же кошелек',
    rolloverBetsReturnInfo: 'В случаях, когда ставка, сделанная с баланса ролловера, возвращается/отменяется, сумма будет переведена на баланс ролловера',
    maxDepositAmounts: 'Максимальные суммы депозитов',
    paymentsIgnoringDepositVerification: 'Платежи, не требующие верификации депозита',
    selfExclusion: 'Самоисключение',
  },
  pt: {
    mainCurrency: 'Moeda Principal',
    mainLanguage: 'Idioma Principal',
    market: 'Mercado',
    setMainCountry: 'Definir País Principal',
    setMainCurrency: 'Definir Moeda Principal',
    setMainLanguage: 'Definir Idioma Principal',
    domainName: 'Nome do Domínio',
    redirectedTo: 'Redirecionado para',
    nameServer1: 'Name Server 1',
    nameServer2: 'Name Server 2',
    verifyDomain: 'Verificar domínio',
    onlyVerifiedDomainInfo: 'Somente domínios verificados podem ser conectados',
    disconnectDomain: 'Desconectar Domínio',
    connectDomain: 'Conectar Domínio',
    deleteDomain: 'Excluir Domínio',
    deleteScript: 'Excluir Script',
    deactivateScript: 'Desativar Script',
    activateScript: 'Ativar Script',
    supportedCurrencies: 'Moedas Compatíveis',
    supportedCountries: 'Países Compatíveis',
    supportedLanguages: 'Idiomas Compatíveis',
    providerDisableInfo: 'O fornecedor pode ter sido desativado para todo o sistema',
    activity: 'Atividade',
    verificationAnd: 'Verificação (e)',
    verificationOr: 'Verificação (ou)',
    andDelimiter: 'e',
    currencyFrom: 'Moeda de',
    currencyTo: 'Moeda para',
    reverseRate: 'Taxa Reversa',
    feature: 'Destaque',
    otherTransactions: 'Outras Transações',
    limitType: 'Limitar Tipo',
    playersNotifiedDate: 'Data Notificada',
    depositProcessingType: 'Tipo de Processamento de Depósito',
    withdrawalProcessingType: 'Tipo de Processamento de Saque',
    allowedActivities: 'Atividades Permitidas',
    depositSupportedCurrencies: 'Moedas Compatíveis para Depósito',
    withdrawalSupportedCurrencies: 'Moedas Compatíveis para Saque',
    newFee: 'Nova Taxa, %',
    feeBefore: 'Taxa Anterior, %',
    newLimit: 'Novo Limite',
    statusDisabledInfo: 'O site ficará indisponível em todos os domínios.',
    statusActivitiesDisabledInfo: 'Não serão aceitas novas apostas.',
    statusVersionNamesDisabledInfo: 'As apostas serão desativadas para as versões selecionadas.',
    timezoneInfo: 'Qualquer cálculo em andamento relacionado com o fuso horário será alterado.',
    domainInfo: {
      text1: 'Faça o login em seu provedor de domínio',
      text2: 'O seu provedor de domínio é a empresa onde você o comprou o domínio (por exemplo, GoDaddy, eNom, 1and1).',
      text3: 'Atualize seus nameservers do domínio',
      text4: 'Vá até as configurações DNS/Nameservers do seu domínio.',
      text5: 'Substitua todos os seus nameservers atuais pelos nameservers personalizados mostrados na tabela de cada domínio',
      text6: 'Verifique a conexão do seu domínio',
      text7: 'Verifique se as configurações do seu domínio foram atualizadas.',
      text8: 'Os Registrars podem levar 24 horas para processar as atualizações do nameserver.',
    },
    domainRedirection: 'Redirecionamento de Domínio',
    uVerify: 'VERIFICAR',
    redirect: 'Redirecionar',
    forwardingURL: 'URL de Encaminhamento',
    destinationURL: 'URL de Destino',
    domainRedirectInfo: 'O domínio que é usado como redirecionamento ou URL de destino não pode ser excluído sem a eliminação da regra existente.',
    registerDomainInfo: 'Você precisa registrar todos os seus domínios em sua conta google para habilitar o captcha para todos eles.',
    ageRestriction: 'Restrições de Idade',
    failedLogonAttempts: 'Tentativas de Logon Fracassadas',
    passwordPattern: 'Padrão de Senha',
    failedAttemptsPeriod: 'Período de Tentativas Fracassadas',
    lockPeriod: 'Período de Bloqueio',
    passwordReminder: 'Lembrete de Senha',
    inactivityPeriod: 'Período de Inatividade',
    securityPeriod: 'Período de Verificação de Segurança',
    referralActivationPeriod: 'Período de Ativação da Indicação',
    passwordPatternNote:
      'Recomenda-se que as senhas tenham no mínimo 8 caracteres, incluindo pelo menos 1 número e 1 letra maiúscula. Entretanto, você pode fragilizá-la até ter de 6 a 20 letras latinas, números ou caracteres especiais.',
    referralActivationPeriodNote: 'Período durante o qual você deseja manter as informações de identificação do indicado para um novo registro no site.',
    playerVerification: 'Configurações de Verificação do Jogador',
    verificationConf: 'Configuração de Verificação',
    correspondingTranslations: 'Certifique-se de que todas as traduções estejam correspondentemente atualizadas.',
    verificationCodeLength: 'Tamanho do Código de Verificação',
    dailyMobileVerification: 'Pedidos Diários de Verificação pelo Celular',
    emailRequestsInDaily: 'Pedidos Diários de Verificação por E-mail',
    expirationMinutes: 'Período de Expiração do Token',
    expirationMinutesNote: 'Config define o período após o qual o token (código, link) na notificação se tornará inválido.',
    emailRequestsInDailyNote: 'Config define quantos pedidos de verificação por e-mail podem ser recebidos em um dia. O valor mínimo é de {{value}}.',
    dailyMobileVerificationNote: 'Config define quantos pedidos de verificação por SMS o jogador pode receber em um dia. O valor mínimo é de {{value}}.',
    identityDocument: 'Documento de Identidade',
    addressDocument: 'Documento de Endereço',
    additionalDocument: 'Documento Adicional',
    disableSportBook: 'Desativar {{name}}',
    gameDisableTooltip: 'O jogo pode ter sido desativado para todo o sistema',
    playerLimitInfo: {
      text1: 'Você pode definir um período de redução da severidade do limite para cada tipo de limite.',
    },
    definiteSelfExclusionDecreaseLimit: 'Autoexclusão Definida',
    indefiniteSelfExclusionDecreaseLimit: 'Autoexclusão Indefinida',
    depositDecreaseLimit: 'Limite de Depósito',
    betDecreaseLimit: 'Limite de Aposta',
    withdrawalDecreaseLimit: 'Limite de Saque',
    activateSportsbook: 'Ativar o Sportsbook',
    customScript: {
      text1: 'Você pode integrar novas ou adicionar diferentes aplicações',
      text2: 'funcionalidades em seu site, adicionando scripts personalizados.',
    },
    scriptName: 'Nome do Script',
    insideHead: 'Antes e dentro da tag de fechamento </head>',
    insideBody: 'Antes e dentro da tag de fechamento </body>',
    headTag: 'script da tag head',
    bodyTag: 'script da tag body',
    service: 'serviço',
    integrate: 'Integrar',
    showOnLive: 'Mostrar no Site Ao Vivo',
    showOnLiveTitle: 'Quando você desligar este interruptor, o ícone do serviço terceirizado será ocultado da página inicial do seu site.',
    apiSecret: 'API Secret',
    apiSecretOrPass: 'API Secret ou senha',
    apiKeyOrUsername: 'API Key ou nome de usuário',
    sendEmailVia: 'Enviar E-mail Via',
    smtpUser: 'Usuário SMTP',
    smtpPass: 'Senha SMTP',
    useSSL: 'Use SSL',
    smtpHost: 'SMTP Host',
    smtpPort: 'SMTP Port',
    emailsInfo: {
      text1: 'Para alguns provedores SMTP, é necessário que o mesmo e-mail seja registrado do seu lado.',
      text2: 'Por favor, certifique-se de que a exigência seja atendida.',
    },
    emailsInfo1: 'Todas as mensagens programadas com este e-mail serão movidas para o rascunho',
    blockCountries: 'Bloquear Países',
    blockCountriesMainText: 'O site estará indisponível nos países selecionados',
    blockIps: 'Bloquear IPs',
    versionControl: 'Controle de Versão',
    policies: 'Políticas',
    policy: 'Política',
    dateToNotify: 'Data para Notificar',
    featureName: 'Nome do Recurso',
    noBrandData: 'Sem Marcas a Mostrar',
    createBrand: 'Criar Marca',
    builder: 'Construtor',
    platformState: 'Estado da Plataforma',
    minMaxWithdrawalAmount: 'Sistema Min-Max Valor de saque',
    minWithdrawalAmount: 'Valor Mín. de Saque',
    maxWithdrawalAmount: 'Valor Máx. de Saque',
    minMaxDepositAmount: 'Valor Mín-Máx do Depósito do Sistema',
    minDepositAmount: 'Valor Mín. de Depósito',
    maxDepositAmount: 'Valor Máx. de Depósito',
    operationFeePercent: 'Taxa de Operação, %',
    withdrawalFeePercent: 'Taxa de Saque, %',
    withdrawalFee: 'Taxa de Saque',
    withdrawalFeeForUsedBalancePercent: 'Taxa de Saque para o Saldo Utilizado, %',
    withdrawalFeeForUsedBalance: 'Taxa de Saque por Saldo Utilizado',
    allowedConversion: 'Conversão Permitida',
    paymentMethodName: 'Nome da forma de pagamento',
    minAmount: 'Valor Mín. de {{title}}',
    maxAmount: 'Valor Máx. de {{title}}',
    minMaxSystemAmount: 'Sistema de Valor Mín-Máx de {{title}}',
    depositFeePercent: 'Taxa de Depósito, %',
    depositFee: 'Taxa de Depósito',
    allowConversion: 'Permitir Conversão',
    previewLive: 'Visualização Ao Vivo',
    feeFromDeposit: 'Taxa de Depósito',
    allowWithdrawalFromUnusedBalance: 'Permitir Saque do Saldo Não Utilizado',
    allowWithdrawalFeeUsedBalance: 'A taxa de Saque será retida do saldo restante.',
    feeCalculationForWithdrawals: 'Cálculo de Taxas de Saques',
    feeFromWithdrawals: 'Taxa de Saque',
    feeFromWithdrawalsUsedBalance: 'Taxa de Saques (Saldo Utilizado)',
    feeFromWithdrawalsBalance: 'Taxa de Saques de Saldo ({{type}})',
    unusedBalanceInfo: "Quando ativada, a opção de saldo 'Não utilizado' aparecerá na configuração 'Taxa de Saque'.",
    paymentTransactionType: 'Tipo de Transação de Pagamento',
    paymentMethodType: 'Tipo de Forma de Pagamento',
    playerPaymentRelation: 'Relação Jogador-Pagador',
    failedPaymentAttempts: 'Tentativas de Pagamento Fracassadas',
    failedPaymentAttemptsPeriod: 'Período de Tentativas de Pagamento Fracassadas',
    temporaryBlockPeriod: 'Período de Bloqueio Temporário',
    reverseConversion: 'Conversão Reversa {{title}}',
    lCustomScript: 'script personalizado',
    largeTransactions: 'Grandes Transações',
    lBrand: 'marca',
    playerSettings: 'Configurações do jogador',
    verificationConfigurations: 'Configurações de verificação',
    theCurrency: 'A moeda',
    mainCountry: 'País Principal',
    theLanguage: 'O idioma',
    theDomain: 'O domínio',
    smsSettings: 'Configurações SMS',
    verificationSettings: 'Configurações de verificação',
    smtpSettings: 'Configurações SMTP',
    captchaSettings: 'Configurações do Captcha',
    theEmail: 'O e-mail',
    conversions: 'Conversões',
    theConversion: 'A conversão',
    theCountry: 'O país',
    playerVerificationSettings: 'Configurações de verificação do jogador',
    domainRedirectionRule: 'Regra de redirecionamento de domínio',
    wageringActivities: 'Atividades de apostas',
    theSportsBook: 'O Sportsbook',
    sportsBook: 'Sportsbook',
    theSportsBookView: 'A visão do Sportsbook',
    setMainView: 'Definir Visão Principal',
    feeForWithdrawalsWillBeHeld: 'A taxa de Saque será retida do saldo restante.',
    brandName: 'Nome da Marca',
    defaultCountry: 'País Padrão',
    defaultLanguage: 'Idioma Padrão',
    defaultCurrency: 'Moeda Padrão',
    rgsActivation:
      'Com a ativação do produto RGS você terá acesso a um portfólio abrangente de até 5.000 jogos de fornecedores mundialmente conhecidos que oferecem a melhor experiência de jogos para seus jogadores.',
    sportsBookActivation:
      'Pela ativação do produto Sportsbook você terá acesso a uma interface abrangente, multilíngue, com múltiplas moedas e personalizável, com eventos ao vivo e pré-jogo para mais de 65 esportes, 7.500 ligas em todo o mundo e mais de 3.000 mercados de apostas.',
    products: 'Produtos',
    websiteTemplate: 'Modelo de Site',
    failedPaymentInfo: 'As configurações não estão disponíveis devido à inatividade do padrão "Procedimento de Pagamentos Malsucedidos". Por favor, ative-a a partir da seção "Normas".',
    depositWithdrawalPaymentMethodInfo:
      'Os métodos de pagamento que utilizam conversão serão afetados em caso de mudanças e os limites de mín/máx personalizados poderão ser redefinidos pelo sistema se contradizerem novas mudanças. Certifique-se de rever os limites e a disponibilidade de moedas nas configurações dos métodos de pagamento.',
    otherPaymentMethodInfo:
      'As funcionalidades internas que utilizam a conversão utilizarão essas taxas de conversão. Assegure-se de ter todas as conversões em vigor (por exemplo, Bônus de Indicação).',
    conversionSettingsInfo: 'O limite atual contradiz o limite sistema/sistema-convertido.',
    keywords: 'Palavras-chave',
    card: 'Carta',
    siteTwitterUsername: 'Site (Twitter @username)',
    imageAlt: 'Alt de Imagem',
    recommendedSize: 'Tamanho recomendado',
    stateOrRegion: 'Estado/Região',
    socialLinks: 'Links Sociais',
    socialLinkInfo: 'As páginas do perfil da empresa em diferentes meios, tais como Facebook, Twitter, Google+, Instagram, Youtube, LinkedIn, Wikipedia, etc. Você pode adicionar quantas URLs quiser.',
    image: 'Imagem',
    depositPaymentCategory: 'Categoria de Pagamento do Depósito',
    withdrawalPaymentCategory: 'Categoria de Pagamento de Saque',
    createNewBrand: 'Criar Nova Marca',
    lConversion: 'conversão',
    allowWithdrawalViaTheDepositedMethods: 'Permitir o saque somente através dos métodos depositados',
    allowWithdrawalViaTheDepositedMethodsInfo: 'A ativação das configurações restringe o jogador de retirar dinheiro através dos métodos que ele nunca depositou antes',
    logInAllowed: 'Acesso permitido',
    logInAllowedNote: 'Quando ativado, os jogadores podem apenas fazer login e fazer levantamentos',
    schemaMarkupSettings: 'Configurações de Schema Markup',
    seoSettings: 'Configurações SEO',
    openGraphSettings: 'Configurações Open Graph',
    twitterSettings: 'Configurações do Twitter',
    accountId: 'ID da conta',
    accountIdInfo: 'Qualquer nome ou código de identificação associado ao ID da conta de serviço que fornece um nível específico de acesso',
    depositTaxesInfo: 'Se nenhuma configuração de imposto for definida para uma moeda, o jogador não poderá fazer depósitos por meio dessa moeda.',
    withdrawalTaxesInfo: 'Se nenhuma configuração de imposto for definida para uma moeda, o jogador não poderá fazer saques por meio dessa moeda.',
    ranges: 'Intervalo',
    depositAmountRanges: 'Intervalo de valores de depósito (de - até)',
    taxPercentage: 'Porcentagem de imposto %',
    lTaxes: 'impostos',
    withdrawalAmountRanges: 'Faixa de valor de saque (de - até)',
    fixedAmountOrPercent: 'Valor Fixo ou Porcentagem de Imposto',
    spotsBookJackpotDescription: 'Quando ativado, o Widget do Sports Jackpot fica disponível na página inicial',
    spotsBookScrollingTypeDescription: 'A configuração é aplicável apenas para visualização padrão e, quando ativada, a página de esportes pode ser totalmente rolada com uma rolagem',
    spotsBookTournamentBalanceDescription: 'Quando ativado, o saldo do torneio fica disponível na página inicial do site',
    sportJackpot: 'Jackpot esportivo',
    sectionsScrolling: 'Rolagem de seções',
    sportTournamentBalance: 'Saldo do Torneio Desportivo',
    iframe: 'Iframe',
    native: 'Nativo',
    sportsBookIframeIntegrationDescription: 'O sportsbook estará disponível em um inline frame',
    sportsBookNativeIntegrationDescription: 'O sportsbook estará disponível diretamente em um aplicativo integrado',
    sportsBookMobileIntegrationType: 'Tipo de integração no celular',
    setAsDefault: 'Definir como padrão',
    configs: 'Configs',
    availableLayouts: 'Layouts Disponíveis',
    setAmountRangesInfo:
      "Se, para um intervalo, 'Valor fixo' e 'Porcentagem de imposto' forem definidos, o sistema deve calcular o imposto da seguinte maneira: reter um valor de imposto fixo + a porcentagem x do valor que excede o valor definido em 'De' campo do intervalo.",
    resetTaxNote: 'Observe que, após redefinir as configurações de impostos, os jogadores não poderão fazer depósitos por meio dessa moeda.',
    depositAmountRangesHint: "O valor no campo 'De' do 'Intervalo de Valor do Depósito' está incluído nesse intervalo, enquanto o valor no campo 'Para' não está incluído no intervalo.",
    withdrawalAmountRangesHint: "O valor no campo 'De' do 'Intervalo de Valor de Saque' está incluído nesse intervalo, enquanto o valor no campo 'Para' não está incluído no intervalo.",
    fixedAmountHint:
      "O valor no campo 'Valor Fixo' deve ser menor ou igual ao valor inserido no campo 'De' do 'Intervalo de Valor do Depósito'. Se o valor 'De' for 0, o valor no campo 'Valor Fixo' também deverá ser 0.",
    fixedAmount: 'Valor Fixo',
    brandSportsBookActivation:
      'Ao ativar o produto Sportsbook, você terá acesso a uma interface personalizável com eventos ao vivo e pré-jogo para mais de 65 esportes. Quando ativado, o produto só pode ser desativado no painel de administração do Sportsbook.',
    amountFrom: 'Valor de',
    allowPlayerCompose: 'Permitir que os jogadores "escrevam" mensagens',
    sportsBookInheritWebsiteColor: 'Herdar cor do site',
    sportsBookInheritWebsiteColorDescription: 'A configuração se aplica a todas as páginas de esportes.',
    theBrand: 'Marca',
    taxSettings: 'Configurações de impostos',
    infoUrlHint: 'Este URL será usado no site para exibir detalhes de tributação.',
    everyMatrixDescription: 'Soluções iGaming modulares e orientadas por API projetadas para desencadear ideias ousadas',
    smarticoAffiliateDescription: 'Não há melhor maneira de se conectar com seus clientes',
    hotjarDescription: 'Entenda como os usuários estão realmente experimentando seu site sem se afogar em números',
    hotjarId: 'ID HotJar',
    googleAnalyticsId: 'ID do Google Analytics',
    googleAnalyticsDescription: 'Analise o uso em seus sites',
    googleSiteVerificationCode: 'Código de verificação do site do Google',
    googleSearchConsoleDescription: 'Obtenha ajuda para monitorar, manter e solucionar problemas de presença do seu site nos resultados de pesquisa do Google.',
    copyPasteFieldName: 'Copie seu {{fieldName}} e cole-o no campo abaixo.',
    learnMore: 'Saber mais…',
    affiliateInfo: '{{count}} integrações não podem estar ativas simultaneamente',
    liveChatDescription: 'Aplicação de comunicação online que permite que você e os visitantes do seu site conversem em tempo real',
    liveAgentDescription: 'Fornecer excelente atendimento ao cliente',
    intercomDescription: 'Comece um ótimo relacionamento com o cliente aqui',
    zendeskDescription: 'Comunique-se com os clientes de forma mais eficiente',
    intercomId: 'Intercom ID',
    zendeskId: 'Zendesk ID',
    liveChatLicenseNumber: 'LiveChat LICENSE_NUMBER',
    liveAgentYourLa: 'LiveAgent YOUR-LA',
    liveAgentButtonID: 'ID do botão LiveAgent',
    digitainAgIdInfo: 'Este é o ID da marca no painel de administração "Digitain Agent"',
    depositCurrencyInfo:
      'Caso os jogadores tenham permissão para fazer depósitos com todas as moedas suportadas pelos métodos de pagamento, "Permitir conversão" com as configurações de moeda suportadas deve estar ativo para o método de pagamento.',
    pageGroups: 'Grupos de Páginas',
    digitainRetailInfo: "Este é o ID da marca no painel de administração 'Digitain Retail'",
    smarticoDescription: 'A solução de gamificação que usa desafios e técnicas de jogo para motivar os jogadores',
    smarticoLabelKey: 'Chave de etiqueta',
    smarticoBrandKey: 'Chave da Marca',
    smarticoBrandKeyInfo: 'Uma chave pública para a marca',
    smarticoLabelKeyInfo: 'Uma chave pública para a etiqueta',
    smarticoNote: 'O Smartico CRM e o Gamification estão interligados, cada ação nesta seção também tem efeito no Gamification',
    realityCheckPeriod: 'Período de Verificação da Realidade',
    timeOut: 'Pausa',
    eachKeyword: 'Cada palavra-chave',
    seoKeywordsInfo: 'Recomenda-se que tenha 3-5 palavras-chave, separadas por uma vírgula.',
    pageDescriptionInfo: 'O comprimento recomendado é de até 1000 caracteres.',
    pageTitleInfo: 'O comprimento recomendado é de até 200 caracteres.',
    prematchTotalTopMatches: 'Principais partidas pré-jogo (mercado total incluído)',
    spotsBookPrematchTotalTopMatchesDescription: 'Quando ativado, fica disponível o widget de Melhores Jogos Prematch com dados do Mercado Total',
    livePrematchTopMatches: 'Jogos principais ao vivo e pré-lançamento',
    spotsBookLivePrematchTopMatchesDescription: 'Quando ativado, o widget Jogos principais em direto e pré-jogo fica disponível',
    liveTopMatches: 'Jogos principais em direto',
    spotsBookLiveTopMatchesDescription: 'Quando ativado, o widget Jogos principais em direto fica disponível',
    verificationPeriodTabInfo: 'Nesta secção, pode definir configurações para bloquear automaticamente transacções ou contas de jogadores não verificados durante períodos personalizáveis.',
    periodCalculationSource: 'Fonte de cálculo do período',
    periodCalculationSourceInfo: 'Selecionar a fonte de data na qual o cálculo do período de verificação irá começar',
    verificationPeriod: 'Período de verificação',
    verificationPeriodInfo: 'O número de dias que uma conta de jogador pode estar ativa antes da verificação',
    unverifiedPlayersExclusionsInfo:
      "Nos casos em que um método de pagamento é selecionado no menu 'Pagamentos que ignoram a verificação de depósitos' e os limites são definidos na parte 'Montantes máximos de depósito', os limites não afectarão esse método de pagamento.",
    periodsLimitsInfo: 'Nesta secção, pode definir um período de tempo específico que um jogador pode escolher para o tempo limite e a auto-exclusão.',
    preferencesDescription: 'Ativar/desativar as notificações por SMS, e-mail e push para os jogadores.',
    preferencesSMSDescription: 'Envio de SMS para todos os jogadores da marca e no site Via SMS a parte deve ficar invisível',
    preferencesEmailDescription: 'Envio de e-mail para todos os jogadores da marca e no site Via E-mail a parte deve ficar invisível',
    walletsSettings: 'Definições das Carteiras',
    wallets: 'Carteiras',
    features: 'Recursos',
    dda: 'DDA',
    ddaInfo: 'Activando esta opção os impostos sobre o levantamento serão retidos na lógica do DDA',
    whatsAppDescription: 'Permita que os seus clientes o contactem utilizando o mensageiro mais popular do mundo.',
    phoneNumber: 'Número de telefone',
    whatsAppPhoneNumberInfo: 'Introduza o número de telefone com o qual a sua conta WhatsApp está registada',
    multiGameLaunch: 'Lançamento de multi-jogos',
    multiGameLaunchDescription: 'Dependendo da configuração, a opção multi-jogo está disponível no sítio Web e os jogadores podem jogar vários jogos simultaneamente.',
    jackpotWidget: 'Widget do Jackpot',
    jackpotWidgetDescription: 'Refere-se à ativação do Widget do Jackpot do Casino para a página de destino. Disponível para o widget de jackpot do fornecedor Amusnet.',
    gameIframe: 'Iframe do Jogo',
    manuBarFixation: 'Fixação da barra de menus',
    manuBarFixationDescription: 'Apenas para a vista móvel, dependendo da configuração, a barra principal é fixada para os lobbies.',
    paymentVisibilityInfo: 'Nesta secção, pode ocultar e não permitir a utilização do método de pagamento para jogadores que tenham uma das etiquetas seleccionadas',
    centrivoConnectAgDescription: 'Transforme o seu negócio iGaming com a solução Connect Agent da Centrivo',
    retailCentrivoDescription: 'Transforme o seu negócio de iGaming com a solução de retail da Centrivo',
    currencySymbol: 'Símbolo da moeda',
    preferencesPushNotificationsDescription: 'O canal de Notificações Push ficará disponível para o envio de notificações para a Marca',
    currencyFormat: 'Formato da moeda',
    currencyFormatInfo: 'Qual o formato da moeda a apresentar no sítio Web para os jogadores.',
    isoCode: 'Código ISO',
    symbols: 'Símbolos',
    digitainCrmDescription: 'Solução CRM concebida para manter o envolvimento dos jogadores existentes e aumentar a sua satisfação geral',
    accessDecreaseLimit: 'Limite de acesso',
    depositOnNegativeBalance: 'Depósito em Saldo Negativo',
    depositOnNegativeBalanceInfo: 'Após a aprovação do depósito, o ajuste será efectuado na carteira negativa do jogador para fechar a dívida.',
    digitainAffiliateDescription: 'Active o seu programa de afiliados e experimente um aumento significativo no tráfego do seu site.',
    projectId: 'ID do Projeto',
    projectIdInfo: 'Este é o ID do projeto no painel de administração "Digitain Affiliate".',
    brandIdInfo: 'Este é o ID da marca no painel de administração "Digitain Affiliate".',
    maintenanceSetup: 'Configuração da manutenção',
    blockAction: 'Ação de bloqueio',
    maintenancePeriod: 'Período de manutenção',
    sportsBookViews: 'Visualizações do Sportsbook',
    mandatoryNotification: 'Notificação obrigatória',
    mandatoryNotificationInfo: 'Um pop-up obrigatório para jogadores com sessão iniciada no site',
    lossDecreaseLimit: 'Limite de perdas',
    inactivityIndicators: 'Indicadores de inatividade',
    inactivityIndicatorsInfo:
      'Esta configuração irá considerar um jogador inativo com base num de vários indicadores, a data do último início de sessão do jogador ou a data das últimas transacções (aposta, depósito, levantamento)',
    recoverByPlayer: 'Recuperar por jogador',
    recoverByPlayerInfo: 'Quando ativado, os jogadores podem recuperar-se a si próprios através do código de verificação',
    singleBetDecreaseLimit: 'Limite de Aposta Única',
    cashierType: 'Tipo de Caixa',
    lIcon: 'ícone',
    gameLaunchType: 'Tipo de lançamento do jogo (vista móvel)',
    gameFullScreenLaunchDescription: 'Jogo aberto com iframe em ecrã inteiro',
    gameHeaderFooterLaunchDescription: 'Jogos abertos com iframe e cabeçalho e rodapé',
    gameQuickActionBarLaunchDescription: 'Jogos abertos com barra de acções rápidas',
    limitEffectConfigs: 'Configurações de efeito limite',
    ignoreFirstDeposit: 'Ignorar Primeiro Depósito',
    ignoreFirstDepositHint: 'Se esta opção for selecionada, o primeiro depósito bem sucedido do jogador não será calculado no limite de depósito de jogo responsável',
    periodLimit: 'Limite do período',
    playerLimitsConfiguration: 'Configuração dos Limites do Jogador',
    alanbaseDescription: 'Uma plataforma trazida até si por especialistas em iGaming e marketing de afiliados, ajudando outros especialistas a otimizar os seus custos e a aumentar os seus lucros.',
    routForEvents: 'Rout for Events',
    routForGoals: 'Rout for Goals',
    fastDepositPaymentMethod: 'Fast Deposit Payment Method',
    fastDepositPaymentMethodInfo:
      "Fast Deposit is a functionality on the website through which the players are able to make a deposit without leaving the game page by the payment method predefined from 'Centrivo'.",
    sportStatusIconsInfo: 'Os ícones carregados serão visíveis no perfil do jogador no sítio Web. Cada ícone carregado será refletido no sítio Web após, no máximo, uma hora.',
    rolloverBetsReturn: 'As apostas de rollover regressam à mesma carteira',
    rolloverBetsReturnInfo: 'Nos casos em que a aposta feita a partir do saldo de rollover é devolvida/cancelada, o montante será transferido para o saldo de rollover',
    maxDepositAmounts: 'Montantes máximos de depósito',
    paymentsIgnoringDepositVerification: 'Pagamentos que ignoram a verificação de depósitos',
    selfExclusion: 'Auto-exclusão',
  },
  zh: {
    mainCurrency: '主要货币',
    mainLanguage: '主要语言',
    market: '市场',
    setMainCountry: '设置主要国家',
    setMainCurrency: '设置主要货币',
    setMainLanguage: '设置主要语言',
    domainName: '域名',
    redirectedTo: '重新定向至',
    nameServer1: '第1个服务器的名称',
    nameServer2: '第2个服务器的名称',
    verifyDomain: '验证域名',
    onlyVerifiedDomainInfo: '只可连接经验证的域名',
    disconnectDomain: '断开域',
    connectDomain: '连接域',
    deleteDomain: '删除域',
    deleteScript: '删除脚本',
    deactivateScript: '停用脚本',
    activateScript: '激活脚本',
    supportedCurrencies: '支持的货币',
    supportedCountries: '支持的国家',
    supportedLanguages: '支持的语言',
    providerDisableInfo: '供应商可能已被整个系统禁用',
    activity: '活动',
    verificationAnd: '验证（和）',
    verificationOr: '验证（或）',
    andDelimiter: '和',
    currencyFrom: '货币从',
    currencyTo: '货币至',
    reverseRate: '逆向速率',
    feature: '特点',
    otherTransactions: '其他交易',
    limitType: '限制类型',
    playersNotifiedDate: '通知日',
    depositProcessingType: '存款处理类型',
    withdrawalProcessingType: '取款处理类型',
    allowedActivities: '允许活动',
    depositSupportedCurrencies: '支持的存款货币',
    withdrawalSupportedCurrencies: '支持的取款货币',
    newFee: '新的费用，％',
    feeBefore: '收费前，%',
    newLimit: '新限制',
    statusDisabledInfo: '网站将无法从所有域中获得。',
    statusActivitiesDisabledInfo: '不接受新的投注。',
    statusVersionNamesDisabledInfo: '选定的版本将禁用投注。',
    timezoneInfo: '任何正在进行的与时区有关的计算将被改变。',
    domainInfo: {
      text1: '登录至您的域名提供商',
      text2: '您的域名供应商是您购买域名的公司（例如，GoDaddy、eNom、1and1）。',
      text3: '更新您的域名服务器',
      text4: '转到您的域名DNS/域名服务器设置。',
      text5: '使用表格中显示的每个域名的自定义域名服务器替换您当前的所有域名服务器',
      text6: '请验证您的域名连接',
      text7: '请检查您的域名设置在之前是否已更新。',
      text8: '注册者可能需要24小时来处理域名服务器更新。',
    },
    domainRedirection: '域名重定向',
    uVerify: '验证',
    redirect: '重新定向',
    forwardingURL: '转发URL',
    destinationURL: '目标URL',
    domainRedirectInfo: '在不删除现有规则的情况下，不能删除用作转发或目标URL的域名。',
    registerDomainInfo: '您需要在您的谷歌账户中注册所有的域名，以便为所有的域名启用验证码。',
    ageRestriction: '年龄限制',
    failedLogonAttempts: '尝试登录失败',
    passwordPattern: '密码模式',
    failedAttemptsPeriod: '尝试失败时期',
    lockPeriod: '封锁期',
    passwordReminder: '密码提醒',
    inactivityPeriod: '不活动期',
    securityPeriod: '安全检查期',
    referralActivationPeriod: '推荐人激活期',
    passwordPatternNote: '我们建议密码至少有8个字符，包括至少一个数字和一个大写字母。然而，您可以将其弱化为拥有6-20个拉丁字母、数字或特殊字符。',
    referralActivationPeriodNote: '您希望保留推荐人的ID信息以便在网站上进行新的注册周期。',
    playerVerification: '玩家验证设置',
    verificationConf: '验证配置',
    correspondingTranslations: '确保所有的翻译内容都得到相应的更新。',
    verificationCodeLength: '验证码长度',
    dailyMobileVerification: '每日手机验证请求',
    emailRequestsInDaily: '每日电子邮箱验证请求',
    expirationMinutes: '代币失效期',
    expirationMinutesNote: '配置定义了通知中的标记（代码、链接）在一段时间后将变得无效。',
    emailRequestsInDailyNote: '配置定义了玩家一天内可以收到多少封要求验证的电子邮件。最小值为 {{value}}。',
    dailyMobileVerificationNote: '配置定义了玩家一天内可以收到多少条要求验证的短信。最小值为 {{value}}。',
    identityDocument: '身份证明文件',
    addressDocument: '地址文件',
    additionalDocument: '附加文件',
    disableSportBook: '禁用 {{name}}',
    gameDisableTooltip: '游戏可能已在整个系统中被禁用',
    playerLimitInfo: {
      text1: '您可以为每一种限制类型设置限制严重程度下降期。',
    },
    definiteSelfExclusionDecreaseLimit: '明确的自我排除',
    indefiniteSelfExclusionDecreaseLimit: '无限期自我禁制',
    depositDecreaseLimit: '存款限制',
    betDecreaseLimit: '投注限制',
    withdrawalDecreaseLimit: '取款限制',
    activateSportsbook: '激活体育博彩',
    customScript: {
      text1: '您可以通过添加自定义脚本整合新的应用程序，',
      text2: '通过添加自定义脚本为您的网站提供功能。',
    },
    scriptName: '脚本名称',
    insideHead: '在关闭的</head>标签内',
    insideBody: '在关闭的</body>标签内',
    headTag: '标题标签脚本',
    bodyTag: '身体标签脚本',
    service: '服务',
    integrate: '对接',
    showOnLive: '在线网站上展示',
    showOnLiveTitle: '当您关闭这个切换器时，第三方服务的图标将从您的网站主页上隐藏。',
    apiSecret: 'API保密问题',
    apiSecretOrPass: 'API秘密问题或密码',
    apiKeyOrUsername: 'API密钥或用户名',
    sendEmailVia: '发送电子邮件通过',
    smtpUser: 'SMTP用户',
    smtpPass: 'SMTP密码',
    useSSL: '使用SSL',
    smtpHost: 'SMTP主机',
    smtpPort: 'SMTP端口',
    emailsInfo: {
      text1: '有SMTP供应商被要求提供相同的电子邮件，以便在他们那边注册。',
      text2: '请确保符合要求。',
    },
    emailsInfo1: '所有带有此电子邮件的定时信息将被移至草稿中',
    blockCountries: '屏蔽的国家',
    blockCountriesMainText: '一些国家的网站将无法访问。',
    blockIps: '屏蔽的IP',
    versionControl: '版本控制',
    policies: '政策',
    policy: '政策',
    dateToNotify: '通知的日期',
    featureName: '功能名称',
    noBrandData: '无品牌式可供展示',
    createBrand: '创建品牌',
    builder: '建造者',
    platformState: '平台状态',
    minMaxWithdrawalAmount: '最低-最高系统取款金额',
    minWithdrawalAmount: '最低取款金额',
    maxWithdrawalAmount: '最高取款金额',
    minMaxDepositAmount: '最低—最高系统存款金额',
    minDepositAmount: '最低存款金额',
    maxDepositAmount: '最高存款金额',
    operationFeePercent: '运营费用，%',
    withdrawalFeePercent: '取款手续费，%',
    withdrawalFee: '取款手续费',
    withdrawalFeeForUsedBalancePercent: '已用余额的取款手续费，%',
    withdrawalFeeForUsedBalance: '已用余额的取款手续费',
    allowedConversion: '允许的转换',
    paymentMethodName: '付款方式名称',
    minAmount: '最低 {{title}} 金额',
    maxAmount: '最高 {{title}} 金额',
    minMaxSystemAmount: '最低—最高系统 {{title}} 金额',
    depositFeePercent: '存款手续费，%',
    depositFee: '存款手续费',
    allowConversion: '允许的转换',
    previewLive: '实时预览',
    feeFromDeposit: '存款手续费',
    allowWithdrawalFromUnusedBalance: '允许从未使用的余额中取款',
    allowWithdrawalFeeUsedBalance: '取款费将从剩余余额中扣除。',
    feeCalculationForWithdrawals: '取款手续费计算',
    feeFromWithdrawals: '取款手续费',
    feeFromWithdrawalsUsedBalance: '取款手续费（已使用余额）',
    feeFromWithdrawalsBalance: '取款手续费（{{type}} 余额）',
    unusedBalanceInfo: '激活后，“未使用”余额选项将出现在“取款手续费”设置中',
    paymentTransactionType: '付款交易类型',
    paymentMethodType: '付款方式类型',
    playerPaymentRelation: '玩家与付款之间的关系',
    failedPaymentAttempts: '尝试付款失败',
    failedPaymentAttemptsPeriod: '尝试付款失败时期',
    temporaryBlockPeriod: '临时屏蔽期',
    reverseConversion: '{{title}} 反向转换',
    lCustomScript: '自定义脚本',
    largeTransactions: '大额交易',
    lBrand: '付款方式',
    playerSettings: '玩家设置',
    verificationConfigurations: '验证配置',
    theCurrency: '货币',
    mainCountry: '主要国家',
    theLanguage: '语言',
    theDomain: '领域',
    smsSettings: 'SMS设置',
    verificationSettings: '验证设置',
    smtpSettings: 'SMTP设置',
    captchaSettings: '验证码设置',
    theEmail: '电子邮件',
    conversions: '转换',
    theConversion: '转换',
    theCountry: '国家',
    playerVerificationSettings: '玩家验证设置',
    domainRedirectionRule: '域重定向规则',
    wageringActivities: '投注活动',
    theSportsBook: '体育博彩',
    sportsBook: '体育博彩',
    theSportsBookView: '体育博彩视图',
    setMainView: '设置主视图',
    feeForWithdrawalsWillBeHeld: '取款手续费将从余额中扣留。',
    brandName: '付款方式名称',
    defaultCountry: '默认国家',
    defaultLanguage: '默认语言',
    defaultCurrency: '默认货币',
    rgsActivation: '通过RGS产品的激活，您将获得由全球知名供应商提供的多达5000种游戏的全面产品组合，为您的玩家提供最佳游戏体验。',
    sportsBookActivation: '通过体育博彩产品的激活，您将获得一个全面的多语言、多币种、可定制的界面，包括65种以上的体育项目、全球7500个联赛和3000多个投注市场的现场和赛前赛事。',
    products: '产品',
    websiteTemplate: '网站模板',
    failedPaymentInfo: '由于“付款程序失败”标准未激活，导致配置不可用。请从“标准”版块将其激活。',
    depositWithdrawalPaymentMethodInfo: '如果发生变化，使用转换功能的付款方式将受到影响，如果与新的变化冲突，系统可能会重置自定义的最低/最高限额。请确保在付款方式的设置中查看限额及可用货币。',
    otherPaymentMethodInfo: '使用转换的内部功能将使用这些汇率。确保所有转换均到位（如推荐奖金）。',
    conversionSettingsInfo: '当前限制与系统/系统转换的限制冲突。',
    keywords: '关键词',
    card: '卡',
    siteTwitterUsername: '网站（Twitter @用户名）',
    imageAlt: '图像Alt',
    recommendedSize: '推荐尺寸',
    stateOrRegion: '国家/地区',
    socialLinks: '社会联系',
    socialLinkInfo: '公司在不同资源上的简介页面，如Facebook、Twitter、Google+、Instagram、Youtube、LinkedIn、Wikipedia等等。您可以添加不限数量的URL。',
    content: '内容',
    image: '图像',
    depositPaymentCategory: '存款支付类别',
    withdrawalPaymentCategory: '取款支付类别',
    createNewBrand: '创建新付款方式',
    lConversion: '转换',
    allowWithdrawalViaTheDepositedMethods: '只允许使用与存款相同的方法取款。',
    allowWithdrawalViaTheDepositedMethodsInfo: '只允许使用与存款相同的方法取款。',
    logInAllowed: '允许登录',
    logInAllowedNote: '启用后，玩家只能登录并进行提款',
    schemaMarkupSettings: '模式标记设置',
    seoSettings: 'SEO设置',
    openGraphSettings: '开放图谱设置',
    twitterSettings: 'Twitter设置',
    accountId: '账户ID',
    accountIdInfo: '任何与服务账户ID相关的识别名称或代码，提供特定级别的访问。',
    depositTaxesInfo: '存款税信息',
    withdrawalTaxesInfo: '取款税信息',
    ranges: '范围',
    depositAmountRanges: '存款金额范围(从-到)',
    taxPercentage: '税率百分比',
    lTaxes: '税',
    withdrawalAmountRanges: '取款金额范围（从-到）',
    fixedAmountOrPercent: '固定金额或税收百分比',
    spotsBookJackpotDescription: '启用后在登陆页面上可以看到体育大奖小工具。',
    spotsBookPrematchTopMatchesDescription: '启用后在体育博彩的Iframe和登陆页面上都可以看到热门比赛的小工具。',
    spotsBookScrollingTypeDescription: '该设置仅适用于标准视图，当启用时，体育页面可完全通过一次滚动进行滚动。',
    spotsBookTournamentBalanceDescription: '启用后锦标赛余额可在网站主页上看到。',
    sportJackpot: '运动大奖',
    sectionsScrolling: '栏目滚动',
    sportTournamentBalance: '体育比赛的余额',
    iframe: 'Iframe',
    native: '本地',
    sportsBookIframeIntegrationDescription: '该体育博彩将以在线框架的形式提供',
    sportsBookNativeIntegrationDescription: '体育博彩将直接在应用内构建的',
    sportsBookMobileIntegrationType: '移动端整合类型',
    setAsDefault: '设为默认',
    configs: '配置',
    availableLayouts: '可用布局',
    setAmountRangesInfo: '如果一个范围同时设置了 "固定金额 "和 "税收百分比"，系统应按以下方式计算税收：预扣固定税额+超过该范围 "来自 "字段中设置的金额的x百分比。',
    resetTaxNote: '请注意，重设税收配置后，玩家将无法通过该货币进行存款。',
    depositAmountRangesHint: '存款金额范围 "的 "自 "字段中的金额包括在该范围内，而 "至 "字段中的金额则不包括在该范围内。',
    withdrawalAmountRangesHint: '提款金额范围 "的 "自 "字段中的金额包括在该范围内，而 "至 "字段中的金额则不包括在该范围内。',
    fixedAmountHint: '“固定金额”字段中的金额应低于或等于“存款金额范围”的“自”字段中输入的金额。如果金额“自”为0，则“固定金额”字段中的金额也必须为0。',
    fixedAmount: '固定金额',
    brandSportsBookActivation: '通过激活体育博彩产品，您将可以访问65项以上体育赛事的现场和赛前活动的自定义界面。激活后，只能从体育博彩管理面板停用产品。',
    amountFrom: '金额自',
    allowPlayerCompose: '允许玩家“编写”消息',
    sportsBookInheritWebsiteColor: '继承网站颜色',
    sportsBookInheritWebsiteColorDescription: '该设置适用于所有体育页面。',
    theBrand: '付款方式',
    taxSettings: '税收设置',
    infoUrlHint: '这个URL将在网站上用于显示税收的详细信息。',
    everyMatrixDescription: '模块化和API驱动的iGaming解决方案旨在释放大胆的想法',
    smarticoAffiliateDescription: '与客户建立联系的最佳方式',
    hotjarDescription: '了解用户如何在不被数字淹没的情况下真正体验您的网站',
    hotjarId: 'HotJar ID',
    googleAnalyticsId: 'Google Analytics ID',
    googleAnalyticsDescription: '分析网站的使用情况',
    googleSiteVerificationCode: 'Google的网站验证码',
    googleSearchConsoleDescription: '获得帮助来监控、维护和排除您的网站在Google搜索结果中的存在。',
    copyPasteFieldName: '复制你的{{fieldName}}并粘贴到下面的字段。',
    learnMore: '了解更多...',
    affiliateInfo: '{{count}}集成不能同时激活',
    liveChatDescription: '线上交流应用程序，使您和您的网站访客能够实时聊天',
    liveAgentDescription: '提供卓越的客户服务',
    intercomDescription: '在这里建立良好的客户关系',
    zendeskDescription: '更有效地与客户沟通',
    intercomId: 'Intercom ID',
    zendeskId: 'Zendesk ID',
    liveChatLicenseNumber: 'LiveChat LICENSE_NUMBER',
    liveAgentYourLa: 'LiveAgent YOUR-LA',
    liveAgentButtonID: 'LiveAgent按钮的ID',
    digitainAgIdInfo: '这是 "Digitain代理 "管理面板中的付款方式ID。',
    depositCurrencyInfo: '如果玩家被允许用支付方式支持的所有货币进行存款，那么支付方式的 "允许转换 "与支持的货币配置应该是激活的。',
    pageGroups: '页面组',
    digitainRetailInfo: '这是 "Digitain Retail "管理面板中付款方式的ID。',
    smarticoDescription: '利用挑战和游戏技术来激励玩家的游戏化解决方案',
    smarticoLabelKey: '标签键',
    smarticoBrandKey: '付款方式键',
    smarticoBrandKeyInfo: '一个付款方式的公键',
    smarticoLabelKeyInfo: '一个标签的公键',
    smarticoNote: 'Smartico CRM和游戏化是相互关联的，本节中的每个动作对游戏化也有影响。',
    realityCheckPeriod: '现实检查期',
    timeOut: '暂停时间',
    prematchTopMatches: '赛前热身赛',
    prematchTotalTopMatches: '赛前热门赛（包括全市场）',
    spotsBookPrematchTotalTopMatchesDescription: '启用后，可使用包含全市场数据的赛前热门比赛小工具',
    livePrematchTopMatches: '现场和赛前顶级比赛',
    spotsBookLivePrematchTopMatchesDescription: '启用“实时和赛前顶级比赛”小工具后即可使用',
    liveTopMatches: '实时顶级比赛',
    spotsBookLiveTopMatchesDescription: '启用“实时顶级比赛”小工具后即可使用',
    verificationPeriodTabInfo: '在本部分中，您可以设置配置，在可定制的时间段内自动阻止未经验证的玩家交易或账户。',
    periodCalculationSource: '期间计算来源',
    periodCalculationSourceInfo: '选择开始计算验证期的日期来源',
    verificationPeriod: '验证期',
    verificationPeriodInfo: '玩家账户在验证前可以活跃的天数',
    unverifiedPlayersExclusionsInfo: '如果在“忽略存款验证的支付”菜单中选择了付款方式，且在“最大存款额”部分设置了限额，则该限额不会影响该付款方式。',
    periodsLimitsInfo: '在本部分中，您可以设置玩家可以选择暂停和自我排除的特定时间段。',
    preferencesDescription: '为玩家激活/停用短信、电子邮件和推送通知。',
    preferencesSMSDescription: '为品牌的所有参与者发送短信，并在网站上 “通过短信”部分应该是不可见的',
    preferencesEmailDescription: '为品牌和网站上的所有参与者发送电子邮件 并且在网站上“通过电子邮件"部分应变得不可见',
    walletsSettings: '钱包设置',
    wallets: '钱包',
    features: '特征',
    ddaInfo: '激活该选项后，提款的税款将在DDA逻辑中预扣。',
    whatsAppDescription: '让您的客户使用世界上最流行的通讯工具联系您。',
    phoneNumber: '手机号',
    whatsAppPhoneNumberInfo: '输入您注册WhatsApp帐户的手机号',
    multiGameLaunch: '多游戏启动',
    multiGameLaunchDescription: '根据配置，网站上提供了多游戏选项，玩家可以同时玩多个游戏。',
    jackpotWidget: '大奖小工具',
    jackpotWidgetDescription: '请参阅 赌场大奖小工具登陆页面激活。适用于Amusnet供应商大奖小工具。',
    gameIframe: '游戏Iframe',
    manuBarFixation: '菜单栏定制',
    manuBarFixationDescription: '仅适用于移动视图，具体取决于大厅的主栏固定配置。',
    paymentVisibilityInfo: '在本部分中，您可以隐藏或不允许拥有以下标签之一的玩家使用付款方式',
    centrivoConnectAgDescription: '使用Centrivo的连接代理解决方案改变您的iGaming业务',
    retailCentrivoDescription: '使用Centrivo的零售解决方案改变您的iGaming业务',
    currencySymbol: '货币符号',
    preferencesPushNotificationsDescription: '推送通知渠道将可用于为品牌发送通知',
    currencyFormat: '货币格式',
    currencyFormatInfo: '在网站上为玩家显示哪种货币格式。',
    isoCode: 'ISO代码',
    symbols: '符号',
    digitainCrmDescription: 'CRM解决方案旨在保持现有玩家的参与度，提高他们的整体满意度',
    accessDecreaseLimit: '访问限制',
    depositOnNegativeBalance: '负余额存款',
    depositOnNegativeBalanceInfo: '存款批准后，将对玩家的负钱包进行调整，以结清债务。',
    digitainAffiliateDescription: '激活您的附属联盟计划并体验网站流量的显著提升。',
    projectId: '项目ID',
    projectIdInfo: '这是“Digitain附属”管理面板中的项目ID',
    brandIdInfo: '这是“Digitain附属”管理面板中的品牌ID',
    maintenanceSetup: '维护设置',
    blockAction: '屏蔽操作',
    maintenancePeriod: '维护期',
    sportsBookViews: '体育博彩视图',
    mandatoryNotification: '强制通知',
    mandatoryNotificationInfo: '在网站上为已登录玩家强制弹出窗口',
    lossDecreaseLimit: '损失限制',
    inactivityIndicators: '不活跃指标',
    inactivityIndicatorsInfo: '该配置将根据玩家的最后登录日期或最后交易（投注、存款、取款）日期等指标之一，将玩家视为非活跃玩家。',
    recoverByPlayer: '按玩家恢复',
    recoverByPlayerInfo: '启用后，玩家可通过验证码找回自己',
    singleBetDecreaseLimit: '单次投注限额',
    cashierType: '出纳类型',
    lIcon: '图标',
    gameLaunchType: '游戏启动类型（移动视图）',
    gameFullScreenLaunchDescription: '用iframe全屏打开游戏',
    gameHeaderFooterLaunchDescription: '用iframe和页眉页脚打开游戏',
    gameQuickActionBarLaunchDescription: '通过快速操作栏打开游戏',
    limitEffectConfigs: '限制效果配置',
    ignoreFirstDeposit: '忽略首次存款',
    ignoreFirstDepositHint: '如果选择此选项，玩家的首次终身成功存款将不计算在负责任博彩存款限额内。',
    periodLimit: '期间限制',
    playerLimitsConfiguration: '玩家限制配置',
    alanbaseDescription: '由iGaming和附属营销专家为您提供的平台，帮助其他专家优化成本，提高利润。',
    routForEvents: '赛事路线',
    routForGoals: '进球途径',
    fastDepositPaymentMethod: '快速存款支付方式',
    fastDepositPaymentMethodInfo: '快速存款是网站上的一项功能，通过该功能，玩家可以使用“Centrivo”预定义的付款方式存款，而无需离开游戏页面。',
    sportStatusIconsInfo: '上传的图标将在网站的玩家资料中显示。每个上传的图标将在不超过一小时后反映在网站上。',
    rolloverBetsReturn: '展期投注返回同一钱包',
    rolloverBetsReturnInfo: '如果从展期余额中投注的金额被退回/取消，该金额将转入展期余额中',
    maxDepositAmounts: '最大存款额',
    paymentsIgnoringDepositVerification: '忽略存款验证的支付',
    selfExclusion: '自我排除',
  },
  ro: {
    mainCurrency: 'Valuta principală',
    mainLanguage: 'Limba principală',
    market: 'Piață',
    setMainCountry: 'Setează țara principală',
    setMainCurrency: 'Setează moneda principală',
    setMainLanguage: 'Setează limba principală',
    domainName: 'Numele de domeniu',
    redirectedTo: 'Redirecționat către',
    nameServer1: 'Numele 1-ului server',
    nameServer2: 'Numele server 2',
    onlyVerifiedDomainInfo: 'Doar domeniile verificate pot fi conectate',
    disconnectDomain: 'Deconectează domeniul',
    connectDomain: 'Conectare Domeniu',
    deleteDomain: 'Ștergere Domeniul',
    deleteScript: 'Ștergere Script',
    deactivateScript: 'Deactivare Script',
    activateScript: 'Activare Script',
    supportedCurrencies: 'Valute acceptate',
    supportedCountries: 'Țări acceptate',
    supportedLanguages: 'Limbi acceptate',
    providerDisableInfo: 'Este posibil ca furnizorul să fi fost dezactivat pentru întregul sistem',
    activity: 'Activitate',
    verificationAnd: 'Verificare (și)',
    verificationOr: 'Verificare (sau)',
    andDelimiter: 'și',
    currencyFrom: 'Valută din',
    currencyTo: 'Valuta către',
    reverseRate: 'Taxare inversă',
    feature: 'Caracteristică',
    otherTransactions: 'Alte tranzacții',
    limitType: 'Tip de limită',
    playersNotifiedDate: 'Data de notificare',
    depositProcessingType: 'Tip de procesare a depozitului',
    withdrawalProcessingType: 'Tip de procesare a retragerii',
    allowedActivities: 'Activități permise',
    depositSupportedCurrencies: 'Valute acceptate pentru depunere',
    withdrawalSupportedCurrencies: 'Valute acceptate pentru retragere',
    newFee: 'Taxa nouă, %',
    feeBefore: 'Taxa înainte, %',
    newLimit: 'Limita nouă',
    statusDisabledInfo: 'Site-ul web va deveni indisponibil din toate domeniile.',
    statusActivitiesDisabledInfo: 'Nu vor fi acceptate pariuri noi.',
    statusVersionNamesDisabledInfo: 'Pariurile vor fi dezactivate pentru versiunile selectate.',
    timezoneInfo: 'Orice calcule în curs, legate de fusul orar, vor fi schimbate.',
    domainRedirection: 'Redirecționarea de domeniu',
    uVerify: 'VERIFICARE',
    redirect: 'Redirecționa',
    forwardingURL: 'URL de redirecționare',
    destinationURL: 'Adresa URL de destinație',
    domainRedirectInfo: 'Domeniul care este folosit ca adresă URL de redirecționare, sau de destinație, nu poate fi șters fără ștergerea regulei existente.',
    registerDomainInfo: 'Trebuie să-ți înregistrezi toate domeniile în contul tău Google pentru a activa captcha pentru toate.',
    ageRestriction: 'Restricție de vârstă',
    failedLogonAttempts: 'Încercări eșuate de conectare',
    passwordPattern: 'Șablon de parolă',
    failedAttemptsPeriod: 'Perioadă de încercări eșuate',
    lockPeriod: 'Perioada de blocare',
    passwordReminder: 'Aducere aminte al parolei',
    inactivityPeriod: 'Perioada de inactivitate',
    securityPeriod: 'Perioada verificării de securitate',
    referralActivationPeriod: 'Perioada activării a recomandărilor',
    passwordPatternNote:
      'Se recomandă ca parolele să aibă minim 8 caractere, inclusiv cel puțin 1 număr și 1 literă mare. Totuși, le poți slăbi până la 6-20 de litere latine, numere sau caractere speciale.',
    referralActivationPeriodNote: 'Perioada pentru care dorești să păstrezi informațiile de identificare a refererului pentru o nouă înregistrare pe site.',
    correspondingTranslations: 'Asigură-ți că toate traducerile sunt actualizate în mod corespunzător.',
    verificationCodeLength: 'Lungimea codului de verificare',
    dailyMobileVerification: 'Solicitări zilnice de verificare pe telefon mobil',
    emailRequestsInDaily: 'Solicitări zilnice de verificare de e-mail',
    expirationMinutes: 'Perioada de expirare a token-ului',
    expirationMinutesNote: 'Configurația definește perioada după care token-ul (codul, linkul) din notificare va deveni nevalid.',
    emailRequestsInDailyNote: 'Configurația definește câte solicitări de e-mail pentru verificare jucătorul poate primi într-o zi. Valoarea minimă este de {{value}}.',
    dailyMobileVerificationNote: 'Configurația definește câte solicitări de SMS pentru verificare jucătorul poate primi într-o zi. Valoarea minimă este de {{value}}.',
    identityDocument: 'Document de identitate',
    addressDocument: 'Document de adresă',
    additionalDocument: 'Document adițional',
    disableSportBook: 'Dezactiva {{name}}',
    gameDisableTooltip: 'Este posibil ca jocul să fi fost dezactivat pentru întregul sistem',
    definiteSelfExclusionDecreaseLimit: 'Autoexcludere definitivă',
    indefiniteSelfExclusionDecreaseLimit: 'Autoexcludere nedefinită',
    depositDecreaseLimit: 'Limită de depunere',
    betDecreaseLimit: 'Limită de pariu',
    withdrawalDecreaseLimit: 'Limită de retragere',
    activateSportsbook: 'Activează pariurile sportive',
    scriptName: 'Numele de script',
    insideHead: 'Tag-ul de închidere </head> în interior',
    insideBody: 'Tag-ul de închidere </body> în interior',
    headTag: 'scriptul etichetei de cap',
    bodyTag: 'scriptul etichetei de corp',
    service: 'serviciu',
    integrate: 'Integrează',
    showOnLive: 'Afișează pe site-ul live',
    showOnLiveTitle: 'Când dezactivezi acest comutator, pictograma serviciilor terță parte va fi ascunsă din pagina de pornire a site-ului tău web.',
    apiSecret: 'Secret de API',
    apiSecretOrPass: 'Secret API și parolă',
    apiKeyOrUsername: 'Cheia API și numele de utilizator',
    sendEmailVia: 'Trimite e-mail prin',
    smtpUser: 'Utilizator SMTP',
    smtpPass: 'Parolă SMTP',
    useSSL: 'Folosește SSL',
    smtpHost: 'Gazdă SMTP',
    smtpPort: 'Port-ul SMTP',
    emailsInfo1: 'Toate mesajele programate cu acest e-mail vor fi mutate în versiune nefinalizată',
    blockCountries: 'Țări blocate',
    blockCountriesMainText: 'Site-ul web va deveni indisponibil din țările selectate',
    blockIps: 'Blochează IP-urile',
    versionControl: 'Controlul de versiuni',
    policies: 'Politici',
    policy: 'Politică',
    dateToNotify: 'Data de notificare',
    featureName: 'Nume caracteristică',
    noBrandData: 'Nu există mărci de afișat',
    createBrand: 'Crează Marcă',
    builder: 'Constructor',
    platformState: 'Starea platformei',
    minMaxWithdrawalAmount: 'Suma de retragere a sistemului min.-max.',
    minWithdrawalAmount: 'Suma de retragere minimă',
    maxWithdrawalAmount: 'Suma de retragere maximă',
    minMaxDepositAmount: 'Suma de depunere a sistemului min.-max.',
    minDepositAmount: 'Suma de depunere minimă',
    maxDepositAmount: 'Suma de depunere maximă',
    operationFeePercent: 'Taxa de operare, %',
    withdrawalFeePercent: 'Taxa de retragere, %',
    withdrawalFee: 'Taxa de retragere',
    withdrawalFeeForUsedBalancePercent: 'Taxa de retragere pentru soldul utilizat, %',
    withdrawalFeeForUsedBalance: 'Taxa de retragere pentru soldul utilizat',
    allowedConversion: 'Conversie permisă',
    paymentMethodName: 'Numele metodei de plată',
    minAmount: 'Sumă minimă de {{title}}',
    maxAmount: 'Sumă maximă de {{title}}',
    minMaxSystemAmount: 'Suma min.-max. de sistem {{title}}',
    depositFeePercent: 'Taxă de depunere %',
    depositFee: 'Taxă de depunere',
    allowConversion: 'Permite conversia',
    previewLive: 'Previzualizare live',
    feeFromDeposit: 'Comision din depunere',
    allowWithdrawalFromUnusedBalance: 'Permite retragerea din soldul neutilizat',
    allowWithdrawalFeeUsedBalance: 'Taxă de retragere va fi reținută din soldul rămas.',
    feeCalculationForWithdrawals: 'Calcul de taxă pentru retrageri',
    feeFromWithdrawals: 'Taxa de la retrageri',
    feeFromWithdrawalsUsedBalance: 'Taxa de la retrageri',
    feeFromWithdrawalsBalance: 'Taxa de la retrageri ({{type}} sold)',
    unusedBalanceInfo: 'Când este activată, opțiunea de sold „Nefolosit” va apărea în setarea de „Taxă de la retrageri”',
    paymentTransactionType: 'Tip de tranzacție de plată',
    paymentMethodType: 'Tip de metodă de plată',
    playerPaymentRelation: 'Relația jucător-plată',
    failedPaymentAttempts: 'Încercări de plată eșuate',
    failedPaymentAttemptsPeriod: 'Perioadă de încercări de plată eșuate',
    temporaryBlockPeriod: 'Perioadă de blocare temporară',
    reverseConversion: 'Conversie inversă {{title}}',
    lCustomScript: 'script personalizat',
    largeTransactions: 'Tranzacții mari',
    lBrand: 'marcă',
    playerSettings: 'Setări de jucător',
    verificationConfigurations: 'Configurații de verificare',
    theCurrency: 'Valută',
    mainCountry: 'Țara principală',
    theLanguage: 'Limba',
    theDomain: 'Domeniul',
    smsSettings: 'setările SMS',
    verificationSettings: 'Setări de verificare',
    smtpSettings: 'Setări SMTP',
    captchaSettings: 'Setări Captcha',
    theEmail: 'e-mailul',
    conversions: 'Conversii',
    theConversion: 'Conversia',
    theCountry: 'Țara',
    playerVerificationSettings: 'Setări de verificare a jucătorului',
    domainRedirectionRule: 'Regula de redirecționare a domeniului',
    wageringActivities: 'Activități de pariuri',
    theSportsBook: 'Pariurile sportive',
    sportsBook: 'Pariuri sportive',
    theSportsBookView: 'Vizualizarea pariurilor sportive',
    setMainView: 'Seta vizualizarea principală',
    feeForWithdrawalsWillBeHeld: 'Taxa de retragere va fi reținută din soldul rămas.',
    brandName: 'Numele de marcă',
    defaultCountry: 'Țara implicită',
    defaultLanguage: 'Limba implicită',
    defaultCurrency: 'Valuta implicită',
    rgsActivation:
      'Prin activarea produsului RGS vei avea acces la un portofoliu cuprinzător de până la 5000 de jocuri ale furnizorilor cunoscuți la nivel mondial, oferind jucătorilor tăi cea mai bună experiență de joc.',
    sportsBookActivation:
      'Prin activarea produsului a pariurilor sportive vei avea acces la o interfață cuprinzătoare multilingvă, multi-valută, personalizabilă, cu evenimente live și înainte de meci pentru mai mult de 65 de sporturi, 7500 de ligi din întreaga lume și peste 3000 de piețe de pariuri.',
    products: 'Produse',
    websiteTemplate: 'Șablon de web',
    failedPaymentInfo: 'Configurațiile nu sunt disponibile din cauza inactivității standardului „Procedură de plată eșuată”. Rugăm să îl activezi din secțiunea „Standarde”.',
    depositWithdrawalPaymentMethodInfo:
      'Metodele de plată care utilizează conversia vor fi afectate în cazul schimbărilor, iar limitele min/max personalizate pot fi resetate de sistem, dacă sunt în contradicție cu noile modificări. Asigură-ți că revizuiești limitele și disponibilitatea valutelor în setările metodelor de plată.',
    otherPaymentMethodInfo:
      'Funcționalitățile interne care utilizează conversia, vor folosi aceste rate de conversie. Asigură-ți că ai toate conversiile la locul lor (de exemplu, bonus de recomandare).',
    conversionSettingsInfo: 'Limita curentă contrazice sistemul / limita convertite de sistem.',
    keywords: 'Cuvinte cheie',
    card: 'Card',
    siteTwitterUsername: 'Site (Twitter @nume utilizator)',
    imageAlt: 'Image Alt',
    recommendedSize: 'Dimensiune recomandată',
    stateOrRegion: 'Stat/Regiune',
    socialLinks: 'Link-uri de socializare',
    socialLinkInfo: 'Paginile de profil ale companiei pe diferite resurse, cum ar fi Facebook, Twitter, Google+, Instagram, Youtube, LinkedIn, Wikipedia etc. Poți adăuga oricâte adrese URL dorești.',
    image: 'Imagine',
    depositPaymentCategory: 'Categoria de plată a depozitului',
    withdrawalPaymentCategory: 'Categoria de plată de retragere',
    createNewBrand: 'Crează o marcă nouă',
    lConversion: 'conversie',
    logInAllowed: 'Conectare permisă',
    logInAllowedNote: 'Când este activat, jucătorii se pot conecta și pot face doar retrageri.',
    schemaMarkupSettings: 'Setări de schema Marchup',
    seoSettings: 'Setări SEO',
    openGraphSettings: 'Setări de graficul deschis',
    twitterSettings: 'Setări de Twitter',
    accountId: 'ID de cont',
    accountIdInfo: 'Orice nume sau cod de identificare asociat cu ID-ul contului de serviciu, care oferă un anumit nivel de acces',
    depositTaxesInfo: 'În cazul în care nu a fost setată nicio configurare de taxe pentru o valută, jucătorul nu va putea face depuneri prin intermediul acelei valute.',
    withdrawalTaxesInfo: 'Dacă nu a fost setată nicio configurare de taxe pentru o valută, jucătorul nu va putea face retrageri \nprin intermediul acelei valute.',
    ranges: 'Intervale',
    depositAmountRanges: 'Intervalul sumei depunerii (de la - până la)',
    taxPercentage: 'Procentul de impozitare %',
    lTaxes: 'Impozite',
    withdrawalAmountRanges: 'Intervalul sumei de retragere (de la - până la)',
    fixedAmountOrPercent: 'Suma fixă sau procentajul de impozit',
    allowWithdrawalViaTheDepositedMethods: 'Permite retragerea doar prin intermediul metodelor depuse',
    allowWithdrawalViaTheDepositedMethodsInfo:
      'Activarea acestei setări restricționează jucătorul de a retrage bani prin metodele de plată/marcă pe care nu le-a folosit niciodată înainte pentru depunere ',
    spotsBookJackpotDescription: 'Odataă activat Widgetul Jackpot Sportiv, devine disponibil pe pagina de aterizare',
    spotsBookScrollingTypeDescription: 'Setarea se aplică doar pentru vizualizarea standard și, odată activată, pagina de Sport este complet derulabilă cu o singură defilare.',
    spotsBookTournamentBalanceDescription: 'Atunci când este activat, soldul turneului este disponibil pe pagina de pornire a site-ului web',
    sportJackpot: 'Jackpot Sportiv',
    sectionsScrolling: 'Derularea secțiunilor',
    sportTournamentBalance: 'Sold de turneu sportiv',
    iframe: 'Iframe',
    native: 'Nativ',
    sportsBookIframeIntegrationDescription: 'Casa de pariuri sportive va fi disponibilă într-un cadru inline',
    sportsBookNativeIntegrationDescription: 'Sportsbookul va fi disponibil direct în aplicația încorporată ',
    sportsBookMobileIntegrationType: 'Tipul de integrare pe telefonul mobil',
    setAsDefault: 'Setare ca implicit',
    configs: 'Configurații',
    availableLayouts: 'Pagina de layout-uri disponibile',
    setAmountRangesInfo:
      'Dacă sunt setate "Suma fixă" și "Procentul de impozit" pentru un interval, sistemul calculează impozitul în felul următor: a reține o sumă fixă de impozit + procentul x din suma, care depășește suma stabilită în câmpul "Din" al intervalului.',
    resetTaxNote: 'Reține că, după resetarea configurațiilor fiscale, jucătorii nu vor mai putea face depuneri în valuta respectivă.',
    depositAmountRangesHint:
      'Suma din câmpul "De la" din secțiunea "Intervalul sumei depunerii" este inclusă în intervalul respectiv, în timp ce suma din câmpul "Până la" nu este inclusă în interval.',
    withdrawalAmountRangesHint: 'Suma din câmpul "De la" din "Intervalul sumei de retragere" este inclusă în acel interval, în timp ce suma din câmpul "Până la" nu este inclusă în interval.',
    fixedAmountHint:
      'Suma din câmpul "Sumă fixă" trebuie să fie mai mică sau egală cu suma introdusă în câmpul "De la" din "Intervalul sumei depunerii". În cazul în care suma "De la" este egală cu 0, suma din câmpul "Sumă fixă" trebuie să fie de asemenea 0.',
    fixedAmount: 'Sumă fixă',
    brandSportsBookActivation:
      'Prin activarea produsului Sportsbook vei avea acces la o interfață personalizabilă cu evenimente live și pre-meci pentru mai mult de 65 de sporturi. Odată activat, produsul poate fi dezactivat numai din panoul de administrare Sportsbook.',
    amountFrom: 'Suma din',
    allowPlayerCompose: 'Permite jucătorii să compună mesaje',
    sportsBookInheritWebsiteColor: 'Utilizează (a moșteni) culoarea site-ului web',
    sportsBookInheritWebsiteColorDescription: 'Setarea se aplică tuturor paginilor de sport.',
    theBrand: 'Marcă (metodă de plată)',
    taxSettings: 'Setări de impozite',
    infoUrlHint: 'Acest URL va fi folosit pe site-ul web pentru afișarea detaliilor fiscale.',
    everyMatrixDescription: 'Soluții iGaming modulare și bazate pe API, concepute pentru implementarea celor mai îndrăznețe idei',
    smarticoAffiliateDescription: 'Nu există o modalitate mai bună de a te conecta cu clienții ',
    hotjarDescription: 'Înțelege modul în care utilizatorii experimentează cu adevărat site-ul tău fără să te îneci în cifre',
    hotjarId: 'ID HotJar',
    googleAnalyticsId: 'ID de Google Analytics',
    googleAnalyticsDescription: 'Analizează utilizarea pe site-urile tale web',
    googleSiteVerificationCode: 'Codul de verificare a site-ului Google',
    googleSearchConsoleDescription: 'Obține ajutor pentru monitorizarea, menținerea și soluționarea problemelor legate de prezența site-ului tău în rezultatele căutării pe Google.',
    copyPasteFieldName: 'Copiază {{fieldName}} și inserează-l în câmpul de mai jos.',
    learnMore: 'Află mai mult',
    affiliateInfo: 'Integrările {{count}} nu pot fi active simultan',
    liveChatDescription: 'Aplicație de comunicare bazată pe internet, care permite să discuti în timp real cu vizitatorii site-ului tău web.',
    liveAgentDescription: 'Furbizează servicii excelente pentru clienți',
    intercomDescription: 'Începe să cultivi relații excelente cu clienții aici',
    zendeskDescription: 'Comunică mai eficient cu clienții',
    intercomId: 'ID de Intercom',
    zendeskId: 'ID Zendesk',
    liveChatLicenseNumber: 'LiveChat LICENSE_NUMBER',
    liveAgentYourLa: 'LiveAgent YOUR-LA',
    liveAgentButtonID: 'ID-ul butonului LiveAgent',
    digitainAgIdInfo: 'Acesta este ID-ul Mărcii în panoul de administrare "Digitain Agent".',
    depositCurrencyInfo:
      'În cazul în care jucătorilor li se permite să facă depuneri cu toate monedele acceptate de metodele de plată, "Permite conversia" cu configurările valutei acceptate trebuie să fie activă pentru metoda de plată.',
    pageGroups: 'Grupuri de pagină',
    digitainRetailInfo: "Acesta este ID-ul metodei de plată în panoul de administrare 'Digitain Retail'.",
    smarticoDescription: 'Soluția de gamificare cu diverse provocări și tehnici de joc pentru a motiva jucătorii ',
    smarticoLabelKey: 'Cheie de Etichetă ',
    smarticoBrandKey: 'Cheie de Marcă',
    smarticoBrandKeyInfo: 'O cheie publică pentru marcă/metoda de plată',
    smarticoLabelKeyInfo: 'O cheie publică pentru etichetă',
    smarticoNote: 'Smartico CRM și Gamificarea sunt interconectate, iar fiecare acțiune din această secțiune are efect și asupra gamificării',
    realityCheckPeriod: 'Perioada de verificare a realității',
    timeOut: 'Timp de expirare',
    inactivityIndicators: 'Indicatori de inactivitate',
    inactivityIndicatorsInfo:
      'Această configurație va considera că jucătorul este inactiv pe baza unui indicator dintr-o listă de indicatori, data ultimei conectări a jucătorului sau data ultimei tranzacții (pariu, depunere, retragere)',
    recoverByPlayer: 'Recuperare după jucător',
    recoverByPlayerInfo: 'La activare, jucătorii pot efectua singuri recuperarea prin codul de verificare',
  },
  bg: {},
  el: {
    mainCurrency: 'Κύριο νόμισμα',
    mainLanguage: 'Κύρια γλώσσα',
    market: 'Αγορά',
    setMainCountry: 'Ορισμός κύριας χώρας',
    setMainCurrency: 'Ορισμός κύριου νομίσματος',
    setMainLanguage: 'Ορισμός κύριας γλώσσας',
    domainName: 'Όνομα τομέα',
    redirectedTo: 'Ανακατεύθυνση σε',
    nameServer1: 'Διακομιστής ονομάτων 1',
    nameServer2: 'Διακομιστής ονομάτων 2',
    onlyVerifiedDomainInfo: 'Μόνο επαληθευμένοι τομείς μπορούν να συνδεθούν',
    disconnectDomain: 'Αποσύνδεση τομέα',
    connectDomain: 'Σύνδεση τομέα',
    deleteDomain: 'Διαγραφή τομέα',
    deleteScript: 'Διαγραφή σεναρίου',
    deactivateScript: 'Απενεργοποίηση σεναρίου',
    activateScript: 'Ενεργοποίηση σεναρίου',
    supportedCurrencies: 'Υποστηριζόμενα νομίσματα',
    supportedCountries: 'Υποστηριζόμενες χώρες',
    supportedLanguages: 'Υποστηριζόμενες γλώσσες',
    providerDisableInfo: 'Ενδέχεται να έχει απενεργοποιηθεί ο πάροχος για ολόκληρο το σύστημα',
    activity: 'Δραστηριότητα',
    verificationAnd: 'Επαλήθευση (και)',
    verificationOr: 'Επαλήθευση (ή)',
    andDelimiter: 'και',
    currencyFrom: 'Νόμισμα από',
    currencyTo: 'Νόμισμα προς',
    reverseRate: 'Ισοτιμία αντίστροφης μετατροπής',
    feature: 'Λειτουργία',
    otherTransactions: 'Άλλες συναλλαγές',
    limitType: 'Τύπος ορίου',
    playersNotifiedDate: 'Ημερομηνία ειδοποίησης',
    depositProcessingType: 'Τύπος επεξεργασίας κατάθεσης',
    withdrawalProcessingType: 'Τύπος επεξεργασίας ανάληψης',
    allowedActivities: 'Επιτρεπόμενες δραστηριότητες',
    depositSupportedCurrencies: 'Υποστηριζόμενα νομίσματα κατάθεσης',
    withdrawalSupportedCurrencies: 'Υποστηριζόμενα νομίσματα ανάληψης',
    newFee: 'Νέα χρέωση, %',
    feeBefore: 'Χρέωση πριν, %',
    newLimit: 'Νέο όριο',
    statusDisabledInfo: 'Ο ιστότοπος θα καταστεί μη διαθέσιμος από όλους τους τομείς.',
    statusActivitiesDisabledInfo: 'Δεν θα γίνονται αποδεκτά νέα στοιχήματα.',
    statusVersionNamesDisabledInfo: 'Τα στοιχήματα θα είναι απενεργοποιημένα για τις επιλεγμένες εκδόσεις.',
    timezoneInfo: 'Τυχόν υπολογισμοί σε εξέλιξη που σχετίζονται με τη ζώνη ώρας θα αλλάξουν.',
    domainInfo: {
      text1: 'Συνδεθείτε στον πάροχο του τομέα σας',
      text2: 'Ο πάροχος του τομέα σας είναι η εταιρεία από την οποία τον αγοράσατε (π.χ., GoDaddy, eNom, 1and1).',
      text3: 'Ενημερώστε τους διακομιστές ονομάτων του τομέα σας.',
      text4: 'Μεταβείτε στις ρυθμίσεις DNS/διακομιστών ονομάτων του τομέα σας.',
      text5: 'Αντικαταστήστε όλους τους τρέχοντες διακομιστές ονομάτων με τους προσαρμοσμένους διακομιστές ονομάτων που αναγράφονται στον πίνακα για κάθε τομέα',
      text6: 'Επιβεβαιώστε τη σύνδεση του τομέα σας',
      text7: 'Ελέγξτε αν έχει γίνει ενημέρωση των ρυθμίσεων του τομέα σας.',
      text8: 'Οι υπεύθυνοι ενδέχεται να χρειαστούν 24 ώρες για να επεξεργαστούν τις ενημερώσεις των διακομιστών ονομάτων.',
    },
    domainRedirection: 'Ανακατεύθυνση τομέα',
    uVerify: 'ΕΠΑΛΗΘΕΥΣΗ',
    redirect: 'Ανακατεύθυνση',
    forwardingURL: 'URL ανακατεύθυνσης',
    destinationURL: 'URL προορισμού',
    domainRedirectInfo: 'Ο τομέας που χρησιμοποιείται είτε ως URL ανακατεύθυνσης είτε ως URL προορισμού δεν μπορεί να διαγραφεί χωρίς να γίνει διαγραφή του υφιστάμενου κανόνα.',
    registerDomainInfo: 'Πρέπει να εγγράψετε όλους τους τομείς σας στον λογαριασμό σας Google για να ενεργοποιηθεί το captcha για όλους τους τομείς.',
    ageRestriction: 'Περιορισμός ηλικίας',
    failedLogonAttempts: 'Αποτυχημένες προσπάθειες σύνδεσης',
    passwordPattern: 'Μοτίβο κωδικού πρόσβασης',
    failedAttemptsPeriod: 'Περίοδος αποτυχημένων προσπαθειών',
    lockPeriod: 'Περίοδος κλειδώματος',
    passwordReminder: 'Υπενθύμιση κωδικού πρόσβασης',
    inactivityPeriod: 'Περίοδος αδράνειας',
    securityPeriod: 'Περίοδος ελέγχου ασφαλείας',
    referralActivationPeriod: 'Περίοδος ενεργοποίησης παραπομπής',
    passwordPatternNote:
      'Συνιστάται οι κωδικοί πρόσβασης να έχουν τουλάχιστον 8 χαρακτήρες, συμπεριλαμβάνοντας τουλάχιστον 1 αριθμό και 1 κεφαλαίο γράμμα. Ωστόσο, μπορείτε να έχετε πιο αδύναμο κωδικό πρόσβασης, χρησιμοποιώντας 6-20 λατινικούς χαρακτήρες, αριθμούς ή ειδικούς χαρακτήρες.',
    referralActivationPeriodNote: 'Περίοδος για την οποία θέλετε να κρατήσετε τα στοιχεία της ταυτότητας του ατόμου που έκανε την παραπομπή για νέα εγγραφή στον ιστότοπο.',
    correspondingTranslations: 'Βεβαιωθείτε ότι όλες οι μεταφράσεις είναι ενημερωμένες αντίστοιχα.',
    verificationCodeLength: 'Μήκος κωδικού επαλήθευσης',
    dailyMobileVerification: 'Ημερήσια αιτήματα επαλήθευσης κινητού τηλεφώνου',
    emailRequestsInDaily: 'Ημερήσια αιτήματα επαλήθευσης email',
    expirationMinutes: 'Περίοδος λήξης token',
    expirationMinutesNote: 'Η διαμόρφωση καθορίζει την περίοδο μετά την οποία το token (κωδικός, σύνδεσμος) της ειδοποίησης δεν θα ισχύει πλέον.',
    emailRequestsInDailyNote: 'Η διαμόρφωση καθορίζει πόσα αιτήματα επαλήθευσης email μπορεί να λάβει ένας παίκτης σε μία ημέρα. Η ελάχιστη τιμή είναι {{value}}.',
    dailyMobileVerificationNote: 'Η διαμόρφωση καθορίζει πόσα αιτήματα επαλήθευσης μέσω SMS μπορεί να λάβει ένας παίκτης σε μία ημέρα. Η ελάχιστη τιμή είναι {{value}}.',
    identityDocument: 'Έγγραφο ταυτότητας',
    addressDocument: 'Έγγραφο διεύθυνσης',
    additionalDocument: 'Επιπρόσθετο έγγραφο',
    disableSportBook: 'Απενεργοποίηση {{name}}',
    gameDisableTooltip: 'Ενδέχεται να έχει απενεργοποιηθεί το παιχνίδι για ολόκληρο το σύστημα',
    playerLimitInfo: {
      text1: 'Μπορείτε να ορίσετε όριο για την περίοδο μείωσης της βαρύτητας για κάθε τύπο ορίου.',
      text2: 'Αλλαγές στα όρια που έχουν επιβληθεί από παίκτες, οι οποίες κάνουν πιο αδύναμα τα όρια, θα εφαρμόζονται σύμφωνα με αυτή τη διαμόρφωση.',
    },
    definiteSelfExclusionDecreaseLimit: 'Αυτοαποκλεισμός για συγκεκριμένο χρονικό διάστημα',
    indefiniteSelfExclusionDecreaseLimit: "Αυτοαποκλεισμός επ' αόριστον",
    depositDecreaseLimit: 'Όριο κατάθεσης',
    betDecreaseLimit: 'Όριο στοιχήματος',
    withdrawalDecreaseLimit: 'Όριο ανάληψης',
    activateSportsbook: 'Ενεργοποίηση Sportsbook',
    customScript: {
      text1: 'Μπορείτε να ενσωματώνετε νέες εφαρμογές ή να προσθέτετε διαφορετικές',
      text2: 'λειτουργίες στον ιστότοπό σας με την προσθήκη προσαρμοσμένων σεναρίων.',
    },
    scriptName: 'Όνομα σεναρίου',
    insideHead: 'Ετικέτα </head> εσωτερικού κλεισίματος',
    insideBody: 'Ετικέτα </body> εσωτερικού κλεισίματος',
    headTag: 'σενάριο ετικέτας επικεφαλίδας',
    bodyTag: 'σενάριο ετικέτας σώματος κειμένου',
    service: 'υπηρεσία',
    integrate: 'Ενσωμάτωση',
    showOnLive: 'Εμφάνιση στον Live ιστότοπο',
    showOnLiveTitle: 'Όταν απενεργοποιείτε αυτόν τον διακόπτη, το εικονίδιο υπηρεσιών τρίτων δεν θα εμφανίζεται στην αρχική σελίδα του ιστότοπού σας.',
    apiSecret: 'Μυστικό API',
    apiSecretOrPass: 'Μυστικό API ή κωδικός πρόσβασης',
    apiKeyOrUsername: 'Κλειδί API ή όνομα χρήστη',
    sendEmailVia: 'Αποστολή email μέσω',
    smtpUser: 'Χρήστης SMTP',
    smtpPass: 'Κωδικός πρόσβασης SMTP',
    useSSL: 'Χρήση SSL',
    smtpHost: 'Εξυπηρετητής SMTP',
    smtpPort: 'Πύλη SMTP',
    emailsInfo: {
      text1: 'Για ορισμένους παρόχους SMTP απαιτείται να έχει γίνει εγγραφή του ίδιου email από την πλευρά τους.',
      text2: 'Βεβαιωθείτε ότι καλύπτεται αυτή η απαίτηση.',
    },
    emailsInfo1: 'Όλα τα προγραμματισμένα μηνύματα με αυτό το email θα μεταφερθούν στο πρόχειρο',
    blockCountries: 'Αποκλεισμός χωρών',
    blockCountriesMainText: 'Ο ιστότοπος θα γίνει μη διαθέσιμος από επιλεγμένες χώρες',
    blockIps: 'Αποκλεισμός IP',
    versionControl: 'Έλεγχος έκδοσης',
    policies: 'Πολιτικές',
    policy: 'Πολιτική',
    dateToNotify: 'Ημερομηνία ειδοποίησης',
    featureName: 'Όνομα λειτουργίας',
    noBrandData: 'Δεν υπάρχουν επωνυμίες για εμφάνιση',
    createBrand: 'Δημιουργία επωνυμίας',
    builder: 'Κατασκευαστής',
    platformState: 'Κατάσταση πλατφόρμας',
    minMaxWithdrawalAmount: 'Ελάχιστο-μέγιστο ποσό ανάληψης συστήματος',
    minWithdrawalAmount: 'Ελάχ. Ποσό ανάληψης',
    maxWithdrawalAmount: 'Μέγ. Ποσό ανάληψης',
    minMaxDepositAmount: 'Ελάχιστο-μέγιστο ποσό κατάθεσης συστήματος',
    minDepositAmount: 'Ελάχ. Ποσό κατάθεσης',
    maxDepositAmount: 'Μέγ. Ποσό κατάθεσης',
    operationFeePercent: 'Χρέωση λειτουργίας, %',
    withdrawalFeePercent: 'Χρέωση ανάληψης, %',
    withdrawalFee: 'Χρέωση ανάληψης',
    withdrawalFeeForUsedBalancePercent: 'Χρέωση ανάληψης για χρησιμοποιημένο υπόλοιπο, %',
    withdrawalFeeForUsedBalance: 'Χρέωση ανάληψης για χρησιμοποιημένο υπόλοιπο',
    allowedConversion: 'Επιτρεπόμενη μετατροπή',
    paymentMethodName: 'Όνομα μεθόδου πληρωμής',
    minAmount: 'Ελαχ. ποσό {{title}}',
    maxAmount: 'Μεγ. ποσό {{title}}',
    minMaxSystemAmount: 'Ελάχιστο-μέγιστο ποσό {{title}} συστήματος',
    depositFeePercent: 'Χρέωση κατάθεσης, %',
    depositFee: 'Χρέωση κατάθεσης',
    allowConversion: 'Να επιτρέπεται η μετατροπή',
    previewLive: 'Προεπισκόπηση Live',
    feeFromDeposit: 'Χρέωση από την κατάθεση',
    allowWithdrawalFromUnusedBalance: 'Να επιτρέπεται η ανάληψη από αχρησιμοποίητο υπόλοιπο',
    allowWithdrawalFeeUsedBalance: 'Το ποσό χρέωσης για την ανάληψη θα κρατηθεί από το εναπομένον υπόλοιπο.',
    feeCalculationForWithdrawals: 'Υπολογισμός χρέωσης για αναλήψεις',
    feeFromWithdrawals: 'Χρέωση από αναλήψεις',
    feeFromWithdrawalsUsedBalance: 'Χρέωση από αναλήψεις (χρησιμοποιημένο υπόλοιπο)',
    feeFromWithdrawalsBalance: 'Χρέωση από αναλήψεις ({{type}} υπόλοιπο)',
    unusedBalanceInfo: 'Όταν είναι ενεργοποιημένη, η επιλογή αχρησιμοποίητου υπολοίπου θα εμφανίζεται στη ρύθμιση Χρέωση από αναλήψεις',
    paymentTransactionType: 'Τύπος συναλλαγής πληρωμής',
    paymentMethodType: 'Τύπος μεθόδου πληρωμής',
    playerPaymentRelation: 'Σχέση παίκτη-πληρωμής',
    failedPaymentAttempts: 'Αποτυχημένες προσπάθειες πληρωμής',
    failedPaymentAttemptsPeriod: 'Περίοδος αποτυχημένων προσπαθειών πληρωμής',
    temporaryBlockPeriod: 'Περίοδος προσωρινού αποκλεισμού',
    reverseConversion: 'Αντίστροφη μετατροπή {{title}}',
    lCustomScript: 'προσαρμοσμένο σενάριο',
    largeTransactions: 'Μεγάλες συναλλαγές',
    lBrand: 'επωνυμία',
    playerSettings: 'Ρυθμίσεις παίκτη',
    verificationConfigurations: 'Διαμορφώσεις επαλήθευσης',
    theCurrency: 'Το νόμισμα',
    mainCountry: 'Κύρια χώρα',
    theLanguage: 'Η γλώσσα',
    theDomain: 'Ο τομέας',
    smsSettings: 'Ρυθμίσεις SMS',
    verificationSettings: 'Ρυθμίσεις επαλήθευσης',
    smtpSettings: 'Ρυθμίσεις SMTP',
    captchaSettings: 'Ρυθμίσεις Captcha',
    theEmail: 'Το email',
    conversions: 'Μετατροπές',
    theConversion: 'Η μετατροπή',
    theCountry: 'Η χώρα',
    playerVerificationSettings: 'Ρυθμίσεις επαλήθευσης παίκτη',
    domainRedirectionRule: 'Κανόνας ανακατεύθυνσης τομέα',
    wageringActivities: 'Δραστηριότητες στοιχηματισμού',
    theSportsBook: 'Το Sportsbook',
    sportsBook: 'Sportsbook',
    theSportsBookView: 'Η προβολή Sportsbook',
    setMainView: 'Ορισμός κύριας προβολής',
    feeForWithdrawalsWillBeHeld: 'Το ποσό χρέωσης για την ανάληψη θα κρατηθεί από το εναπομένον υπόλοιπο.',
    brandName: 'Όνομα επωνυμίας',
    defaultCountry: 'Προεπιλεγμένη χώρα',
    defaultLanguage: 'Προεπιλεγμένη γλώσσα',
    defaultCurrency: 'Προεπιλεγμένο νόμισμα',
    rgsActivation:
      'Αν ενεργοποιήσετε το προϊόν RGS, θα αποκτήσετε πρόσβαση σε ένα ολοκληρωμένο χαρτοφυλάκιο που περιέχει έως και 5000 παιχνίδια από παρόχους παγκόσμιας φήμης προσφέροντας έτσι κορυφαία εμπειρία παιχνιδιού στους παίκτες σας.',
    sportsBookActivation:
      'Αν ενεργοποιήσετε το προϊόν Sportsbook, θα αποκτήσετε πρόσβαση σε ένα ολοκληρωμένο περιβάλλον '
      + 'χρήστη με δυνατότητα προσαρμογής καθώς και χρήσης πολλαπλών γλωσσών και πολλαπλών νομισμάτων, που διαθέτει γεγονότα live και pre-match σε πάνω από 65 αθλήματα, 7500 πρωταθλήματα σε όλο τον κόσμο και πάνω από 3000 αγορές στοιχημάτων.',
    products: 'Προϊόντα',
    websiteTemplate: 'Πρότυπο ιστότοπου',
    failedPaymentInfo: 'Οι διαμορφώσεις δεν είναι διαθέσιμες λόγω αδράνειας του προτύπου «Διαδικασία αποτυχημένων πληρωμών». Ενεργοποιήστε το από την ενότητα «Πρότυπα».',
    depositWithdrawalPaymentMethodInfo:
      'Οι μέθοδοι πληρωμής που χρησιμοποιούν μετατροπή θα επηρεαστούν σε περίπτωση αλλαγών,'
      + ' και μπορεί να γίνει επαναρύθμιση των προσαρμοσμένων ορίων ελάχιστου/μέγιστου από το σύστημα, αν δεν είναι συνεπή με τις νέες αλλαγές. Βεβαιωθείτε ότι έχετε ελέγξει τα όρια και τη διαθεσιμότητα των νομισμάτων στις ρυθμίσεις των μεθόδων πληρωμής.',
    otherPaymentMethodInfo:
      'Οι εσωτερικές λειτουργίες που χρησιμοποιούν μετατροπή θα χρησιμοποιήσουν αυτές τις συναλλαγματικές ισοτιμίες. Βεβαιωθείτε ότι έχετε εφαρμόσει όλες τις μετατροπές (π.χ. Μπόνους παραπομπής).',
    conversionSettingsInfo: 'Το τρέχον όριο δεν είναι συνεπές με το σύστημα/όριο μετατροπής συστήματος.',
    keywords: 'Λέξεις-κλειδιά',
    eachKeyword: 'Κάθε λέξη-κλειδί',
    seoKeywordsInfo: 'Συνιστάται να έχετε 3-5 λέξεις-κλειδιά που χωρίζονται με κόμμα.',
    pageDescriptionInfo: 'Το προτεινόμενο μήκος είναι μέχρι 1000 χαρακτήρες.',
    pageTitleInfo: 'Το προτεινόμενο μήκος είναι μέχρι 200 χαρακτήρες.',
    card: 'Κάρτα',
    siteTwitterUsername: 'Ιστότοπος (Twitter @username)',
    imageAlt: 'Εναλλακτικό κείμενο εικόνας',
    recommendedSize: 'Προτεινόμενο μέγεθος',
    stateOrRegion: 'Πολιτεία/Περιφέρεια',
    socialLinks: 'Σύνδεσμοι μέσων κοινωνικής δικτύωσης',
    socialLinkInfo: 'Σελίδες προφίλ της εταιρείας σε διάφορους πόρους όπως Facebook, Twitter, Google+, Instagram, YouTube, LinkedIn, Wikipedia, κ.λπ. Μπορείτε να προσθέσετε όσα URL θέλετε.',
    image: 'Εικόνα',
    depositPaymentCategory: 'Κατηγορία πληρωμής κατάθεσης',
    withdrawalPaymentCategory: 'Κατηγορία πληρωμής ανάληψης',
    createNewBrand: 'Δημιουργία νέας επωνυμίας',
    lConversion: 'μετατροπή',
    logInAllowed: 'Η σύνδεση επιτρέπεται',
    logInAllowedNote: 'Όταν είναι ενεργοποιημένο, οι παίκτες μπορούν να συνδέονται και να κάνουν αναλήψεις μόνο',
    schemaMarkupSettings: 'Ρυθμίσεις σήμανσης δομημένων δεδομένων',
    seoSettings: 'Ρυθμίσεις SEO',
    openGraphSettings: 'Άνοιγμα ρυθμίσεων γραφήματος',
    twitterSettings: 'Ρυθμίσεις Twitter',
    accountId: 'Αναγνωριστικό λογαριασμού',
    accountIdInfo: 'Οποιοδήποτε όνομα ή κωδικός αναγνώρισης που σχετίζεται με το αναγνωριστικό Λογαριασμού υπηρεσίας που παρέχει συγκεκριμένο επίπεδο πρόσβασης',
    depositTaxesInfo: 'Αν δεν έχει ρυθμιστεί καμία διαμόρφωση φόρου για ένα νόμισμα, ο παίκτης δεν θα μπορεί να κάνει καταθέσεις μέσω αυτού του νομίσματος.',
    withdrawalTaxesInfo: 'Αν δεν έχει ρυθμιστεί καμία διαμόρφωση φόρου για ένα νόμισμα, ο παίκτης δεν θα μπορεί να κάνει αναλήψεις μέσω αυτού του νομίσματος.',
    ranges: 'Εύρη',
    depositAmountRanges: 'Εύρος ποσού κατάθεσης (Από - Έως)',
    taxPercentage: 'Ποσοστό φόρου %',
    lTaxes: 'φόροι',
    withdrawalAmountRanges: 'Εύρος ποσού ανάληψης (Από - Έως)',
    fixedAmountOrPercent: 'Σταθερό ποσό ή ποσοστό φόρου',
    allowWithdrawalViaTheDepositedMethods: 'Να επιτρέπεται ανάληψη μόνο μέσω των μεθόδων κατάθεσης',
    allowWithdrawalViaTheDepositedMethodsInfo: 'Αν ενεργοποιηθεί η ρύθμιση, ο παίκτης δεν θα μπορεί να κάνει ανάληψη χρημάτων μέσω μεθόδων επωνυμίας από τις οποίες δεν έχει κάνει ποτέ κατάθεση',
    spotsBookJackpotDescription: 'Όταν είναι ενεργοποιημένο το γραφικό στοιχείο Τζάκποτ αθλητικού στοιχήματος γίνεται διαθέσιμο στη Σελίδα προορισμού',
    spotsBookPrematchTopMatchesDescription: 'Όταν είναι ενεργοποιημένο το γραφικό στοιχείο Κορυφαίων αγώνων γίνεται διαθέσιμο στο Iframe του Sportsbook και στη Σελίδα προορισμού',
    spotsBookScrollingTypeDescription:
      'Η ρύθμιση ισχύει μόνο για τη Βασική προβολή και όταν είναι ενεργοποιημένη ο παίκτης μπορεί να δει ολόκληρη τη σελίδα Αθλητικού στοιχήματος με μία κύλιση του δρομέα',
    spotsBookTournamentBalanceDescription: 'Όταν είναι ενεργοποιημένο το υπόλοιπο τουρνουά γίνεται διαθέσιμο στην Αρχική σελίδα του Ιστότοπου',
    sportJackpot: 'Τζάκποτ αθλητικού στοιχήματος',
    sectionsScrolling: 'Κύλιση δρομέα στις ενότητες',
    sportTournamentBalance: 'Υπόλοιπο Αθλητικών τουρνουά',
    iframe: 'Iframe',
    native: 'Εγγενής',
    sportsBookIframeIntegrationDescription: 'Το Sportsbook θα είναι διαθέσιμο σε μορφή inline frame',
    sportsBookNativeIntegrationDescription: 'Το Sportsbook θα είναι διαθέσιμο άμεσα σε εσωτερική εφαρμογή',
    sportsBookMobileIntegrationType: 'Τύπος ενσωμάτωσης στο κινητό',
    setAsDefault: 'Ρύθμιση ως προεπιλογή',
    configs: 'Διαμορφώσεις',
    availableLayouts: 'Διαθέσιμες διατάξεις',
    setAmountRangesInfo:
      'Αν σε ένα εύρος έχουν οριστεί τόσο το «Σταθερό ποσό» όσο και το «Ποσοστό φόρου», το σύστημα θα πρέπει να υπολογίσει τον φόρο ως εξής: να παρακρατάει ένα σταθερό ποσό φόρου + ποσοστό x του ποσού που υπερβαίνει το ποσό που έχει καθοριστεί στο πεδίο «Από» του εύρους.',
    resetTaxNote: 'Θα πρέπει να σημειωθεί ότι μετά την επαναφορά των διαμορφώσεων φόρου, οι παίκτες δεν θα μπορούν να κάνουν καταθέσεις μέσω αυτού του νομίσματος.',
    depositAmountRangesHint: 'Το ποσό στο πεδίο «Από» στο «Εύρος ποσού κατάθεσης» περιλαμβάνεται σε αυτό το εύρος, ενώ το ποσό στο πεδίο «Έως» δεν περιλαμβάνεται στο εύρος.',
    withdrawalAmountRangesHint: 'Το ποσό στο πεδίο «Από» στο «Εύρος ποσού ανάληψης» περιλαμβάνεται σε αυτό το εύρος, ενώ το ποσό στο πεδίο «Έως» δεν περιλαμβάνεται στο εύρος.',
    fixedAmountHint:
      'Το ποσό στο πεδίο «Σταθερό ποσό» θα πρέπει να είναι χαμηλότερο ή ίσο με το ποσό που πληκτρολογήθηκε στο πεδίο «Από» στο «Εύρος ποσού ανάληψης». Αν το ποσό «Από» είναι 0, το ποσό του πεδίου «Σταθερό ποσό» θα πρέπει επίσης να είναι 0.',
    fixedAmount: 'Σταθερό ποσό',
    brandSportsBookActivation:
      'Αν ενεργοποιήσετε το προϊόν Sportsbook, θα αποκτήσετε πρόσβαση σε ένα ευρύ περιβάλλον χρήστη με δυνατότητα προσαρμογής με γεγονότα live και pre-match σε πάνω από 65 αθλήματα. Μετά την ενεργοποίησή του, το προϊόν μπορεί να απενεργοποιηθεί μόνο από τον πίνακα διαχειριστή του Sportsbook.',
    amountFrom: 'Ποσό Από',
    allowPlayerCompose: 'Να επιτρέπεται στους παίκτες να «συνθέτουν» μηνύματα',
    sportsBookInheritWebsiteColor: 'Διαδοχή χρώματος ιστότοπου',
    sportsBookInheritWebsiteColorDescription: 'Η ρύθμιση ισχύει σε όλες τις σελίδες αθλητικού στοιχήματος.',
    theBrand: 'Η επωνυμία',
    taxSettings: 'Ρυθμίσεις φόρων',
    infoUrlHint: 'Αυτό το URL θα χρησιμοποιηθεί στον ιστότοπο για την προβολή στοιχείων φορολόγησης.',
    everyMatrixDescription: 'Λύσεις τυχερών παιχνιδιών σπονδυλωτές και με γνώμονα τα API, σχεδιασμένες για να προτείνουν τολμηρές ιδέες',
    smarticoAffiliateDescription: 'Δεν υπάρχει καλύτερος τρόπος για να συνδεθείτε με τους πελάτες σας',
    hotjarDescription: 'Κατανοήστε πώς βιώνουν πραγματικά οι χρήστες τον ιστότοπό σας χωρίς να «πνίγεστε» σε αριθμούς',
    hotjarId: 'Αναγνωριστικό HotJar',
    googleAnalyticsId: 'Αναγνωριστικό Google Analytics',
    googleAnalyticsDescription: 'Αναλύει την χρήση στους ιστότοπούς σας',
    googleSiteVerificationCode: 'Κωδικός επαλήθευσης ιστότοπου Google',
    googleSearchConsoleDescription: 'Λάβετε βοήθεια για την παρακολούθηση, συντήρηση και επίλυση προβλημάτων σχετικά με την παρουσία του ιστότοπού σας σε αποτελέσματα αναζήτησης στο Google.',
    copyPasteFieldName: 'Αντιγράψτε το {{fieldName}} και επικολλήστε το στο παρακάτω πεδίο.',
    learnMore: 'Μάθετε περισσότερα',
    affiliateInfo: 'Δεν μπορούν να είναι ενεργές {{count}} ενσωματώσεις ταυτόχρονα',
    liveChatDescription: 'Διαδικτυακή εφαρμογή επικοινωνίας που επιτρέπει σε σας και στους επισκέπτες του ιστότοπού σας να συνομιλείτε σε πραγματικό χρόνο',
    liveAgentDescription: 'Παρέχετε άριστη εξυπηρέτηση πελατών',
    intercomDescription: 'Ξεκινήστε εδώ μια εξαιρετική σχέση με τον πελάτη',
    zendeskDescription: 'Επικοινωνήστε με τους πελάτες πιο αποτελεσματικά',
    intercomId: 'Αναγνωριστικό Intercom',
    zendeskId: 'Αναγνωριστικό Zendesk',
    liveChatLicenseNumber: 'ΑΡΙΘΜΟΣ ΑΔΕΙΑΣ Live συνομιλίας',
    liveAgentYourLa: 'Live πράκτορας YOUR-LA',
    liveAgentButtonID: 'Αναγνωριστικό κουμπιού Live πράκτορας',
    digitainAgIdInfo: 'Αυτό είναι το αναγνωριστικό της επωνυμίας στον πίνακα διαχειριστή «Πράκτορα Digitain»',
    depositCurrencyInfo:
      'Σε περίπτωση που επιτρέπεται στους παίκτες να κάνουν καταθέσεις με όλα τα νομίσματα που υποστηρίζονται από τις μεθόδους πληρωμής, η επιλογή «Να επιτρέπεται η μετατροπή» με τις υποστηριζόμενες διαμορφώσεις νομίσματος θα πρέπει να είναι ενεργή για τη μέθοδο πληρωμής.',
    pageGroups: 'Ομάδες σελίδας',
    digitainRetailInfo: 'Αυτό είναι το αναγνωριστικό της επωνυμίας στον πίνακα διαχειριστή «Digitain Retail»',
    smarticoDescription: 'Η λύση gamification που χρησιμοποιεί προκλήσεις και τεχνικές παιχνιδιών για να δίνει κίνητρο στους παίκτες',
    smarticoLabelKey: 'Κλειδί μάρκας',
    smarticoBrandKey: 'Κλειδί επωνυμίας',
    smarticoBrandKeyInfo: 'Ένα δημόσιο κλειδί για την επωνυμία',
    smarticoLabelKeyInfo: 'Ένα δημόσιο κλειδί για τη μάρκα',
    smarticoNote: 'Το Smartico CRM και το Gamification είναι διασυνδεδεμένα, κάθε ενέργεια σε αυτή την ενότητα επηρεάζει επίσης και το Gamification',
    realityCheckPeriod: 'Περίοδος Reality Check',
    prematchTopMatches: 'Prematch κορυφαίοι αγώνες',
    prematchTotalTopMatches: 'Prematch κορυφαίοι αγώνες (περιλαμβάνει τη συνολική αγορά)',
    spotsBookPrematchTotalTopMatchesDescription: 'Όταν είναι ενεργοποιημένο το γραφικό στοιχείο Prematch κορυφαίοι αγώνες, τα Δεδομένα συνολικής αγοράς γίνονται διαθέσιμα',
    livePrematchTopMatches: 'Live & Prematch κορυφαίοι αγώνες',
    spotsBookLivePrematchTopMatchesDescription: 'Όταν είναι ενεργοποιημένο, γίνεται διαθέσιμο το γραφικό στοιχείο Live & Prematch κορυφαίοι αγώνες',
    liveTopMatches: 'Live κορυφαίοι αγώνες',
    spotsBookLiveTopMatchesDescription: 'Όταν είναι ενεργοποιημένο, γίνεται διαθέσιμο το γραφικό στοιχείο Live κορυφαίοι αγώνες',
    verificationPeriodTabInfo: 'Σε αυτή την ενότητα μπορείτε να ορίσετε διαμορφώσεις για να αποκλείονται αυτόματα μη επαληθευμένοι λογαριασμοί παικτών για προσαρμοσμένες περιόδους.',
    periodCalculationSource: 'Πηγή υπολογισμού περιόδου',
    periodCalculationSourceInfo: 'Επιλέξτε την πηγή της ημερομηνίας στην οποία θα ξεκινήσει ο υπολογισμός της περιόδου επαλήθευσης',
    verificationPeriod: 'Περίοδος επαλήθευσης',
    verificationPeriodInfo: 'Ο αριθμός των ημερών που μπορεί να είναι ενεργός ένας λογαριασμός παίκτη πριν να γίνει επαλήθευση',
    periodsLimitsInfo: 'Σε αυτή την ενότητα μπορείτε να ρυθμίσετε συγκεκριμένη χρονική περίοδο κατά την οποία ο παίκτης μπορεί να επιλέξει προσωρινή διακοπή ή αυτοαποκλεισμό.',
    preferencesDescription: 'Ενεργοποίηση/απενεργοποίηση ειδοποιήσεων SMS και email για παίκτες.',
    preferencesSMSDescription: 'Το κομμάτι αποστολής SMS για όλους τους παίκτες της επωνυμίας και στον ιστότοπο μέσω SMS θα πρέπει να γίνει αόρατο',
    preferencesEmailDescription: 'Το κομμάτι αποστολής email για όλους τους παίκτες της επωνυμίας και στον ιστότοπο μέσω email θα πρέπει να γίνει αόρατο',
    walletsSettings: 'Ρυθμίσεις πορτοφολιών',
    wallets: 'Πορτοφόλια',
    features: 'Λειτουργίες',
    dda: 'DDA',
    ddaInfo: 'Αν ενεργοποιήσετε αυτή την επιλογή, οι φόροι επί της ανάληψης θα παρακρατηθούν στο DDA logic',
    whatsAppDescription: 'Επιτρέψτε στους πελάτες σας να επικοινωνούν μαζί σας με το πιο δημοφιλές σύστημα ανταλλαγής μηνυμάτων στον κόσμο.',
    phoneNumber: 'Αριθμός τηλεφώνου',
    whatsAppPhoneNumberInfo: 'Πληκτρολογήστε τον αριθμό τηλεφώνου με τον οποίον είναι εγγεγραμμένος ο λογαριασμός σας στο WhatsApp',
    multiGameLaunch: 'Έναρξη πολλαπλών παιχνιδιών',
    multiGameLaunchDescription: 'Ανάλογα με τη διαμόρφωση, η επιλογή πολλαπλών παιχνιδιών είναι διαθέσιμη στον ιστότοπο και οι παίκτες μπορούν να παίζουν πολλά παιχνίδια ταυτόχρονα.',
    jackpotWidget: 'Γραφικό στοιχείο τζάκποτ',
    jackpotWidgetDescription: 'Αναφέρεται στην ενεργοποίηση του γραφικού στοιχείου Τζάκποτ καζίνο για τη Σελίδα προορισμού. Διαθέσιμο στο γραφικό στοιχείο Τζάκποτ του παρόχου Amusnet.',
    gameIframe: 'Iframe παιχνιδιού',
    manuBarFixation: 'Σταθεροποίηση Κύριας μπάρας',
    manuBarFixationDescription: 'Μόνο για την προβολή για κινητά, ανάλογα με τη διαμόρφωση της Κύριας μπάρας για τα λόμπι.',
    paymentVisibilityInfo: 'Σε αυτή την ενότητα μπορείτε να κάνετε απόκρυψη και να μην επιτρέπετε τη χρήση μεθόδου πληρωμής για παίκτες που έχουν μία από τις επιλεγμένες ετικέτες',
    centrivoConnectAgDescription: 'Μεταμορφώστε την επιχείριση τυχερών παιχνιδιών σας με την Connect Agent Solution της Centrivo',
    retailCentrivoDescription: 'Μεταμορφώστε την επιχείριση τυχερών παιχνιδιών σας με τη Retail Solution της Centrivo',
    currencySymbol: 'Σύμβολο νομίσματος',
    timeOut: 'Προσωρινή διακοπή',
    inactivityIndicators: 'Δείκτες αδράνειας',
    inactivityIndicatorsInfo:
      'Με αυτή τη διαμόρφωση, ένας παίκτης θεωρείται ανενεργός με βάση έναν από τους διάφορους δείκτες, την ημερομηνία τελευταίας σύνδεσης του παίκτη ή την ημερομηνία των τελευταίων συναλλαγών (στοίχημα, κατάθεση, ανάληψη)',
    recoverByPlayer: 'Επαναφορά από παίκτη',
    recoverByPlayerInfo: 'Όταν ενεργοποιηθεί, οι παίκτες μπορούν να κάνουν επαναφορά της κατάστασής τους μέσω του κωδικού επαλήθευσης',
  },
};
