import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemErrorMessage } from 'components/common';
import { FormInputWrapper } from 'components/styledComponents/input/Input.styled';
import { StyledStatusChange } from 'components/brandSetting/BrandSettings.styled';
import { StyledFormMainWrapper } from 'components/styledComponents/form/Form.styled';
import { AppConstants } from 'constants/index';
import { FormItemMarginWrapper } from 'Global.styled';

const ChangeMainConfigs = (props) => {
  const { brand, form } = props;
  const { name, licenseId } = brand;

  const { t } = useTranslation();

  return (
    <StyledStatusChange>
      <StyledFormMainWrapper form={form} initialValues={{ name, licenseId }} layout="vertical">
        <FormItemMarginWrapper>
          <FormInputWrapper midSize>
            <Form.Item
              name="name"
              label={t('name')}
              rules={[
                {
                  required: true,
                  message: <FormItemErrorMessage errorMassage={(translate) => translate('validate:requiredField', { fieldName: translate('name') })} />,
                },
                {
                  pattern: AppConstants.regex.brandName,
                  message: <FormItemErrorMessage errorMassage={(translate) => translate('validate:brandName', { fieldName: translate('name') })} />,
                },
              ]}
            >
              <Input placeholder={t('typeHere')} autoComplete="off" allowClear />
            </Form.Item>
          </FormInputWrapper>
        </FormItemMarginWrapper>
        <FormInputWrapper midSize>
          <Form.Item
            name="licenseId"
            label={t('brand:licenseId')}
            rules={[
              {
                pattern: AppConstants.regex.notOnlySpaces3To50,
                message: <FormItemErrorMessage errorMassage={(translate) => translate('validate:notOnlySpaces3toMax', { fieldName: translate('brand:licenseId'), max: 50 })} />,
              },
            ]}
          >
            <Input placeholder={t('typeHere')} autoComplete="off" allowClear />
          </Form.Item>
        </FormInputWrapper>
      </StyledFormMainWrapper>
    </StyledStatusChange>
  );
};

ChangeMainConfigs.propTypes = {
  brand: PropTypes.object,
  form: PropTypes.object.isRequired,
};

ChangeMainConfigs.defaultProps = {
  brand: {},
};

export default ChangeMainConfigs;
