import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import qs from 'qs';
import CampaignV2ActionType from 'actions/campaignV2/campaignV2ActionType';
import { AppConstants } from 'constants/index';
import {
  changeCampaignV2BonusEndedStatus,
  changeCampaignV2GeneralConfigEndedStatus,
  changeCampaignV2ParticipationGroupEndedStatus,
  changeCampaignV2TriggerConditionEndedStatus,
  changeCampaignV2PublishEndedStatus,
  saveCampaignsV2,
  saveCampaignV2,
  saveCampaignV2FreeSpinBonus,
  saveCampaignV2ParticipationGroup,
  saveCampaignV2ParticipationGroups,
  saveCampaignV2FreeBetBonus,
  saveCampaignV2TriggerCondition,
  saveCampaignV2TriggerConditions,
  saveCreatedCampaignV2Id,
  saveCampaignV2Bonuses,
  saveCampaignV2SpecialBonus,
  saveCampaignV2CombinedBonus,
  changeCampaignV2BonusContentEndedStatus,
  saveCampaignV2BonusContents,
  changeCampaignV2CampaignContentEndedStatus,
  saveCampaignV2CampaignContents,
  saveCampaignV2CampaignContent,
  saveCampaignV2BonusContent,
  saveCampaignV2Units,
  saveCampaignV2Unit,
  changeCampaignV2UnitEndedStatus,
} from 'actions/campaignV2/campaignV2ActionCreator';
import {
  setSuccessMessage, changeEndedStatus, setErrorMessage, changeLoadingState,
} from 'actions/setting/settingActionCreator';
import { getMessage, getError } from 'utils/helpers';
import i18n from 'services/i18n';

import NetworkService from 'services/networkService';

function* getCampaigns({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, Campaigns, V2 } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2], options);
    yield put(saveCampaignsV2(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaign({ payload }) {
  try {
    const { id } = payload;
    const { BonusAdmin, Campaigns, V2 } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id]);
    yield put(saveCampaignV2(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCampaign({ payload }) {
  try {
    const { name } = payload.data;
    const { BonusAdmin, Campaigns, V2 } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2], payload);
    const { id } = data;
    yield put(saveCreatedCampaignV2Id(id));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaign'), i18n.t('notification:slCreated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCampaign({ payload }) {
  try {
    const { id, data } = payload;
    const { BonusAdmin, Campaigns, V2 } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, id], { data });
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:campaign'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createParticipationGroup({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, ParticipationConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, campaignId, ParticipationConditions], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lParticipationGroup'), i18n.t('notification:slCreated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeCampaignV2ParticipationGroupEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editParticipationGroup({ payload }) {
  try {
    const { campaignId, id, data } = payload;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, ParticipationConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, ParticipationConditions, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:participationGroup'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
    yield put(changeCampaignV2ParticipationGroupEndedStatus(true));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getParticipationGroup({ payload }) {
  try {
    const { campaignId, id } = payload;
    const {
      BonusAdmin, Campaigns, V2, ParticipationConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, campaignId, ParticipationConditions, id], payload);
    yield put(changeLoadingState(false));
    yield put(saveCampaignV2ParticipationGroup(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getParticipationGroups({ payload }) {
  try {
    const { id } = payload;
    const {
      BonusAdmin, Campaigns, V2, ParticipationConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, ParticipationConditions], payload);
    yield put(changeLoadingState(false));
    yield put(saveCampaignV2ParticipationGroups(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteParticipationGroup({ payload }) {
  try {
    const { campaignId, id, name } = payload;
    const {
      BonusAdmin, Campaigns, V2, ParticipationConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, ParticipationConditions, id], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lParticipationGroup'), i18n.t('notification:slDeleted'), name)));
    yield put(changeLoadingState(false));
    yield put(changeCampaignV2ParticipationGroupEndedStatus(true));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getFreeSpinBonus({ payload }) {
  try {
    const { campaignId, id } = payload;
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Spins,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Spins, id]);
    yield put(saveCampaignV2FreeSpinBonus(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getFreeBetBonus({ payload }) {
  try {
    const { campaignId, id } = payload;
    const {
      BonusAdmin, Campaigns, V2, FreeBets, Bonuses,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, FreeBets, id]);
    yield put(saveCampaignV2FreeBetBonus(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createFreeSpinBonus({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Spins,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Spins], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slCreated'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createFreeBetBonus({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, FreeBets, Bonuses,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, FreeBets], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slCreated'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editFreeSpinBonus({ payload }) {
  try {
    const { campaignId, data, id } = payload;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Spins,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Spins, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:bonus'), i18n.t('notification:slUpdated'))));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editFreeBetBonus({ payload }) {
  try {
    const { campaignId, id, data } = payload;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Bonuses, FreeBets,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, FreeBets, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:bonus'), i18n.t('notification:slUpdated'))));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBonuses({ payload }) {
  try {
    const { id } = payload;
    const {
      BonusAdmin, Campaigns, V2, Bonuses,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, Bonuses], payload);
    yield put(changeLoadingState(false));
    yield put(saveCampaignV2Bonuses(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteFreeBetBonus({ payload }) {
  try {
    const { campaignId, id, name } = payload;
    const {
      BonusAdmin, Campaigns, V2, Bonuses, FreeBets,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, FreeBets, id], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slDeleted'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteFreeSpinBonus({ payload }) {
  try {
    const { campaignId, id, name } = payload;
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Spins,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Spins, id], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slDeleted'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCombinedBonus({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Combined, Bonuses,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Combined], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slCreated'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCombinedBonus({ payload }) {
  try {
    const { campaignId, id, data } = payload;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Combined,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Combined, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:bonus'), i18n.t('notification:slUpdated'))));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCombinedBonus({ payload }) {
  try {
    const { campaignId, id, name } = payload;
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Combined,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Combined, id], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slDeleted'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCombinedBonus({ payload }) {
  try {
    const { campaignId, id } = payload;
    const {
      BonusAdmin, Campaigns, V2, Combined, Bonuses,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Combined, id]);
    yield put(saveCampaignV2CombinedBonus(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2TriggerConditions({ payload }) {
  try {
    const {
      BonusAdmin, Campaigns, V2, TriggerConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, payload.id, TriggerConditions]);
    yield put(saveCampaignV2TriggerConditions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCampaignV2TriggerCondition({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const {
      BonusAdmin, Campaigns, V2, TriggerConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, id, TriggerConditions], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:conditionsGroup'), i18n.t('notification:slCreated'), name)));
    yield put(changeCampaignV2TriggerConditionEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCampaignV2TriggerCondition({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name, id } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, TriggerConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, TriggerConditions, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:conditionsGroup'), i18n.t('notification:slEdited'), name)));
    yield put(changeCampaignV2TriggerConditionEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCampaignV2TriggerCondition({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { id, name } = data;
    const {
      BonusAdmin, Campaigns, V2, TriggerConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, TriggerConditions, id]);
    yield put(changeCampaignV2TriggerConditionEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lTriggerCondition'), i18n.t('notification:slDeleted'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2TriggerCondition({ payload }) {
  try {
    const { campaignId, id } = payload;

    const {
      BonusAdmin, Campaigns, V2, TriggerConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, campaignId, TriggerConditions, id]);
    yield put(saveCampaignV2TriggerCondition(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createSpecialBonus({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Specials,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Specials], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slCreated'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editSpecialBonus({ payload }) {
  try {
    const { campaignId, data, id } = payload;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Specials,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Specials, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:bonus'), i18n.t('notification:slUpdated'))));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteSpecialBonus({ payload }) {
  try {
    const { campaignId, id, name } = payload;
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Specials,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Specials, id], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slDeleted'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSpecialBonus({ payload }) {
  try {
    const { campaignId, id } = payload;
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Specials,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Specials, id]);
    yield put(saveCampaignV2SpecialBonus(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* publishCampaignV2({ payload }) {
  try {
    const { id, data, name } = payload;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Publish,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, id, Publish], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaign'), i18n.t('notification:slPublished'), name)));
    yield put(changeCampaignV2PublishEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2BonusContents({ payload }) {
  try {
    const {
      BonusAdmin, Campaigns, V2, BonusContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, payload.id, BonusContents]);
    yield put(saveCampaignV2BonusContents(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCampaignV2BonusContent({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { bonusName } = data;
    const {
      BonusAdmin, Campaigns, V2, BonusContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, campaignId, BonusContents], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:contentAndNotification'), i18n.t('notification:slCreated'), bonusName)));
    yield put(changeCampaignV2BonusContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCampaignV2BonusContent({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { bonusName } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, BonusContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, BonusContents], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:contentAndNotification'), i18n.t('notification:plUpdated'), bonusName)));
    yield put(changeCampaignV2BonusContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCampaignV2BonusContent({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { bonusId, bonusName } = data;
    const {
      BonusAdmin, Campaigns, V2, BonusContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, BonusContents, bonusId]);
    yield put(changeCampaignV2BonusContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:contentAndNotification'), i18n.t('notification:slDeleted'), bonusName)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2BonusContent({ payload }) {
  try {
    const { campaignId, id } = payload;

    const {
      BonusAdmin, Campaigns, V2, BonusContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, campaignId, BonusContents, id]);
    yield put(saveCampaignV2BonusContent(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2CampaignContents({ payload }) {
  try {
    const {
      BonusAdmin, Campaigns, V2, Contents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, payload.id, Contents]);
    yield put(saveCampaignV2CampaignContents(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCampaignV2CampaignContent({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { languageId, languageName } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Contents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, campaignId, Contents, languageId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaignContent'), i18n.t('notification:slCreated'), languageName)));
    yield put(changeCampaignV2CampaignContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCampaignV2CampaignContent({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { languageId, languageName } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Contents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, Contents, languageId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaignContent'), i18n.t('notification:plUpdated'), languageName)));
    yield put(changeCampaignV2CampaignContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCampaignV2CampaignContent({ payload }) {
  try {
    const { campaignId, languageId, data } = payload;
    const { languageName } = data;
    const {
      BonusAdmin, Campaigns, V2, Contents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, Contents, languageId]);
    yield put(changeCampaignV2CampaignContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaignContent'), i18n.t('notification:slDeleted'), languageName)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2CampaignContent({ payload }) {
  try {
    const { id, languageId } = payload;

    const {
      BonusAdmin, Campaigns, V2, Contents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, Contents, languageId]);
    yield put(saveCampaignV2CampaignContent(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getUnits({ payload }) {
  try {
    const { id } = payload;
    const {
      BonusAdmin, Campaigns, V2, Units,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, Units], payload);
    yield put(changeLoadingState(false));
    yield put(saveCampaignV2Units(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getUnit({ payload }) {
  try {
    const { campaignId, id } = payload;
    const {
      BonusAdmin, Campaigns, V2, Units,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, campaignId, Units, id], payload);
    yield put(changeLoadingState(false));
    yield put(saveCampaignV2Unit(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createUnit({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Units,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, campaignId, Units], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lUnit'), i18n.t('notification:slCreated'), name)));
    yield put(changeCampaignV2UnitEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editUnit({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name, id } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Units,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, Units, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lUnit'), i18n.t('notification:slEdited'), name)));
    yield put(changeCampaignV2UnitEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteUnit({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { id, name } = data;
    const {
      BonusAdmin, Campaigns, V2, Units,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, Units, id]);
    yield put(changeCampaignV2UnitEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lUnit'), i18n.t('notification:slDeleted'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* campaignV2Saga() {
  yield all([
    takeLatest(CampaignV2ActionType.GET_CAMPAIGNS_V2, getCampaigns),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2, getCampaign),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2, createCampaign),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2, editCampaign),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_PARTICIPATION_GROUP, createParticipationGroup),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_PARTICIPATION_GROUP, editParticipationGroup),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_PARTICIPATION_GROUP, getParticipationGroup),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_PARTICIPATION_GROUPS, getParticipationGroups),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_PARTICIPATION_GROUP, deleteParticipationGroup),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_FREE_BET_BONUS, createFreeBetBonus),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_FREE_BET_BONUS, editFreeBetBonus),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_FREE_BET_BONUS, getFreeBetBonus),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_FREE_SPIN_BONUS, createFreeSpinBonus),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_FREE_SPIN_BONUS, editFreeSpinBonus),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_FREE_SPIN_BONUS, getFreeSpinBonus),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_BONUSES, getBonuses),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_FREE_BET_BONUS, deleteFreeBetBonus),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_FREE_SPIN_BONUS, deleteFreeSpinBonus),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_TRIGGER_CONDITIONS, getCampaignV2TriggerConditions),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_TRIGGER_CONDITION, createCampaignV2TriggerCondition),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_TRIGGER_CONDITION, editCampaignV2TriggerCondition),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_TRIGGER_CONDITION, deleteCampaignV2TriggerCondition),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_TRIGGER_CONDITION, getCampaignV2TriggerCondition),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_SPECIAL_BONUS, createSpecialBonus),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_SPECIAL_BONUS, editSpecialBonus),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_SPECIAL_BONUS, deleteSpecialBonus),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_SPECIAL_BONUS, getSpecialBonus),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_COMBINED_BONUS, getCombinedBonus),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_COMBINED_BONUS, createCombinedBonus),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_COMBINED_BONUS, editCombinedBonus),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_COMBINED_BONUS, deleteCombinedBonus),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_BONUS_CONTENTS, getCampaignV2BonusContents),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_BONUS_CONTENT, createCampaignV2BonusContent),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_BONUS_CONTENT, editCampaignV2BonusContent),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_BONUS_CONTENT, deleteCampaignV2BonusContent),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_BONUS_CONTENT, getCampaignV2BonusContent),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_CAMPAIGN_CONTENTS, getCampaignV2CampaignContents),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_CAMPAIGN_CONTENT, createCampaignV2CampaignContent),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_CAMPAIGN_CONTENT, editCampaignV2CampaignContent),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_CAMPAIGN_CONTENT, deleteCampaignV2CampaignContent),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_CAMPAIGN_CONTENT, getCampaignV2CampaignContent),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_UNITS, getUnits),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_UNIT, getUnit),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_UNIT, createUnit),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_UNIT, editUnit),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_UNIT, deleteUnit),
    takeLatest(CampaignV2ActionType.PUBLISH_CAMPAIGN_V2, publishCampaignV2),
  ]);
}
