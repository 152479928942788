export default {
  en: {
    home: 'Home',
    dashboard: 'Dashboard',
    brands: 'Brands',
    players: 'Players',
    testPlayers: 'Test Players',
    testPlayer: 'Test Player',
    kycReport: 'KYC Report',
    financials: 'Financials',
    deposits: 'Deposits',
    lDeposits: 'deposits',
    withdrawals: 'Withdrawals',
    lWithdrawals: 'withdrawals',
    bets: 'Bets',
    bonusManagement: 'Bonus Management',
    bonuses: 'Bonuses',
    bonusTemplates: 'Bonus Templates',
    templates: 'Templates',
    rules: 'Rules',
    cashback: 'Cashback',
    lCashback: 'cashback',
    lCashbacks: 'cashbacks',
    promoCodes: 'Promo Codes',
    referralBonus: 'Referral Bonus',
    riskManagement: 'Risk Management',
    similarAccounts: 'Similar Accounts',
    bulkActions: 'Bulk Actions',
    blacklist: 'Blacklist',
    tools: 'Tools',
    bonusSetup: 'Bonus Setup',
    brandAutomation: 'Brand Automation',
    tag: 'Tag',
    lTag: 'tag',
    tags: 'Tags',
    marketing: 'Marketing',
    market: 'Market',
    playerSegments: 'Player Segments',
    gameSegments: 'Game Segments',
    notificationTemplates: 'Notification Templates',
    lNotificationTemplate: 'notification template',
    massMessages: 'Mass Messages',
    inboxMessages: 'Inbox Messages',
    cms: 'CMS',
    translations: 'Translations',
    systemNotifications: 'System Notifications',
    lSystemNotifications: 'system notification',
    systemNotification: 'System Notification',
    fileManager: 'File Manager',
    reporting: 'Reporting',
    businessIntelligence: 'Business Intelligence',
    compliance: 'Compliance',
    partners: 'Partners',
    partner: 'Partner',
    userManagement: 'User Management',
    users: 'Users',
    permissionGroups: 'Permission Groups',
    permissions: 'Permissions',
    ipWhitelist: 'IP Whitelist',
    auditLog: 'Audit Log',
    systemConversion: 'System Conversion',
    systemSettings: 'System Settings',
    mainSettings: 'Main Settings',
    sportsBook: 'Sportsbook',
    lSportsBook: 'sportsbook',
    providersAndGames: 'Providers and Games',
    payments: 'Payments',
    payment: 'Payment',
    operatorLimits: 'Operator Limits',
    operator: 'Operator',
    limits: 'Limits',
    thirdPartyServices: 'Third Party Services',
    notificationSettings: 'Notification Settings',
    seoSettings: 'SEO Settings',
    locationServices: 'Location Services',
    conversionSettings: 'Conversion Settings',
    legal: 'Legal',
    standards: 'Standards',
    casinoGames: 'Casino Games',
    account: 'Account',
    lAccount: 'account',
    myAccount: 'My Account',
    changePass: 'Change Password',
    logOut: 'Log Out',
    logo: 'Logo',
    nightMode: 'Night Mode',
    lightMode: 'Light Mode',
    quickSearch: 'Quick Search',
    add: 'Add',
    gameId: 'Game ID',
    cancel: 'Cancel',
    search: 'Search',
    country: 'Country',
    actions: 'Actions',
    action: 'Action',
    registrationDate: 'Registration Date',
    timeZone: 'Time Zone',
    language: 'Language',
    currency: 'Currency',
    edit: 'Edit',
    view: 'View',
    collapse: 'Collapse',
    expand: 'Expand',
    disabled: 'Disabled',
    enabled: 'Enabled',
    disable: 'Disable',
    enable: 'Enable',
    lDisable: 'disable',
    lEnable: 'enable',
    currencyId: 'Currency ID',
    currencyName: 'Currency Name',
    languageId: 'Language ID',
    languageName: 'Language Name',
    selectAll: 'Select All',
    status: 'Status',
    name: 'Name',
    providerName: 'Provider Name',
    provider: 'Provider',
    lProvider: 'provider',
    games: 'Games',
    game: 'Game',
    dateAdded: 'Date Added',
    addedBy: 'Added By',
    select: 'Select',
    categories: 'Categories',
    email: 'Email',
    lEmail: 'email',
    senderName: 'Sender Name',
    default: 'Default',
    logs: 'Logs',
    logId: 'Log ID',
    dateUpdated: 'Date Updated',
    updatedBy: 'Updated By',
    description: 'Description',
    ipAddress: 'IP Address',
    bet: 'Bet',
    win: 'Win',
    deposit: 'Deposit',
    withdrawal: 'Withdrawal',
    reset: 'Reset',
    transaction: 'Transaction',
    valueBefore: 'Value Before',
    valueAfter: 'Value After',
    period: 'Period',
    policy: 'Policy',
    version: 'Version',
    effectiveDate: 'Effective Date',
    endDate: 'End Date',
    creationDate: 'Creation Date',
    createdBy: 'Created By',
    paymentMethod: 'Payment Method',
    paymentMethods: 'Payment Methods',
    type: 'Type',
    restrictedCountries: 'Restricted Countries',
    countries: 'Countries',
    lastUpdateDate: 'Last Update Date',
    lastUpdateBy: 'Last Update By',
    update: 'Update',
    fee: 'Fee',
    limit: 'Limit',
    limitBefore: 'Limit Before',
    save: 'Save',
    discard: 'Discard',
    apply: 'Apply',
    active: 'Active',
    inactive: 'Inactive',
    pending: 'Pending',
    lPending: 'pending',
    verified: 'Verified',
    failed: 'Failed',
    info: 'Info',
    captcha: 'Captcha',
    apiKey: 'API Key',
    secretKey: 'Secret Key',
    timeOutWebsite: 'Time-out Website, Webmobile, Pwapp',
    timeOutNativeApp: 'Time-out Native applications',
    mins: 'mins',
    hours: 'hours',
    hour: 'hour',
    days: 'days',
    months: 'months',
    documentType: 'Document Type',
    sportBetting: 'Sport Betting',
    casinoBetting: 'Casino Betting',
    sport: 'Sport',
    address: 'Address',
    lAddress: 'address',
    identity: 'Identity',
    lIdentity: 'identity',
    physical: 'Physical',
    reason: 'Reason',
    comment: 'Comment',
    text: 'Text',
    filter: 'Filter',
    lDomain: 'domain',
    count: 'Count',
    noData: 'No Data',
    category: 'Category',
    autoUpdate: 'Auto-Update',
    all: 'All',
    clear: 'Clear',
    show: 'Show',
    back: 'Back',
    both: 'Both',
    gameName: 'Game Name',
    yes: 'Yes',
    no: 'No',
    connect: 'Connect',
    systemState: 'System State',
    id: 'ID',
    enterId: 'Enter ID',
    delete: 'Delete',
    block: 'Block',
    lBlock: 'block',
    lUnblock: 'unblock',
    import: 'Import',
    export: 'Export',
    create: 'Create',
    statuses: 'Statuses',
    website: 'Website',
    procedures: 'Procedures',
    activate: 'Activate',
    deactivate: 'Deactivate',
    lDeactivate: 'deactivate',
    lActivate: 'activate',
    seeLess: 'See Less',
    seeMore: 'See More',
    changes: 'Changes',
    showTotal: '{{min}}-{{max}} of {{total}} item(s)',
    triggerDesc: 'Click sort by descend',
    triggerAsc: 'Click sort by ascend',
    cancelSort: 'Click to cancel sort',
    expandRow: 'Expand row',
    collapseRow: 'Collapse row',
    selectionAllData: 'Select all data',
    overview: 'Overview',
    settings: 'Settings',
    system: 'System',
    verification: 'Verification',
    recipients: 'Recipients',
    statistics: 'Statistics',
    currencies: 'Currencies',
    segments: 'Segments',
    segment: 'Segment',
    sendFrom: 'Send From',
    subject: 'Subject',
    sender: 'Sender',
    canceledBy: 'Canceled By',
    finish: 'Finish',
    balance: 'Balance',
    lPlayer: 'player',
    manage: 'Manage',
    configure: 'Configure',
    totalPlayers: 'Total Players',
    auditLogs: 'Audit Logs',
    composeOptions: 'Compose Options',
    compose: 'Compose',
    selectBonusType: 'Select Bonus Type',
    gamesInSegment: 'Games in Segment',
    playersInSegment: 'Players in Segment',
    statusUpdate: 'Status Update',
    balanceAdjustments: 'Balance Adjustments',
    restrictedPlayers: 'Restricted Players',
    referees: 'Referees',
    participants: 'Participants',
    txnDetails: 'TXN Details',
    casinoBetsReport: 'Casino Bets Report',
    clone: 'Clone',
    details: 'Details',
    general: 'General',
    next: 'Next',
    invalidPlayers: 'Invalid Players',
    send: 'Send',
    reSend: 'Resend',
    now: 'Now',
    downloadSample: 'Download Sample',
    download: 'Download',
    uploadList: 'Upload list',
    playerUploadFormatMsg: 'You can upload player lists. Only CSV format is acceptable',
    memorySize: {
      bytes: 'Bytes',
      kb: 'KB',
      mb: 'MB',
      gb: 'GB',
    },
    exportCsv: 'Export CSV',
    csvWithDetails: 'CSV (With Details)',
    columns: 'Columns',
    playerId: 'Player ID',
    username: 'Username',
    firstName: 'First Name',
    lastName: 'Last Name',
    accountStatus: 'Account Status',
    startDate: 'Start Date',
    gender: 'Gender',
    birthDate: 'Birthdate',
    document: 'Document',
    city: 'City',
    mobileNumber: 'Mobile Number',
    documentNumber: 'Document Number',
    lastLoginDate: 'Last Login Date',
    assign: 'Assign',
    position: 'Position',
    amount: 'Amount',
    ggr: 'GGR',
    brand: 'Brand',
    fullName: 'Full Name',
    dateUploaded: 'Date Uploaded',
    uploadedBy: 'Uploaded By',
    player: 'Player',
    approve: 'Approve',
    reject: 'Reject',
    from: 'From',
    to: 'To',
    casino: 'Casino',
    lCasino: 'casino',
    transactions: 'Transactions',
    lTransactions: 'transactions',
    verify: 'Verify',
    lVerify: 'verify',
    lUnverify: 'unverify',
    notVerified: 'Not Verified',
    feePercent: 'Fee, %',
    convertedAmount: 'Converted Amount',
    conversionRate: 'Conversion Rate',
    note: 'Note',
    addNote: 'Add Note',
    balanceBefore: 'Balance Before',
    balanceAfter: 'Balance After',
    txnId: 'TXN ID',
    extTxnId: 'Ext. TXN ID',
    state: 'State',
    bonusId: 'Bonus ID',
    betAmount: 'Bet Amount',
    winAmount: 'Win Amount',
    platform: 'Platform',
    ip: 'IP',
    betType: 'Bet Type',
    odd: 'Odd',
    adjustmentType: 'Adjustment Type',
    operationType: 'Operation Type',
    datePerformed: 'Date Performed',
    transactionId: 'Transaction ID',
    performedBy: 'Performed By',
    dateRange: 'Date Range',
    comments: 'Comments',
    timestamp: 'Timestamp',
    userId: 'User ID',
    browser: 'Browser',
    resource: 'Resource',
    deviceID: 'Device ID',
    rangeDates: 'Range Dates',
    notFound: 'Not Found',
    upload: 'Upload',
    acceptableFormat: 'Acceptable format',
    maxUploadSize: 'File size cannot exceed',
    maxSumUploadSize: 'Max sum upload size cannot exceed',
    fileDetails: 'File Details',
    csvSample: 'CSV Sample',
    invalidKeys: 'Invalid Keys',
    middleName: 'Middle Name',
    fathersName: "Father's Name",
    source: 'Source',
    tfa: '2 Factor Authentication',
    move: 'Move',
    merge: 'Merge',
    color: 'Color',
    numbersRange: {
      min: 'Min',
      max: 'Max',
    },
    day: 'day',
    operationDates: 'Operation Dates',
    trigger: 'Trigger',
    languages: 'Languages',
    location: 'Location',
    created: 'Created',
    updated: 'Updated',
    value: 'Value',
    totalAmount: 'Total Amount',
    single: 'Single',
    bulk: 'Bulk',
    managePermissions: 'Manage Permissions',
    depositId: 'Deposit ID',
    pageCantBeDisplayed: "Page can't be displayed",
    youDontHavePermission: "Page URL is invalid or you don't have permission to view it.",
    lCategory: 'category',
    thePlayer: 'The player',
    accounts: 'Accounts',
    password: 'Password',
    templateName: 'Template Name',
    allPlayers: 'All Players',
    segmentName: 'Segment Name',
    attach: 'Attach',
    schedule: 'Schedule',
    body: 'Body',
    templateId: 'Template ID',
    confirm: 'Confirm',
    accountInfo: 'Account Info',
    company: 'Company',
    selectCashbackType: 'Select Cashback Type',
    misConfigured: 'Misconfigured',
    attentionMarked: 'Attention Marked',
    paymentProvider: 'Payment Provider',
    submissionDate: 'Submission Date',
    ngr: 'NGR',
    icon: 'Icon',
    betId: 'Bet ID',
    paymentRequestId: 'Payment Request ID',
    roundIdCouponNumber: 'Round ID/Coupon Number',
    dateDuration: '{{hour}} {{minute}} {{second}}',
    noCardSelected: 'No Card Selected',
    backToList: 'Back to List',
    time: {
      hour: 'h',
      minute: 'm',
      second: 's',
    },
    duration: 'Duration',
    browserId: 'Browser ID',
    preview: 'Preview',
    sportBetsReport: 'Sport Bets Report',
    lMinutes: 'minutes',
    pageNotFound: 'Oops! page not found',
    title: 'Title',
    zipCode: 'Zip Code',
    url: 'URL',
    rate: 'Rate',
    conversionRates: 'Conversion Rates',
    conversionOrConversions: 'Conversion(s)',
    gameManagement: 'Game Management',
    date: 'Date',
    restricted: 'Restricted',
    maintenanceState: 'Maintenance State',
    underMaintenance: 'Under Maintenance',
    noMaintenance: 'No Maintenance',
    gameSystemState: 'Game System State',
    pages: 'Pages',
    groupName: 'Group Name',
    betMinusWin: 'Bet - Win',
    today: 'Today',
    lastWeek: 'Last Week',
    lastMonth: 'Last Month',
    yesterday: 'Yesterday',
    messages: 'Messages',
    providers: 'Providers',
    unVerify: 'Unverify',
    deviceType: 'Device Type',
    totalWithdrawals: 'Total Withdrawals',
    totalDeposits: 'Total Deposits',
    viewAll: 'View All',
    thisMonth: 'This Month',
    thisWeek: 'This Week',
    typeHere: 'Type here',
    reasonsSystem: 'Reasons System',
    campaigns: 'Campaigns',
    noResults: 'No Results',
    shared: 'Shared',
    tagCategories: 'Tag Categories',
    reasons: 'Reasons',
    verticalTags: 'Vertical Tags',
    horizontalTags: 'Horizontal Tags',
    addNew: 'Add New',
    taxes: 'Taxes',
    widgets: 'Widgets',
    lMobileNumber: 'mobile number',
    infoUrl: 'Info URL',
    previousAccounts: 'Previous Accounts',
    agSentData: 'AG Sent Data',
    developmentTools: 'Development Tools',
    keys: 'Keys',
    copy: 'Copy',
    lobbyManagement: 'Lobby Management',
    widget: 'Widget',
    expirationPeriod: 'Expiration period',
    spinsCount: 'Spins Count',
    lNow: 'now',
    selected: 'Selected',
    playerName: 'Player Name',
    paymentId: 'Payment ID',
    maxAmount: 'Max Amount',
    totalBonuses: 'Total Bonuses',
    totalCount: 'Total Count',
    generateReport: 'Generate Report',
    generateReportNote: 'After the statement is generated, you will receive an email reminder. The statement will be stored for 7 days in the <0>Export Center</0>.',
    exportCenter: 'Export Center',
    validTill: 'Valid Till',
    remove: 'Remove',
    ok: 'OK',
    aggregator: 'Aggregator',
    providerId: 'Provider ID',
    systemCategory: 'System Category',
    demoVersion: 'Demo Version',
    gameState: 'Game State',
    gameBrandState: 'Game Brand State',
    gameGlobalState: 'Game Global State',
    providerList: 'Provider List',
    gameList: 'Game List',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    changePassword: 'Change Password',
    pushNotifications: 'Push Notifications',
    total: 'Total',
    more: 'More',
    notification: 'Notification',
    templateWasDeleted: 'The template was deleted and is no longer available',
    methodType: 'Method Type',
    playerBonusId: 'Player Bonus ID',
    spinCount: 'Spin Count',
    spinAmount: 'Spin Amount',
    activationDate: 'Activation Date',
    receivedDate: 'Received Date',
    activatedBy: 'Activated By',
    extId: 'Ext. ID',
    feeAmount: 'Fee Amount',
    taxAmount: 'Tax Amount',
    taxPercent: 'Tax %',
    taxTotalAmount: 'Total Tax Amount',
    amountAfterTaxes: 'Amount after Taxes',
    conversionCurrency: 'Conversion Currency',
    operationDate: 'Operation Date',
    dateOfBirth: 'Date of Birth',
    uDays: 'Days',
    timePeriod: 'Time Period',
    fullDay: 'Full Day',
    section: 'Section',
    bonusBetAmount: 'Bonus Bet Amount',
    bonusWinAmount: 'Bonus Win Amount',
    brandId: 'Brand ID',
    regulatory: 'Regulatory',
    betCount: 'Bet Count',
    winCount: 'Win Count',
    taxation: 'Taxation',
    campaignName: 'Campaign Name',
    bonusFamily: 'Bonus Family',
    bonusType: 'Bonus Type',
    bonusAmount: 'Bonus Amount',
    betStatusCorrection: 'Bet Status Correction',
    roundId: 'Round ID',
    currentValue: 'Current value',
    none: 'None',
    sportStatus: 'Sport Status',
    unselect: 'Unselect',
    tagLogs: 'Tag Logs',
    romanian: 'Romanian',
    cypriot: 'Cypriot',
    crm: 'CRM',
    sms: 'SMS',
    betBuilder: 'Bet Builder',
    join: 'Join',
    joinDate: 'Join Date',
    uploadImageInfo: 'Acceptable formats are: {{formats}} and size cannot exceed {{size}}{{unit}}',
    expirationDate: 'Expiration Date',
    transferTo: 'Transfer To',
    manageMaintenance: 'Manage Maintenance',
    dynamicSegment: 'Dynamic Segment',
    european: 'European',
    deviceSessions: 'Device Sessions',
    sessionId: 'Session ID',
    weekDays: 'Weekdays',
    attribute: 'Attribute',
    priority: 'Priority',
    region: 'Region',
    categoryOrTag: 'Category | Tag',
    updatedDate: 'Updated Date',
    widgetName: 'Widget Name',
    unblock: 'Unblock',
    blockPaymentMethods: 'Block Payment Methods',
    unblockPaymentMethods: 'Unblock Payment Methods',
    sessions: 'Sessions',
    submit: 'Submit',
    playerDeletedReason: 'The player account was closed',
    playersTimeOutReason: 'The player is in time-out',
    playersSelfExcludedReason: 'The player is self-excluded',
    playersBlockedReason: 'The player has restricted account status',
    autoRefresh: 'Auto Refresh',
    autoRefreshPeriods: {
      tenSec: '10 sec',
      thirtySec: '30 sec',
      oneMin: '1 min',
      fiveMin: '5 min',
      tenMin: '10 min',
    },
    failedLogonAttempts: 'Failed Logon Attempts',
    failedAttemptsPeriod: 'Failed Attempts Period',
    captchaPeriod: 'CAPTCHA Period',
    casinoProduct: 'Casino Product',
    campaignsv2: 'Campaigns_v2',
    generalSettings: 'General Settings',
    playerComplaints: 'Player Complaints',
    conditions: 'Conditions',
    gameType: 'Game Type',
    translationKey: 'Translation Key',
    bTag: 'BTag',
    lWidget: 'widget',
    createWidget: 'Create Widget',
    main: 'Main',
    onJoin: 'On Join',
    onJoinInfo: 'Campaign will require joining in order to participate',
    sourceType: 'Source Type',
    apple: 'Apple',
  },
  es: {
    home: 'Principal',
    dashboard: 'Panel de control',
    brands: 'Marcas',
    players: 'Jugadores',
    testPlayers: 'Probar jugadores',
    testPlayer: 'Probar jugador',
    kycReport: 'Informe KYC',
    financials: 'Finanzas',
    deposits: 'Depósitos',
    lDeposits: 'depósitos',
    withdrawals: 'Retiros',
    lWithdrawals: 'retiros',
    bets: 'Apuestas',
    bonusManagement: 'Gestión de bonos',
    bonuses: 'Bonos',
    bonusTemplates: 'Plantillas de bonos',
    templates: 'Plantillas',
    rules: 'Reglas',
    cashback: 'Devolución de efectivo',
    lCashback: 'devolución de efectivo',
    lCashbacks: 'devoluciones de efectivo',
    promoCodes: 'Códigos de promoción',
    referralBonus: 'Bono por recomendación',
    riskManagement: 'Gestión de riesgos',
    similarAccounts: 'Cuentas similares',
    bulkActions: 'Acciones por lotes',
    blacklist: 'Lista de excluidos',
    tools: 'Herramientas',
    bonusSetup: 'Configuración de bonos',
    brandAutomation: 'Automatización de marca',
    tag: 'Etiqueta',
    lTag: 'etiqueta',
    tags: 'Etiquetas',
    marketing: 'Marketing',
    market: 'Mercado',
    playerSegments: 'Segmentos de jugadores',
    gameSegments: 'Segmentos de juegos',
    notificationTemplates: 'Plantillas de notificación',
    lNotificationTemplate: 'plantilla de notificación',
    massMessages: 'Mensajes masivos',
    inboxMessages: 'Mensajes en la bandeja de entrada',
    cms: 'CMS',
    translations: 'Traducciones',
    systemNotifications: 'Notificaciones del sistema',
    lSystemNotifications: 'notificación del sistema',
    systemNotification: 'Notificación del sistema',
    fileManager: 'Gestor de archivos',
    reporting: 'Informes',
    businessIntelligence: 'Inteligencia empresarial',
    compliance: 'Cumplimiento',
    partners: 'Socios',
    partner: 'Socio',
    userManagement: 'Gestión de usuarios',
    users: 'Usuarios',
    permissionGroups: 'Grupos de permisos',
    permissions: 'Permisos',
    ipWhitelist: 'Lista de IPs admitidas',
    auditLog: 'Registro de auditoría',
    systemConversion: 'Conversión del sistema',
    systemSettings: 'Opciones del sistema',
    mainSettings: 'Configuración principal',
    sportsBook: 'Sportsbook',
    lSportsBook: 'sportsbook',
    providersAndGames: 'Proveedores y juegos',
    payments: 'Pagos',
    payment: 'Pago',
    operatorLimits: 'Límites del operador',
    operator: 'Operador',
    limits: 'Límites',
    thirdPartyServices: 'Servicios de terceros',
    notificationSettings: 'Opciones de notificación',
    seoSettings: 'Opciones de SEO',
    locationServices: 'Servicios de ubicación',
    conversionSettings: 'Opciones de conversión',
    legal: 'Legal',
    standards: 'Estándares',
    casinoGames: 'Juegos de casino',
    account: 'Cuenta',
    lAccount: 'cuenta',
    myAccount: 'Mi cuenta',
    changePass: 'Cambiar contraseña',
    logOut: 'Cerrar sesión',
    logo: 'Logotipo',
    nightMode: 'Modo nocturno',
    lightMode: 'Modo diurno',
    quickSearch: 'Búsqueda rápida',
    add: 'Añadir',
    gameId: 'ID de juego',
    cancel: 'Cancelar',
    search: 'Buscar',
    country: 'País',
    actions: 'Acciones',
    action: 'Acción',
    registrationDate: 'Fecha de registro',
    timeZone: 'Zona horaria',
    language: 'Idioma',
    currency: 'Moneda',
    edit: 'Editar',
    view: 'Ver',
    collapse: 'Contraer',
    expand: 'Expandir',
    disabled: 'Desactivado',
    enabled: 'Activado',
    disable: 'Desactivar',
    enable: 'Activar',
    lDisable: 'desactivar',
    lEnable: 'activar',
    currencyId: 'ID de moneda',
    currencyName: 'Nombre de moneda',
    languageId: 'ID de idioma',
    languageName: 'Nombre de idioma',
    selectAll: 'Seleccionar todo',
    status: 'Estado',
    name: 'Nombre',
    providerName: 'Nombre del proveedor',
    provider: 'Proveedor',
    lProvider: 'proveedor',
    games: 'Juegos',
    game: 'Juego',
    dateAdded: 'Fecha de adición',
    addedBy: 'Añadido el',
    select: 'Seleccionar',
    categories: 'Categorías',
    email: 'Correo electrónico',
    lEmail: 'correo electrónico',
    senderName: 'Nombre del remitente',
    default: 'Predeterminado',
    logs: 'Registros',
    logId: 'ID de registro',
    dateUpdated: 'Fecha de actualización',
    updatedBy: 'Actualizado por',
    description: 'Descripción',
    ipAddress: 'Dirección IP',
    bet: 'Apostar',
    win: 'Ganar',
    deposit: 'Depositar',
    withdrawal: 'Retirar',
    reset: 'Reiniciar',
    transaction: 'Transacción',
    valueBefore: 'Valor anterior',
    valueAfter: 'Valor posterior',
    period: 'Periodo',
    policy: 'Política',
    version: 'Versión',
    effectiveDate: 'Fecha de entrada en vigor',
    endDate: 'Fecha de finalización',
    creationDate: 'Fecha de creación',
    createdBy: 'Creado por',
    paymentMethod: 'Método de pago',
    paymentMethods: 'Métodos de pago',
    type: 'Tipo',
    restrictedCountries: 'Países restringidos',
    countries: 'Países',
    lastUpdateDate: 'Fecha de la última actualización',
    lastUpdateBy: 'Actualizado por última vez el',
    update: 'Actualización',
    fee: 'Tasa',
    limit: 'Límite',
    limitBefore: 'Límite anterior',
    save: 'Guardar',
    discard: 'Descartar',
    apply: 'Aplicar',
    active: 'Activo',
    inactive: 'Inactivo',
    pending: 'Pendiente',
    lPending: 'en espera',
    verified: 'Verificado',
    failed: 'Fallido',
    info: 'Información',
    captcha: 'Captcha',
    apiKey: 'Clave API',
    secretKey: 'Clave secreta',
    mins: 'min',
    hours: 'horas',
    hour: 'hora',
    days: 'días',
    months: 'meses',
    documentType: 'Tipo de documento',
    sportBetting: 'Apuestas deportivas',
    casinoBetting: 'Apuestas de casino',
    sport: 'Deporte',
    address: 'Dirección',
    lAddress: 'dirección',
    identity: 'Identidad',
    lIdentity: 'identidad',
    physical: 'Físico',
    reason: 'Motivo',
    comment: 'Comentario',
    text: 'Texto',
    filter: 'Filtro',
    lDomain: 'dominio',
    count: 'Recuento',
    noData: 'Sin datos',
    category: 'Categoría',
    autoUpdate: 'Actualizar automáticamente',
    all: 'Todos',
    clear: 'Borrar',
    show: 'Show',
    back: 'Volver',
    both: 'Ambos',
    gameName: 'Nombre del juego',
    yes: 'Sí',
    no: 'No',
    connect: 'Conectar',
    systemState: 'Estado del sistema',
    id: 'ID',
    enterId: 'Introducir ID',
    delete: 'Eliminar',
    block: 'Bloquear',
    lBlock: 'bloquear',
    lUnblock: 'desbloquear',
    import: 'Importar',
    export: 'Exportar',
    create: 'Crear',
    statuses: 'Estados',
    website: 'Sitio web',
    procedures: 'Procedimientos',
    activate: 'Activar',
    deactivate: 'Desactivar',
    lDeactivate: 'desactivar',
    lActivate: 'activar',
    seeLess: 'Ver menos',
    seeMore: 'Ver más',
    changes: 'Cambios',
    showTotal: '{{min}}-{{max}} de {{total}} artículo(s)',
    triggerDesc: 'Haga clic para ordenar en sentido descendente',
    triggerAsc: 'Haga clic para ordenar en sentido ascendente',
    cancelSort: 'Haga clic para cancelar la ordenación',
    expandRow: 'Expandir fila',
    collapseRow: 'Contraer fila',
    selectionAllData: 'Seleccionar todos los datos',
    overview: 'Vista general',
    settings: 'Opciones',
    system: 'Sistema',
    verification: 'Verificación',
    recipients: 'Destinatarios',
    statistics: 'Estadísticas',
    currencies: 'Monedas',
    segments: 'Segmentos',
    segment: 'Segmento',
    sendFrom: 'Enviar desde',
    subject: 'Objeto',
    sender: 'Remitente',
    canceledBy: 'Cancelado por',
    finish: 'Terminar',
    balance: 'Saldo',
    lPlayer: 'jugador',
    manage: 'Gestionar',
    configure: 'Configurar',
    totalPlayers: 'Total de jugadores',
    auditLogs: 'Registros de auditoría',
    composeOptions: 'Opciones de redacción',
    compose: 'Redactar',
    selectBonusType: 'Seleccionar tipo de bono',
    gamesInSegment: 'Juegos en el segmento',
    playersInSegment: 'Jugadores en el segmento',
    statusUpdate: 'Actualización de estado',
    balanceAdjustments: 'Ajustes de saldo',
    restrictedPlayers: 'Jugadores restringidos',
    referees: 'Recomendados',
    participants: 'Participantes',
    txnDetails: 'Detalles TXN',
    casinoBetsReport: 'Informe de apuestas de casino',
    clone: 'Clonar',
    details: 'Detalles',
    general: 'General',
    next: 'Siguiente',
    invalidPlayers: 'Jugadores no válidos',
    send: 'Enviar',
    reSend: 'Reenviar',
    now: 'Ahora',
    downloadSample: 'Descargar muestra',
    download: 'Descargar',
    uploadList: 'Subir lista',
    playerUploadFormatMsg: 'Puede subir listas de jugadores. Solo se admite el formato CSV',
    memorySize: {
      bytes: 'Bytes',
      kb: 'KB',
      mb: 'MB',
      gb: 'GB',
    },
    exportCsv: 'Exportar CSV',
    csvWithDetails: 'CSV (Detallado)',
    columns: 'Columnas',
    playerId: 'ID del jugador',
    username: 'Usuario',
    firstName: 'Nombre',
    lastName: 'Apellido',
    accountStatus: 'Estado de la cuenta',
    startDate: 'Fecha de inicio',
    gender: 'Género',
    birthDate: 'Fecha de nacimiento',
    document: 'Documento',
    city: 'Ciudad',
    mobileNumber: 'Número de teléfono móvil',
    documentNumber: 'Número de documento',
    lastLoginDate: 'Última fecha de inicio de sesión',
    assign: 'Asignar',
    position: 'Puesto',
    amount: 'Importe',
    ggr: 'GGR',
    brand: 'Marca',
    fullName: 'Nombre completo',
    dateUploaded: 'Fecha de subida',
    uploadedBy: 'Subido el',
    player: 'Jugador',
    approve: 'Aprobar',
    reject: 'Rechazar',
    from: 'De',
    to: 'Para',
    casino: 'Casino',
    lCasino: 'casino',
    transactions: 'Transacciones',
    lTransactions: 'transacciones',
    verify: 'Verificar',
    lVerify: 'verificar',
    lUnverify: 'deshacer verificación',
    notVerified: 'No verificado',
    feePercent: 'Tasa, %',
    convertedAmount: 'Importe convertido',
    conversionRate: 'Tasa de conversión',
    note: 'Nota',
    addNote: 'Añadir nota',
    balanceBefore: 'Saldo anterior',
    balanceAfter: 'Saldo posterior',
    txnId: 'ID TXN',
    extTxnId: 'Ext. ID TXN',
    state: 'Estado',
    bonusId: 'ID de Bono',
    betAmount: 'Importe de apuesta',
    winAmount: 'Importe de ganancias',
    platform: 'Plataforma',
    ip: 'IP',
    betType: 'Tipo de apuesta',
    odd: 'Cuota',
    adjustmentType: 'Tipo de ajuste',
    operationType: 'Tipo de operación',
    datePerformed: 'Fecha de realización',
    transactionId: 'ID de transacción',
    performedBy: 'Realizado por',
    dateRange: 'Intervalo de fechas',
    comments: 'Comentarios',
    timestamp: 'Marca de tiempo',
    userId: 'ID de usuario',
    browser: 'Navegador',
    resource: 'Recurso',
    deviceID: 'ID de dispositivo',
    rangeDates: 'Rango de fechas',
    notFound: 'No se encuentra',
    upload: 'Subir',
    acceptableFormat: 'Formato admitido',
    maxUploadSize: 'El tamaño del archivo no puede superar los',
    maxSumUploadSize: 'La suma máx. del tamaño de la subida no puede exceder los',
    fileDetails: 'Detalles del archivo',
    csvSample: 'CSV de muestra',
    invalidKeys: 'Claves inválidas',
    middleName: 'Segundo nombre',
    fathersName: 'Nombre del padre',
    source: 'Fuente',
    tfa: 'Autenticación de 2 factores',
    move: 'Mover',
    merge: 'Fusionar',
    color: 'Color',
    numbersRange: {
      min: 'Mín.',
      max: 'Máx.',
    },
    day: 'día',
    operationDates: 'Fechas de operación',
    trigger: 'Activador',
    languages: 'Idiomas',
    location: 'Ubicación',
    created: 'Creado',
    updated: 'Actualizado',
    value: 'Valor',
    totalAmount: 'Importe total',
    single: 'Único',
    bulk: 'Por lotes',
    managePermissions: 'Gestionar permisos',
    depositId: 'ID de Depósito',
    pageCantBeDisplayed: 'No se puede mostrar la página',
    youDontHavePermission: 'La URL de la página no es válida o no tiene permiso para verla.',
    lCategory: 'categoría',
    thePlayer: 'El jugador',
    accounts: 'Cuentas',
    password: 'Contraseña',
    templateName: 'Nombre de plantilla',
    allPlayers: 'Todos los jugadores',
    segmentName: 'Nombre de segmento',
    attach: 'Adjuntar',
    schedule: 'Programación',
    body: 'Cuerpo',
    templateId: 'ID de plantilla',
    confirm: 'Confirmar',
    accountInfo: 'Información de la cuenta',
    company: 'Empresa',
    selectCashbackType: 'Seleccionar tipo de devolución de efectivo',
    misConfigured: 'Mal configurado',
    attentionMarked: 'Atención marcada',
    paymentProvider: 'Proveedor de pagos',
    submissionDate: 'Fecha de envío',
    ngr: 'NGR',
    icon: 'Icono',
    betId: 'ID de apuesta',
    paymentRequestId: 'ID de solicitud de pago',
    roundIdCouponNumber: 'ID de la ronda/número de cupón',
    dateDuration: '{{hour}} {{minute}} {{second}}',
    noCardSelected: 'No se ha seleccionado ninguna tarjeta',
    backToList: 'Volver a la lista',
    time: {
      hour: 'h',
      minute: 'm',
      second: 's',
    },
    duration: 'Duración',
    browserId: 'ID de navegador',
    preview: 'Previsualizar',
    sportBetsReport: 'Informe de apuestas deportivas',
    lMinutes: 'minutos',
    pageNotFound: 'Vaya, no se encuentra la página',
    title: 'Título',
    zipCode: 'Código postal',
    url: 'URL',
    rate: 'Tasa',
    conversionRates: 'Tasas de conversión',
    conversionOrConversions: 'Conversión(es)',
    gameManagement: 'Gestión de juegos',
    date: 'Fecha',
    restricted: 'Restringido',
    maintenanceState: 'Estado del mantenimiento',
    underMaintenance: 'En mantenimiento',
    noMaintenance: 'Sin mantenimientoMantenimiento',
    gameSystemState: 'Estado del sistema de juego',
    today: 'Hoy',
    lastWeek: 'Última semana',
    lastMonth: 'Último mes',
    yesterday: 'Ayer',
    providers: 'Proveedores',
    totalWithdrawals: 'Total de Retiradas',
    totalDeposits: 'Total de Depósitos',
    viewAll: 'Ver todo',
    deviceType: 'Tipo de dispositivo',
    verticalTags: 'Etiquetas verticales',
    horizontalTags: 'Etiquetas horizontales',
    addNew: 'Añadir nuevo',
    widgets: 'Widgets',
    lMobileNumber: 'número de teléfono móvil',
    infoUrl: 'URL de información',
    previousAccounts: 'Cuentas previas',
    keys: 'Claves',
    widget: 'Widget',
    expirationPeriod: 'Período de caducidad',
    maxAmount: 'Importe máx.',
    timeOutWebsite: 'Time-out Website, Webmobile, Pwapp',
    timeOutNativeApp: 'Tiempo de espera Aplicaciones nativas',
    pages: 'Páginas',
    groupName: 'Nombre del Grupo',
    betMinusWin: 'Apuesta-Ganancia',
    unVerify: 'No Verificado',
    thisMonth: 'Este Mes',
    thisWeek: 'Esta Semana',
    typeHere: 'Escriba aquí',
    reasonsSystem: 'Sistema de Motivos',
    campaigns: 'Campañas',
    noResults: 'Sin Resultados',
    shared: 'Compartido',
    tagCategories: 'Categorías de las Etiquetas',
    reasons: 'Motivos',
    taxes: 'Impuestos',
    agSentData: 'Datos AG enviados',
    developmentTools: 'Herramientas de Desarrollo',
    copy: 'Copiar',
    lobbyManagement: 'Gestión del Inicio',
    spinsCount: 'Recuento de Spins',
    lNow: 'ahora',
    selected: 'Selección',
    playerName: 'Nombre del Jugador',
    totalBonuses: 'Bonos totales',
    totalCount: 'Recuento total',
    validTill: 'Válido hasta',
    systemCategory: 'Categoría del sistema',
    demoVersion: 'Versión demo',
    gameState: 'Estado de juego',
    total: 'Total',
    notification: 'Notificación',
    methodType: 'Tipo de método',
    playerBonusId: 'ID del bono del jugador',
    spinCount: 'Recuento de Spin',
    spinAmount: 'Importe de Spin',
    activationDate: 'Fecha de activación',
    receivedDate: 'Fecha de recepción',
    extId: 'ID ext.',
    feeAmount: 'Importe de la cuota',
    taxAmount: 'Importe del impuesto',
    taxPercent: 'Impuesto %',
    taxTotalAmount: 'Importe total de impuestos',
    amountAfterTaxes: 'Importe después de Impuestos',
    conversionCurrency: 'Conversión de moneda',
    operationDate: 'Fecha de la Operación',
    timePeriod: 'Periodo de tiempo',
    fullDay: 'Día completo',
    section: 'Sección',
    bonusBetAmount: 'Importe de la apuesta de bono',
    bonusWinAmount: 'Importe de la ganancia de bono',
    campaignName: 'Nombre de la campaña',
    bonusFamily: 'Familia de Bono',
    bonusAmount: 'Importe de bono',
    roundId: 'ID de ronda',
    currentValue: 'Valor actual',
    sportStatus: 'Estado del deporte',
    crm: 'CRM',
    sms: 'SMS',
    transferTo: 'Transferir a',
    manageMaintenance: 'Gestionar mantenimiento',
    attribute: 'Atributo',
    priority: 'Prioridad',
    widgetName: 'Nombre de widget',
    messages: 'Mensajes',
    paymentId: 'ID de Pago',
    generateReport: 'Generar Reporte',
    generateReportNote:
      'Después de que se genere el estado de cuenta, recibirás un recordatorio por correo electrónico. El estado de cuenta se almacenará durante 7 días en el <0>Centro de Exportación</0>.',
    exportCenter: 'Centro de Exportación',
    remove: 'Eliminar',
    ok: 'OK',
    aggregator: 'Agregador',
    providerId: 'ID del Proveedor',
    gameBrandState: 'Estado de la Marca del Juego',
    gameGlobalState: 'Estado Global del Juego',
    providerList: 'Lista de Proveedores',
    gameList: 'Lista de Juegos',
    oldPassword: 'Contraseña Anterior',
    newPassword: 'Nueva Contraseña',
    confirmPassword: 'Confirmar Contraseña',
    changePassword: 'Cambiar Contraseña',
    pushNotifications: 'Notificaciones Push',
    more: 'Más',
    activatedBy: 'Activado Por',
    dateOfBirth: 'Fecha de Nacimiento',
    uDays: 'Días',
    brandId: 'ID de Marca',
    regulatory: 'Regulador',
    betCount: 'Conteo de Apuestas',
    winCount: 'Conteo de Ganancias',
    taxation: 'Tributación',
    betStatusCorrection: 'Corrección de Estado de Apuesta',
    none: 'Ninguno',
    unselect: 'Deseleccionar',
    tagLogs: 'Registros de Etiquetas',
    romanian: 'Rumano',
    cypriot: 'Cypriot',
    betBuilder: 'Bet Builder',
    sessions: 'Sesiones',
    playerDeletedReason: 'La cuenta del jugador se ha cerrado',
    playersTimeOutReason: 'El jugador está en tiempo de espera',
    playersSelfExcludedReason: 'El jugador está autoexcluido',
    playersBlockedReason: 'El jugador tiene un estado de cuenta restringido',
    conditions: 'Condiciones',
    gameType: 'Tipo de juego',
    translationKey: 'Traducción de clave',
    bTag: 'BTag',
    lWidget: 'widget',
    createWidget: 'Crear widget',
    onJoin: 'Al unirse',
    main: 'Principal',
    sourceType: 'Tipo de Fuente',
  },
  tr: {
    home: 'Ana Sayfa',
    dashboard: 'Dashboard',
    brands: 'Markalar',
    players: 'Oyuncular',
    testPlayers: 'Test Oyuncuları',
    testPlayer: 'Test Oyuncu',
    kycReport: 'KYC Raporu',
    financials: 'Finans Raporları',
    deposits: 'Para Yatırmalar',
    lDeposits: 'para yatırmalar',
    withdrawals: 'Para Çekmeler',
    lWithdrawals: 'para çekmeler',
    bets: 'Bahisler',
    bonusManagement: 'Bonus Yönetimi',
    bonuses: 'Bonuslar',
    bonusTemplates: 'Bonus Şablonu',
    templates: 'Şablonlar',
    rules: 'Kurallar',
    cashback: 'Cashback',
    lCashback: 'cashback',
    lCashbacks: 'cashback',
    promoCodes: 'Promosyon Kodları',
    referralBonus: 'Referans Bonusu',
    riskManagement: 'Risk Yönetimi',
    similarAccounts: 'Benzer Hesaplar',
    bulkActions: 'Toplu Eylemler',
    blacklist: 'Kara Liste',
    tools: 'Araçlar',
    bonusSetup: 'Bonus Kurulumu',
    brandAutomation: 'Marka Otomasyonu',
    tag: 'Etiket',
    lTag: 'etiket',
    tags: 'Etiketler',
    marketing: 'Pazarlama',
    market: 'Pazar',
    playerSegments: 'Oyuncu Segmentleri',
    gameSegments: 'Oyun Segmentleri',
    notificationTemplates: 'Bildirim Şablonları',
    lNotificationTemplate: 'bildirim şablonu',
    massMessages: 'Toplu Mesajlar',
    inboxMessages: 'Gelen Kutusu Mesajları',
    cms: 'CMS',
    translations: 'Çeviriler',
    systemNotifications: 'Sistem Bildirimleri',
    lSystemNotifications: 'sistem bildirimi',
    systemNotification: 'Sistem Bildirimi',
    fileManager: 'Dosya Yöneticisi',
    reporting: 'Raporlama',
    businessIntelligence: 'İş Zekası',
    compliance: 'Uyumluluk',
    partners: 'İş Ortakları',
    partner: 'İş Ortağı',
    userManagement: 'Kullanıcı Yönetimi',
    users: 'Kullanıcılar',
    permissionGroups: 'İzin Grupları',
    permissions: 'İzinler',
    ipWhitelist: 'IP Beyaz Listesi',
    auditLog: 'Denetim Günlüğü',
    systemConversion: 'Sistem Dönüşümü',
    systemSettings: 'Sistem Ayarları',
    mainSettings: 'Ana Ayarlar',
    sportsBook: 'Spor Bahisleri',
    lSportsBook: 'spor bahisleri',
    providersAndGames: 'Sağlayıcılar ve Oyunlar',
    payments: 'Ödemeler',
    payment: 'Ödeme',
    operatorLimits: 'Operatör Limitleri',
    operator: 'Operatör',
    limits: 'Limitler',
    thirdPartyServices: 'Üçüncü Taraf Hizmetleri',
    notificationSettings: 'Bildirim Ayarları',
    seoSettings: 'SEO Ayarları',
    locationServices: 'Konum Hizmetleri',
    conversionSettings: 'Dönüşüm Ayarları',
    legal: 'Yasal',
    standards: 'Standartlar',
    casinoGames: 'Casino Oyunları',
    account: 'Hesap',
    lAccount: 'hesap',
    myAccount: 'Hesabım',
    changePass: 'Şifre Değiştir',
    logOut: 'Çıkış Yap',
    logo: 'Logo',
    nightMode: 'Gece Modu',
    lightMode: 'Aydınlık Mod',
    quickSearch: 'Hızlı Arama',
    add: 'Ekle',
    gameId: 'Oyun ID',
    cancel: 'İptal Et',
    search: 'Ara',
    country: 'Ülke',
    actions: 'Eylemler',
    action: 'İşlem',
    registrationDate: 'Kayıt Tarihi',
    timeZone: 'Zaman Dilimi',
    language: 'Dil',
    currency: 'Para Birimi',
    edit: 'Düzenle',
    view: 'İzle',
    collapse: 'Daralt',
    expand: 'Genişlet',
    disabled: 'Devre Dışı Bırakıldı',
    enabled: 'Etkinleştirildi',
    disable: 'Devre Dışı Bırak',
    enable: 'Etkinleştir',
    lDisable: 'devre dışı bırak',
    lEnable: 'etkinleştir',
    currencyId: "Para Birimi ID'si",
    currencyName: 'Para Birimi Adı',
    languageId: "Dil ID'si",
    languageName: 'Dil Adı',
    selectAll: 'Hepsini Seç',
    status: 'Durum',
    name: 'İsim',
    providerName: 'Sağlayıcı Adı',
    provider: 'Sağlayıcı',
    lProvider: 'sağlayıcı',
    games: 'Oyunlar',
    game: 'Oyun',
    dateAdded: 'Eklenme Tarihi',
    addedBy: 'Ekleyen',
    select: 'Seç',
    categories: 'Kategoriler',
    email: 'E-mail',
    lEmail: 'email',
    senderName: 'Gönderici Adı',
    default: 'Varsayılan',
    logs: 'Girişler',
    logId: "Giriş ID'si",
    dateUpdated: 'Güncelleme Tarihi',
    updatedBy: 'Güncelleyen',
    description: 'Açıklama',
    ipAddress: 'IP Adresi',
    bet: 'Bahis',
    win: 'Kazanç',
    deposit: 'Para Yatırma',
    withdrawal: 'Para Çekme',
    reset: 'Sıfırla',
    transaction: 'İşlem',
    valueBefore: 'Öncesinde Değer',
    valueAfter: 'Sonrasında Değer',
    period: 'Dönem',
    policy: 'İlke',
    version: 'Sürüm',
    effectiveDate: 'Etkinlik Tarihi',
    endDate: 'Bitiş Tarihi',
    creationDate: 'Oluşturulma Tarihi',
    createdBy: 'Oluşturan',
    paymentMethod: 'Ödeme Metodu',
    paymentMethods: 'Ödeme metotları',
    type: 'Tür',
    restrictedCountries: 'Yasaklı Ülkeler',
    countries: 'Ülkeler',
    lastUpdateDate: 'Son Güncelleme Tarihi',
    lastUpdateBy: 'Son Güncelleyen:',
    update: 'Güncelle',
    fee: 'Ücret',
    limit: 'Limit',
    limitBefore: 'Öncesinde Limit',
    save: 'Kaydet',
    discard: 'Çıkart',
    apply: 'Uygula',
    active: 'Aktif',
    inactive: 'Aktif Değil',
    pending: 'Beklemede',
    lPending: 'beklemede',
    verified: 'Doğrulandı',
    failed: 'Başarısız',
    info: 'Bilgi',
    captcha: 'Captcha',
    apiKey: 'API Anahtarı',
    secretKey: 'Gizli Anahtar',
    mins: 'dakikalar',
    hours: 'saatler',
    hour: 'saat',
    days: 'günler',
    months: 'aylar',
    documentType: 'Belge Türü',
    sportBetting: 'Spor Bahisleri',
    casinoBetting: 'Casino Bahisleri',
    sport: 'Spor',
    address: 'Adres',
    lAddress: 'adres',
    identity: 'Kimlik',
    lIdentity: 'kimlik',
    physical: 'Fiziki',
    reason: 'Neden',
    comment: 'Yorum',
    text: 'Metin',
    filter: 'Filtrele',
    lDomain: 'domain',
    count: 'Say',
    noData: 'Veri Yok',
    category: 'Kategori',
    autoUpdate: 'Otomatik güncelleme',
    all: 'Tamamı',
    clear: 'Temizle',
    show: 'Göster',
    back: 'Geri',
    both: 'Her İkisi',
    gameName: 'Oyun Adı',
    yes: 'Evet',
    no: 'Hayır',
    connect: 'Bağlan',
    systemState: 'Sistem Durumu',
    id: 'ID',
    enterId: 'ID Gir',
    delete: 'Sil',
    block: 'Engelle',
    lBlock: 'engelle',
    lUnblock: 'engeli kaldır',
    import: 'İçe Aktar',
    export: 'Dışa Aktar',
    create: 'Oluştur',
    statuses: 'Durumlar',
    website: 'Web sitesi',
    procedures: 'Prosedürler',
    activate: 'Etkinleştir',
    deactivate: 'Devre dışı bırak',
    lDeactivate: 'devre dışı bırak',
    lActivate: 'etkinleştir',
    seeLess: 'Daha Az Görünüle',
    seeMore: 'Daha Fazla Görüntüle',
    changes: 'Değişiklikler',
    showTotal: ' {{total}} öğelerin {{min}}-{{max}} ',
    triggerDesc: "Azalana göre sırala'ya tıklayın",
    triggerAsc: "Artana göre sırala'ya tıklayın",
    cancelSort: 'Sıralamayı iptal etmek için tıklayın',
    expandRow: 'Satırı genişlet',
    collapseRow: 'Satırı daralt',
    selectionAllData: 'Tüm verileri seç',
    overview: 'Genel bakış',
    settings: 'Ayarlar',
    system: 'Sistem',
    verification: 'Doğrulama',
    recipients: 'Alıcılar',
    statistics: 'İstatistikler',
    currencies: 'Para Birimleri',
    segments: 'Segmentler',
    segment: 'Segment',
    sendFrom: 'Gönderilen',
    subject: 'Konu',
    sender: 'Gönderen',
    canceledBy: 'İptal Edildi',
    finish: 'Bitiş',
    balance: 'Bakiye',
    lPlayer: 'oyuncu',
    manage: 'Yönet',
    configure: 'Yapılandır',
    totalPlayers: 'Toplam Oyuncular',
    auditLogs: 'Denetim Günlükleri',
    composeOptions: 'Oluşturma Seçenekleri',
    compose: 'Oluştur',
    selectBonusType: 'Bonus Türünü Seçin',
    gamesInSegment: 'Segmentteki Oyunlar',
    playersInSegment: 'Segmentteki Oyuncular',
    statusUpdate: 'Durum güncelleme',
    balanceAdjustments: 'Bakiye Ayarlamaları',
    restrictedPlayers: '‎ Yasaklı Oyuncular',
    referees: 'Referans Verenler',
    participants: 'Katılımcılar',
    txnDetails: 'TXN Bilgileri',
    casinoBetsReport: 'Casino Bahisleri Raporu',
    clone: 'Klonla',
    details: 'Detaylar',
    general: 'Genel',
    next: 'Sonraki',
    invalidPlayers: 'Geçersiz Oyuncular',
    send: 'Gönder',
    reSend: 'Tekrar Gönder',
    now: 'Şimdi',
    downloadSample: 'Örnek İndir',
    download: 'İndir',
    uploadList: 'Liste yükle',
    playerUploadFormatMsg: 'Oyuncu listelerini yükleyebilirsiniz. Yalnızca CSV formatı kabul edilir',
    memorySize: {
      bytes: 'Bytes',
      kb: 'KB',
      mb: 'MB',
      gb: 'GB',
    },
    exportCsv: "CSV'yi dışa aktar",
    csvWithDetails: 'CSV (Ayrıntılı)',
    columns: 'Sütunlar',
    playerId: "Oyuncu ID'si",
    username: 'Kullanıcı Adı',
    firstName: 'Ad',
    lastName: 'Soyad',
    accountStatus: 'Hesap Durumu',
    startDate: 'Başlangıç Tarihi',
    gender: 'Cinsiyet',
    birthDate: 'DoğumTarihi',
    document: 'Belge',
    city: 'Şehir',
    mobileNumber: 'Cep Telefonu Numarası',
    documentNumber: 'Belge Numarası',
    lastLoginDate: 'Son Giriş Tarihi',
    assign: 'Ata',
    position: 'Pozisyon',
    amount: 'Tutar',
    ggr: 'GGR',
    brand: 'Marka',
    fullName: 'Ad Soyad',
    dateUploaded: 'Yüklenen Tarih',
    uploadedBy: 'Yükleyen',
    player: 'Oyuncu',
    approve: 'Onayla',
    reject: 'Reddet',
    from: 'En Düşük',
    to: 'En Yüksek',
    casino: 'Casino',
    lCasino: 'casino',
    transactions: 'İşlemler',
    lTransactions: 'işlemler',
    verify: 'Doğrula',
    lVerify: 'doğrula',
    lUnverify: 'doğrulamayı kaldır',
    notVerified: 'Doğrulanmadı',
    feePercent: 'Ücret, %',
    convertedAmount: 'Dönüştürülen Tutar',
    conversionRate: 'Dönüşüm oranı',
    note: 'Not',
    addNote: 'Not ekle',
    balanceBefore: 'Önceki Bakiye',
    balanceAfter: 'Sonraki Bakiye',
    txnId: 'TXN ID',
    extTxnId: 'Ext.TXN ID',
    state: 'Durum',
    bonusId: "Bonus ID'si",
    betAmount: 'Bahis Tutarı',
    winAmount: 'Kazanç Tutarı',
    platform: 'Platform',
    ip: 'IP',
    betType: 'Bahis Türü',
    odd: 'Oran',
    adjustmentType: 'Ayar Türü',
    operationType: 'Operasyon Türü',
    datePerformed: 'Gerçekleştirildiği Tarih',
    transactionId: 'İşlem ID',
    performedBy: 'Gerçekleştiren',
    dateRange: 'Tarih Aralığı',
    comments: 'Yorumlar',
    timestamp: 'Zaman Damgası',
    userId: 'Kullanıcı ID',
    browser: 'Tarayıcı',
    resource: 'Kaynak',
    deviceID: 'Cihaz ID',
    rangeDates: 'Aralık Tarihleri',
    notFound: 'Bulunamadı',
    upload: 'Yükle',
    acceptableFormat: 'Geçerli format',
    maxUploadSize: 'Dosya boyutu aşılamaz',
    maxSumUploadSize: 'Maksimum toplam dosya yükleme boyutu aşılamaz',
    fileDetails: 'Dosya Bilgileri',
    csvSample: 'CSV Örneği',
    invalidKeys: 'Geçersiz Anahtarlar',
    middleName: 'İkinci Ad',
    fathersName: 'Baba Adı',
    source: 'Kaynak',
    tfa: '‎2 Faktörlü Kimlik Doğrulama',
    move: 'Taşı',
    merge: 'Birleştir',
    color: 'Renk',
    numbersRange: {
      min: 'Min.',
      max: 'Maks.',
    },
    day: 'gün',
    operationDates: 'Operasyon Tarihleri',
    trigger: 'Tetikleyici',
    languages: 'Diller',
    location: 'Konum',
    created: 'Oluşturuldu',
    updated: 'Güncellendi',
    value: 'Değer',
    totalAmount: 'Toplam Tutar',
    single: 'Tekli',
    bulk: 'Toplu',
    managePermissions: 'İzinleri Yönet',
    depositId: 'Para Yatırma ID',
    pageCantBeDisplayed: 'Sayfa görüntülenemiyor',
    youDontHavePermission: "Sayfa URL'si geçersiz veya onu görüntüleme izniniz yok.",
    lCategory: 'kategori',
    thePlayer: 'Oyuncu',
    accounts: 'Hesaplar',
    password: 'Şifre',
    templateName: 'Şablon adı',
    allPlayers: 'Tüm Oyuncular',
    segmentName: 'Segment Adı',
    attach: 'Ekle',
    schedule: 'Takvim',
    body: 'Gövde',
    templateId: 'Şablon ID',
    confirm: 'Onayla',
    accountInfo: 'Hesap Bilgisi',
    company: 'Şirket',
    selectCashbackType: 'Cashback Türünü Seçin',
    misConfigured: 'Yanlış yapılandırılmış',
    attentionMarked: 'Dikkat İşaretli',
    paymentProvider: 'Ödeme Sağlayıcı',
    submissionDate: 'Teslim Tarihi',
    ngr: 'NGR',
    icon: 'İkon',
    betId: 'Bahis ID',
    paymentRequestId: 'Ödeme Talebi ID',
    roundIdCouponNumber: 'Raunt ID/Kupon Numarası',
    dateDuration: '‎{{saat}} {{dakika}} {{saniye}}',
    noCardSelected: 'Kart Seçilmedi',
    backToList: 'Listeye Geri Dön',
    time: {
      hour: 's',
      minute: 'd',
      second: 's',
    },
    duration: 'Süre',
    browserId: 'Tarayıcı ID',
    preview: 'Önizleme',
    sportBetsReport: 'Spor Bahisleri Raporu',
    lMinutes: 'dakika',
    pageNotFound: 'Hata! sayfa bulunamadı',
    title: 'Başlık',
    zipCode: 'Posta kodu',
    url: 'URL',
    rate: 'Oran',
    conversionRates: 'Dönüşüm Oranları',
    conversionOrConversions: 'Dönüşüm(ler)',
    gameManagement: 'Oyun Yönetimi',
    date: 'Tarih',
    restricted: 'Kısıtlanmış',
    maintenanceState: 'Bakım Durumu',
    underMaintenance: 'Bakımda',
    noMaintenance: 'Bakım Yok',
    gameSystemState: 'Oyun Sistemi Durumu',
    today: 'Bugün',
    lastWeek: 'Geçen Hafta',
    lastMonth: 'Geçen Ay',
    yesterday: 'Dün',
    providers: 'Sağlayıcılar',
    totalWithdrawals: 'Toplam Para Çekimleri',
    totalDeposits: 'Toplam Para Yatırma İşlemleri',
    viewAll: 'Tümünü Görüntüle',
    deviceType: 'Cihaz Türü',
    horizontalTags: 'Yatay Etiketler',
    verticalTags: 'Dikey Etiketler',
    addNew: 'Yeni Ekle',
    widgets: 'Widget’lar',
    lMobileNumber: 'telefon numarası',
    infoUrl: 'Bilgi URL',
    pages: 'Sayfalar',
    groupName: 'Grup Adı',
    betMinusWin: 'Bahis-Kazanç',
    messages: 'Mesajlar',
    unVerify: 'Doğrulamayı kaldır',
    thisMonth: 'Bu Ay',
    thisWeek: 'Bu Hafta',
    typeHere: 'Buraya yazın',
    reasonsSystem: 'Nedenler Sistemi',
    campaigns: 'Kampanyalar',
    noResults: 'Sonuçlar bulunamadı',
    shared: 'Paylaşılan',
    tagCategories: 'Etiket Kategorileri',
    reasons: 'Nedenler',
    taxes: 'Vergiler',
    previousAccounts: 'Önceki Hesaplar',
    keys: 'Anahtarlar',
    widget: 'Widget',
    expirationPeriod: 'Son kullanma dönemi',
    maxAmount: 'Maksimum Tutar',
    timeOutWebsite: 'Zaman Aşımı Web Sitesi, Webmobile, Pwapp',
    timeOutNativeApp: 'Zaman aşımı Yerel uygulamalar',
    agSentData: 'AG Gönderilen Veriler',
    developmentTools: 'Geliştirme Araçları',
    copy: 'Kopyala',
    lobbyManagement: 'Lobi Yönetimi',
    spinsCount: 'Spin Sayısı',
    lNow: 'şimdi',
    selected: 'Seçilmiş',
    playerName: 'Oyuncu Adı',
    totalBonuses: 'Toplam Bonuslar',
    totalCount: 'Toplam Sayı',
    validTill: 'Geçerlilik Süresi',
    systemCategory: 'Sistem Kategorisi',
    demoVersion: 'Demo Sürümü',
    gameState: 'Oyun Durumu',
    total: 'Toplam',
    notification: 'Bildirim',
    methodType: 'Yöntem Türü',
    playerBonusId: 'Oyuncu Bonus ID',
    spinCount: 'Spin Sayısı',
    spinAmount: 'Spin Miktarı',
    activationDate: 'Etkinleştirme Tarihi',
    receivedDate: 'Alınan Tarih',
    extId: 'Harici Kimlik',
    feeAmount: 'Ücret Tutarı',
    taxAmount: 'Vergi Tutarı',
    taxPercent: 'Vergi %',
    taxTotalAmount: 'Toplam Vergi Tutarı',
    amountAfterTaxes: 'Vergilerden Sonraki Tutar',
    conversionCurrency: 'Dönüştürme Para Birimi',
    operationDate: 'İşlem Tarihi',
    timePeriod: 'Zaman Dilimi',
    fullDay: 'Tam Gün',
    section: 'Bölüm',
    bonusBetAmount: 'Bonus Bahis Tutarı',
    bonusWinAmount: 'Bonus Kazancı Tutarı',
    campaignName: 'Kampanya Adı',
    bonusFamily: 'Bonus Ailesi',
    bonusType: 'Bonus Türü',
    bonusAmount: 'Bonus Tutarı',
    roundId: 'Raunt ID',
    currentValue: 'Güncel değer',
    sportStatus: 'Spor Durumu',
    crm: 'CRM',
    sms: 'SMS',
    transferTo: 'Transfer Et',
    manageMaintenance: 'Bakımı Yönet',
    attribute: 'Nitelik',
    priority: 'Öncelik',
    widgetName: 'Widget Adı',
    paymentId: 'Ödeme ID',
    generateReport: 'Rapor Oluştur',
    generateReportNote: 'Bildirim oluşturulduktan sonra bir e-posta hatırlatıcısı alırsınız. Ekstre 7 gün boyunca <0>Dışa Aktarım Merkezinde</0> saklanır.',
    exportCenter: 'Dışa Aktarma Merkezi',
    remove: 'Kaldır',
    ok: 'OK',
    aggregator: 'Toplayıcı',
    providerId: 'Sağlayıcı ID',
    gameBrandState: 'Oyun Marka Durumu',
    gameGlobalState: 'Oyun Genel Durumu',
    providerList: 'Sağlayıcı Listesi',
    gameList: 'Oyun Listesi',
    oldPassword: 'Eski Şifre',
    newPassword: 'Yeni Şifre',
    confirmPassword: 'Şifreyi Onayla ',
    changePassword: 'Şifreyi Değiştir',
    pushNotifications: 'Anlık Bildirimler',
    more: 'Daha fazlası',
    templateWasDeleted: 'Şablon silindi ve artık mevcut değil',
    activatedBy: 'Etkinleştiren',
    dateOfBirth: 'Doğum Tarihi',
    uDays: 'Günler',
    brandId: 'Marka ID',
    regulatory: 'Düzenleyici',
    betCount: 'Bahis Sayısı ',
    winCount: 'Kazanç Sayısı',
    taxation: 'Vergilendirme',
    betStatusCorrection: 'Bahis Durumu Düzeltme',
    none: 'Hiçbiri',
    unselect: 'Seçimi Kaldır',
    tagLogs: 'Etiket Günlükleri ',
    romanian: 'Romence',
    cypriot: 'Kıbrıs',
    betBuilder: 'Bahis Oluşturucu',
    sessions: 'Oturumlar',
    playerDeletedReason: 'Oyuncunun hesabı kapatıldı',
    playersTimeOutReason: 'Oyuncu molada',
    playersSelfExcludedReason: 'Oyuncu kendisini hariç tutar',
    playersBlockedReason: 'Oyuncunun hesap durumu yasaklı ',
    conditions: 'Koşullar',
    gameType: 'Oyun Türü',
    translationKey: 'Çeviri Anahtarı',
    bTag: 'BTag',
    lWidget: 'widget',
    createWidget: 'Widget Oluştur',
    onJoin: 'Katılım',
    main: 'Ana',
    sourceType: 'Kaynak Türü',
  },
  ru: {
    home: 'Домашняя страница',
    dashboard: 'Панель управления',
    brands: 'Бренды',
    players: 'Игроки',
    testPlayers: 'Тестовые игроки',
    testPlayer: 'Тестовый игрок',
    kycReport: 'Отчет KYC',
    financials: 'Финансовые инструменты',
    deposits: 'Депозиты',
    lDeposits: 'депозиты',
    withdrawals: 'Выведенные средства',
    lWithdrawals: 'выведенные средства',
    bets: 'Ставки',
    bonusManagement: 'Управление бонусами',
    bonuses: 'Бонусы',
    bonusTemplates: 'Бонусные шаблоны',
    templates: 'Шаблоны',
    rules: 'Правила',
    cashback: 'Кэшбэк',
    lCashback: 'кэшбэк',
    lCashbacks: 'кэшбэки',
    promoCodes: 'Промокоды',
    referralBonus: 'Реферальный бонус',
    riskManagement: 'Управление рисками',
    similarAccounts: 'Аналогичные учетные записи',
    bulkActions: 'Групповые действия',
    blacklist: 'Черный список',
    tools: 'Инструменты',
    bonusSetup: 'Настройки бонуса',
    brandAutomation: 'Автоматизация бренда',
    tag: 'Тег',
    lTag: 'тег',
    tags: 'Теги',
    marketing: 'Маркетинг',
    market: 'Маркет',
    playerSegments: 'Сегменты игрока',
    gameSegments: 'Сегменты игры',
    notificationTemplates: 'Шаблоны уведомлений',
    lNotificationTemplate: 'шаблон уведомления',
    massMessages: 'Массовые сообщения',
    inboxMessages: 'Входящие сообщения',
    cms: 'CMS',
    translations: 'Переводы',
    systemNotifications: 'Системные уведомления',
    lSystemNotifications: 'системное уведомление',
    systemNotification: 'Системное уведомление',
    fileManager: 'Диспетчер файлов',
    reporting: 'Отчетность',
    businessIntelligence: 'Бизнес-аналитика',
    compliance: 'Соблюдение нормативно-правовых требований',
    partners: 'Партнеры',
    partner: 'Партнер',
    userManagement: 'Управление пользователями',
    users: 'Пользователи',
    permissionGroups: 'Группы полномочий',
    permissions: 'Полномочия',
    ipWhitelist: 'Белый список IP',
    auditLog: 'Журнал аудита',
    systemConversion: 'Конвертация системы',
    systemSettings: 'Настройки системы',
    mainSettings: 'Основные настройки',
    sportsBook: 'Спортсбук',
    lSportsBook: 'спортсбук',
    providersAndGames: 'Провайдеры и игры',
    payments: 'Выплаты',
    payment: 'Выплата',
    operatorLimits: 'Лимиты оператора',
    operator: 'Оператор',
    limits: 'Лимиты',
    thirdPartyServices: 'Сторонние услуги',
    notificationSettings: 'Настройки уведомлений',
    seoSettings: 'Настройки поисковой оптимизации',
    locationServices: 'Службы позиционирования',
    conversionSettings: 'Настройки конвертации',
    legal: 'Юридический раздел',
    standards: 'Стандарты',
    casinoGames: 'Казино игры',
    account: 'Учетная запись',
    lAccount: 'учетная запись',
    myAccount: 'Моя учетная запись',
    changePass: 'Изменить пароль',
    logOut: 'Выйти',
    logo: 'Лого',
    nightMode: 'Ночной режим',
    lightMode: 'Светлый режим',
    quickSearch: 'Быстрый поиск',
    add: 'Добавить',
    gameId: 'ID игры',
    cancel: 'Отменить',
    search: 'Поиск',
    country: 'Страна',
    actions: 'Действия',
    action: 'Действие',
    registrationDate: 'Дата регистрации',
    timeZone: 'Часовой пояс',
    language: 'Язык',
    currency: 'Валюта',
    edit: 'Редактировать',
    view: 'Посмотреть',
    collapse: 'Свернуть',
    expand: 'Развернуть',
    disabled: 'Отключенные',
    enabled: 'Включенные',
    disable: 'Отключить',
    enable: 'Включить',
    lDisable: 'отключить',
    lEnable: 'включить',
    currencyId: 'ID валюты',
    currencyName: 'Название валюты',
    languageId: 'ID языка',
    languageName: 'Название языка',
    selectAll: 'Выбрать все',
    status: 'Статус',
    name: 'Название',
    providerName: 'Название провайдера',
    provider: 'Провайдер',
    lProvider: 'провайдер',
    games: 'Игры',
    game: 'Игра',
    dateAdded: 'Дата добавления',
    addedBy: 'Добавлено (кем)',
    select: 'Выбрать',
    categories: 'Категории',
    email: 'Электронная почта',
    lEmail: 'электронная почта',
    senderName: 'Имя отправителя',
    default: 'По умолчанию',
    logs: 'Журналы',
    logId: 'ID журнала',
    dateUpdated: 'Дата обновления',
    updatedBy: 'Обновлено (кем)',
    description: 'Описание',
    ipAddress: 'IP-адрес',
    bet: 'Ставка',
    win: 'Выигрыш',
    deposit: 'Депозит',
    withdrawal: 'Вывод средств',
    reset: 'Сбросить',
    transaction: 'Транзакция',
    valueBefore: 'Значение до',
    valueAfter: 'Значение после',
    period: 'Период',
    policy: 'Политика',
    version: 'Версия',
    effectiveDate: 'Дата вступления в силу',
    endDate: 'Дата завершения',
    creationDate: 'Дата создания',
    createdBy: 'Создано (кем)',
    paymentMethod: 'Способ оплаты',
    paymentMethods: 'Способы оплаты',
    type: 'Тип',
    restrictedCountries: 'Запрещенные страны',
    countries: 'Страны',
    lastUpdateDate: 'Дата последнего обновления',
    lastUpdateBy: 'Последнее обновление (кем)',
    update: 'Обновить',
    fee: 'Комиссия',
    limit: 'Лимит',
    limitBefore: 'Лимит до',
    save: 'Сохранить',
    discard: 'Сбросить',
    apply: 'Применить',
    active: 'Активные',
    inactive: 'Неактивные',
    pending: 'На рассмотрении',
    lPending: 'в ожидании',
    verified: 'Верифицированные',
    failed: 'Неудавшиеся',
    info: 'Информация',
    captcha: 'Капча',
    apiKey: 'Ключ API',
    secretKey: 'Секретный ключ',
    mins: 'мин.',
    hours: 'часы',
    hour: 'час',
    days: 'дни',
    months: 'месяцы',
    documentType: 'Тип документа',
    sportBetting: 'Ставки на спорт',
    casinoBetting: 'Ставки в казино',
    sport: 'Спорт',
    address: 'Адрес',
    lAddress: 'адрес',
    identity: 'Личные данные',
    lIdentity: 'личные данные',
    physical: 'Физически',
    reason: 'Причина',
    comment: 'Комментарий',
    text: 'Текст',
    filter: 'Фильтр',
    lDomain: 'домен',
    count: 'Количество',
    noData: 'Нет данных',
    category: 'Категория',
    autoUpdate: 'Автообновление',
    all: 'Все',
    clear: 'Очистить',
    show: 'Показать',
    back: 'Назад',
    both: 'Оба/обе',
    gameName: 'Название игры',
    yes: 'Да',
    no: 'Нет',
    connect: 'Подключить',
    systemState: 'Состояние системы',
    id: 'ID',
    enterId: 'Ввести ID',
    delete: 'Удалить',
    block: 'Блокировать',
    lBlock: 'блокировать',
    lUnblock: 'разблокировать',
    import: 'Импортировать',
    export: 'Экспортировать',
    create: 'Создать',
    statuses: 'Статусы',
    website: 'Сайт',
    procedures: 'Процедуры',
    activate: 'Активировать',
    deactivate: 'Деактивировать',
    lDeactivate: 'деактивировать',
    lActivate: 'активировать',
    seeLess: 'Показать меньше',
    seeMore: 'Показать больше',
    changes: 'Изменения',
    showTotal: '{{min}}-{{max}} из {{total}} пункта(-ов)',
    triggerDesc: 'Сортировать по убыванию',
    triggerAsc: 'Сортировать по возрастанию',
    cancelSort: 'Отменить сортировку',
    expandRow: 'Развернуть ряд',
    collapseRow: 'Свернуть ряд',
    selectionAllData: 'Выбрать все данные',
    overview: 'Обзор',
    settings: 'Настройки',
    system: 'Система',
    verification: 'Верификация',
    recipients: 'Получатели',
    statistics: 'Статистика',
    currencies: 'Валюты',
    segments: 'Сегменты',
    segment: 'Сегмент',
    sendFrom: 'Отправить (от кого)',
    subject: 'Тема',
    sender: 'Отправитель',
    canceledBy: 'Кем отменено',
    finish: 'Конец',
    balance: 'Баланс',
    lPlayer: 'игрок',
    manage: 'Управлять',
    configure: 'Настроить',
    totalPlayers: 'Всего игроков',
    auditLogs: 'Журналы аудита',
    composeOptions: 'Написать варианты',
    compose: 'Написать',
    selectBonusType: 'Выбрать тип бонуса',
    gamesInSegment: 'Игры в сегменте',
    playersInSegment: 'Игроки в сегменте',
    statusUpdate: 'Обновление статуса',
    balanceAdjustments: 'Корректировки баланса',
    restrictedPlayers: 'Ограниченные игроки',
    referees: 'Рефералы',
    participants: 'Участники',
    txnDetails: 'Данные транзакции',
    casinoBetsReport: 'Отчет о ставках в казино',
    clone: 'Клонировать',
    details: 'Подробности',
    general: 'Общие сведения',
    next: 'Вперед',
    invalidPlayers: 'Недопустимые игроки',
    send: 'Отправить',
    reSend: 'Отправить повторно',
    now: 'Сейчас',
    downloadSample: 'Загрузить образец',
    download: 'Загрузить',
    uploadList: 'Загрузить список',
    playerUploadFormatMsg: 'Вы можете загрузить список игроков. Допустим только формат CSV',
    memorySize: {
      bytes: 'Байты',
      kb: 'Кб',
      mb: 'Мб',
      gb: 'Гб',
    },
    exportCsv: 'Экспортировать CSV',
    csvWithDetails: 'CSV (с данными)',
    columns: 'Колонки',
    playerId: 'ID игрока',
    username: 'Имя пользователя',
    firstName: 'Имя',
    lastName: 'Фамилия',
    accountStatus: 'Cтатус учетной записи',
    startDate: 'Дата начала',
    gender: 'Пол',
    birthDate: 'Дата рождения',
    document: 'Документ',
    city: 'Город',
    mobileNumber: 'Номер мобильного телефона',
    documentNumber: 'Номер документа',
    lastLoginDate: 'Дата последнего входа',
    assign: 'Назначить',
    position: 'Позиция',
    amount: 'Сумма',
    ggr: 'GGR',
    brand: 'Бренд',
    fullName: 'ФИО',
    dateUploaded: 'Дата загрузки',
    uploadedBy: 'Загружено (кем)',
    player: 'Игрок',
    approve: 'Одобрить',
    reject: 'Отклонить',
    from: 'От',
    to: 'Кому',
    casino: 'Казино',
    lCasino: 'казино',
    transactions: 'Транзакции',
    lTransactions: 'операции',
    verify: 'Подтвердить',
    lVerify: 'подтвердить',
    lUnverify: 'Отменить подтверждение',
    notVerified: 'Не подтверждено',
    feePercent: 'Комиссия (%)',
    convertedAmount: 'Конвертированная сумма',
    conversionRate: 'Ставка конвертации',
    note: 'Примечание',
    addNote: 'Добавить примечание',
    balanceBefore: 'Баланс до',
    balanceAfter: 'Баланс после',
    txnId: 'ID транзакции',
    extTxnId: 'ID внешней транзакции',
    state: 'Состояние',
    bonusId: 'ID бонуса',
    betAmount: 'Сумма ставки',
    winAmount: 'Сумма выигрыша',
    platform: 'Платформа',
    ip: 'IP',
    betType: 'Тип ставки',
    odd: 'Коэффициент',
    adjustmentType: 'Тип корректировки',
    operationType: 'Тип операции',
    datePerformed: 'Дата выполнения',
    transactionId: 'ID транзакции',
    performedBy: 'Выполнено (кем)',
    dateRange: 'Диапазон дат',
    comments: 'Комментарии',
    timestamp: 'Временная отметка',
    userId: 'ID пользователя',
    browser: 'Браузер',
    resource: 'Ресурс',
    deviceID: 'ID устройства',
    rangeDates: 'Диапазон дат',
    notFound: 'Не найдено',
    upload: 'Загрузить',
    acceptableFormat: 'Допустимый формат',
    maxUploadSize: 'Размер файла не должен превышать',
    maxSumUploadSize: 'Макс. суммарный размер загрузки не должен превышать',
    fileDetails: 'Информация о файле',
    csvSample: 'Пример CSV',
    invalidKeys: 'Недопустимые ключи',
    middleName: 'Второе имя',
    fathersName: 'Отчество',
    source: 'Источник',
    tfa: 'Двухфакторная аутентификация',
    move: 'Переместить',
    merge: 'Объединить',
    color: 'Цвет',
    numbersRange: {
      min: 'Мин.',
      max: 'Макс.',
    },
    day: 'день',
    operationDates: 'Даты операции',
    trigger: 'Триггер',
    languages: 'Языки',
    location: 'Местонахождение',
    created: 'Создано',
    updated: 'Обновлено',
    value: 'Значение',
    totalAmount: 'Общая сумма',
    single: 'Одиночный(-ая)',
    bulk: 'Групповой(-ая)',
    managePermissions: 'Управление полномочиями',
    depositId: 'ID депозита',
    pageCantBeDisplayed: 'Невозможно отобразить страницу',
    youDontHavePermission: 'URL-адрес страницы недействителен или у вас нет разрешения на ее просмотр.',
    lCategory: 'категория',
    thePlayer: 'Игрок',
    accounts: 'Учетные записи',
    password: 'Пароль',
    templateName: 'Название шаблона',
    allPlayers: 'Все игроки',
    segmentName: 'Название сегмента',
    attach: 'Прикрепить',
    schedule: 'Расписание',
    body: 'Текст',
    templateId: 'ID шаблона',
    confirm: 'Подтвердить',
    accountInfo: 'Информация учетной записи',
    company: 'Компания',
    selectCashbackType: 'Выбрать вид кешбэка',
    misConfigured: 'Неверная настройка',
    attentionMarked: 'С отметкой «Внимание»',
    paymentProvider: 'Провайдер платежной системы',
    submissionDate: 'Дата подачи',
    ngr: 'NGR',
    icon: 'Иконка',
    betId: 'ID ставки',
    paymentRequestId: 'ID запроса на оплату',
    roundIdCouponNumber: 'ID раунда / Номер купона',
    dateDuration: '{{hour}} {{minute}} {{second}}',
    noCardSelected: 'Карта не выбрана',
    backToList: 'Назад к списку',
    time: {
      hour: 'ч',
      minute: 'м',
      second: 'с',
    },
    duration: 'Продолжительность',
    browserId: 'ID браузера',
    preview: 'Предпросмотр',
    sportBetsReport: 'Отчет о ставках на спорт',
    lMinutes: 'минуты',
    pageNotFound: 'Ой! Страница не найдена',
    title: 'Заголовок',
    zipCode: 'Почтовый индекс',
    url: 'URL',
    rate: 'Курс',
    conversionRates: 'Курсы конвертации',
    conversionOrConversions: 'Конвертация(-ии)',
    gameManagement: 'Управление играми',
    date: 'Дата',
    restricted: 'Запрещены',
    maintenanceState: 'Состояние обслуживания',
    underMaintenance: 'На обслуживании',
    noMaintenance: 'Нет обслуживания',
    gameSystemState: 'Состояние игровой системы',
    today: 'Сегодня',
    lastWeek: 'Прошлая неделя',
    lastMonth: 'Прошлый месяц',
    yesterday: 'Вчера',
    providers: 'Провайдеры',
    totalWithdrawals: 'Всего выплаты',
    totalDeposits: 'Всего депозиты',
    viewAll: 'Посмотреть все',
    deviceType: 'Тип устройства',
    horizontalTags: 'Горизонтальные теги',
    verticalTags: 'Вертикальные теги',
    addNew: 'Добавить новую',
    widgets: 'Виджеты',
    lMobileNumber: 'номер мобильного телефона',
    infoUrl: 'Информационный URL',
    previousAccounts: 'Предыдущие учетные записи',
    keys: 'Ключи',
    pages: 'Страницы',
    groupName: 'Название группы',
    betMinusWin: 'Ставка - Выигрыш',
    messages: 'Сообщения',
    unVerify: 'Не проверен',
    thisMonth: 'Этот месяц',
    thisWeek: 'Эта неделя',
    typeHere: 'Введите сюда',
    reasonsSystem: 'Система причин',
    campaigns: 'Кампании',
    noResults: 'Результатов нет',
    shared: 'Совместный',
    tagCategories: 'Категории тегов',
    reasons: 'Причины',
    taxes: 'Налоги',
    widget: 'Виджет',
    expirationPeriod: 'Срок истечения',
    maxAmount: 'Макс. сумма',
    timeOutWebsite: 'Тайм-аут Веб-сайта, Webmobile, Pwapp',
    timeOutNativeApp: 'Тайм-аут нативных приложений',
    agSentData: 'Данные, отправленные AG',
    developmentTools: 'Инструменты разработчика',
    copy: 'Копировать',
    lobbyManagement: 'Управление лобби',
    spinsCount: 'Количество спинов',
    lNow: 'сейчас',
    selected: 'Выбрано',
    playerName: 'Имя игрока',
    totalBonuses: 'Всего бонусов',
    totalCount: 'Всего количество',
    validTill: 'Срок действия до',
    systemCategory: 'Категория системы',
    demoVersion: 'Демо-версия',
    gameState: 'Состояние игры',
    total: 'Всего',
    notification: 'Уведомление',
    methodType: 'Тип метода',
    playerBonusId: 'ID бонуса игрока',
    spinCount: 'Количество спинов',
    spinAmount: 'Сумма спинов',
    activationDate: 'Дата активации',
    receivedDate: 'Дата получения',
    extId: 'Внешний ID',
    feeAmount: 'Сумма комиссии',
    taxAmount: 'Сумма налога',
    taxPercent: 'Налог %',
    taxTotalAmount: 'Общая сумма налога',
    amountAfterTaxes: 'Сумма после удержания налогов',
    conversionCurrency: 'Валюта конвертации',
    operationDate: 'Дата операции',
    timePeriod: 'Период времени',
    fullDay: 'Полный день',
    section: 'Секция',
    bonusBetAmount: 'Сумма ставок за бонусные средства',
    bonusWinAmount: 'Сумма выигрышей за бонусные средства',
    campaignName: 'Название кампании',
    bonusFamily: 'Семейство бонусов',
    bonusAmount: 'Сумма бонуса',
    roundId: 'Идентификатор раунда',
    currentValue: 'Текущее значение',
    sportStatus: 'Статус спорта',
    crm: 'CRM',
    sms: 'SMS',
    transferTo: 'Кому перевести',
    manageMaintenance: 'Управление обслуживанием',
    attribute: 'Атрибут',
    priority: 'Приоритет',
    generateReport: 'Сгенерировать отчет',
    generateReportNote: 'После того как выписка будет сгенерирована, вы получите напоминание по электронной почте. Выписка будет храниться в течение 7 дней в <0>Центре экспорта</0>.',
    exportCenter: 'Центр экспорта',
    remove: 'Удалить',
    ok: 'OK',
    aggregator: 'Агрегатор',
    providerId: 'ID провайдера',
    gameGlobalState: 'Глобальное состояние игры',
    providerList: 'Список провайдеров',
    gameList: 'Список игр',
    oldPassword: 'Старый пароль',
    newPassword: 'Новый пароль',
    confirmPassword: 'Подтвердить пароль',
    changePassword: 'Изменить пароль',
    pushNotifications: 'Push-уведомления',
    more: 'Подробнее',
    templateWasDeleted: 'Шаблон был удален и больше не доступен',
    dateOfBirth: 'Дата рождения',
    uDays: 'Дни',
    brandId: 'ID Бренда',
    regulatory: 'Нормативно-правовое регулирование',
    betCount: 'Счет ставок',
    winCount: 'Счет выигрышей',
    taxation: 'Налогообложение',
    bonusType: 'Тип бонуса',
    betStatusCorrection: 'Корректировка статуса ставки',
    none: 'Отсутствует',
    unselect: 'Отменить',
    tagLogs: 'Журналы тегов',
    romanian: 'Румынский',
    cypriot: 'Кипрский',
    betBuilder: 'Bet Builder',
    widgetName: 'Название виджета',
    sessions: 'Сессии',
    playerDeletedReason: 'Учетная запись игрока закрыта',
    playersTimeOutReason: 'Время, отведенное игроку, истекло',
    playersSelfExcludedReason: 'Игрок активировал самоисключение',
    playersBlockedReason: 'Игрок ограничил статус учетной записи',
    conditions: 'Условия',
    gameType: 'Тип игры',
    translationKey: 'Ключ перевода',
    bTag: 'BTag',
    lWidget: 'Виджет',
    createWidget: 'Создать виджет',
    onJoin: 'На присоединение',
    main: 'Основное',
    sourceType: 'Тип источника',
  },
  pt: {
    home: 'Página Inicial',
    dashboard: 'Painel',
    brands: 'Marcas',
    players: 'Jogadores',
    testPlayers: 'Jogadores Teste',
    testPlayer: 'Jogador Teste',
    kycReport: 'Relatório KYC',
    financials: 'Finanças',
    deposits: 'Depósitos',
    lDeposits: 'depósitos',
    withdrawals: 'Saques',
    lWithdrawals: 'saques',
    bets: 'Apostas',
    bonusManagement: 'Gerenciamento de Bônus',
    bonuses: 'Bônus',
    bonusTemplates: 'Modelos de Bônus',
    templates: 'Modelos',
    rules: 'Regras',
    cashback: 'Cashback',
    lCashback: 'cashback',
    lCashbacks: 'cashbacks',
    promoCodes: 'Códigos Promocionais',
    referralBonus: 'Bônus de Indicação',
    riskManagement: 'Gerenciamento de Risco',
    similarAccounts: 'Contas Similares',
    bulkActions: 'Acções em massa',
    blacklist: 'Lista de proibições',
    tools: 'Ferramentas',
    bonusSetup: 'Configuração de Bónus',
    brandAutomation: 'Automação da Marca',
    tag: 'Tag',
    lTag: 'tag',
    tags: 'Tags',
    marketing: 'Marketing',
    market: 'Mercado',
    playerSegments: 'Segmentos do Jogador',
    gameSegments: 'Segmentos de Jogo',
    notificationTemplates: 'Modelos de Notificação',
    lNotificationTemplate: 'modelo de notificação',
    massMessages: 'Mensagens em Massa',
    inboxMessages: 'Mensagens da Inbox',
    cms: 'CMS',
    translations: 'Traduções',
    systemNotifications: 'Notificações de Sistema',
    lSystemNotifications: 'notificação de sistema',
    systemNotification: 'Notificação de Sistema',
    fileManager: 'Gerenciador de Arquivos',
    reporting: 'Relatório',
    businessIntelligence: 'Business Intelligence',
    compliance: 'Conformidade',
    partners: 'Parceiros',
    partner: 'Parceiro',
    userManagement: 'Gestão de Usuários',
    users: 'Usuários',
    permissionGroups: 'Grupos de Permissão',
    permissions: 'Permissões',
    ipWhitelist: 'Lista de permissões de IP',
    auditLog: 'Registro de Auditoria',
    systemConversion: 'Conversão de Sistema',
    systemSettings: 'Configurações de Sistema',
    mainSettings: 'Configurações Principais',
    sportsBook: 'Sportsbook',
    lSportsBook: 'sportsbook',
    providersAndGames: 'Provedores e Jogos',
    payments: 'Pagamentos',
    payment: 'Pagamento',
    operatorLimits: 'Limites do Operador',
    operator: 'Operador',
    limits: 'Limites',
    thirdPartyServices: 'Serviços Terceirizados',
    notificationSettings: 'Configurações de Notificação',
    seoSettings: 'Configurações de SEO',
    locationServices: 'Serviços de Localização',
    conversionSettings: 'Configurações de Conversão',
    legal: 'Legal',
    standards: 'Normas',
    casinoGames: 'Jogos de Cassino',
    account: 'Conta',
    lAccount: 'conta',
    myAccount: 'Minha Conta',
    changePass: 'Alterar Senha',
    logOut: 'Sair',
    logo: 'Logo',
    nightMode: 'Modo Noite',
    lightMode: 'Modo Claro',
    quickSearch: 'Busca Rápida',
    add: 'Adicionar',
    gameId: 'ID do Jogo',
    cancel: 'Cancelar',
    search: 'Procurar',
    country: 'País',
    actions: 'Ações',
    action: 'Ação',
    registrationDate: 'Data de Registro',
    timeZone: 'Fuso Horário',
    language: 'Idioma',
    currency: 'Moeda',
    edit: 'Editar',
    view: 'Visão',
    collapse: 'Colapsar',
    expand: 'Expandir',
    disabled: 'Desativado',
    enabled: 'Ativado',
    disable: 'Desativar',
    enable: 'Ativar',
    lDisable: 'desativar',
    lEnable: 'ativar',
    currencyId: 'ID da Moeda',
    currencyName: 'Nome da Moeda',
    languageId: 'ID do Idioma',
    languageName: 'Nome do Idioma',
    selectAll: 'Selecione Todos',
    status: 'Status',
    name: 'Nome',
    providerName: 'Nome do Fornecedor',
    provider: 'Provedor',
    lProvider: 'provedor',
    games: 'Jogos',
    game: 'Jogo',
    dateAdded: 'Data Adicionada',
    addedBy: 'Adicionado por',
    select: 'Selecionar',
    categories: 'Categorias',
    email: 'E-mail',
    lEmail: 'e-mail',
    senderName: 'Nome do Remetente',
    default: 'Padrão',
    logs: 'Registros',
    logId: 'ID do Registro',
    dateUpdated: 'Data de Atualização',
    updatedBy: 'Atualizado por',
    description: 'Descrição',
    ipAddress: 'Endereço IP',
    bet: 'Aposta',
    win: 'Ganho',
    deposit: 'Depositar',
    withdrawal: 'Saque',
    reset: 'Redefinir',
    transaction: 'Transação',
    valueBefore: 'Valor Antes',
    valueAfter: 'Valor Após',
    period: 'Período',
    policy: 'Política',
    version: 'Versão',
    effectiveDate: 'Data de Vigência',
    endDate: 'Data de Término',
    creationDate: 'Data de Criação',
    createdBy: 'Criado por',
    paymentMethod: 'Método de Pagamento',
    paymentMethods: 'Métodos de Pagamento',
    type: 'Tipo',
    restrictedCountries: 'Países Restritos',
    countries: 'Países',
    lastUpdateDate: 'Data da Última Atualização',
    lastUpdateBy: 'Última Atualização por',
    update: 'Atualizar',
    fee: 'Taxa',
    limit: 'Limite',
    limitBefore: 'Limite Antes',
    save: 'Salvar',
    discard: 'Descartar',
    apply: 'Aplicar',
    active: 'Ativo',
    inactive: 'Inativo',
    pending: 'Pendente',
    lPending: 'pendente',
    verified: 'Verificado',
    failed: 'Falha',
    info: 'Informação',
    captcha: 'Captcha',
    apiKey: 'API Key',
    secretKey: 'Chave secreta',
    mins: 'min.',
    hours: 'hs.',
    hour: 'h.',
    days: 'dias',
    months: 'meses',
    documentType: 'Tipo de Documento',
    sportBetting: 'Apostas Desportivas',
    casinoBetting: 'Apostas de Casino',
    sport: 'Esporte',
    address: 'Endereço',
    lAddress: 'endereço',
    identity: 'Identidade',
    lIdentity: 'identidade',
    physical: 'Físico',
    reason: 'Motivo',
    comment: 'Comentário',
    text: 'Texto',
    filter: 'Filtro',
    lDomain: 'domínio',
    count: 'Conde',
    noData: 'Sem Dados',
    category: 'Categoria',
    autoUpdate: 'Atualização Automática',
    all: 'Todos',
    clear: 'Limpar',
    show: 'Mostrar',
    back: 'Voltar',
    both: 'Ambos',
    gameName: 'Nome do Jogo',
    yes: 'Sim',
    no: 'Não',
    connect: 'Conectar',
    systemState: 'Estado do Sistema',
    id: 'ID',
    enterId: 'Digite ID',
    delete: 'Excluir',
    block: 'Bloquear',
    lBlock: 'bloquear',
    lUnblock: 'desbloquear',
    import: 'Importar',
    export: 'Exportar',
    create: 'Criar',
    statuses: 'Status',
    website: 'Site',
    procedures: 'Procedimentos',
    activate: 'Ativar',
    deactivate: 'Desativar',
    lDeactivate: 'desativar',
    lActivate: 'ativar',
    seeLess: 'Mostrar Menos',
    seeMore: 'Mostrar Mais',
    changes: 'Alterações',
    showTotal: '{{min}}-{{max}} de {{total}} item(ns)',
    triggerDesc: 'Clique ordenar por ordem decrescente',
    triggerAsc: 'Clique ordenar por ordem crescente',
    cancelSort: 'Clique para cancelar ordenar',
    expandRow: 'Expandir fileira',
    collapseRow: 'Colapsar fileira',
    selectionAllData: 'Selecionar todos os dados',
    overview: 'Visão geral',
    settings: 'Configurações',
    system: 'Sistema',
    verification: 'Verificação',
    recipients: 'Destinatários',
    statistics: 'Estatísticas',
    currencies: 'Moedas',
    segments: 'Segmentos',
    segment: 'Segmento',
    sendFrom: 'Enviar de',
    subject: 'Assunto',
    sender: 'Remetente',
    canceledBy: 'Cancelado por',
    finish: 'Terminar',
    balance: 'Saldo',
    lPlayer: 'jogador',
    manage: 'Gerenciar',
    configure: 'Configurar',
    totalPlayers: 'Total de Jogadores',
    auditLogs: 'Relatórios de Auditoria',
    composeOptions: 'Opções de Composição',
    compose: 'Compor',
    selectBonusType: 'Selecione o Tipo de Bônus',
    gamesInSegment: 'Jogos em Segmento',
    playersInSegment: 'Jogadores em Segmento',
    statusUpdate: 'Atualização de Status',
    balanceAdjustments: 'Ajustes de Saldo',
    restrictedPlayers: 'Jogadores com Restrições',
    referees: 'Indicados',
    participants: 'Participantes',
    txnDetails: 'Detalhes de TXN',
    casinoBetsReport: 'Relatório de Apostas de Cassino',
    clone: 'Clonar',
    details: 'Detalhes',
    general: 'Geral',
    next: 'Próximo',
    invalidPlayers: 'Jogadores Inválidos',
    send: 'Enviar',
    reSend: 'Reenviar',
    now: 'Agora',
    downloadSample: 'Descarregar amostra',
    download: 'Baixar',
    uploadList: 'Lista de Carregamento',
    playerUploadFormatMsg: 'Você pode carregar listas de jogadores. Somente o formato CSV é aceito',
    memorySize: {
      bytes: 'Bytes',
      kb: 'KB',
      mb: 'MB',
      gb: 'GB',
    },
    exportCsv: 'Exportar CSV',
    csvWithDetails: 'CSV (Com detalhes)',
    columns: 'Colunas',
    playerId: 'ID do Jogador',
    username: 'Nome de utilizador',
    firstName: 'Nome',
    lastName: 'Sobrenome',
    accountStatus: 'Status da Conta',
    startDate: 'Data de Início',
    gender: 'Sexo',
    birthDate: 'Data de Nascimento',
    document: 'Documento',
    city: 'Cidade',
    mobileNumber: 'Número do Celular',
    documentNumber: 'Número do Documento',
    lastLoginDate: 'Data do Último Login',
    assign: 'Atribuir',
    position: 'Posição',
    amount: 'Valor',
    ggr: 'GGR',
    brand: 'Marca',
    fullName: 'Nome Completo',
    dateUploaded: 'Data de Carregamento',
    uploadedBy: 'Carregado por',
    player: 'Jogador',
    approve: 'Aprovar',
    reject: 'Rejeitar',
    from: 'De',
    to: 'Para',
    casino: 'Casino',
    lCasino: 'casino',
    transactions: 'Transações',
    lTransactions: 'transações',
    verify: 'Verificar',
    lVerify: 'verificar',
    lUnverify: 'não verificar',
    notVerified: 'Não Verificado',
    feePercent: 'Taxa, %',
    convertedAmount: 'Valor Convertido',
    conversionRate: 'Taxa de Conversão',
    note: 'Nota',
    addNote: 'Adicionar Nota',
    balanceBefore: 'Saldo Anterior',
    balanceAfter: 'Saldo Posterior',
    txnId: 'TXN ID',
    extTxnId: 'Ext. TXN ID',
    state: 'Estado',
    bonusId: 'ID do Bônus',
    betAmount: 'Valor da Aposta',
    winAmount: 'Valor do Ganho',
    platform: 'Plataforma',
    ip: 'IP',
    betType: 'Tipo de Aposta',
    odd: 'Ímpar',
    adjustmentType: 'Tipo de Ajuste',
    operationType: 'Tipo de Operação',
    datePerformed: 'Data de Realização',
    transactionId: 'ID da Transação',
    performedBy: 'Realizado por',
    dateRange: 'Intervalo de Datas',
    comments: 'Comentários',
    timestamp: 'Timestamp',
    userId: 'ID do Utilizador',
    browser: 'Navegador',
    resource: 'Recurso',
    deviceID: 'ID do Dispositivo',
    rangeDates: 'Intervalo de Datas',
    notFound: 'Não Encontrado',
    upload: 'Carregar',
    acceptableFormat: 'Formato aceitável',
    maxUploadSize: 'O tamanho do arquivo não pode exceder',
    maxSumUploadSize: 'O tamanho máximo do upload não pode exceder',
    fileDetails: 'Detalhes do Arquivo',
    csvSample: 'Amostra de CSV',
    invalidKeys: 'Keys Inválidas',
    middleName: 'Nome do Meio',
    fathersName: 'Nome do Pai',
    source: 'Fonte',
    tfa: 'Autenticação de 2 Fatores',
    move: 'Mover',
    merge: 'Combinar',
    color: 'Cor',
    numbersRange: {
      min: 'Mín.',
      max: 'Máx.',
    },
    day: 'dia',
    operationDates: 'Datas de Operação',
    trigger: 'Acionar',
    languages: 'Idiomas',
    location: 'Localização',
    created: 'Criado',
    updated: 'Atualizado',
    value: 'Valor',
    totalAmount: 'Valor Total',
    single: 'Único',
    bulk: 'Grupo',
    managePermissions: 'Gerenciar Permissões',
    depositId: 'ID do Depósito',
    pageCantBeDisplayed: 'A página não pode ser exibida',
    youDontHavePermission: 'A URL da página é inválida ou você não tem permissão para visualizá-la.',
    lCategory: 'categoria',
    thePlayer: 'O jogador',
    accounts: 'Contas',
    password: 'Senha',
    templateName: 'Nome do Modelo',
    allPlayers: 'Todos os Jogadores',
    segmentName: 'Nome do Segmento',
    attach: 'Anexar',
    schedule: 'Cronograma',
    body: 'Body',
    templateId: 'ID do Modelo',
    confirm: 'Confirmar',
    accountInfo: 'Informações da Conta',
    company: 'Empresa',
    selectCashbackType: 'Selecione o Tipo de Cashback',
    misConfigured: 'Mal-configurado',
    attentionMarked: 'Atenção Marcada',
    paymentProvider: 'Provedor de Pagamento',
    submissionDate: 'Data de Submissão',
    ngr: 'NGR',
    icon: 'Ícone',
    betId: 'ID da Aposta',
    paymentRequestId: 'ID da Solicitação de Pagamento',
    roundIdCouponNumber: 'ID do Round/Número do Cupão',
    dateDuration: '{{hour}} {{minute}} {{second}}',
    noCardSelected: 'Nenhuma Carta Selecionada',
    backToList: 'Voltar à Lista',
    time: {
      hour: 'h',
      minute: 'm',
      second: 's',
    },
    duration: 'Duração',
    browserId: 'ID do Navegador',
    preview: 'Pré-visualização',
    sportBetsReport: 'Relatório de Apostas Esportivas',
    lMinutes: 'minutos',
    pageNotFound: 'Ops! Página não encontrada',
    title: 'Título',
    zipCode: 'Código Postal',
    url: 'URL',
    rate: 'Taxa',
    conversionRates: 'Taxas de Conversão',
    conversionOrConversions: 'Conversão(ões)',
    gameManagement: 'Gestão de Jogos',
    date: 'Dia',
    restricted: 'Restrito',
    maintenanceState: 'Estado de Manutenção',
    underMaintenance: 'Em Manutenção',
    noMaintenance: 'Sem Manutenção',
    gameSystemState: 'Estado do Sistema de Jogos',
    pages: 'Páginas',
    groupName: 'Nome do Grupo',
    betMinusWin: 'Aposta - Ganho',
    today: 'Hoje',
    lastWeek: 'Última Semana',
    lastMonth: 'Último Mês',
    yesterday: 'Ontem',
    totalWithdrawals: 'Total de Retiradas',
    totalDeposits: 'Total de Depósitos',
    viewAll: 'Ver Tudo',
    deviceType: 'Tipo de Dispositivo',
    horizontalTags: 'Tags Horizontais',
    verticalTags: 'Tags Verticais',
    addNew: 'Adicionar Novo',
    widgets: 'Widgets',
    lMobileNumber: 'número do celular',
    infoUrl: 'URL de Informação',
    previousAccounts: 'Contas Anteriores',
    keys: 'Chaves',
    widget: 'Widget',
    expirationPeriod: 'Período de vencimento',
    maxAmount: 'Valor Máximo',
    timeOutWebsite: 'Time-out Website, Webmobile, Pwapp',
    timeOutNativeApp: 'Aplicativos nativos com tempo limite',
    messages: 'Mensagens',
    providers: 'Provedores',
    unVerify: 'Cancelar verificação',
    thisMonth: 'Este Mês',
    thisWeek: 'Esta Semana',
    typeHere: 'Digite aqui',
    reasonsSystem: 'Sistema de Razões',
    campaigns: 'Campanhas',
    noResults: 'Sem resultados',
    shared: 'Compartilhado',
    tagCategories: 'Categorias de tags',
    reasons: 'Razões',
    taxes: 'Impostos',
    agSentData: 'Dados AG enviados',
    developmentTools: 'Ferramentas de desenvolvimento',
    copy: 'Copiar',
    lobbyManagement: 'Gerenciamento de Lobby',
    spinsCount: 'Contagem de Spins',
    lNow: 'agora',
    selected: 'Selecionado',
    playerName: 'Nome do jogador',
    totalBonuses: 'Total de Bónus',
    totalCount: 'Contagem Total',
    validTill: 'Válido até',
    systemCategory: 'Categoria do Sistema',
    demoVersion: 'Versão Demonstração',
    gameState: 'Estado do Jogo',
    total: 'Total',
    notification: 'Notificação',
    methodType: 'Tipo de Método',
    playerBonusId: 'ID do Bónus do Jogador',
    spinCount: 'Contagem de Spin',
    spinAmount: 'Valor de Spin',
    activationDate: 'Data de Ativação',
    receivedDate: 'Data de Recepção',
    extId: 'Ext. ID',
    feeAmount: 'Valor da Taxa',
    taxAmount: 'Valor do Imposto',
    taxPercent: 'Imposto %',
    taxTotalAmount: 'Valor total do imposto',
    amountAfterTaxes: 'Valor após impostos',
    conversionCurrency: 'Conversão de Moeda',
    operationDate: 'Data da operação',
    timePeriod: 'Período de tempo',
    fullDay: 'Dia completo',
    section: 'Seção',
    campaignName: 'Nome da Campanha',
    bonusFamily: 'Família de Bónus',
    bonusAmount: 'Valor do Bónus',
    roundId: 'ID da Ronda',
    currentValue: 'Valor atual',
    sportStatus: 'Estado do Desporto',
    crm: 'CRM',
    sms: 'SMS',
    transferTo: 'Transferir para',
    manageMaintenance: 'Gerir Manutenção',
    attribute: 'Atributo',
    priority: 'Prioridade',
    widgetName: 'Nome do Widget',
    paymentId: 'ID do Pagamento',
    generateReport: 'Gerar relatório',
    generateReportNote: 'Depois que o extrato for gerado, você receberá um lembrete por e-mail. O extrato será guardado durante 7 dias no <0>Centro de Exportação</0>.',
    exportCenter: 'Centro de Exportação',
    remove: 'Remover',
    ok: 'Ok',
    aggregator: 'Agregador',
    providerId: 'ID do Fornecedor',
    gameBrandState: 'Estado da Marca do Jogo',
    gameGlobalState: 'Estado Global do Jogo',
    providerList: 'Lista de Fornecedor',
    gameList: 'Lista de Jogos',
    oldPassword: 'Palavra-passe antiga',
    newPassword: 'Nova palavra-passe',
    confirmPassword: 'Confirmar Palavra-passe',
    changePassword: 'Alterar a palavra-passe',
    pushNotifications: 'Notificações push',
    more: 'Mais',
    templateWasDeleted: 'O modelo foi eliminado e já não está disponível',
    activatedBy: 'Ativado por',
    dateOfBirth: 'Data de Nascimento',
    uDays: 'Dias',
    bonusBetAmount: 'Valor da Aposta de Bónus',
    bonusWinAmount: 'Montante do prémio de bónus',
    brandId: 'ID da Marca',
    regulatory: 'Regulamentação',
    betCount: 'Contagem de apostas',
    winCount: 'Contagem de vitórias',
    taxation: 'Tributação',
    betStatusCorrection: 'Correção do estado da aposta',
    none: 'Nenhum',
    unselect: 'Não selecionar',
    tagLogs: 'Relatório de Tags',
    romanian: 'Romeno',
    cypriot: 'Cipriota',
    betBuilder: 'Bet Builder',
    sessions: 'Sessões',
    playerDeletedReason: 'A conta do jogador foi fechada',
    playersTimeOutReason: 'O jogador está em time-out',
    playersSelfExcludedReason: 'O jogador está autoexcluído',
    playersBlockedReason: 'O jogador tem um estado de conta restrito',
    conditions: 'Condições',
    gameType: 'Tipo de Jogo',
    translationKey: 'Chave de Tradução',
    bTag: 'BTag',
    lWidget: 'widget',
    createWidget: 'Criar Widget',
    onJoin: 'Ao aderir',
    main: 'Principal',
    sourceType: 'Tipo de fonte',
  },
  zh: {
    home: '主页',
    dashboard: '仪表板',
    brands: '品牌',
    players: '玩家',
    testPlayers: '测试玩家',
    testPlayer: '测试玩家',
    kycReport: 'KYC报告',
    financials: '金融股',
    deposits: '存款',
    lDeposits: '存款',
    withdrawals: '取款',
    lWithdrawals: '取款',
    bets: '投注',
    bonusManagement: '奖金管理',
    bonuses: '奖金',
    bonusTemplates: '奖金模板',
    templates: '模板',
    rules: '规则',
    cashback: '现金返还',
    lCashback: '现金返还',
    lCashbacks: '现金返还',
    promoCodes: '促销代码',
    referralBonus: '推荐人奖金',
    riskManagement: '风险管理',
    similarAccounts: '相似账户',
    bulkActions: '批量操作',
    blacklist: '黑名单',
    tools: '工具',
    bonusSetup: '奖金设置',
    brandAutomation: '品牌自动化',
    tag: '标签',
    lTag: '标签',
    tags: '标签',
    marketing: '营销',
    market: '市场',
    playerSegments: '玩家分区',
    gameSegments: '游戏分区',
    notificationTemplates: '通知模板',
    lNotificationTemplate: '通知模板',
    massMessages: '海量消息',
    inboxMessages: '收件箱邮件',
    cms: 'CMS',
    translations: '翻译',
    systemNotifications: '系统通知',
    lSystemNotifications: '系统通知',
    systemNotification: '系统通知',
    fileManager: '文件管理器',
    reporting: '报告',
    businessIntelligence: '商业智能',
    compliance: '合规',
    partners: '合作伙伴',
    partner: '合作伙伴',
    userManagement: '用户管理',
    users: '用户',
    permissionGroups: '组权限',
    permissions: '权限',
    ipWhitelist: 'IP白名单',
    auditLog: '审核日志',
    systemConversion: '系统转换',
    systemSettings: '系统设置',
    mainSettings: '主要设置',
    sportsBook: '体育博彩',
    lSportsBook: '体育博彩',
    providersAndGames: '供应商和游戏',
    payments: '付款',
    payment: '付款',
    operatorLimits: '运营限制',
    operator: '运营商',
    limits: '限制',
    thirdPartyServices: '第三方服务',
    notificationSettings: '通知设置',
    seoSettings: 'SEO设置',
    locationServices: '定位服务',
    conversionSettings: '转换设置',
    legal: '法律',
    standards: '标准',
    casinoGames: '娱乐场游戏',
    account: '账户',
    lAccount: '账户',
    myAccount: '我的账户',
    changePass: '更改密码',
    logOut: '登出',
    logo: '标志',
    nightMode: '夜间模式',
    lightMode: '光照模式',
    quickSearch: '快速搜索',
    add: '添加',
    gameId: '游戏ID',
    cancel: '取消',
    search: '搜索',
    country: '国家',
    actions: '操作',
    action: '操作',
    registrationDate: '注册日期',
    timeZone: '时区',
    language: '语言',
    currency: '货币',
    edit: '编辑',
    view: '查看',
    collapse: '折叠',
    expand: '展开',
    disabled: '禁用的',
    enabled: '开启的',
    disable: '禁用',
    enable: '开启',
    lDisable: '禁用',
    lEnable: '开启',
    currencyId: '货币ID',
    currencyName: '货币名称',
    languageId: '语言ID',
    languageName: '语言名称',
    selectAll: '选择全部',
    status: '状态',
    name: '姓名',
    providerName: '供应商名称',
    provider: '供应商',
    lProvider: '供应商',
    games: '游戏',
    game: '游戏',
    dateAdded: '添加日期',
    addedBy: '添加者',
    select: '选择',
    categories: '类别',
    email: '电子邮件',
    lEmail: '电子邮件',
    senderName: '发送者姓名',
    default: '默认',
    logs: '日志',
    logId: '登录ID',
    dateUpdated: '更新日期',
    updatedBy: '更新为',
    description: '描述',
    ipAddress: 'IP地址',
    bet: '投注',
    win: '获胜',
    deposit: '存款',
    withdrawal: '取款',
    reset: '重置',
    transaction: '交易',
    valueBefore: '此前价值',
    valueAfter: '之后的值',
    period: '周期',
    policy: '政策',
    version: '版本',
    effectiveDate: '生效日期',
    endDate: '结束日期',
    creationDate: '创建日期',
    createdBy: '创建来自',
    paymentMethod: '付款方式',
    paymentMethods: '付款方式',
    type: '类型',
    restrictedCountries: '受限制的国家/地区',
    countries: '国家/地区',
    lastUpdateDate: '最近更新日期',
    lastUpdateBy: '最近更新由',
    update: '更新',
    fee: '手续费',
    limit: '限制',
    limitBefore: '此前限制',
    save: '保存',
    discard: '放弃',
    apply: '应用',
    active: '激活',
    inactive: '未激活',
    pending: '待定的',
    lPending: '待处理',
    verified: '验证的',
    failed: '失败的',
    info: '信息',
    captcha: '验证码',
    apiKey: 'API密钥',
    secretKey: 'API密匙',
    mins: '分钟',
    hours: '小时',
    hour: '小时',
    days: '日',
    months: '月',
    documentType: '文件类型',
    sportBetting: '体育投注',
    casinoBetting: '娱乐场投注',
    sport: '体育项目',
    address: '地址',
    lAddress: '地址',
    identity: '身份',
    lIdentity: '身份',
    physical: '物理',
    reason: '原因',
    comment: '评论',
    text: '文本',
    filter: '筛选',
    lDomain: '域名',
    count: '次数',
    noData: '无数据',
    category: '类别',
    autoUpdate: '自动更新的',
    all: '全部',
    clear: '清空',
    show: '显示',
    back: '返回',
    both: '两者都',
    gameName: '游戏名称',
    yes: '是',
    no: '否',
    connect: '连接',
    systemState: '系统状态',
    id: 'ID',
    enterId: '输入ID',
    delete: '删除',
    block: '屏蔽',
    lBlock: '屏蔽',
    lUnblock: '解除屏蔽',
    import: '导入',
    export: '导出',
    create: '创建',
    statuses: '状态',
    website: '网站',
    procedures: '程序',
    activate: '激活',
    deactivate: '停用',
    lDeactivate: '停用',
    lActivate: '激活',
    seeLess: '查看更少',
    seeMore: '查看更多',
    changes: '更改',
    showTotal: '总计 {{total}} 个物品中的 {{min}}-{{max}}',
    triggerDesc: '点击“按降序排序”',
    triggerAsc: '点击“按升序排序”',
    cancelSort: '点击“取消排序”',
    expandRow: '展开行',
    collapseRow: '折叠行',
    selectionAllData: '选择全部数据',
    overview: '总览',
    settings: '设置',
    system: '系统',
    verification: '验证',
    recipients: '接受者',
    statistics: '数据',
    currencies: '货币',
    segments: '分区',
    segment: '分区',
    sendFrom: '从...发送',
    subject: '主题',
    sender: '发送者',
    canceledBy: '取消来自',
    finish: '完成',
    balance: '余额',
    lPlayer: '玩家',
    manage: '管理',
    configure: '配置',
    totalPlayers: '玩家总数',
    auditLogs: '审核日志',
    composeOptions: '撰写选项',
    compose: '撰写',
    selectBonusType: '选择奖金类型',
    gamesInSegment: '分区中的游戏',
    playersInSegment: '分区中的玩家',
    statusUpdate: '状态更新',
    balanceAdjustments: '余额调整',
    restrictedPlayers: '受限制的玩家',
    referees: '推荐人',
    participants: '参与者',
    txnDetails: '交易详情',
    casinoBetsReport: '娱乐场投注报告',
    clone: '复制',
    details: '详情',
    general: '通用',
    next: '下一个',
    invalidPlayers: '无效玩家',
    send: '发送',
    reSend: '重新发送',
    now: '现在',
    downloadSample: '下载示例',
    download: '下载',
    uploadList: '上传列表',
    playerUploadFormatMsg: '您可以上传玩家列表。我们仅接受CSV格式',
    memorySize: {
      bytes: '字节',
      kb: 'KB',
      mb: 'MB',
      gb: 'GB',
    },
    exportCsv: '导出CSV',
    csvWithDetails: 'CSV（详情版）',
    columns: '栏',
    playerId: '玩家ID',
    username: '用户名',
    firstName: '姓氏',
    lastName: '名字',
    accountStatus: '账户状态',
    startDate: '开始日期',
    gender: '性别',
    birthDate: '出生日期',
    document: '文件',
    city: '城市',
    mobileNumber: '手机号码',
    documentNumber: '文件编号',
    lastLoginDate: '最近登录日期',
    assign: '分配',
    position: '名次',
    amount: '金额',
    ggr: '博彩总收入',
    brand: '品牌',
    fullName: '全名',
    dateUploaded: '上传日期',
    uploadedBy: '上传',
    player: '玩家',
    approve: '批准',
    reject: '拒绝',
    from: '从',
    to: '到',
    casino: '娱乐场',
    lCasino: '娱乐场',
    transactions: '交易',
    lTransactions: '交易',
    verify: '验证',
    lVerify: '验证',
    lUnverify: '解除验证',
    notVerified: '未验证',
    feePercent: '手续费，%',
    convertedAmount: '转换金额',
    conversionRate: '汇率',
    note: '注意',
    addNote: '添加备注',
    balanceBefore: '此前余额',
    balanceAfter: '此后余额',
    txnId: '交易ID',
    extTxnId: '扩展交易ID',
    state: '状态',
    bonusId: '奖金ID',
    betAmount: '投注额',
    winAmount: '赢利额',
    platform: '平台',
    ip: 'IP',
    betType: '投注类型',
    odd: '赔率',
    adjustmentType: '调整类型',
    operationType: '运营类型',
    datePerformed: '执行日期',
    transactionId: '交易ID',
    performedBy: '表演者',
    dateRange: '日期范围',
    comments: '评论',
    timestamp: '时间戳',
    userId: '用户ID',
    browser: '浏览器',
    resource: '资源',
    deviceID: '设备ID',
    rangeDates: '范围日期',
    notFound: '未发现',
    upload: '上传',
    acceptableFormat: '可接受格式',
    maxUploadSize: '文件大小不得超出',
    maxSumUploadSize: '最大上传大小不得超过',
    fileDetails: '文件详情',
    csvSample: 'CSV示例',
    invalidKeys: '无效密钥',
    middleName: '中间名',
    fathersName: '父亲姓名',
    source: '来源',
    tfa: '双重认证',
    move: '移动',
    merge: '合并',
    color: '颜色',
    numbersRange: {
      min: '最低',
      max: '最高',
    },
    day: '日',
    operationDates: '运营日期',
    trigger: '触发器',
    languages: '语言',
    location: '地址',
    created: '创建的',
    updated: '更新的',
    value: '价值',
    totalAmount: '总金额',
    single: '单个',
    bulk: '批量',
    managePermissions: '管理权限',
    depositId: '存款ID',
    pageCantBeDisplayed: '页面无法显示',
    youDontHavePermission: '页面URL无效，或您没有权限查看该URL。',
    lCategory: '类别',
    thePlayer: '玩家',
    accounts: '账户',
    password: '密码',
    templateName: '模板名称',
    allPlayers: '全部玩家',
    segmentName: '分区名称',
    attach: '附件',
    schedule: '定时',
    body: '正文',
    templateId: '模板ID',
    confirm: '确认',
    accountInfo: '账户信息',
    company: '公司',
    selectCashbackType: '选择现金返还类型',
    misConfigured: '配置错误',
    attentionMarked: '标记的注意',
    paymentProvider: '付款供应商',
    submissionDate: '提交日期',
    ngr: '游戏净收入',
    icon: '图标',
    betId: '投注ID',
    paymentRequestId: '付款请求ID',
    roundIdCouponNumber: '回合ID/优惠券号码',
    dateDuration: '{{hour}} 小时{{minute}} 分{{second}} 秒',
    noCardSelected: '未选择卡',
    backToList: '返回至列表',
    time: {
      hour: '小时',
      minute: '分钟',
      second: '秒',
    },
    duration: '时长',
    browserId: '浏览器ID',
    preview: '预览',
    sportBetsReport: '体育投注报告',
    lMinutes: '分钟',
    pageNotFound: '糟糕！未找到页面',
    title: '标题',
    zipCode: '邮政编码',
    url: 'URL',
    rate: '比率',
    conversionRates: '汇率',
    conversionOrConversions: '转换',
    gameManagement: '游戏管理',
    date: '日期',
    restricted: '受限制',
    maintenanceState: '维护状态',
    underMaintenance: '维护中',
    noMaintenance: '无维护',
    gameSystemState: '游戏系统状态',
    pages: '页面',
    groupName: '组名',
    betMinusWin: '投注—赢',
    today: '本日',
    lastWeek: '上周',
    lastMonth: '上月',
    yesterday: '昨天',
    totalWithdrawals: '提款总额',
    totalDeposits: '总存款',
    viewAll: '查看全部',
    deviceType: '设备类型',
    horizontalTags: '水平标签',
    verticalTags: '垂直标签',
    addNew: '添加新的',
    widgets: '小工具',
    lMobileNumber: '手机号码',
    infoUrl: '信息URL',
    previousAccounts: '之前的账户',
    keys: '密钥',
    messages: '信息',
    providers: '提供商',
    unVerify: '取消验证',
    thisMonth: '本月',
    thisWeek: '本周',
    typeHere: 'Введите сюда',
    reasonsSystem: '原因系统',
    campaigns: '活动',
    noResults: '无结果',
    shared: '共享',
    tagCategories: '标记类别',
    reasons: '原因',
    taxes: '税',
    widget: '小工具',
    expirationPeriod: '投注期',
    maxAmount: '最大金额',
    timeOutWebsite: '计时网站，Webmobile，Pwapp',
    timeOutNativeApp: '超时的本地应用',
    agSentData: 'AG发送的数据',
    developmentTools: '研发工具',
    copy: '复制',
    lobbyManagement: '大厅管理',
    spinsCount: '旋转计数',
    lNow: '现在',
    selected: '选定',
    playerName: '玩家名称',
    totalBonuses: '奖金总额',
    totalCount: '总数',
    validTill: '有效分红',
    systemCategory: '系统类别',
    demoVersion: '演示版',
    gameState: '游戏状态',
    notification: '通知',
    methodType: '方式类型',
    playerBonusId: '玩家奖金的ID',
    spinCount: '旋转数次',
    spinAmount: '旋转金额',
    activationDate: '激活日期',
    receivedDate: '接收日期',
    extId: '扩展ID',
    feeAmount: '手续费金额',
    taxAmount: '税额',
    taxPercent: '税率%',
    taxTotalAmount: '总税额',
    amountAfterTaxes: '税后金额',
    conversionCurrency: '货币转换',
    operationDate: '操作日期',
    timePeriod: '时间段',
    fullDay: '全天',
    section: '节',
    campaignName: '活动名称',
    bonusFamily: '奖金大组',
    bonusAmount: '奖金数额',
    roundId: '回合ID',
    currentValue: '当前价值',
    sportStatus: '体育状态',
    crm: 'CRM',
    sms: '短信',
    transferTo: '转账至',
    manageMaintenance: '管理维护',
    attribute: '属性',
    priority: '优先级',
    widgetName: '组件名称',
    generateReport: '生成报告',
    generateReportNote: '生成对账单后，您将收到一封电子邮件提醒。对账单将在<0>Export Center</0>中保存7天。',
    exportCenter: '导出中心',
    remove: '移除',
    ok: 'OK',
    aggregator: '聚合器',
    providerId: '供应商ID',
    gameBrandState: '游戏品牌状态',
    gameGlobalState: '游戏全球状态',
    providerList: '供应商名单',
    gameList: '游戏名单',
    oldPassword: '旧密码',
    newPassword: '新密码',
    confirmPassword: '确认密码',
    changePassword: '更改密码',
    pushNotifications: '推送通知',
    total: '总计',
    more: '更多',
    templateWasDeleted: '模板已删除，不再可用',
    activatedBy: '被激活',
    dateOfBirth: '出生日期',
    uDays: '日数',
    bonusBetAmount: '奖金投注额',
    bonusWinAmount: '奖金赢取额',
    brandId: '品牌ID',
    regulatory: '监管',
    betCount: '投注数',
    winCount: '获胜次数',
    taxation: '税收',
    bonusType: '奖金类型',
    betStatusCorrection: '投注状态更正',
    none: '无',
    unselect: '取消选择',
    tagLogs: '标签日志',
    romanian: '罗马尼亚语',
    cypriot: '塞浦路斯语',
    betBuilder: '投注生成器',
    sessions: '专场',
    playerDeletedReason: '玩家账户已关闭',
    playersTimeOutReason: '该玩家处于暂停期',
    playersSelfExcludedReason: '该玩家处于自我禁制期',
    playersBlockedReason: '该玩家已限制帐户状态',
    conditions: '条件',
    gameType: '游戏类型',
    translationKey: '翻译密钥',
    bTag: 'BTag',
    lWidget: '小工具',
    createWidget: '创建小工具',
    onJoin: '关于加入',
    main: '主要的',
    sourceType: '源类型',
  },
  ro: {
    totalWithdrawals: 'Retrageri totale',
    totalDeposits: 'Depozite totale',
    amount: 'Sumă',
    tags: 'Etichete',
    Reason: 'Motiv',
    pending: 'În așteptare',
    failed: 'Eșuat',
    verticalTags: 'Taguri verticale',
    horizontalTags: 'Taguri orizontale',
    home: 'Pagina principală',
    dashboard: 'Tablou de bord',
    brands: 'Mărci',
    players: 'Jucători',
    testPlayers: 'Testa jucătorii',
    testPlayer: 'Testa jucătorii',
    kycReport: 'Raport KYC ',
    financials: 'Financiare',
    deposits: 'Depuneri',
    lDeposits: 'depuneri',
    withdrawals: 'Retrageri',
    lWithdrawals: 'retrageri',
    bets: 'Pariuri',
    bonusManagement: 'Managementul bonusurilor',
    bonuses: 'Bonusuri',
    bonusTemplates: 'Șabloane bonus',
    templates: 'Șabloane',
    rules: 'Regule',
    cashback: 'Cashback',
    lCashback: 'cashback',
    lCashbacks: 'cashback-uri',
    promoCodes: 'Coduri promoționale',
    referralBonus: 'Bonus de recomandare',
    riskManagement: 'Managementul riscurilor',
    similarAccounts: 'Conturi similare',
    bulkActions: 'Acțiuni în masă',
    blacklist: 'Listă neagră',
    tools: 'Instrumente',
    bonusSetup: 'Configurare bonus',
    brandAutomation: 'Automatizarea mărcii',
    tag: 'Etichetă',
    lTag: 'etichetă',
    marketing: 'Marketing',
    market: 'Piață',
    playerSegments: 'Segmente de jucător',
    gameSegments: 'Segmente de joc',
    notificationTemplates: 'Șabloane de notificare',
    lNotificationTemplate: 'șabloane de notificare',
    massMessages: 'Mesaje în masă',
    inboxMessages: 'Mesaje primite',
    cms: 'CMS',
    translations: 'Traduceri',
    systemNotifications: 'Notificări de sistem',
    lSystemNotifications: 'notificări de sistem',
    systemNotification: 'Notificări de sistem',
    fileManager: 'Manager de fișiere',
    reporting: 'Raportare',
    businessIntelligence: 'Analiză de afaceri',
    compliance: 'Conformitate',
    partners: 'Parteneri',
    partner: 'Partener',
    userManagement: 'Managementul utilizatorilor',
    users: 'Utilizatori',
    permissionGroups: 'Grupuri de permisiuni',
    permissions: 'Permisiuni',
    ipWhitelist: 'Lista albă de adresă IP',
    auditLog: 'Jurnal de audit',
    systemConversion: 'Conversie de sistem',
    systemSettings: 'Setari de sistem',
    mainSettings: 'Setări principale',
    sportsBook: 'Pariuri sportive',
    lSportsBook: 'pariuri sportive',
    providersAndGames: 'Furnizori și jocuri',
    payments: 'Plăți',
    payment: 'Plată',
    operatorLimits: 'Limitele operatorului',
    operator: 'Operator',
    limits: 'Limite ',
    thirdPartyServices: 'Servicii de terți',
    notificationSettings: 'Setări de notificare',
    seoSettings: 'Setări SEO',
    locationServices: 'Servicii de localizare',
    conversionSettings: 'Setări de conversie',
    legal: 'Legal',
    standards: 'Standarde',
    casinoGames: 'Jocuri de casino',
    account: 'Cont ',
    lAccount: 'cont ',
    myAccount: 'Contul meu',
    changePass: 'Schimba parola',
    logOut: 'Deconectează-te',
    logo: 'Siglă',
    nightMode: 'Mod întunecat',
    lightMode: 'Mod luminos',
    quickSearch: 'Cautare rapidă',
    add: 'Adăuga',
    gameId: 'ID de joc ',
    cancel: 'Anula',
    search: 'Căuta',
    country: 'Țară',
    actions: 'Acțiuni',
    action: 'Acțiune',
    registrationDate: 'Data Înregistrării',
    timeZone: 'Fus orar',
    language: 'Limbă',
    currency: 'Valută',
    edit: 'Editează ',
    view: 'Vizualiza',
    collapse: 'Restrânge',
    expand: 'Extinde',
    disabled: 'Dezactivat',
    enabled: 'Activat',
    disable: 'Dezactiva',
    enable: 'Activa',
    lDisable: 'dezactiva',
    lEnable: 'activa',
    currencyId: 'ID de valută ',
    currencyName: 'Numele de valută',
    languageId: 'ID de limbă ',
    languageName: 'Numele de limbă ',
    selectAll: 'Selecta tot',
    status: 'Stare ',
    name: 'Nume',
    providerName: 'Numele de furnizor',
    provider: 'Furnizor',
    lProvider: 'furnizor',
    games: 'Jocuri',
    game: 'Joc',
    dateAdded: 'Data adaugată',
    addedBy: 'Adaugat de',
    select: 'Selecta ',
    categories: 'Categorii',
    email: 'E-mail',
    lEmail: 'E-mail ',
    senderName: 'Nume de expeditor',
    default: 'Implicit ',
    logs: 'Loguri',
    logId: 'ID de înregistrare',
    dateUpdated: 'Data actualizată',
    updatedBy: 'Actualizată de',
    description: 'Descriere',
    ipAddress: 'Adresă IP',
    bet: 'Pariu',
    win: 'Câștig',
    deposit: 'Depozit',
    withdrawal: 'Retragere',
    reset: 'Resetare',
    transaction: 'Tranzacţie',
    valueBefore: 'Valoare înainte',
    valueAfter: 'Valoare după',
    period: 'Perioadă ',
    policy: 'Politică ',
    version: 'Versiune',
    effectiveDate: 'Data efectivă',
    endDate: 'Data de încheiere',
    creationDate: 'Data de creare',
    createdBy: 'Creat de',
    paymentMethod: 'Metodă de plată',
    paymentMethods: 'Ödeme yöntemleri',
    type: 'Tip ',
    restrictedCountries: 'Țări restricționate',
    countries: 'Țări',
    lastUpdateDate: 'Data de ultima actualizare',
    lastUpdateBy: 'Ultima actualizare până la',
    update: 'Actualiza ',
    fee: 'Comision',
    limit: 'Limită ',
    limitBefore: 'Limită înainte',
    save: 'Salvare',
    discard: 'Decartează',
    apply: 'Aplică',
    active: 'Activa',
    inactive: 'Inactiv',
    lPending: 'în așteptare',
    verified: 'Verificat',
    info: 'Informație',
    captcha: 'Captcha',
    apiKey: 'Cheie API',
    secretKey: 'Cheie secretă',
    mins: 'min. ',
    hours: 'ore',
    hour: 'oră',
    days: 'zile',
    months: 'luni ',
    documentType: 'Tip de document ',
    sportBetting: 'Pariuri sportive',
    casinoBetting: 'Pariuri la cazinou',
    sport: 'Sport',
    address: 'Adresă',
    lAddress: 'adresă',
    identity: 'Identitate',
    lIdentity: 'identitate',
    physical: 'Fizic ',
    reason: 'Motiv ',
    comment: 'Cometariu',
    text: 'Text',
    filter: 'Filtru',
    lDomain: 'Domeniu ',
    count: 'Număr',
    noData: 'Nu există date',
    category: 'Categorie ',
    autoUpdate: 'Actualizare automată',
    all: 'Toți/Toate',
    clear: 'Goli ',
    show: 'Arăta ',
    back: 'Înapoi',
    both: 'Ambii',
    gameName: 'Numele jocului',
    yes: 'Da',
    no: 'Nu',
    connect: 'Conecta ',
    systemState: 'Stare de sistem',
    id: 'ID',
    enterId: 'Introduce ID',
    delete: 'Șterge ',
    block: 'Bloca ',
    lBlock: 'bloca ',
    lUnblock: 'debloca',
    import: 'Importă',
    export: 'Exporta',
    create: 'Creează',
    statuses: 'Statusuri',
    website: 'Site web',
    procedures: 'Proceduri',
    activate: 'Activa ',
    deactivate: 'Dezactiva',
    lDeactivate: 'dezactiva',
    lActivate: 'activa ',
    seeLess: 'Vezi mai puțin',
    seeMore: 'Vezi mai mult',
    changes: 'Modificări ',
    showTotal: '{{min}}-{{max}} din {{total}} articol(e)',
    triggerDesc: 'Fă clic pe sortare după coborâre',
    triggerAsc: 'Fă clic pe sortare după ascendere',
    cancelSort: 'Fă clic pentru a anula sortarea',
    expandRow: 'Extinde rândul',
    collapseRow: 'Restrânge rândul',
    selectionAllData: 'Selectează toate datele',
    overview: 'Prezentare generală',
    settings: 'Setări ',
    system: 'Sistem ',
    verification: 'Verificare ',
    recipients: 'Destinatari',
    statistics: 'Statistici',
    currencies: 'Monede ',
    segments: 'Segmente ',
    segment: 'Segment ',
    sendFrom: 'Trimis de la',
    subject: 'Subiect',
    sender: 'Expeditor',
    canceledBy: 'Anulat de',
    finish: 'Finaliza',
    balance: 'Sold ',
    lPlayer: 'Jucător ',
    manage: 'Gestiona ',
    configure: 'Configura',
    totalPlayers: 'Total jucători',
    auditLogs: 'Jurnale de audit',
    composeOptions: 'Opțiuni de scriere',
    compose: 'Compune',
    selectBonusType: 'Selectează tipul de bonus',
    gamesInSegment: 'Jocuri în segment',
    playersInSegment: 'Jucători din segment',
    statusUpdate: 'Actualizare de stare',
    balanceAdjustments: 'Ajustări de sold',
    restrictedPlayers: 'Jucători restricționați',
    referees: 'Arbitrii',
    participants: 'Participanți',
    txnDetails: 'Detalii TXN',
    casinoBetsReport: 'Raport pariuri de cazino',
    clone: 'Copia ',
    details: 'Detalii ',
    general: 'General ',
    next: 'Următorul ',
    invalidPlayers: 'Jucători pe lista de invaliditate',
    send: 'Trimite ',
    reSend: 'Retrimite',
    now: 'Acum',
    downloadSample: 'Descărca eșantionul',
    download: 'Descărca',
    uploadList: 'Încărca lista',
    playerUploadFormatMsg: 'Poți încărca liste de jucători. Doar formatul CSV este acceptabil',
    exportCsv: 'Export CSV',
    csvWithDetails: 'CSV (cu detalii)',
    columns: 'Coloane',
    playerId: 'ID de jucător',
    username: 'Nume de utilizator',
    firstName: 'Nume',
    lastName: 'Numele de familie',
    accountStatus: 'Stare de cont',
    startDate: 'Data de început',
    gender: 'Gen',
    birthDate: 'Data de naștere ',
    document: 'Document ',
    city: 'Oraș ',
    mobileNumber: 'Număr de telefon mobil',
    documentNumber: 'Numărul de documente',
    assign: 'Atribui',
    position: 'Poziţie',
    ggr: 'GGR',
    brand: 'Marcă',
    fullName: 'Numele complet',
    dateUploaded: 'Data de încărcare',
    uploadedBy: 'Încărcat de',
    player: 'Jucător',
    approve: 'Aproba',
    reject: 'Respinge',
    from: 'De la ',
    to: 'La ',
    casino: 'Casino',
    lCasino: 'casino ',
    transactions: 'Tranzacții',
    lTransactions: 'tranzacții',
    verify: 'Verifica ',
    lVerify: 'verifica ',
    lUnverify: 'neverifica',
    notVerified: 'Neverificat',
    feePercent: 'Taxă',
    convertedAmount: 'Sumă convertită',
    conversionRate: 'Rata de conversie',
    note: 'Notă',
    addNote: 'Adăuga notiță',
    balanceBefore: 'Soldul înainte',
    balanceAfter: 'Soldul după',
    txnId: 'ID TXN',
    extTxnId: 'Ext. ID TXN',
    state: 'Stare ',
    bonusId: 'ID bonus ',
    betAmount: 'Suma de pariu',
    winAmount: 'Suma de câștig',
    platform: 'Platformă',
    ip: 'IP',
    betType: 'Tip de pariu ',
    odd: 'Cotă ',
    adjustmentType: 'Tip de ajustare',
    operationType: 'Tip de operațiune',
    datePerformed: 'Data efectuării',
    transactionId: 'ID-ul de tranzacție',
    performedBy: 'Realizat de către',
    dateRange: 'Interval de date',
    comments: 'Comentarii',
    timestamp: 'Timestamp',
    userId: 'ID de utilizator',
    browser: 'Browser',
    resource: 'Resursă',
    deviceID: 'ID-ul dispozitivului',
    rangeDates: 'Interval de date',
    notFound: 'Nu a fost găsit',
    upload: 'Încarcă',
    acceptableFormat: 'Format acceptabil',
    maxUploadSize: 'Dimensiunea de fișier nu poate depăși',
    maxSumUploadSize: 'Dimensiunea maximă de încărcare nu poate depăși',
    fileDetails: 'Detalii fișier',
    csvSample: 'Exemplu CSV',
    invalidKeys: 'Chei nevalide',
    middleName: 'Al doilea nume',
    fathersName: 'Numele tatălui',
    source: 'Sursa ',
    tfa: 'Autentificare cu 2 factori',
    move: 'Muta ',
    merge: 'Îmbina',
    color: 'Culoare',
    day: 'zi',
    operationDates: 'Date de operațiune',
    trigger: 'Declanșator',
    languages: 'Limbă ',
    location: 'Locație',
    created: 'Creat ',
    updated: 'Actualizat',
    value: 'Valoare ',
    totalAmount: 'Valoare totală',
    single: 'Unic ',
    bulk: 'În masă ',
    managePermissions: 'Gestiona permisiunile',
    depositId: 'ID de depozit',
    pageCantBeDisplayed: 'Pagina nu poate fi afișată',
    youDontHavePermission: 'Adresa URL a paginii este nevalidă sau nu ai permisiunea de a o vizualiza.',
    lCategory: 'categorie ',
    thePlayer: 'Jucătorul',
    accounts: 'Conturi',
    password: 'Parolă ',
    templateName: 'Nume de șablon',
    allPlayers: 'Toți jucătorii',
    segmentName: 'Numele segmentului',
    attach: 'Atașa',
    schedule: 'Programa',
    body: 'Corp ',
    templateId: 'Șablon ',
    confirm: 'Confirma ',
    accountInfo: 'Informații de cont',
    company: 'Companie ',
    selectCashbackType: 'Selecta tipul de cashback',
    misConfigured: 'Configurat greșit',
    attentionMarked: 'Atenție marcată',
    paymentProvider: 'Furnizor de plăți',
    submissionDate: 'Data depunerii',
    ngr: 'NGR',
    icon: 'Pictogramă',
    betId: 'ID de pariu ',
    paymentRequestId: 'ID cerere de plată',
    roundIdCouponNumber: 'ID rundă/Număr de cupon',
    dateDuration: '{{ora}} {{minut}} {{secunde}}',
    noCardSelected: 'Niciun card selectat ',
    backToList: 'Înapoi la listă',
    duration: 'Durată',
    browserId: 'ID de browser',
    preview: 'previzualizare',
    sportBetsReport: 'Raport de pariuri sportive',
    lMinutes: 'minute',
    pageNotFound: 'Hopa! Pagina nu a fost gasită',
    title: 'Titlu',
    zipCode: 'Cod poștal',
    url: 'URL',
    rate: 'Rată',
    conversionRates: 'Rate de conversie',
    conversionOrConversions: 'Conversie(e)',
    gameManagement: 'Management de joc',
    date: 'Dată ',
    restricted: 'Restricţionat',
    maintenanceState: 'Stare de întreținere',
    underMaintenance: 'În mentenanță',
    noMaintenance: 'Nicio întreținere',
    gameSystemState: 'Stare de sistem de joc',
    pages: 'Pagini ',
    groupName: 'Numele Grupului',
    betMinusWin: 'Pariu - Câștig',
    today: 'Astăzi',
    lastWeek: 'Săptămâna trecută',
    lastMonth: 'Luna trecută',
    yesterday: 'Ieri ',
    messages: 'Mesaje ',
    providers: 'Furnizori',
    unVerify: 'Anula verificarea',
    deviceType: 'Tip de dispozitiv',
    viewAll: 'A vizualiza tot',
    thisMonth: 'Această lună ',
    thisWeek: 'Această săptămână',
    typeHere: 'Введите сюда',
    infoUrl: 'adresa URL a informațiilor',
    lastLoginDate: 'Data ultimei logări',
    campaigns: 'Campanii',
    previousAccounts: 'Conturi anterioare',
    keys: 'Keys (Chei)',
    widget: 'Widget',
    expirationPeriod: 'Perioadă de pariere',
    maxAmount: 'Suma maximă',
    timeOutWebsite: 'Time-out de site-ul web, Webmobile, Pwapp',
    timeOutNativeApp: 'Aplicații native de Time-out',
    reasonsSystem: 'Sistemă de motive',
    noResults: 'Niciun rezultat',
    shared: 'Partajat',
    tagCategories: 'Categorii de etichete',
    reasons: 'Motive',
    addNew: 'Adaugă noul',
    taxes: 'Impozite',
    widgets: 'Widgeturi',
    lMobileNumber: 'numărul de telefon mobil',
    agSentData: 'Date trimise AG',
    developmentTools: 'Instrumente de dezvoltare web',
    copy: 'Copiere',
    lobbyManagement: 'Management de lobby',
    spinsCount: 'Numărul de rotiri',
    lNow: 'acum',
    selected: 'Selectat',
    playerName: 'Numele jucătorului',
    totalBonuses: 'Bonusuri totale',
    totalCount: 'Numărul total',
    validTill: 'Valid până la',
    systemCategory: 'Categorie de sistem',
    demoVersion: 'Versiunea demo',
    gameState: 'Starea de joc',
    notification: 'Notificare',
    methodType: 'Tip de metodă',
    playerBonusId: 'ID bonus de jucător',
    spinCount: 'Numărul de rotiri',
    spinAmount: 'Suma de rotiri',
    activationDate: 'Data activării',
    receivedDate: 'Data primirii',
    extId: 'ID Ext.',
    feeAmount: 'Suma de taxă',
    taxAmount: 'Suma impozitului',
    taxPercent: 'Impozit',
    taxTotalAmount: 'Suma totală a impozitului',
    amountAfterTaxes: 'Suma după achitarea impozitelor',
    conversionCurrency: 'Valută de conversie',
    operationDate: 'Data operațiunii',
    timePeriod: 'Perioadă de timp',
    fullDay: 'Toată ziua',
    section: 'Secțiune',
    campaignName: 'Denumirea campaniei',
    bonusFamily: 'Familie (grupă) de Bonusuri',
    bonusAmount: 'Suma bonusului',
    roundId: 'ID de rundă',
    currentValue: 'Valoarea curentă',
    sportStatus: 'Statut sportiv',
    crm: 'CRM',
    sms: 'SMS',
    transferTo: 'Transfer la',
    manageMaintenance: 'Gestiona mentenanța',
    attribute: 'Atribuit',
    priority: 'Prioritate',
    widgetName: 'Numele widget-ului',
    sessions: 'Sesiuni',
    playerDeletedReason: 'Contul jucătorului a fost închis',
    playersTimeOutReason: 'Jucătorul este în time-out (cu timp expirat)',
    playersSelfExcludedReason: 'Jucătorul este autoexclus',
    playersBlockedReason: 'Jucătorul are un cont restricționat',
    conditions: 'Condiții',
    gameType: 'Tipul de joc',
    translationKey: 'Cheie de traducere',
    bTag: 'BTag',
    lWidget: 'widget',
    createWidget: 'Creează un Widget',
    main: 'Principal',
  },
  bg: {},
  el: {
    home: 'Αρχική',
    dashboard: 'Πίνακας ελέγχου',
    brands: 'Επωνυμίες',
    players: 'Παίκτες',
    testPlayers: 'Δοκιμαστικοί παίκτες',
    testPlayer: 'Δοκιμαστικός παίκτης',
    kycReport: 'Αναφορά KYC',
    financials: 'Οικονομικά',
    deposits: 'Καταθέσεις',
    lDeposits: 'καταθέσεις',
    withdrawals: 'Αναλήψεις',
    lWithdrawals: 'αναλήψεις',
    bets: 'Στοιχήματα',
    bonusManagement: 'Διαχείριση μπόνους',
    bonuses: 'Μπόνους',
    bonusTemplates: 'Πρότυπα μπόνους',
    templates: 'Πρότυπα',
    rules: 'Κανόνες',
    cashback: 'Επιστροφή μετρητών',
    lCashback: 'επιστροφή μετρητών',
    lCashbacks: 'επιστροφές μετρητών',
    promoCodes: 'Κωδικοί προώθησης',
    referralBonus: 'Μπόνους παραπομπής',
    riskManagement: 'Διαχείριση κινδύνου',
    similarAccounts: 'Παρόμοιοι λογαριασμοί',
    bulkActions: 'Μαζικές ενέργειες',
    blacklist: 'Λίστα αποκλεισμένων',
    tools: 'Εργαλεία',
    bonusSetup: 'Ρύθμιση μπόνους',
    brandAutomation: 'Αυτοματισμός επωνυμίας',
    tag: 'Ετικέτα',
    lTag: 'ετικέτα',
    tags: 'Ετικέτες',
    marketing: 'Μάρκετινγκ',
    market: 'Αγορά',
    playerSegments: 'Τμήματα παικτών',
    gameSegments: 'Τμήματα παιχνιδιών',
    notificationTemplates: 'Πρότυπα ειδοποιήσεων',
    lNotificationTemplate: 'πρότυπο ειδοποιήσεων',
    massMessages: 'Μαζικά μηνύματα',
    inboxMessages: 'Μηνύματα εισερχομένων',
    cms: 'CMS',
    translations: 'Μεταφράσεις',
    systemNotifications: 'Ειδοποιήσεις συστήματος',
    lSystemNotifications: 'ειδοποίηση συστήματος',
    systemNotification: 'Ειδοποίηση συστήματος',
    fileManager: 'Διαχείριση αρχείων',
    reporting: 'Αναφορές',
    businessIntelligence: 'Επιχειρηματική ευφυΐα',
    compliance: 'Συμμόρφωση',
    partners: 'Συνεργάτες',
    partner: 'Συνεργάτης',
    userManagement: 'Διαχείριση χρηστών',
    users: 'Χρήστες',
    permissionGroups: 'Ομάδες αδειών',
    permissions: 'Άδειες',
    ipWhitelist: 'Λίστα επιτρεπομένων IP',
    auditLog: 'Αρχείο καταγραφής ελέγχου',
    systemConversion: 'Μετατροπή συστήματος',
    systemSettings: 'Ρυθμίσεις συστήματος',
    mainSettings: 'Κύριες ρυθμίσεις',
    sportsBook: 'Sportsbook',
    lSportsBook: 'sportsbook',
    providersAndGames: 'Πάροχοι και παιχνίδια',
    payments: 'Πληρωμές',
    payment: 'Πληρωμή',
    operatorLimits: 'Όρια χειριστή',
    operator: 'Χειριστής',
    limits: 'Όρια',
    thirdPartyServices: 'Υπηρεσίες τρίτων',
    notificationSettings: 'Ρυθμίσεις ειδοποιήσεων',
    seoSettings: 'Ρυθμίσεις SEO',
    locationServices: 'Υπηρεσίες τοποθεσίας',
    conversionSettings: 'Ρυθμίσεις μετατροπών',
    legal: 'Νομικά',
    standards: 'Πρότυπα',
    casinoGames: 'Παιχνίδια καζίνο',
    account: 'Λογαριασμός',
    lAccount: 'λογαριασμός',
    myAccount: 'Ο λογαριασμός μου',
    changePass: 'Αλλαγή κωδικού πρόσβασης',
    logOut: 'Αποσύνδεση',
    logo: 'Λογότυπο',
    nightMode: 'Νυχτερινή λειτουργία',
    lightMode: 'Φωτεινή λειτουργία',
    quickSearch: 'Γρήγορη αναζήτηση',
    add: 'Προσθήκη',
    gameId: 'Αναγνωριστικό παιχνιδιού',
    cancel: 'Ακύρωση',
    search: 'Αναζήτηση',
    country: 'Χώρα',
    actions: 'Ενέργειες',
    action: 'Ενέργεια',
    registrationDate: 'Ημερομηνία εγγραφής',
    timeZone: 'Ζώνη ώρας',
    language: 'Γλώσσα',
    currency: 'Νόμισμα',
    edit: 'Επεξεργασία',
    view: 'Προβολή',
    collapse: 'Σύμπτυξη',
    expand: 'Ανάπτυξη',
    disabled: 'Απενεργοποιημένο',
    enabled: 'Ενεργοποιημένο',
    disable: 'Απενεργοποίηση',
    enable: 'Ενεργοποίηση',
    lDisable: 'απενεργοποίηση',
    lEnable: 'ενεργοποίηση',
    currencyId: 'Αναγνωριστικό νομίσματος',
    currencyName: 'Όνομα νομίσματος',
    languageId: 'Αναγνωριστικό γλώσσας',
    languageName: 'Όνομα γλώσσας',
    selectAll: 'Επιλογή όλων',
    status: 'Κατάσταση',
    name: 'Όνομα',
    providerName: 'Όνομα παρόχου',
    provider: 'Πάροχος',
    lProvider: 'πάροχος',
    games: 'Παιχνίδια',
    game: 'Παιχνίδι',
    dateAdded: 'Ημερομηνία προσθήκης',
    addedBy: 'Προστέθηκε από',
    select: 'Επιλογή',
    categories: 'Κατηγορίες',
    email: 'Email',
    lEmail: 'email',
    senderName: 'Όνομα αποστολέα',
    default: 'Προεπιλεγμένο',
    logs: 'Αρχεία καταγραφής',
    logId: 'Αναγνωριστικό αρχείου καταγραφής',
    dateUpdated: 'Ημερομηνία ενημέρωσης',
    updatedBy: 'Ενημερώθηκε από',
    description: 'Περιγραφή',
    ipAddress: 'Διεύθυνση IP',
    bet: 'Στοίχημα',
    win: 'Κέρδος',
    deposit: 'Κατάθεση',
    withdrawal: 'Ανάληψη',
    reset: 'Επαναφορά',
    transaction: 'Συναλλαγή',
    valueBefore: 'Τιμή πριν',
    valueAfter: 'Τιμή μετά',
    period: 'Περίοδος',
    policy: 'Πολιτική',
    version: 'Έκδοση',
    effectiveDate: 'Ημερομηνία έναρξης ισχύος',
    endDate: 'Ημερομηνία λήξης',
    creationDate: 'Ημερομηνία δημιουργίας',
    createdBy: 'Δημιουργήθηκε από',
    paymentMethod: 'Μέθοδος πληρωμής',
    paymentMethods: 'Μέθοδοι πληρωμής',
    type: 'Τύπος',
    restrictedCountries: 'Χώρες υπό περιορισμό',
    countries: 'Χώρες',
    lastUpdateDate: 'Ημερομηνία τελευταίας ενημέρωσης',
    lastUpdateBy: 'Τελευταία ενημέρωση από',
    update: 'Ενημέρωση',
    fee: 'Χρέωση',
    limit: 'Όριο',
    limitBefore: 'Όριο πριν',
    save: 'Αποθήκευση',
    discard: 'Απόρριψη',
    apply: 'Εφαρμογή',
    active: 'Ενεργό',
    inactive: 'Ανενεργό',
    pending: 'Σε εκκρεμότητα',
    lPending: 'σε εκκρεμότητα',
    verified: 'Επαληθευμένο',
    failed: 'Αποτυχία',
    info: 'Πληροφορίες',
    captcha: 'Captcha',
    apiKey: 'Κλειδί API',
    secretKey: 'Μυστικό κλειδί',
    timeOutWebsite: 'Προσωρινή διακοπή – Ιστότοπος, Webmobile, Pwapp',
    timeOutNativeApp: 'Προσωρινή διακοπή – Εγγενείς εφαρμογές',
    mins: 'λεπτά',
    hours: 'ώρες',
    hour: 'ώρα',
    days: 'ημέρες',
    months: 'μήνες',
    documentType: 'Τύπος εγγράφου',
    sportBetting: 'Αθλητικά στοιχήματα',
    casinoBetting: 'Στοιχήματα καζίνο',
    sport: 'Άθλημα',
    address: 'Διεύθυνση',
    lAddress: 'διεύθυνση',
    identity: 'Ταυτότητα',
    lIdentity: 'ταυτότητα',
    physical: 'Φυσική',
    reason: 'Αιτία',
    comment: 'Σχόλιο',
    text: 'Κείμενο',
    filter: 'Φίλτρο',
    lDomain: 'τομέας',
    count: 'Αριθμός',
    noData: 'Δεν υπάρχουν δεδομένα',
    category: 'Κατηγορία',
    autoUpdate: 'Αυτόματη ενημέρωση',
    all: 'Όλα',
    clear: 'Καθαρισμός',
    show: 'Εμφάνιση',
    back: 'Επιστροφή',
    both: 'Και τα δύο',
    gameName: 'Όνομα παιχνιδιού',
    yes: 'Ναι',
    no: 'Όχι',
    connect: 'Σύνδεση',
    systemState: 'Κατάσταση συστήματος',
    id: 'Αναγνωριστικό',
    enterId: 'Εισαγάγετε αναγνωριστικό',
    delete: 'Διαγραφή',
    block: 'Αποκλεισμός',
    lBlock: 'αποκλεισμός',
    lUnblock: 'αναίρεση αποκλεισμού',
    import: 'Εισαγωγή',
    export: 'Εξαγωγή',
    create: 'Δημιουργία',
    statuses: 'Καταστάσεις',
    website: 'Ιστότοπος',
    procedures: 'Διαδικασίες',
    activate: 'Ενεργοποίηση',
    deactivate: 'Απενεργοποίηση',
    lDeactivate: 'απενεργοποίηση',
    lActivate: 'ενεργοποίηση',
    seeLess: 'Εμφάνιση λιγότερων',
    seeMore: 'Εμφάνιση περισσότερων',
    changes: 'Αλλαγές',
    showTotal: '{{min}}-{{max}} από {{total}} στοιχείο(-α)',
    triggerDesc: 'Κάντε κλικ στην ταξινόμηση κατά φθίνουσα σειρά',
    triggerAsc: 'Κάντε κλικ στην ταξινόμηση κατά αύξουσα σειρά',
    cancelSort: 'Κάντε κλικ για να ακυρώσετε την ταξινόμηση',
    expandRow: 'Ανάπτυξη σειράς',
    collapseRow: 'Σύμπτυξη σειράς',
    selectionAllData: 'Επιλογή όλων των δεδομένων',
    overview: 'Επισκόπηση',
    settings: 'Ρυθμίσεις',
    system: 'Σύστημα',
    verification: 'Επαλήθευση',
    recipients: 'Αποδέκτες',
    statistics: 'Στατιστικά',
    currencies: 'Νομίσματα',
    segments: 'Τμήματα',
    segment: 'Τμήμα',
    sendFrom: 'Αποστολή από',
    subject: 'Θέμα',
    sender: 'Αποστολέας',
    canceledBy: 'Ακυρώθηκε από',
    finish: 'Τέλος',
    balance: 'Υπόλοιπο',
    lPlayer: 'παίκτης',
    manage: 'Διαχείριση',
    configure: 'Διαμόρφωση',
    totalPlayers: 'Σύνολο παικτών',
    auditLogs: 'Αρχεία καταγραφής ελέγχου',
    composeOptions: 'Επιλογές σύνθεσης',
    compose: 'Σύνθεση',
    selectBonusType: 'Επιλογή τύπου μπόνους',
    gamesInSegment: 'Παιχνίδια στο τμήμα',
    playersInSegment: 'Παίκτες στο τμήμα',
    statusUpdate: 'Ενημέρωση κατάστασης',
    balanceAdjustments: 'Προσαρμογές υπολοίπου',
    restrictedPlayers: 'Παίκτες υπό περιορισμό',
    referees: 'Παραπεμπόμενοι',
    participants: 'Συμμετέχοντες',
    txnDetails: 'Στοιχεία συναλ.',
    casinoBetsReport: 'Αναφορά στοιχημάτων καζίνο',
    clone: 'Κλωνοποίηση',
    details: 'Στοιχεία',
    general: 'Γενικά',
    next: 'Επόμενο',
    invalidPlayers: 'Μη έγκυροι παίκτες',
    send: 'Αποστολή',
    reSend: 'Αποστολή εκ νέου',
    now: 'Τώρα',
    downloadSample: 'Λήψη δείγματος',
    download: 'Λήψη',
    uploadList: 'Μεταφόρτωση λίστας',
    playerUploadFormatMsg: 'Μπορείτε να μεταφορτώσετε λίστες παικτών. Μόνο η μορφή CSV είναι αποδεκτή',
    memorySize: {
      bytes: 'Byte',
      kb: 'KB',
      mb: 'MB',
      gb: 'GB',
    },
    exportCsv: 'Εξαγωγή CSV',
    csvWithDetails: 'CSV (με στοιχεία)',
    columns: 'Στήλες',
    playerId: 'Αναγνωριστικό παίκτη',
    username: 'Όνομα χρήστη',
    firstName: 'Όνομα',
    lastName: 'Επώνυμο',
    accountStatus: 'Κατάσταση λογαριασμού',
    startDate: 'Ημερομηνία έναρξης',
    gender: 'Φύλο',
    birthDate: 'Ημερομηνία γέννησης',
    document: 'Έγγραφο',
    city: 'Πόλη',
    mobileNumber: 'Αριθμός κινητού τηλεφώνου',
    documentNumber: 'Αριθμός εγγράφου',
    lastLoginDate: 'Ημερομηνία τελευταίας σύνδεσης',
    assign: 'Ανάθεση',
    position: 'Θέση',
    amount: 'Ποσό',
    ggr: 'GGR',
    brand: 'Επωνυμία',
    fullName: 'Ονοματεπώνυμο',
    dateUploaded: 'Ημερομηνία μεταφόρτωσης',
    uploadedBy: 'Μεταφορτώθηκε από',
    player: 'Παίκτης',
    approve: 'Έγκριση',
    reject: 'Απόρριψη',
    from: 'Από',
    to: 'Προς',
    casino: 'Καζίνο',
    lCasino: 'καζίνο',
    transactions: 'Συναλλαγές',
    lTransactions: 'συναλλαγές',
    verify: 'Επαλήθευση',
    lVerify: 'επαλήθευση',
    lUnverify: 'αναίρεση επαλήθευσης',
    notVerified: 'Μη επαληθευμένο',
    feePercent: 'Χρέωση, %',
    convertedAmount: 'Μετατραπέν ποσό',
    conversionRate: 'Συναλλαγματική ισοτιμία',
    note: 'Σημείωση',
    addNote: 'Προσθήκη σημείωσης',
    balanceBefore: 'Υπόλοιπο πριν',
    balanceAfter: 'Υπόλοιπο μετά',
    txnId: 'Αναγνωριστικό συναλ.',
    extTxnId: 'Αναγνωριστικό εξ. συναλ.',
    state: 'Κατάσταση',
    bonusId: 'Αναγνωριστικό μπόνους',
    betAmount: 'Ποσό στοιχήματος',
    winAmount: 'Ποσό κέρδους',
    platform: 'Πλατφόρμα',
    ip: 'IP',
    betType: 'Τύπος στοιχήματος',
    odd: 'Απόδοση',
    adjustmentType: 'Τύπος προσαρμογής',
    operationType: 'Τύπος λειτουργίας',
    datePerformed: 'Ημερομηνία εκτέλεσης',
    transactionId: 'Αναγνωριστικό συναλλαγής',
    performedBy: 'Εκτελέστηκε από',
    dateRange: 'Εύρος ημερομηνιών',
    comments: 'Σχόλια',
    timestamp: 'Χρονική σήμανση',
    userId: 'Αναγνωριστικό χρήστη',
    browser: 'Πρόγραμμα περιήγησης',
    resource: 'Πόρος',
    deviceID: 'Αναγνωριστικό συσκευής',
    rangeDates: 'Ημερομηνίες εύρους',
    notFound: 'Δεν βρέθηκε',
    upload: 'Μεταφόρτωση',
    acceptableFormat: 'Αποδεκτή μορφή',
    maxUploadSize: 'Το μέγεθος του αρχείου δεν μπορεί να είναι πάνω από',
    maxSumUploadSize: 'Το μέγιστο συνολικό μέγεθος μεταφόρτωσης δεν μπορεί να είναι πάνω από',
    fileDetails: 'Στοιχεία αρχείου',
    csvSample: 'Δείγμα CSV',
    invalidKeys: 'Μη έγκυρα κλειδιά',
    middleName: 'Μεσαίο όνομα',
    fathersName: 'Όνομα πατρός',
    source: 'Πηγή',
    tfa: 'Έλεγχος ταυτότητας 2 παραγόντων',
    move: 'Μετακίνηση',
    merge: 'Συγχώνευση',
    color: 'Χρώμα',
    numbersRange: {
      min: 'Ελάχ.',
      max: 'Μέγ.',
    },
    day: 'ημέρα',
    operationDates: 'Ημερομηνίες λειτουργίας',
    trigger: 'Ενεργοποίηση',
    languages: 'Γλώσσες',
    location: 'Τοποθεσία',
    created: 'Δημιουργήθηκε',
    updated: 'Ενημερώθηκε',
    value: 'Τιμή',
    totalAmount: 'Συνολικό ποσό',
    single: 'Μονό',
    bulk: 'Μαζικό',
    managePermissions: 'Διαχείριση αδειών',
    depositId: 'Αναγνωριστικό κατάθεσης',
    pageCantBeDisplayed: 'Δεν είναι δυνατή η εμφάνιση της σελίδας',
    youDontHavePermission: 'Η διεύθυνση URL της σελίδας δεν είναι έγκυρη ή δεν έχετε άδεια να την προβάλετε.',
    lCategory: 'κατηγορία',
    thePlayer: 'Ο παίκτης',
    accounts: 'Λογαριασμοί',
    password: 'Κωδικός πρόσβασης',
    templateName: 'Όνομα προτύπου',
    allPlayers: 'Όλοι οι παίκτες',
    segmentName: 'Όνομα τμήματος',
    attach: 'Επισύναψη',
    schedule: 'Προγραμματισμός',
    body: 'Σώμα κειμένου',
    templateId: 'Αναγνωριστικό προτύπου',
    confirm: 'Επιβεβαίωση',
    accountInfo: 'Πληροφορίες λογαριασμού',
    company: 'Εταιρεία',
    selectCashbackType: 'Επιλογή τύπου επιστροφής μετρητών',
    misConfigured: 'Εσφαλμένη διαμόρφωση',
    attentionMarked: 'Επισημάνθηκε προσοχή',
    paymentProvider: 'Πάροχος πληρωμής',
    submissionDate: 'Ημερομηνία υποβολής',
    ngr: 'NGR',
    icon: 'Εικονίδιο',
    betId: 'Αναγνωριστικό στοιχήματος',
    paymentRequestId: 'Αναγνωριστικό αιτήματος πληρωμής',
    roundIdCouponNumber: 'Αναγνωριστικό γύρου/Αριθμός κουπονιού',
    dateDuration: '{{hour}} {{minute}} {{second}}',
    noCardSelected: 'Δεν έχει επιλεγεί κάρτα',
    backToList: 'Επιστροφή στη λίστα',
    time: {
      hour: 'ώ.',
      minute: 'λ.',
      second: 'δ.',
    },
    duration: 'Διάρκεια',
    browserId: 'Αναγνωριστικό προγράμματος περιήγησης',
    preview: 'Προεπισκόπηση',
    sportBetsReport: 'Αναφορά αθλητικών στοιχημάτων',
    lMinutes: 'λεπτά',
    pageNotFound: 'Η σελίδα δεν βρέθηκε',
    title: 'Τίτλος',
    zipCode: 'Ταχυδρομικός κώδικας',
    url: 'URL',
    rate: 'Ισοτιμία',
    conversionRates: 'Συναλλαγματικές ισοτιμίες',
    conversionOrConversions: 'Μετατροπή(-ές)',
    gameManagement: 'Διαχείριση παιχνιδιών',
    date: 'Ημερομηνία',
    restricted: 'Υπό περιορισμό',
    maintenanceState: 'Κατάσταση συντήρησης',
    underMaintenance: 'Υπό συντήρηση',
    noMaintenance: 'Δεν γίνεται συντήρηση',
    gameSystemState: 'Κατάσταση συστήματος παιχνιδιού',
    pages: 'Σελίδες',
    groupName: 'Όνομα ομάδας',
    betMinusWin: 'Στοίχημα - Κέρδος',
    today: 'Σήμερα',
    lastWeek: 'Προηγούμενη εβδομάδα',
    lastMonth: 'Προηγούμενος μήνας',
    yesterday: 'Χθες',
    messages: 'Μηνύματα',
    providers: 'Πάροχοι',
    unVerify: 'Αναίρεση επαλήθευσης',
    deviceType: 'Τύπος συσκευής',
    totalWithdrawals: 'Συνολικές αναλήψεις',
    totalDeposits: 'Συνολικές καταθέσεις',
    viewAll: 'Προβολή όλων',
    thisMonth: 'Τρέχων μήνας',
    thisWeek: 'Τρέχουσα εβδομάδα',
    typeHere: 'Πληκτρολογήστε εδώ',
    reasonsSystem: 'Σύστημα αιτιών',
    campaigns: 'Καμπάνιες',
    noResults: 'Κανένα αποτέλεσμα',
    shared: 'Κοινοποιήθηκε',
    tagCategories: 'Κατηγορίες ετικετών',
    reasons: 'Αιτίες',
    verticalTags: 'Κάθετες ετικέτες',
    horizontalTags: 'Οριζόντιες ετικέτες',
    addNew: 'Προσθήκη νέου',
    taxes: 'Φόροι',
    widgets: 'Γραφικά στοιχεία',
    lMobileNumber: 'αριθμός κινητού τηλεφώνου',
    infoUrl: 'URL πληροφοριών',
    previousAccounts: 'Προηγούμενοι λογαριασμοί',
    agSentData: 'Απεσταλμένα δεδομένα AG',
    developmentTools: 'Εργαλεία προγραμματισμού',
    keys: 'Κλειδιά',
    copy: 'Αντιγραφή',
    lobbyManagement: 'Διαχείριση λόμπι',
    widget: 'Γραφικό στοιχείο',
    expirationPeriod: 'Περίοδος λήξης',
    spinsCount: '‏Αριθμός περιστροφών',
    lNow: 'τώρα',
    selected: 'Επιλέχθηκε',
    playerName: 'Ονομα παίκτη',
    paymentId: 'Αναγνωριστικό πληρωμής',
    maxAmount: 'Μέγ. ποσό',
    totalBonuses: 'Συνολικά μπόνους',
    totalCount: 'Συνολικός αριθμός',
    generateReport: 'Δημιουργία αναφοράς',
    generateReportNote: 'Μετά τη δημιουργία του αντιγράφου κινήσεων, θα λάβετε μια υπενθύμιση μέσω email. Το αντίγραφο κινήσεων θα αποθηκευτεί για 7 ημέρες στο <0>Κέντρο εξαγωγών</0>.',
    exportCenter: 'Κέντρο εξαγωγών',
    validTill: 'Έγκυρο μέχρι',
    remove: 'Αφαίρεση',
    ok: 'OK',
    aggregator: 'Σωρευτής',
    providerId: 'Αναγνωριστικό παρόχου',
    systemCategory: 'Κατηγορία συστήματος',
    demoVersion: 'Δοκιμαστική έκδοση',
    gameState: 'Κατάσταση παιχνιδιού',
    gameBrandState: 'Κατάσταση επωνυμίας παιχνιδιού',
    gameGlobalState: 'Καθολική κατάσταση παιχνιδιού',
    providerList: 'Λίστα παρόχων',
    gameList: 'Λίστα παιχνιδιών',
    oldPassword: 'Παλιός κωδικός πρόσβασης',
    newPassword: 'Νέος κωδικός πρόσβασης',
    confirmPassword: 'Επιβεβαίωση κωδικού πρόσβασης',
    changePassword: 'Αλλαγή κωδικού πρόσβασης',
    pushNotifications: 'Ειδοποιήσεις push',
    total: 'Σύνολο',
    more: 'Περισσότερα',
    notification: 'Ειδοποίηση',
    campaignName: 'Όνομα καμπάνιας',
    bonusFamily: 'Οικογένεια μπόνους',
    bonusAmount: 'Ποσό μπόνους',
    roundId: 'Αναγνωριστικό γύρου',
    currentValue: 'Τρέχουσα τιμή',
    sportStatus: 'Κατάσταση αθλητικών',
    crm: 'CRM',
    sms: 'SMS',
    expirationDate: 'Ημερομηνία λήξης',
    transferTo: 'Μεταφορά σε',
    manageMaintenance: 'Διαχείριση συντήρησης',
    attribute: 'Χαρακτηριστικό',
    priority: 'Προτεραιότητα',
    widgetName: 'Όνομα γραφικού στοιχείου',
    sessions: 'Συνεδρίες',
    playerDeletedReason: 'Ο λογαριασμός παίκτη έκλεισε',
    playersTimeOutReason: 'Ο παίκτης βρίσκεται σε προσωρινή διακοπή',
    playersSelfExcludedReason: 'Ο παίκτης έχει αυτοαποκλειστεί',
    playersBlockedReason: 'Ο παίκτης βρίσκεται σε κατάσταση περιορισμένου λογαριασμού',
    conditions: 'Προϋποθέσεις',
    gameType: 'Τύπος παιχνιδιού',
    translationKey: 'Κλειδί μετάφρασης',
    bTag: 'BTag',
    lWidget: 'γραφικό στοιχείο',
    createWidget: 'Δημιουργία γραφικού στοιχείου',
  },
};
