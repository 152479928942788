import styled from 'styled-components';
import { Form } from 'antd';
import { media } from 'utils/styledHelpers';

const StyledFormMainWrapper = styled(Form)`
  height: 100%;
  max-width: 100%;
  .ant-form-item-label > label.ant-form-item-required::before,
  .ant-form-item-label > label::after {
    display: none;
  }

  &.ant-form {
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.colors.primaryText};
  }

  &.ant-form-item-required {
    .hint-label p:not(:empty) {
      &:after {
        content: '*';
      }
    }
  }
  &.ant-form label {
    color: ${(props) => props.theme.colors.primaryText};
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
  }
  &.ant-form {
    .ant-mentions,
    textarea.ant-input {
      margin-bottom: 0;
    }
    .ant-input[disabled],
    .ant-select-selection[disabled],
    .ant-select-disabled .ant-select-selection {
      border-color: ${(props) => props.theme.colors.primaryBorder};
      background-color: ${(props) => props.theme.colors.grayButton};
      color: ${(props) => props.theme.colors.filterLabelColor};
      opacity: 1;
    }
    .ant-select-disabled .ant-select-selection-item {
      color: ${(props) => props.theme.colors.filterLabelColor};
    }
  }
  &.ant-form {
    .ant-input[disabled]:hover,
    .ant-select-selection[disabled]:hover,
    .ant-select-disabled:hover .ant-select-selection,
    .ant-select-disabled .ant-select-selection:hover,
    .ant-form-item-control:hover .ant-select-disabled .ant-select-selection {
      border-color: ${(props) => props.theme.colors.primaryBorder};
      background-color: ${(props) => props.theme.colors.grayButton};
      color: ${(props) => props.theme.colors.primaryText};
    }
  }
  .ant-picker-panel-container,
  .ant-picker-range-arrow::after {
    background: ${(props) => props.theme.colors.tableTrEvenColor};
  }
  ${media.sm`
    height: auto
  `}
`;
const StyledTopWrapper = styled.div`
  margin-top: 0.4rem;
`;
const IPsFormsWrapper = styled(Form)`
  &.ant-form {
    display: flex;
    align-items: flex-end;
    > div:first-of-type {
      width: 15.4rem;
    }
    > div:last-of-type {
      width: 9.9rem;
    }
    > p {
      margin: 0 1.1rem 0.8rem;
      height: 1.14rem;
    }
  }
`;
const StyledFormCheckBoxWrapper = styled.div`
  margin-top: ${(props) => (props.isMarginTop ? '2.28rem' : '0')};
  .ant-form-item {
    .ant-form-item-label {
      font-weight: ${(props) => props.theme.typography.fontWeightMedium};
      font-size: ${(props) => props.theme.typography.fontSizeDefault};
      margin-bottom: 1.14rem;
      font-weight: ${(props) => (props.isBoldLabel ? props.theme.typography.fontWeightRegular : props.theme.typography.fontWeightMedium)};
      padding: 0;
    }
    .ant-form-item-label > label::after {
      display: none;
    }
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 1.71rem;
    label {
      width: auto;
      span {
        font-size: ${(props) => props.theme.typography.fontSizeDefault};
    }
  }
  .ant-radio-wrapper {
    span {
      font-size: ${(props) => props.theme.typography.fontSizeDefault};
    }
  }
`;

const StyledFormRadioGroupWrapper = styled.div`
  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 1.71rem 0.71rem;

    .ant-radio-wrapper {
      margin-right: 0;
    }
  }
  .ant-form-item {
    .ant-form-item-label {
      font-weight: ${(props) => props.theme.typography.fontWeightMedium};
      font-size: ${(props) => props.theme.typography.fontSizeDefault};
      margin-bottom: 1.14rem;
      font-weight: ${(props) => (props.isBoldLabel ? props.theme.typography.fontWeightRegular : props.theme.typography.fontWeightMedium)};
      padding: 0;
    }
  }
`;

const StyledCheckboxesWithoutGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.direction === 'vertical' ? 'column' : 'row')};
  gap: 1.28rem 1.71rem;

  .ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 1.28rem 1.71rem;
  }
  strong {
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    flex-basis: 100%;
    margin-bottom: -0.2rem;
    color: ${(props) => props.theme.colors.primaryText};
  }
  label {
    width: auto;

    span {
      font-size: ${(props) => props.theme.typography.fontSizeDefault};
    }
  }
`;

const StyledDOBFieldsWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  > .ant-form-item {
    flex: 0 0 32%;
    max-width: 32%;

    &:first-child {
      flex: 0 0 36%;
      max-width: 36%;

      .ant-select.ant-select-single {
        .ant-select-selector {
          border-top-left-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
          border-bottom-left-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
          border-right: none;
        }
      }
    }

    &:last-child {
      .ant-select.ant-select-single {
        .ant-select-selector {
          border-top-right-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
          border-bottom-right-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
          border-left: none;
          border-right-width: 1px;
        }
      }
    }

    .ant-select.ant-select-single {
      .ant-select-selector {
        padding: 0 0.71rem;
        border-radius: 0;
        border-right-width: 0;
        .ant-select-selection-placeholder,
        .ant-select-selection-item {
          padding-right: 0.57rem;
        }

        .ant-select-selection-search {
          left: 0.71rem;
          width: calc(100% - 3rem);
        }
      }
    }
  }
`;

export {
  StyledFormMainWrapper, StyledTopWrapper, IPsFormsWrapper, StyledFormCheckBoxWrapper, StyledFormRadioGroupWrapper, StyledCheckboxesWithoutGroup, StyledDOBFieldsWrapper,
};
