export default {
  en: {
    promotionTool: 'Gamification',
    promotions: 'Gamification',
    promotion: 'Gamification',
    slPromotion: 'gamification',
    freeActivationDesc: 'Allows to provide free activations for the specified period of time',
    spinDistribution: 'Spin Distribution',
    spinDistributions: 'Spin Distributions',
    lSpinDistribution: 'spin distribution',
    promoParticipantTypeDesc: {
      1: 'Gamification will be visible for all players, including guests',
      2: 'Gamification will be visible only for logged-in players',
    },
    promotionFund: 'Gamification Fund',
    winningsTable: 'Winnings Table',
    winningTableDesc: 'Here we can control enabling or disabling Winnings Table on the Gamification Page.',
    myWinningsDesc: 'Here we can control enabling or disabling My Winnings Table on the Gamification Page.',
    promotionFunds: 'Gamification Funds',
    countOfSpins: 'Count Of Spins',
    period: 'Period',
    spinProvisionPeriodInfo: 'Allows defining the period when the machine spins will be provided. The period will be calculated after spending/spinning the machine spin',
    spinExpirationPeriod: 'Allows defining the period when the machine spins will be expired',
    viewingOptions: 'Viewing Options',
    emptySpinDistributionDesc: 'You can see the Spin Distributions after adding and configuring them from the right-hand navigation menu.',
    machine: 'Machine',
    lMachine: 'machine',
    numberOfSections: 'Number Of Sections',
    numberOfSectionsInfo: 'The Quantity of Machine Sections where the prizes will be connected.',
    numberOfSection: 'Number Of Section',
    sectionName: 'Section Name',
    machines: 'Machines',
    emptyMachinesSectionDesc: 'You can see the Machines after adding and configuring them from the right-hand navigation menu.',
    playingParticipants: 'Playing Participants',
    emptyPlayingParticipantsDesc: 'You can see the Participation Conditions after adding and configuring them from the right-hand navigation menu.',
    allPlayersDesc: 'Allows to include all players as a participant when the trigger is executed',
    conditionDesc: 'Allows to include players by trigger specific conditions',
    lPlayingParticipant: 'playing participant',
    machineConfig: 'Machine Config',
    machineConfigDesc: 'Here we can choose different machines and configure them',
    machineSidebarInfo: 'Here we can choose the machine and add it',
    machineConfigMachinesInfo: 'Select the machine for which the configuration will start',
    machineConfigSpinDistributionsInfo: 'Select the created spin distribution rules for the machine, based on which the players will get the machine spins',
    machineConfigPlayingParticipantsInfo: 'Select the created playing condition, which will check the players who can use/activate the machine',
    spinDistributionSidebarInfo: 'Here we can choose spin distribution rules',
    prize: 'Prize',
    lPrize: 'prize',
    campaignId: 'Campaign Id',
    totalWinCount: 'Total Win Count',
    totalWinCountInfo: 'Total Quantity of Prize that can be won during the active period of gamification',
    dailyMaxWinCount: 'Daily Max Win Count',
    dailyTotalMaxWinCount: 'Daily Total Max Win Count',
    dailyMaxWinCountInfo: 'Daily Total Quantity of Prize that can be won by all players during the day',
    playerMaxWinCount: 'Player Max Win Count',
    playerMaxWinCountInfo: 'Total Quantity of Prize that can be won by one player during the gamification',
    prizeType: 'Prize Type',
    prizeTypeBonusInfo: 'Bonus Type Prizes can be created by selecting the campaign and configuring its bonuses',
    bonusCampaignInfo: 'Select the cross platform campaign to get the bonuses for the prize',
    prizeAndProbabilitySection: 'Prize Section and Probability',
    prizeAndProbabilitySectionInfo: 'Select the machine to start configuring the machine sections and probabilities.',
    probabilityPercent: 'Probability %',
    defaultPrize: 'Default Prize',
    setDefaultPrize: 'Set Default Prize',
    advancedProbability: 'Advanced Probability',
    advancedProbabilityInfo: 'The section will be enabled if the machine sections are defined with prizes and probabilities.',
    machineConfigs: 'Machine Configs',
    lMachineConfig: 'machine config',
    emptyMachineConfigsDesc: 'You can see the configured Machines after adding and configuring them from the right-hand navigation menu.',
    lGroupLimit: 'group limit',
    groupLimit: 'Group Limit',
    emptyGroupLimitDesc: 'You can see the Limits after adding and configuring them from the right-hand navigation menu.',
    groupLimitDesc: 'Here we can choose the groups and provide limitations',
    groupLimitGeneralInfo:
      'The limitations can be defined by configuring and connecting prizes with groups. The advanced probability section will be reset, if the prize is added or deleted from the standard probability section.',
    groupLimitAdvancedTagsInfo: 'In this area, we define the limitation for the selected group',
    groupLimitAdvancedInfo: 'The limitations per tags can be defined and if the player has one of the selected tags, the tag limitation will be considered.',
    groupLimitAdvancedPriorityInfo:
      'Each selected Vertical tag should have its own priority. The highest priority starts from 1. If the player has multiple tags, which are defined in limits, the highest priority tag should be considered.',
    prizes: 'Prizes',
    groupType: 'Group Type',
    advanced: 'Advanced',
    standard: 'Standard',
    lPromotion: 'gamification',
    emptyPrizesDesc: 'You can see the Prizes after adding and configuring them from the right-hand navigation menu.',
    prizesSidebarInfo: 'Here we can choose different prizes and add them',
    playingParticipantsSidebarInfo: 'Here we can choose participants’ selection conditions.',
    verifyConditionDesc: 'Checks players verification level',
    content: 'Content',
    contentMachineInfo: 'Select the machine to configure the content for it.',
    unAuthorizedSubtitle: 'Unauthorized Subtitle',
    authorizedSubtitle: 'Authorized Subtitle',
    websiteMachineBackground: 'Website Machine Background',
    mobileMachineBackground: 'Mobile Machine Background',
    authorizedSubtitleInfo: 'Define the subtitle which will be shown to authorizes players',
    unAuthorizedSubtitleInfo: 'Define the subtitle which will be shown to unauthorized players',
    sectionSubtitle: 'Section Subtitle',
    contentSectionInfo: 'In this area, we define the content for the machine sections. The section content can be defined per currency as well.',
    contentSelectSectionsInfo: 'Select machine to get sections',
    contentPrizeInfo: 'In this area, we define the content and translations for the prizes which will be visible on player side.',
    termsAndConditions: 'Terms and Conditions',
    contentTermsAndConditionsInfo: 'In this area, we define the terms and conditions for participating in gamification.',
    contentSidebarInfo: 'Here we can define the content and icons of the gamification, machines and spin distributions',
    sectionContent: 'Section Content',
    sectionTitle: 'Section Title',
    probabilities: 'Probabilities',
    isUsedInMachineConfig: "Unable to delete because it's used in machine config",
    promotionName: 'Gamification',
    emptyContentDesc: 'You can see the Machine Contents after adding and configuring them from the right-hand navigation menu.',
    previewName: 'Preview Name',
    navigationItemsDisabledInfo: 'The navigation items will become active after saving the Gamification General section.',
    connectPrizesAndDefineProbabilities:
      'In this area, we connect prizes to machine sections and define probabilities for it. The maximum count of the prize can be defined which can be won during the day, based on the set probabilities. Default prize should be selected, which will be unlimited prize for the machine and will be provided if one of the prize limitation is met.',
    advancedLimits: 'Advanced limits',
    choosePrize: 'Choose Prize',
    wheelSectionsAndProbabilities: 'Wheel Sections and Probabilities',
    configureTagsAndProbabilities:
      "In this area, we configure the Vertical tags and probabilities per prize. Tags' with higher priority should be considered in the first place in case the player has multiple tags. Default prize should be selected for each tag, which will be provided if one of the prize limitation is met.",
    defaultLanguageIs: 'Brand’s default language is {{language}}',
    bonusConfigurations: 'Bonus Configurations',
    setLimitationsForSelectedGroup: 'In this area, we define the limitation for the selected group. Each prize can be connected to one group.',
    noPrizesForSection: 'Add prize in Prizes section, which can be connected to group and define the limitation',
    main: 'Main',
    spinDistributionPeriodInfo: 'Time period in hours, after which the spins will be distributed',
    countOfSpinsInfo: 'The quantity of spins which should be distributed to players',
    expirationPeriodInfo: 'Time period in hours, after which the provided spins will be expired and cannot be used',
    tryAgainInfo: 'No Win',
    machineName: 'Machine Name',
    machineType: 'Machine Type',
    machineTypes: 'Machine Types',
    prizeName: 'Prize Name',
    prizeTypes: 'Prize Types',
    spinDate: 'Spin Date',
    totalUsage: 'Total Usage',
    itemName: 'Item Name',
    totalDistributedSpins: 'Total Distributed Spins',
    totalUsedSpins: 'Total Used Spins',
    remainingSpins: 'Remaining Spins',
    lastSpinDate: 'Last Spin Date',
    limitPerPlayer: 'Limit Per Player',
    manualSpinsPlayers: 'Manual Spins Players',
    provisionType: 'Provision Type',
    spins: 'Spins',
    promotionUrl: 'Gamification URL',
    promotionUrlHint: 'Here we can define the Gamification URL which will be used to navigate to the Gamification page. The /promo/URL relative path will be used in Builder',
    websiteBackground: 'Website Background',
    mobileBackground: 'Mobile Background',
    pageBackground: 'Page Background',
    webSiteFit: 'Website Fit Type',
    mobileFit: 'Mobile Fit Type',
    machineContents: 'Machine Contents',
    promotionContents: 'Gamification Contents',
    emptyPromotionContentDesc: 'You can see the Gamification Contents after adding and configuring them from the right-hand navigation menu.',
    promotionTransactions: 'Gamification Transactions',
    promotionPrizes: 'Gamification Prizes',
    promotionMachines: 'Gamification Machines',
    promotionPlayers: 'Gamification Players',
    spinDepositDesc: 'Allows to provide activations from deposits made by player',
    depositDistributionNumberOfDesc: 'User can choose for how many deposits the players will get the machine activations',
    depositDistributionEveryDesc: 'This field will allow setting the maximum successful trigger count per player',
    periodEveryInfo: 'Allows to define the period for which the deposits should be considered',
    periodEveryMaxTriggerCountInfo: 'This field will allow setting the maximum successful trigger count per player',
    depositExpirationPeriodInfo: 'Allows defining the period when the machine spins will be expired',
    minimumAmount: 'Minimum Amount',
    numberOfDepositsInfo: 'Select the deposits that players must receive machine spins for in the specified period',
    maxIssuanceCount: 'Maximum Issuance Count',
    amountType: 'Amount Type',
    distributionOption: 'Distribution Option',
    distributionType: 'Distribution Type',
    sidebarConfigDependenciesInfo: 'The Group Limit, Machine Config, and Content sections will become active after completing Spin Distribution, Playing Participants, Prize, and Machine sections.',
    sidebarMissingMainLanguageContentInfo: "Gamification, Machine Contents and Spin Distribution Contents should be provided for the brand's default language.",
    privateModeParticipantsConfigInfo: 'The provided list can be affected after selecting the gamification currencies.',
    playersAbleToSee: 'Players are able to see Gamification page',
    noPromotionsAvailableForPlayer: 'There are no gamification available for the player',
    heightAndWidth: 'Height and Width',
    heightAndWidthInfo: 'Select the Height and Width to create a machine with the specific dimensions',
    dimensions: 'Dimensions',
    gridMachineInfo: 'Here you can define Grid machine cell names for future prize connections',
    gridSectionsAndProbabilities: 'Grid Sections and Probabilities',
    prizeTypeMaterialInfo: 'Material Type Prizes can be created by configuring their name and the equivalent amount',
    prizeItemConfigurations: 'Prize Item Configurations',
    equivalentAmount: 'Equivalent Amount',
    wonDate: 'Won Date',
    item: 'Item',
    daysInfo: 'Allows defining the days for which the player will receive the spins',
    resetProgress: 'Reset Progress',
    resetProgressInfo: "When enabled, player progress resets if consecutive days' spins go uncollected/claimed",
    spinCollectDesc: "Allows to provide activations from player's collect action.",
    joiningOptions: 'Joining Options',
    onJoinInfo: 'Gamification will require joining in order to participate',
    maxJoinedPlayers: 'Max Joined Players',
    maxJoinedPlayersInfo: 'Here we can set the limit of players who can join the gamification.',
    countDown: 'Countdown',
    countDownInfo: 'Here, we can enable the countdown, which will show the remaining time until the Gamification starts.',
    joinStartDate: 'Join Start Date',
    joinEndDate: 'Join End Date',
    operationStartDate: 'Operation Start Date',
    operationEndDate: 'Operation End Date',
    joinDateInfo: 'Here we can set the date when the players can join the gamification.',
    spinBetDesc: 'Players will get free activations for all bets in the trigger period\n',
    subTitle: 'Subtitle',
    spinDistributionContents: 'Spin Distribution Contents',
    emptySpinDistributionContentDesc: 'You can see the Spin Distribution Contents after adding and configuring them from the right-hand navigation menu.',
    totalDistributedCount: 'Total Distributed Count',
    receivedSpins: 'Received Spins',
    spinStatistics: 'Spin Statistics',
    buyActivationInfo:
      'The amount per one activation should be defined for all currencies. The players can purchase the activation based on quantity which will be calculated by multiplying the count and the amount.',
    discountPercent: 'Discount, %',
    walletToBuy: 'Wallet to Buy',
    buyActivationCountOfSpinsInfo: "The number in the 'From' field of the 'Count Of Spins' is included in that range, whereas the number in the 'To' field is not included in the range.",
    discount: 'Descuento',
    onDiscount: 'On Discount',
    buyActivationDesc: 'Allows players to buy the activations.',
    walletToBuyActivationInfo: 'Here we can define from which wallet the player can purchase the activation.',
    gamificationSpinStatistics: 'Gamification Spin Statistics',
    machineInfo: 'Arrange machines with a drag-and-drop interface. The first machine becomes the lead item on the website, followed by others in your chosen sequence.',
    websiteBackgroundPosition: 'Website Background Position type',
    mobileBackgroundPosition: 'Mobile Background Position type',
    activationOnDepositNumberOfDesc: 'User can choose for how many deposits in the trigger period players to get spins',
    activationOnDepositEveryDesc: 'Players will get spins for all deposits in the trigger period',
    activationOnDepositCumulativeDesc: 'Players will get spins for total deposits made in a specific time period',
    spinDistributionCumulativeInfo:
      "The sum of the player's deposit amounts should be compared against the defined amounts. By satisfying one or multiple amounts the defined spins will be provided to player.",
    spinDistributionCumulativeCheckPointsInfo: 'Players earn spins based on total deposits amount made within a specific period or by reaching set checkpoints.',
    points: 'Points',
    addPoints: 'Add Points',
    viewPoints: 'View Points',
    resetPoints: 'Reset Points',
    spinDistributionPaymentMethodsInfo: 'Allows to set deposit payment methods and sub methods. If payment method is not set, All Payments methods will be considered.',
    exchangeCenter: 'Exchange Center',
    exchangeCenterDesc: 'Here we can define the exchange rates among the machines',
    exchangeItemInfo: 'Define a machine coefficient to enable an exchange of spins between different machines',
    coefficient: 'Coefficient',
    exchange: 'Exchange',
    lExchange: 'exchange',
    emptyExchangeCenterDesc: 'You can see the Exchange after adding and configuring them from the right-hand navigation menu.',
    gamificationSpinsReport: 'Gamification Spins Report',
    prizeTypeMachineSpinInfo: 'Machine Spin Type Prizes can be created by configuring their name and providing the count of spins. A machine Spin prize will be provided for the connected machine.',
    machineSpinStatistics: 'Machine Spin Statistics',
    prizeTypeMachineSpinCountInfo: 'Allows defining the spins count which should be provided',
    autoSpinAndTurboSpin: 'Autospin/Turbospin',
    autoSpinAndTurboSpinDesc: 'Here we can control enabling or disabling Autospin/Turbospin option by which players can use their Spin and activate the machines in these modes.',
    isUsedInExchangeCenter: "Unable to delete because it's used in exchange center",
    materialStatistics: 'Material Statistics',
    treasury: 'Treasury',
    treasuryDesc: 'Here we can control enabling or disabling the Treasury section on the Gamification Page.',
    prizeContents: 'Prize Contents',
    prizeContentsDesc: 'You can see the Prize Contents after adding and configuring them from the right-hand navigation menu.',
    prizeImageRequired: 'Prize image is required',
    minMultiplierAmount: 'Min Multiplier Amount',
    maxMultiplierAmount: 'Max Multiplier Amount',
    walletToMultiply: 'Wallet to Multiply',
    multiplier: 'Multiplier',
    prizeTypeBalanceMultiplierInfo: 'Balance Multiplier Type Prizes can be created by providing the amount and defining the wallet, where it will be transferred.',
    balanceMultiplierTransferToInfo: 'The multiplied amount will be transferred to the selected wallet.',
    balanceMultiplierMinMultipliedAmountInfo: 'Min Multiplied Amount will be provided to the player when the player balance is 0 or multiplied amount is less then Min Multiplied Amount.',
    balanceMultiplierMaxMultipliedAmountInfo: 'Max Multiplied Amount will be provided to the player when the multiplied amount exceeds it.',
    multipliedWallet: 'Multiplied Wallet',
    originalAmount: 'Original Amount',
    transferredAmount: 'Transferred Amount',
    transferredToWallet: 'Transferred to Wallet',
    gamificationExchangesReport: 'Gamification Exchanges Report',
    exchangedDate: 'Exchanged Date',
    received: 'Received',
    exchanged: 'Exchanged',
    spinsToActivate: 'Spins To Activate',
    spinsToActivateInfo: 'Number of activations which should be collected to activate the Box',
    boxSectionsAndProbabilities: 'Box Sections and Probabilities',
    before: 'Before',
    after: 'After',
    noChangesAreAvailable: {
      text1: 'Whoops!',
      text2: 'No changes are available',
    },
    addedDate: 'Added Date',
    joinedDate: 'Joined Date',
    hasTransaction: 'Has Transaction',
    expandable: 'Expandable',
    exchangeable: 'Exchangeable',
    exchangeableInfo: "When enabled, the player can choose between a material prize or its equivalent amount, which will be transferred to the player's wallet.",
  },
  es: {
    freeActivationDesc: 'Permite ofrecer activaciones gratuitas durante el periodo de tiempo especificado',
    spinDistribution: 'Distribución Spin',
    spinDistributions: 'Distribución Spin',
    lSpinDistribution: 'Distribución Spin',
    winningsTable: 'Tabla de Ganancias',
    countOfSpins: 'Recuento de Spins',
    period: 'Periodo',
    spinProvisionPeriodInfo: 'Permite definir el periodo en el que se proporcionarán los giros de máquina. El periodo se calculará después de gastar/hacer girar la máquina',
    spinExpirationPeriod: 'Permite definir el periodo en el que caducarán las tiradas de la máquina',
    viewingOptions: 'Opciones de Visualización',
    emptySpinDistributionDesc: 'Puede ver las Distribuciones Spin después de añadirlas y configurarlas en el menú de navegación de la derecha.',
    machine: 'Máquina',
    lMachine: 'Máquina',
    numberOfSections: 'Número de Secciones',
    numberOfSectionsInfo: 'Cantidad de Secciones de Máquina donde se conectarán los premios.',
    numberOfSection: 'Número de Sección',
    sectionName: 'Nombre de la Sección',
    machines: 'Máquinas',
    emptyMachinesSectionDesc: 'Puede ver las máquinas después de añadirlas y configurarlas en el menú de navegación de la derecha.',
    playingParticipants: 'Participantes en el Juego',
    emptyPlayingParticipantsDesc: 'Puede ver las Condiciones de participación después de añadirlas y configurarlas en el menú de navegación de la derecha.',
    allPlayersDesc: 'Permite incluir a todos los jugadores como participantes cuando se ejecuta el activador',
    conditionDesc: 'Permite incluir jugadores por condiciones específicas de activación',
    lPlayingParticipant: 'Participantes en el Juego',
    machineConfig: 'Configuración de la máquina',
    machineConfigMachinesInfo: 'Seleccione la máquina para la que se iniciará la configuración',
    machineConfigSpinDistributionsInfo: 'Seleccione las reglas de distribución de tiradas creadas para la máquina, en función de las cuales los jugadores obtendrán las tiradas de la máquina.',
    machineConfigPlayingParticipantsInfo: 'Seleccione la condición de juego creada, que comprobará los jugadores que pueden utilizar/activar la máquina',
    spinDistributionSidebarInfo: 'Aquí podemos elegir reglas de distribución de spin',
    prize: 'Premio',
    lPrize: 'premio',
    campaignId: 'Id de Campaña',
    totalWinCount: 'Recuento Total de Victorias',
    dailyMaxWinCount: 'Número Máximo de Ganancias Diarias',
    dailyTotalMaxWinCount: 'Recuento de Victorias Máximas Totales Diarias',
    dailyMaxWinCountInfo: 'Cantidad Total Diaria de Premio que pueden ganar todos los jugadores durante el día',
    playerMaxWinCount: 'Número Máximo de Ganancias del Jugador',
    prizeType: 'Tipo de Premio',
    prizeTypeBonusInfo: 'Los Tipos de Premios de bonificación se pueden crear seleccionando la campaña y configurando sus bonificaciones',
    bonusCampaignInfo: 'Selecciona la campaña multiplataforma para obtener las bonificaciones del premio',
    prizeAndProbabilitySection: 'Sección de Premios y Probabilidad',
    prizeAndProbabilitySectionInfo: 'Seleccione la máquina para empezar a configurar las secciones de la máquina y las probabilidades.',
    probabilityPercent: 'Probabilidad %',
    defaultPrize: 'Premio por Defecto',
    setDefaultPrize: 'Fijar Premio por Defecto',
    advancedProbability: 'Probabilidad Avanzada',
    advancedProbabilityInfo: 'La sección se habilitará si las secciones de la máquina están definidas con premios y probabilidades.',
    machineConfigs: 'Configuración de la Máquina',
    lMachineConfig: 'configuración de la máquina',
    emptyMachineConfigsDesc: 'Puede ver las Máquinas configuradas después de añadirlas y configurarlas desde el menú de navegación de la derecha.',
    lGroupLimit: 'límite de grupo',
    groupLimit: 'Límite de Grupo',
    emptyGroupLimitDesc: 'Puede ver los Límites después de añadirlos y configurarlos en el menú de navegación de la derecha.',
    groupLimitDesc: 'Aquí podemos elegir los grupos y establecer limitaciones',
    groupLimitGeneralInfo:
      'Las limitaciones pueden definirse configurando y conectando premios con grupos. La sección de probabilidad avanzada se restablecerá si el premio se añade o elimina de la sección de probabilidad estándar.',
    groupLimitAdvancedTagsInfo: 'En esta área, definimos la limitación para el grupo seleccionado',
    groupLimitAdvancedInfo: 'Se pueden definir las limitaciones por etiquetas y si el jugador tiene una de las etiquetas seleccionadas, se tendrá en cuenta la limitación de la etiqueta.',
    groupLimitAdvancedPriorityInfo:
      'Cada etiqueta vertical seleccionada debe tener su propia prioridad. La prioridad más alta comienza a partir de 1. Si el jugador tiene varias etiquetas, que se definen en los límites, se debe considerar la etiqueta de mayor prioridad.',
    prizes: 'Premio',
    groupType: 'Tipo de Grupo',
    advanced: 'Avanzado',
    standard: 'Estándar',
    emptyPrizesDesc: 'Puede ver los Premios después de añadirlos y configurarlos desde el menú de navegación de la derecha.',
    verifyConditionDesc: 'Comprueba el nivel de verificación de los jugadores',
    content: 'Contenido',
    contentMachineInfo: 'Seleccione la máquina para configurar el contenido para ella.',
    unAuthorizedSubtitle: 'Subtítulo No Autorizado',
    authorizedSubtitle: 'Subtítulo Autorizado',
    websiteMachineBackground: 'Antecedentes de la Máquina del Sitio Web',
    mobileMachineBackground: 'Antecedentes de la Máquina del Móvil',
    authorizedSubtitleInfo: 'Definir el subtítulo que se mostrará a los jugadores autorizados',
    unAuthorizedSubtitleInfo: 'Definir el subtítulo que se mostrará a los reproductores no autorizados',
    sectionSubtitle: 'Sección Subtítulo',
    contentSectionInfo: 'En esta área se define el contenido de las secciones de la máquina. El contenido de las secciones también se puede definir por moneda.',
    contentSelectSectionsInfo: 'Seleccione la máquina para obtener secciones',
    contentPrizeInfo: 'En esta área, definimos el contenido y las traducciones para los premios que serán visibles en el lado del jugador.',
    termsAndConditions: 'Términos y Condiciones',
    sectionContent: 'Contenido de las Secciones',
    sectionTitle: 'Título de las Secciones',
    probabilities: 'Probabilidades',
    isUsedInMachineConfig: 'No se puede eliminar porque se utiliza en la configuración de la máquina',
    emptyContentDesc: 'Puede ver los Contenidos después de añadirlos y configurarlos desde el menú de navegación de la derecha.',
    previewName: 'Vista Previa Nombre',
    connectPrizesAndDefineProbabilities:
      'En esta área, conectamos los premios a las secciones de la máquina y definimos probabilidades para ello. Se puede definir el '
      + 'número máximo de premios que se pueden ganar durante el día, en función de las probabilidades establecidas. Debe seleccionarse el premio por defecto, que será un premio ilimitado para la máquina y se proporcionará si se cumple una de las limitaciones de premio.',
    advancedLimits: 'Límites Avanzados',
    choosePrize: 'Elegir Premio',
    wheelSectionsAndProbabilities: 'Secciones de Rueda y Probabilidades',
    configureTagsAndProbabilities:
      'En esta área, configuramos las etiquetas verticales y las probabilidades por premio. Las "etiquetas" con mayor prioridad deben considerarse en primer lugar en caso de que el jugador tenga varias etiquetas. Se debe seleccionar el premio predeterminado para cada etiqueta, que se proporcionará si se cumple una de las limitaciones de premio.',
    defaultLanguageIs: 'El idioma por defecto del método de pago es {{language}}',
    bonusConfigurations: 'Configuraciones de Bonificaciones',
    setLimitationsForSelectedGroup: 'En esta área se define la limitación para el grupo seleccionado. Cada premio puede conectarse a un solo grupo.',
    noPrizesForSection: 'Añade un premio en la sección Premios, que puede conectarse a un grupo y definir el límite.',
    spinDistributionPeriodInfo: 'Periodo de tiempo en horas, tras el cual se distribuirán los spins',
    countOfSpinsInfo: 'La cantidad de spins que deben distribuirse a los jugadores',
    expirationPeriodInfo: 'Periodo de tiempo en horas, tras el cual los spins proporcionados caducarán y no podrán utilizarse.',
    tryAgainInfo: 'Sin Victoria',
    machineName: 'Nombre de la Máquina',
    machineType: 'Tipo de la Máquina',
    machineTypes: 'Tipos de Máquinas',
    prizeName: 'Nombre del Premio',
    prizeTypes: 'Tipo del Premio',
    spinDate: 'Fecha del Spin',
    totalUsage: 'Uso Total',
    itemName: 'Nombre del Artículo',
    totalDistributedSpins: 'Distribución Total de Spins',
    totalUsedSpins: 'Total de Spins Utilizados',
    remainingSpins: 'Spins Restantes',
    lastSpinDate: 'Fecha del último Spin',
    limitPerPlayer: 'Límite por Jugador',
    promotionTool: 'Gamificación',
    promotions: 'Gamificación',
    promotion: 'Gamificación',
    slPromotion: 'gamificación',
    winningTableDesc: 'Aquí podemos controlar la habilitación o deshabilitación de la Tabla de Ganancias en la página de Gamificación.',
    myWinningsDesc: 'Aquí podemos controlar la habilitación o deshabilitación de la Tabla de Mis Ganancias en la página de Gamificación.',
    promotionFunds: 'Fondos de Gamificación',
    machineConfigDesc: 'Aquí podemos elegir diferentes máquinas y configurarlas.',
    machineSidebarInfo: 'Aquí podemos elegir la máquina y añadirla.',
    totalWinCountInfo: 'Cantidad total de premios que se pueden ganar durante el período activo de gamificación.',
    playerMaxWinCountInfo: 'Cantidad total de premios que puede ganar un jugador durante la gamificación.',
    prizesSidebarInfo: 'Aquí podemos elegir diferentes premios y añadirlos',
    playingParticipantsSidebarInfo: 'Aquí podemos elegir las condiciones de selección de los participantes',
    contentTermsAndConditionsInfo: 'Aquí definimos los términos y condiciones para participar en la gamificación',
    contentSidebarInfo: 'Aquí podemos definir el contenido e iconos de la gamificación, máquinas y distribuciones de giros',
    manualSpinsPlayers: 'Jugadores con Spins Manuales',
    provisionType: 'Tipo de Provisión',
    spins: 'Spins',
    promotionUrl: 'URL de Gamificación',
    promotionUrlHint: 'Aquí podemos definir la URL de Gamificación que se utilizará para navegar a la página de Gamificación. La ruta relativa /promo/URL se utilizará en el Builder.',
    websiteBackground: 'Background de la Página Web',
    mobileBackground: 'Background de la Página Móvil',
    webSiteFit: 'Tipo de Ajuste para Móvil',
    mobileFit: 'Tipo de Ajuste para Móvil',
    machineContents: 'Contenidos de Máquinas',
    promotionContents: 'Contenidos de Gamificación',
    emptyPromotionContentDesc: 'Puede ver los Contenidos de Gamificación después de agregarlos y configurarlos desde el menú de navegación de la mano derecha.',
    promotionTransactions: 'Transacciones de Gamificación',
    promotionPrizes: 'Premios de Gamificación',
    promotionMachines: 'Máquinas de Gamificación',
    promotionPlayers: 'Jugadores de Gamificación',
    spinDepositDesc: 'Permite proporcionar activaciones a partir de los depósitos realizados por el jugador',
    depositDistributionNumberOfDesc: 'El usuario puede elegir cuántos depósitos activarán las máquinas para los jugadores',
    depositDistributionEveryDesc: 'Este campo permitirá establecer el conteo máximo de activaciones exitosas por jugador',
    periodEveryInfo: 'Permite definir el período durante el cual se deben considerar los depósitos',
    periodEveryMaxTriggerCountInfo: 'Este campo permitirá establecer el conteo máximo de activaciones exitosas por jugador.',
    depositExpirationPeriodInfo: 'Permite definir el período durante el cual las activaciones de máquina caducarán.',
    minimumAmount: 'Importe Mínimo',
    numberOfDepositsInfo: 'Selecciona los depósitos para los cuales los jugadores deben recibir activaciones de máquina en el período especificado.',
    maxIssuanceCount: 'Conteo Máximo de Emisión',
    amountType: 'Tipo de Importe',
    distributionOption: 'Opción de Distribución',
    distributionType: 'Tipo de Distribución',
    sidebarConfigDependenciesInfo:
      'Las secciones de Límite de Grupo, Configuración de Máquinas y Contenido se activarán después de completar las secciones de Distribución de Spins, Participantes que Juegan, Premios y Máquinas.',
    sidebarMissingMainLanguageContentInfo: 'La Gamificación, los Contenidos de Máquinas y los Contenidos de Distribución de Spins deben ser proporcionados en el idioma predeterminado de la marca',
    privateModeParticipantsConfigInfo: 'The provided list can be affected after selecting the gamification currencies.',
    playersAbleToSee: 'Los jugadores pueden ver la página de Gamificación',
    noPromotionsAvailableForPlayer: 'No hay gamificaciones disponibles para el jugador',
    heightAndWidth: 'Altura y Ancho',
    heightAndWidthInfo: 'Selecciona la Altura y el Ancho para crear una máquina con dimensiones específicas.',
    dimensions: 'Dimensiones',
    gridMachineInfo: 'Aquí puedes definir los nombres de las celdas de la máquina de cuadrícula para futuras conexiones de premios.',
    gridSectionsAndProbabilities: 'Secciones de la Cuadrícula y Probabilidades',
    prizeTypeMaterialInfo: 'Los Premios de Tipo Material se pueden crear configurando su nombre y la cantidad equivalente.',
    prizeItemConfigurations: 'Ítems de Premios',
    equivalentAmount: 'Importe Equivalente',
    wonDate: 'Fecha de Ganancia',
    item: 'Ítem',
    daysInfo: 'Permite definir los días durante los cuales el jugador recibirá activaciones',
    resetProgress: 'Reiniciar Progreso',
    resetProgressInfo: 'Cuando está habilitado, el progreso del jugador se reinicia si los spins de días consecutivos no se recogen o reclaman.',
    spinCollectDesc: 'Permite proporcionar activaciones a partir de la acción de recolección del jugador.',
    joiningOptions: 'Opciones de unión',
    onJoinInfo: 'La gamificación requerirá unirse para participar',
    maxJoinedPlayers: 'Núm. Máx. de Jugadores Unidos',
    maxJoinedPlayersInfo: 'Aquí podemos establecer el límite de jugadores que pueden unirse a la gamificación.',
    countDown: 'Cuenta regresiva',
    countDownInfo: 'Aquí podemos habilitar la cuenta regresiva, que mostrará el tiempo restante hasta que comience la gamificación.',
    joinStartDate: 'Fecha de Inicio de Unión',
    joinEndDate: 'Fecha de Finalización de Unión',
    operationStartDate: 'Fecha de Inicio de Operación',
    operationEndDate: 'Fecha de Fin de Operación',
    joinDateInfo: 'Aquí podemos establecer la fecha en la que los jugadores pueden unirse a la gamificación',
    spinBetDesc: 'Los jugadores recibirán activaciones gratuitas para todas las apuestas en el período de activación.',
    subTitle: 'Subtítulo',
    spinDistributionContents: 'Contenidos de Distribución de Spins',
    emptySpinDistributionContentDesc: 'Puedes ver los contenidos de Distribución de Spins después de agregarlos y configurarlos desde el menú de navegación lateral derecho.',
    totalDistributedCount: 'Recuento Total Distribuido',
    receivedSpins: 'Spins Recibidos',
    spinStatistics: 'Estatísticas de Spins',
    buyActivationInfo:
      'El importe por cada activación debe definirse para todas las monedas. Los jugadores pueden comprar la activación según la cantidad, que se calculará multiplicando el conteo y el importe',
    discountPercent: 'Descuento, %',
    walletToBuy: 'Monedero para Comprar',
    buyActivationCountOfSpinsInfo: "El número en el campo 'Desde' de 'Conteo de Spins' está incluido en ese rango, mientras que el número en el campo 'Hasta' no está incluido en el rango.",
    discount: 'Descuento',
    onDiscount: 'Con Descuento',
    buyActivationDesc: 'Permite a los jugadores comprar las activaciones',
    walletToBuyActivationInfo: 'Aquí podemos definir desde qué billetera el jugador puede comprar la activación.',
    gamificationSpinStatistics: '\nEstadísticas de Gamificación de Spins',
    machineInfo: 'Organiza las máquinas con una interfaz de arrastrar y soltar. La primera máquina se convierte en el elemento principal del sitio web, seguida por las demás en la secuencia elegida',
    websiteBackgroundPosition: 'Tipo de Posición de Background del Sitio Web',
    mobileBackgroundPosition: 'Tipo de Posición de Backgrounden Móviles',
    activationOnDepositNumberOfDesc: 'El usuario puede elegir para cuántos depósitos en el período de activación los jugadores obtendrán spins',
    activationOnDepositEveryDesc: 'Los jugadores obtendrán spins por todos los depósitos realizados en el período de activación',
    activationOnDepositCumulativeDesc: 'Los jugadores obtendrán spins por el total de depósitos realizados en un período de tiempo específico',
    spinDistributionCumulativeInfo:
      'La suma de los importes de depósito del jugador debe compararse con los montos definidos. Al cumplir con uno o varios de estos importes definidos, se proporcionarán spins al jugador.',
    spinDistributionCumulativeCheckPointsInfo:
      'Los jugadores ganan spins basados en el monto total de los depósitos realizados dentro de un período específico o al alcanzar puntos de control establecidos.',
    points: 'Puntos',
    addPoints: 'Añadir Puntos',
    viewPoints: 'Ver Puntos',
    resetPoints: 'Reiniciar Puntos ',
    spinDistributionPaymentMethodsInfo: 'Permite establecer métodos de pago de depósito y submétodos. Si no se establece un método de pago, se considerarán todos los métodos de pago disponibles.',
    exchangeCenter: 'Centro de Intercambio',
    exchangeCenterDesc: 'Aquí podemos definir las tasas de cambio entre las máquinas.',
    exchangeItemInfo: 'Define un coeficiente de máquina para permitir el intercambio de spins entre diferentes máquinas.',
    coefficient: 'Coeficiente',
    exchange: 'Intercambio',
    lExchange: 'intercambio',
    emptyExchangeCenterDesc: 'Puedes ver el Intercambio después de añadirlos y configurarlos desde el menú de navegación de la mano derecha.',
    gamificationSpinsReport: 'Informe de Spins de Gamificación',
    prizeTypeMachineSpinInfo:
      'Los premios del tipo "Spin de Máquina" pueden crearse configurando su nombre y especificando el número de spins. Un premio de spin de máquina se proporcionará para la máquina conectada.',
    machineSpinStatistics: 'Estadísticas de Spins de Máquina',
    prizeTypeMachineSpinCountInfo: 'Permite definir la cantidad de spins que deben ser proporcionados.',
    autoSpinAndTurboSpin: 'Autospin/Turbospin',
    autoSpinAndTurboSpinDesc:
      'Aquí podemos controlar la opción de habilitar o deshabilitar la función de Autospin/Turbospin, mediante la cual los jugadores pueden usar su Spin y activar las máquinas en estos modos.',
    isUsedInExchangeCenter: 'No se puede eliminar porque está siendo utilizado en el centro de intercambio.',
    materialStatistics: 'Estadísticas de Materiales',
  },
  tr: {
    freeActivationDesc: 'Belirtilen süre boyunca ücretsiz aktivasyon sağlamaya izin verir',
    spinDistribution: 'Spin Dağıtımı',
    spinDistributions: 'Spin Dağıtımları',
    lSpinDistribution: 'spin dağıtımı',
    winningsTable: 'Kazanç Tablosu',
    countOfSpins: 'Spin Sayısı',
    period: 'Dönem',
    spinProvisionPeriodInfo: 'Makine spinlerinin sağlanacağı sürenin tanımlanmasına izin verir. Süre, makine spini kullandıktan / çevrildikten sonra hesaplanır',
    spinExpirationPeriod: 'Makine spinlerinin sona ereceği sürenin tanımlanmasına izin verir',
    viewingOptions: 'Görüntüleme Seçenekleri',
    emptySpinDistributionDesc: 'Spin Dağıtımlarını ekledikten ve yapılandırdıktan sonra sağ taraftaki navigasyon menüsünden görebilirsiniz.',
    machine: 'Makine',
    lMachine: 'Makine',
    numberOfSections: 'Bölüm Sayısı',
    numberOfSectionsInfo: 'Ödüllerin bağlanacağı Makine Bölümlerinin Sayısı.',
    numberOfSection: 'Bölüm Sayısı',
    sectionName: 'Bölüm Adı',
    machines: 'Makineler',
    emptyMachinesSectionDesc: 'Makineleri ekledikten ve yapılandırdıktan sonra sağ taraftaki navigasyon menüsünden görebilirsiniz.',
    playingParticipants: 'Oynayan Katılımcılar',
    emptyPlayingParticipantsDesc: 'Katılım Koşullarını ekledikten ve yapılandırdıktan sonra sağ taraftaki navigasyon menüsünden görebilirsiniz.',
    allPlayersDesc: 'Tetikleyici uygulandığında tüm oyuncuların katılımcı olarak dahil edilmesine izin verir',
    conditionDesc: 'Spesifik koşulları tetikleyerek oyuncuları dahil etmeye izin verir',
    lPlayingParticipant: 'oynayan katılımcılar',
    machineConfig: 'Makine Yapılandırması',
    machineConfigMachinesInfo: 'Yapılandırmanın başlayacağı makineyi seçin',
    machineConfigSpinDistributionsInfo: 'Oyuncuların makine spinlerini alacağı makine için oluşturulan spin dağıtım kurallarını seçin',
    machineConfigPlayingParticipantsInfo: 'Makineyi kullanan/aktif hale getirebilen oyuncuları kontrol edecek olan oluşturulmuş oyun koşulunu seçin',
    spinDistributionSidebarInfo: 'Burada spin dağıtım kurallarını seçebiliriz',
    prize: 'Ödül',
    lPrize: 'ödül',
    campaignId: 'Kampanya ID',
    totalWinCount: 'Toplam Kazanç Sayısı',
    dailyMaxWinCount: 'Günlük Maksimum Kazanç Sayısı',
    dailyTotalMaxWinCount: 'Günlük Toplam Maksimum Kazanç Sayısı',
    dailyMaxWinCountInfo: 'Gün boyunca tüm oyuncular tarafından kazanılabilecek Günlük Toplam Ödül Miktarı',
    playerMaxWinCount: 'Oyuncu Maksimum Kazanç Sayısı',
    prizeType: 'Ödül Türü',
    prizeTypeBonusInfo: 'Bonus Tipi Ödüller, kampanya seçilerek ve bonusları yapılandırılarak oluşturulabilir',
    bonusCampaignInfo: 'Ödül için bonusları almak üzere çapraz platform kampanyasını seçin',
    prizeAndProbabilitySection: 'Ödül Bölümü ve Olasılık',
    prizeAndProbabilitySectionInfo: 'Makine bölümlerini ve olasılıkları yapılandırmaya başlamak için makineyi seçin.',
    probabilityPercent: 'Olasılık %',
    defaultPrize: 'Varsayılan Ödül',
    setDefaultPrize: 'Varsayılan Ödülü Ayarla',
    advancedProbability: 'Gelişmiş Olasılık',
    advancedProbabilityInfo: 'Makine bölümleri ödüller ve olasılıklarla tanımlanmışsa bölüm etkinleştirilecektir.',
    machineConfigs: 'Makine Yapılandırmaları',
    lMachineConfig: 'makine yapılandırması',
    emptyMachineConfigsDesc: 'Yapılandırılmış Makineleri ekledikten ve yapılandırdıktan sonra sağ taraftaki navigasyon menüsünden görebilirsiniz.',
    lGroupLimit: 'grup limiti',
    groupLimit: 'Grup Limiti',
    emptyGroupLimitDesc: 'Limitleri ekledikten ve yapılandırdıktan sonra sağ taraftaki navigasyon menüsünden görebilirsiniz.',
    groupLimitDesc: 'Burada grupları seçebilir ve limitleri koyabiliriz',
    groupLimitGeneralInfo:
      'Limitler, ödüller yapılandırılarak ve gruplara bağlanarak tanımlanabilir. Ödül standart olasılık bölümüne eklenir veya buradan silinirse gelişmiş olasılık bölümü sıfırlanır.',
    groupLimitAdvancedTagsInfo: 'Bu alanda, seçilen grup için limitleri tanımlarız',
    groupLimitAdvancedInfo: 'Etiket başına limitler tanımlanabilir ve oyuncu seçilen etiketlerden birine sahipse, etiket limiti dikkate alınır.',
    groupLimitAdvancedPriorityInfo:
      "Seçilen her Dikey etiketin kendi önceliği olmalıdır. En yüksek öncelik 1'den başlar. Eğer oyuncunun limitlerde tanımlanmış birden fazla etiketi varsa, en yüksek öncelikli etiket dikkate alınmalıdır.",
    prizes: 'Ödüller',
    groupType: 'Grup Türü',
    advanced: 'Gelişmiş',
    standard: 'Standart',
    emptyPrizesDesc: 'Ödülleri ekledikten ve yapılandırdıktan sonra sağ taraftaki navigasyon menüsünden görebilirsiniz.',
    verifyConditionDesc: 'Oyuncuların doğrulama seviyesini kontrol eder',
    content: 'İçerik',
    contentMachineInfo: 'Makinenin içeriğini yapılandırmak için seçin.',
    unAuthorizedSubtitle: 'Onaylanmamış Altyazı',
    authorizedSubtitle: 'Onaylanmış Altyazı',
    websiteMachineBackground: 'Web Sitesi Makine Arka Planı',
    mobileMachineBackground: 'Mobil Makine Arka Planı',
    authorizedSubtitleInfo: 'Yetkisi olan oyunculara gösterilecek altyazıyı tanımlayın',
    unAuthorizedSubtitleInfo: 'Yetkisi olmayan oyunculara gösterilecek altyazıyı tanımlayın',
    sectionSubtitle: 'Bölüm Altyazısı',
    contentSectionInfo: 'Bu alanda, makine bölümleri için içeriği tanımlarız. Bölüm içeriği para birimi bazında da tanımlanabilir.',
    contentSelectSectionsInfo: 'Bölümler için makine seçin',
    contentPrizeInfo: 'Bu alanda oyuncu tarafında görünecek ödüller için içerik ve çeviriler tanımlanır.',
    termsAndConditions: 'Şartlar ve Koşullar',
    sectionContent: 'Bölüm İçeriği',
    sectionTitle: 'Bölüm Başlığı',
    probabilities: 'Olasılıklar',
    isUsedInMachineConfig: 'Makine yapılandırmasında kullanıldığı için silinemiyor',
    emptyContentDesc: 'İçeriği ekledikten ve yapılandırdıktan sonra sağ taraftaki navigasyon menüsünden görebilirsiniz.',
    previewName: 'Ön İzleme Adı',
    connectPrizesAndDefineProbabilities:
      'Bu alanda, ödülleri makine bölümlerine bağlarız ve bunun için olasılıkları tanımlarız. Belirlenen olasılıklara göre gün içinde kazanılabilecek maksimum ödül sayısı tanımlanabilir. Makine için sınırsız ödül olacak ve ödül limitlerinden birinin karşılanması durumunda sağlanacak olan varsayılan ödül seçilmelidir.',
    advancedLimits: 'Gelişmiş limitler',
    choosePrize: 'Ödül Seçin',
    wheelSectionsAndProbabilities: 'Çark Bölümleri ve Olasılıklar',
    configureTagsAndProbabilities:
      'Bu alanda, ödül başına Dikey etiketleri ve olasılıkları yapılandırırız. Oyuncunun birden fazla etiketi olması durumunda, daha yüksek önceliğe sahip etiketler ilk sırada dikkate alınmalıdır. Her etiket için, ödül limitlerinden birinin karşılanması durumunda sağlanacak olan varsayılan ödül seçilmelidir.',
    defaultLanguageIs: 'Markanın varsayılan dili {{language}}',
    bonusConfigurations: 'Bonus Yapılandırmaları',
    setLimitationsForSelectedGroup: 'Bu alanda, seçilen grup için limit tanımlanır. Her ödül bir gruba bağlanabilir.',
    noPrizesForSection: 'Ödüller bölümüne ödül ekleyin, bu ödül gruba bağlanabilir ve limiti tanımlanabilir',
    spinDistributionPeriodInfo: 'Zaman periyodu saat cinsinden, sonrasında spinler dağıtılır',
    countOfSpinsInfo: 'Oyunculara dağıtılması gereken spin sayısı',
    expirationPeriodInfo: 'Zaman periyodu saat cinsinden, bu süreden sonra sağlanan dönüşlerin süresi dolar ve kullanılamaz',
    tryAgainInfo: 'Kazanç Yok',
    machineName: 'Makine Adı',
    machineType: 'Makine Türü',
    machineTypes: 'Makine Türleri',
    prizeName: 'Ödül Adı',
    prizeTypes: 'Ödül Türleri',
    spinDate: 'Spin Tarihi',
    totalUsage: 'Toplam Kullanım',
    itemName: 'Öğe Adı',
    totalDistributedSpins: 'Toplam Dağıtılmış Spinler',
    totalUsedSpins: 'Toplam Kullanılmış Spin',
    remainingSpins: 'Kalan Spinler',
    lastSpinDate: 'Son Spin Tarihi',
    limitPerPlayer: 'Oyuncu Başına Limit',
    promotionTool: 'Oyunlaştırma',
    promotions: 'Oyunlaştırma',
    promotion: 'Oyunlaştırma',
    slPromotion: 'oyunlaştırma',
    promotionFund: 'Oyunlaştırma Fonu',
    winningTableDesc: 'Burada, Oyunlaştırma Sayfasında Kazanç Tablosunu etkinleştirmeyi veya devre dışı bırakmayı kontrol edebiliriz.',
    myWinningsDesc: 'Burada, Oyunlaştırma Sayfasında Kazanç Tablomu etkinleştirmeyi veya devre dışı bırakmayı kontrol edebiliriz.',
    promotionFunds: 'Oyunlaştırma Fonları',
    machineConfigDesc: 'Burada farklı makineler seçebilir ve bunları yapılandırabiliriz',
    machineSidebarInfo: 'Burada makineyi seçebilir ve ekleyebiliriz',
    totalWinCountInfo: 'Oyunlaştırmanın aktif olduğu süre boyunca kazanılabilecek Toplam Ödül Miktarı',
    playerMaxWinCountInfo: 'Oyunlaştırma sırasında bir oyuncu tarafından kazanılabilecek Toplam Ödül Miktarı',
    prizesSidebarInfo: 'Burada farklı ödüller seçebilir ve bunları ekleyebiliriz',
    playingParticipantsSidebarInfo: 'Burada katılımcıların seçilme koşullarını belirleyebiliriz.',
    contentTermsAndConditionsInfo: 'Bu alanda, oyunlaştırmaya katılım için şart ve koşulları tanımlıyoruz.',
    contentSidebarInfo: 'Burada oyunlaştırmanın içeriğini ve simgelerini, makineleri ve spin dağıtımlarını tanımlayabiliriz',
    promotionName: 'Oyunlaştırma',
    navigationItemsDisabledInfo: 'Oyunlaştırma Genel bölümü kaydedildikten sonra gezinme öğeleri etkin hale gelecektir.',
    manualSpinsPlayers: 'Manuel Spin Oyuncular',
    provisionType: 'Provizyon Türü',
    spins: 'Spinler',
    promotionUrl: 'Oyunlaştırma URL',
    promotionUrlHint: "Burada, Oyunlaştırma sayfasına gitmek için kullanılacak Oyunlaştırma URL'sini tanımlayabiliriz. Builder'da /promo/URL relative path (göreceli yolu) kullanılacaktır",
    websiteBackground: 'Web Sitesi Arka Planı',
    mobileBackground: 'Mobil Arka Planı',
    pageBackground: 'Sayfa Arka Planı',
    webSiteFit: 'Web Sitesi Uyum Türü',
    mobileFit: 'Mobil Uyum Türü',
    machineContents: 'Makine İçerikleri',
    promotionContents: 'Oyunlaştırma İçerikleri',
    emptyPromotionContentDesc: 'Oyunlaştırma İçeriklerini ekledikten ve yapılandırdıktan sonra sağ taraftaki gezinti menüsünden görebilirsiniz.',
    promotionTransactions: 'Oyunlaştırma İşlemleri',
    promotionPrizes: 'Oyunlaştırma Ödülleri',
    promotionMachines: 'Oyunlaştırma Makineleri',
    promotionPlayers: 'Oyunlaştırma Oyuncuları',
    spinDepositDesc: 'Oyuncu tarafından yapılan para yatırma işlemlerinden etkinleştirmelere izin verir',
    depositDistributionNumberOfDesc: 'Kullanıcı, oyuncuların kaç para yatırma işlemi için makine etkinleştirme alacağını seçebilir',
    depositDistributionEveryDesc: 'Bu alan, oyuncu başına maksimum başarılı tetikleme sayısının ayarlanmasına izin verir',
    periodEveryInfo: 'Yatırımların dikkate alınması gereken dönemin tanımlanmasına izin verir',
    periodEveryMaxTriggerCountInfo: 'Bu alan, oyuncu başına maksimum başarılı tetikleme sayısının ayarlanmasına izin verir',
    depositExpirationPeriodInfo: 'Makine spinlerinin sona ereceği sürenin tanımlanmasına izin verir',
    minimumAmount: 'Minimum Tutar',
    numberOfDepositsInfo: 'Belirtilen dönemde oyuncuların makine spini alması gereken para yatırma işlemlerini seçin',
    maxIssuanceCount: 'Maksimum Tahsis Sayısı',
    amountType: 'Tutar Türü',
    distributionOption: 'Dağıtım Seçeneği',
    distributionType: 'Dağıtım Türü',
    sidebarConfigDependenciesInfo: 'Grup Limiti, Makine Yapılandırması ve İçerik bölümleri Spin Dağıtımı, Oynayan Katılımcılar, Ödül ve Makine bölümleri tamamlandıktan sonra etkinleştirilir.',
    sidebarMissingMainLanguageContentInfo: 'Oyunlaştırma, Makine İçerikleri ve Spin Dağıtım İçerikleri markanın varsayılan dili için sağlanmalıdır.',
    privateModeParticipantsConfigInfo: 'Oyunlaştırma para birimleri seçildikten sonra sağlanan liste etkilenebilir.',
    playersAbleToSee: 'Oyuncular Oyunlaştırma sayfasını görebilir',
    noPromotionsAvailableForPlayer: 'Oyuncu için herhangi bir oyunlaştırma mevcut değildir',
    heightAndWidth: 'Yükseklik ve Genişlik',
    heightAndWidthInfo: 'Spesifik boyutlara sahip bir makine oluşturmak için Yükseklik ve Genişliği seçin',
    dimensions: 'Boyutlar',
    gridMachineInfo: 'Burada gelecekteki ödül bağlantıları için Grid makine hücre adlarını tanımlayabilirsiniz',
    gridSectionsAndProbabilities: 'Grid Bölümleri ve Olasılıklar',
    prizeTypeMaterialInfo: 'Materyal Türü Ödülleri, adları ve eşdeğer tutarları yapılandırılarak oluşturulabilir',
    prizeItemConfigurations: 'Ödül Öğesi Yapılandırmaları',
    equivalentAmount: 'Eşdeğer Tutar',
    wonDate: 'Kazanılan Tarih',
    item: 'Öğe',
    daysInfo: 'Oyuncunun spinleri alacağı günlerin tanımlanmasına izin verir',
    resetProgress: 'İlerlemeyi Sıfırla',
    resetProgressInfo: 'Etkinleştirildiğinde, ardışık günlerin spinleri alınmazsa/talep edilmezse oyuncunun ilerlemesi sıfırlanır',
    spinCollectDesc: 'Oyuncunun toplama eyleminden etkinleştirme sağlamaya izin verir.',

    joiningOptions: 'Katılım Seçenekleri',
    onJoinInfo: 'Oyunlaştırma, katılmak için kayıt olmayı gerektirir',
    maxJoinedPlayers: 'Maksimum Katılan Oyuncu',
    maxJoinedPlayersInfo: 'Burada oyunlaştırmaya katılabilecek oyuncuların limitini ayarlayabiliriz.',
    countDown: 'Geri Sayım',
    countDownInfo: 'Burada, Oyunlaştırma başlayana kadar kalan süreyi gösterecek olan geri sayımı etkinleştirebiliriz.',
    joinStartDate: 'Katılım Başlangıç Tarihi',
    joinEndDate: 'Katılım Bitiş Tarihi',
    operationStartDate: 'Operasyon Başlangıç Tarihi',
    operationEndDate: 'Operasyon Bitiş Tarihi',
    joinDateInfo: 'Burada oyuncuların oyunlaştırmaya katılabileceği tarihi ayarlayabiliriz.',
    spinBetDesc: 'Oyuncular tetikleme dönemindeki tüm bahisler için ücretsiz aktivasyon kazanır\n',
    subTitle: 'Altyazı',
    spinDistributionContents: 'Spin Dağıtım İçeriği',
    emptySpinDistributionContentDesc: 'Spin Dağıtım İçeriğini ekledikten ve yapılandırdıktan sonra sağ taraftaki gezinti menüsünden görebilirsiniz.',
    totalDistributedCount: 'Toplam Dağıtılan Sayı',
    receivedSpins: 'Alınan Spinler',
    spinStatistics: 'Spin İstatistikleri',
    buyActivationInfo: 'Bir aktivasyon başına miktar tüm para birimleri için tanımlanmalıdır. Oyuncular, sayı ve miktarın çarpılmasıyla hesaplanacak miktara göre aktivasyonu satın alabilirler.',
    discountPercent: 'İndirim, %',
    walletToBuy: 'Cüzdan Satın Almak',
    buyActivationCountOfSpinsInfo: "Spin Sayısı'nın 'Sayıdan' alanındaki sayı bu aralığa dahil edilirken, 'Sayıya' alanındaki sayı aralığa dahil edilmez.",
    discount: 'İndirim',
    onDiscount: 'İndirimde',
    buyActivationDesc: 'Oyuncuların aktivasyonları satın almasına izin verir.',
    walletToBuyActivationInfo: 'Burada oyuncunun aktivasyonu hangi cüzdandan satın alabileceğini tanımlayabiliriz.',
    gamificationSpinStatistics: 'Oyunlaştırma Spin İstatistikleri',
    machineInfo: 'Sürükle ve bırak arayüzü ile makineleri düzenleyin. İlk makine web sitesindeki öncü öğe olur ve ardından seçtiğiniz sırayla diğerleri gelir.',
    websiteBackgroundPosition: 'Web Sitesi Arka Plan Pozisyon türü',
    mobileBackgroundPosition: 'Mobil Arka Plan Pozisyon türü',
    activationOnDepositNumberOfDesc: 'Kullanıcı, tetikleme döneminde oyuncuların kaç para yatırma işlemi için spin alacağını seçebilir',
    activationOnDepositEveryDesc: 'Oyuncular tetikleme dönemindeki tüm para yatırma işlemleri için spin alırlar',
    activationOnDepositCumulativeDesc: 'Oyuncular belirlenen bir zaman diliminde yaptıkları toplam para yatırma işlemi için spin alırlar',
    spinDistributionCumulativeInfo:
      'Oyuncunun yatırım tutarlarının toplamı, tanımlanan tutarlarla karşılaştırılmalıdır. Bir veya birden fazla tutarın karşılanmasıyla, tanımlanan spinler oyuncuya sağlanacaktır.',
    spinDistributionCumulativeCheckPointsInfo: 'Oyuncular, belirli bir süre içinde yapılan toplam para yatırma miktarına göre veya belirlenen noktalara ulaşarak spin kazanırlar.',
    points: 'Puanlar',
    addPoints: 'Puan Ekle',
    viewPoints: 'Puanları Görüntüle',
    resetPoints: 'Puanları Sıfırla',
    spinDistributionPaymentMethodsInfo: 'Para yatırma ödeme yöntemlerini ve alt yöntemleri ayarlamaya izin verir. Ödeme yöntemi belirlenmezse, Tüm Ödemeler yöntemleri göz önünde bulundurulacaktır.',
    exchangeCenter: 'Değişim Merkezi',
    exchangeCenterDesc: 'Burada makineler arasındaki döviz kurlarını tanımlayabiliriz',
    exchangeItemInfo: 'Farklı makineler arasında spin değişimini sağlamak için bir makine katsayısı tanımlayın',
    coefficient: 'Katsayı',
    exchange: 'Değişim',
    lExchange: 'değişim',
    emptyExchangeCenterDesc: 'Değişimi ekledikten ve yapılandırdıktan sonra sağ taraftaki gezinti menüsünden görebilirsiniz.',
    gamificationSpinsReport: 'Oyunlaştırma Spin Raporu',
    prizeTypeMachineSpinInfo: 'Makine Spin Türü Ödülleri, adları yapılandırılarak ve spin sayısı girilerek oluşturulabilir. Bağlı makine için bir makine Spin ödülü sağlanacaktır.',
    machineSpinStatistics: 'Makine Spin İstatistikleri',
    prizeTypeMachineSpinCountInfo: 'Verilmesi gereken spin sayısının tanımlanmasını sağlar',
    autoSpinAndTurboSpin: 'Otospin/Turbospin',
    autoSpinAndTurboSpinDesc:
      'Burada, oyuncuların Spinlerini kullanabilecekleri ve makineleri bu modlarda etkinleştirebilecekleri Otospin / Turbospin seçeneğini etkinleştirmeyi veya devre dışı bırakmayı kontrol edebiliriz.',
    isUsedInExchangeCenter: 'Değişim merkezinde kullanıldığı için silinemiyor',
    materialStatistics: 'Materyal İstatistikleri',
  },
  ru: {
    promotionTool: 'Геймификация',
    promotions: 'Геймификация',
    promotion: 'Геймификация',
    slPromotion: 'геймификация',
    freeActivationDesc: 'Позволяет предоставлять бесплатные активации на указанный период времени',
    spinDistribution: 'Распределение спинов',
    spinDistributions: 'Распределения спинов',
    lSpinDistribution: 'распределение спинов',
    promotionFund: 'Фонд геймификации',
    winningsTable: 'Таблица выигрышей',
    winningTableDesc: 'Здесь можно включать и выключать Таблицу выигрышей на Cтранице геймификации.',
    myWinningsDesc: 'Здесь можно включать и выключать Таблицу выигрышей на Cтранице геймификации.',
    promotionFunds: 'Фонды геймификаций',
    countOfSpins: 'Количество спинов',
    period: 'Период',
    spinProvisionPeriodInfo: 'Позволяет определить период, в течение которого будут предоставляться спины. Период будет рассчитан после использования спина',
    spinExpirationPeriod: 'Позволяет определить период окончания срока действия спинов',
    viewingOptions: 'Параметры просмотра',
    emptySpinDistributionDesc: 'Распределение спинов будет отображаться после добавления и настройки функции в меню навигации справа.',
    machine: 'Игровой автомат',
    lMachine: 'Игровой автомат',
    numberOfSections: 'Количество секций',
    numberOfSectionsInfo: 'Количество секций автомата, в которых будут соединяться призы.',
    numberOfSection: 'Номер секции',
    sectionName: 'Название секции',
    machines: 'Игровые автоматы',
    emptyMachinesSectionDesc: 'Игровые автоматы будут отображаться после их добавления и настройки в меню навигации справа.',
    playingParticipants: 'Участники игры',
    emptyPlayingParticipantsDesc: 'Условия участия будут отображаться после их добавления и настройки в меню навигации справа.',
    allPlayersDesc: 'Позволяет включать всех игроков в список участников при условии включения триггера',
    conditionDesc: 'Позволяет включать игроков в список по определенным условиям триггера',
    lPlayingParticipant: 'участники игры',
    machineConfig: 'Конфигурации игрового автомата',
    machineConfigDesc: 'Здесь можно выбрать игровые автоматы и настроить их',
    machineSidebarInfo: 'Здесь можно выбрать игровой автомат и добавить его',
    machineConfigMachinesInfo: 'Выберите автомат, для которого запустится конфигурация',
    machineConfigSpinDistributionsInfo: 'Выберите созданные правила распределения спинов для автомата, на основании которых игроки будут получать спины ',
    machineConfigPlayingParticipantsInfo: 'Выберите созданное игровое условие, определяющее игроков, которые могут использовать/активировать автомат',
    spinDistributionSidebarInfo: 'Здесь можно выбрать правила распределения спинов',
    prize: 'Приз',
    lPrize: 'приз',
    campaignId: 'ID кампании',
    totalWinCount: 'Общее число побед',
    totalWinCountInfo: 'Общее количество призов, которые можно выиграть в течение активного периода геймификации',
    dailyMaxWinCount: 'Максимальное количество выигрышей в день',
    dailyTotalMaxWinCount: 'Общее максимальное количество выигрышей в день',
    dailyMaxWinCountInfo: 'Общее количество призов, которые могут выиграть все игроки в течение дня',
    playerMaxWinCount: 'Максимальное количество выигрышей игрока',
    playerMaxWinCountInfo: 'Общее количество призов, которые может выиграть один игрок во время геймификации',
    prizeType: 'Тип приза',
    prizeTypeBonusInfo: 'Призы бонусного типа можно создать, выбрав кампанию и настроив ее бонусы',
    bonusCampaignInfo: 'Выберите кроссплатформенную кампанию, чтобы получить бонусы к призу',
    prizeAndProbabilitySection: 'Секция призов и вероятностей',
    prizeAndProbabilitySectionInfo: 'Выберите игровой автомат, чтобы начать настройку секций и вероятностей.',
    probabilityPercent: 'Вероятность выигрыша %',
    defaultPrize: 'Приз по умолчанию',
    setDefaultPrize: 'Установить приз по умолчанию',
    advancedProbability: 'Продвинутая вероятность',
    advancedProbabilityInfo: 'Секция будет включена, если секции игрового автомата определены с призами и вероятностями.',
    machineConfigs: 'Конфигурации игрового автомата',
    lMachineConfig: 'конфигурации игрового автомата',
    emptyMachineConfigsDesc: 'Настроенные игровые автоматы будут отображаться после их добавления и настройки в меню навигации справа.',
    lGroupLimit: 'групповой лимит',
    groupLimit: 'Групповой лимит',
    emptyGroupLimitDesc: 'Вы можете увидеть лимиты после их добавления и настройки в меню навигации справа.',
    groupLimitDesc: 'Здесь можно выбрать группы и установить ограничения',
    groupLimitGeneralInfo:
      'Ограничения определяются путем настройки и привязки призов к группам. Раздел продвинутой вероятности будет сброшен, если приз будет добавлен или удален из раздела стандартной вероятности.',
    groupLimitAdvancedTagsInfo: 'В этой области можно определить ограничение для выбранной группы',
    groupLimitAdvancedInfo: 'Ограничения по тегам будут учитываться, если у игрока есть один из выбранных тегов.',
    groupLimitAdvancedPriorityInfo:
      'Каждый выбранный вертикальный тег должен иметь собственный приоритет. Наивысший приоритет начинается с 1. Если у игрока несколько тегов, установленных в ограничениях, в первую очередь следует учитывать тег с наивысшим приоритетом.',
    prizes: 'Призы',
    groupType: 'Тип группы',
    advanced: 'Продвинутая',
    standard: 'Стандартная',
    lPromotion: 'геймификация',
    emptyPrizesDesc: 'Призы будут отображаться после их добавления и настройки в меню навигации справа.',
    prizesSidebarInfo: 'Здесь можно выбрать призы и добавить их',
    playingParticipantsSidebarInfo: 'Здесь можно определить условия отбора участников.',
    verifyConditionDesc: 'Проверка уровня верификации игроков',
    content: 'Содержание',
    contentMachineInfo: 'Выберите игоровой автомат, что настроить его содержание.',
    unAuthorizedSubtitle: 'Подзаголовок для неавторизированных пользователей',
    authorizedSubtitle: 'Подзаголовок для авторизированных пользователей',
    websiteMachineBackground: 'Веб-версия фона игрового автомата',
    mobileMachineBackground: 'Мобильная версия фона игрового автомата',
    authorizedSubtitleInfo: 'Определите подзаголовок, который будет показан авторизованным игрокам',
    unAuthorizedSubtitleInfo: 'Определите подзаголовок, который будет показан неавторизованным игрокам',
    sectionSubtitle: 'Подзаголовок секции',
    contentSectionInfo: 'В этой области можно определить содержание для разделов игрового автомата. Содержимое секции также может быть настроено для каждой валюты.',
    contentSelectSectionsInfo: 'Выберите игровой автомат, чтобы получить секции',
    contentPrizeInfo: 'В этой области можно определить описание призов с переводами, которые будут видны игрокам.',
    termsAndConditions: 'Условия и положения',
    contentTermsAndConditionsInfo: 'В этой области можно определить условия участия в акции.',
    contentSidebarInfo: 'Здесь можно содержание и иконки геймификации, автоматы и распределение спинов.',
    sectionContent: 'Содержание секции',
    sectionTitle: 'Название секции',
    probabilities: 'Вероятности',
    isUsedInMachineConfig: 'Невозможно удалить, так как это используется в конфигурации игрового автомата.',
    promotionName: 'Геймификация',
    emptyContentDesc: 'Содержание игрового автомата будет отобраться после его добавления и настройки в меню навигациисправа.',
    previewName: 'Предварительный просмотр названия',
    navigationItemsDisabledInfo: 'Элементы навигации станут активными после сохранения раздела «Общие акции».',
    connectPrizesAndDefineProbabilities:
      'В этой области можно привязывать призы к секциями автоматов и определять для них вероятности. Можно определять максимальное '
      + 'количество призов, которые можно выиграть в течение дня, исходя из установленных вероятностей. необходимо выбрать приз по умолчанию, который будет неограниченным призом для автомата и будет предоставлен, если будет выполнено одно из ограничений приза.',
    advancedLimits: 'Продвинутые лимиты',
    choosePrize: 'Выберите приз',
    wheelSectionsAndProbabilities: 'Секции колеса и вероятности выигрыша',
    configureTagsAndProbabilities:
      'В этой области можно настроить вертикальные теги и вероятности выигрыша каждого приза. Если у игрока несколько тегов, в первую очередь следует рассматривать теги с наиболее высоким приоритетом. Для каждого тега нужно выбрать приз по умолчанию, который будет вручен, если выполнено одно из ограничений по призу.',
    defaultLanguageIs: 'Язык бренда по умолчанию: {{language}}',
    bonusConfigurations: 'Конфигурации бонуса',
    setLimitationsForSelectedGroup: 'В этой области можно устанавливать ограничения для выбранной группы. Каждый приз может быть привязан к одной группе.',
    noPrizesForSection: 'Добавьте приз в секцию Призы, которая может быть привязана к группе и где можно установить ограничения',
    spinDistributionPeriodInfo: 'Период времени в часах, по истечении которого спины будут распределены',
    countOfSpinsInfo: 'Количество спинов, которое должно быть распределено между игроками',
    expirationPeriodInfo: 'Период времени в часах, по истечении которого предоставленные спины аннулируются и не могут быть использованы',
    tryAgainInfo: 'Выигрыша нет',
    machineName: 'Название игрового автомата',
    machineType: 'Тип игрового автомата',
    machineTypes: 'Типы игровых автоматов',
    prizeName: 'Название приза',
    prizeTypes: 'Типы призов',
    spinDate: 'Дата спина ',
    totalUsage: 'Суммарное использование',
    itemName: 'Имя элемента',
    totalDistributedSpins: 'Общее количество распределенных спинов',
    totalUsedSpins: 'Общее количество использованных спинов',
    remainingSpins: 'Оставшиеся спины',
    lastSpinDate: 'Дата последнего спина',
    limitPerPlayer: 'Лимит на игрока',
    manualSpinsPlayers: 'Игроки с ручными спинами',
    provisionType: 'Тип обеспечения',
    spins: 'Спины',
    promotionUrl: 'URL геймификации',
    promotionUrlHint: 'Здесь мы можем определить URL Геймификации, который будет использоваться для перехода на страницу Геймификация. Относительный путь /промо/URL будет использоваться в Builder',
    websiteBackground: 'Бэкграунд веб-сайта',
    mobileBackground: 'Бэкграунд для мобильных устройств',
    pageBackground: 'Бэкграунд страницы',
    webSiteFit: 'Тип установки веб-сайта',
    mobileFit: 'Мобильный тип установки',
    machineContents: 'Содержание игровых автоматов',
    promotionContents: 'Содержание геймификации',
    emptyPromotionContentDesc: 'Вы можете увидеть Содержание геймификации после его добавления и настройки в навигационном меню справа.',
    promotionTransactions: 'Игровые транзакции',
    promotionPrizes: 'Игровые призы',
    promotionMachines: 'Игровые атоматы',
    promotionPlayers: 'Игроки',
    spinDepositDesc: 'Позволяет проводить активации с депозитов, сделанных игроком',
    depositDistributionNumberOfDesc: 'Пользователь может выбрать, за какое количество депозитов игроки получат активацию игрового автомата',
    depositDistributionEveryDesc: 'Это поле позволяет установить максимальное количество успешных триггеров для одного игрока',
    periodEveryInfo: 'Позволяет определить период, за который следует учитывать депозиты',
    periodEveryMaxTriggerCountInfo: 'Это поле позволяет установить максимальное количество успешных триггеров для одного игрока',
    depositExpirationPeriodInfo: 'Позволяет определить период, в течение которого будут заканчиваться спины в игровом автомате',
    minimumAmount: 'Минимальная сумма',
    numberOfDepositsInfo: 'Выберите депозиты, за которые игроки должны получить спины в игровом автомате в указанный период',
    maxIssuanceCount: 'Максимальное количество выпусков',
    amountType: 'Тип суммы',
    distributionOption: 'Вариант распределения',
    distributionType: 'Тип распределения',
    sidebarConfigDependenciesInfo:
      'Разделы Лимит группы, Конфигурация игрового автомата и Содержание станут активными после заполнения разделов Распределение спинов, Участники игры, Приз и Игровой автомат.',
    sidebarMissingMainLanguageContentInfo: 'Разделы Геймификация, Содержание игровых автоматов и Распределение спинов должны быть представлены на языке бренда по умолчанию.',
    privateModeParticipantsConfigInfo: 'На представленный список можно повлиять после выбора валют геймификации.',
    playersAbleToSee: 'Игроки могут видеть страницу Геймификация',
    noPromotionsAvailableForPlayer: 'Игрокам не доступна геймификация',
    heightAndWidth: 'Высота и ширина',
    heightAndWidthInfo: 'Выберите высоту и ширину, чтобы создать игровой автомат с определенными размерами',
    dimensions: 'Размеры',
    gridMachineInfo: 'Здесь вы можете задать имена ячеек грид-игрового автомата для будущих призовых подключений',
    gridSectionsAndProbabilities: 'Грид-сечения и вероятности',
    prizeTypeMaterialInfo: 'Призы материального типа можно создать, указав их название и эквивалентную сумму',
    prizeItemConfigurations: 'Конфигурации призовых элементов',
    equivalentAmount: 'Эквивалентная сумма',
    wonDate: 'Дата выигрыша',
    item: 'элемент',
    daysInfo: 'Позволяет определить дни, в течение которых игрок будет получать спины',
    resetProgress: 'Сброс прогресса',
    resetProgressInfo: 'Если эта функция включена, прогресс игрока сбрасывается, если в течение нескольких дней подряд спины не были получены/сыграны',
    spinCollectDesc: 'Позволяет предоставлять активации от действий игрока по получению.',
    joiningOptions: 'Опции присоединения',
    onJoinInfo: 'Чтобы принять участие, необходимо присоединиться к геймификации',
    maxJoinedPlayers: 'Максимальное количество присоединившихся игроков',
    maxJoinedPlayersInfo: 'Здесь мы можем установить ограничение на количество игроков, которые могут присоединиться к геймификации.',
    countDown: 'Обратный отсчет',
    countDownInfo: 'Здесь мы можем включить обратный отсчет, который будет показывать время, оставшееся до начала Геймификации.',
    joinStartDate: 'Дата начала присоединения',
    joinEndDate: 'Дата окончания присоединения',
    operationStartDate: 'Дата начала операции',
    operationEndDate: 'Дата окочания операции',
    joinDateInfo: 'Здесь мы можем задать дату, когда игроки смогут присоединиться к геймификации.',
    spinBetDesc: 'Игроки получат бесплатные активации для всех ставок в триггерный период\n',
    subTitle: 'Подзаголовок',
    spinDistributionContents: 'Содержание распределения спинов',
    emptySpinDistributionContentDesc: 'Содержание распределения спинов после его добавления и настройки можно увидеть в навигационном меню справа.',
    totalDistributedCount: 'Всего распределено',
    receivedSpins: 'Полученные спины',
    spinStatistics: 'Статистика спинов',
    buyActivationInfo:
      'Сумма за одну активацию должна быть определена для всех валют. Игроки могут приобрести активацию на основе количества, которое будет рассчитано путем умножения количества и суммы.',
    discountPercent: 'Дисконт, %',
    walletToBuy: 'Кошелек для покупки',
    buyActivationCountOfSpinsInfo: "Число в поле 'От' в разделе 'Счет спинов' входит в этот диапазон, а число в поле 'До' не входит в этот диапазон.",
    discount: 'Дисконт',
    onDiscount: 'С дисконтом',
    buyActivationDesc: 'Позволяет игрокам покупать активации.',
    walletToBuyActivationInfo: 'Здесь мы можем определить, с какого кошелька игрок может приобрести активацию.',
    gamificationSpinStatistics: 'Статистика игровых спинов',
    machineInfo: 'Упорядочивайте игровые автоматы с помощью интерфейса перетаскивания. Первый игровой автомат становится главным на сайте, за ним следуют другие в выбранной вами последовательности.',
    websiteBackgroundPosition: 'Тип расположения бэкграунда веб-сайта',
    mobileBackgroundPosition: 'Тип расположения бэкграунда для мобильной версии',
    activationOnDepositNumberOfDesc: 'Пользователь может выбрать, за какое количество депозитов в триггерный период игроки получат спины',
    activationOnDepositEveryDesc: 'Игроки получат спины на все депозиты в триггерный период',
    activationOnDepositCumulativeDesc: 'Игроки получают спины за общую сумму депозитов, сделанных за определенный период времени',
    spinDistributionCumulativeInfo: 'Сумма депозитов игрока должна быть сравнена с определенными суммами. При соответствии одной или нескольких сумм игроку будут предоставлены определенные спины.',
    spinDistributionCumulativeCheckPointsInfo: 'Игроки зарабатывают спины, основываясь на общей сумме депозитов, сделанных за определенный период, или достигая установленных контрольных точек.',
    points: 'Очки',
    addPoints: 'Добавить очки',
    viewPoints: 'Смотреть очки',
    resetPoints: 'Сбросить очки',
    spinDistributionPaymentMethodsInfo: 'Позволяет установить методы оплаты депозита и субметоды. Если способ оплаты не задан, будут использоваться все способы оплаты.',
    exchangeCenter: 'Центр обмена',
    exchangeCenterDesc: 'Здесь мы можем определить обменные курсы между игровыми автоматами',
    exchangeItemInfo: 'Определите коэффициент игрового автомата, чтобы обеспечить обмен спинами между разными игровыми автоматами',
    coefficient: 'Коэффициент',
    exchange: 'Обмен',
    lExchange: 'обмен',
    emptyExchangeCenterDesc: 'Вы можете увидеть Обмен после добавления и настройки в навигационном меню справа.',
    gamificationSpinsReport: 'Отчет об игровых спинах',
    prizeTypeMachineSpinInfo:
      'Призы типа Спин игрового автомата можно создать, задав их название и указав количество спинов. Для подключенного игорового автомата будет предоставлен приз Спин игрового автомата.',
    machineSpinStatistics: 'Статистика синов игрового автомата',
    prizeTypeMachineSpinCountInfo: 'Позволяет определить количество спинов, которое должно быть предоставлено',
    autoSpinAndTurboSpin: 'Автоспин/Турбоспин',
    autoSpinAndTurboSpinDesc:
      'Здесь мы можем управлять включением или выключением опции Автоспин/Турбоспин, с помощью которой игроки могут использовать свои спины и активировать игровые автоматы в этих режимах.',
    isUsedInExchangeCenter: 'Невозможно удалить, потому что он используется в центре обмена',
    materialStatistics: 'Статистика материалов',
  },
  pt: {
    freeActivationDesc: 'Permite fornecer ativações gratuitas pelo período de tempo especificado',
    spinDistribution: 'Distribuição de Spin',
    spinDistributions: 'Distribuições de Spin',
    lSpinDistribution: 'distribuição de spin',
    winningsTable: 'Tabela de Ganhos',
    countOfSpins: 'Contagem de Spins',
    period: 'Período',
    spinProvisionPeriodInfo: 'Permite definir o período em que as rotações da máquina serão fornecidas. O período será calculado após gastar/girar o giro da máquina',
    spinExpirationPeriod: 'Permite definir o período em que as rotações da máquina serão expiradas',
    viewingOptions: 'Opções de Visualização',
    emptySpinDistributionDesc: 'Você pode ver as Distribuições de Spin depois de adicioná-las e configurá-las no menu de navegação à direita.',
    machine: 'Máquina',
    lMachine: 'máquina',
    numberOfSections: 'Número de Seções',
    numberOfSectionsInfo: 'A Quantidade de Seções da Máquina onde os prêmios serão conectados.',
    numberOfSection: 'Número de Seção',
    sectionName: 'Nome da Seção',
    machines: 'Máquinas',
    emptyMachinesSectionDesc: 'Você pode ver as Máquinas depois de adicioná-las e configurá-las no menu de navegação à direita.',
    playingParticipants: 'Participantes do Jogo',
    emptyPlayingParticipantsDesc: 'Você pode ver as Condições de Participação após adicioná-las e configurá-las no menu de navegação à direita.',
    allPlayersDesc: 'Permite incluir todos os jogadores como participantes quando o acionador é executado',
    conditionDesc: 'Permite incluir jogadores por condições específicas de acionamento',
    lPlayingParticipant: 'participante do jogo',
    machineConfig: 'Configuração da Máquina',
    machineConfigMachinesInfo: 'Selecione a máquina para a qual a configuração será iniciada',
    machineConfigSpinDistributionsInfo: 'Selecione as regras de distribuição de spin criadas para a máquina, com base nas quais os jogadores receberão os spins da máquina',
    machineConfigPlayingParticipantsInfo: 'Selecione a condição de jogo criada, que verificará os jogadores que podem usar/ativar a máquina',
    spinDistributionSidebarInfo: 'Aqui podemos escolher regras de distribuição de spin',
    prize: 'Prêmio',
    lPrize: 'prêmio',
    campaignId: 'Id de Campanha',
    totalWinCount: 'Contagem Total de Vitórias',
    dailyMaxWinCount: 'Número Máximo de Vitórias Diárias',
    dailyTotalMaxWinCount: 'Número Total Máximo de Vitórias Diárias',
    dailyMaxWinCountInfo: 'Quantidade Total Diária de Prêmio que pode ser ganho por todos os jogadores durante o dia',
    playerMaxWinCount: 'Número Máximo de Vitórias do Jogador',
    prizeType: 'Tipo de Prêmio',
    prizeTypeBonusInfo: 'Tipos de Prêmios de Bónus podem ser criados selecionando a campanha e configurando seus bônus',
    bonusCampaignInfo: 'Selecione a campanha multiplataforma para obter os bônus do prêmio',
    prizeAndProbabilitySection: 'Secção de Prémio e Probabilidade',
    prizeAndProbabilitySectionInfo: 'Selecione a máquina para começar a configurar as seções e probabilidades da máquina.',
    probabilityPercent: 'Probabilidade %',
    defaultPrize: 'Prêmio Padrão',
    setDefaultPrize: 'Definir Prémio Padrão',
    advancedProbability: 'Probabilidade Avançada',
    advancedProbabilityInfo: 'A seção será habilitada se as seções da máquina forem definidas com prémios e probabilidades.',
    machineConfigs: 'Configuração da Máquina',
    lMachineConfig: 'configuração da máquina',
    emptyMachineConfigsDesc: 'Você pode ver as Máquinas configuradas após adicioná-las e configurá-las no menu de navegação à direita.',
    lGroupLimit: 'limite de grupo ',
    groupLimit: 'Limite de Grupo',
    emptyGroupLimitDesc: 'Você pode ver os Limites após adicioná-los e configurá-los no menu de navegação à direita.',
    groupLimitDesc: 'Aqui podemos escolher os grupos e fornecer limitações',
    groupLimitGeneralInfo:
      'As limitações podem ser definidas configurando e conectando prêmios com grupos. A seção de probabilidade avançada será redefinida, se o prêmio for adicionado ou excluído da seção de probabilidade padrão.',
    groupLimitAdvancedTagsInfo: 'Nesta área, definimos a limitação para o grupo selecionado',
    groupLimitAdvancedInfo: 'As limitações por tags podem ser definidas e caso o jogador possua uma das tags selecionadas, será considerada a limitação de tags.',
    groupLimitAdvancedPriorityInfo:
      'Cada tag Vertical selecionada deve ter sua própria prioridade. A maior prioridade começa em 1. Se o jogador tiver várias tags, que são definidas em limites, a tag de maior prioridade deve ser considerada.',
    prizes: 'Prêmios',
    groupType: 'Tipo de Grupo',
    advanced: 'Avançado',
    standard: 'Padrão',
    emptyPrizesDesc: 'Você pode ver os Prémios depois de adicioná-los e configurá-los no menu de navegação à direita.',
    verifyConditionDesc: 'Verifica o nível de verificação dos jogadores',
    content: 'Conteúdo',
    contentMachineInfo: 'Selecione a máquina para configurar o conteúdo dela.',
    unAuthorizedSubtitle: 'Legenda não autorizada',
    authorizedSubtitle: 'Legenda autorizada',
    websiteMachineBackground: 'Plano de fundo da máquina do site',
    mobileMachineBackground: 'Plano de fundo da Máquina móvel',
    authorizedSubtitleInfo: 'Defina a legenda que será exibida para jogadores autorizados',
    unAuthorizedSubtitleInfo: 'Defina a legenda que será exibida para jogadores não autorizados',
    sectionSubtitle: 'Subtítulo da Seção',
    contentSectionInfo: 'Nesta área, definimos o conteúdo das seções da máquina. O conteúdo da seção também pode ser definido por moeda.',
    contentSelectSectionsInfo: 'Selecione a máquina para obter seções',
    contentPrizeInfo: 'Nesta área, definimos o conteúdo e as traduções dos prêmios que ficarão visíveis do lado do jogador.',
    termsAndConditions: 'Termos e Condições',
    sectionContent: 'Conteúdo das Seções',
    sectionTitle: 'Título das Seções',
    probabilities: 'Probabilidades',
    isUsedInMachineConfig: 'Não é possível excluir porque é usado na configuração da máquina',
    emptyContentDesc: 'Você pode ver o Conteúdo depois de adicioná-lo e configurá-lo no menu de navegação à direita.',
    previewName: 'Nome da Visualização',
    connectPrizesAndDefineProbabilities:
      'Nesta área, conectamos os prêmios às seções da máquina e definimos probabilidades para isso. Pode ser definida a contagem máxima do'
      + ' prêmio que pode ser ganho durante o dia, com base nas probabilidades definidas. O prêmio padrão deve ser selecionado, que será um prêmio ilimitado para a máquina e será fornecido se uma das limitações de prêmio for atendida.',
    advancedLimits: 'Limites avançados',
    choosePrize: 'Escolher o Prêmio',
    wheelSectionsAndProbabilities: 'Seções e probabilidades da roda',
    configureTagsAndProbabilities:
      'Nesta área, configuramos as tags verticais e as probabilidades por prémio. Tags com prioridade mais alta devem ser consideradas em primeiro lugar, caso o jogador tenha várias tags. O prémio padrão deve ser selecionado para cada tag, que será fornecido se uma das limitações de prémio for atendida.',
    defaultLanguageIs: 'O idioma padrão da marca é {{language}}',
    bonusConfigurations: 'Configurações de Bônus',
    setLimitationsForSelectedGroup: 'Nesta área, definimos a limitação para o grupo selecionado. Cada prémio pode ser conectado a um grupo.',
    noPrizesForSection: 'Adicionar prémio na seção Prémios, que pode ser conectado ao grupo e definir a limitação',
    spinDistributionPeriodInfo: 'Período de tempo em horas, após o qual os spins serão distribuídos',
    countOfSpinsInfo: 'A quantidade de spins que devem ser distribuídas aos jogadores',
    expirationPeriodInfo: 'Período de tempo em horas, após o qual os spins fornecidos serão expirados e não poderão ser usados',
    tryAgainInfo: 'Sem Vitória',
    machineName: 'Nome da Máquina',
    machineType: 'Tipo da Máquina',
    machineTypes: 'Tipos de Máquinas',
    prizeName: 'Nome do Prémio',
    prizeTypes: 'Tipos de Prémio',
    spinDate: 'Data de Spin',
    totalUsage: 'Uso Total',
    itemName: 'Nome do Item',
    totalDistributedSpins: 'Distribuição Total de Spins',
    totalUsedSpins: 'Total de Spins Utilizados',
    remainingSpins: 'Spins Restantes',
    lastSpinDate: 'Data do Último Spin',
    limitPerPlayer: 'Limite Por Jogador',
    promotionTool: 'Gamificação',
    promotions: 'Gamificação',
    promotion: 'Gamificação',
    slPromotion: 'gamificação',
    promotionFund: 'Fundo de gamificação',
    winningTableDesc: 'Aqui podemos controlar a ativação ou desativação da Tabela de Ganhos na Página de gamificação.',
    myWinningsDesc: 'Aqui podemos controlar a ativação ou desativação da Minha Tabela de Ganhos na Página de Gamificação.',
    promotionFunds: 'Fundos de Gamificação',
    machineConfigDesc: 'Aqui podemos escolher diferentes máquinas e configurá-las',
    machineSidebarInfo: 'Aqui podemos escolher a máquina e adicioná-la',
    totalWinCountInfo: 'Quantidade total de prémios que podem ser ganhos durante o período ativo da gamificação',
    playerMaxWinCountInfo: 'Quantidade total de prémios que podem ser ganhos por um jogador durante a gamificação',
    lPromotion: 'gamificação',
    prizesSidebarInfo: 'Aqui podemos escolher diferentes prémios e adicioná-los',
    playingParticipantsSidebarInfo: 'Aqui podemos escolher as condições de seleção dos participantes.',
    contentTermsAndConditionsInfo: 'Nesta área, definimos os termos e condições para participar na gamificação.',
    contentSidebarInfo: 'Aqui podemos definir o conteúdo e os ícones da gamificação, as máquinas e as distribuições de giros',
    promotionName: 'Gamificação',
    navigationItemsDisabledInfo: 'Os itens de navegação ficarão activos depois de guardar a secção Geral de gamificação.',
    manualSpinsPlayers: 'Jogadores de Spins Manuais',
    provisionType: 'Tipo de provisão',
    spins: 'Spins',
    promotionUrl: 'URL de gamificação',
    promotionUrlHint: 'Aqui podemos definir o URL de Gamificação que será usado para navegar para a página de Gamificação. O caminho relativo /promo/URL será utilizado no Builder',
    websiteBackground: 'Fundo do Website',
    mobileBackground: 'Fundo do Telemóvel',
    pageBackground: 'Fundo da Página',
    webSiteFit: 'Tipo de ajuste do site',
    mobileFit: 'Tipo de ajuste do telemóvel',
    machineContents: 'Conteúdos da máquina',
    promotionContents: 'Conteúdos de gamificação',
    emptyPromotionContentDesc: 'Pode ver os Conteúdos de gamificação depois de os adicionar e configurar a partir do menu de navegação do lado direito.',
    promotionTransactions: 'Transacções de Gamificação',
    promotionPrizes: 'Prémios de Gamificação',
    promotionMachines: 'Máquinas de Gamificação',
    promotionPlayers: 'Jogadores de Gamificação',
    spinDepositDesc: 'Permite fornecer activações a partir de depósitos feitos pelo jogador',
    depositDistributionNumberOfDesc: 'O utilizador pode escolher por quantos depósitos os jogadores receberão as activações da máquina',
    depositDistributionEveryDesc: 'Este campo permite definir o número máximo de accionamentos bem sucedidos por jogador',
    periodEveryInfo: 'Permite definir o período para o qual os depósitos devem ser considerados',
    periodEveryMaxTriggerCountInfo: 'Este campo permite definir o número máximo de accionamentos bem sucedidos por jogador',
    depositExpirationPeriodInfo: 'Permite definir o período em que as rodadas da máquina expiram',
    minimumAmount: 'Montante Mínimo',
    numberOfDepositsInfo: 'Selecionar os depósitos para os quais os jogadores devem receber os giros da máquina no período especificado',
    maxIssuanceCount: 'Contagem Máxima de Emissões',
    amountType: 'Tipo de Montante',
    distributionOption: 'Opção de distribuição',
    distributionType: 'Tipo de distribuição',
    sidebarConfigDependenciesInfo:
      'As secções Limite de Grupo, Configuração da Máquina e Conteúdo ficarão activas depois de completar as secções Distribuição de Rodadas, Participantes de Jogo, Prémio e Máquina.',
    sidebarMissingMainLanguageContentInfo: 'A gamificação, o conteúdo da máquina e o conteúdo da distribuição de rodadas devem ser fornecidos para o idioma padrão da marca.',
    privateModeParticipantsConfigInfo: 'A lista fornecida pode ser afetada após a seleção das moedas de gamificação.',
    playersAbleToSee: 'Os jogadores podem ver a página de gamificação',
    noPromotionsAvailableForPlayer: 'Não há gamificação disponível para o jogador ',
    heightAndWidth: 'Altura e Largura',
    heightAndWidthInfo: 'Seleccione a Altura e a Largura para criar uma máquina com as dimensões específicas',
    dimensions: 'Dimensões',
    gridMachineInfo: 'Aqui pode definir os nomes das células da máquina de grelha para futuras ligações de prémios',
    gridSectionsAndProbabilities: 'Secções da rede e probabilidades',
    prizeTypeMaterialInfo: 'Os prémios de tipo de material podem ser criados configurando o seu nome e o montante equivalente',
    prizeItemConfigurations: 'Configurações do item do prémio',
    wonDate: 'Data de Ganho',
    item: 'Item',
    daysInfo: 'Permite definir os dias durante os quais o jogador receberá as rodadas',
    resetProgress: 'Reiniciar progresso',
    resetProgressInfo: 'Quando ativado, o progresso do jogador é reiniciado se as rodadas de dias consecutivos não forem recolhidas/reclamadas',
    spinCollectDesc: 'Permite fornecer activações a partir da ação de recolha do jogador.',
    joiningOptions: 'Opções de adesão ',
    onJoinInfo: 'A gamificação requer a adesão para poder participar',
    maxJoinedPlayers: 'Máximo de jogadores registados',
    maxJoinedPlayersInfo: 'Aqui podemos definir o limite de jogadores que podem aderir à gamificação.',
    countDown: 'Contagem decrescente',
    countDownInfo: 'Aqui, podemos ativar a contagem decrescente, que mostrará o tempo que falta para o início da Gamificação.',
    joinStartDate: 'Data de início da adesão',
    joinEndDate: 'Data de fim da adesão',
    operationStartDate: 'Data de início da operação',
    operationEndDate: 'Data de fim da operação',
    joinDateInfo: 'Aqui podemos definir a data em que os jogadores podem aderir à gamificação.',
    spinBetDesc: 'Os jogadores receberão activações gratuitas para todas as apostas no período de ativação\n',
    subTitle: 'Subtítulo',
    spinDistributionContents: 'Conteúdo da distribuição de Spins',
    emptySpinDistributionContentDesc: 'Pode ver os Conteúdos de Distribuição de Spins depois de os adicionar e configurar no menu de navegação do lado direito.',
    totalDistributedCount: 'Contagem total distribuída',
    receivedSpins: 'Spins recebidos',
    spinStatistics: 'Estatísticas de Spins',
    buyActivationInfo:
      'O montante por uma ativação deve ser definido para todas as moedas. Os jogadores podem comprar a ativação com base na quantidade que será calculada multiplicando a contagem e o montante.',
    discountPercent: 'Desconto, %',
    walletToBuy: 'Carteira para comprar',
    buyActivationCountOfSpinsInfo: 'O número no campo "De" da "Contagem de Rodadas" está incluído nesse intervalo, enquanto o número no campo "Até" não está incluído no intervalo.',
    discount: 'Desconto',
    onDiscount: 'Com desconto',
    buyActivationDesc: 'Permite que os jogadores comprem as activações.',
    walletToBuyActivationInfo: 'Aqui podemos definir a partir de que carteira o jogador pode comprar a ativação.',
    gamificationSpinStatistics: 'Estatísticas de spins de gamificação',
    machineInfo: 'Organiza as máquinas com uma interface de arrastar e largar. A primeira máquina torna-se o item principal no site, seguido de outras na sequência escolhida.',
    websiteBackgroundPosition: 'Tipo de posição de fundo do sítio Web',
    mobileBackgroundPosition: 'Tipo de posição de fundo no telemóvel',
    activationOnDepositNumberOfDesc: 'O utilizador pode escolher para quantos depósitos no período de ativação os jogadores recebem spins',
    activationOnDepositEveryDesc: 'Os jogadores receberão spins por todos os depósitos efectuados no período de ativação',
    activationOnDepositCumulativeDesc: 'Os jogadores receberão spins pelo total de depósitos efectuados num período de tempo específico',
    spinDistributionCumulativeInfo:
      'A soma dos montantes de depósito do jogador deve ser comparada com os montantes definidos. Ao satisfazer um ou vários montantes, as rodadas definidas serão fornecidas ao jogador.',
    spinDistributionCumulativeCheckPointsInfo: 'Os jogadores ganham spins com base no montante total de depósitos efectuados num período específico ou ao atingirem determinados pontos de controlo.',
    points: 'Pontos',
    addPoints: 'Adicionar pontos',
    viewPoints: 'Ver pontos',
    resetPoints: 'Redefinir pontos',
    spinDistributionPaymentMethodsInfo: 'Permite definir métodos de pagamento de depósitos e sub-métodos. Se o método de pagamento não for definido, serão considerados todos os métodos de pagamento.',
    exchangeCenter: 'Centro de câmbio',
    exchangeCenterDesc: 'qui podemos definir as taxas de câmbio entre as máquinas',
    exchangeItemInfo: 'Definir um coeficiente de máquina para permitir uma troca de spins entre máquinas',
    coefficient: 'Coeficiente',
    exchange: 'Câmbio',
    lExchange: 'câmbio',
    emptyExchangeCenterDesc: 'Pode ver os câmbios depois de os adicionar e configurar no menu de navegação do lado direito.',
    gamificationSpinsReport: 'Relatório de spins de gamificação',
    prizeTypeMachineSpinInfo: '0',
    machineSpinStatistics: 'Estatísticas de Spins da Máquina',
    prizeTypeMachineSpinCountInfo: 'Permite definir a contagem de rotações que deve ser fornecida',
    autoSpinAndTurboSpin: 'Autospin/Turbospin',
    autoSpinAndTurboSpinDesc: 'Aqui podemos controlar a ativação ou desativação da opção Autospin/Turbospin, através da qual os jogadores podem utilizar o seu Spin e ativar as máquinas nestes modos.',
    isUsedInExchangeCenter: 'Não é possível eliminar porque é utilizado no centro de intercâmbio',
    materialStatistics: 'Estatísticas de materiais',
  },
  zh: {
    freeActivationDesc: '允许在指定时间内提供免费激活服务',
    spinDistribution: '旋转分布',
    spinDistributions: '旋转分布',
    lSpinDistribution: '旋转分布',
    winningsTable: '赢利表',
    winningTableDesc: '这里可以控制在促销页面上启用或禁用赢利表。',
    myWinningsDesc: '这里可以控制在促销页面上启用或禁用我的赢利表。',
    countOfSpins: '旋转次数',
    period: '期间',
    spinProvisionPeriodInfo: '允许定义提供机器旋转的时间段。该周期将在花费/旋转机器后计算',
    spinExpirationPeriod: '允许定义机器旋转过期的时间。',
    viewingOptions: '查看选项',
    emptySpinDistributionDesc: '您可以从右侧的导航菜单中看到添加和配置后的旋转分布。',
    machine: '博弈机',
    lMachine: '博弈机',
    numberOfSections: '节数',
    numberOfSectionsInfo: '奖金将被连接的博弈机部分的数量。',
    numberOfSection: '节数',
    sectionName: '节名称',
    machines: '博弈机',
    emptyMachinesSectionDesc: '您可以从右侧的导航菜单中看到添加和配置后的博弈机',
    playingParticipants: '游戏参与者',
    emptyPlayingParticipantsDesc: '您可以从右侧的导航菜单中看到添加和配置后的参与条件',
    allPlayersDesc: '允许在执行触发器时将所有玩家作为参与者。',
    conditionDesc: '允许通过触发特定条件纳入玩家',
    lPlayingParticipant: '游戏参与者',
    machineConfig: '博弈机配置',
    machineConfigDesc: '这里可以选择不同的博弈机，并配置它们进行推广',
    machineSidebarInfo: '这里可以选择博弈机并将其添加到促销活动中。',
    machineConfigMachinesInfo: '选择将开始配置的博弈机',
    machineConfigSpinDistributionsInfo: '选择为博弈机创建的旋转分配规则，根据这些规则，玩家将获得博弈机的旋转。',
    machineConfigPlayingParticipantsInfo: '选择创建的游戏条件，这将检查可以使用/激活博弈机的玩家。',
    spinDistributionSidebarInfo: '这里可以选择旋转分布规则',
    prize: '奖金',
    lPrize: '奖金',
    campaignId: '活动ID',
    totalWinCount: '总赢家数',
    totalWinCountInfo: '在促销活动期间，可获得的奖项总数',
    dailyMaxWinCount: '每日最大赢家数',
    dailyTotalMaxWinCount: '每日最大赢家总数',
    dailyMaxWinCountInfo: '所有玩家在一天内可获得的每日总奖金数量',
    playerMaxWinCount: '玩家最大赢钱数',
    playerMaxWinCountInfo: '一个玩家在促销活动中可获得的总奖金数量',
    prizeType: '奖品类型',
    prizeTypeBonusInfo: '奖金类型奖金可以通过选择活动并配置其奖金来创建',
    bonusCampaignInfo: '请选择跨平台活动，获得奖金的奖励',
    prizeAndProbabilitySection: '奖金部分和概率',
    prizeAndProbabilitySectionInfo: '请选择博弈机，开始配置博弈机部分和概率',
    probabilityPercent: 'Probability %',
    defaultPrize: '默认奖励',
    setDefaultPrize: '设置默认奖励',
    advancedProbability: '高级概率',
    advancedProbabilityInfo: '如果博弈机部分定义了奖金和概率，该部分将被启用。',
    machineConfigs: '博弈机配置',
    lMachineConfig: '博弈机配置',
    emptyMachineConfigsDesc: '添加和配置后，您可以从右侧导航菜单中看到已配置的博弈机。',
    lGroupLimit: '组别限制',
    groupLimit: '组别限制',
    emptyGroupLimitDesc: '您可以从右侧的导航菜单中看到添加和配置后的极限值',
    groupLimitDesc: '这里可以选择组别并提供限制',
    groupLimitGeneralInfo: '可以通过配置奖品并将其与组连接来定义限制。如果奖品从标准概率部分添加或删除，则高级概率部分将重置。',
    groupLimitAdvancedTagsInfo: '在这个领域，可以定义了对所选组的限制',
    groupLimitAdvancedInfo: '每个标签的限制可以定义，如果玩家有一个选定的标签，则会考虑标签限制。',
    groupLimitAdvancedPriorityInfo: '每个选定的垂直标签都应该有自己的优先权。最高的优先级从1开始。如果选手有多个标签，这些标签在限制中被定义，应该考虑最高优先级的标签。',
    prizes: '奖金',
    groupType: '组别类型',
    advanced: '高级',
    standard: '标准',
    emptyPrizesDesc: '您可以从右侧的导航菜单中看到添加和配置后的奖品',
    prizesSidebarInfo: '这里可以选择不同的奖品，并将它们添加到促销活动中。',
    playingParticipantsSidebarInfo: '这里可以选择促销参与者的选择条件。',
    verifyConditionDesc: '请检查玩家验证级别\n\n',
    content: '内容',
    contentMachineInfo: '选择博弈机，为其配置内容。',
    unAuthorizedSubtitle: '未经授权的副标题',
    authorizedSubtitle: '未经授权的副标题',
    websiteMachineBackground: '网站博弈机背景',
    mobileMachineBackground: '移动博弈机背景',
    authorizedSubtitleInfo: '请定义将显示的字幕以授权玩家',
    unAuthorizedSubtitleInfo: '请定义将显示给未经授权的玩家的字幕',
    sectionSubtitle: ' 节的字幕',
    contentSectionInfo: '这个领域定义了机器部分的内容。部分内容也可以按货币定义。',
    contentSelectSectionsInfo: '请选择博弈机以获得节',
    contentPrizeInfo: '这个区域定义了奖品的内容和翻译，这些内容和翻译将在玩家一侧可见。\n',
    termsAndConditions: '条款和条件',
    contentTermsAndConditionsInfo: '这个领域定义了参与推广的条款和条件',
    contentSidebarInfo: '在这里，我们可以定义游戏化的内容和图标、机器和旋转分配',
    sectionContent: '章节内容',
    sectionTitle: '章节标题',
    probabilities: '概率',
    isUsedInMachineConfig: '无法删除，因为它已在博弈机配置中使用',
    emptyContentDesc: '添加和配置目录后，您可以从右侧导航菜单中看到它们。',
    previewName: '预览名称',
    navigationItemsDisabledInfo: '在保存促销活动的常规部分后，导航项目将变得有效。',
    connectPrizesAndDefineProbabilities:
      '这里可以将奖品与博弈机部分联系起来，并为它定义概率。根据设定的概率，可以定义一天内可以赢得的最大奖项数。应该选择默认的奖品，这将是博弈机的无限制奖品，如果满足其中一个奖品限制，就会提供。',
    advancedLimits: '高级限制',
    choosePrize: '选择奖项',
    wheelSectionsAndProbabilities: '轮盘章节和概率',
    configureTagsAndProbabilities: '这里可以配置垂直标签和每个奖品的概率。如果玩家有多个标签，则应首先考虑优先级较高的标签。应为每个标签选择默认奖品，如果满足其中一个奖品限制，则将提供该奖品。',
    defaultLanguageIs: '付款方式的默认语言是{{language}}。',
    bonusConfigurations: '奖金配置',
    setLimitationsForSelectedGroup: '这个区域里可以定义了对所选组的限制。每个奖项可以连接到一个组。',
    noPrizesForSection: '在奖品部分添加奖品，可以连接到组并定义限制。',
    spinDistributionPeriodInfo: '以小时为单位的时间段，在这之后将分配转盘。',
    countOfSpinsInfo: '应分配给玩家的旋转次数',
    expirationPeriodInfo: '以小时为单位的时间段，过了这个时间段，所提供的转盘就会过期，不能再使用。',
    tryAgainInfo: '没有赢家',
    machineName: '博弈机名称',
    machineType: '博弈机类型',
    machineTypes: '博弈机类型',
    prizeName: '奖金名称',
    prizeTypes: '奖金类型',
    spinDate: '旋转日期',
    totalUsage: '总使用量',
    itemName: '项目名称',
    totalDistributedSpins: '分布式旋转总数',
    totalUsedSpins: '已使用的转盘总数',
    remainingSpins: '剩余旋转',
    lastSpinDate: '最后一次旋转日期',
    limitPerPlayer: '每个玩家的限额',
    promotionTool: '游戏化',
    promotions: '游戏化',
    promotion: '游戏化',
    slPromotion: '游戏化',
    promotionFund: '游戏化基金',
    promotionFunds: '游戏化基金',
    lPromotion: '游戏化',
    promotionName: '游戏化',
    provisionType: '供应类型',
    spins: '旋转',
    promotionUrl: '游戏化URL',
    promotionUrlHint: '在这里，我们可以定义用于导航到“游戏化”页面的游戏化URL。/promo/URL相对路径将在生成器中使用',
    websiteBackground: '网站背景',
    mobileBackground: '移动背景',
    pageBackground: '页面背景',
    webSiteFit: '网站适合类型',
    mobileFit: '移动适配类型',
    machineContents: '机器内容',
    promotionContents: '游戏化内容',
    emptyPromotionContentDesc: '添加和配置游戏化内容后，您可以在右侧导航菜单中看到这些内容。',
    promotionTransactions: '游戏化交易',
    promotionPrizes: '游戏化奖项',
    promotionMachines: '游戏化机器',
    promotionPlayers: '游戏化玩家',
    spinDepositDesc: '允许通过玩家存款提供激活服务',
    depositDistributionNumberOfDesc: '用户可选择存款多少次才能激活机器',
    depositDistributionEveryDesc: '该字段允许设置每个玩家的最大成功触发次数',
    periodEveryInfo: '允许定义应考虑存款的期限',
    periodEveryMaxTriggerCountInfo: '该字段允许设置每个玩家的最大成功触发次数',
    depositExpirationPeriodInfo: '允许定义机器旋转过期的时间段',
    minimumAmount: '最小数额',
    numberOfDepositsInfo: '选择玩家在指定时间内必须获得机器旋转币的存款额',
    maxIssuanceCount: '最大发行量',
    amountType: '金额类型',
    distributionOption: '分配选项',
    distributionType: '分配类型',
    sidebarConfigDependenciesInfo: '完成"旋转分配"、"参加游戏者"、"奖品"和"机器"部分后，"组限制"、"机器配置"和"内容"部分将被激活。',
    sidebarMissingMainLanguageContentInfo: '应为品牌的默认语言提供游戏化、机器内容和旋转分配内容。',
    privateModeParticipantsConfigInfo: '选择游戏化货币后，所提供的名单会受到影响。',
    playersAbleToSee: '玩家可以查看"游戏化"页面',
    noPromotionsAvailableForPlayer: '玩家没有游戏化功能',
    heightAndWidth: '高度和宽度',
    heightAndWidthInfo: '选择高度和宽度，创建具有特定尺寸的机器',
    dimensions: '尺寸',
    gridMachineInfo: '您可以在此定义网格机器单元名称，以便将来连接奖品',
    gridSectionsAndProbabilities: '网格部分和概率',
    prizeTypeMaterialInfo: '材料类型奖品可通过配置名称和等值金额来创建',
    prizeItemConfigurations: '奖品项目配置',
    equivalentAmount: '等值金额',
    wonDate: '获奖日期',
    item: '项目',
    daysInfo: '允许定义玩家获得旋转奖励的天数',
    resetProgress: '重置进度',
    resetProgressInfo: '启用后，如果连续几天的旋转币无人领取/认领，玩家进度会重置',
    spinCollectDesc: '允许通过玩家的收集操作进行激活。',
    joiningOptions: '加入选项',
    onJoinInfo: '游戏化需要加入才能参与',
    maxJoinedPlayers: '最大加入玩家数',
    maxJoinedPlayersInfo: '在这里，我们可以设置参加游戏化的玩家人数限制。',
    countDown: '倒计时',
    countDownInfo: '在这里，我们可以启用倒计时，它将显示游戏化开始前的剩余时间。',
    joinStartDate: '加入开始日期',
    joinEndDate: '加入结束日期',
    operationStartDate: '运行开始日期',
    operationEndDate: '运行结束日期',
    joinDateInfo: '在这里，我们可以设置玩家可以加入游戏化的日期。',
    spinBetDesc: '玩家在触发期\n的所有投注都将获得免费激活',
    subTitle: '副标题',
    spinDistributionContents: '旋转分配内容',
    emptySpinDistributionContentDesc: '您可以从右侧导航菜单中看到添加和配置后的旋转分配内容。',
    totalDistributedCount: '分发总数',
    receivedSpins: '已收到的旋转',
    spinStatistics: '旋转统计',
    buyActivationInfo: '应为所有货币定义每次激活的金额。玩家可以根据数量购买激活，数量将通过次数和金额相乘来计算。',
    discountPercent: '折扣，%',
    walletToBuy: '钱包购买',
    buyActivationCountOfSpinsInfo: '“旋转次数”的“从”字段中的数字包含在该范围内，而“至”字段中的数字不包含在该范围内。',
    discount: '折扣',
    onDiscount: '关于折扣',
    buyActivationDesc: '允许玩家购买激活。',
    walletToBuyActivationInfo: '在这里，我们可以定义玩家可以从哪个钱包购买激活。',
    gamificationSpinStatistics: '游戏化旋转统计',
    machineInfo: '使用拖放界面排列机器。第一台机器将成为网站上的主要项目，随后其他机器将按照您选择的顺序排列。',
    websiteBackgroundPosition: '网站背景位置类型',
    mobileBackgroundPosition: '移动背景位置类型',
    activationOnDepositNumberOfDesc: '用户可选择在触发期内存入多少次钱才能获得旋转奖励',
    activationOnDepositEveryDesc: '玩家在触发期内的所有存款均可获得旋转',
    activationOnDepositCumulativeDesc: '玩家可根据特定时间段内的存款总额获得旋转',
    spinDistributionCumulativeInfo: '玩家存款金额的总和应与定义的金额进行比较。如果满足一个或多个金额，玩家将获得规定的旋转。',
    spinDistributionCumulativeCheckPointsInfo: '玩家可以根据特定时间内的存款总额或达到设定的检查点来赚取旋转。',
    points: '积分',
    addPoints: '增加积分',
    viewPoints: '查看积分',
    resetPoints: '重置积分',
    spinDistributionPaymentMethodsInfo: '允许设置存款支付方式和子支付方式。如果未设置付款方式，则将考虑所有支付方式。',
    exchangeCenter: '交换中心',
    exchangeCenterDesc: '在这里，我们可以定义机器之间的汇率',
    exchangeItemInfo: '定义机器系数，以实现不同机器之间的旋转交换',
    coefficient: '系数',
    exchange: '交换',
    lExchange: '交换',
    emptyExchangeCenterDesc: '您可以在右侧导航菜单中看到添加和配置后的“交换”。',
    gamificationSpinsReport: '游戏化旋转报告',
    prizeTypeMachineSpinInfo: '机器旋转类型奖品可以通过配置名称和提供旋转次数来创建。将为连接的机器提供机器旋转奖品。',
    machineSpinStatistics: '机器旋转统计',
    prizeTypeMachineSpinCountInfo: '允许定义应提供的旋转次数',
    autoSpinAndTurboSpin: '自动旋转/加速旋转',
    autoSpinAndTurboSpinDesc: '在这里，我们可以控制自动旋转/加速旋转选项的启用或禁用，玩家可以在这些模式下使用旋转并激活机器。',
    isUsedInExchangeCenter: '无法删除，因为它在交换中心被使用',
    materialStatistics: '材料统计',
  },
  ro: {
    freeActivationDesc: 'Permite furnizarea activării gratuite pentru o perioadă de timp specificată',
    spinDistribution: 'Distribuția rotirilor',
    spinDistributions: 'Distribuții de rotiri',
    lSpinDistribution: 'distribuția rotirilor',
    winningsTable: 'Tabelul de câștiguri',
    countOfSpins: 'Numărul rotirilor',
    period: 'Perioadă',
    spinProvisionPeriodInfo: 'Permite definirea perioadei în timpul căreia vor fi oferite rotirile. Perioada va fi calculată după cheltuirea/învârtirea rotirilor\n',
    spinExpirationPeriod: 'Permite determinarea perioadei de sfârșit a rotirilor',
    viewingOptions: 'Opțiuni de vizualizare',
    emptySpinDistributionDesc: 'Poți vedea distribuțiile rotirilor după adăugarea și configurarea acestora din meniul de navigare din partea dreaptă.',
    machine: 'Aparate de joc',
    lMachine: 'Mășinărie de joc',
    numberOfSections: 'Numărul secțiunii',
    numberOfSectionsInfo: 'Cantitatea secțiunii de aparate, unde vor fi conectate premiile.',
    numberOfSection: 'Numărul secțiunii',
    sectionName: 'Numele secțiunii',
    machines: 'Mășinării de joc',
    emptyMachinesSectionDesc: 'Poți vedea aparatele de joc după adăugarea și configurarea lor din meniul de navigare din partea dreaptă.',
    playingParticipants: 'Participanții la joc',
    emptyPlayingParticipantsDesc: 'Poți vedea Condițiile de Participare după adăugarea și configurarea lor din meniul de navigare din partea dreaptă.',
    allPlayersDesc: 'Atunci când declanșatorul este executat, permite tuturor jucătorilor să fie incluși în lista de participanți',
    conditionDesc: 'Permite includerea participanților prin declanșarea unor condiții specifice',
    lPlayingParticipant: 'Participant la joc',
    machineConfig: 'Configurații ale aparatului de joc',
    machineConfigMachinesInfo: 'Selectează mașinăria pentru care va începe configurarea',
    machineConfigSpinDistributionsInfo: 'Selectează regulile de distribuire a rotirilor, create pentru mașinărie, pe baza cărora jucătorii vor primi rotiri.',
    machineConfigPlayingParticipantsInfo: 'Selectează condiția de joc creată, ce va verifica (determina) jucătorii care pot utiliza/activa aparatul slot',
    spinDistributionSidebarInfo: 'Aici putem alege reguli de distribuție a rotirilor',
    prize: 'Premiu',
    lPrize: 'premiu',
    campaignId: 'ID-ul de campanie',
    totalWinCount: 'Numărul total de câștiguri',
    dailyMaxWinCount: 'Numărul maxim de câștiguri zilnice',
    dailyTotalMaxWinCount: 'Numărul total maxim de câștiguri pe zi',
    dailyMaxWinCountInfo: 'Cantitatea totală zilnică de premii care pot fi câștigate de toți jucătorii în timpul zilei',
    playerMaxWinCount: 'Numărul maxim de câștiguri ale jucătorului',
    prizeType: 'Tipul de premiu',
    prizeTypeBonusInfo: 'Premiile de tip Bonus pot fi create prin selectarea campaniei și prin configurarea bonusurilor sale',
    bonusCampaignInfo: 'Selectează campania multi-platformă pentru a primi bonusurile de premiere / la premiu',
    prizeAndProbabilitySection: 'Secția premiului și probabilitatea',
    prizeAndProbabilitySectionInfo: 'Selectează mașinăria pentru a începe configurarea secțiunilor și probabilităților aparatului ',
    probabilityPercent: '%-ul de probabilitate',
    defaultPrize: 'Premiul implicit',
    setDefaultPrize: 'Setează premiul implicit',
    advancedProbability: 'Probabilitatea avansată ',
    advancedProbabilityInfo: 'Secțiunea va fi activată în cazul în care secțiunile de mașinărie sunt definite cu premii și probabilități.',
    machineConfigs: 'Configurații de aparate slot',
    lMachineConfig: 'configurații de aparate slot',
    emptyMachineConfigsDesc: 'Poți vedea aparatele configurate după adăugarea și configurarea lor din meniul de navigare din partea dreaptă.',
    lGroupLimit: 'limita de grupului',
    groupLimit: 'Limita de grupului',
    emptyGroupLimitDesc: 'Poți vedea limitele după adăugarea și configurarea lor din meniul de navigare din partea dreaptă.',
    groupLimitDesc: 'Aici se poate alege grupurile și furniza/oferi limitări',
    groupLimitGeneralInfo:
      'Limitările pot fi definite prin configurarea și conectarea premiilor cu grupuri. Secțiunea de probabilitate avansată va fi resetată în cazul în care premiul este adăugat sau șters din secțiunea de probabilitate standard.',
    groupLimitAdvancedTagsInfo: 'În această secțiune, definim limitarea pentru grupul selectat',
    groupLimitAdvancedInfo: 'Se pot defini limitările pe etichete, iar dacă jucătorul are una dintre etichetele selectate, se va lua în considerare limitarea tag-ului.',
    groupLimitAdvancedPriorityInfo:
      'Fiecare etichetă verticală selectată trebuie să aibă propria sa prioritate. Prioritatea cea mai mare începe de la 1. Dacă jucătorul are mai multe etichete, definite în limite, trebuie să se ia în considerare cea mai mare prioritate.',
    prizes: 'Premii',
    groupType: 'Tip de grup',
    advanced: 'Avansat ',
    standard: 'Standard',
    emptyPrizesDesc: 'Poți vedea premiile după adăugarea și configurarea lor din meniul de navigare din partea dreaptă.',
    verifyConditionDesc: 'Verifică nivelul de verificare a jucătorilor',
    content: 'Conținut',
    contentMachineInfo: 'Selectează aparatul pentru a configura conținutul pentru acesta.',
    unAuthorizedSubtitle: 'Subtitlu neautorizat',
    authorizedSubtitle: 'Subtitlu autorizat',
    websiteMachineBackground: 'Fundalul de site-ul web al mașinăriei slot',
    mobileMachineBackground: 'Versiunea fundalului pentru mobil al mașinăriei slot',
    authorizedSubtitleInfo: 'Definește subtitlul care va fi afișat jucătorilor autorizați',
    unAuthorizedSubtitleInfo: 'Definește subtitrarea care va fi afișată jucătorilor neautorizați',
    sectionSubtitle: 'Subtitlul secțiunii',
    contentSectionInfo: 'Aici se definește conținutul secțiunilor aparatului. Conținutul secțiunii poate fi definit, de asemenea, pentru fiecare valută în parte.',
    contentSelectSectionsInfo: 'Selectează aparatul pentru a primi secțiuni',
    contentPrizeInfo: 'În această parte se poate defini conținutul și traducerile pentru premiile care vor fi vizibile pe partea jucătorului.',
    termsAndConditions: 'Termeni și Condiții',
    sectionContent: 'Conținutul secțiunii',
    sectionTitle: 'Titlul secțiunii',
    probabilities: 'Probabilități ',
    isUsedInMachineConfig: 'Nu se poate șterge, pentru că este utilizat în configurația mașinăriei',
    emptyContentDesc: 'Poți vedea conținutul după adăugarea și configurarea acestora din meniul de navigare din partea dreapta.',
    previewName: 'Denumirea previzualizării',
    connectPrizesAndDefineProbabilities:
      'În această parte se poate conecta premiile la secțiunile mașinăriei și defini probabilități pentru aceasta. Se poate defini numărul '
      + 'maxim de premii care pot fi câștigate în timpul zilei, în funcție de probabilitățile stabilite. Trebuie selectat premiul implicit, care va fi un premiu nelimitat pentru aparat de joc și va fi oferit cu condiția îndeplinirii uneia dintre limitările premiului.',
    advancedLimits: 'Limite avansate',
    choosePrize: 'Alege premiul',
    wheelSectionsAndProbabilities: 'Secțiunile și probabilitățile roții',
    configureTagsAndProbabilities:
      'În această zonă configurăm etichetele verticale și probabilitățile pentru fiecare premiu. Dacă jucătorul are mai multe etichete,'
      + ' în primul rând trebuie să se ia în considerare etichetele cu prioritate mai mare. Pentru fiecare etichetă trebuie să fie selectat premiul implicit, care va fi furnizat în cazul în care este îndeplinită una dintre limitările premiilor.',
    defaultLanguageIs: 'Limba implicită a mărcii/metodei de plată este {{language}}',
    bonusConfigurations: 'Configurații de Bonus',
    setLimitationsForSelectedGroup: 'În această zonă definim limitarea pentru grupul selectat. Fiecare premiu poate fi conectat la un singur grup.',
    noPrizesForSection: 'Adaugă un premiu în secțiunea Premii, care poate fi conectat la un grup și definit limitarea',
    spinDistributionPeriodInfo: 'Perioada de timp în ore, după care rotirile vor fi distribuite',
    countOfSpinsInfo: 'Cantitatea rotirilor care trebuie să fie distribuite jucătorilor',
    expirationPeriodInfo: 'Perioada de timp în ore, după care rotirile oferite vor expira și nu vor mai putea fi utilizate',
    tryAgainInfo: 'Niciun câștig',
    machineName: 'Numele aparatului de slot',
    machineType: 'Tipul aparatului de slot',
    machineTypes: 'Tipurile mașinăriei slot',
    prizeName: 'Numele premiului ',
    prizeTypes: 'Tipurile de premii',
    spinDate: 'Data de rotiri',
    totalUsage: 'Utilizare totală ',
    itemName: 'Numele articolului / elementului ',
    totalDistributedSpins: 'Numărul total de rotiri deistribuite ',
    totalUsedSpins: 'Numărul total de rotiri ',
    remainingSpins: 'Rotirile rămase',
    lastSpinDate: 'Data ultimei rotiri',
    limitPerPlayer: 'Limita pe jucător',
  },
  bg: {},
  el: {
    freeActivationDesc: 'Επιτρέπει την παροχή δωρεάν ενεργοποιήσεων για την καθορισμένη χρονική περίοδο ',
    spinDistribution: 'Διανομή περιστροφών',
    spinDistributions: 'Διανομές περιστροφών',
    lSpinDistribution: 'διανομή περιστροφών',
    winningsTable: 'Πίνακας κερδών',
    winningTableDesc: 'Εδώ μπορούμε να ελέγξουμε την ενεργοποίηση ή την απενεργοποίηση του Πίνακα κερδών στη σελίδα Προωθητικής ενέργειας.',
    myWinningsDesc: 'Εδώ μπορούμε να ελέγξουμε την ενεργοποίηση ή την απενεργοποίηση του πίνακα «Τα κέρδη μου» στη σελίδα προωθητικής ενέργειας.',
    countOfSpins: 'Aριθμός περιστροφών',
    period: 'Περίοδος',
    spinProvisionPeriodInfo:
      'Επιτρέπει τον καθορισμό της περιόδου όπου θα παρέχονται οι περιστροφές του μηχανισμού. Η περίοδος θα υπολογιστεί μετά την ανάλωση/περιστροφή της περιστροφής του μηχανισμού',
    spinExpirationPeriod: 'Επιτρέπει τον καθορισμό της περιόδου όπου θα λήξουν οι περιστροφές του μηχανισμού',
    viewingOptions: 'Επιλογές προβολής',
    emptySpinDistributionDesc: 'Μπορείτε να δείτε τις Διανομές περιστροφών αφού τις προσθέσετε και τις διαμορφώσετε από το δεξί μενού πλοήγησης.',
    machine: 'Μηχανισμός',
    lMachine: 'μηχανισμός',
    numberOfSections: 'Αριθμός ενοτήτων',
    numberOfSectionsInfo: 'Η ποσότητα των Ενοτήτων του μηχανισμού όπου θα συνδεθούν τα έπαθλα.',
    numberOfSection: 'Αριθμός ενότητας',
    sectionName: 'Όνομα ενότητας',
    machines: 'Μηχανισμοί',
    emptyMachinesSectionDesc: 'Μπορείτε να δείτε τους Μηχανισμούς αφού τους προσθέσετε και τους διαμορφώσετε από το δεξί μενού πλοήγησης.',
    playingParticipants: 'Συμμετέχοντες που παίζουν',
    emptyPlayingParticipantsDesc: 'Μπορείτε να δείτε τις Προϋποθέσεις συμμετοχής αφού τις προσθέσετε και τις διαμορφώσετε από το δεξί μενού πλοήγησης.',
    allPlayersDesc: 'Επιτρέπει τη συμπερίληψη όλων των παικτών ως συμμετέχοντες όταν εκτελείται η ενεργοποίηση',
    conditionDesc: 'Επιτρέπει τη συμπερίληψη παικτών ενεργοποιώντας συγκεκριμένες προϋποθέσεις',
    lPlayingParticipant: 'συμμετέχων που παίζει',
    machineConfig: 'Διαμόρφωση μηχανισμού',
    machineConfigDesc: 'Εδώ μπορούμε να επιλέξουμε διαφορετικούς μηχανισμούς και να τους διαμορφώσουμε για την προωθητική ενέργεια',
    machineSidebarInfo: 'Εδώ μπορούμε να επιλέξουμε τον μηχανισμό και να τον προσθέσουμε στην προωθητική ενέργεια',
    machineConfigMachinesInfo: 'Επιλέξτε τον μηχανισμό για το οποίο θα ξεκινήσει η διαμόρφωση',
    machineConfigSpinDistributionsInfo: 'Επιλέξτε τους κανόνες διανομής περιστροφών που δημιουργήθηκαν για τον μηχανισμό, βάσει των οποίων οι παίκτες θα λάβουν τις περιστροφές του μηχανισμού',
    machineConfigPlayingParticipantsInfo: 'Επιλέξτε την προϋπόθεση παιχνιδιού που δημιουργήθηκε, η οποία θα ελέγξει τους παίκτες που μπορούν να χρησιμοποιήσουν/ενεργοποιήσουν τον μηχανισμό',
    spinDistributionSidebarInfo: 'Εδώ μπορούμε να επιλέξουμε τους κανόνες διανομής περιστροφών',
    prize: 'Έπαθλο',
    lPrize: 'έπαθλο',
    campaignId: 'Αναγνωριστικό καμπάνιας',
    totalWinCount: 'Συνολικός αριθμός κερδών',
    totalWinCountInfo: 'Συνολική ποσότητα επάθλων που μπορούν να κερδίσουν οι παίκτες κατά την ενεργή περίοδο της προωθητικής ενέργειας',
    dailyMaxWinCount: 'Ημερήσιος μέγ. αριθμός κερδών',
    dailyTotalMaxWinCount: 'Ημερήσιος συνολικός μέγ. αριθμός κερδών',
    dailyMaxWinCountInfo: 'Ημερήσια συνολική ποσότητα επάθλων που μπορούν να κερδίσουν όλοι οι παίκτες στη διάρκεια της ημέρας',
    playerMaxWinCount: 'Μέγ. αριθμός κερδών παίκτη',
    playerMaxWinCountInfo: 'Συνολική ποσότητα επάθλων που μπορεί να κερδίσει ένας παίκτης κατά τη διάρκεια της προωθητικής ενέργειας',
    prizeType: 'Τύπος επάθλου',
    prizeTypeBonusInfo: 'Τα έπαθλα τύπου μπόνους μπορούν να δημιουργηθούν επιλέγοντας την καμπάνια και διαμορφώνοντας τα μπόνους της',
    bonusCampaignInfo: 'Επιλέξτε την καμπάνια μεταξύ των πλατφορμών για να λάβετε τα μπόνους για το έπαθλο',
    prizeAndProbabilitySection: 'Ενότητα Επάθλου και Πιθανότητας',
    prizeAndProbabilitySectionInfo: 'Επιλέξτε τον μηχανισμό για να ξεκινήσετε τη διαμόρφωση των ενοτήτων και των πιθανοτήτων του μηχανισμού',
    probabilityPercent: 'Πιθανότητα %',
    defaultPrize: 'Προεπιλεγμένο έπαθλο',
    setDefaultPrize: 'Ορισμός προεπιλεγμένου επάθλου',
    advancedProbability: 'Προηγμένη πιθανότητα',
    advancedProbabilityInfo: 'Η ενότητα θα ενεργοποιηθεί εάν οι ενότητες του μηχανισμού καθορίζονται με έπαθλα και πιθανότητες.',
    machineConfigs: 'Διαμορφώσεις μηχανισμού',
    lMachineConfig: 'διαμόρφωση μηχανισμού',
    emptyMachineConfigsDesc: 'Μπορείτε να δείτε τους διαμορφωμένους Μηχανισμούς αφού τους προσθέσετε και τους διαμορφώσετε από το δεξί μενού πλοήγησης.',
    lGroupLimit: 'όριο ομάδας',
    groupLimit: 'Όριο ομάδας',
    emptyGroupLimitDesc: 'Μπορείτε να δείτε τα Όρια αφού τα προσθέσετε και τα διαμορφώσετε από το δεξί μενού πλοήγησης.',
    groupLimitDesc: 'Εδώ μπορούμε να επιλέξουμε τις ομάδες και παράσχουμε περιορισμούς',
    groupLimitGeneralInfo:
      'Οι περιορισμοί μπορούν να οριστούν διαμορφώνοντας και συνδέοντας έπαθλα με ομάδες. Θα γίνει επαναφορά της ενότητας προηγμένης πιθανότητας, εάν το έπαθλο προστεθεί ή διαγραφεί από την ενότητα βασικών πιθανοτήτων.',
    groupLimitAdvancedTagsInfo: 'Σε αυτή την περιοχή, καθορίζουμε τον περιορισμό για την επιλεγμένη ομάδα',
    groupLimitAdvancedInfo: 'Οι περιορισμοί ανά ετικέτες μπορούν να οριστούν και εάν ο παίκτης έχει μία από τις επιλεγμένες ετικέτες, θα ληφθεί υπόψη ο περιορισμός της ετικέτας.',
    groupLimitAdvancedPriorityInfo:
      'Κάθε επιλεγμένη Κάθετη ετικέτα θα πρέπει να έχει τη δική της προτεραιότητα. Η υψηλότερη προτεραιότητα ξεκινά από το 1. Εάν ο παίκτης έχει πολλές ετικέτες, οι οποίες ορίζονται με όρια, θα πρέπει να ληφθεί υπόψη η ετικέτα υψηλότερης προτεραιότητας.',
    prizes: 'Έπαθλα',
    groupType: 'Τύπος ομάδας',
    advanced: 'Προηγμένη',
    standard: 'Βασική',
    emptyPrizesDesc: "Μπορείτε να δείτε τα 'Επαθλα αφού τα προσθέσετε και τα διαμορφώσετε από το δεξί μενού πλοήγησης.",
    prizesSidebarInfo: 'Εδώ μπορούμε να επιλέξουμε διαφορετικά έπαθλα και να τα προσθέσουμε στην προωθητική ενέργεια',
    playingParticipantsSidebarInfo: 'Εδώ μπορούμε να επιλέξουμε τις προϋποθέσεις επιλογής των συμμετεχόντων στην προωθητική ενέργεια.',
    verifyConditionDesc: 'Ελέγχει το επίπεδο επαλήθευσης των παικτών',
    content: 'Περιεχόμενο',
    contentMachineInfo: 'Επιλέξτε τον μηχανισμό για να διαμορφώσετε το περιεχόμενό του.',
    unAuthorizedSubtitle: 'Υπότιτλος χωρίς εξουσιοδότηση',
    authorizedSubtitle: 'Υπότιτλος με εξουσιοδότηση',
    websiteMachineBackground: 'Φόντο μηχανισμού ιστότοπου',
    mobileMachineBackground: 'Φόντο μηχανισμού κινητού',
    authorizedSubtitleInfo: 'Καθορίστε τον υπότιτλο ο οποίος θα εμφανίζεται σε εξουσιοδοτημένους παίκτες',
    unAuthorizedSubtitleInfo: 'Καθορίστε τον υπότιτλο ο οποίος θα εμφανίζεται σε μη εξουσιοδοτημένους παίκτες',
    sectionSubtitle: 'Υπότιτλος ενότητας',
    contentSectionInfo: 'Σε αυτή την περιοχή, καθορίζουμε το περιεχόμενο για τις ενότητες του μηχανισμού. Το περιεχόμενο της ενότητας μπορεί επίσης να καθοριστεί ανά νόμισμα.',
    contentSelectSectionsInfo: 'Επιλέξτε μηχανισμό για να λάβετε ενότητες',
    contentPrizeInfo: 'Σε αυτή την περιοχή, καθορίζουμε το περιεχόμενο και τις μεταφράσεις για τα έπαθλα που θα είναι ορατά στην πλευρά του παίκτη.',
    termsAndConditions: 'Όροι και Προϋποθέσεις',
    contentTermsAndConditionsInfo: 'Σε αυτή την περιοχή, καθορίζουμε τους όρους και τις προϋποθέσεις για τη συμμετοχή στην προωθητική ενέργεια.',
    contentSidebarInfo: 'Εδώ μπορούμε να ορίσουμε το περιεχόμενο και τα εικονίδια της προωθητικής ενέργειας και των μηχανισμών',
    sectionContent: 'Περιεχόμενο ενότητας',
    sectionTitle: 'Τίτλος ενότητας',
    probabilities: 'Πιθανότητες',
    isUsedInMachineConfig: 'Δεν ήταν δυνατή η διαγραφή επειδή χρησιμοποιείται στη διαμόρφωση του μηχανισμού',
    emptyContentDesc: 'Μπορείτε να δείτε τα Περιεχόμενα μηχανισμού αφού τα προσθέσετε και τα διαμορφώσετε από το δεξί μενού πλοήγησης.',
    previewName: 'Προεπισκόπηση ονόματος',
    navigationItemsDisabledInfo: 'Τα στοιχεία πλοήγησης θα ενεργοποιηθούν μετά την αποθήκευση της ενότητας Γενικά της προωθητικής ενέργειας.',
    connectPrizesAndDefineProbabilities:
      'Σε αυτή την περιοχή, συνδέουμε έπαθλα με ενότητες μηχανισμών και καθορίζουμε τις πιθανότητες για αυτό. '
      + 'Το μέγιστο πλήθος επάθλων το οποίο μπορούν να κερδίσουν οι παίκτες κατά τη διάρκεια της ημέρας μπορεί να καθοριστεί με βάση τις ορισμένες πιθανότητες. Θα πρέπει να επιλεγεί το προκαθορισμένο έπαθλο, το οποίο θα είναι απεριόριστο έπαθλο για τον μηχανισμό και θα παρέχεται εάν ένας από τους περιορισμούς του επάθλου πληρούται.',
    advancedLimits: 'Προηγμένα όρια',
    choosePrize: 'Επιλογή επάθλου',
    wheelSectionsAndProbabilities: 'Ενότητες και Πιθανότητες τροχού',
    configureTagsAndProbabilities:
      'Σε αυτή την περιοχή, διαμορφώνουμε τις Κάθετες ετικέτες και τις πιθανότητες ανά έπαθλο. Οι ετικέτες με υψηλότερη'
      + ' προτεραιότητα θα πρέπει να λαμβάνονται πρώτες υπόψη σε περίπτωση που ο παίκτης έχει πολλές ετικέτες. Θα πρέπει να επιλεγεί το προκαθορισμένο έπαθλο για κάθε ετικέτα, το οποίο θα παρέχεται εάν ένας από τους περιορισμούς του επάθλου πληρούται.',
    defaultLanguageIs: 'Η προεπιλεγμένη γλώσσα της επωνυμίας είναι {{language}}',
    bonusConfigurations: 'Διαμορφώσεις μπόνους',
    setLimitationsForSelectedGroup: 'Σε αυτή την περιοχή, καθορίζουμε τον περιορισμό για την επιλεγμένη ομάδα. Κάθε έπαθλο μπορεί να συνδεθεί με μία ομάδα.',
    noPrizesForSection: 'Προσθέστε έπαθλο στην ενότητα Έπαθλα, που μπορεί να συνδεθεί με την ομάδα και καθορίσετε τον περιορισμό',
    main: 'Κύριο',
    spinDistributionPeriodInfo: 'Χρονική περίοδος σε ώρες, μετά την οποία οι περιστροφές θα διανεμηθούν',
    countOfSpinsInfo: 'Η ποσότητα των περιστροφών που πρέπει να διανεμηθεί στους παίκτες',
    expirationPeriodInfo: 'Χρονική περίοδος σε ώρες, μετά την οποία οι παρεχόμενες περιστροφές θα λήξουν και δεν θα μπορούν να χρησιμοποιηθούν',
    tryAgainInfo: 'Κανένα κέρδος',
    machineName: 'Όνομα μηχανισμού',
    machineType: 'Τύπος μηχανισμού',
    machineTypes: 'Τύποι μηχανισμών',
    prizeName: 'Όνομα επάθλου',
    prizeTypes: 'Τύποι επάθλων',
    spinDate: 'Ημερομηνία περιστροφής',
    totalUsage: 'Συνολική χρήση',
    itemName: 'Όνομα στοιχείου',
    totalDistributedSpins: 'Σύνολο διανεμημένων περιστροφών',
    totalUsedSpins: 'Σύνολο χρησιμοποιημένων περιστροφών',
    remainingSpins: 'Περιστροφές που απομένουν',
    lastSpinDate: 'Ημερομηνία τελευταίας περιστροφής',
    limitPerPlayer: 'Όριο ανά παίκτη',
    manualSpinsPlayers: 'Παίκτες μη αυτόματων περιστροφών',
    provisionType: 'Τύπος παροχής',
    spins: '‏Περιστροφές',
    websiteBackground: 'Φόντο ιστότοπου',
    mobileBackground: 'Φόντο κινητού',
    pageBackground: 'Φόντο σελίδας',
    machineContents: 'Περιεχόμενα μηχανισμού',
    spinDepositDesc: 'Επιτρέπει την παροχή ενεργοποιήσεων από καταθέσεις που έγιναν από τον παίκτη',
    depositDistributionNumberOfDesc: 'Ο χρήστης μπορεί να επιλέξει για πόσες καταθέσεις θα λάβουν οι παίκτες τις ενεργοποιήσεις μηχανισμού',
    depositDistributionEveryDesc: 'Αυτό το πεδίο θα επιτρέψει τον ορισμό του μέγιστου πλήθους επιτυχημένων ενεργοποιήσεων ανά παίκτη',
    periodEveryInfo: 'Επιτρέπει τον καθορισμό της περιόδου για την οποία θα πρέπει να λαμβάνονται υπόψη οι καταθέσεις',
    periodEveryMaxTriggerCountInfo: 'Αυτό το πεδίο θα επιτρέψει τον ορισμό του μέγιστου πλήθους επιτυχημένων ενεργοποιήσεων ανά παίκτη',
    depositExpirationPeriodInfo: 'Επιτρέπει τον καθορισμό της περιόδου όπου θα λήξουν οι περιστροφές του μηχανισμού',
    minimumAmount: 'Ελάχιστο ποσό',
    numberOfDepositsInfo: 'Επιλέξτε τις καταθέσεις για τις οποίες οι παίκτες πρέπει να λάβουν περιστροφές μηχανισμού κατά την καθορισμένη περίοδο',
    maxIssuanceCount: 'Μέγιστο πλήθος εκδόσεων',
    amountType: 'Τύπος ποσού',
    distributionOption: 'Επιλογή διανομής',
    distributionType: 'Τύπος διανομής',
    sidebarConfigDependenciesInfo:
      'Οι ενότητες Όριο ομάδας, Διαμόρφωση μηχανισμού και Περιεχόμενο θα καταστούν ενεργές μετά την ολοκλήρωση των ενοτήτων Διανομή περιστροφών, Συμμετέχοντες που παίζουν, Έπαθλο και Μηχανισμός.',
    sidebarMissingMainLanguageContentInfo: 'Tα Περιεχόμενα προωθητικής ενέργειας και Μηχανισμού θα πρέπει να παρέχονται για την προεπιλεγμένη γλώσσα της επωνυμίας.',
    privateModeParticipantsConfigInfo: 'Η παρεχόμενη λίστα μπορεί να επηρεαστεί μετά την επιλογή των νομισμάτων προωθητικής ενέργειας.',
    playersAbleToSee: 'Οι παίκτες μπορούν να δουν τη σελίδα Προωθητικής ενέργειας',
    heightAndWidth: 'Ύψος και Πλάτος',
    heightAndWidthInfo: 'Επιλέξτε το Ύψος και το Πλάτος για να δημιουργήσετε έναν μηχανισμό με τις συγκεκριμένες διαστάσεις',
    dimensions: 'Διαστάσεις',
    gridMachineInfo: 'Εδώ μπορείτε να ορίσετε ονόματα κελιών μηχανισμού Πίνακα για μελλοντικές συνδέσεις επάθλων',
    gridSectionsAndProbabilities: 'Ενότητες και Πιθανότητες Πίνακα',
    prizeTypeMaterialInfo: 'Τα έπαθλα τύπου υλικού μπορούν να δημιουργηθούν διαμορφώνοντας το όνομά τους και το ισοδύναμο ποσό',
    prizeItemConfigurations: 'Διαμορφώσεις στοιχείου επάθλου',
    equivalentAmount: 'Ισοδύναμο ποσό',
    wonDate: 'Ημερομηνία που κερδήθηκε',
  },
};
