import { filterKeys } from 'constants/configKeys';

const players = (marketGeneralSettings, standards) => ({
  id: {
    title: 'id',
    disabled: true,
    checked: true,
  },
  userName: {
    title: 'username',
    disabled: true,
    checked: true,
  },
  firstName: {
    title: 'firstName',
    checked: true,
  },
  lastName: {
    title: 'lastName',
    checked: true,
  },
  email: {
    title: 'email',
    checked: true,
  },
  country: {
    title: 'country',
    checked: true,
  },
  currencyId: {
    title: 'currency',
    checked: true,
  },
  deviceTypes: {
    title: 'deviceType',
  },
  date: {
    title: 'player:regDate',
    disabled: true,
    checked: true,
  },
  lastLoginDate: {
    title: 'lastLoginDate',
  },
  sportStatuses: {
    title: 'sportStatus',
  },
  status: {
    title: 'accountStatus',
  },
  identityVerified: {
    title: 'player:identityVerified',
  },
  emailVerified: {
    title: 'player:emailVerified',
  },
  mobileVerified: {
    title: 'player:mobileVerified',
  },
  addressVerified: {
    title: 'player:addressVerified',
  },
  isLocked: {
    title: 'player:accountLocked',
  },
  isDormant: {
    title: 'player:accountInactive',
  },
  isAttentionMarked: {
    title: 'attentionMarked',
  },
  isSelfExcluded: {
    title: 'player:exclusionState',
  },
  betshopVerified: {
    title: 'player:physicalVerification',
  },
  isCitizenshipVerified: {
    title: 'player:citizenshipVerified',
  },
  blacklisted: {
    title: 'player:blacklisted',
  },
  nationality: {
    title: 'player:nationality',
  },
  bTag: {
    title: 'bTag',
  },
  politicallyExposedPerson: {
    title: 'player:pep',
  },
  subAffiliateId: {
    title: 'player:subAffiliateId',
  },
  externalPlayerId: {
    title: 'player:externalPlayerId',
  },
  ...(marketGeneralSettings?.account?.isCNPEnabled
    ? {
      cnp: {
        title: 'player:personalNumericCode',
      },
    }
    : {}),
  ...(standards?.brazil?.enabled
    ? {
      cpfNumber: {
        title: 'player:cpfNumber',
      },
      isCpfNumberVerified: {
        title: 'player:cpfNumberVerified',
      },
    }
    : {}),
  birthday: {
    title: 'dateOfBirth',
  },
});

const deposits = {
  transactionId: {
    title: 'id',
    disabled: true,
    checked: true,
  },
  extTransactionId: {
    title: 'extId',
  },
  playerId: {
    title: 'playerId',
  },
  userName: {
    title: 'username',
  },
  paymentMethods: {
    title: 'paymentMethod',
  },
  paymentTypes: {
    title: 'methodType',
  },
  statuses: {
    title: 'status',
    checked: true,
  },
  firstName: {
    title: 'firstName',
  },
  lastName: {
    title: 'lastName',
  },
  amount: {
    title: 'amount',
  },
  deviceType: {
    title: 'deviceType',
  },
  currencies: {
    title: 'currency',
  },
  tagCategoryId: {
    title: 'tagCategories',
  },
  tagIds: {
    title: 'tags',
  },
  betshopId: {
    title: 'payment:betshop',
  },
  createdDate: {
    title: 'creationDate',
    disabled: true,
    checked: true,
  },
  updatedDate: {
    title: 'lastUpdateDate',
  },
};

const withdrawals = {
  transactionId: {
    title: 'id',
    disabled: true,
    checked: true,
  },
  extTransactionId: {
    title: 'extId',
  },
  playerId: {
    title: 'playerId',
  },
  userName: {
    title: 'username',
  },
  paymentMethods: {
    title: 'paymentMethod',
  },
  paymentTypes: {
    title: 'methodType',
  },
  statuses: {
    title: 'status',
    checked: true,
  },
  firstName: {
    title: 'firstName',
  },
  lastName: {
    title: 'lastName',
  },
  amount: {
    title: 'amount',
  },
  deviceType: {
    title: 'deviceType',
  },
  currencies: {
    title: 'currency',
  },
  tagCategoryId: {
    title: 'tagCategories',
  },
  tagIds: {
    title: 'tags',
  },
  automationId: {
    title: 'payment:automationId',
  },
  isAttentionMarked: {
    title: 'attentionMarked',
  },
  betshopId: {
    title: 'payment:betshop',
  },
  createdDate: {
    title: 'creationDate',
    disabled: true,
    checked: true,
  },
  updatedDate: {
    title: 'lastUpdateDate',
  },
};

const casino = {
  id: {
    title: 'id',
    checked: true,
    disabled: true,
  },
  roundId: {
    title: 'roundId',
    checked: true,
  },
  isBonusBet: {
    title: 'bet:bonusBet',
  },
  bonusPlayerId: {
    title: 'bet:bonusPlayerId',
  },
  states: {
    title: 'state',
  },
  currencyIds: {
    title: 'currencies',
  },
  playerId: {
    title: 'playerId',
  },
  externalPlayerId: {
    title: 'player:externalPlayerId',
  },
  isTestAccount: {
    title: 'testPlayer',
  },
  userName: {
    title: 'username',
  },
  betAmount: {
    title: 'betAmount',
  },
  winAmount: {
    title: 'winAmount',
  },
  providerAggregatorIds: {
    title: 'aggregator',
  },
  providerIds: {
    title: 'provider',
  },
  categoryIds: {
    title: 'category',
  },
  gameIds: {
    title: 'gameName',
  },
  deviceTypes: {
    title: 'deviceType',
  },
  createdDate: {
    title: 'creationDate',
    checked: true,
    disabled: true,
  },
  updatedDate: {
    title: 'lastUpdateDate',
  },
};

const sport = {
  id: {
    title: 'id',
    checked: true,
    disabled: true,
  },
  couponNumber: {
    title: 'bet:couponNumber',
    checked: true,
  },
  isBonusBet: {
    title: 'bet:bonusBet',
  },
  bonusPlayerId: {
    title: 'bet:bonusPlayerId',
  },
  states: {
    title: 'state',
  },
  currencies: {
    title: 'currencies',
  },
  playerId: {
    title: 'playerId',
  },
  isTestAccount: {
    title: 'testPlayer',
  },
  userName: {
    title: 'username',
  },
  isBetBuilder: {
    title: 'betBuilder',
  },
  betTypes: {
    title: 'betType',
  },
  sportBetType: {
    title: 'bet:sportBetType',
  },
  sportBetProcessingTypes: {
    title: 'bet:processingType',
  },
  isCashedOut: {
    title: 'bet:cashOut',
  },
  betAmount: {
    title: 'betAmount',
  },
  winAmount: {
    title: 'winAmount',
  },
  deviceTypes: {
    title: 'deviceType',
  },
  createdDate: {
    title: 'creationDate',
    checked: true,
    disabled: true,
  },
  updatedDate: {
    title: 'lastUpdateDate',
  },
};

const playerCasino = {
  id: {
    title: 'id',
    checked: true,
    disabled: true,
  },
  roundId: {
    title: 'roundId',
    checked: true,
  },
  isBonusBet: {
    title: 'bet:bonusBet',
  },
  bonusPlayerId: {
    title: 'bet:bonusPlayerId',
  },
  states: {
    title: 'state',
  },
  betAmount: {
    title: 'betAmount',
  },
  winAmount: {
    title: 'winAmount',
  },
  providerAggregatorIds: {
    title: 'aggregator',
  },
  providerIds: {
    title: 'provider',
  },
  categoryIds: {
    title: 'category',
  },
  gameIds: {
    title: 'gameName',
  },
  deviceTypes: {
    title: 'deviceType',
  },
  createdDate: {
    title: 'creationDate',
    checked: true,
    disabled: true,
  },
  updatedDate: {
    title: 'lastUpdateDate',
  },
};

const playerSport = {
  id: {
    title: 'id',
    checked: true,
    disabled: true,
  },
  couponNumber: {
    title: 'bet:couponNumber',
    checked: true,
  },
  isBonusBet: {
    title: 'bet:bonusBet',
  },
  bonusPlayerId: {
    title: 'bet:bonusPlayerId',
  },
  states: {
    title: 'state',
  },
  isBetBuilder: {
    title: 'betBuilder',
  },
  betTypes: {
    title: 'betType',
  },
  sportBetType: {
    title: 'bet:sportBetType',
  },
  sportBetProcessingTypes: {
    title: 'bet:processingType',
  },
  isCashedOut: {
    title: 'bet:cashOut',
  },
  betAmount: {
    title: 'betAmount',
  },
  winAmount: {
    title: 'winAmount',
  },
  deviceTypes: {
    title: 'deviceType',
  },
  createdDate: {
    title: 'creationDate',
    checked: true,
    disabled: true,
  },
  updatedDate: {
    title: 'lastUpdateDate',
  },
};

export default {
  [filterKeys.playersFilter]: players,
  [filterKeys.depositsFilter]: deposits,
  [filterKeys.withdrawalsFilter]: withdrawals,
  [filterKeys.casinoFilter]: casino,
  [filterKeys.playerCasinoFilter]: playerCasino,
  [filterKeys.sportFilter]: sport,
  [filterKeys.playerSportFilter]: playerSport,
};
