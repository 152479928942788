import PropTypes from 'prop-types';
import IconSvg from 'components/common/icon/IconSvg';
import { StyledTabInfo, StyledTabInfoText } from './TabInfo.styled';

const TabInfo = (props) => {
  const {
    content, width, isInline, hasBg, marginTop,
  } = props;
  return (
    <StyledTabInfo width={width} isInline={isInline} hasBg={hasBg} marginTop={marginTop}>
      <IconSvg icon="InfoIcon" />
      <StyledTabInfoText>{content}</StyledTabInfoText>
    </StyledTabInfo>
  );
};

TabInfo.propTypes = {
  content: PropTypes.node.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isInline: PropTypes.bool,
  hasBg: PropTypes.bool,
  marginTop: PropTypes.bool,
};

TabInfo.defaultProps = {
  width: 'auto',
  isInline: false,
  hasBg: false,
  marginTop: false,
};

export default TabInfo;
